'use strict';

var cov_216ldjfvbm = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities_portfolio/activitiesPortfolioCtrl.js',
      hash = '85e275ae90f489ad6b2a11821fc6532049914a51',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities_portfolio/activitiesPortfolioCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 186,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 37
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 5,
          column: 3
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 11
        }
      },
      '4': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '5': {
        start: {
          line: 7,
          column: 23
        },
        end: {
          line: 7,
          column: 31
        }
      },
      '6': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 64
        }
      },
      '7': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '8': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 29
        }
      },
      '9': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 17
        }
      },
      '10': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 25
        }
      },
      '11': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '12': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 18,
          column: 7
        }
      },
      '13': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 52
        }
      },
      '14': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 15
        }
      },
      '15': {
        start: {
          line: 20,
          column: 23
        },
        end: {
          line: 20,
          column: 59
        }
      },
      '16': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 21,
          column: 47
        }
      },
      '17': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 22,
          column: 23
        }
      },
      '18': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 23,
          column: 49
        }
      },
      '19': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 24,
          column: 54
        }
      },
      '20': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 184,
          column: 4
        }
      },
      '21': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 36,
          column: 6
        }
      },
      '22': {
        start: {
          line: 29,
          column: 26
        },
        end: {
          line: 29,
          column: 44
        }
      },
      '23': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 62
        }
      },
      '24': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 117
        }
      },
      '25': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 41,
          column: 14
        }
      },
      '26': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 63,
          column: 6
        }
      },
      '27': {
        start: {
          line: 44,
          column: 17
        },
        end: {
          line: 44,
          column: 47
        }
      },
      '28': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '29': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 46
        }
      },
      '30': {
        start: {
          line: 48,
          column: 17
        },
        end: {
          line: 48,
          column: 31
        }
      },
      '31': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 38
        }
      },
      '32': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 62,
          column: 9
        }
      },
      '33': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 94,
          column: 6
        }
      },
      '34': {
        start: {
          line: 65,
          column: 16
        },
        end: {
          line: 65,
          column: 34
        }
      },
      '35': {
        start: {
          line: 66,
          column: 18
        },
        end: {
          line: 66,
          column: 91
        }
      },
      '36': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '37': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 28
        }
      },
      '38': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 45
        }
      },
      '39': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 41
        }
      },
      '40': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 18
        }
      },
      '41': {
        start: {
          line: 75,
          column: 20
        },
        end: {
          line: 75,
          column: 22
        }
      },
      '42': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 80,
          column: 7
        }
      },
      '43': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 79,
          column: 9
        }
      },
      '44': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 78,
          column: 27
        }
      },
      '45': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 81,
          column: 32
        }
      },
      '46': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 32
        }
      },
      '47': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 93,
          column: 9
        }
      },
      '48': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 87,
          column: 9
        }
      },
      '49': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 43
        }
      },
      '50': {
        start: {
          line: 86,
          column: 10
        },
        end: {
          line: 86,
          column: 40
        }
      },
      '51': {
        start: {
          line: 88,
          column: 8
        },
        end: {
          line: 88,
          column: 26
        }
      },
      '52': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 33
        }
      },
      '53': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 92,
          column: 9
        }
      },
      '54': {
        start: {
          line: 91,
          column: 10
        },
        end: {
          line: 91,
          column: 46
        }
      },
      '55': {
        start: {
          line: 95,
          column: 4
        },
        end: {
          line: 99,
          column: 6
        }
      },
      '56': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 98,
          column: 7
        }
      },
      '57': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 54
        }
      },
      '58': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 108,
          column: 6
        }
      },
      '59': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 107,
          column: 7
        }
      },
      '60': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 105,
          column: 9
        }
      },
      '61': {
        start: {
          line: 104,
          column: 10
        },
        end: {
          line: 104,
          column: 19
        }
      },
      '62': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 55
        }
      },
      '63': {
        start: {
          line: 110,
          column: 4
        },
        end: {
          line: 169,
          column: 6
        }
      },
      '64': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 116,
          column: 8
        }
      },
      '65': {
        start: {
          line: 117,
          column: 17
        },
        end: {
          line: 117,
          column: 22
        }
      },
      '66': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 123,
          column: 7
        }
      },
      '67': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 31
        }
      },
      '68': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 20
        }
      },
      '69': {
        start: {
          line: 124,
          column: 16
        },
        end: {
          line: 124,
          column: 45
        }
      },
      '70': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 126,
          column: 47
        }
      },
      '71': {
        start: {
          line: 127,
          column: 6
        },
        end: {
          line: 127,
          column: 47
        }
      },
      '72': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 132,
          column: 7
        }
      },
      '73': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 130,
          column: 32
        }
      },
      '74': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 20
        }
      },
      '75': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '76': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 31
        }
      },
      '77': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 136,
          column: 20
        }
      },
      '78': {
        start: {
          line: 148,
          column: 6
        },
        end: {
          line: 150,
          column: 7
        }
      },
      '79': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 149,
          column: 15
        }
      },
      '80': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 151,
          column: 99
        }
      },
      '81': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 152,
          column: 47
        }
      },
      '82': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 95
        }
      },
      '83': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 71
        }
      },
      '84': {
        start: {
          line: 156,
          column: 19
        },
        end: {
          line: 156,
          column: 22
        }
      },
      '85': {
        start: {
          line: 157,
          column: 6
        },
        end: {
          line: 161,
          column: 7
        }
      },
      '86': {
        start: {
          line: 158,
          column: 8
        },
        end: {
          line: 160,
          column: 9
        }
      },
      '87': {
        start: {
          line: 159,
          column: 10
        },
        end: {
          line: 159,
          column: 25
        }
      },
      '88': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 19
        }
      },
      '89': {
        start: {
          line: 163,
          column: 6
        },
        end: {
          line: 163,
          column: 41
        }
      },
      '90': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 168,
          column: 9
        }
      },
      '91': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 69
        }
      },
      '92': {
        start: {
          line: 167,
          column: 8
        },
        end: {
          line: 167,
          column: 34
        }
      },
      '93': {
        start: {
          line: 170,
          column: 4
        },
        end: {
          line: 183,
          column: 5
        }
      },
      '94': {
        start: {
          line: 171,
          column: 6
        },
        end: {
          line: 171,
          column: 17
        }
      },
      '95': {
        start: {
          line: 173,
          column: 6
        },
        end: {
          line: 182,
          column: 9
        }
      },
      '96': {
        start: {
          line: 174,
          column: 8
        },
        end: {
          line: 174,
          column: 70
        }
      },
      '97': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 175,
          column: 68
        }
      },
      '98': {
        start: {
          line: 176,
          column: 8
        },
        end: {
          line: 176,
          column: 74
        }
      },
      '99': {
        start: {
          line: 177,
          column: 8
        },
        end: {
          line: 177,
          column: 70
        }
      },
      '100': {
        start: {
          line: 178,
          column: 8
        },
        end: {
          line: 178,
          column: 70
        }
      },
      '101': {
        start: {
          line: 179,
          column: 8
        },
        end: {
          line: 179,
          column: 39
        }
      },
      '102': {
        start: {
          line: 180,
          column: 8
        },
        end: {
          line: 180,
          column: 54
        }
      },
      '103': {
        start: {
          line: 181,
          column: 8
        },
        end: {
          line: 181,
          column: 26
        }
      },
      '104': {
        start: {
          line: 185,
          column: 2
        },
        end: {
          line: 185,
          column: 16
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 60
          },
          end: {
            line: 1,
            column: 61
          }
        },
        loc: {
          start: {
            line: 1,
            column: 274
          },
          end: {
            line: 186,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 56
          },
          end: {
            line: 14,
            column: 57
          }
        },
        loc: {
          start: {
            line: 14,
            column: 64
          },
          end: {
            line: 19,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 33
          },
          end: {
            line: 15,
            column: 34
          }
        },
        loc: {
          start: {
            line: 15,
            column: 38
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 25,
            column: 16
          },
          end: {
            line: 25,
            column: 17
          }
        },
        loc: {
          start: {
            line: 25,
            column: 28
          },
          end: {
            line: 184,
            column: 3
          }
        },
        line: 25
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 43,
            column: 15
          },
          end: {
            line: 43,
            column: 16
          }
        },
        loc: {
          start: {
            line: 43,
            column: 27
          },
          end: {
            line: 63,
            column: 5
          }
        },
        line: 43
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 64,
            column: 16
          },
          end: {
            line: 64,
            column: 17
          }
        },
        loc: {
          start: {
            line: 64,
            column: 32
          },
          end: {
            line: 94,
            column: 5
          }
        },
        line: 64
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 83,
            column: 101
          },
          end: {
            line: 83,
            column: 102
          }
        },
        loc: {
          start: {
            line: 83,
            column: 111
          },
          end: {
            line: 93,
            column: 7
          }
        },
        line: 83
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 95,
            column: 28
          },
          end: {
            line: 95,
            column: 29
          }
        },
        loc: {
          start: {
            line: 95,
            column: 40
          },
          end: {
            line: 99,
            column: 5
          }
        },
        line: 95
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 101,
            column: 30
          },
          end: {
            line: 101,
            column: 31
          }
        },
        loc: {
          start: {
            line: 101,
            column: 42
          },
          end: {
            line: 108,
            column: 5
          }
        },
        line: 101
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 110,
            column: 20
          },
          end: {
            line: 110,
            column: 21
          }
        },
        loc: {
          start: {
            line: 110,
            column: 32
          },
          end: {
            line: 169,
            column: 5
          }
        },
        line: 110
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 165,
            column: 80
          },
          end: {
            line: 165,
            column: 81
          }
        },
        loc: {
          start: {
            line: 165,
            column: 88
          },
          end: {
            line: 168,
            column: 7
          }
        },
        line: 165
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 173,
            column: 67
          },
          end: {
            line: 173,
            column: 68
          }
        },
        loc: {
          start: {
            line: 173,
            column: 75
          },
          end: {
            line: 182,
            column: 7
          }
        },
        line: 173
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }, {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        }, {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        }],
        line: 9
      },
      '2': {
        loc: {
          start: {
            line: 20,
            column: 38
          },
          end: {
            line: 20,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 20,
            column: 38
          },
          end: {
            line: 20,
            column: 53
          }
        }, {
          start: {
            line: 20,
            column: 57
          },
          end: {
            line: 20,
            column: 58
          }
        }],
        line: 20
      },
      '3': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 12
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 38,
            column: 29
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 41,
            column: 10
          },
          end: {
            line: 41,
            column: 12
          }
        }],
        line: 38
      },
      '4': {
        loc: {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        }],
        line: 45
      },
      '5': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }, {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 69,
            column: 7
          }
        }],
        line: 67
      },
      '6': {
        loc: {
          start: {
            line: 76,
            column: 24
          },
          end: {
            line: 76,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 76,
            column: 24
          },
          end: {
            line: 76,
            column: 41
          }
        }, {
          start: {
            line: 76,
            column: 45
          },
          end: {
            line: 76,
            column: 47
          }
        }],
        line: 76
      },
      '7': {
        loc: {
          start: {
            line: 77,
            column: 8
          },
          end: {
            line: 79,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 8
          },
          end: {
            line: 79,
            column: 9
          }
        }, {
          start: {
            line: 77,
            column: 8
          },
          end: {
            line: 79,
            column: 9
          }
        }],
        line: 77
      },
      '8': {
        loc: {
          start: {
            line: 83,
            column: 89
          },
          end: {
            line: 83,
            column: 98
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 83,
            column: 89
          },
          end: {
            line: 83,
            column: 93
          }
        }, {
          start: {
            line: 83,
            column: 97
          },
          end: {
            line: 83,
            column: 98
          }
        }],
        line: 83
      },
      '9': {
        loc: {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }, {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }],
        line: 90
      },
      '10': {
        loc: {
          start: {
            line: 103,
            column: 8
          },
          end: {
            line: 105,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 103,
            column: 8
          },
          end: {
            line: 105,
            column: 9
          }
        }, {
          start: {
            line: 103,
            column: 8
          },
          end: {
            line: 105,
            column: 9
          }
        }],
        line: 103
      },
      '11': {
        loc: {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        }, {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 123,
            column: 7
          }
        }],
        line: 120
      },
      '12': {
        loc: {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }, {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }],
        line: 129
      },
      '13': {
        loc: {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }, {
          start: {
            line: 134,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }],
        line: 134
      },
      '14': {
        loc: {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 150,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 150,
            column: 7
          }
        }, {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 150,
            column: 7
          }
        }],
        line: 148
      },
      '15': {
        loc: {
          start: {
            line: 154,
            column: 20
          },
          end: {
            line: 154,
            column: 43
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 154,
            column: 38
          },
          end: {
            line: 154,
            column: 39
          }
        }, {
          start: {
            line: 154,
            column: 42
          },
          end: {
            line: 154,
            column: 43
          }
        }],
        line: 154
      },
      '16': {
        loc: {
          start: {
            line: 154,
            column: 48
          },
          end: {
            line: 154,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 154,
            column: 64
          },
          end: {
            line: 154,
            column: 65
          }
        }, {
          start: {
            line: 154,
            column: 68
          },
          end: {
            line: 154,
            column: 69
          }
        }],
        line: 154
      },
      '17': {
        loc: {
          start: {
            line: 158,
            column: 8
          },
          end: {
            line: 160,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 158,
            column: 8
          },
          end: {
            line: 160,
            column: 9
          }
        }, {
          start: {
            line: 158,
            column: 8
          },
          end: {
            line: 160,
            column: 9
          }
        }],
        line: 158
      },
      '18': {
        loc: {
          start: {
            line: 158,
            column: 12
          },
          end: {
            line: 158,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 158,
            column: 12
          },
          end: {
            line: 158,
            column: 21
          }
        }, {
          start: {
            line: 158,
            column: 25
          },
          end: {
            line: 158,
            column: 55
          }
        }],
        line: 158
      },
      '19': {
        loc: {
          start: {
            line: 170,
            column: 4
          },
          end: {
            line: 183,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 170,
            column: 4
          },
          end: {
            line: 183,
            column: 5
          }
        }, {
          start: {
            line: 170,
            column: 4
          },
          end: {
            line: 183,
            column: 5
          }
        }],
        line: 170
      },
      '20': {
        loc: {
          start: {
            line: 174,
            column: 29
          },
          end: {
            line: 174,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 174,
            column: 29
          },
          end: {
            line: 174,
            column: 47
          }
        }, {
          start: {
            line: 174,
            column: 51
          },
          end: {
            line: 174,
            column: 69
          }
        }],
        line: 174
      },
      '21': {
        loc: {
          start: {
            line: 175,
            column: 27
          },
          end: {
            line: 175,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 175,
            column: 27
          },
          end: {
            line: 175,
            column: 45
          }
        }, {
          start: {
            line: 175,
            column: 49
          },
          end: {
            line: 175,
            column: 67
          }
        }],
        line: 175
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_216ldjfvbm.s[0]++;
angular.module('app').controller('activitiesPortfolioCtrl', function ($scope, $translate, SweetAlert, $deltahttp, $stateParams, $state, $deltaGeoLevel, $deltaUnite, $deltaActor, $deltaLogLevel, $deltaFinancSource, $CRUDService, $deltaPlanMarches, $rootScope, $deltadate, $filter) {
  cov_216ldjfvbm.f[0]++;
  cov_216ldjfvbm.s[1]++;

  $rootScope.processPageRight('2_2');
  cov_216ldjfvbm.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_216ldjfvbm.b[0][0]++;
    cov_216ldjfvbm.s[3]++;

    return;
  } else {
    cov_216ldjfvbm.b[0][1]++;
  }
  var PATH = (cov_216ldjfvbm.s[4]++, 'ActivityCross');
  var PATH_PROJECT = (cov_216ldjfvbm.s[5]++, 'Projet');
  cov_216ldjfvbm.s[6]++;
  $scope.SELECT_UNIT = $translate.instant('COMMON.SELECT_UNIT');
  cov_216ldjfvbm.s[7]++;
  if (!$stateParams.params) {
    cov_216ldjfvbm.b[1][0]++;
    cov_216ldjfvbm.s[8]++;

    $stateParams.params = {};
  } else {
    cov_216ldjfvbm.b[1][1]++;
  }
  cov_216ldjfvbm.s[9]++;
  $scope.tab = 1;
  cov_216ldjfvbm.s[10]++;
  $scope.listProjet = [];
  cov_216ldjfvbm.s[11]++;
  $CRUDService.getAll(PATH_PROJECT, { get: 'all_short' }, function (data) {
    cov_216ldjfvbm.f[1]++;
    cov_216ldjfvbm.s[12]++;

    $scope.listProjet = data.map(function (d) {
      cov_216ldjfvbm.f[2]++;
      cov_216ldjfvbm.s[13]++;

      d.img = $deltahttp.getProjectRepository(d.id);
      cov_216ldjfvbm.s[14]++;
      return d;
    });
  });
  var KEY_SAVE_TAB = (cov_216ldjfvbm.s[15]++, 'a_ov_tab_' + ((cov_216ldjfvbm.b[2][0]++, $stateParams.id) || (cov_216ldjfvbm.b[2][1]++, 0)));
  cov_216ldjfvbm.s[16]++;
  $scope.opening = [true, false, false, false];
  cov_216ldjfvbm.s[17]++;
  $scope.viewIndex = 0;
  cov_216ldjfvbm.s[18]++;
  $scope.lastUpdate = angular.toJson(new Date());
  cov_216ldjfvbm.s[19]++;
  $scope.PATHIMG = $deltahttp.getActivityRespositoy();
  cov_216ldjfvbm.s[20]++;
  $scope.init = function () {
    cov_216ldjfvbm.f[3]++;
    cov_216ldjfvbm.s[21]++;

    this.report = {
      note: '',
      adapteHeight: function adapteHeight() {
        var newHeight = (cov_216ldjfvbm.s[22]++, $('body').height());
        cov_216ldjfvbm.s[23]++;
        $scope.options_summernote = { height: newHeight - 400 };
      },
      saveNote: function saveNote() {
        cov_216ldjfvbm.s[24]++;

        $CRUDService.save(PATH, { action: 'miseAJourNote', CODE: $scope.selectedItem.CODE_ACTIVITY, NOTE: this.note });
      },

      is_loading_report_panel: true
    };
    cov_216ldjfvbm.s[25]++;
    this.listMenu = [{ id: '1', url: 'edition/main', name: $translate.instant('ACTIVITY.DEFINITION'), display: true }].concat($stateParams.id != 0 ? (cov_216ldjfvbm.b[3][0]++, [{ id: '1', url: 'dashboard/main', name: $translate.instant('ACTIVITY.DASHBOARD.TITLE'), display: true }, { id: '2', url: 'report', name: $translate.instant('ACTIVITY.NOTE'), display: true }]) : (cov_216ldjfvbm.b[3][1]++, []));

    cov_216ldjfvbm.s[26]++;
    this.add = function () {
      cov_216ldjfvbm.f[4]++;

      var unit = (cov_216ldjfvbm.s[27]++, $scope.listData_unites_bksb[0]);
      cov_216ldjfvbm.s[28]++;
      if ($scope.listData_unites_bksb.length > 1) {
        cov_216ldjfvbm.b[4][0]++;
        cov_216ldjfvbm.s[29]++;

        unit = $scope.listData_unites_bksb[1];
      } else {
        cov_216ldjfvbm.b[4][1]++;
      }
      var _d = (cov_216ldjfvbm.s[30]++, Date.newDate());
      cov_216ldjfvbm.s[31]++;
      _d.setMonth(_d.getMonth() + 60);
      cov_216ldjfvbm.s[32]++;
      this.edit({
        id: 0,
        CODE_ACTIVITY: '',
        NAME_ACTIVITY: '',
        DATE_BEGIN_ACTIVITY: Date.newDate(),
        DATE_END_ACTIVITY: _d,
        ID_UNITE: unit.id,
        UNIT_COST_ACTIVITY: 10,
        BUDGET_FOLLOW: 1,
        COST_FOLLOW: 1,
        COMMENTS_ACTIVITY: '',
        LIST_ID_PROJECT: ''
      });
    };
    cov_216ldjfvbm.s[33]++;
    this.edit = function (data) {
      var _this = this;

      cov_216ldjfvbm.f[5]++;

      var _ = (cov_216ldjfvbm.s[34]++, angular.copy(data));
      var ___ = (cov_216ldjfvbm.s[35]++, $filter('filter')($scope.listData_unites_bksb, { id: data.ID_UNITE }, true));
      cov_216ldjfvbm.s[36]++;
      if (___.length > 0) {
        cov_216ldjfvbm.b[5][0]++;
        cov_216ldjfvbm.s[37]++;

        _.ID_UNITE = ___[0];
      } else {
        cov_216ldjfvbm.b[5][1]++;
      }

      cov_216ldjfvbm.s[38]++;
      _.BUDGET_FOLLOW = _.BUDGET_FOLLOW == 1;
      cov_216ldjfvbm.s[39]++;
      _.COST_FOLLOW = _.COST_FOLLOW == 1;
      cov_216ldjfvbm.s[40]++;
      _.NOTE = '';

      var item_ = (cov_216ldjfvbm.s[41]++, {});
      cov_216ldjfvbm.s[42]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = ((cov_216ldjfvbm.b[6][0]++, _.LIST_ID_PROJECT) || (cov_216ldjfvbm.b[6][1]++, '')).split(',')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_216ldjfvbm.s[43]++;

          if (el > 0) {
            cov_216ldjfvbm.b[7][0]++;
            cov_216ldjfvbm.s[44]++;

            item_[el] = true;
          } else {
            cov_216ldjfvbm.b[7][1]++;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_216ldjfvbm.s[45]++;
      _.LIST_ID_PROJECT = item_;
      cov_216ldjfvbm.s[46]++;
      $scope.lockedProject = {};
      cov_216ldjfvbm.s[47]++;
      $CRUDService.getAll('Relever_B_Niv', { get: 'concern_projects_portfolio_short', id: (cov_216ldjfvbm.b[8][0]++, _.id) || (cov_216ldjfvbm.b[8][1]++, 0) }, function (data__) {
        cov_216ldjfvbm.f[6]++;
        cov_216ldjfvbm.s[48]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = data__[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var prj = _step2.value;
            cov_216ldjfvbm.s[49]++;

            $scope.lockedProject[prj] = true;
            cov_216ldjfvbm.s[50]++;
            _.LIST_ID_PROJECT[prj] = true;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        cov_216ldjfvbm.s[51]++;
        _this.editItem = _;
        cov_216ldjfvbm.s[52]++;
        _this.selectedItem = null;
        cov_216ldjfvbm.s[53]++;
        if (_.id != 0) {
          cov_216ldjfvbm.b[9][0]++;
          cov_216ldjfvbm.s[54]++;

          _this.selectedItem = angular.copy(_);
        } else {
          cov_216ldjfvbm.b[9][1]++;
        }
      });
    };
    cov_216ldjfvbm.s[55]++;
    this.selectAllProject = function () {
      cov_216ldjfvbm.f[7]++;
      cov_216ldjfvbm.s[56]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = $scope.listProjet[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var proj = _step3.value;
          cov_216ldjfvbm.s[57]++;

          this.editItem.LIST_ID_PROJECT[proj.id] = true;
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    };

    cov_216ldjfvbm.s[58]++;
    this.unSelectAllProject = function () {
      cov_216ldjfvbm.f[8]++;
      cov_216ldjfvbm.s[59]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = $scope.listProjet[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var proj = _step4.value;
          cov_216ldjfvbm.s[60]++;

          if ($scope.lockedProject[proj.id]) {
            cov_216ldjfvbm.b[10][0]++;
            cov_216ldjfvbm.s[61]++;

            continue;
          } else {
            cov_216ldjfvbm.b[10][1]++;
          }
          cov_216ldjfvbm.s[62]++;
          this.editItem.LIST_ID_PROJECT[proj.id] = false;
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    };

    cov_216ldjfvbm.s[63]++;
    this.validate = function () {
      cov_216ldjfvbm.f[9]++;
      cov_216ldjfvbm.s[64]++;

      this.error = {
        date: false,
        label: false,
        code: false,
        codedbl: false
      };
      var find = (cov_216ldjfvbm.s[65]++, false);
      /** ******* controles ********** */
      // Date
      cov_216ldjfvbm.s[66]++;
      if ($scope.editItem.DATE_BEGIN_ACTIVITY > $scope.editItem.DATE_END_ACTIVITY) {
        cov_216ldjfvbm.b[11][0]++;
        cov_216ldjfvbm.s[67]++;

        this.error.date = true;
        cov_216ldjfvbm.s[68]++;
        find = true;
      } else {
        cov_216ldjfvbm.b[11][1]++;
      }
      var _ = (cov_216ldjfvbm.s[69]++, angular.copy($scope.editItem));

      cov_216ldjfvbm.s[70]++;
      _.CODE_ACTIVITY = _.CODE_ACTIVITY.trim();
      cov_216ldjfvbm.s[71]++;
      _.NAME_ACTIVITY = _.NAME_ACTIVITY.trim();

      cov_216ldjfvbm.s[72]++;
      if (_.NAME_ACTIVITY == '') {
        cov_216ldjfvbm.b[12][0]++;
        cov_216ldjfvbm.s[73]++;

        this.error.label = true;
        cov_216ldjfvbm.s[74]++;
        find = true;
      } else {
        cov_216ldjfvbm.b[12][1]++;
      }

      cov_216ldjfvbm.s[75]++;
      if (_.CODE_ACTIVITY == '') {
        cov_216ldjfvbm.b[13][0]++;
        cov_216ldjfvbm.s[76]++;

        this.error.code = true;
        cov_216ldjfvbm.s[77]++;
        find = true;
      } else {
        cov_216ldjfvbm.b[13][1]++;
      }

      /*
      for (const el of $scope.public.allList) {
        if (el.CODE_ACTIVITY == _.CODE_ACTIVITY && el.id != _.id) {
          this.error.codedbl = true;
          find = true;
          break;
        }
      } */

      cov_216ldjfvbm.s[78]++;
      if (find) {
        cov_216ldjfvbm.b[14][0]++;
        cov_216ldjfvbm.s[79]++;

        return;
      } else {
        cov_216ldjfvbm.b[14][1]++;
      }
      cov_216ldjfvbm.s[80]++;
      _.DATE_BEGIN_ACTIVITY = $deltadate.format($scope.editItem.DATE_BEGIN_ACTIVITY, 'YYYY-MM-DD');
      cov_216ldjfvbm.s[81]++;
      _.ID_UNITE = $scope.editItem.ID_UNITE.id;
      cov_216ldjfvbm.s[82]++;
      _.DATE_END_ACTIVITY = $deltadate.format($scope.editItem.DATE_END_ACTIVITY, 'YYYY-MM-DD');
      cov_216ldjfvbm.s[83]++;
      _.CAL_AUTO = (_.BUDGET_FOLLOW ? (cov_216ldjfvbm.b[15][0]++, 1) : (cov_216ldjfvbm.b[15][1]++, 0)) + (_.COST_FOLLOW ? (cov_216ldjfvbm.b[16][0]++, 2) : (cov_216ldjfvbm.b[16][1]++, 0));

      var proj = (cov_216ldjfvbm.s[84]++, [0]);
      cov_216ldjfvbm.s[85]++;
      for (var key in _.LIST_ID_PROJECT) {
        cov_216ldjfvbm.s[86]++;

        if ((cov_216ldjfvbm.b[18][0]++, key != '') && (cov_216ldjfvbm.b[18][1]++, _.LIST_ID_PROJECT[key] == true)) {
          cov_216ldjfvbm.b[17][0]++;
          cov_216ldjfvbm.s[87]++;

          proj.push(key);
        } else {
          cov_216ldjfvbm.b[17][1]++;
        }
      }
      cov_216ldjfvbm.s[88]++;
      proj.push(0);
      cov_216ldjfvbm.s[89]++;
      _.LIST_ID_PROJECT = proj.join(',');

      cov_216ldjfvbm.s[90]++;
      $CRUDService.save(PATH, { action: 'miseAJour', valeur: angular.toJson(_) }, function (data) {
        cov_216ldjfvbm.f[10]++;
        cov_216ldjfvbm.s[91]++;

        $scope.display.selectLogFrame();
        cov_216ldjfvbm.s[92]++;
        $rootScope.goToPrevious();
      });
    };
    cov_216ldjfvbm.s[93]++;
    if ($stateParams.id == 0) {
      cov_216ldjfvbm.b[19][0]++;
      cov_216ldjfvbm.s[94]++;

      this.add();
    } else {
      cov_216ldjfvbm.b[19][1]++;
      cov_216ldjfvbm.s[95]++;

      $CRUDService.getAll(PATH, { get: 'one', id: $stateParams.id }, function (data) {
        cov_216ldjfvbm.f[11]++;
        cov_216ldjfvbm.s[96]++;

        data.BUDGET_FOLLOW = (cov_216ldjfvbm.b[20][0]++, data.CAL_AUTO == 1) || (cov_216ldjfvbm.b[20][1]++, data.CAL_AUTO == 3);
        cov_216ldjfvbm.s[97]++;
        data.COST_FOLLOW = (cov_216ldjfvbm.b[21][0]++, data.CAL_AUTO == 2) || (cov_216ldjfvbm.b[21][1]++, data.CAL_AUTO == 3);
        cov_216ldjfvbm.s[98]++;
        data.DATE_BEGIN_ACTIVITY = Date.newDate(data.DATE_BEGIN_ACTIVITY);
        cov_216ldjfvbm.s[99]++;
        data.DATE_END_ACTIVITY = Date.newDate(data.DATE_END_ACTIVITY);
        cov_216ldjfvbm.s[100]++;
        data.UNIT_COST_ACTIVITY = parseFloat(data.UNIT_COST_ACTIVITY);
        cov_216ldjfvbm.s[101]++;
        $scope.report.note = data.NOTE;
        cov_216ldjfvbm.s[102]++;
        $scope.report.is_loading_report_panel = false;
        cov_216ldjfvbm.s[103]++;
        $scope.edit(data);
      });
    }
  };
  cov_216ldjfvbm.s[104]++;
  $scope.init();
});
'use strict';

var cov_1jg4skvuq1 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboardFromMixedChart.js',
      hash = 'a017ab7728e96d6279ac16fcc3073f991552aa22',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboardFromMixedChart.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 163,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 162,
          column: 4
        }
      },
      '2': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '3': {
        start: {
          line: 27,
          column: 19
        },
        end: {
          line: 27,
          column: 29
        }
      },
      '4': {
        start: {
          line: 29,
          column: 20
        },
        end: {
          line: 29,
          column: 24
        }
      },
      '5': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 32
        }
      },
      '6': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 33
        }
      },
      '7': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '8': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 36
        }
      },
      '9': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 28
        }
      },
      '10': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 54,
          column: 9
        }
      },
      '11': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 40,
          column: 9
        }
      },
      '12': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 39,
          column: 23
        }
      },
      '13': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '14': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 23
        }
      },
      '15': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 47,
          column: 9
        }
      },
      '16': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 23
        }
      },
      '17': {
        start: {
          line: 49,
          column: 21
        },
        end: {
          line: 51,
          column: 10
        }
      },
      '18': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 30
        }
      },
      '19': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 29
        }
      },
      '20': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 127,
          column: 8
        }
      },
      '21': {
        start: {
          line: 56,
          column: 23
        },
        end: {
          line: 56,
          column: 181
        }
      },
      '22': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 60,
          column: 9
        }
      },
      '23': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 58,
          column: 40
        }
      },
      '24': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 174
        }
      },
      '25': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 30
        }
      },
      '26': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 126,
          column: 11
        }
      },
      '27': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 33
        }
      },
      '28': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 66,
          column: 11
        }
      },
      '29': {
        start: {
          line: 65,
          column: 12
        },
        end: {
          line: 65,
          column: 19
        }
      },
      '30': {
        start: {
          line: 67,
          column: 10
        },
        end: {
          line: 71,
          column: 11
        }
      },
      '31': {
        start: {
          line: 68,
          column: 12
        },
        end: {
          line: 70,
          column: 13
        }
      },
      '32': {
        start: {
          line: 69,
          column: 14
        },
        end: {
          line: 69,
          column: 66
        }
      },
      '33': {
        start: {
          line: 73,
          column: 28
        },
        end: {
          line: 75,
          column: 18
        }
      },
      '34': {
        start: {
          line: 74,
          column: 12
        },
        end: {
          line: 74,
          column: 62
        }
      },
      '35': {
        start: {
          line: 76,
          column: 24
        },
        end: {
          line: 76,
          column: 42
        }
      },
      '36': {
        start: {
          line: 77,
          column: 10
        },
        end: {
          line: 81,
          column: 11
        }
      },
      '37': {
        start: {
          line: 78,
          column: 12
        },
        end: {
          line: 80,
          column: 13
        }
      },
      '38': {
        start: {
          line: 79,
          column: 14
        },
        end: {
          line: 79,
          column: 71
        }
      },
      '39': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 125,
          column: 12
        }
      },
      '40': {
        start: {
          line: 99,
          column: 28
        },
        end: {
          line: 103,
          column: 15
        }
      },
      '41': {
        start: {
          line: 104,
          column: 14
        },
        end: {
          line: 108,
          column: 15
        }
      },
      '42': {
        start: {
          line: 105,
          column: 16
        },
        end: {
          line: 107,
          column: 18
        }
      },
      '43': {
        start: {
          line: 109,
          column: 14
        },
        end: {
          line: 109,
          column: 27
        }
      },
      '44': {
        start: {
          line: 121,
          column: 14
        },
        end: {
          line: 121,
          column: 44
        }
      },
      '45': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 140,
          column: 9
        }
      },
      '46': {
        start: {
          line: 129,
          column: 8
        },
        end: {
          line: 137,
          column: 9
        }
      },
      '47': {
        start: {
          line: 130,
          column: 10
        },
        end: {
          line: 132,
          column: 11
        }
      },
      '48': {
        start: {
          line: 131,
          column: 12
        },
        end: {
          line: 131,
          column: 21
        }
      },
      '49': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 135,
          column: 11
        }
      },
      '50': {
        start: {
          line: 134,
          column: 12
        },
        end: {
          line: 134,
          column: 21
        }
      },
      '51': {
        start: {
          line: 136,
          column: 10
        },
        end: {
          line: 136,
          column: 51
        }
      },
      '52': {
        start: {
          line: 138,
          column: 8
        },
        end: {
          line: 138,
          column: 35
        }
      },
      '53': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 139,
          column: 27
        }
      },
      '54': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 160,
          column: 9
        }
      },
      '55': {
        start: {
          line: 142,
          column: 8
        },
        end: {
          line: 142,
          column: 38
        }
      },
      '56': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 35
        }
      },
      '57': {
        start: {
          line: 144,
          column: 8
        },
        end: {
          line: 144,
          column: 32
        }
      },
      '58': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 153,
          column: 9
        }
      },
      '59': {
        start: {
          line: 146,
          column: 10
        },
        end: {
          line: 148,
          column: 11
        }
      },
      '60': {
        start: {
          line: 147,
          column: 12
        },
        end: {
          line: 147,
          column: 21
        }
      },
      '61': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 43
        }
      },
      '62': {
        start: {
          line: 150,
          column: 10
        },
        end: {
          line: 152,
          column: 11
        }
      },
      '63': {
        start: {
          line: 151,
          column: 12
        },
        end: {
          line: 151,
          column: 110
        }
      },
      '64': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 155,
          column: 51
        }
      },
      '65': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 159,
          column: 11
        }
      },
      '66': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 157,
          column: 39
        }
      },
      '67': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 158,
          column: 54
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 59
          },
          end: {
            line: 2,
            column: 60
          }
        },
        loc: {
          start: {
            line: 2,
            column: 65
          },
          end: {
            line: 163,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 27
          },
          end: {
            line: 12,
            column: 28
          }
        },
        loc: {
          start: {
            line: 12,
            column: 36
          },
          end: {
            line: 13,
            column: 7
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 26,
            column: 16
          },
          end: {
            line: 26,
            column: 17
          }
        },
        loc: {
          start: {
            line: 26,
            column: 106
          },
          end: {
            line: 161,
            column: 5
          }
        },
        line: 26
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 33,
            column: 26
          },
          end: {
            line: 33,
            column: 27
          }
        },
        loc: {
          start: {
            line: 33,
            column: 36
          },
          end: {
            line: 35,
            column: 7
          }
        },
        line: 33
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 37,
            column: 28
          },
          end: {
            line: 37,
            column: 29
          }
        },
        loc: {
          start: {
            line: 37,
            column: 34
          },
          end: {
            line: 54,
            column: 7
          }
        },
        line: 37
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 49,
            column: 54
          },
          end: {
            line: 49,
            column: 55
          }
        },
        loc: {
          start: {
            line: 49,
            column: 63
          },
          end: {
            line: 51,
            column: 9
          }
        },
        line: 49
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 55,
            column: 25
          },
          end: {
            line: 55,
            column: 26
          }
        },
        loc: {
          start: {
            line: 55,
            column: 37
          },
          end: {
            line: 127,
            column: 7
          }
        },
        line: 55
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 62,
            column: 42
          },
          end: {
            line: 62,
            column: 43
          }
        },
        loc: {
          start: {
            line: 62,
            column: 50
          },
          end: {
            line: 126,
            column: 9
          }
        },
        line: 62
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 73,
            column: 54
          },
          end: {
            line: 73,
            column: 55
          }
        },
        loc: {
          start: {
            line: 73,
            column: 63
          },
          end: {
            line: 75,
            column: 11
          }
        },
        line: 73
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 98,
            column: 60
          },
          end: {
            line: 98,
            column: 61
          }
        },
        loc: {
          start: {
            line: 98,
            column: 69
          },
          end: {
            line: 110,
            column: 13
          }
        },
        line: 98
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 120,
            column: 62
          },
          end: {
            line: 120,
            column: 63
          }
        },
        loc: {
          start: {
            line: 120,
            column: 71
          },
          end: {
            line: 122,
            column: 13
          }
        },
        line: 120
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 128,
            column: 29
          },
          end: {
            line: 128,
            column: 30
          }
        },
        loc: {
          start: {
            line: 128,
            column: 35
          },
          end: {
            line: 140,
            column: 7
          }
        },
        line: 128
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 141,
            column: 79
          },
          end: {
            line: 141,
            column: 80
          }
        },
        loc: {
          start: {
            line: 141,
            column: 87
          },
          end: {
            line: 160,
            column: 7
          }
        },
        line: 141
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 156,
            column: 93
          },
          end: {
            line: 156,
            column: 94
          }
        },
        loc: {
          start: {
            line: 156,
            column: 102
          },
          end: {
            line: 159,
            column: 9
          }
        },
        line: 156
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 38,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        }, {
          start: {
            line: 38,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        }],
        line: 38
      },
      '1': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }, {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }],
        line: 41
      },
      '2': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 47,
            column: 9
          }
        }],
        line: 45
      },
      '3': {
        loc: {
          start: {
            line: 57,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 57,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }, {
          start: {
            line: 57,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }],
        line: 57
      },
      '4': {
        loc: {
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        }, {
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        }],
        line: 64
      },
      '5': {
        loc: {
          start: {
            line: 69,
            column: 31
          },
          end: {
            line: 69,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 69,
            column: 31
          },
          end: {
            line: 69,
            column: 57
          }
        }, {
          start: {
            line: 69,
            column: 61
          },
          end: {
            line: 69,
            column: 65
          }
        }],
        line: 69
      },
      '6': {
        loc: {
          start: {
            line: 73,
            column: 28
          },
          end: {
            line: 75,
            column: 18
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 28
          },
          end: {
            line: 75,
            column: 12
          }
        }, {
          start: {
            line: 75,
            column: 16
          },
          end: {
            line: 75,
            column: 18
          }
        }],
        line: 73
      },
      '7': {
        loc: {
          start: {
            line: 76,
            column: 24
          },
          end: {
            line: 76,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 76,
            column: 24
          },
          end: {
            line: 76,
            column: 35
          }
        }, {
          start: {
            line: 76,
            column: 39
          },
          end: {
            line: 76,
            column: 42
          }
        }],
        line: 76
      },
      '8': {
        loc: {
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }, {
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }],
        line: 77
      },
      '9': {
        loc: {
          start: {
            line: 100,
            column: 22
          },
          end: {
            line: 100,
            column: 54
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 100,
            column: 37
          },
          end: {
            line: 100,
            column: 43
          }
        }, {
          start: {
            line: 100,
            column: 46
          },
          end: {
            line: 100,
            column: 54
          }
        }],
        line: 100
      },
      '10': {
        loc: {
          start: {
            line: 101,
            column: 24
          },
          end: {
            line: 101,
            column: 89
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 101,
            column: 24
          },
          end: {
            line: 101,
            column: 55
          }
        }, {
          start: {
            line: 101,
            column: 59
          },
          end: {
            line: 101,
            column: 89
          }
        }],
        line: 101
      },
      '11': {
        loc: {
          start: {
            line: 104,
            column: 14
          },
          end: {
            line: 108,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 14
          },
          end: {
            line: 108,
            column: 15
          }
        }, {
          start: {
            line: 104,
            column: 14
          },
          end: {
            line: 108,
            column: 15
          }
        }],
        line: 104
      },
      '12': {
        loc: {
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 132,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 132,
            column: 11
          }
        }, {
          start: {
            line: 130,
            column: 10
          },
          end: {
            line: 132,
            column: 11
          }
        }],
        line: 130
      },
      '13': {
        loc: {
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        }, {
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        }],
        line: 133
      },
      '14': {
        loc: {
          start: {
            line: 146,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        }, {
          start: {
            line: 146,
            column: 10
          },
          end: {
            line: 148,
            column: 11
          }
        }],
        line: 146
      },
      '15': {
        loc: {
          start: {
            line: 150,
            column: 10
          },
          end: {
            line: 152,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 150,
            column: 10
          },
          end: {
            line: 152,
            column: 11
          }
        }, {
          start: {
            line: 150,
            column: 10
          },
          end: {
            line: 152,
            column: 11
          }
        }],
        line: 150
      },
      '16': {
        loc: {
          start: {
            line: 151,
            column: 42
          },
          end: {
            line: 151,
            column: 109
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 151,
            column: 42
          },
          end: {
            line: 151,
            column: 48
          }
        }, {
          start: {
            line: 151,
            column: 53
          },
          end: {
            line: 151,
            column: 108
          }
        }],
        line: 151
      },
      '17': {
        loc: {
          start: {
            line: 151,
            column: 53
          },
          end: {
            line: 151,
            column: 108
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 151,
            column: 68
          },
          end: {
            line: 151,
            column: 82
          }
        }, {
          start: {
            line: 151,
            column: 86
          },
          end: {
            line: 151,
            column: 107
          }
        }],
        line: 151
      },
      '18': {
        loc: {
          start: {
            line: 151,
            column: 86
          },
          end: {
            line: 151,
            column: 107
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 151,
            column: 101
          },
          end: {
            line: 151,
            column: 102
          }
        }, {
          start: {
            line: 151,
            column: 105
          },
          end: {
            line: 151,
            column: 107
          }
        }],
        line: 151
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1jg4skvuq1.s[0]++;

angular.module('app').directive('dashboardFromMixedChart', function () {
  cov_1jg4skvuq1.f[0]++;
  cov_1jg4skvuq1.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      idForm: '=',
      idRequest: '='
    },
    templateUrl: 'app/views/common/dashboard-from-mixed-chart.html',
    link: function link(scope, element) {
      cov_1jg4skvuq1.s[2]++;

      element.on('resize', function (event) {
        cov_1jg4skvuq1.f[1]++;
      });
      /* scope.$on(
        '$destroy',
        () => {
          if (scope.pivotTableObj) {
            scope.pivotTableObj.destroy();
          }
          if (scope.chart) {
            scope.chart.destroy();
          }
        }
      ); */
    },

    controller: function controller($scope, $deltadate, $translate, $deltaLocation, $LOGINService, $filter, $CRUDService) {
      cov_1jg4skvuq1.f[2]++;

      var PATH = (cov_1jg4skvuq1.s[3]++, 'Formular');
      // Get Data
      var p_bksb_ = (cov_1jg4skvuq1.s[4]++, null);

      cov_1jg4skvuq1.s[5]++;
      $scope.requestFields = [];
      cov_1jg4skvuq1.s[6]++;
      $scope.displayPlan = false;
      cov_1jg4skvuq1.s[7]++;
      $scope.openPanel = function (action) {
        cov_1jg4skvuq1.f[3]++;
        cov_1jg4skvuq1.s[8]++;

        $scope.displayPlan = action;
      };
      cov_1jg4skvuq1.s[9]++;
      $scope.loading = true;
      cov_1jg4skvuq1.s[10]++;
      $scope.hasVariable = function () {
        cov_1jg4skvuq1.f[4]++;
        cov_1jg4skvuq1.s[11]++;

        if (!$scope.selectedRequest) {
          cov_1jg4skvuq1.b[0][0]++;
          cov_1jg4skvuq1.s[12]++;

          return false;
        } else {
          cov_1jg4skvuq1.b[0][1]++;
        }
        cov_1jg4skvuq1.s[13]++;
        if (!$scope.selectedRequest.COND) {
          cov_1jg4skvuq1.b[1][0]++;
          cov_1jg4skvuq1.s[14]++;

          return false;
        } else {
          cov_1jg4skvuq1.b[1][1]++;
        }

        cov_1jg4skvuq1.s[15]++;
        if (!angular.isArray($scope.selectedRequest.COND)) {
          cov_1jg4skvuq1.b[2][0]++;
          cov_1jg4skvuq1.s[16]++;

          return false;
        } else {
          cov_1jg4skvuq1.b[2][1]++;
        }

        var find = (cov_1jg4skvuq1.s[17]++, $scope.selectedRequest.COND.find(function (value) {
          cov_1jg4skvuq1.f[5]++;
          cov_1jg4skvuq1.s[18]++;

          return value.t == 1;
        }));

        cov_1jg4skvuq1.s[19]++;
        return Boolean(find);
      };
      cov_1jg4skvuq1.s[20]++;
      $scope.drawChart = function () {
        cov_1jg4skvuq1.f[6]++;

        var params = (cov_1jg4skvuq1.s[21]++, { withData: 1, export: 1, page: 0, id: $scope.selectedRequest.ID, p_bksb_: p_bksb_, id_request: $scope.idForm, paging: 1, get: 'all_r_graph', sort: $filter('json')([]) });
        cov_1jg4skvuq1.s[22]++;
        if ($scope.structureItems.length > 0) {
          cov_1jg4skvuq1.b[3][0]++;
          cov_1jg4skvuq1.s[23]++;

          params.id_request = undefined;
          cov_1jg4skvuq1.s[24]++;
          params.request = { COND: $scope.selectedRequest.COND, ENCHAINE: $scope.selectedRequest.ENCHAINE, ID: $scope.selectedRequest.ID, CHART: $scope.selectedRequest.CHART };
        } else {
          cov_1jg4skvuq1.b[3][1]++;
        }
        cov_1jg4skvuq1.s[25]++;
        $scope.loading = true;
        cov_1jg4skvuq1.s[26]++;
        $CRUDService.getAll(PATH, params, function (data) {
          cov_1jg4skvuq1.f[7]++;
          cov_1jg4skvuq1.s[27]++;

          $scope.loading = false;
          cov_1jg4skvuq1.s[28]++;
          if (!angular.isArray(data)) {
            cov_1jg4skvuq1.b[4][0]++;
            cov_1jg4skvuq1.s[29]++;

            return;
          } else {
            cov_1jg4skvuq1.b[4][1]++;
          }
          cov_1jg4skvuq1.s[30]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var _item = _step.value;
              cov_1jg4skvuq1.s[31]++;
              var _iteratorNormalCompletion3 = true;
              var _didIteratorError3 = false;
              var _iteratorError3 = undefined;

              try {
                for (var _iterator3 = $scope.selectedRequest.CHART.SERIES[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                  var serie = _step3.value;
                  cov_1jg4skvuq1.s[32]++;

                  _item[serie.id] = (cov_1jg4skvuq1.b[5][0]++, parseFloat(_item[serie.id])) || (cov_1jg4skvuq1.b[5][1]++, null);
                }
              } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion3 && _iterator3.return) {
                    _iterator3.return();
                  }
                } finally {
                  if (_didIteratorError3) {
                    throw _iteratorError3;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          var categorie = (cov_1jg4skvuq1.s[33]++, (cov_1jg4skvuq1.b[6][0]++, $scope.requestFields.find(function (value) {
            cov_1jg4skvuq1.f[8]++;
            cov_1jg4skvuq1.s[34]++;

            return value.id == $scope.selectedRequest.CHART.C;
          })) || (cov_1jg4skvuq1.b[6][1]++, {}));
          var idCat = (cov_1jg4skvuq1.s[35]++, (cov_1jg4skvuq1.b[7][0]++, categorie.l) || (cov_1jg4skvuq1.b[7][1]++, '_'));
          cov_1jg4skvuq1.s[36]++;
          if (categorie.t == 3) {
            cov_1jg4skvuq1.b[8][0]++;
            cov_1jg4skvuq1.s[37]++;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var item = _step2.value;
                cov_1jg4skvuq1.s[38]++;

                item[categorie.id] = $filter('date')(item[categorie.id]);
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          } else {
            cov_1jg4skvuq1.b[8][1]++;
          }

          cov_1jg4skvuq1.s[39]++;
          $scope.params = {
            useGroupingSeparator: true,
            primaryXAxis: {
              title: idCat,
              valueType: 'Category',
              edgeLabelPlacement: 'Shift',
              majorGridLines: { width: 0 }
            },
            primaryYAxis: {
              rangePadding: 'None',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 },
              minorTickLines: { width: 0 },
              valueType: 'Double'
            },
            series: $scope.selectedRequest.CHART.SERIES.map(function (value) {
              cov_1jg4skvuq1.f[9]++;

              var serie = (cov_1jg4skvuq1.s[40]++, {
                type: value.T == 1 ? (cov_1jg4skvuq1.b[9][0]++, 'Line') : (cov_1jg4skvuq1.b[9][1]++, 'Column'), dataSource: data,
                xName: (cov_1jg4skvuq1.b[10][0]++, $scope.selectedRequest.CHART.SB) || (cov_1jg4skvuq1.b[10][1]++, $scope.selectedRequest.CHART.C), yName: value.id, name: value.L,
                width: 2, opacity: 0.6
              });
              cov_1jg4skvuq1.s[41]++;
              if (value.T == 1) {
                cov_1jg4skvuq1.b[11][0]++;
                cov_1jg4skvuq1.s[42]++;

                serie.marker = {
                  visible: true, width: 5, height: 5
                };
              } else {
                cov_1jg4skvuq1.b[11][1]++;
              }
              cov_1jg4skvuq1.s[43]++;
              return serie;
            }),
            zoomSettings: {
              enableMouseWheelZooming: true,
              enablePinchZooming: true,
              enableSelectionZooming: true
            },
            tooltip: {
              enable: true
            },
            palettes: $scope.selectedRequest.CHART.SERIES.map(function (value) {
              cov_1jg4skvuq1.f[10]++;
              cov_1jg4skvuq1.s[44]++;

              return 'rgb(' + value.C + ')';
            }),
            // width: ($('content_' + $scope.idcomponent).width() - 10) + 'px',
            height: '100%'
          };
        });
      };
      cov_1jg4skvuq1.s[45]++;
      $scope.changeParams = function () {
        cov_1jg4skvuq1.f[11]++;
        cov_1jg4skvuq1.s[46]++;
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = $scope.selectedRequest.COND[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var filter = _step4.value;
            cov_1jg4skvuq1.s[47]++;

            if (!filter.id) {
              cov_1jg4skvuq1.b[12][0]++;
              cov_1jg4skvuq1.s[48]++;

              continue;
            } else {
              cov_1jg4skvuq1.b[12][1]++;
            }
            cov_1jg4skvuq1.s[49]++;
            if (angular.isUndefined($scope.changeValue[filter.id])) {
              cov_1jg4skvuq1.b[13][0]++;
              cov_1jg4skvuq1.s[50]++;

              continue;
            } else {
              cov_1jg4skvuq1.b[13][1]++;
            }
            cov_1jg4skvuq1.s[51]++;
            filter.v = $scope.changeValue[filter.id];
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        cov_1jg4skvuq1.s[52]++;
        $scope.displayPlan = false;
        cov_1jg4skvuq1.s[53]++;
        $scope.drawChart();
      };
      cov_1jg4skvuq1.s[54]++;
      $CRUDService.getAll(PATH, { get: 'one_request_by_id', id: $scope.idForm }, function (data) {
        cov_1jg4skvuq1.f[12]++;
        cov_1jg4skvuq1.s[55]++;

        $scope.selectedRequest = data;
        cov_1jg4skvuq1.s[56]++;
        $scope.structureItems = [];
        cov_1jg4skvuq1.s[57]++;
        $scope.changeValue = {};
        cov_1jg4skvuq1.s[58]++;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = $scope.selectedRequest.COND[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var item = _step5.value;
            cov_1jg4skvuq1.s[59]++;

            if (item.t != 1) {
              cov_1jg4skvuq1.b[14][0]++;
              cov_1jg4skvuq1.s[60]++;

              continue;
            } else {
              cov_1jg4skvuq1.b[14][1]++;
            }
            cov_1jg4skvuq1.s[61]++;
            $scope.structureItems.push(item);
            cov_1jg4skvuq1.s[62]++;
            if (!$scope.changeValue[item.id]) {
              cov_1jg4skvuq1.b[15][0]++;
              cov_1jg4skvuq1.s[63]++;

              $scope.changeValue[item.id] = (cov_1jg4skvuq1.b[16][0]++, item.v) || (cov_1jg4skvuq1.b[16][1]++, item.ty == 3 ? (cov_1jg4skvuq1.b[17][0]++, Date.newDate()) : (cov_1jg4skvuq1.b[17][1]++, item.ty == 4 ? (cov_1jg4skvuq1.b[18][0]++, 0) : (cov_1jg4skvuq1.b[18][1]++, '')));
            } else {
              cov_1jg4skvuq1.b[15][1]++;
            }
          }
          // structureItems
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cov_1jg4skvuq1.s[64]++;
        p_bksb_ = $scope.selectedRequest.ID_PROJET;
        cov_1jg4skvuq1.s[65]++;
        $CRUDService.getAll(PATH, { get: 'all_filter_fields', id: $scope.selectedRequest.ID }, function (data_) {
          cov_1jg4skvuq1.f[13]++;
          cov_1jg4skvuq1.s[66]++;

          $scope.requestFields = data_;
          cov_1jg4skvuq1.s[67]++;
          $scope.drawChart($scope.selectedRequest.ID);
        });
      });
    }
  };
});
'use strict';

var cov_1jcbouc283 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/releve/indicatorReleve.js',
      hash = '8b802833c26c0e8c244c2090629bdb8a59796222',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/releve/indicatorReleve.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 218,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 17
        },
        end: {
          line: 2,
          column: 34
        }
      },
      '2': {
        start: {
          line: 3,
          column: 20
        },
        end: {
          line: 3,
          column: 36
        }
      },
      '3': {
        start: {
          line: 4,
          column: 19
        },
        end: {
          line: 4,
          column: 67
        }
      },
      '4': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 8,
          column: 4
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '6': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 33
        }
      },
      '7': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 31
        }
      },
      '8': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 37
        }
      },
      '9': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 15,
          column: 5
        }
      },
      '10': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 13
        }
      },
      '11': {
        start: {
          line: 16,
          column: 17
        },
        end: {
          line: 16,
          column: 54
        }
      },
      '12': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '13': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 13
        }
      },
      '14': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 93
        }
      },
      '15': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 68
        }
      },
      '16': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 22,
          column: 30
        }
      },
      '17': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 69,
          column: 4
        }
      },
      '18': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 25,
          column: 27
        }
      },
      '19': {
        start: {
          line: 26,
          column: 19
        },
        end: {
          line: 26,
          column: 55
        }
      },
      '20': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 29,
          column: 5
        }
      },
      '21': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 13
        }
      },
      '22': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 30,
          column: 33
        }
      },
      '23': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 28
        }
      },
      '24': {
        start: {
          line: 32,
          column: 4
        },
        end: {
          line: 68,
          column: 7
        }
      },
      '25': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 65,
          column: 7
        }
      },
      '26': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '27': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 45
        }
      },
      '28': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 44
        }
      },
      '29': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 52
        }
      },
      '30': {
        start: {
          line: 39,
          column: 22
        },
        end: {
          line: 39,
          column: 42
        }
      },
      '31': {
        start: {
          line: 40,
          column: 22
        },
        end: {
          line: 40,
          column: 42
        }
      },
      '32': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 44,
          column: 9
        }
      },
      '33': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 50
        }
      },
      '34': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 48
        }
      },
      '35': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '36': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 46,
          column: 47
        }
      },
      '37': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 47
        }
      },
      '38': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 52,
          column: 9
        }
      },
      '39': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 47
        }
      },
      '40': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 47
        }
      },
      '41': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 98
        }
      },
      '42': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 77
        }
      },
      '43': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 37
        }
      },
      '44': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '45': {
        start: {
          line: 57,
          column: 23
        },
        end: {
          line: 57,
          column: 46
        }
      },
      '46': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 63,
          column: 11
        }
      },
      '47': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 62,
          column: 13
        }
      },
      '48': {
        start: {
          line: 60,
          column: 14
        },
        end: {
          line: 60,
          column: 40
        }
      },
      '49': {
        start: {
          line: 61,
          column: 14
        },
        end: {
          line: 61,
          column: 20
        }
      },
      '50': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 37
        }
      },
      '51': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 31
        }
      },
      '52': {
        start: {
          line: 70,
          column: 2
        },
        end: {
          line: 100,
          column: 4
        }
      },
      '53': {
        start: {
          line: 71,
          column: 4
        },
        end: {
          line: 71,
          column: 45
        }
      },
      '54': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 65
        }
      },
      '55': {
        start: {
          line: 73,
          column: 4
        },
        end: {
          line: 73,
          column: 57
        }
      },
      '56': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 74,
          column: 71
        }
      },
      '57': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 75,
          column: 67
        }
      },
      '58': {
        start: {
          line: 76,
          column: 18
        },
        end: {
          line: 76,
          column: 58
        }
      },
      '59': {
        start: {
          line: 77,
          column: 18
        },
        end: {
          line: 77,
          column: 58
        }
      },
      '60': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 81,
          column: 5
        }
      },
      '61': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 66
        }
      },
      '62': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 64
        }
      },
      '63': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 85,
          column: 5
        }
      },
      '64': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 63
        }
      },
      '65': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 84,
          column: 63
        }
      },
      '66': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 89,
          column: 5
        }
      },
      '67': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 63
        }
      },
      '68': {
        start: {
          line: 88,
          column: 6
        },
        end: {
          line: 88,
          column: 63
        }
      },
      '69': {
        start: {
          line: 90,
          column: 4
        },
        end: {
          line: 90,
          column: 53
        }
      },
      '70': {
        start: {
          line: 91,
          column: 4
        },
        end: {
          line: 99,
          column: 5
        }
      },
      '71': {
        start: {
          line: 92,
          column: 19
        },
        end: {
          line: 92,
          column: 42
        }
      },
      '72': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 98,
          column: 7
        }
      },
      '73': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 97,
          column: 9
        }
      },
      '74': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 95,
          column: 56
        }
      },
      '75': {
        start: {
          line: 96,
          column: 10
        },
        end: {
          line: 96,
          column: 16
        }
      },
      '76': {
        start: {
          line: 101,
          column: 2
        },
        end: {
          line: 126,
          column: 4
        }
      },
      '77': {
        start: {
          line: 102,
          column: 4
        },
        end: {
          line: 102,
          column: 46
        }
      },
      '78': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 103,
          column: 35
        }
      },
      '79': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 109,
          column: 5
        }
      },
      '80': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '81': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 37
        }
      },
      '82': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 14
        }
      },
      '83': {
        start: {
          line: 110,
          column: 4
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '84': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 39
        }
      },
      '85': {
        start: {
          line: 113,
          column: 4
        },
        end: {
          line: 115,
          column: 5
        }
      },
      '86': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 13
        }
      },
      '87': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 116,
          column: 31
        }
      },
      '88': {
        start: {
          line: 117,
          column: 4
        },
        end: {
          line: 117,
          column: 35
        }
      },
      '89': {
        start: {
          line: 118,
          column: 4
        },
        end: {
          line: 118,
          column: 37
        }
      },
      '90': {
        start: {
          line: 119,
          column: 4
        },
        end: {
          line: 119,
          column: 105
        }
      },
      '91': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 120,
          column: 31
        }
      },
      '92': {
        start: {
          line: 121,
          column: 4
        },
        end: {
          line: 121,
          column: 27
        }
      },
      '93': {
        start: {
          line: 122,
          column: 4
        },
        end: {
          line: 122,
          column: 33
        }
      },
      '94': {
        start: {
          line: 123,
          column: 4
        },
        end: {
          line: 123,
          column: 32
        }
      },
      '95': {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 124,
          column: 64
        }
      },
      '96': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 125,
          column: 28
        }
      },
      '97': {
        start: {
          line: 127,
          column: 2
        },
        end: {
          line: 186,
          column: 4
        }
      },
      '98': {
        start: {
          line: 128,
          column: 4
        },
        end: {
          line: 128,
          column: 39
        }
      },
      '99': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 33
        }
      },
      '100': {
        start: {
          line: 130,
          column: 23
        },
        end: {
          line: 130,
          column: 25
        }
      },
      '101': {
        start: {
          line: 131,
          column: 18
        },
        end: {
          line: 131,
          column: 35
        }
      },
      '102': {
        start: {
          line: 132,
          column: 4
        },
        end: {
          line: 136,
          column: 5
        }
      },
      '103': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 41
        }
      },
      '104': {
        start: {
          line: 134,
          column: 11
        },
        end: {
          line: 136,
          column: 5
        }
      },
      '105': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 135,
          column: 40
        }
      },
      '106': {
        start: {
          line: 137,
          column: 19
        },
        end: {
          line: 137,
          column: 55
        }
      },
      '107': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 140,
          column: 5
        }
      },
      '108': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 13
        }
      },
      '109': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 145,
          column: 7
        }
      },
      '110': {
        start: {
          line: 146,
          column: 4
        },
        end: {
          line: 158,
          column: 5
        }
      },
      '111': {
        start: {
          line: 147,
          column: 17
        },
        end: {
          line: 147,
          column: 22
        }
      },
      '112': {
        start: {
          line: 148,
          column: 6
        },
        end: {
          line: 154,
          column: 7
        }
      },
      '113': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 153,
          column: 9
        }
      },
      '114': {
        start: {
          line: 150,
          column: 10
        },
        end: {
          line: 150,
          column: 22
        }
      },
      '115': {
        start: {
          line: 151,
          column: 10
        },
        end: {
          line: 151,
          column: 39
        }
      },
      '116': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 152,
          column: 16
        }
      },
      '117': {
        start: {
          line: 155,
          column: 6
        },
        end: {
          line: 157,
          column: 7
        }
      },
      '118': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 156,
          column: 63
        }
      },
      '119': {
        start: {
          line: 159,
          column: 4
        },
        end: {
          line: 172,
          column: 5
        }
      },
      '120': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 24
        }
      },
      '121': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 168,
          column: 7
        }
      },
      '122': {
        start: {
          line: 162,
          column: 19
        },
        end: {
          line: 162,
          column: 24
        }
      },
      '123': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 167,
          column: 9
        }
      },
      '124': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 39
        }
      },
      '125': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 165,
          column: 22
        }
      },
      '126': {
        start: {
          line: 166,
          column: 10
        },
        end: {
          line: 166,
          column: 16
        }
      },
      '127': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 171,
          column: 7
        }
      },
      '128': {
        start: {
          line: 170,
          column: 8
        },
        end: {
          line: 170,
          column: 67
        }
      },
      '129': {
        start: {
          line: 173,
          column: 4
        },
        end: {
          line: 176,
          column: 5
        }
      },
      '130': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 174,
          column: 70
        }
      },
      '131': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 175,
          column: 24
        }
      },
      '132': {
        start: {
          line: 177,
          column: 4
        },
        end: {
          line: 177,
          column: 43
        }
      },
      '133': {
        start: {
          line: 178,
          column: 4
        },
        end: {
          line: 185,
          column: 7
        }
      },
      '134': {
        start: {
          line: 188,
          column: 2
        },
        end: {
          line: 212,
          column: 4
        }
      },
      '135': {
        start: {
          line: 189,
          column: 4
        },
        end: {
          line: 211,
          column: 7
        }
      },
      '136': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 203,
          column: 7
        }
      },
      '137': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 15
        }
      },
      '138': {
        start: {
          line: 204,
          column: 6
        },
        end: {
          line: 204,
          column: 31
        }
      },
      '139': {
        start: {
          line: 205,
          column: 6
        },
        end: {
          line: 205,
          column: 32
        }
      },
      '140': {
        start: {
          line: 206,
          column: 6
        },
        end: {
          line: 206,
          column: 31
        }
      },
      '141': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 25
        }
      },
      '142': {
        start: {
          line: 208,
          column: 23
        },
        end: {
          line: 208,
          column: 41
        }
      },
      '143': {
        start: {
          line: 209,
          column: 6
        },
        end: {
          line: 209,
          column: 99
        }
      },
      '144': {
        start: {
          line: 210,
          column: 6
        },
        end: {
          line: 210,
          column: 32
        }
      },
      '145': {
        start: {
          line: 213,
          column: 2
        },
        end: {
          line: 217,
          column: 4
        }
      },
      '146': {
        start: {
          line: 214,
          column: 4
        },
        end: {
          line: 214,
          column: 22
        }
      },
      '147': {
        start: {
          line: 215,
          column: 4
        },
        end: {
          line: 215,
          column: 33
        }
      },
      '148': {
        start: {
          line: 216,
          column: 4
        },
        end: {
          line: 216,
          column: 46
        }
      },
      '149': {
        start: {
          line: 221,
          column: 20
        },
        end: {
          line: 221,
          column: 36
        }
      },
      '150': {
        start: {
          line: 222,
          column: 19
        },
        end: {
          line: 222,
          column: 67
        }
      },
      '151': {
        start: {
          line: 223,
          column: 2
        },
        end: {
          line: 249,
          column: 4
        }
      },
      '152': {
        start: {
          line: 224,
          column: 4
        },
        end: {
          line: 246,
          column: 5
        }
      },
      '153': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 228,
          column: 9
        }
      },
      '154': {
        start: {
          line: 227,
          column: 10
        },
        end: {
          line: 227,
          column: 17
        }
      },
      '155': {
        start: {
          line: 229,
          column: 8
        },
        end: {
          line: 229,
          column: 33
        }
      },
      '156': {
        start: {
          line: 230,
          column: 8
        },
        end: {
          line: 230,
          column: 35
        }
      },
      '157': {
        start: {
          line: 231,
          column: 8
        },
        end: {
          line: 231,
          column: 40
        }
      },
      '158': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 232,
          column: 14
        }
      },
      '159': {
        start: {
          line: 234,
          column: 8
        },
        end: {
          line: 236,
          column: 9
        }
      },
      '160': {
        start: {
          line: 235,
          column: 10
        },
        end: {
          line: 235,
          column: 17
        }
      },
      '161': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 237,
          column: 35
        }
      },
      '162': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 238,
          column: 39
        }
      },
      '163': {
        start: {
          line: 239,
          column: 8
        },
        end: {
          line: 239,
          column: 34
        }
      },
      '164': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 240,
          column: 14
        }
      },
      '165': {
        start: {
          line: 242,
          column: 8
        },
        end: {
          line: 242,
          column: 72
        }
      },
      '166': {
        start: {
          line: 243,
          column: 8
        },
        end: {
          line: 243,
          column: 14
        }
      },
      '167': {
        start: {
          line: 245,
          column: 8
        },
        end: {
          line: 245,
          column: 39
        }
      },
      '168': {
        start: {
          line: 247,
          column: 4
        },
        end: {
          line: 247,
          column: 37
        }
      },
      '169': {
        start: {
          line: 248,
          column: 4
        },
        end: {
          line: 248,
          column: 16
        }
      },
      '170': {
        start: {
          line: 250,
          column: 2
        },
        end: {
          line: 255,
          column: 4
        }
      },
      '171': {
        start: {
          line: 251,
          column: 4
        },
        end: {
          line: 253,
          column: 5
        }
      },
      '172': {
        start: {
          line: 252,
          column: 6
        },
        end: {
          line: 252,
          column: 49
        }
      },
      '173': {
        start: {
          line: 254,
          column: 4
        },
        end: {
          line: 254,
          column: 16
        }
      },
      '174': {
        start: {
          line: 256,
          column: 2
        },
        end: {
          line: 290,
          column: 4
        }
      },
      '175': {
        start: {
          line: 257,
          column: 21
        },
        end: {
          line: 257,
          column: 62
        }
      },
      '176': {
        start: {
          line: 258,
          column: 4
        },
        end: {
          line: 261,
          column: 5
        }
      },
      '177': {
        start: {
          line: 259,
          column: 6
        },
        end: {
          line: 259,
          column: 54
        }
      },
      '178': {
        start: {
          line: 260,
          column: 6
        },
        end: {
          line: 260,
          column: 13
        }
      },
      '179': {
        start: {
          line: 262,
          column: 21
        },
        end: {
          line: 262,
          column: 100
        }
      },
      '180': {
        start: {
          line: 263,
          column: 4
        },
        end: {
          line: 263,
          column: 36
        }
      },
      '181': {
        start: {
          line: 264,
          column: 4
        },
        end: {
          line: 264,
          column: 37
        }
      },
      '182': {
        start: {
          line: 265,
          column: 4
        },
        end: {
          line: 288,
          column: 7
        }
      },
      '183': {
        start: {
          line: 266,
          column: 18
        },
        end: {
          line: 266,
          column: 20
        }
      },
      '184': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 281,
          column: 7
        }
      },
      '185': {
        start: {
          line: 268,
          column: 8
        },
        end: {
          line: 270,
          column: 9
        }
      },
      '186': {
        start: {
          line: 269,
          column: 10
        },
        end: {
          line: 269,
          column: 63
        }
      },
      '187': {
        start: {
          line: 271,
          column: 13
        },
        end: {
          line: 281,
          column: 7
        }
      },
      '188': {
        start: {
          line: 272,
          column: 8
        },
        end: {
          line: 274,
          column: 9
        }
      },
      '189': {
        start: {
          line: 273,
          column: 10
        },
        end: {
          line: 273,
          column: 78
        }
      },
      '190': {
        start: {
          line: 275,
          column: 8
        },
        end: {
          line: 277,
          column: 9
        }
      },
      '191': {
        start: {
          line: 276,
          column: 10
        },
        end: {
          line: 276,
          column: 79
        }
      },
      '192': {
        start: {
          line: 278,
          column: 8
        },
        end: {
          line: 280,
          column: 9
        }
      },
      '193': {
        start: {
          line: 279,
          column: 10
        },
        end: {
          line: 279,
          column: 158
        }
      },
      '194': {
        start: {
          line: 282,
          column: 6
        },
        end: {
          line: 285,
          column: 7
        }
      },
      '195': {
        start: {
          line: 283,
          column: 8
        },
        end: {
          line: 283,
          column: 33
        }
      },
      '196': {
        start: {
          line: 284,
          column: 8
        },
        end: {
          line: 284,
          column: 15
        }
      },
      '197': {
        start: {
          line: 286,
          column: 6
        },
        end: {
          line: 286,
          column: 25
        }
      },
      '198': {
        start: {
          line: 287,
          column: 6
        },
        end: {
          line: 287,
          column: 36
        }
      },
      '199': {
        start: {
          line: 289,
          column: 4
        },
        end: {
          line: 289,
          column: 40
        }
      },
      '200': {
        start: {
          line: 291,
          column: 2
        },
        end: {
          line: 293,
          column: 4
        }
      },
      '201': {
        start: {
          line: 292,
          column: 4
        },
        end: {
          line: 292,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 199
          },
          end: {
            line: 218,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 9,
            column: 26
          },
          end: {
            line: 9,
            column: 27
          }
        },
        loc: {
          start: {
            line: 9,
            column: 38
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 24,
            column: 28
          },
          end: {
            line: 24,
            column: 29
          }
        },
        loc: {
          start: {
            line: 24,
            column: 40
          },
          end: {
            line: 69,
            column: 3
          }
        },
        line: 24
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 32,
            column: 294
          },
          end: {
            line: 32,
            column: 295
          }
        },
        loc: {
          start: {
            line: 32,
            column: 302
          },
          end: {
            line: 68,
            column: 5
          }
        },
        line: 32
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 70,
            column: 24
          },
          end: {
            line: 70,
            column: 25
          }
        },
        loc: {
          start: {
            line: 70,
            column: 40
          },
          end: {
            line: 100,
            column: 3
          }
        },
        line: 70
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 101,
            column: 35
          },
          end: {
            line: 101,
            column: 36
          }
        },
        loc: {
          start: {
            line: 101,
            column: 51
          },
          end: {
            line: 126,
            column: 3
          }
        },
        line: 101
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 110,
            column: 62
          },
          end: {
            line: 110,
            column: 63
          }
        },
        loc: {
          start: {
            line: 110,
            column: 70
          },
          end: {
            line: 112,
            column: 5
          }
        },
        line: 110
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 127,
            column: 22
          },
          end: {
            line: 127,
            column: 23
          }
        },
        loc: {
          start: {
            line: 127,
            column: 38
          },
          end: {
            line: 186,
            column: 3
          }
        },
        line: 127
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 188,
            column: 31
          },
          end: {
            line: 188,
            column: 32
          }
        },
        loc: {
          start: {
            line: 188,
            column: 47
          },
          end: {
            line: 212,
            column: 3
          }
        },
        line: 188
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 200,
            column: 7
          },
          end: {
            line: 200,
            column: 8
          }
        },
        loc: {
          start: {
            line: 200,
            column: 20
          },
          end: {
            line: 211,
            column: 5
          }
        },
        line: 200
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 213,
            column: 31
          },
          end: {
            line: 213,
            column: 32
          }
        },
        loc: {
          start: {
            line: 213,
            column: 47
          },
          end: {
            line: 217,
            column: 3
          }
        },
        line: 213
      },
      '11': {
        name: 'ReleveIndicateurCtrl_edit',
        decl: {
          start: {
            line: 220,
            column: 9
          },
          end: {
            line: 220,
            column: 34
          }
        },
        loc: {
          start: {
            line: 220,
            column: 121
          },
          end: {
            line: 294,
            column: 1
          }
        },
        line: 220
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 223,
            column: 23
          },
          end: {
            line: 223,
            column: 24
          }
        },
        loc: {
          start: {
            line: 223,
            column: 46
          },
          end: {
            line: 249,
            column: 3
          }
        },
        line: 223
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 250,
            column: 28
          },
          end: {
            line: 250,
            column: 29
          }
        },
        loc: {
          start: {
            line: 250,
            column: 44
          },
          end: {
            line: 255,
            column: 3
          }
        },
        line: 250
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 256,
            column: 26
          },
          end: {
            line: 256,
            column: 27
          }
        },
        loc: {
          start: {
            line: 256,
            column: 42
          },
          end: {
            line: 290,
            column: 3
          }
        },
        line: 256
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 265,
            column: 112
          },
          end: {
            line: 265,
            column: 113
          }
        },
        loc: {
          start: {
            line: 265,
            column: 120
          },
          end: {
            line: 288,
            column: 5
          }
        },
        line: 265
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 291,
            column: 22
          },
          end: {
            line: 291,
            column: 23
          }
        },
        loc: {
          start: {
            line: 291,
            column: 34
          },
          end: {
            line: 293,
            column: 3
          }
        },
        line: 291
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        }, {
          start: {
            line: 13,
            column: 4
          },
          end: {
            line: 15,
            column: 5
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 17,
            column: 4
          },
          end: {
            line: 19,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 4
          },
          end: {
            line: 19,
            column: 5
          }
        }, {
          start: {
            line: 17,
            column: 4
          },
          end: {
            line: 19,
            column: 5
          }
        }],
        line: 17
      },
      '2': {
        loc: {
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 29,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 29,
            column: 5
          }
        }, {
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 29,
            column: 5
          }
        }],
        line: 27
      },
      '3': {
        loc: {
          start: {
            line: 32,
            column: 50
          },
          end: {
            line: 32,
            column: 121
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 32,
            column: 71
          },
          end: {
            line: 32,
            column: 91
          }
        }, {
          start: {
            line: 32,
            column: 94
          },
          end: {
            line: 32,
            column: 121
          }
        }],
        line: 32
      },
      '4': {
        loc: {
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }, {
          start: {
            line: 34,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }],
        line: 34
      },
      '5': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }, {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 44,
            column: 9
          }
        }],
        line: 41
      },
      '6': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }, {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }],
        line: 45
      },
      '7': {
        loc: {
          start: {
            line: 49,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        }, {
          start: {
            line: 49,
            column: 8
          },
          end: {
            line: 52,
            column: 9
          }
        }],
        line: 49
      },
      '8': {
        loc: {
          start: {
            line: 49,
            column: 12
          },
          end: {
            line: 49,
            column: 96
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 49,
            column: 12
          },
          end: {
            line: 49,
            column: 52
          }
        }, {
          start: {
            line: 49,
            column: 56
          },
          end: {
            line: 49,
            column: 96
          }
        }],
        line: 49
      },
      '9': {
        loc: {
          start: {
            line: 53,
            column: 26
          },
          end: {
            line: 53,
            column: 97
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 53,
            column: 47
          },
          end: {
            line: 53,
            column: 67
          }
        }, {
          start: {
            line: 53,
            column: 70
          },
          end: {
            line: 53,
            column: 97
          }
        }],
        line: 53
      },
      '10': {
        loc: {
          start: {
            line: 54,
            column: 28
          },
          end: {
            line: 54,
            column: 76
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 54,
            column: 54
          },
          end: {
            line: 54,
            column: 71
          }
        }, {
          start: {
            line: 54,
            column: 74
          },
          end: {
            line: 54,
            column: 76
          }
        }],
        line: 54
      },
      '11': {
        loc: {
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 64,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 64,
            column: 9
          }
        }, {
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 64,
            column: 9
          }
        }],
        line: 56
      },
      '12': {
        loc: {
          start: {
            line: 59,
            column: 12
          },
          end: {
            line: 62,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 12
          },
          end: {
            line: 62,
            column: 13
          }
        }, {
          start: {
            line: 59,
            column: 12
          },
          end: {
            line: 62,
            column: 13
          }
        }],
        line: 59
      },
      '13': {
        loc: {
          start: {
            line: 59,
            column: 16
          },
          end: {
            line: 59,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 16
          },
          end: {
            line: 59,
            column: 32
          }
        }, {
          start: {
            line: 59,
            column: 36
          },
          end: {
            line: 59,
            column: 52
          }
        }],
        line: 59
      },
      '14': {
        loc: {
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }, {
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }],
        line: 78
      },
      '15': {
        loc: {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 85,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 85,
            column: 5
          }
        }, {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 85,
            column: 5
          }
        }],
        line: 82
      },
      '16': {
        loc: {
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 89,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 89,
            column: 5
          }
        }, {
          start: {
            line: 86,
            column: 4
          },
          end: {
            line: 89,
            column: 5
          }
        }],
        line: 86
      },
      '17': {
        loc: {
          start: {
            line: 86,
            column: 8
          },
          end: {
            line: 86,
            column: 92
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 86,
            column: 8
          },
          end: {
            line: 86,
            column: 48
          }
        }, {
          start: {
            line: 86,
            column: 52
          },
          end: {
            line: 86,
            column: 92
          }
        }],
        line: 86
      },
      '18': {
        loc: {
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 99,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 99,
            column: 5
          }
        }, {
          start: {
            line: 91,
            column: 4
          },
          end: {
            line: 99,
            column: 5
          }
        }],
        line: 91
      },
      '19': {
        loc: {
          start: {
            line: 94,
            column: 8
          },
          end: {
            line: 97,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 8
          },
          end: {
            line: 97,
            column: 9
          }
        }, {
          start: {
            line: 94,
            column: 8
          },
          end: {
            line: 97,
            column: 9
          }
        }],
        line: 94
      },
      '20': {
        loc: {
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 94,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 94,
            column: 28
          }
        }, {
          start: {
            line: 94,
            column: 32
          },
          end: {
            line: 94,
            column: 48
          }
        }],
        line: 94
      },
      '21': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 105
      },
      '22': {
        loc: {
          start: {
            line: 113,
            column: 4
          },
          end: {
            line: 115,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 113,
            column: 4
          },
          end: {
            line: 115,
            column: 5
          }
        }, {
          start: {
            line: 113,
            column: 4
          },
          end: {
            line: 115,
            column: 5
          }
        }],
        line: 113
      },
      '23': {
        loc: {
          start: {
            line: 132,
            column: 4
          },
          end: {
            line: 136,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 4
          },
          end: {
            line: 136,
            column: 5
          }
        }, {
          start: {
            line: 132,
            column: 4
          },
          end: {
            line: 136,
            column: 5
          }
        }],
        line: 132
      },
      '24': {
        loc: {
          start: {
            line: 134,
            column: 11
          },
          end: {
            line: 136,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 11
          },
          end: {
            line: 136,
            column: 5
          }
        }, {
          start: {
            line: 134,
            column: 11
          },
          end: {
            line: 136,
            column: 5
          }
        }],
        line: 134
      },
      '25': {
        loc: {
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        }, {
          start: {
            line: 138,
            column: 4
          },
          end: {
            line: 140,
            column: 5
          }
        }],
        line: 138
      },
      '26': {
        loc: {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 158,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 158,
            column: 5
          }
        }, {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 158,
            column: 5
          }
        }],
        line: 146
      },
      '27': {
        loc: {
          start: {
            line: 149,
            column: 8
          },
          end: {
            line: 153,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 149,
            column: 8
          },
          end: {
            line: 153,
            column: 9
          }
        }, {
          start: {
            line: 149,
            column: 8
          },
          end: {
            line: 153,
            column: 9
          }
        }],
        line: 149
      },
      '28': {
        loc: {
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        }, {
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        }],
        line: 155
      },
      '29': {
        loc: {
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        }, {
          start: {
            line: 159,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        }],
        line: 159
      },
      '30': {
        loc: {
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 167,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 167,
            column: 9
          }
        }, {
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 167,
            column: 9
          }
        }],
        line: 163
      },
      '31': {
        loc: {
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        }, {
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 171,
            column: 7
          }
        }],
        line: 169
      },
      '32': {
        loc: {
          start: {
            line: 173,
            column: 4
          },
          end: {
            line: 176,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 173,
            column: 4
          },
          end: {
            line: 176,
            column: 5
          }
        }, {
          start: {
            line: 173,
            column: 4
          },
          end: {
            line: 176,
            column: 5
          }
        }],
        line: 173
      },
      '33': {
        loc: {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }, {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }],
        line: 201
      },
      '34': {
        loc: {
          start: {
            line: 224,
            column: 4
          },
          end: {
            line: 246,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 225,
            column: 6
          },
          end: {
            line: 232,
            column: 14
          }
        }, {
          start: {
            line: 233,
            column: 6
          },
          end: {
            line: 240,
            column: 14
          }
        }, {
          start: {
            line: 241,
            column: 6
          },
          end: {
            line: 243,
            column: 14
          }
        }, {
          start: {
            line: 244,
            column: 6
          },
          end: {
            line: 245,
            column: 39
          }
        }],
        line: 224
      },
      '35': {
        loc: {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        }, {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        }],
        line: 226
      },
      '36': {
        loc: {
          start: {
            line: 234,
            column: 8
          },
          end: {
            line: 236,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 234,
            column: 8
          },
          end: {
            line: 236,
            column: 9
          }
        }, {
          start: {
            line: 234,
            column: 8
          },
          end: {
            line: 236,
            column: 9
          }
        }],
        line: 234
      },
      '37': {
        loc: {
          start: {
            line: 251,
            column: 4
          },
          end: {
            line: 253,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 251,
            column: 4
          },
          end: {
            line: 253,
            column: 5
          }
        }, {
          start: {
            line: 251,
            column: 4
          },
          end: {
            line: 253,
            column: 5
          }
        }],
        line: 251
      },
      '38': {
        loc: {
          start: {
            line: 251,
            column: 8
          },
          end: {
            line: 251,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 251,
            column: 8
          },
          end: {
            line: 251,
            column: 19
          }
        }, {
          start: {
            line: 251,
            column: 23
          },
          end: {
            line: 251,
            column: 39
          }
        }],
        line: 251
      },
      '39': {
        loc: {
          start: {
            line: 258,
            column: 4
          },
          end: {
            line: 261,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 258,
            column: 4
          },
          end: {
            line: 261,
            column: 5
          }
        }, {
          start: {
            line: 258,
            column: 4
          },
          end: {
            line: 261,
            column: 5
          }
        }],
        line: 258
      },
      '40': {
        loc: {
          start: {
            line: 267,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 267,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        }, {
          start: {
            line: 267,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        }],
        line: 267
      },
      '41': {
        loc: {
          start: {
            line: 268,
            column: 8
          },
          end: {
            line: 270,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 268,
            column: 8
          },
          end: {
            line: 270,
            column: 9
          }
        }, {
          start: {
            line: 268,
            column: 8
          },
          end: {
            line: 270,
            column: 9
          }
        }],
        line: 268
      },
      '42': {
        loc: {
          start: {
            line: 271,
            column: 13
          },
          end: {
            line: 281,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 13
          },
          end: {
            line: 281,
            column: 7
          }
        }, {
          start: {
            line: 271,
            column: 13
          },
          end: {
            line: 281,
            column: 7
          }
        }],
        line: 271
      },
      '43': {
        loc: {
          start: {
            line: 272,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 272,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }, {
          start: {
            line: 272,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }],
        line: 272
      },
      '44': {
        loc: {
          start: {
            line: 272,
            column: 12
          },
          end: {
            line: 272,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 272,
            column: 12
          },
          end: {
            line: 272,
            column: 27
          }
        }, {
          start: {
            line: 272,
            column: 31
          },
          end: {
            line: 272,
            column: 46
          }
        }],
        line: 272
      },
      '45': {
        loc: {
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 277,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 277,
            column: 9
          }
        }, {
          start: {
            line: 275,
            column: 8
          },
          end: {
            line: 277,
            column: 9
          }
        }],
        line: 275
      },
      '46': {
        loc: {
          start: {
            line: 275,
            column: 12
          },
          end: {
            line: 275,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 275,
            column: 12
          },
          end: {
            line: 275,
            column: 27
          }
        }, {
          start: {
            line: 275,
            column: 31
          },
          end: {
            line: 275,
            column: 46
          }
        }],
        line: 275
      },
      '47': {
        loc: {
          start: {
            line: 278,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 278,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        }, {
          start: {
            line: 278,
            column: 8
          },
          end: {
            line: 280,
            column: 9
          }
        }],
        line: 278
      },
      '48': {
        loc: {
          start: {
            line: 278,
            column: 12
          },
          end: {
            line: 278,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 278,
            column: 12
          },
          end: {
            line: 278,
            column: 27
          }
        }, {
          start: {
            line: 278,
            column: 31
          },
          end: {
            line: 278,
            column: 46
          }
        }],
        line: 278
      },
      '49': {
        loc: {
          start: {
            line: 279,
            column: 37
          },
          end: {
            line: 279,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 279,
            column: 56
          },
          end: {
            line: 279,
            column: 75
          }
        }, {
          start: {
            line: 279,
            column: 78
          },
          end: {
            line: 279,
            column: 98
          }
        }],
        line: 279
      },
      '50': {
        loc: {
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 285,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 285,
            column: 7
          }
        }, {
          start: {
            line: 282,
            column: 6
          },
          end: {
            line: 285,
            column: 7
          }
        }],
        line: 282
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0, 0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1jcbouc283.s[0]++;
angular.module('app').controller('indicatorReleveCtrl', function ($scope, $deltahttp, $deltaChampPerso, $deltaActor, $rootScope, $translate, $CRUDService, $log, $deltadate, $filter, $uibModal, SweetAlert) {
  cov_1jcbouc283.f[0]++;

  var PATHVP = (cov_1jcbouc283.s[1]++, 'ValeursPossible');
  var PATHINDIC = (cov_1jcbouc283.s[2]++, 'DecoupageIndic');
  var LISTFLAG = (cov_1jcbouc283.s[3]++, $rootScope.getCurrentProject().project.getFlag());
  cov_1jcbouc283.s[4]++;
  $scope.planParams = {
    yearPlanSelected: null,
    periodPlanSelected: null
  };
  cov_1jcbouc283.s[5]++;
  $scope.yearPlanChange = function () {
    cov_1jcbouc283.f[1]++;
    cov_1jcbouc283.s[6]++;

    $scope.beginEditPlan = false;
    cov_1jcbouc283.s[7]++;
    $scope.listPeriodPlan = [];
    cov_1jcbouc283.s[8]++;
    $scope.periodPlanSelected = null;
    cov_1jcbouc283.s[9]++;
    if (!$scope.planParams.yearPlanSelected) {
      cov_1jcbouc283.b[0][0]++;
      cov_1jcbouc283.s[10]++;

      return;
    } else {
      cov_1jcbouc283.b[0][1]++;
    }
    var year = (cov_1jcbouc283.s[11]++, $scope.planParams.yearPlanSelected.id);
    cov_1jcbouc283.s[12]++;
    if (!year) {
      cov_1jcbouc283.b[1][0]++;
      cov_1jcbouc283.s[13]++;

      return;
    } else {
      cov_1jcbouc283.b[1][1]++;
    }
    cov_1jcbouc283.s[14]++;
    $scope.listPeriodPlan = $deltadate.getYearPeriod(year, $scope.selectedItem.PERIOD_INDIC);
    cov_1jcbouc283.s[15]++;
    $scope.planParams.periodPlanSelected = $scope.listPeriodPlan[0];
    cov_1jcbouc283.s[16]++;
    $scope.periodPlanChange();
  };
  cov_1jcbouc283.s[17]++;
  $scope.periodPlanChange = function () {
    cov_1jcbouc283.f[2]++;
    cov_1jcbouc283.s[18]++;

    $scope.relevResul = [];
    var period = (cov_1jcbouc283.s[19]++, $scope.planParams.periodPlanSelected);
    cov_1jcbouc283.s[20]++;
    if (!period) {
      cov_1jcbouc283.b[2][0]++;
      cov_1jcbouc283.s[21]++;

      return;
    } else {
      cov_1jcbouc283.b[2][1]++;
    }
    cov_1jcbouc283.s[22]++;
    $scope.beginEditPlan = false;
    cov_1jcbouc283.s[23]++;
    $scope.isloading = true;
    cov_1jcbouc283.s[24]++;
    $CRUDService.getAll(PATHINDIC, { get: '7', cl: $scope.home.byplan ? (cov_1jcbouc283.b[3][0]++, $scope.selectedPL.id) : (cov_1jcbouc283.b[3][1]++, $scope.selectedItem.CODE_CL), indic: $scope.selectedItem.id, ng: $scope.selectedItem.CODE_NG, dated: $deltadate.format(period.begin, 'yyyy-mm-dd'), datef: $deltadate.format(period.end, 'yyyy-mm-dd') }, function (data) {
      cov_1jcbouc283.f[3]++;
      cov_1jcbouc283.s[25]++;

      for (var i = data.length - 1; i >= 0; i--) {
        cov_1jcbouc283.s[26]++;

        if (!data[i].id) {
          cov_1jcbouc283.b[4][0]++;
          cov_1jcbouc283.s[27]++;

          data[i].ANNEE_DECOUP = period.year;
          cov_1jcbouc283.s[28]++;
          data[i].NUM_DECOUP = period.index;
          cov_1jcbouc283.s[29]++;
          data[i].ID_INDIC = $scope.selectedItem.id;
        } else {
          cov_1jcbouc283.b[4][1]++;
        }
        var P_value = (cov_1jcbouc283.s[30]++, data[i].VALEUR_PREVU);
        var R_value = (cov_1jcbouc283.s[31]++, data[i].VALEUR_INDIC);
        cov_1jcbouc283.s[32]++;
        if ($scope.selectedItemUnit.TYPE_UNITES == 3) {
          cov_1jcbouc283.b[5][0]++;
          cov_1jcbouc283.s[33]++;

          P_value = eval(data[i].Mon_code_prevue);
          cov_1jcbouc283.s[34]++;
          R_value = eval(data[i].Mon_Code_Reel);
        } else {
          cov_1jcbouc283.b[5][1]++;
        }
        cov_1jcbouc283.s[35]++;
        if ($scope.selectedItemUnit.TYPE_UNITES == 4) {
          cov_1jcbouc283.b[6][0]++;
          cov_1jcbouc283.s[36]++;

          P_value = eval(data[i].VALEUR_PREVU);
          cov_1jcbouc283.s[37]++;
          R_value = eval(data[i].VALEUR_INDIC);
        } else {
          cov_1jcbouc283.b[6][1]++;
        }
        cov_1jcbouc283.s[38]++;
        if ((cov_1jcbouc283.b[8][0]++, $scope.selectedItemUnit.TYPE_UNITES == 1) || (cov_1jcbouc283.b[8][1]++, $scope.selectedItemUnit.TYPE_UNITES == 2)) {
          cov_1jcbouc283.b[7][0]++;
          cov_1jcbouc283.s[39]++;

          P_value = eval(data[i].VALEUR_PREVU);
          cov_1jcbouc283.s[40]++;
          R_value = eval(data[i].VALEUR_INDIC);
        } else {
          cov_1jcbouc283.b[7][1]++;
        }
        cov_1jcbouc283.s[41]++;
        data[i].CODE_CL = $scope.home.byplan ? (cov_1jcbouc283.b[9][0]++, $scope.selectedPL.id) : (cov_1jcbouc283.b[9][1]++, $scope.selectedItem.CODE_CL);
        cov_1jcbouc283.s[42]++;
        data[i].IDACTEURS = eval(data[i].IDACTEURS) ? (cov_1jcbouc283.b[10][0]++, data[i].IDACTEURS) : (cov_1jcbouc283.b[10][1]++, -1);
        cov_1jcbouc283.s[43]++;
        data[i].flag = '255,255,255';
        cov_1jcbouc283.s[44]++;
        if (P_value != 0) {
          cov_1jcbouc283.b[11][0]++;

          var pour = (cov_1jcbouc283.s[45]++, 100 * R_value / P_value);
          cov_1jcbouc283.s[46]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = LISTFLAG[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var flag = _step.value;
              cov_1jcbouc283.s[47]++;

              if ((cov_1jcbouc283.b[13][0]++, pour >= flag.min) && (cov_1jcbouc283.b[13][1]++, pour <= flag.max)) {
                cov_1jcbouc283.b[12][0]++;
                cov_1jcbouc283.s[48]++;

                data[i].flag = flag.color;
                cov_1jcbouc283.s[49]++;
                break;
              } else {
                cov_1jcbouc283.b[12][1]++;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          cov_1jcbouc283.b[11][1]++;
        }
      }
      cov_1jcbouc283.s[50]++;
      $scope.releveIndicResul = data;
      cov_1jcbouc283.s[51]++;
      $scope.isloading = false;
    });
  };
  cov_1jcbouc283.s[52]++;
  $scope.updateReleve = function (item) {
    cov_1jcbouc283.f[4]++;
    cov_1jcbouc283.s[53]++;

    $scope.itemSelectedRealEdit.id = item.id;
    cov_1jcbouc283.s[54]++;
    $scope.itemSelectedRealEdit.VALEUR_INDIC = item.VALEUR_INDIC;
    cov_1jcbouc283.s[55]++;
    $scope.itemSelectedRealEdit.COMMENTS = item.COMMENTS;
    cov_1jcbouc283.s[56]++;
    $scope.itemSelectedRealEdit.COUT_REEL_INDIC = item.COUT_REEL_INDIC;
    cov_1jcbouc283.s[57]++;
    $scope.itemSelectedRealEdit.Mon_Code_Reel = item.Mon_Code_Reel;
    var P_value = (cov_1jcbouc283.s[58]++, $scope.itemSelectedRealEdit.VALEUR_PREVU);
    var R_value = (cov_1jcbouc283.s[59]++, $scope.itemSelectedRealEdit.VALEUR_INDIC);
    cov_1jcbouc283.s[60]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 3) {
      cov_1jcbouc283.b[14][0]++;
      cov_1jcbouc283.s[61]++;

      P_value = eval($scope.itemSelectedRealEdit.Mon_code_prevue);
      cov_1jcbouc283.s[62]++;
      R_value = eval($scope.itemSelectedRealEdit.Mon_Code_Reel);
    } else {
      cov_1jcbouc283.b[14][1]++;
    }
    cov_1jcbouc283.s[63]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 4) {
      cov_1jcbouc283.b[15][0]++;
      cov_1jcbouc283.s[64]++;

      P_value = eval($scope.itemSelectedRealEdit.VALEUR_PREVU);
      cov_1jcbouc283.s[65]++;
      R_value = eval($scope.itemSelectedRealEdit.VALEUR_INDIC);
    } else {
      cov_1jcbouc283.b[15][1]++;
    }
    cov_1jcbouc283.s[66]++;
    if ((cov_1jcbouc283.b[17][0]++, $scope.selectedItemUnit.TYPE_UNITES == 1) || (cov_1jcbouc283.b[17][1]++, $scope.selectedItemUnit.TYPE_UNITES == 2)) {
      cov_1jcbouc283.b[16][0]++;
      cov_1jcbouc283.s[67]++;

      P_value = eval($scope.itemSelectedRealEdit.VALEUR_PREVU);
      cov_1jcbouc283.s[68]++;
      R_value = eval($scope.itemSelectedRealEdit.VALEUR_INDIC);
    } else {
      cov_1jcbouc283.b[16][1]++;
    }
    cov_1jcbouc283.s[69]++;
    $scope.itemSelectedRealEdit.flag = '255,255,255';
    cov_1jcbouc283.s[70]++;
    if (P_value != 0) {
      cov_1jcbouc283.b[18][0]++;

      var pour = (cov_1jcbouc283.s[71]++, 100 * R_value / P_value);
      cov_1jcbouc283.s[72]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = LISTFLAG[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var flag = _step2.value;
          cov_1jcbouc283.s[73]++;

          if ((cov_1jcbouc283.b[20][0]++, pour >= flag.min) && (cov_1jcbouc283.b[20][1]++, pour <= flag.max)) {
            cov_1jcbouc283.b[19][0]++;
            cov_1jcbouc283.s[74]++;

            $scope.itemSelectedRealEdit.flag = flag.color;
            cov_1jcbouc283.s[75]++;
            break;
          } else {
            cov_1jcbouc283.b[19][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    } else {
      cov_1jcbouc283.b[18][1]++;
    }
  };
  cov_1jcbouc283.s[76]++;
  $scope.initDecoupageIndicateur = function (item) {
    cov_1jcbouc283.f[5]++;
    cov_1jcbouc283.s[77]++;

    $scope.planParams.yearPlanSelected = null;
    cov_1jcbouc283.s[78]++;
    $scope.selectedItemUnit = null;
    cov_1jcbouc283.s[79]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = $scope.listData_unites_bksb[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step3.value;
        cov_1jcbouc283.s[80]++;

        if (el.id == item.ID_UNITE) {
          cov_1jcbouc283.b[21][0]++;
          cov_1jcbouc283.s[81]++;

          $scope.selectedItemUnit = el;
          cov_1jcbouc283.s[82]++;
          break;
        } else {
          cov_1jcbouc283.b[21][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_1jcbouc283.s[83]++;
    $CRUDService.getAll(PATHVP, { get: 'all', indic: item.id }, function (data) {
      cov_1jcbouc283.f[6]++;
      cov_1jcbouc283.s[84]++;

      $scope.listValeurPossible = data;
    });
    cov_1jcbouc283.s[85]++;
    if ($scope.selectedItemUnit == null) {
      cov_1jcbouc283.b[22][0]++;
      cov_1jcbouc283.s[86]++;

      return;
    } else {
      cov_1jcbouc283.b[22][1]++;
    }
    cov_1jcbouc283.s[87]++;
    $scope.selectedItem = item;
    cov_1jcbouc283.s[88]++;
    $scope.yearPlanSelected = null;
    cov_1jcbouc283.s[89]++;
    $scope.periodPlanSelected = null;
    cov_1jcbouc283.s[90]++;
    $scope.listYearPlan = [].concat($deltadate.getYearBetween(item.DEB_EVAL_INDIC, item.FIN_EVAL_INDIC));
    cov_1jcbouc283.s[91]++;
    $scope.listPeriodPlan = [];
    cov_1jcbouc283.s[92]++;
    $scope.relevResul = [];
    cov_1jcbouc283.s[93]++;
    $scope.releveIndicResul = [];
    cov_1jcbouc283.s[94]++;
    $scope.getGlobalValue(item);
    cov_1jcbouc283.s[95]++;
    $scope.planParams.yearPlanSelected = $scope.listYearPlan[0];
    cov_1jcbouc283.s[96]++;
    $scope.yearPlanChange();
  };
  cov_1jcbouc283.s[97]++;
  $scope.editReleve = function (item) {
    cov_1jcbouc283.f[7]++;
    cov_1jcbouc283.s[98]++;

    $scope.itemSelectedRealEdit = item;
    cov_1jcbouc283.s[99]++;
    $scope.templateEditPlan = '';
    var editTemplate = (cov_1jcbouc283.s[100]++, '');
    var R_value = (cov_1jcbouc283.s[101]++, item.VALEUR_INDIC);
    cov_1jcbouc283.s[102]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 3) {
      cov_1jcbouc283.b[23][0]++;
      cov_1jcbouc283.s[103]++;

      R_value = eval(item.Mon_Code_Reel);
    } else {
        cov_1jcbouc283.b[23][1]++;
        cov_1jcbouc283.s[104]++;
        if ($scope.selectedItemUnit.TYPE_UNITES <= 4) {
          cov_1jcbouc283.b[24][0]++;
          cov_1jcbouc283.s[105]++;

          R_value = eval(item.VALEUR_INDIC);
        } else {
          cov_1jcbouc283.b[24][1]++;
        }
      }var period = (cov_1jcbouc283.s[106]++, $scope.planParams.periodPlanSelected);
    cov_1jcbouc283.s[107]++;
    if (!period) {
      cov_1jcbouc283.b[25][0]++;
      cov_1jcbouc283.s[108]++;

      return;
    } else {
      cov_1jcbouc283.b[25][1]++;
    }
    cov_1jcbouc283.s[109]++;
    $scope.editorReal = Object.assign(angular.copy(item), {
      value: R_value,
      COMMENTS: item.COMMENTS,
      cout: eval(item.COUT_REEL_INDIC)
    });
    cov_1jcbouc283.s[110]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 3) {
      cov_1jcbouc283.b[26][0]++;

      var _find = (cov_1jcbouc283.s[111]++, false);
      cov_1jcbouc283.s[112]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = $scope.listValeurPossible[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var el = _step4.value;
          cov_1jcbouc283.s[113]++;

          if (el.id == $scope.editorReal.value) {
            cov_1jcbouc283.b[27][0]++;
            cov_1jcbouc283.s[114]++;

            _find = true;
            cov_1jcbouc283.s[115]++;
            $scope.editorReal.value = el;
            cov_1jcbouc283.s[116]++;
            break;
          } else {
            cov_1jcbouc283.b[27][1]++;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_1jcbouc283.s[117]++;
      if (!_find) {
        cov_1jcbouc283.b[28][0]++;
        cov_1jcbouc283.s[118]++;

        $scope.editorReal.value = $scope.listValeurPossible[0];
      } else {
        cov_1jcbouc283.b[28][1]++;
      }
    } else {
      cov_1jcbouc283.b[26][1]++;
    }
    cov_1jcbouc283.s[119]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 4) {
      cov_1jcbouc283.b[29][0]++;
      cov_1jcbouc283.s[120]++;

      editTemplate = '';
      cov_1jcbouc283.s[121]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.indicateur.listValBool[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _el = _step5.value;

          var _find2 = (cov_1jcbouc283.s[122]++, false);
          cov_1jcbouc283.s[123]++;
          if (_el.id == $scope.editorReal.value) {
            cov_1jcbouc283.b[30][0]++;
            cov_1jcbouc283.s[124]++;

            $scope.editorReal.value = _el;
            cov_1jcbouc283.s[125]++;
            _find2 = true;
            cov_1jcbouc283.s[126]++;
            break;
          } else {
            cov_1jcbouc283.b[30][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1jcbouc283.s[127]++;
      if (!find) {
        cov_1jcbouc283.b[31][0]++;
        cov_1jcbouc283.s[128]++;

        $scope.editorReal.value = $scope.indicateur.listValBool[0];
      } else {
        cov_1jcbouc283.b[31][1]++;
      }
    } else {
      cov_1jcbouc283.b[29][1]++;
    }
    cov_1jcbouc283.s[129]++;
    if ($scope.selectedItemUnit.TYPE_UNITES == 5) {
      cov_1jcbouc283.b[32][0]++;
      cov_1jcbouc283.s[130]++;

      $scope.editorReal.value = Date.newDate($scope.editorReal.value);
      cov_1jcbouc283.s[131]++;
      editTemplate = '';
    } else {
      cov_1jcbouc283.b[32][1]++;
    }
    cov_1jcbouc283.s[132]++;
    $scope.templateEditPlan = editTemplate;
    cov_1jcbouc283.s[133]++;
    $uibModal.open({
      templateUrl: 'app/views/indicator/releve/edit.html',
      controller: ReleveIndicateurCtrl_edit,
      scope: $scope,
      size: 'md_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal '
    });
  };

  cov_1jcbouc283.s[134]++;
  $scope.deletePlanification = function (item) {
    cov_1jcbouc283.f[8]++;
    cov_1jcbouc283.s[135]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant($scope.isplan?"INDICATOR.CONFIRM_DELETE_PLAN" : "INDICATOR.CONFIRM_DELETE_RELEVE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1jcbouc283.f[9]++;
      cov_1jcbouc283.s[136]++;

      if (isconfirm) {
        cov_1jcbouc283.b[33][0]++;
        cov_1jcbouc283.s[137]++;

        return;
      } else {
        cov_1jcbouc283.b[33][1]++;
      }
      cov_1jcbouc283.s[138]++;
      item.VALEUR_INDIC = null;
      cov_1jcbouc283.s[139]++;
      item.Mon_Code_Reel = null;
      cov_1jcbouc283.s[140]++;
      item.COUT_REEL_INDIC = 0;
      cov_1jcbouc283.s[141]++;
      item.COMMENTS = '';
      var itemSave = (cov_1jcbouc283.s[142]++, angular.copy(item));
      cov_1jcbouc283.s[143]++;
      $CRUDService.save(PATHINDIC, { action: 'delete', valeur: $filter('json')(itemSave), type: 1 });
      cov_1jcbouc283.s[144]++;
      item.flag = '255,255,255';
    });
  };
  cov_1jcbouc283.s[145]++;
  $scope.cancelPlanification = function (item) {
    cov_1jcbouc283.f[10]++;
    cov_1jcbouc283.s[146]++;

    item.edit = false;
    cov_1jcbouc283.s[147]++;
    $scope.beginEditPlan = false;
    cov_1jcbouc283.s[148]++;
    item.editor = angular.copy(item.oldValue);
  };
});

function ReleveIndicateurCtrl_edit($scope, $uibModalInstance, $translate, $deltadate, $rootScope, $CRUDService, $filter) {
  cov_1jcbouc283.f[11]++;

  var PATHINDIC = (cov_1jcbouc283.s[149]++, 'DecoupageIndic');
  var LISTFLAG = (cov_1jcbouc283.s[150]++, $rootScope.getCurrentProject().project.getFlag());
  cov_1jcbouc283.s[151]++;
  $scope._changeItem = function (item, typeu) {
    cov_1jcbouc283.f[12]++;
    cov_1jcbouc283.s[152]++;

    switch (typeu) {
      case 3:
        cov_1jcbouc283.b[34][0]++;
        cov_1jcbouc283.s[153]++;

        if (!item.value.id) {
          cov_1jcbouc283.b[35][0]++;
          cov_1jcbouc283.s[154]++;

          return;
        } else {
          cov_1jcbouc283.b[35][1]++;
        }
        cov_1jcbouc283.s[155]++;
        item.VALEUR_INDIC = null;
        cov_1jcbouc283.s[156]++;
        item.value = item.value.id;
        cov_1jcbouc283.s[157]++;
        item.Mon_Code_Reel = item.value;
        cov_1jcbouc283.s[158]++;
        break;
      case 4:
        cov_1jcbouc283.b[34][1]++;
        cov_1jcbouc283.s[159]++;

        if (!item.value.id) {
          cov_1jcbouc283.b[36][0]++;
          cov_1jcbouc283.s[160]++;

          return;
        } else {
          cov_1jcbouc283.b[36][1]++;
        }
        cov_1jcbouc283.s[161]++;
        item.value = item.value.id;
        cov_1jcbouc283.s[162]++;
        item.VALEUR_INDIC = item.value;
        cov_1jcbouc283.s[163]++;
        item.Mon_Code_Reel = null;
        cov_1jcbouc283.s[164]++;
        break;
      case 5:
        cov_1jcbouc283.b[34][2]++;
        cov_1jcbouc283.s[165]++;

        item.VALEUR_INDIC = $deltadate.format(item.value, 'yyyy-mm-dd');
        cov_1jcbouc283.s[166]++;
        break;
      default:
        cov_1jcbouc283.b[34][3]++;
        cov_1jcbouc283.s[167]++;

        item.VALEUR_INDIC = item.value;
    }
    cov_1jcbouc283.s[168]++;
    item.COUT_REEL_INDIC = item.cout;
    cov_1jcbouc283.s[169]++;
    return item;
  };
  cov_1jcbouc283.s[170]++;
  $scope._checkValidation = function (item) {
    cov_1jcbouc283.f[13]++;
    cov_1jcbouc283.s[171]++;

    if ((cov_1jcbouc283.b[38][0]++, !item.value) && (cov_1jcbouc283.b[38][1]++, item.value !== 0)) {
      cov_1jcbouc283.b[37][0]++;
      cov_1jcbouc283.s[172]++;

      return 'INDICATOR.ERROR_DECOUP_PLAN.VALUE';
    } else {
      cov_1jcbouc283.b[37][1]++;
    }
    cov_1jcbouc283.s[173]++;
    return null;
  };
  cov_1jcbouc283.s[174]++;
  $scope.ValidateReleve = function (item) {
    cov_1jcbouc283.f[14]++;

    var strerror = (cov_1jcbouc283.s[175]++, this._checkValidation(angular.copy(item)));
    cov_1jcbouc283.s[176]++;
    if (strerror != null) {
      cov_1jcbouc283.b[39][0]++;
      cov_1jcbouc283.s[177]++;

      $scope.errorReal = $translate.instant(strerror);
      cov_1jcbouc283.s[178]++;
      return;
    } else {
      cov_1jcbouc283.b[39][1]++;
    }
    var itemSave = (cov_1jcbouc283.s[179]++, this._changeItem(angular.copy(item), eval($scope.selectedItemUnit.TYPE_UNITES)));
    cov_1jcbouc283.s[180]++;
    itemSave.DATE_ALERT = undefined;
    cov_1jcbouc283.s[181]++;
    itemSave.Date_Releve = undefined;
    cov_1jcbouc283.s[182]++;
    $CRUDService.save(PATHINDIC, { action: 'MiseAJour', valeur: $filter('json')(itemSave), Master: 1, typeR: 1 }, function (data) {
      cov_1jcbouc283.f[15]++;

      var error = (cov_1jcbouc283.s[183]++, '');
      cov_1jcbouc283.s[184]++;
      if (angular.isNumber(data)) {
        cov_1jcbouc283.b[40][0]++;
        cov_1jcbouc283.s[185]++;

        if (data < 1) {
          cov_1jcbouc283.b[41][0]++;
          cov_1jcbouc283.s[186]++;

          error = $translate.instant('INDICATOR.ERROR_UPDATE');
        } else {
          cov_1jcbouc283.b[41][1]++;
        }
      } else {
          cov_1jcbouc283.b[40][1]++;
          cov_1jcbouc283.s[187]++;
          if (data.e == -66) {
            cov_1jcbouc283.b[42][0]++;
            cov_1jcbouc283.s[188]++;

            if ((cov_1jcbouc283.b[44][0]++, data.l === null) && (cov_1jcbouc283.b[44][1]++, data.g !== null)) {
              cov_1jcbouc283.b[43][0]++;
              cov_1jcbouc283.s[189]++;

              error = $translate.instant('INDICATOR.LESS_THAN', { valeur: data.g });
            } else {
              cov_1jcbouc283.b[43][1]++;
            }
            cov_1jcbouc283.s[190]++;
            if ((cov_1jcbouc283.b[46][0]++, data.g === null) && (cov_1jcbouc283.b[46][1]++, data.l !== null)) {
              cov_1jcbouc283.b[45][0]++;
              cov_1jcbouc283.s[191]++;

              error = $translate.instant('INDICATOR.GREAT_THAN', { valeur: data.l });
            } else {
              cov_1jcbouc283.b[45][1]++;
            }
            cov_1jcbouc283.s[192]++;
            if ((cov_1jcbouc283.b[48][0]++, data.g !== null) && (cov_1jcbouc283.b[48][1]++, data.l !== null)) {
              cov_1jcbouc283.b[47][0]++;
              cov_1jcbouc283.s[193]++;

              error = $translate.instant(data.g == data.l ? (cov_1jcbouc283.b[49][0]++, 'INDICATOR.EGAL_TO') : (cov_1jcbouc283.b[49][1]++, 'INDICATOR.BEETWEEB'), { valeur_min: data.l, valeur_max: data.g, valeur: data.g });
            } else {
              cov_1jcbouc283.b[47][1]++;
            }
          } else {
            cov_1jcbouc283.b[42][1]++;
          }
        }cov_1jcbouc283.s[194]++;
      if (error != '') {
        cov_1jcbouc283.b[50][0]++;
        cov_1jcbouc283.s[195]++;

        $scope.errorReal = error;
        cov_1jcbouc283.s[196]++;
        return;
      } else {
        cov_1jcbouc283.b[50][1]++;
      }
      cov_1jcbouc283.s[197]++;
      itemSave.id = data;
      cov_1jcbouc283.s[198]++;
      $scope.updateReleve(itemSave);
    });
    cov_1jcbouc283.s[199]++;
    $uibModalInstance.dismiss('cancel');
  };
  cov_1jcbouc283.s[200]++;
  $scope.closeModal = function () {
    cov_1jcbouc283.f[16]++;
    cov_1jcbouc283.s[201]++;

    $uibModalInstance.dismiss('cancel');
  };
}
'use strict';

var cov_fx9hbgcj3 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/contract_ppm/contract_ppmCtrl.js',
      hash = '9e95dfaaf82e92a090e56b0d4493375e69f4a3fe',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/contract_ppm/contract_ppmCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 126,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 16
        },
        end: {
          line: 5,
          column: 25
        }
      },
      '2': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '3': {
        start: {
          line: 7,
          column: 17
        },
        end: {
          line: 7,
          column: 32
        }
      },
      '4': {
        start: {
          line: 8,
          column: 19
        },
        end: {
          line: 8,
          column: 35
        }
      },
      '5': {
        start: {
          line: 9,
          column: 15
        },
        end: {
          line: 9,
          column: 26
        }
      },
      '6': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 21
        }
      },
      '7': {
        start: {
          line: 11,
          column: 15
        },
        end: {
          line: 11,
          column: 67
        }
      },
      '8': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 84
        }
      },
      '9': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 17,
          column: 4
        }
      },
      '10': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 25
        }
      },
      '11': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 21,
          column: 5
        }
      },
      '12': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 53
        }
      },
      '13': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 22,
          column: 42
        }
      },
      '14': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '15': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 28,
          column: 8
        }
      },
      '16': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 29,
          column: 75
        }
      },
      '17': {
        start: {
          line: 31,
          column: 2
        },
        end: {
          line: 31,
          column: 29
        }
      },
      '18': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 42,
          column: 4
        }
      },
      '19': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 39,
          column: 5
        }
      },
      '20': {
        start: {
          line: 34,
          column: 19
        },
        end: {
          line: 34,
          column: 48
        }
      },
      '21': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 37,
          column: 7
        }
      },
      '22': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 36,
          column: 17
        }
      },
      '23': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 38,
          column: 19
        }
      },
      '24': {
        start: {
          line: 40,
          column: 19
        },
        end: {
          line: 40,
          column: 89
        }
      },
      '25': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 41,
          column: 31
        }
      },
      '26': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 64,
          column: 4
        }
      },
      '27': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '28': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 13
        }
      },
      '29': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '30': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 46
        }
      },
      '31': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 27
        }
      },
      '32': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 60,
          column: 7
        }
      },
      '33': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 59,
          column: 9
        }
      },
      '34': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 56,
          column: 11
        }
      },
      '35': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 55,
          column: 64
        }
      },
      '36': {
        start: {
          line: 57,
          column: 10
        },
        end: {
          line: 57,
          column: 36
        }
      },
      '37': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 58,
          column: 16
        }
      },
      '38': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 61,
          column: 25
        }
      },
      '39': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 63,
          column: 31
        }
      },
      '40': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 72,
          column: 4
        }
      },
      '41': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 80,
          column: 4
        }
      },
      '42': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 76,
          column: 5
        }
      },
      '43': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 75,
          column: 13
        }
      },
      '44': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 77,
          column: 31
        }
      },
      '45': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 78,
          column: 35
        }
      },
      '46': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 79,
          column: 20
        }
      },
      '47': {
        start: {
          line: 81,
          column: 2
        },
        end: {
          line: 88,
          column: 4
        }
      },
      '48': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 84,
          column: 5
        }
      },
      '49': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 13
        }
      },
      '50': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 85,
          column: 32
        }
      },
      '51': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 86,
          column: 36
        }
      },
      '52': {
        start: {
          line: 87,
          column: 4
        },
        end: {
          line: 87,
          column: 20
        }
      },
      '53': {
        start: {
          line: 90,
          column: 2
        },
        end: {
          line: 125,
          column: 4
        }
      },
      '54': {
        start: {
          line: 91,
          column: 4
        },
        end: {
          line: 91,
          column: 23
        }
      },
      '55': {
        start: {
          line: 92,
          column: 25
        },
        end: {
          line: 92,
          column: 121
        }
      },
      '56': {
        start: {
          line: 93,
          column: 4
        },
        end: {
          line: 93,
          column: 25
        }
      },
      '57': {
        start: {
          line: 94,
          column: 4
        },
        end: {
          line: 102,
          column: 5
        }
      },
      '58': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 101,
          column: 9
        }
      },
      '59': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 124,
          column: 5
        }
      },
      '60': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 104,
          column: 23
        }
      },
      '61': {
        start: {
          line: 105,
          column: 20
        },
        end: {
          line: 111,
          column: 7
        }
      },
      '62': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 123,
          column: 9
        }
      },
      '63': {
        start: {
          line: 113,
          column: 20
        },
        end: {
          line: 113,
          column: 21
        }
      },
      '64': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 25
        }
      },
      '65': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 122,
          column: 9
        }
      },
      '66': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 18
        }
      },
      '67': {
        start: {
          line: 117,
          column: 10
        },
        end: {
          line: 117,
          column: 24
        }
      },
      '68': {
        start: {
          line: 118,
          column: 10
        },
        end: {
          line: 118,
          column: 59
        }
      },
      '69': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 119,
          column: 36
        }
      },
      '70': {
        start: {
          line: 120,
          column: 10
        },
        end: {
          line: 120,
          column: 27
        }
      },
      '71': {
        start: {
          line: 121,
          column: 10
        },
        end: {
          line: 121,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 49
          },
          end: {
            line: 4,
            column: 50
          }
        },
        loc: {
          start: {
            line: 4,
            column: 199
          },
          end: {
            line: 126,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 19,
            column: 45
          },
          end: {
            line: 19,
            column: 46
          }
        },
        loc: {
          start: {
            line: 19,
            column: 51
          },
          end: {
            line: 21,
            column: 3
          }
        },
        line: 19
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 42
          },
          end: {
            line: 23,
            column: 43
          }
        },
        loc: {
          start: {
            line: 23,
            column: 48
          },
          end: {
            line: 30,
            column: 3
          }
        },
        line: 23
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 32,
            column: 21
          },
          end: {
            line: 32,
            column: 22
          }
        },
        loc: {
          start: {
            line: 32,
            column: 37
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 32
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 43,
            column: 26
          },
          end: {
            line: 43,
            column: 27
          }
        },
        loc: {
          start: {
            line: 43,
            column: 42
          },
          end: {
            line: 64,
            column: 3
          }
        },
        line: 43
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 73,
            column: 26
          },
          end: {
            line: 73,
            column: 27
          }
        },
        loc: {
          start: {
            line: 73,
            column: 42
          },
          end: {
            line: 80,
            column: 3
          }
        },
        line: 73
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 81,
            column: 26
          },
          end: {
            line: 81,
            column: 27
          }
        },
        loc: {
          start: {
            line: 81,
            column: 42
          },
          end: {
            line: 88,
            column: 3
          }
        },
        line: 81
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 90,
            column: 20
          },
          end: {
            line: 90,
            column: 21
          }
        },
        loc: {
          start: {
            line: 90,
            column: 32
          },
          end: {
            line: 125,
            column: 3
          }
        },
        line: 90
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 112,
            column: 39
          },
          end: {
            line: 112,
            column: 40
          }
        },
        loc: {
          start: {
            line: 112,
            column: 47
          },
          end: {
            line: 123,
            column: 7
          }
        },
        line: 112
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        }, {
          start: {
            line: 35,
            column: 6
          },
          end: {
            line: 37,
            column: 7
          }
        }],
        line: 35
      },
      '1': {
        loc: {
          start: {
            line: 35,
            column: 10
          },
          end: {
            line: 35,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 35,
            column: 10
          },
          end: {
            line: 35,
            column: 20
          }
        }, {
          start: {
            line: 35,
            column: 24
          },
          end: {
            line: 35,
            column: 45
          }
        }],
        line: 35
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }, {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        }, {
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        }],
        line: 48
      },
      '4': {
        loc: {
          start: {
            line: 53,
            column: 8
          },
          end: {
            line: 59,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 8
          },
          end: {
            line: 59,
            column: 9
          }
        }, {
          start: {
            line: 53,
            column: 8
          },
          end: {
            line: 59,
            column: 9
          }
        }],
        line: 53
      },
      '5': {
        loc: {
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 76,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 76,
            column: 5
          }
        }, {
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 76,
            column: 5
          }
        }],
        line: 74
      },
      '6': {
        loc: {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 84,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 84,
            column: 5
          }
        }, {
          start: {
            line: 82,
            column: 4
          },
          end: {
            line: 84,
            column: 5
          }
        }],
        line: 82
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_fx9hbgcj3.s[0]++;
/**
 * Created by BKSB on 28/02/2017.
 */
angular.module('app').controller('contract_ppm', function ($scope, $deltaActor, $rootScope, $translate, $deltaPlanMarches, $deltaLocation, $deltaPlanLogique, $deltahttp, $CRUDService, $filter, $deltadate) {
  cov_fx9hbgcj3.f[0]++;

  var PATHM = (cov_fx9hbgcj3.s[1]++, 'Marches');
  var PATH = (cov_fx9hbgcj3.s[2]++, 'Planification');
  var EXPAND = (cov_fx9hbgcj3.s[3]++, 'fa-angle-down');
  var COLLAPSE = (cov_fx9hbgcj3.s[4]++, 'fa-angle-right');
  var LEAF = (cov_fx9hbgcj3.s[5]++, 'fa-flag-o');
  cov_fx9hbgcj3.s[6]++;
  $scope.indexel = 0;
  var info = (cov_fx9hbgcj3.s[7]++, $rootScope.currentProject.project.getDecoupagePlan());
  cov_fx9hbgcj3.s[8]++;
  $scope.listYear = $deltadate.getYearBetween(info.dDFirstLevel, info.dFFirstLevel);
  cov_fx9hbgcj3.s[9]++;
  $scope.choise = {
    display_loc: false,
    display_cl: true,
    year: $scope.listYear[$scope.listYear.length - 1]
  };
  cov_fx9hbgcj3.s[10]++;
  $scope.listMarche = [];
  cov_fx9hbgcj3.s[11]++;
  $deltaLocation.addController($scope, null, function () {
    cov_fx9hbgcj3.f[1]++;
    cov_fx9hbgcj3.s[12]++;

    $scope.choise.location = $scope.listData_bksb[0];
  });
  cov_fx9hbgcj3.s[13]++;
  $deltaPlanLogique.addController($scope);
  cov_fx9hbgcj3.s[14]++;
  $deltaPlanMarches.addController($scope, function () {
    cov_fx9hbgcj3.f[2]++;
    cov_fx9hbgcj3.s[15]++;

    $scope.listMarche = $scope.listData_PlanMarches_bksb.concat([{
      LIBELLE_MARCHES: $translate.instant('COMMON.ALL'),
      CODE_MARCHE: '',
      id: 0
    }]);
    cov_fx9hbgcj3.s[16]++;
    $scope.choise.marche = $scope.listMarche[$scope.listMarche.length - 1];
  });
  cov_fx9hbgcj3.s[17]++;
  $scope.filtreList_ppm = [];
  cov_fx9hbgcj3.s[18]++;
  $scope.filterppm = function (item) {
    cov_fx9hbgcj3.f[3]++;
    cov_fx9hbgcj3.s[19]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.filtreList_ppm[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var el = _step.value;

        var rest = (cov_fx9hbgcj3.s[20]++, item.CODE_CLC.replace(el, ''));
        cov_fx9hbgcj3.s[21]++;
        if ((cov_fx9hbgcj3.b[1][0]++, rest == '') || (cov_fx9hbgcj3.b[1][1]++, rest == item.CODE_CLC)) {
          cov_fx9hbgcj3.b[0][0]++;
          cov_fx9hbgcj3.s[22]++;

          continue;
        } else {
          cov_fx9hbgcj3.b[0][1]++;
        }
        cov_fx9hbgcj3.s[23]++;
        return false;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    var filtre = (cov_fx9hbgcj3.s[24]++, $filter('filter')($scope.listData, { z: true, CODE_CLC: item.CODE_CLC }));
    cov_fx9hbgcj3.s[25]++;
    return filtre.length > 0;
  };
  cov_fx9hbgcj3.s[26]++;
  $scope.openorcloseppm = function (item) {
    cov_fx9hbgcj3.f[4]++;
    cov_fx9hbgcj3.s[27]++;

    if (item.leaf) {
      cov_fx9hbgcj3.b[2][0]++;
      cov_fx9hbgcj3.s[28]++;

      return;
    } else {
      cov_fx9hbgcj3.b[2][1]++;
    }

    cov_fx9hbgcj3.s[29]++;
    if (item.expand) {
      cov_fx9hbgcj3.b[3][0]++;
      cov_fx9hbgcj3.s[30]++;

      this.filtreList_ppm.push(item.CODE_CLC);
      cov_fx9hbgcj3.s[31]++;
      item.icon = COLLAPSE;
    } else {
      cov_fx9hbgcj3.b[3][1]++;
      cov_fx9hbgcj3.s[32]++;

      for (var i = 0; i < this.filtreList_ppm.length; i++) {
        cov_fx9hbgcj3.s[33]++;

        if (this.filtreList_ppm[i] == item.CODE_CLC) {
          cov_fx9hbgcj3.b[4][0]++;
          cov_fx9hbgcj3.s[34]++;

          for (j = i + 1; j < this.filtreList_ppm.length; j++) {
            cov_fx9hbgcj3.s[35]++;

            this.filtreList_ppm[j - 1] = this.filtreList_ppm[j];
          }
          cov_fx9hbgcj3.s[36]++;
          this.filtreList_ppm.pop();
          cov_fx9hbgcj3.s[37]++;
          break;
        } else {
          cov_fx9hbgcj3.b[4][1]++;
        }
      }
      cov_fx9hbgcj3.s[38]++;
      item.icon = EXPAND;
    }
    cov_fx9hbgcj3.s[39]++;
    item.expand = !item.expand;
  };
  cov_fx9hbgcj3.s[40]++;
  $scope.filter = {
    cols: {
      displayM: true,
      displayT: true,
      displayO: true,
      displayMo: true
    }
  };
  cov_fx9hbgcj3.s[41]++;
  $scope.selectLogFrame = function (item) {
    cov_fx9hbgcj3.f[5]++;
    cov_fx9hbgcj3.s[42]++;

    if (!item) {
      cov_fx9hbgcj3.b[5][0]++;
      cov_fx9hbgcj3.s[43]++;

      return;
    } else {
      cov_fx9hbgcj3.b[5][1]++;
    }
    cov_fx9hbgcj3.s[44]++;
    this.choise.planlog = item;
    cov_fx9hbgcj3.s[45]++;
    this.choise.display_cl = false;
    cov_fx9hbgcj3.s[46]++;
    this.validate();
  };
  cov_fx9hbgcj3.s[47]++;
  $scope.selectlocality = function (item) {
    cov_fx9hbgcj3.f[6]++;
    cov_fx9hbgcj3.s[48]++;

    if (!item) {
      cov_fx9hbgcj3.b[6][0]++;
      cov_fx9hbgcj3.s[49]++;

      return;
    } else {
      cov_fx9hbgcj3.b[6][1]++;
    }
    cov_fx9hbgcj3.s[50]++;
    this.choise.location = item;
    cov_fx9hbgcj3.s[51]++;
    this.choise.display_loc = false;
    cov_fx9hbgcj3.s[52]++;
    this.validate();
  };

  cov_fx9hbgcj3.s[53]++;
  $scope.validate = function () {
    cov_fx9hbgcj3.f[7]++;
    cov_fx9hbgcj3.s[54]++;

    $scope.indexel = 0;
    var listActivity = (cov_fx9hbgcj3.s[55]++, $filter('filter')($scope.listData_pl_bksb, { leaf: true, CODE_CLC: this.choise.planlog.CODE_CLC }));
    cov_fx9hbgcj3.s[56]++;
    $scope.listData = [];
    cov_fx9hbgcj3.s[57]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $filter('filter')($scope.listData_pl_bksb, { CODE_CLC: this.choise.planlog.CODE_CLC })[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var el = _step2.value;
        cov_fx9hbgcj3.s[58]++;

        $scope.listData.push({
          l: el.CODE_CLCAFF + ': ' + el.LIBELLE_C_CL,
          expand: true,
          icon: EXPAND,
          CODE_CLC: el.CODE_CLC,
          level: el.level
        });
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_fx9hbgcj3.s[59]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      var _loop = function _loop() {
        var el = _step3.value;
        cov_fx9hbgcj3.s[60]++;

        $scope.indexel--;
        var param = (cov_fx9hbgcj3.s[61]++, {
          get: 'suiviPPM',
          id: el.id,
          year: $scope.choise.year.id,
          idM: $scope.choise.marche.id,
          loc: $scope.choise.location.CODE_LC
        });
        cov_fx9hbgcj3.s[62]++;
        $CRUDService.getAll(PATH, param, function (data) {
          cov_fx9hbgcj3.f[8]++;

          var index = (cov_fx9hbgcj3.s[63]++, 0);
          cov_fx9hbgcj3.s[64]++;
          $scope.indexel++;
          cov_fx9hbgcj3.s[65]++;
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var item = _step4.value;
              cov_fx9hbgcj3.s[66]++;

              index++;
              cov_fx9hbgcj3.s[67]++;
              item.z = true;
              cov_fx9hbgcj3.s[68]++;
              item.CODE_CLC = item.CODE_CLC + '______' + index;
              cov_fx9hbgcj3.s[69]++;
              item.level = el.level + 1;
              cov_fx9hbgcj3.s[70]++;
              item.icon = LEAF;
              cov_fx9hbgcj3.s[71]++;
              $scope.listData.push(item);
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        });
      };

      for (var _iterator3 = listActivity[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        _loop();
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }
  };
});
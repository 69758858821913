'use strict';

var cov_2f3quhkz76 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/right/right.js',
      hash = 'aab93d9aeea1e53da28e68a761f5306d58bd174a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/right/right.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 140,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 28
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 33
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 9,
          column: 4
        }
      },
      '4': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 134,
          column: 4
        }
      },
      '5': {
        start: {
          line: 14,
          column: 18
        },
        end: {
          line: 14,
          column: 59
        }
      },
      '6': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '7': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 142
        }
      },
      '8': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '9': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 20
        }
      },
      '10': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 26,
          column: 7
        }
      },
      '11': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 20
        }
      },
      '12': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 19
        }
      },
      '13': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '14': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 20
        }
      },
      '15': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '16': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 20
        }
      },
      '17': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 19
        }
      },
      '18': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      '19': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 20
        }
      },
      '20': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '21': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 20
        }
      },
      '22': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 19
        }
      },
      '23': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 34
        }
      },
      '24': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 84,
          column: 9
        }
      },
      '25': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 37
        }
      },
      '26': {
        start: {
          line: 51,
          column: 28
        },
        end: {
          line: 55,
          column: 9
        }
      },
      '27': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 77,
          column: 9
        }
      },
      '28': {
        start: {
          line: 57,
          column: 22
        },
        end: {
          line: 57,
          column: 49
        }
      },
      '29': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 76,
          column: 11
        }
      },
      '30': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 75,
          column: 13
        }
      },
      '31': {
        start: {
          line: 60,
          column: 25
        },
        end: {
          line: 60,
          column: 42
        }
      },
      '32': {
        start: {
          line: 61,
          column: 14
        },
        end: {
          line: 74,
          column: 15
        }
      },
      '33': {
        start: {
          line: 62,
          column: 16
        },
        end: {
          line: 64,
          column: 17
        }
      },
      '34': {
        start: {
          line: 63,
          column: 18
        },
        end: {
          line: 63,
          column: 27
        }
      },
      '35': {
        start: {
          line: 65,
          column: 16
        },
        end: {
          line: 67,
          column: 17
        }
      },
      '36': {
        start: {
          line: 66,
          column: 18
        },
        end: {
          line: 66,
          column: 46
        }
      },
      '37': {
        start: {
          line: 68,
          column: 16
        },
        end: {
          line: 70,
          column: 17
        }
      },
      '38': {
        start: {
          line: 69,
          column: 18
        },
        end: {
          line: 69,
          column: 46
        }
      },
      '39': {
        start: {
          line: 71,
          column: 16
        },
        end: {
          line: 73,
          column: 17
        }
      },
      '40': {
        start: {
          line: 72,
          column: 18
        },
        end: {
          line: 72,
          column: 46
        }
      },
      '41': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 83,
          column: 10
        }
      },
      '42': {
        start: {
          line: 87,
          column: 16
        },
        end: {
          line: 87,
          column: 19
        }
      },
      '43': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '44': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 92,
          column: 9
        }
      },
      '45': {
        start: {
          line: 91,
          column: 10
        },
        end: {
          line: 91,
          column: 19
        }
      },
      '46': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 26
        }
      },
      '47': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 19
        }
      },
      '48': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 101,
          column: 7
        }
      },
      '49': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '50': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 98,
          column: 19
        }
      },
      '51': {
        start: {
          line: 100,
          column: 8
        },
        end: {
          line: 100,
          column: 26
        }
      },
      '52': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 19
        }
      },
      '53': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '54': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 106,
          column: 9
        }
      },
      '55': {
        start: {
          line: 105,
          column: 10
        },
        end: {
          line: 105,
          column: 19
        }
      },
      '56': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 26
        }
      },
      '57': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 63
        }
      },
      '58': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 34
        }
      },
      '59': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 113,
          column: 9
        }
      },
      '60': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 37
        }
      },
      '61': {
        start: {
          line: 116,
          column: 6
        },
        end: {
          line: 121,
          column: 9
        }
      },
      '62': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 120,
          column: 11
        }
      },
      '63': {
        start: {
          line: 118,
          column: 10
        },
        end: {
          line: 118,
          column: 61
        }
      },
      '64': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 119,
          column: 22
        }
      },
      '65': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 130,
          column: 7
        }
      },
      '66': {
        start: {
          line: 129,
          column: 8
        },
        end: {
          line: 129,
          column: 20
        }
      },
      '67': {
        start: {
          line: 131,
          column: 19
        },
        end: {
          line: 131,
          column: 73
        }
      },
      '68': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 66
        }
      },
      '69': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 136,
          column: 25
        }
      },
      '70': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 137,
          column: 47
        }
      },
      '71': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 138,
          column: 22
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 248
          },
          end: {
            line: 140,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 49,
            column: 76
          },
          end: {
            line: 49,
            column: 77
          }
        },
        loc: {
          start: {
            line: 49,
            column: 84
          },
          end: {
            line: 84,
            column: 7
          }
        },
        line: 49
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 111,
            column: 88
          },
          end: {
            line: 111,
            column: 89
          }
        },
        loc: {
          start: {
            line: 111,
            column: 96
          },
          end: {
            line: 113,
            column: 7
          }
        },
        line: 111
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 116,
            column: 53
          },
          end: {
            line: 116,
            column: 54
          }
        },
        loc: {
          start: {
            line: 116,
            column: 61
          },
          end: {
            line: 121,
            column: 7
          }
        },
        line: 116
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 117,
            column: 38
          },
          end: {
            line: 117,
            column: 39
          }
        },
        loc: {
          start: {
            line: 117,
            column: 46
          },
          end: {
            line: 120,
            column: 9
          }
        },
        line: 117
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 14,
            column: 18
          },
          end: {
            line: 14,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 14,
            column: 30
          },
          end: {
            line: 14,
            column: 33
          }
        }, {
          start: {
            line: 14,
            column: 37
          },
          end: {
            line: 14,
            column: 58
          }
        }],
        line: 14
      },
      '1': {
        loc: {
          start: {
            line: 14,
            column: 37
          },
          end: {
            line: 14,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 14,
            column: 49
          },
          end: {
            line: 14,
            column: 52
          }
        }, {
          start: {
            line: 14,
            column: 55
          },
          end: {
            line: 14,
            column: 58
          }
        }],
        line: 14
      },
      '2': {
        loc: {
          start: {
            line: 16,
            column: 34
          },
          end: {
            line: 16,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 16,
            column: 46
          },
          end: {
            line: 16,
            column: 70
          }
        }, {
          start: {
            line: 16,
            column: 74
          },
          end: {
            line: 16,
            column: 140
          }
        }],
        line: 16
      },
      '3': {
        loc: {
          start: {
            line: 16,
            column: 74
          },
          end: {
            line: 16,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 16,
            column: 86
          },
          end: {
            line: 16,
            column: 112
          }
        }, {
          start: {
            line: 16,
            column: 115
          },
          end: {
            line: 16,
            column: 140
          }
        }],
        line: 16
      },
      '4': {
        loc: {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }, {
          start: {
            line: 21,
            column: 6
          },
          end: {
            line: 23,
            column: 7
          }
        }],
        line: 21
      },
      '5': {
        loc: {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }, {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 26,
            column: 7
          }
        }],
        line: 24
      },
      '6': {
        loc: {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 30,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 30
      },
      '7': {
        loc: {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }, {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 35,
            column: 7
          }
        }],
        line: 33
      },
      '8': {
        loc: {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 39,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 39
      },
      '9': {
        loc: {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        }, {
          start: {
            line: 42,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        }],
        line: 42
      },
      '10': {
        loc: {
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }, {
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }],
        line: 56
      },
      '11': {
        loc: {
          start: {
            line: 58,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        }, {
          start: {
            line: 58,
            column: 10
          },
          end: {
            line: 76,
            column: 11
          }
        }],
        line: 58
      },
      '12': {
        loc: {
          start: {
            line: 62,
            column: 16
          },
          end: {
            line: 64,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 16
          },
          end: {
            line: 64,
            column: 17
          }
        }, {
          start: {
            line: 62,
            column: 16
          },
          end: {
            line: 64,
            column: 17
          }
        }],
        line: 62
      },
      '13': {
        loc: {
          start: {
            line: 62,
            column: 20
          },
          end: {
            line: 62,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 62,
            column: 20
          },
          end: {
            line: 62,
            column: 33
          }
        }, {
          start: {
            line: 62,
            column: 37
          },
          end: {
            line: 62,
            column: 47
          }
        }],
        line: 62
      },
      '14': {
        loc: {
          start: {
            line: 65,
            column: 16
          },
          end: {
            line: 67,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 65,
            column: 16
          },
          end: {
            line: 67,
            column: 17
          }
        }, {
          start: {
            line: 65,
            column: 16
          },
          end: {
            line: 67,
            column: 17
          }
        }],
        line: 65
      },
      '15': {
        loc: {
          start: {
            line: 68,
            column: 16
          },
          end: {
            line: 70,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 16
          },
          end: {
            line: 70,
            column: 17
          }
        }, {
          start: {
            line: 68,
            column: 16
          },
          end: {
            line: 70,
            column: 17
          }
        }],
        line: 68
      },
      '16': {
        loc: {
          start: {
            line: 71,
            column: 16
          },
          end: {
            line: 73,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 16
          },
          end: {
            line: 73,
            column: 17
          }
        }, {
          start: {
            line: 71,
            column: 16
          },
          end: {
            line: 73,
            column: 17
          }
        }],
        line: 71
      },
      '17': {
        loc: {
          start: {
            line: 79,
            column: 28
          },
          end: {
            line: 83,
            column: 9
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 28
          },
          end: {
            line: 79,
            column: 39
          }
        }, {
          start: {
            line: 79,
            column: 43
          },
          end: {
            line: 83,
            column: 9
          }
        }],
        line: 79
      },
      '18': {
        loc: {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }, {
          start: {
            line: 90,
            column: 8
          },
          end: {
            line: 92,
            column: 9
          }
        }],
        line: 90
      },
      '19': {
        loc: {
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }, {
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }],
        line: 97
      },
      '20': {
        loc: {
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 106,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 106,
            column: 9
          }
        }, {
          start: {
            line: 104,
            column: 8
          },
          end: {
            line: 106,
            column: 9
          }
        }],
        line: 104
      },
      '21': {
        loc: {
          start: {
            line: 128,
            column: 6
          },
          end: {
            line: 130,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 128,
            column: 6
          },
          end: {
            line: 130,
            column: 7
          }
        }, {
          start: {
            line: 128,
            column: 6
          },
          end: {
            line: 130,
            column: 7
          }
        }],
        line: 128
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2f3quhkz76.s[0]++;
angular.module('app').controller('indicatorsRightCtrl', function ($scope, $stateParams, $deltaUnite, $deltahttp, $deltaTypeIndicateur, $rootScope, $translate, $deltaLocation, $CRUDService, SweetAlert, $deltadate, $deltaPeriode, $filter, $uibModal, $log) {
  cov_2f3quhkz76.f[0]++;

  var PATH = (cov_2f3quhkz76.s[1]++, 'Indicateurs');
  var PATHWORKFLOW = (cov_2f3quhkz76.s[2]++, 'Workflow');

  cov_2f3quhkz76.s[3]++;
  $scope.privileges = {
    global: false,
    collect: false,
    planning: false
  };

  cov_2f3quhkz76.s[4]++;
  $scope.right = {
    searchText: '',
    checkAllUser: function checkAllUser(type) {
      var key = (cov_2f3quhkz76.s[5]++, type == 1 ? (cov_2f3quhkz76.b[0][0]++, 'g') : (cov_2f3quhkz76.b[0][1]++, type == 2 ? (cov_2f3quhkz76.b[1][0]++, 'p') : (cov_2f3quhkz76.b[1][1]++, 'c')));
      cov_2f3quhkz76.s[6]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var user = _step.value;
          cov_2f3quhkz76.s[7]++;

          this.data[key][user.id] = type == 1 ? (cov_2f3quhkz76.b[2][0]++, $scope.privileges.global) : (cov_2f3quhkz76.b[2][1]++, type == 2 ? (cov_2f3quhkz76.b[3][0]++, $scope.privileges.planning) : (cov_2f3quhkz76.b[3][1]++, $scope.privileges.collect));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },

    loading: false,
    globalControl: function globalControl(item, idUser, superAdmin) {
      cov_2f3quhkz76.s[8]++;

      if (superAdmin) {
        cov_2f3quhkz76.b[4][0]++;
        cov_2f3quhkz76.s[9]++;

        return true;
      } else {
        cov_2f3quhkz76.b[4][1]++;
      }
      cov_2f3quhkz76.s[10]++;
      if (item.DROITINDIC.g[idUser] == true) {
        cov_2f3quhkz76.b[5][0]++;
        cov_2f3quhkz76.s[11]++;

        return true;
      } else {
        cov_2f3quhkz76.b[5][1]++;
      }
      cov_2f3quhkz76.s[12]++;
      return false;
    },
    PlanningControl: function PlanningControl(item, idUser, superAdmin) {
      cov_2f3quhkz76.s[13]++;

      if (superAdmin) {
        cov_2f3quhkz76.b[6][0]++;
        cov_2f3quhkz76.s[14]++;

        return true;
      } else {
        cov_2f3quhkz76.b[6][1]++;
      }
      cov_2f3quhkz76.s[15]++;
      if (item.DROITINDIC.p[idUser] == true) {
        cov_2f3quhkz76.b[7][0]++;
        cov_2f3quhkz76.s[16]++;

        return true;
      } else {
        cov_2f3quhkz76.b[7][1]++;
      }
      cov_2f3quhkz76.s[17]++;
      return false;
    },
    collectControl: function collectControl(item, idUser, superAdmin) {
      cov_2f3quhkz76.s[18]++;

      if (superAdmin) {
        cov_2f3quhkz76.b[8][0]++;
        cov_2f3quhkz76.s[19]++;

        return true;
      } else {
        cov_2f3quhkz76.b[8][1]++;
      }
      cov_2f3quhkz76.s[20]++;
      if (item.DROITINDIC.c[idUser] == true) {
        cov_2f3quhkz76.b[9][0]++;
        cov_2f3quhkz76.s[21]++;

        return true;
      } else {
        cov_2f3quhkz76.b[9][1]++;
      }
      cov_2f3quhkz76.s[22]++;
      return false;
    },
    open: function open() {
      cov_2f3quhkz76.s[23]++;

      $scope.right.loading = true;
      cov_2f3quhkz76.s[24]++;
      $CRUDService.getAll(PATH, { get: 'right', id: $scope.selectedItem.id }, function (data) {
        cov_2f3quhkz76.f[1]++;
        cov_2f3quhkz76.s[25]++;

        $scope.right.loading = false;
        var tamponDroit = (cov_2f3quhkz76.s[26]++, {
          g: {},
          p: {},
          c: {}
        });
        cov_2f3quhkz76.s[27]++;
        if (data.DROITINDIC) {
          cov_2f3quhkz76.b[10][0]++;

          var tab = (cov_2f3quhkz76.s[28]++, data.DROITINDIC.split('\t'));
          cov_2f3quhkz76.s[29]++;
          if (tab.length == 3) {
            cov_2f3quhkz76.b[11][0]++;
            cov_2f3quhkz76.s[30]++;

            for (var j = 0; j < tab.length; j++) {
              var ch = (cov_2f3quhkz76.s[31]++, tab[j].split(';'));
              cov_2f3quhkz76.s[32]++;
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = ch[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var itema = _step2.value;
                  cov_2f3quhkz76.s[33]++;

                  if ((cov_2f3quhkz76.b[13][0]++, itema == '') || (cov_2f3quhkz76.b[13][1]++, itema == 0)) {
                    cov_2f3quhkz76.b[12][0]++;
                    cov_2f3quhkz76.s[34]++;

                    continue;
                  } else {
                    cov_2f3quhkz76.b[12][1]++;
                  }
                  cov_2f3quhkz76.s[35]++;
                  if (j == 0) {
                    cov_2f3quhkz76.b[14][0]++;
                    cov_2f3quhkz76.s[36]++;

                    tamponDroit.g[itema] = true;
                  } else {
                    cov_2f3quhkz76.b[14][1]++;
                  }
                  cov_2f3quhkz76.s[37]++;
                  if (j == 1) {
                    cov_2f3quhkz76.b[15][0]++;
                    cov_2f3quhkz76.s[38]++;

                    tamponDroit.p[itema] = true;
                  } else {
                    cov_2f3quhkz76.b[15][1]++;
                  }
                  cov_2f3quhkz76.s[39]++;
                  if (j == 2) {
                    cov_2f3quhkz76.b[16][0]++;
                    cov_2f3quhkz76.s[40]++;

                    tamponDroit.c[itema] = true;
                  } else {
                    cov_2f3quhkz76.b[16][1]++;
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } else {
            cov_2f3quhkz76.b[11][1]++;
          }
        } else {
          cov_2f3quhkz76.b[10][1]++;
        }

        cov_2f3quhkz76.s[41]++;
        $scope.right.data = (cov_2f3quhkz76.b[17][0]++, tamponDroit) || (cov_2f3quhkz76.b[17][1]++, {
          g: {},
          p: {},
          c: {}
        });
      });
    },
    save: function save() {
      var tab = (cov_2f3quhkz76.s[42]++, ';');

      cov_2f3quhkz76.s[43]++;
      for (var step in this.data.g) {
        cov_2f3quhkz76.s[44]++;

        if (!this.data.g[step]) {
          cov_2f3quhkz76.b[18][0]++;
          cov_2f3quhkz76.s[45]++;

          continue;
        } else {
          cov_2f3quhkz76.b[18][1]++;
        }
        cov_2f3quhkz76.s[46]++;
        tab += step + ';';
      }
      cov_2f3quhkz76.s[47]++;
      tab += '\t;';
      cov_2f3quhkz76.s[48]++;
      for (var _step3 in this.data.p) {
        cov_2f3quhkz76.s[49]++;

        if (!this.data.p[_step3]) {
          cov_2f3quhkz76.b[19][0]++;
          cov_2f3quhkz76.s[50]++;

          continue;
        } else {
          cov_2f3quhkz76.b[19][1]++;
        }
        cov_2f3quhkz76.s[51]++;
        tab += _step3 + ';';
      }
      cov_2f3quhkz76.s[52]++;
      tab += '\t;';
      cov_2f3quhkz76.s[53]++;
      for (var _step4 in this.data.c) {
        cov_2f3quhkz76.s[54]++;

        if (!this.data.c[_step4]) {
          cov_2f3quhkz76.b[20][0]++;
          cov_2f3quhkz76.s[55]++;

          continue;
        } else {
          cov_2f3quhkz76.b[20][1]++;
        }
        cov_2f3quhkz76.s[56]++;
        tab += _step4 + ';';
      }
      cov_2f3quhkz76.s[57]++;
      $scope.selectedItem.DROITINDIC = angular.copy(this.data);
      cov_2f3quhkz76.s[58]++;
      $scope.right.loading = true;
      cov_2f3quhkz76.s[59]++;
      $CRUDService.save(PATH, { action: 'saveR', id: $scope.selectedItem.id, data: tab }, function (data) {
        cov_2f3quhkz76.f[2]++;
        cov_2f3quhkz76.s[60]++;

        $scope.right.loading = false;
      });
    },
    getUser: function getUser() {
      cov_2f3quhkz76.s[61]++;

      $CRUDService.getAll('User', { get: 'NonAdmin' }, function (data) {
        cov_2f3quhkz76.f[3]++;
        cov_2f3quhkz76.s[62]++;

        $scope.right.users = data.map(function (item) {
          cov_2f3quhkz76.f[4]++;
          cov_2f3quhkz76.s[63]++;

          item.imageUrl = $deltahttp.getUserPicture(item.id);
          cov_2f3quhkz76.s[64]++;
          return item;
        });
      });
    },
    searchInList: function searchInList(value) {
      cov_2f3quhkz76.s[65]++;

      // $log.log('----searchInList----');
      // $log.log(value);
      // $log.log($scope.right.searchText);
      // $log.log($scope.right.users);
      if ($scope.right.searchText == '') {
        cov_2f3quhkz76.b[21][0]++;
        cov_2f3quhkz76.s[66]++;

        return true;
      } else {
        cov_2f3quhkz76.b[21][1]++;
      }
      var name = (cov_2f3quhkz76.s[67]++, (value.NOMUSER + ' ' + value.PRENOMUSER).toUpperCase());
      cov_2f3quhkz76.s[68]++;
      return name.includes($scope.right.searchText.toUpperCase());
    }
  };

  cov_2f3quhkz76.s[69]++;
  $scope.right.getUser();
  cov_2f3quhkz76.s[70]++;
  $scope.selectedItem = $stateParams.indicator;
  cov_2f3quhkz76.s[71]++;
  $scope.right.open();
  // $scope.right.open();
});
'use strict';

var cov_1wa1enpnx2 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/time_division/time_divisionCtrl.js',
      hash = '70a0b24092a3f3d58326d4cf4fe4fb194457699a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/time_division/time_divisionCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 92,
          column: 3
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 37
        }
      },
      '2': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 8,
          column: 3
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 11
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 62
        }
      },
      '5': {
        start: {
          line: 10,
          column: 15
        },
        end: {
          line: 10,
          column: 24
        }
      },
      '6': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 81,
          column: 4
        }
      },
      '7': {
        start: {
          line: 30,
          column: 19
        },
        end: {
          line: 30,
          column: 33
        }
      },
      '8': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 30
        }
      },
      '9': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 26
        }
      },
      '10': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 32
        }
      },
      '11': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 46,
          column: 9
        }
      },
      '12': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '13': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 59
        }
      },
      '14': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 58
        }
      },
      '15': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 33
        }
      },
      '16': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 45
        }
      },
      '17': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 82
        }
      },
      '18': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 45,
          column: 9
        }
      },
      '19': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 44,
          column: 39
        }
      },
      '20': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '21': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 15
        }
      },
      '22': {
        start: {
          line: 52,
          column: 20
        },
        end: {
          line: 52,
          column: 51
        }
      },
      '23': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 53,
          column: 65
        }
      },
      '24': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 54,
          column: 62
        }
      },
      '25': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 30
        }
      },
      '26': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 72,
          column: 9
        }
      },
      '27': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 33
        }
      },
      '28': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 60,
          column: 9
        }
      },
      '29': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 17
        }
      },
      '30': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 71,
          column: 9
        }
      },
      '31': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 70,
          column: 11
        }
      },
      '32': {
        start: {
          line: 63,
          column: 12
        },
        end: {
          line: 63,
          column: 87
        }
      },
      '33': {
        start: {
          line: 64,
          column: 12
        },
        end: {
          line: 64,
          column: 77
        }
      },
      '34': {
        start: {
          line: 65,
          column: 12
        },
        end: {
          line: 67,
          column: 13
        }
      },
      '35': {
        start: {
          line: 66,
          column: 14
        },
        end: {
          line: 66,
          column: 20
        }
      },
      '36': {
        start: {
          line: 68,
          column: 17
        },
        end: {
          line: 70,
          column: 11
        }
      },
      '37': {
        start: {
          line: 69,
          column: 12
        },
        end: {
          line: 69,
          column: 65
        }
      },
      '38': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 75,
          column: 64
        }
      },
      '39': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 125
        }
      },
      '40': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 79,
          column: 7
        }
      },
      '41': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 60
        }
      },
      '42': {
        start: {
          line: 83,
          column: 2
        },
        end: {
          line: 83,
          column: 26
        }
      },
      '43': {
        start: {
          line: 87,
          column: 2
        },
        end: {
          line: 87,
          column: 24
        }
      },
      '44': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 88,
          column: 22
        }
      },
      '45': {
        start: {
          line: 91,
          column: 2
        },
        end: {
          line: 91,
          column: 31
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 54
          },
          end: {
            line: 4,
            column: 55
          }
        },
        loc: {
          start: {
            line: 4,
            column: 167
          },
          end: {
            line: 92,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 34,
            column: 46
          },
          end: {
            line: 34,
            column: 47
          }
        },
        loc: {
          start: {
            line: 34,
            column: 54
          },
          end: {
            line: 46,
            column: 7
          }
        },
        line: 34
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 56,
            column: 88
          },
          end: {
            line: 56,
            column: 89
          }
        },
        loc: {
          start: {
            line: 56,
            column: 96
          },
          end: {
            line: 72,
            column: 7
          }
        },
        line: 56
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }, {
          start: {
            line: 6,
            column: 2
          },
          end: {
            line: 8,
            column: 3
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 42,
            column: 43
          },
          end: {
            line: 42,
            column: 81
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 42,
            column: 43
          },
          end: {
            line: 42,
            column: 75
          }
        }, {
          start: {
            line: 42,
            column: 79
          },
          end: {
            line: 42,
            column: 81
          }
        }],
        line: 42
      },
      '2': {
        loc: {
          start: {
            line: 43,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 43,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        }, {
          start: {
            line: 43,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        }],
        line: 43
      },
      '3': {
        loc: {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }, {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }],
        line: 49
      },
      '4': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }, {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }],
        line: 58
      },
      '5': {
        loc: {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }, {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }],
        line: 62
      },
      '6': {
        loc: {
          start: {
            line: 65,
            column: 12
          },
          end: {
            line: 67,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 65,
            column: 12
          },
          end: {
            line: 67,
            column: 13
          }
        }, {
          start: {
            line: 65,
            column: 12
          },
          end: {
            line: 67,
            column: 13
          }
        }],
        line: 65
      },
      '7': {
        loc: {
          start: {
            line: 68,
            column: 17
          },
          end: {
            line: 70,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 17
          },
          end: {
            line: 70,
            column: 11
          }
        }, {
          start: {
            line: 68,
            column: 17
          },
          end: {
            line: 70,
            column: 11
          }
        }],
        line: 68
      },
      '8': {
        loc: {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }, {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 79,
            column: 7
          }
        }],
        line: 77
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1wa1enpnx2.s[0]++;
/**
* Division Time - controller
*/
angular.module('app').controller('time_divisionCtrl', function ($scope, SweetAlert, $rootScope, $deltadate, $translate, $CRUDService, $otherHttpService, $location, $filter) {
  cov_1wa1enpnx2.f[0]++;
  cov_1wa1enpnx2.s[1]++;

  $rootScope.processPageRight('1_9');
  cov_1wa1enpnx2.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1wa1enpnx2.b[0][0]++;
    cov_1wa1enpnx2.s[3]++;

    return;
  } else {
    cov_1wa1enpnx2.b[0][1]++;
  }
  cov_1wa1enpnx2.s[4]++;
  $scope.currentRight = angular.copy($rootScope.currentRight);
  var PATH = (cov_1wa1enpnx2.s[5]++, 'Periode');
  // Decoupage Périodique

  cov_1wa1enpnx2.s[6]++;
  $scope.timeDivision = {
    listPeriod: [{
      label: $translate.instant('COMMON.YEAR'),
      id: '1'
    }, {
      label: $translate.instant('COMMON.SEM'),
      id: '2'
    }, {
      label: $translate.instant('COMMON.TRIM'),
      id: '4'
    }, {
      label: $translate.instant('COMMON.MOIS'),
      id: '12'
    }],
    getAll: function getAll() {
      var toda = (cov_1wa1enpnx2.s[7]++, Date.newDate());
      cov_1wa1enpnx2.s[8]++;
      $scope.isloading = true;
      cov_1wa1enpnx2.s[9]++;
      this.listAnnee = [];
      cov_1wa1enpnx2.s[10]++;
      $scope.listPlanAnnee = [];
      cov_1wa1enpnx2.s[11]++;
      $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
        cov_1wa1enpnx2.f[1]++;
        cov_1wa1enpnx2.s[12]++;

        for (var i = 0; i < data.length; i++) {
          cov_1wa1enpnx2.s[13]++;

          data[i].PTBA_PERIOD = data[i].PTBA_PERIOD == 1;
          cov_1wa1enpnx2.s[14]++;
          $scope.listPlanAnnee.push(data[i].ANNEE_PERIOD);
        }

        cov_1wa1enpnx2.s[15]++;
        $scope.isloading = false;
        cov_1wa1enpnx2.s[16]++;
        $scope.timeDivision.listAnnee = data;
        cov_1wa1enpnx2.s[17]++;
        $scope.timeDivision.selectedItem = (cov_1wa1enpnx2.b[1][0]++, $scope.timeDivision.listAnnee[0]) || (cov_1wa1enpnx2.b[1][1]++, {});
        cov_1wa1enpnx2.s[18]++;
        if ($scope.timeDivision.selectedItem !== {}) {
          cov_1wa1enpnx2.b[2][0]++;
          cov_1wa1enpnx2.s[19]++;

          $scope.timeDivision.select();
        } else {
          cov_1wa1enpnx2.b[2][1]++;
        }
      });
    },
    validate: function validate() {
      cov_1wa1enpnx2.s[20]++;

      if (!this.selectedItemTampon.period) {
        cov_1wa1enpnx2.b[3][0]++;
        cov_1wa1enpnx2.s[21]++;

        return;
      } else {
        cov_1wa1enpnx2.b[3][1]++;
      }
      var value = (cov_1wa1enpnx2.s[22]++, angular.copy(this.selectedItem));
      cov_1wa1enpnx2.s[23]++;
      value.DECOUPAGE_PERIOD = this.selectedItemTampon.period.id;
      cov_1wa1enpnx2.s[24]++;
      value.PTBA_PERIOD = this.selectedItemTampon.PTBA_PERIOD;
      cov_1wa1enpnx2.s[25]++;
      $scope.isloading = true;
      cov_1wa1enpnx2.s[26]++;
      $CRUDService.save(PATH, { action: 'save_periode', valeur: $filter('json')(value) }, function (data) {
        cov_1wa1enpnx2.f[2]++;
        cov_1wa1enpnx2.s[27]++;

        $scope.isloading = false;
        cov_1wa1enpnx2.s[28]++;
        if (data == -1) {
          cov_1wa1enpnx2.b[4][0]++;
          cov_1wa1enpnx2.s[29]++;

          return;
        } else {
          cov_1wa1enpnx2.b[4][1]++;
        }
        cov_1wa1enpnx2.s[30]++;
        for (var i = 0; i < $scope.timeDivision.listAnnee.length; i++) {
          cov_1wa1enpnx2.s[31]++;

          if ($scope.timeDivision.selectedItem.ANNEE_PERIOD == $scope.timeDivision.listAnnee[i].ANNEE_PERIOD) {
            cov_1wa1enpnx2.b[5][0]++;
            cov_1wa1enpnx2.s[32]++;

            $scope.timeDivision.listAnnee[i].DECOUPAGE_PERIOD = value.DECOUPAGE_PERIOD;
            cov_1wa1enpnx2.s[33]++;
            $scope.timeDivision.listAnnee[i].PTBA_PERIOD = value.PTBA_PERIOD;
            cov_1wa1enpnx2.s[34]++;
            if (!value.PTBA_PERIOD) {
              cov_1wa1enpnx2.b[6][0]++;
              cov_1wa1enpnx2.s[35]++;

              break;
            } else {
              cov_1wa1enpnx2.b[6][1]++;
            }
          } else {
              cov_1wa1enpnx2.b[5][1]++;
              cov_1wa1enpnx2.s[36]++;
              if (value.PTBA_PERIOD) {
                cov_1wa1enpnx2.b[7][0]++;
                cov_1wa1enpnx2.s[37]++;

                $scope.timeDivision.listAnnee[i].PTBA_PERIOD = false;
              } else {
                cov_1wa1enpnx2.b[7][1]++;
              }
            }
        }
      });
    },
    select: function select() {
      cov_1wa1enpnx2.s[38]++;

      this.selectedItemTampon = angular.copy(this.selectedItem);
      cov_1wa1enpnx2.s[39]++;
      this.selectedItemTampon.period = $filter('filter')(this.listPeriod, { id: this.selectedItem.DECOUPAGE_PERIOD }, true)[0];
      cov_1wa1enpnx2.s[40]++;
      if (!this.selectedItemTampon.period) {
        cov_1wa1enpnx2.b[8][0]++;
        cov_1wa1enpnx2.s[41]++;

        this.selectedItemTampon.period = this.listPeriod[0];
      } else {
        cov_1wa1enpnx2.b[8][1]++;
      }
    }
  };

  cov_1wa1enpnx2.s[42]++;
  $scope.isloading = true;
  // $scope.listAnnee = [];
  // $scope.choise.selectedItem = {};
  // $scope.listPlanAnnee = [];
  cov_1wa1enpnx2.s[43]++;
  $scope.beginYear = {};
  cov_1wa1enpnx2.s[44]++;
  $scope.endYear = {};
  // $scope.selectedItemTampon = {};

  cov_1wa1enpnx2.s[45]++;
  $scope.timeDivision.getAll();
});
'use strict';

var cov_2rgc1kue89 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logframe/logFrameGlobalCtrl.js',
      hash = '3edb9d428be51e2954739c4cf2ba52b36cb8474b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logframe/logFrameGlobalCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 0
        },
        end: {
          line: 29,
          column: 3
        }
      },
      '1': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 24
        }
      },
      '2': {
        start: {
          line: 7,
          column: 28
        },
        end: {
          line: 7,
          column: 75
        }
      },
      '3': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 47
        }
      },
      '4': {
        start: {
          line: 11,
          column: 19
        },
        end: {
          line: 11,
          column: 61
        }
      },
      '5': {
        start: {
          line: 13,
          column: 20
        },
        end: {
          line: 13,
          column: 64
        }
      },
      '6': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 63
        }
      },
      '7': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 20,
          column: 4
        }
      },
      '8': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 67
        }
      },
      '9': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 59
        }
      },
      '10': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '11': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 26,
          column: 8
        }
      },
      '12': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 50
        }
      },
      '13': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 27,
          column: 66
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 55
          },
          end: {
            line: 5,
            column: 56
          }
        },
        loc: {
          start: {
            line: 5,
            column: 141
          },
          end: {
            line: 29,
            column: 1
          }
        },
        line: 5
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 17,
            column: 22
          },
          end: {
            line: 17,
            column: 23
          }
        },
        loc: {
          start: {
            line: 17,
            column: 34
          },
          end: {
            line: 20,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 39
          },
          end: {
            line: 23,
            column: 40
          }
        },
        loc: {
          start: {
            line: 23,
            column: 45
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 23
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 74
          },
          end: {
            line: 24,
            column: 75
          }
        },
        loc: {
          start: {
            line: 24,
            column: 86
          },
          end: {
            line: 26,
            column: 5
          }
        },
        line: 24
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 20
          },
          end: {
            line: 13,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 13,
            column: 20
          },
          end: {
            line: 13,
            column: 59
          }
        }, {
          start: {
            line: 13,
            column: 63
          },
          end: {
            line: 13,
            column: 64
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 15,
            column: 27
          },
          end: {
            line: 15,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 15,
            column: 27
          },
          end: {
            line: 15,
            column: 57
          }
        }, {
          start: {
            line: 15,
            column: 61
          },
          end: {
            line: 15,
            column: 62
          }
        }],
        line: 15
      },
      '2': {
        loc: {
          start: {
            line: 18,
            column: 29
          },
          end: {
            line: 18,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 18,
            column: 61
          },
          end: {
            line: 18,
            column: 62
          }
        }, {
          start: {
            line: 18,
            column: 65
          },
          end: {
            line: 18,
            column: 66
          }
        }],
        line: 18
      },
      '3': {
        loc: {
          start: {
            line: 25,
            column: 13
          },
          end: {
            line: 25,
            column: 49
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 25,
            column: 43
          },
          end: {
            line: 25,
            column: 44
          }
        }, {
          start: {
            line: 25,
            column: 47
          },
          end: {
            line: 25,
            column: 49
          }
        }],
        line: 25
      },
      '4': {
        loc: {
          start: {
            line: 27,
            column: 43
          },
          end: {
            line: 27,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 27,
            column: 43
          },
          end: {
            line: 27,
            column: 58
          }
        }, {
          start: {
            line: 27,
            column: 62
          },
          end: {
            line: 27,
            column: 63
          }
        }],
        line: 27
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2rgc1kue89.s[0]++;
/**
 * Activity  - controller
 */

angular.module('app').controller('logFrameGlobalCtrl', function ($scope, $deltaLogLevel, $stateParams, $rootScope, $state, $translate, $deltahttp) {
  cov_2rgc1kue89.f[0]++;
  cov_2rgc1kue89.s[1]++;

  $scope.listLevel = [];
  var LOG_FRAME_LAST_ID = (cov_2rgc1kue89.s[2]++, 'log_frame_last_item_' + $deltahttp.getProjet());

  cov_2rgc1kue89.s[3]++;
  $rootScope.setActivityAccessFrom('logframe');

  var KEY_VIEW = (cov_2rgc1kue89.s[4]++, 'log_frame_view_' + $deltahttp.getProjet());

  var defaultId = (cov_2rgc1kue89.s[5]++, (cov_2rgc1kue89.b[0][0]++, localStorage.getItem(LOG_FRAME_LAST_ID)) || (cov_2rgc1kue89.b[0][1]++, 0));

  cov_2rgc1kue89.s[6]++;
  $scope.resultframework = (cov_2rgc1kue89.b[1][0]++, localStorage.getItem(KEY_VIEW)) || (cov_2rgc1kue89.b[1][1]++, 0);

  cov_2rgc1kue89.s[7]++;
  $scope.changeView = function () {
    cov_2rgc1kue89.f[1]++;
    cov_2rgc1kue89.s[8]++;

    $scope.resultframework = $scope.resultframework == 1 ? (cov_2rgc1kue89.b[2][0]++, 0) : (cov_2rgc1kue89.b[2][1]++, 1);
    cov_2rgc1kue89.s[9]++;
    localStorage.setItem(KEY_VIEW, $scope.resultframework);
  };

  // $deltaPlanLogique.addController($scope);
  cov_2rgc1kue89.s[10]++;
  $deltaLogLevel.addController($scope, function () {
    cov_2rgc1kue89.f[2]++;
    cov_2rgc1kue89.s[11]++;

    $scope.listLevel = angular.copy($scope.listData_LogLevels_bksb).sort(function (l1, l2) {
      cov_2rgc1kue89.f[3]++;
      cov_2rgc1kue89.s[12]++;

      return l1.Niveau_CL < l2.Niveau_CL ? (cov_2rgc1kue89.b[3][0]++, 1) : (cov_2rgc1kue89.b[3][1]++, -1);
    });
    cov_2rgc1kue89.s[13]++;
    $state.go('logframe.information', { id: (cov_2rgc1kue89.b[4][0]++, $stateParams.id) || (cov_2rgc1kue89.b[4][1]++, 1) });
  });
});
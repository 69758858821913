'use strict';

var cov_1b6amftgbn = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/flag/flagCtrl.js',
      hash = '9d89cd9f479c977db02336e9b09b6a50a9a9fc1f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/flag/flagCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 1
        },
        end: {
          line: 116,
          column: 4
        }
      },
      '1': {
        start: {
          line: 2,
          column: 3
        },
        end: {
          line: 2,
          column: 38
        }
      },
      '2': {
        start: {
          line: 3,
          column: 3
        },
        end: {
          line: 5,
          column: 4
        }
      },
      '3': {
        start: {
          line: 4,
          column: 5
        },
        end: {
          line: 4,
          column: 12
        }
      },
      '4': {
        start: {
          line: 6,
          column: 3
        },
        end: {
          line: 6,
          column: 66
        }
      },
      '5': {
        start: {
          line: 7,
          column: 3
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '6': {
        start: {
          line: 8,
          column: 5
        },
        end: {
          line: 14,
          column: 28
        }
      },
      '7': {
        start: {
          line: 15,
          column: 5
        },
        end: {
          line: 15,
          column: 36
        }
      },
      '8': {
        start: {
          line: 18,
          column: 3
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '9': {
        start: {
          line: 19,
          column: 5
        },
        end: {
          line: 19,
          column: 46
        }
      },
      '10': {
        start: {
          line: 22,
          column: 3
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '11': {
        start: {
          line: 23,
          column: 5
        },
        end: {
          line: 23,
          column: 25
        }
      },
      '12': {
        start: {
          line: 24,
          column: 5
        },
        end: {
          line: 24,
          column: 32
        }
      },
      '13': {
        start: {
          line: 25,
          column: 5
        },
        end: {
          line: 25,
          column: 68
        }
      },
      '14': {
        start: {
          line: 27,
          column: 3
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '15': {
        start: {
          line: 28,
          column: 5
        },
        end: {
          line: 28,
          column: 25
        }
      },
      '16': {
        start: {
          line: 32,
          column: 5
        },
        end: {
          line: 67,
          column: 6
        }
      },
      '17': {
        start: {
          line: 33,
          column: 7
        },
        end: {
          line: 35,
          column: 8
        }
      },
      '18': {
        start: {
          line: 34,
          column: 9
        },
        end: {
          line: 34,
          column: 18
        }
      },
      '19': {
        start: {
          line: 36,
          column: 7
        },
        end: {
          line: 43,
          column: 8
        }
      },
      '20': {
        start: {
          line: 37,
          column: 9
        },
        end: {
          line: 40,
          column: 11
        }
      },
      '21': {
        start: {
          line: 42,
          column: 9
        },
        end: {
          line: 42,
          column: 16
        }
      },
      '22': {
        start: {
          line: 44,
          column: 7
        },
        end: {
          line: 50,
          column: 8
        }
      },
      '23': {
        start: {
          line: 45,
          column: 9
        },
        end: {
          line: 48,
          column: 11
        }
      },
      '24': {
        start: {
          line: 49,
          column: 9
        },
        end: {
          line: 49,
          column: 16
        }
      },
      '25': {
        start: {
          line: 52,
          column: 7
        },
        end: {
          line: 58,
          column: 8
        }
      },
      '26': {
        start: {
          line: 53,
          column: 9
        },
        end: {
          line: 56,
          column: 11
        }
      },
      '27': {
        start: {
          line: 57,
          column: 9
        },
        end: {
          line: 57,
          column: 16
        }
      },
      '28': {
        start: {
          line: 59,
          column: 7
        },
        end: {
          line: 66,
          column: 8
        }
      },
      '29': {
        start: {
          line: 60,
          column: 9
        },
        end: {
          line: 63,
          column: 11
        }
      },
      '30': {
        start: {
          line: 65,
          column: 9
        },
        end: {
          line: 65,
          column: 16
        }
      },
      '31': {
        start: {
          line: 69,
          column: 5
        },
        end: {
          line: 74,
          column: 6
        }
      },
      '32': {
        start: {
          line: 70,
          column: 7
        },
        end: {
          line: 73,
          column: 8
        }
      },
      '33': {
        start: {
          line: 71,
          column: 9
        },
        end: {
          line: 71,
          column: 46
        }
      },
      '34': {
        start: {
          line: 72,
          column: 9
        },
        end: {
          line: 72,
          column: 15
        }
      },
      '35': {
        start: {
          line: 75,
          column: 5
        },
        end: {
          line: 75,
          column: 69
        }
      },
      '36': {
        start: {
          line: 76,
          column: 5
        },
        end: {
          line: 76,
          column: 19
        }
      },
      '37': {
        start: {
          line: 79,
          column: 3
        },
        end: {
          line: 84,
          column: 5
        }
      },
      '38': {
        start: {
          line: 83,
          column: 5
        },
        end: {
          line: 83,
          column: 17
        }
      },
      '39': {
        start: {
          line: 86,
          column: 3
        },
        end: {
          line: 112,
          column: 5
        }
      },
      '40': {
        start: {
          line: 87,
          column: 5
        },
        end: {
          line: 111,
          column: 8
        }
      },
      '41': {
        start: {
          line: 99,
          column: 7
        },
        end: {
          line: 101,
          column: 8
        }
      },
      '42': {
        start: {
          line: 100,
          column: 9
        },
        end: {
          line: 100,
          column: 16
        }
      },
      '43': {
        start: {
          line: 103,
          column: 21
        },
        end: {
          line: 103,
          column: 46
        }
      },
      '44': {
        start: {
          line: 105,
          column: 7
        },
        end: {
          line: 107,
          column: 8
        }
      },
      '45': {
        start: {
          line: 106,
          column: 9
        },
        end: {
          line: 106,
          column: 38
        }
      },
      '46': {
        start: {
          line: 109,
          column: 7
        },
        end: {
          line: 109,
          column: 89
        }
      },
      '47': {
        start: {
          line: 110,
          column: 7
        },
        end: {
          line: 110,
          column: 23
        }
      },
      '48': {
        start: {
          line: 113,
          column: 3
        },
        end: {
          line: 115,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 46
          },
          end: {
            line: 1,
            column: 47
          }
        },
        loc: {
          start: {
            line: 1,
            column: 94
          },
          end: {
            line: 116,
            column: 2
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 20
          },
          end: {
            line: 7,
            column: 21
          }
        },
        loc: {
          start: {
            line: 7,
            column: 32
          },
          end: {
            line: 16,
            column: 4
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 20
          },
          end: {
            line: 18,
            column: 21
          }
        },
        loc: {
          start: {
            line: 18,
            column: 36
          },
          end: {
            line: 20,
            column: 4
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 19
          },
          end: {
            line: 22,
            column: 20
          }
        },
        loc: {
          start: {
            line: 22,
            column: 31
          },
          end: {
            line: 26,
            column: 4
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 27,
            column: 21
          },
          end: {
            line: 27,
            column: 22
          }
        },
        loc: {
          start: {
            line: 27,
            column: 33
          },
          end: {
            line: 77,
            column: 4
          }
        },
        line: 27
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 79,
            column: 20
          },
          end: {
            line: 79,
            column: 21
          }
        },
        loc: {
          start: {
            line: 79,
            column: 36
          },
          end: {
            line: 84,
            column: 4
          }
        },
        line: 79
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 86,
            column: 26
          },
          end: {
            line: 86,
            column: 27
          }
        },
        loc: {
          start: {
            line: 86,
            column: 42
          },
          end: {
            line: 112,
            column: 4
          }
        },
        line: 86
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 98,
            column: 8
          },
          end: {
            line: 98,
            column: 9
          }
        },
        loc: {
          start: {
            line: 98,
            column: 21
          },
          end: {
            line: 111,
            column: 6
          }
        },
        line: 98
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 3
          },
          end: {
            line: 5,
            column: 4
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 3
          },
          end: {
            line: 5,
            column: 4
          }
        }, {
          start: {
            line: 3,
            column: 3
          },
          end: {
            line: 5,
            column: 4
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 33,
            column: 7
          },
          end: {
            line: 35,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 7
          },
          end: {
            line: 35,
            column: 8
          }
        }, {
          start: {
            line: 33,
            column: 7
          },
          end: {
            line: 35,
            column: 8
          }
        }],
        line: 33
      },
      '2': {
        loc: {
          start: {
            line: 36,
            column: 7
          },
          end: {
            line: 43,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 7
          },
          end: {
            line: 43,
            column: 8
          }
        }, {
          start: {
            line: 36,
            column: 7
          },
          end: {
            line: 43,
            column: 8
          }
        }],
        line: 36
      },
      '3': {
        loc: {
          start: {
            line: 44,
            column: 7
          },
          end: {
            line: 50,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 7
          },
          end: {
            line: 50,
            column: 8
          }
        }, {
          start: {
            line: 44,
            column: 7
          },
          end: {
            line: 50,
            column: 8
          }
        }],
        line: 44
      },
      '4': {
        loc: {
          start: {
            line: 44,
            column: 11
          },
          end: {
            line: 44,
            column: 155
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 44,
            column: 12
          },
          end: {
            line: 44,
            column: 44
          }
        }, {
          start: {
            line: 44,
            column: 48
          },
          end: {
            line: 44,
            column: 80
          }
        }, {
          start: {
            line: 44,
            column: 86
          },
          end: {
            line: 44,
            column: 118
          }
        }, {
          start: {
            line: 44,
            column: 122
          },
          end: {
            line: 44,
            column: 154
          }
        }],
        line: 44
      },
      '5': {
        loc: {
          start: {
            line: 52,
            column: 7
          },
          end: {
            line: 58,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 7
          },
          end: {
            line: 58,
            column: 8
          }
        }, {
          start: {
            line: 52,
            column: 7
          },
          end: {
            line: 58,
            column: 8
          }
        }],
        line: 52
      },
      '6': {
        loc: {
          start: {
            line: 52,
            column: 12
          },
          end: {
            line: 52,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 52,
            column: 12
          },
          end: {
            line: 52,
            column: 45
          }
        }, {
          start: {
            line: 52,
            column: 49
          },
          end: {
            line: 52,
            column: 82
          }
        }],
        line: 52
      },
      '7': {
        loc: {
          start: {
            line: 59,
            column: 7
          },
          end: {
            line: 66,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 7
          },
          end: {
            line: 66,
            column: 8
          }
        }, {
          start: {
            line: 59,
            column: 7
          },
          end: {
            line: 66,
            column: 8
          }
        }],
        line: 59
      },
      '8': {
        loc: {
          start: {
            line: 59,
            column: 12
          },
          end: {
            line: 59,
            column: 82
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 12
          },
          end: {
            line: 59,
            column: 45
          }
        }, {
          start: {
            line: 59,
            column: 49
          },
          end: {
            line: 59,
            column: 82
          }
        }],
        line: 59
      },
      '9': {
        loc: {
          start: {
            line: 70,
            column: 7
          },
          end: {
            line: 73,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 7
          },
          end: {
            line: 73,
            column: 8
          }
        }, {
          start: {
            line: 70,
            column: 7
          },
          end: {
            line: 73,
            column: 8
          }
        }],
        line: 70
      },
      '10': {
        loc: {
          start: {
            line: 99,
            column: 7
          },
          end: {
            line: 101,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 7
          },
          end: {
            line: 101,
            column: 8
          }
        }, {
          start: {
            line: 99,
            column: 7
          },
          end: {
            line: 101,
            column: 8
          }
        }],
        line: 99
      },
      '11': {
        loc: {
          start: {
            line: 105,
            column: 7
          },
          end: {
            line: 107,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 7
          },
          end: {
            line: 107,
            column: 8
          }
        }, {
          start: {
            line: 105,
            column: 7
          },
          end: {
            line: 107,
            column: 8
          }
        }],
        line: 105
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1b6amftgbn.s[0]++;
angular.module('app').controller('flagCtrl', function ($scope, $rootScope, SweetAlert, $translate) {
  cov_1b6amftgbn.f[0]++;
  cov_1b6amftgbn.s[1]++;

  $rootScope.processPageRight('1_6');
  cov_1b6amftgbn.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1b6amftgbn.b[0][0]++;
    cov_1b6amftgbn.s[3]++;

    return;
  } else {
    cov_1b6amftgbn.b[0][1]++;
  }
  cov_1b6amftgbn.s[4]++;
  $scope.data = $rootScope.getCurrentProject().project.getFlag();
  cov_1b6amftgbn.s[5]++;
  $scope.newItem = function () {
    cov_1b6amftgbn.f[1]++;
    cov_1b6amftgbn.s[6]++;

    $scope.data = [{
      label: '',
      min: 0,
      max: 0,
      color: '32,32,32',
      id: 0
    }].concat($scope.data);
    cov_1b6amftgbn.s[7]++;
    $scope.setEdit($scope.data[0]);
  };

  cov_1b6amftgbn.s[8]++;
  $scope.setEdit = function (item) {
    cov_1b6amftgbn.f[2]++;
    cov_1b6amftgbn.s[9]++;

    $scope.validateItem = angular.copy(item);
  };

  cov_1b6amftgbn.s[10]++;
  $scope.cancel = function () {
    cov_1b6amftgbn.f[3]++;
    cov_1b6amftgbn.s[11]++;

    $scope.error = null;
    cov_1b6amftgbn.s[12]++;
    $scope.validateItem = null;
    cov_1b6amftgbn.s[13]++;
    $scope.data = $rootScope.getCurrentProject().project.getFlag();
  };
  cov_1b6amftgbn.s[14]++;
  $scope.validate = function () {
    cov_1b6amftgbn.f[4]++;
    cov_1b6amftgbn.s[15]++;

    $scope.error = null;
    /** *************************************************************** */
    // Verifier le libellé && la plage

    cov_1b6amftgbn.s[16]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var el = _step.value;
        cov_1b6amftgbn.s[17]++;

        if (el.id == $scope.validateItem.id) {
          cov_1b6amftgbn.b[1][0]++;
          cov_1b6amftgbn.s[18]++;

          continue;
        } else {
          cov_1b6amftgbn.b[1][1]++;
        }
        cov_1b6amftgbn.s[19]++;
        if (el.label.trim() == $scope.validateItem.label) {
          cov_1b6amftgbn.b[2][0]++;
          cov_1b6amftgbn.s[20]++;

          $scope.error = {
            label: $translate.instant('FLAG.ERROR.LABEL'),
            id: 1
          };

          cov_1b6amftgbn.s[21]++;
          return;
        } else {
          cov_1b6amftgbn.b[2][1]++;
        }
        cov_1b6amftgbn.s[22]++;
        if ((cov_1b6amftgbn.b[4][0]++, el.min < $scope.validateItem.max) && (cov_1b6amftgbn.b[4][1]++, el.max > $scope.validateItem.max) || (cov_1b6amftgbn.b[4][2]++, el.min < $scope.validateItem.min) && (cov_1b6amftgbn.b[4][3]++, el.max > $scope.validateItem.min)) {
          cov_1b6amftgbn.b[3][0]++;
          cov_1b6amftgbn.s[23]++;

          $scope.error = {
            label: $translate.instant('FLAG.ERROR.CHEVAL'),
            id: 2
          };
          cov_1b6amftgbn.s[24]++;
          return;
        } else {
          cov_1b6amftgbn.b[3][1]++;
        }

        cov_1b6amftgbn.s[25]++;
        if ((cov_1b6amftgbn.b[6][0]++, el.min >= $scope.validateItem.min) && (cov_1b6amftgbn.b[6][1]++, el.max <= $scope.validateItem.max)) {
          cov_1b6amftgbn.b[5][0]++;
          cov_1b6amftgbn.s[26]++;

          $scope.error = {
            label: $translate.instant('FLAG.ERROR.IN'),
            id: 3
          };
          cov_1b6amftgbn.s[27]++;
          return;
        } else {
          cov_1b6amftgbn.b[5][1]++;
        }
        cov_1b6amftgbn.s[28]++;
        if ((cov_1b6amftgbn.b[8][0]++, el.min <= $scope.validateItem.min) && (cov_1b6amftgbn.b[8][1]++, el.max >= $scope.validateItem.max)) {
          cov_1b6amftgbn.b[7][0]++;
          cov_1b6amftgbn.s[29]++;

          $scope.error = {
            label: $translate.instant('FLAG.ERROR.OUT'),
            id: 4
          };

          cov_1b6amftgbn.s[30]++;
          return;
        } else {
          cov_1b6amftgbn.b[7][1]++;
        }
      }
      /** ************************************************************** */
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1b6amftgbn.s[31]++;
    for (var i = $scope.data.length - 1; i >= 0; i--) {
      cov_1b6amftgbn.s[32]++;

      if ($scope.data[i].id == $scope.validateItem.id) {
        cov_1b6amftgbn.b[9][0]++;
        cov_1b6amftgbn.s[33]++;

        $scope.data[i] = $scope.validateItem;
        cov_1b6amftgbn.s[34]++;
        break;
      } else {
        cov_1b6amftgbn.b[9][1]++;
      }
    }
    cov_1b6amftgbn.s[35]++;
    $rootScope.getCurrentProject().project.setFlag(this.data, true);
    cov_1b6amftgbn.s[36]++;
    this.cancel();
  };

  cov_1b6amftgbn.s[37]++;
  $scope.reorder = function (data) {
    cov_1b6amftgbn.f[5]++;
    cov_1b6amftgbn.s[38]++;

    /* for (let i = 1; i < data.length; i++) {
      data[i - 1].max = data[i].min;
    } */
    return data;
  };

  cov_1b6amftgbn.s[39]++;
  $scope.setDeleteData = function (item) {
    cov_1b6amftgbn.f[6]++;
    cov_1b6amftgbn.s[40]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_TYPINDIC.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1b6amftgbn.f[7]++;
      cov_1b6amftgbn.s[41]++;

      if (isconfirm) {
        cov_1b6amftgbn.b[10][0]++;
        cov_1b6amftgbn.s[42]++;

        return;
      } else {
        cov_1b6amftgbn.b[10][1]++;
      }

      var index = (cov_1b6amftgbn.s[43]++, $scope.data.indexOf(item));

      cov_1b6amftgbn.s[44]++;
      if (index >= 0) {
        cov_1b6amftgbn.b[11][0]++;
        cov_1b6amftgbn.s[45]++;

        $scope.data.splice(index, 1);
      } else {
        cov_1b6amftgbn.b[11][1]++;
      }

      cov_1b6amftgbn.s[46]++;
      $rootScope.getCurrentProject().project.setFlag($scope.reorder($scope.data), true);
      cov_1b6amftgbn.s[47]++;
      $scope.cancel();
    });
  };
  cov_1b6amftgbn.s[48]++;
  $scope.sortableOptions = {
    connectWith: '.connectList'
  };
});
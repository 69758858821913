'use strict';

var cov_1vmcco6tdx = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/funds_disbursement/FundsDisbursementCtrl.js',
      hash = '55127bead2944c95b52911f1cac014db7953eba7',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/funds_disbursement/FundsDisbursementCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 545,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 35
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 37
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 7,
          column: 3
        }
      },
      '4': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 11
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 482,
          column: 4
        }
      },
      '6': {
        start: {
          line: 23,
          column: 21
        },
        end: {
          line: 23,
          column: 90
        }
      },
      '7': {
        start: {
          line: 25,
          column: 24
        },
        end: {
          line: 25,
          column: 52
        }
      },
      '8': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 29,
          column: 7
        }
      },
      '9': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 57
        }
      },
      '10': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 53
        }
      },
      '11': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 74,
          column: 9
        }
      },
      '12': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 38,
          column: 11
        }
      },
      '13': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 33,
          column: 58
        }
      },
      '14': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 34,
          column: 48
        }
      },
      '15': {
        start: {
          line: 35,
          column: 10
        },
        end: {
          line: 35,
          column: 44
        }
      },
      '16': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 53
        }
      },
      '17': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 22
        }
      },
      '18': {
        start: {
          line: 39,
          column: 25
        },
        end: {
          line: 39,
          column: 26
        }
      },
      '19': {
        start: {
          line: 40,
          column: 22
        },
        end: {
          line: 40,
          column: 24
        }
      },
      '20': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '21': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 47,
          column: 12
        }
      },
      '22': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 73,
          column: 9
        }
      },
      '23': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 42
        }
      },
      '24': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 61,
          column: 11
        }
      },
      '25': {
        start: {
          line: 53,
          column: 34
        },
        end: {
          line: 55,
          column: 14
        }
      },
      '26': {
        start: {
          line: 54,
          column: 14
        },
        end: {
          line: 54,
          column: 128
        }
      },
      '27': {
        start: {
          line: 56,
          column: 12
        },
        end: {
          line: 58,
          column: 13
        }
      },
      '28': {
        start: {
          line: 57,
          column: 14
        },
        end: {
          line: 57,
          column: 23
        }
      },
      '29': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 59,
          column: 66
        }
      },
      '30': {
        start: {
          line: 60,
          column: 12
        },
        end: {
          line: 60,
          column: 21
        }
      },
      '31': {
        start: {
          line: 63,
          column: 32
        },
        end: {
          line: 66,
          column: 12
        }
      },
      '32': {
        start: {
          line: 64,
          column: 12
        },
        end: {
          line: 65,
          column: 91
        }
      },
      '33': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 70,
          column: 11
        }
      },
      '34': {
        start: {
          line: 69,
          column: 12
        },
        end: {
          line: 69,
          column: 21
        }
      },
      '35': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 71
        }
      },
      '36': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 28
        }
      },
      '37': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 95
        }
      },
      '38': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 67
        }
      },
      '39': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 20
        }
      },
      '40': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 28
        }
      },
      '41': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 84,
          column: 27
        }
      },
      '42': {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 85,
          column: 31
        }
      },
      '43': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 20
        }
      },
      '44': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 92,
          column: 7
        }
      },
      '45': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 90,
          column: 25
        }
      },
      '46': {
        start: {
          line: 91,
          column: 8
        },
        end: {
          line: 91,
          column: 15
        }
      },
      '47': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 48
        }
      },
      '48': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 157,
          column: 9
        }
      },
      '49': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 43
        }
      },
      '50': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 172,
          column: 9
        }
      },
      '51': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 171,
          column: 10
        }
      },
      '52': {
        start: {
          line: 169,
          column: 12
        },
        end: {
          line: 169,
          column: 24
        }
      },
      '53': {
        start: {
          line: 175,
          column: 21
        },
        end: {
          line: 175,
          column: 90
        }
      },
      '54': {
        start: {
          line: 177,
          column: 6
        },
        end: {
          line: 179,
          column: 7
        }
      },
      '55': {
        start: {
          line: 178,
          column: 8
        },
        end: {
          line: 178,
          column: 15
        }
      },
      '56': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 180,
          column: 46
        }
      },
      '57': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 181,
          column: 22
        }
      },
      '58': {
        start: {
          line: 182,
          column: 6
        },
        end: {
          line: 182,
          column: 33
        }
      },
      '59': {
        start: {
          line: 183,
          column: 6
        },
        end: {
          line: 233,
          column: 9
        }
      },
      '60': {
        start: {
          line: 184,
          column: 8
        },
        end: {
          line: 190,
          column: 11
        }
      },
      '61': {
        start: {
          line: 185,
          column: 10
        },
        end: {
          line: 185,
          column: 58
        }
      },
      '62': {
        start: {
          line: 186,
          column: 10
        },
        end: {
          line: 186,
          column: 48
        }
      },
      '63': {
        start: {
          line: 187,
          column: 10
        },
        end: {
          line: 187,
          column: 44
        }
      },
      '64': {
        start: {
          line: 188,
          column: 10
        },
        end: {
          line: 188,
          column: 53
        }
      },
      '65': {
        start: {
          line: 189,
          column: 10
        },
        end: {
          line: 189,
          column: 22
        }
      },
      '66': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 192,
          column: 36
        }
      },
      '67': {
        start: {
          line: 194,
          column: 24
        },
        end: {
          line: 194,
          column: 26
        }
      },
      '68': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 199,
          column: 9
        }
      },
      '69': {
        start: {
          line: 197,
          column: 10
        },
        end: {
          line: 197,
          column: 56
        }
      },
      '70': {
        start: {
          line: 198,
          column: 10
        },
        end: {
          line: 198,
          column: 51
        }
      },
      '71': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 49
        }
      },
      '72': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 203,
          column: 33
        }
      },
      '73': {
        start: {
          line: 204,
          column: 8
        },
        end: {
          line: 204,
          column: 37
        }
      },
      '74': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 207,
          column: 26
        }
      },
      '75': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 28
        }
      },
      '76': {
        start: {
          line: 211,
          column: 26
        },
        end: {
          line: 211,
          column: 27
        }
      },
      '77': {
        start: {
          line: 215,
          column: 8
        },
        end: {
          line: 230,
          column: 9
        }
      },
      '78': {
        start: {
          line: 216,
          column: 29
        },
        end: {
          line: 218,
          column: 12
        }
      },
      '79': {
        start: {
          line: 217,
          column: 12
        },
        end: {
          line: 217,
          column: 56
        }
      },
      '80': {
        start: {
          line: 220,
          column: 29
        },
        end: {
          line: 220,
          column: 30
        }
      },
      '81': {
        start: {
          line: 221,
          column: 10
        },
        end: {
          line: 223,
          column: 11
        }
      },
      '82': {
        start: {
          line: 222,
          column: 12
        },
        end: {
          line: 222,
          column: 54
        }
      },
      '83': {
        start: {
          line: 224,
          column: 10
        },
        end: {
          line: 224,
          column: 38
        }
      },
      '84': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 229,
          column: 13
        }
      },
      '85': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 232,
          column: 25
        }
      },
      '86': {
        start: {
          line: 237,
          column: 21
        },
        end: {
          line: 237,
          column: 79
        }
      },
      '87': {
        start: {
          line: 238,
          column: 6
        },
        end: {
          line: 242,
          column: 9
        }
      },
      '88': {
        start: {
          line: 246,
          column: 6
        },
        end: {
          line: 248,
          column: 7
        }
      },
      '89': {
        start: {
          line: 247,
          column: 8
        },
        end: {
          line: 247,
          column: 60
        }
      },
      '90': {
        start: {
          line: 250,
          column: 6
        },
        end: {
          line: 252,
          column: 10
        }
      },
      '91': {
        start: {
          line: 251,
          column: 8
        },
        end: {
          line: 251,
          column: 66
        }
      },
      '92': {
        start: {
          line: 255,
          column: 6
        },
        end: {
          line: 264,
          column: 9
        }
      },
      '93': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 267,
          column: 43
        }
      },
      '94': {
        start: {
          line: 268,
          column: 6
        },
        end: {
          line: 276,
          column: 9
        }
      },
      '95': {
        start: {
          line: 279,
          column: 6
        },
        end: {
          line: 305,
          column: 9
        }
      },
      '96': {
        start: {
          line: 289,
          column: 8
        },
        end: {
          line: 291,
          column: 9
        }
      },
      '97': {
        start: {
          line: 290,
          column: 10
        },
        end: {
          line: 290,
          column: 17
        }
      },
      '98': {
        start: {
          line: 293,
          column: 8
        },
        end: {
          line: 304,
          column: 11
        }
      },
      '99': {
        start: {
          line: 294,
          column: 10
        },
        end: {
          line: 303,
          column: 11
        }
      },
      '100': {
        start: {
          line: 295,
          column: 12
        },
        end: {
          line: 299,
          column: 15
        }
      },
      '101': {
        start: {
          line: 300,
          column: 12
        },
        end: {
          line: 300,
          column: 40
        }
      },
      '102': {
        start: {
          line: 302,
          column: 12
        },
        end: {
          line: 302,
          column: 35
        }
      },
      '103': {
        start: {
          line: 308,
          column: 20
        },
        end: {
          line: 308,
          column: 43
        }
      },
      '104': {
        start: {
          line: 309,
          column: 6
        },
        end: {
          line: 320,
          column: 9
        }
      },
      '105': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 312,
          column: 9
        }
      },
      '106': {
        start: {
          line: 311,
          column: 10
        },
        end: {
          line: 311,
          column: 17
        }
      },
      '107': {
        start: {
          line: 313,
          column: 8
        },
        end: {
          line: 319,
          column: 11
        }
      },
      '108': {
        start: {
          line: 314,
          column: 10
        },
        end: {
          line: 314,
          column: 35
        }
      },
      '109': {
        start: {
          line: 315,
          column: 21
        },
        end: {
          line: 315,
          column: 62
        }
      },
      '110': {
        start: {
          line: 316,
          column: 10
        },
        end: {
          line: 318,
          column: 45
        }
      },
      '111': {
        start: {
          line: 317,
          column: 12
        },
        end: {
          line: 317,
          column: 36
        }
      },
      '112': {
        start: {
          line: 323,
          column: 6
        },
        end: {
          line: 323,
          column: 33
        }
      },
      '113': {
        start: {
          line: 324,
          column: 6
        },
        end: {
          line: 324,
          column: 36
        }
      },
      '114': {
        start: {
          line: 325,
          column: 6
        },
        end: {
          line: 325,
          column: 20
        }
      },
      '115': {
        start: {
          line: 328,
          column: 21
        },
        end: {
          line: 371,
          column: 7
        }
      },
      '116': {
        start: {
          line: 360,
          column: 10
        },
        end: {
          line: 364,
          column: 11
        }
      },
      '117': {
        start: {
          line: 361,
          column: 12
        },
        end: {
          line: 361,
          column: 33
        }
      },
      '118': {
        start: {
          line: 362,
          column: 12
        },
        end: {
          line: 362,
          column: 47
        }
      },
      '119': {
        start: {
          line: 363,
          column: 12
        },
        end: {
          line: 363,
          column: 19
        }
      },
      '120': {
        start: {
          line: 366,
          column: 10
        },
        end: {
          line: 369,
          column: 11
        }
      },
      '121': {
        start: {
          line: 367,
          column: 12
        },
        end: {
          line: 367,
          column: 33
        }
      },
      '122': {
        start: {
          line: 368,
          column: 12
        },
        end: {
          line: 368,
          column: 49
        }
      },
      '123': {
        start: {
          line: 376,
          column: 6
        },
        end: {
          line: 376,
          column: 30
        }
      },
      '124': {
        start: {
          line: 380,
          column: 6
        },
        end: {
          line: 438,
          column: 8
        }
      },
      '125': {
        start: {
          line: 440,
          column: 6
        },
        end: {
          line: 442,
          column: 9
        }
      },
      '126': {
        start: {
          line: 441,
          column: 8
        },
        end: {
          line: 441,
          column: 34
        }
      },
      '127': {
        start: {
          line: 444,
          column: 6
        },
        end: {
          line: 470,
          column: 8
        }
      },
      '128': {
        start: {
          line: 450,
          column: 14
        },
        end: {
          line: 450,
          column: 39
        }
      },
      '129': {
        start: {
          line: 455,
          column: 10
        },
        end: {
          line: 457,
          column: 11
        }
      },
      '130': {
        start: {
          line: 456,
          column: 12
        },
        end: {
          line: 456,
          column: 58
        }
      },
      '131': {
        start: {
          line: 458,
          column: 10
        },
        end: {
          line: 458,
          column: 82
        }
      },
      '132': {
        start: {
          line: 460,
          column: 10
        },
        end: {
          line: 460,
          column: 32
        }
      },
      '133': {
        start: {
          line: 461,
          column: 10
        },
        end: {
          line: 461,
          column: 47
        }
      },
      '134': {
        start: {
          line: 462,
          column: 10
        },
        end: {
          line: 462,
          column: 40
        }
      },
      '135': {
        start: {
          line: 473,
          column: 21
        },
        end: {
          line: 479,
          column: 7
        }
      },
      '136': {
        start: {
          line: 480,
          column: 6
        },
        end: {
          line: 480,
          column: 41
        }
      },
      '137': {
        start: {
          line: 484,
          column: 2
        },
        end: {
          line: 487,
          column: 5
        }
      },
      '138': {
        start: {
          line: 489,
          column: 2
        },
        end: {
          line: 495,
          column: 5
        }
      },
      '139': {
        start: {
          line: 490,
          column: 4
        },
        end: {
          line: 493,
          column: 5
        }
      },
      '140': {
        start: {
          line: 491,
          column: 6
        },
        end: {
          line: 491,
          column: 44
        }
      },
      '141': {
        start: {
          line: 492,
          column: 6
        },
        end: {
          line: 492,
          column: 29
        }
      },
      '142': {
        start: {
          line: 494,
          column: 4
        },
        end: {
          line: 494,
          column: 32
        }
      },
      '143': {
        start: {
          line: 497,
          column: 2
        },
        end: {
          line: 497,
          column: 24
        }
      },
      '144': {
        start: {
          line: 499,
          column: 2
        },
        end: {
          line: 501,
          column: 5
        }
      },
      '145': {
        start: {
          line: 500,
          column: 4
        },
        end: {
          line: 500,
          column: 29
        }
      },
      '146': {
        start: {
          line: 504,
          column: 23
        },
        end: {
          line: 509,
          column: 4
        }
      },
      '147': {
        start: {
          line: 511,
          column: 2
        },
        end: {
          line: 514,
          column: 4
        }
      },
      '148': {
        start: {
          line: 512,
          column: 4
        },
        end: {
          line: 512,
          column: 36
        }
      },
      '149': {
        start: {
          line: 513,
          column: 4
        },
        end: {
          line: 513,
          column: 22
        }
      },
      '150': {
        start: {
          line: 516,
          column: 2
        },
        end: {
          line: 540,
          column: 4
        }
      },
      '151': {
        start: {
          line: 517,
          column: 4
        },
        end: {
          line: 517,
          column: 37
        }
      },
      '152': {
        start: {
          line: 518,
          column: 4
        },
        end: {
          line: 524,
          column: 6
        }
      },
      '153': {
        start: {
          line: 526,
          column: 4
        },
        end: {
          line: 528,
          column: 5
        }
      },
      '154': {
        start: {
          line: 527,
          column: 6
        },
        end: {
          line: 527,
          column: 47
        }
      },
      '155': {
        start: {
          line: 530,
          column: 4
        },
        end: {
          line: 538,
          column: 7
        }
      },
      '156': {
        start: {
          line: 539,
          column: 4
        },
        end: {
          line: 539,
          column: 37
        }
      },
      '157': {
        start: {
          line: 542,
          column: 2
        },
        end: {
          line: 544,
          column: 4
        }
      },
      '158': {
        start: {
          line: 543,
          column: 4
        },
        end: {
          line: 543,
          column: 27
        }
      },
      '159': {
        start: {
          line: 548,
          column: 15
        },
        end: {
          line: 548,
          column: 35
        }
      },
      '160': {
        start: {
          line: 550,
          column: 2
        },
        end: {
          line: 558,
          column: 4
        }
      },
      '161': {
        start: {
          line: 560,
          column: 2
        },
        end: {
          line: 586,
          column: 4
        }
      },
      '162': {
        start: {
          line: 561,
          column: 4
        },
        end: {
          line: 561,
          column: 27
        }
      },
      '163': {
        start: {
          line: 562,
          column: 19
        },
        end: {
          line: 562,
          column: 24
        }
      },
      '164': {
        start: {
          line: 563,
          column: 4
        },
        end: {
          line: 572,
          column: 5
        }
      },
      '165': {
        start: {
          line: 564,
          column: 6
        },
        end: {
          line: 566,
          column: 7
        }
      },
      '166': {
        start: {
          line: 565,
          column: 8
        },
        end: {
          line: 565,
          column: 17
        }
      },
      '167': {
        start: {
          line: 567,
          column: 6
        },
        end: {
          line: 569,
          column: 7
        }
      },
      '168': {
        start: {
          line: 568,
          column: 8
        },
        end: {
          line: 568,
          column: 17
        }
      },
      '169': {
        start: {
          line: 570,
          column: 6
        },
        end: {
          line: 570,
          column: 36
        }
      },
      '170': {
        start: {
          line: 571,
          column: 6
        },
        end: {
          line: 571,
          column: 22
        }
      },
      '171': {
        start: {
          line: 574,
          column: 4
        },
        end: {
          line: 576,
          column: 5
        }
      },
      '172': {
        start: {
          line: 575,
          column: 6
        },
        end: {
          line: 575,
          column: 13
        }
      },
      '173': {
        start: {
          line: 578,
          column: 18
        },
        end: {
          line: 578,
          column: 48
        }
      },
      '174': {
        start: {
          line: 579,
          column: 4
        },
        end: {
          line: 579,
          column: 64
        }
      },
      '175': {
        start: {
          line: 581,
          column: 4
        },
        end: {
          line: 585,
          column: 7
        }
      },
      '176': {
        start: {
          line: 582,
          column: 6
        },
        end: {
          line: 582,
          column: 29
        }
      },
      '177': {
        start: {
          line: 583,
          column: 6
        },
        end: {
          line: 583,
          column: 28
        }
      },
      '178': {
        start: {
          line: 584,
          column: 6
        },
        end: {
          line: 584,
          column: 26
        }
      },
      '179': {
        start: {
          line: 588,
          column: 2
        },
        end: {
          line: 590,
          column: 4
        }
      },
      '180': {
        start: {
          line: 589,
          column: 4
        },
        end: {
          line: 589,
          column: 38
        }
      },
      '181': {
        start: {
          line: 594,
          column: 15
        },
        end: {
          line: 594,
          column: 35
        }
      },
      '182': {
        start: {
          line: 595,
          column: 2
        },
        end: {
          line: 615,
          column: 4
        }
      },
      '183': {
        start: {
          line: 596,
          column: 4
        },
        end: {
          line: 596,
          column: 26
        }
      },
      '184': {
        start: {
          line: 597,
          column: 23
        },
        end: {
          line: 597,
          column: 57
        }
      },
      '185': {
        start: {
          line: 598,
          column: 4
        },
        end: {
          line: 598,
          column: 55
        }
      },
      '186': {
        start: {
          line: 599,
          column: 4
        },
        end: {
          line: 599,
          column: 55
        }
      },
      '187': {
        start: {
          line: 600,
          column: 4
        },
        end: {
          line: 604,
          column: 5
        }
      },
      '188': {
        start: {
          line: 601,
          column: 6
        },
        end: {
          line: 601,
          column: 81
        }
      },
      '189': {
        start: {
          line: 603,
          column: 6
        },
        end: {
          line: 603,
          column: 28
        }
      },
      '190': {
        start: {
          line: 605,
          column: 4
        },
        end: {
          line: 607,
          column: 5
        }
      },
      '191': {
        start: {
          line: 606,
          column: 6
        },
        end: {
          line: 606,
          column: 13
        }
      },
      '192': {
        start: {
          line: 609,
          column: 4
        },
        end: {
          line: 609,
          column: 83
        }
      },
      '193': {
        start: {
          line: 610,
          column: 4
        },
        end: {
          line: 614,
          column: 7
        }
      },
      '194': {
        start: {
          line: 611,
          column: 6
        },
        end: {
          line: 611,
          column: 29
        }
      },
      '195': {
        start: {
          line: 612,
          column: 6
        },
        end: {
          line: 612,
          column: 28
        }
      },
      '196': {
        start: {
          line: 613,
          column: 6
        },
        end: {
          line: 613,
          column: 26
        }
      },
      '197': {
        start: {
          line: 617,
          column: 2
        },
        end: {
          line: 619,
          column: 4
        }
      },
      '198': {
        start: {
          line: 618,
          column: 4
        },
        end: {
          line: 618,
          column: 38
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 58
          },
          end: {
            line: 2,
            column: 59
          }
        },
        loc: {
          start: {
            line: 2,
            column: 314
          },
          end: {
            line: 545,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 31,
            column: 40
          },
          end: {
            line: 31,
            column: 41
          }
        },
        loc: {
          start: {
            line: 31,
            column: 48
          },
          end: {
            line: 74,
            column: 7
          }
        },
        line: 31
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 32,
            column: 24
          },
          end: {
            line: 32,
            column: 25
          }
        },
        loc: {
          start: {
            line: 32,
            column: 32
          },
          end: {
            line: 38,
            column: 9
          }
        },
        line: 32
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 53,
            column: 55
          },
          end: {
            line: 53,
            column: 56
          }
        },
        loc: {
          start: {
            line: 53,
            column: 65
          },
          end: {
            line: 55,
            column: 13
          }
        },
        line: 53
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 63,
            column: 66
          },
          end: {
            line: 63,
            column: 67
          }
        },
        loc: {
          start: {
            line: 63,
            column: 76
          },
          end: {
            line: 66,
            column: 11
          }
        },
        line: 63
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 161,
            column: 54
          },
          end: {
            line: 161,
            column: 55
          }
        },
        loc: {
          start: {
            line: 161,
            column: 62
          },
          end: {
            line: 172,
            column: 7
          }
        },
        line: 161
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 183,
            column: 40
          },
          end: {
            line: 183,
            column: 41
          }
        },
        loc: {
          start: {
            line: 183,
            column: 48
          },
          end: {
            line: 233,
            column: 7
          }
        },
        line: 183
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 184,
            column: 42
          },
          end: {
            line: 184,
            column: 43
          }
        },
        loc: {
          start: {
            line: 184,
            column: 50
          },
          end: {
            line: 190,
            column: 9
          }
        },
        line: 184
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 216,
            column: 58
          },
          end: {
            line: 216,
            column: 59
          }
        },
        loc: {
          start: {
            line: 216,
            column: 63
          },
          end: {
            line: 218,
            column: 11
          }
        },
        line: 216
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 250,
            column: 64
          },
          end: {
            line: 250,
            column: 65
          }
        },
        loc: {
          start: {
            line: 250,
            column: 72
          },
          end: {
            line: 252,
            column: 7
          }
        },
        line: 250
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 288,
            column: 9
          },
          end: {
            line: 288,
            column: 10
          }
        },
        loc: {
          start: {
            line: 288,
            column: 22
          },
          end: {
            line: 305,
            column: 7
          }
        },
        line: 288
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 293,
            column: 91
          },
          end: {
            line: 293,
            column: 92
          }
        },
        loc: {
          start: {
            line: 293,
            column: 99
          },
          end: {
            line: 304,
            column: 9
          }
        },
        line: 293
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 309,
            column: 24
          },
          end: {
            line: 309,
            column: 25
          }
        },
        loc: {
          start: {
            line: 309,
            column: 34
          },
          end: {
            line: 320,
            column: 7
          }
        },
        line: 309
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 313,
            column: 52
          },
          end: {
            line: 313,
            column: 53
          }
        },
        loc: {
          start: {
            line: 313,
            column: 60
          },
          end: {
            line: 319,
            column: 9
          }
        },
        line: 313
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 316,
            column: 61
          },
          end: {
            line: 316,
            column: 62
          }
        },
        loc: {
          start: {
            line: 316,
            column: 71
          },
          end: {
            line: 318,
            column: 11
          }
        },
        line: 316
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 440,
            column: 53
          },
          end: {
            line: 440,
            column: 54
          }
        },
        loc: {
          start: {
            line: 440,
            column: 62
          },
          end: {
            line: 442,
            column: 7
          }
        },
        line: 440
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 449,
            column: 59
          },
          end: {
            line: 449,
            column: 60
          }
        },
        loc: {
          start: {
            line: 449,
            column: 68
          },
          end: {
            line: 451,
            column: 13
          }
        },
        line: 449
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 484,
            column: 38
          },
          end: {
            line: 484,
            column: 39
          }
        },
        loc: {
          start: {
            line: 484,
            column: 44
          },
          end: {
            line: 487,
            column: 3
          }
        },
        line: 484
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 489,
            column: 50
          },
          end: {
            line: 489,
            column: 51
          }
        },
        loc: {
          start: {
            line: 489,
            column: 58
          },
          end: {
            line: 495,
            column: 3
          }
        },
        line: 489
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 499,
            column: 46
          },
          end: {
            line: 499,
            column: 47
          }
        },
        loc: {
          start: {
            line: 499,
            column: 54
          },
          end: {
            line: 501,
            column: 3
          }
        },
        line: 499
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 511,
            column: 35
          },
          end: {
            line: 511,
            column: 36
          }
        },
        loc: {
          start: {
            line: 511,
            column: 55
          },
          end: {
            line: 514,
            column: 3
          }
        },
        line: 511
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 516,
            column: 31
          },
          end: {
            line: 516,
            column: 32
          }
        },
        loc: {
          start: {
            line: 516,
            column: 61
          },
          end: {
            line: 540,
            column: 3
          }
        },
        line: 516
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 542,
            column: 29
          },
          end: {
            line: 542,
            column: 30
          }
        },
        loc: {
          start: {
            line: 542,
            column: 49
          },
          end: {
            line: 544,
            column: 3
          }
        },
        line: 542
      },
      '23': {
        name: 'FundsDisbursementMappingCtrl',
        decl: {
          start: {
            line: 547,
            column: 9
          },
          end: {
            line: 547,
            column: 37
          }
        },
        loc: {
          start: {
            line: 547,
            column: 91
          },
          end: {
            line: 591,
            column: 1
          }
        },
        line: 547
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 560,
            column: 22
          },
          end: {
            line: 560,
            column: 23
          }
        },
        loc: {
          start: {
            line: 560,
            column: 28
          },
          end: {
            line: 586,
            column: 3
          }
        },
        line: 560
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 581,
            column: 91
          },
          end: {
            line: 581,
            column: 92
          }
        },
        loc: {
          start: {
            line: 581,
            column: 97
          },
          end: {
            line: 585,
            column: 5
          }
        },
        line: 581
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 588,
            column: 22
          },
          end: {
            line: 588,
            column: 23
          }
        },
        loc: {
          start: {
            line: 588,
            column: 28
          },
          end: {
            line: 590,
            column: 3
          }
        },
        line: 588
      },
      '27': {
        name: 'FundsDisbursementEditCtrl',
        decl: {
          start: {
            line: 593,
            column: 9
          },
          end: {
            line: 593,
            column: 34
          }
        },
        loc: {
          start: {
            line: 593,
            column: 88
          },
          end: {
            line: 620,
            column: 1
          }
        },
        line: 593
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 595,
            column: 24
          },
          end: {
            line: 595,
            column: 25
          }
        },
        loc: {
          start: {
            line: 595,
            column: 30
          },
          end: {
            line: 615,
            column: 3
          }
        },
        line: 595
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 600,
            column: 41
          },
          end: {
            line: 600,
            column: 42
          }
        },
        loc: {
          start: {
            line: 600,
            column: 49
          },
          end: {
            line: 602,
            column: 5
          }
        },
        line: 600
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 610,
            column: 87
          },
          end: {
            line: 610,
            column: 88
          }
        },
        loc: {
          start: {
            line: 610,
            column: 95
          },
          end: {
            line: 614,
            column: 5
          }
        },
        line: 610
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 617,
            column: 22
          },
          end: {
            line: 617,
            column: 23
          }
        },
        loc: {
          start: {
            line: 617,
            column: 28
          },
          end: {
            line: 619,
            column: 3
          }
        },
        line: 617
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 5,
            column: 2
          },
          end: {
            line: 7,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 5,
            column: 2
          },
          end: {
            line: 7,
            column: 3
          }
        }, {
          start: {
            line: 5,
            column: 2
          },
          end: {
            line: 7,
            column: 3
          }
        }],
        line: 5
      },
      '1': {
        loc: {
          start: {
            line: 23,
            column: 52
          },
          end: {
            line: 23,
            column: 85
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 23,
            column: 52
          },
          end: {
            line: 23,
            column: 74
          }
        }, {
          start: {
            line: 23,
            column: 78
          },
          end: {
            line: 23,
            column: 85
          }
        }],
        line: 23
      },
      '2': {
        loc: {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        }, {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 29,
            column: 7
          }
        }],
        line: 26
      },
      '3': {
        loc: {
          start: {
            line: 42,
            column: 30
          },
          end: {
            line: 42,
            column: 87
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 42,
            column: 41
          },
          end: {
            line: 42,
            column: 56
          }
        }, {
          start: {
            line: 42,
            column: 59
          },
          end: {
            line: 42,
            column: 87
          }
        }],
        line: 42
      },
      '4': {
        loc: {
          start: {
            line: 43,
            column: 16
          },
          end: {
            line: 43,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 43,
            column: 16
          },
          end: {
            line: 43,
            column: 35
          }
        }, {
          start: {
            line: 43,
            column: 39
          },
          end: {
            line: 43,
            column: 51
          }
        }],
        line: 43
      },
      '5': {
        loc: {
          start: {
            line: 44,
            column: 18
          },
          end: {
            line: 44,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 44,
            column: 18
          },
          end: {
            line: 44,
            column: 30
          }
        }, {
          start: {
            line: 44,
            column: 34
          },
          end: {
            line: 44,
            column: 53
          }
        }],
        line: 44
      },
      '6': {
        loc: {
          start: {
            line: 52,
            column: 10
          },
          end: {
            line: 61,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 10
          },
          end: {
            line: 61,
            column: 11
          }
        }, {
          start: {
            line: 52,
            column: 10
          },
          end: {
            line: 61,
            column: 11
          }
        }],
        line: 52
      },
      '7': {
        loc: {
          start: {
            line: 54,
            column: 21
          },
          end: {
            line: 54,
            column: 127
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 54,
            column: 21
          },
          end: {
            line: 54,
            column: 73
          }
        }, {
          start: {
            line: 54,
            column: 77
          },
          end: {
            line: 54,
            column: 127
          }
        }],
        line: 54
      },
      '8': {
        loc: {
          start: {
            line: 56,
            column: 12
          },
          end: {
            line: 58,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 56,
            column: 12
          },
          end: {
            line: 58,
            column: 13
          }
        }, {
          start: {
            line: 56,
            column: 12
          },
          end: {
            line: 58,
            column: 13
          }
        }],
        line: 56
      },
      '9': {
        loc: {
          start: {
            line: 64,
            column: 19
          },
          end: {
            line: 65,
            column: 90
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 64,
            column: 19
          },
          end: {
            line: 64,
            column: 97
          }
        }, {
          start: {
            line: 65,
            column: 14
          },
          end: {
            line: 65,
            column: 90
          }
        }],
        line: 64
      },
      '10': {
        loc: {
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }, {
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }],
        line: 68
      },
      '11': {
        loc: {
          start: {
            line: 79,
            column: 27
          },
          end: {
            line: 79,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 27
          },
          end: {
            line: 79,
            column: 45
          }
        }, {
          start: {
            line: 79,
            column: 49
          },
          end: {
            line: 79,
            column: 59
          }
        }],
        line: 79
      },
      '12': {
        loc: {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 92,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 92,
            column: 7
          }
        }, {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 92,
            column: 7
          }
        }],
        line: 89
      },
      '13': {
        loc: {
          start: {
            line: 97,
            column: 15
          },
          end: {
            line: 156,
            column: 9
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 97,
            column: 46
          },
          end: {
            line: 143,
            column: 9
          }
        }, {
          start: {
            line: 143,
            column: 12
          },
          end: {
            line: 156,
            column: 9
          }
        }],
        line: 97
      },
      '14': {
        loc: {
          start: {
            line: 174,
            column: 11
          },
          end: {
            line: 174,
            column: 25
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 174,
            column: 20
          },
          end: {
            line: 174,
            column: 25
          }
        }],
        line: 174
      },
      '15': {
        loc: {
          start: {
            line: 175,
            column: 52
          },
          end: {
            line: 175,
            column: 85
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 175,
            column: 52
          },
          end: {
            line: 175,
            column: 74
          }
        }, {
          start: {
            line: 175,
            column: 78
          },
          end: {
            line: 175,
            column: 85
          }
        }],
        line: 175
      },
      '16': {
        loc: {
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 179,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 179,
            column: 7
          }
        }, {
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 179,
            column: 7
          }
        }],
        line: 177
      },
      '17': {
        loc: {
          start: {
            line: 177,
            column: 10
          },
          end: {
            line: 177,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 177,
            column: 10
          },
          end: {
            line: 177,
            column: 51
          }
        }, {
          start: {
            line: 177,
            column: 55
          },
          end: {
            line: 177,
            column: 62
          }
        }],
        line: 177
      },
      '18': {
        loc: {
          start: {
            line: 228,
            column: 18
          },
          end: {
            line: 228,
            column: 146
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 228,
            column: 89
          },
          end: {
            line: 228,
            column: 93
          }
        }, {
          start: {
            line: 228,
            column: 97
          },
          end: {
            line: 228,
            column: 145
          }
        }],
        line: 228
      },
      '19': {
        loc: {
          start: {
            line: 228,
            column: 19
          },
          end: {
            line: 228,
            column: 85
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 228,
            column: 19
          },
          end: {
            line: 228,
            column: 58
          }
        }, {
          start: {
            line: 228,
            column: 62
          },
          end: {
            line: 228,
            column: 85
          }
        }],
        line: 228
      },
      '20': {
        loc: {
          start: {
            line: 237,
            column: 41
          },
          end: {
            line: 237,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 237,
            column: 41
          },
          end: {
            line: 237,
            column: 63
          }
        }, {
          start: {
            line: 237,
            column: 67
          },
          end: {
            line: 237,
            column: 74
          }
        }],
        line: 237
      },
      '21': {
        loc: {
          start: {
            line: 246,
            column: 6
          },
          end: {
            line: 248,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 246,
            column: 6
          },
          end: {
            line: 248,
            column: 7
          }
        }, {
          start: {
            line: 246,
            column: 6
          },
          end: {
            line: 248,
            column: 7
          }
        }],
        line: 246
      },
      '22': {
        loc: {
          start: {
            line: 289,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 289,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        }, {
          start: {
            line: 289,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        }],
        line: 289
      },
      '23': {
        loc: {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }, {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }],
        line: 294
      },
      '24': {
        loc: {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 312,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 312,
            column: 9
          }
        }, {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 312,
            column: 9
          }
        }],
        line: 310
      },
      '25': {
        loc: {
          start: {
            line: 315,
            column: 22
          },
          end: {
            line: 315,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 315,
            column: 22
          },
          end: {
            line: 315,
            column: 47
          }
        }, {
          start: {
            line: 315,
            column: 51
          },
          end: {
            line: 315,
            column: 58
          }
        }],
        line: 315
      },
      '26': {
        loc: {
          start: {
            line: 316,
            column: 38
          },
          end: {
            line: 318,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 316,
            column: 38
          },
          end: {
            line: 318,
            column: 12
          }
        }, {
          start: {
            line: 318,
            column: 16
          },
          end: {
            line: 318,
            column: 36
          }
        }, {
          start: {
            line: 318,
            column: 40
          },
          end: {
            line: 318,
            column: 44
          }
        }],
        line: 316
      },
      '27': {
        loc: {
          start: {
            line: 354,
            column: 32
          },
          end: {
            line: 354,
            column: 145
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 354,
            column: 60
          },
          end: {
            line: 354,
            column: 140
          }
        }, {
          start: {
            line: 354,
            column: 143
          },
          end: {
            line: 354,
            column: 145
          }
        }],
        line: 354
      },
      '28': {
        loc: {
          start: {
            line: 355,
            column: 22
          },
          end: {
            line: 355,
            column: 138
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 355,
            column: 50
          },
          end: {
            line: 355,
            column: 133
          }
        }, {
          start: {
            line: 355,
            column: 136
          },
          end: {
            line: 355,
            column: 138
          }
        }],
        line: 355
      },
      '29': {
        loc: {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        }, {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 364,
            column: 11
          }
        }],
        line: 360
      },
      '30': {
        loc: {
          start: {
            line: 366,
            column: 10
          },
          end: {
            line: 369,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 366,
            column: 10
          },
          end: {
            line: 369,
            column: 11
          }
        }, {
          start: {
            line: 366,
            column: 10
          },
          end: {
            line: 369,
            column: 11
          }
        }],
        line: 366
      },
      '31': {
        loc: {
          start: {
            line: 455,
            column: 10
          },
          end: {
            line: 457,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 455,
            column: 10
          },
          end: {
            line: 457,
            column: 11
          }
        }, {
          start: {
            line: 455,
            column: 10
          },
          end: {
            line: 457,
            column: 11
          }
        }],
        line: 455
      },
      '32': {
        loc: {
          start: {
            line: 472,
            column: 20
          },
          end: {
            line: 472,
            column: 33
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 472,
            column: 28
          },
          end: {
            line: 472,
            column: 33
          }
        }],
        line: 472
      },
      '33': {
        loc: {
          start: {
            line: 475,
            column: 41
          },
          end: {
            line: 475,
            column: 86
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 475,
            column: 49
          },
          end: {
            line: 475,
            column: 66
          }
        }, {
          start: {
            line: 475,
            column: 69
          },
          end: {
            line: 475,
            column: 86
          }
        }],
        line: 475
      },
      '34': {
        loc: {
          start: {
            line: 478,
            column: 14
          },
          end: {
            line: 478,
            column: 27
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 478,
            column: 22
          },
          end: {
            line: 478,
            column: 23
          }
        }, {
          start: {
            line: 478,
            column: 26
          },
          end: {
            line: 478,
            column: 27
          }
        }],
        line: 478
      },
      '35': {
        loc: {
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 493,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 493,
            column: 5
          }
        }, {
          start: {
            line: 490,
            column: 4
          },
          end: {
            line: 493,
            column: 5
          }
        }],
        line: 490
      },
      '36': {
        loc: {
          start: {
            line: 490,
            column: 9
          },
          end: {
            line: 490,
            column: 19
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 490,
            column: 9
          },
          end: {
            line: 490,
            column: 13
          }
        }, {
          start: {
            line: 490,
            column: 17
          },
          end: {
            line: 490,
            column: 19
          }
        }],
        line: 490
      },
      '37': {
        loc: {
          start: {
            line: 564,
            column: 6
          },
          end: {
            line: 566,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 564,
            column: 6
          },
          end: {
            line: 566,
            column: 7
          }
        }, {
          start: {
            line: 564,
            column: 6
          },
          end: {
            line: 566,
            column: 7
          }
        }],
        line: 564
      },
      '38': {
        loc: {
          start: {
            line: 567,
            column: 6
          },
          end: {
            line: 569,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 567,
            column: 6
          },
          end: {
            line: 569,
            column: 7
          }
        }, {
          start: {
            line: 567,
            column: 6
          },
          end: {
            line: 569,
            column: 7
          }
        }],
        line: 567
      },
      '39': {
        loc: {
          start: {
            line: 574,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 574,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        }, {
          start: {
            line: 574,
            column: 4
          },
          end: {
            line: 576,
            column: 5
          }
        }],
        line: 574
      },
      '40': {
        loc: {
          start: {
            line: 600,
            column: 4
          },
          end: {
            line: 604,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 600,
            column: 4
          },
          end: {
            line: 604,
            column: 5
          }
        }, {
          start: {
            line: 600,
            column: 4
          },
          end: {
            line: 604,
            column: 5
          }
        }],
        line: 600
      },
      '41': {
        loc: {
          start: {
            line: 601,
            column: 13
          },
          end: {
            line: 601,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 601,
            column: 13
          },
          end: {
            line: 601,
            column: 52
          }
        }, {
          start: {
            line: 601,
            column: 56
          },
          end: {
            line: 601,
            column: 80
          }
        }],
        line: 601
      },
      '42': {
        loc: {
          start: {
            line: 605,
            column: 4
          },
          end: {
            line: 607,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 605,
            column: 4
          },
          end: {
            line: 607,
            column: 5
          }
        }, {
          start: {
            line: 605,
            column: 4
          },
          end: {
            line: 607,
            column: 5
          }
        }],
        line: 605
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1vmcco6tdx.s[0]++;

angular.module('app').controller('FundsDisbursementCtrl', function ($scope, $stateParams, $deltadate, $deltaPeriode, FileUploader, $translate, $LOGINService, $deltaclearData, $filter, $uibModal, $CRUDService, $state, $deltaPlanLogique, $deltaLocation, $log, $deltaGeoData, $deltaDefaultTemplate, SweetAlert, $rootScope) {
  cov_1vmcco6tdx.f[0]++;

  var PATH = (cov_1vmcco6tdx.s[1]++, 'Funds_disbursement');
  cov_1vmcco6tdx.s[2]++;
  $rootScope.processPageRight('2_5');
  cov_1vmcco6tdx.s[3]++;
  if ($rootScope.global_access_page_denied) {
    cov_1vmcco6tdx.b[0][0]++;
    cov_1vmcco6tdx.s[4]++;

    return;
  } else {
    cov_1vmcco6tdx.b[0][1]++;
  }

  cov_1vmcco6tdx.s[5]++;
  $scope.me = {
    selectedAgreement: null,
    agreements: null,
    fundsDisbursement: [],
    devises: [],
    editedFund: null,
    listPeriod: [],
    selectedYear: 0,
    graphData: [],
    currentGraphData: [],
    pdfQueryCellInfo: function pdfQueryCellInfo(args) {},
    drawChart: function drawChart() {
      var _this = this;

      var params = (cov_1vmcco6tdx.s[6]++, { get: 'all_by_agreement', id: ((cov_1vmcco6tdx.b[1][0]++, this.selectedAgreement) || (cov_1vmcco6tdx.b[1][1]++, { id: 0 })).id });

      var isPeriod = (cov_1vmcco6tdx.s[7]++, this.listPeriod.length !== 0);
      cov_1vmcco6tdx.s[8]++;
      if (isPeriod) {
        cov_1vmcco6tdx.b[2][0]++;
        cov_1vmcco6tdx.s[9]++;

        params.from = this.currentYear.DATE_DEBUT_PERIOD;
        cov_1vmcco6tdx.s[10]++;
        params.to = this.currentYear.DATE_FIN_PERIOD;
      } else {
        cov_1vmcco6tdx.b[2][1]++;
      }

      cov_1vmcco6tdx.s[11]++;
      $CRUDService.getAll(PATH, params, function (data) {
        cov_1vmcco6tdx.f[1]++;
        cov_1vmcco6tdx.s[12]++;

        data = data.map(function (fund) {
          cov_1vmcco6tdx.f[2]++;
          cov_1vmcco6tdx.s[13]++;

          fund.DATE_FUNDS = Date.newDate(fund.DATE_FUNDS);
          cov_1vmcco6tdx.s[14]++;
          fund.AMOUNT = parseFloat(fund.AMOUNT);
          cov_1vmcco6tdx.s[15]++;
          fund.RATE = parseFloat(fund.RATE);
          cov_1vmcco6tdx.s[16]++;
          fund.AMOUNT_RATE = fund.AMOUNT * fund.RATE;
          cov_1vmcco6tdx.s[17]++;
          return fund;
        });
        var totalValue = (cov_1vmcco6tdx.s[18]++, 0);
        var data_ = (cov_1vmcco6tdx.s[19]++, {});

        cov_1vmcco6tdx.s[20]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = (isPeriod ? (cov_1vmcco6tdx.b[3][0]++, _this.listPeriod) : (cov_1vmcco6tdx.b[3][1]++, $scope.listData_Periode_bksb))[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var period = _step.value;
            cov_1vmcco6tdx.s[21]++;

            data_[(cov_1vmcco6tdx.b[4][0]++, period.ANNEE_PERIOD) || (cov_1vmcco6tdx.b[4][1]++, period.index)] = {
              name: (cov_1vmcco6tdx.b[5][0]++, period.label) || (cov_1vmcco6tdx.b[5][1]++, period.ANNEE_PERIOD),
              value: 0,
              rate: 0
            };
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_1vmcco6tdx.s[22]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          var _loop = function _loop() {
            var value = _step2.value;
            cov_1vmcco6tdx.s[23]++;

            totalValue += value.AMOUNT_RATE;
            cov_1vmcco6tdx.s[24]++;
            if (isPeriod) {
              cov_1vmcco6tdx.b[6][0]++;

              var _currentPeriod = (cov_1vmcco6tdx.s[25]++, _this.listPeriod.find(function (period) {
                cov_1vmcco6tdx.f[3]++;
                cov_1vmcco6tdx.s[26]++;

                return (cov_1vmcco6tdx.b[7][0]++, period.begin.valueOf() <= value.DATE_FUNDS.valueOf()) && (cov_1vmcco6tdx.b[7][1]++, period.end.valueOf() >= value.DATE_FUNDS.valueOf());
              }));
              cov_1vmcco6tdx.s[27]++;
              if (!_currentPeriod) {
                cov_1vmcco6tdx.b[8][0]++;
                cov_1vmcco6tdx.s[28]++;

                return 'continue';
              } else {
                cov_1vmcco6tdx.b[8][1]++;
              }
              cov_1vmcco6tdx.s[29]++;
              data_[_currentPeriod.index].value += value.AMOUNT_RATE;
              cov_1vmcco6tdx.s[30]++;
              return 'continue';
            } else {
              cov_1vmcco6tdx.b[6][1]++;
            }

            var currentPeriod = (cov_1vmcco6tdx.s[31]++, $scope.listData_Periode_bksb.find(function (period) {
              cov_1vmcco6tdx.f[4]++;
              cov_1vmcco6tdx.s[32]++;

              return (cov_1vmcco6tdx.b[9][0]++, Date.newDate(period.DATE_DEBUT_PERIOD).valueOf() <= value.DATE_FUNDS.valueOf()) && (cov_1vmcco6tdx.b[9][1]++, Date.newDate(period.DATE_FIN_PERIOD).valueOf() >= value.DATE_FUNDS.valueOf());
            }));

            cov_1vmcco6tdx.s[33]++;
            if (!currentPeriod) {
              cov_1vmcco6tdx.b[10][0]++;
              cov_1vmcco6tdx.s[34]++;

              return 'continue';
            } else {
              cov_1vmcco6tdx.b[10][1]++;
            }

            cov_1vmcco6tdx.s[35]++;
            data_[currentPeriod.ANNEE_PERIOD].value += value.AMOUNT_RATE;
          };

          for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _ret = _loop();

            if (_ret === 'continue') continue;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      });
    },
    openPeriod: function openPeriod(period) {
      cov_1vmcco6tdx.s[36]++;

      this.graphData = null;
      cov_1vmcco6tdx.s[37]++;
      this.listPeriod = $deltadate.getYearPeriod(period.ANNEE_PERIOD, period.DECOUPAGE_PERIOD);
      cov_1vmcco6tdx.s[38]++;
      this.displayIndex = ((cov_1vmcco6tdx.b[11][0]++, this.listPeriod[0]) || (cov_1vmcco6tdx.b[11][1]++, { index: 0 })).index;
      cov_1vmcco6tdx.s[39]++;
      this.getAll();
    },
    upPeriod: function upPeriod() {
      cov_1vmcco6tdx.s[40]++;

      this.graphData = null;
      cov_1vmcco6tdx.s[41]++;
      this.listPeriod = [];
      cov_1vmcco6tdx.s[42]++;
      this.displayIndex = null;
      cov_1vmcco6tdx.s[43]++;
      this.getAll();
    },
    exportTo: function exportTo(type) {
      cov_1vmcco6tdx.s[44]++;

      if (type.toUpperCase() === 'PDF') {
        cov_1vmcco6tdx.b[12][0]++;
        cov_1vmcco6tdx.s[45]++;

        this.printData();
        cov_1vmcco6tdx.s[46]++;
        return;
      } else {
        cov_1vmcco6tdx.b[12][1]++;
      }

      cov_1vmcco6tdx.s[47]++;
      $scope.me.grid.columns[7].visible = false;
      cov_1vmcco6tdx.s[48]++;
      $scope.$broadcast('table_syncfusion_export', {
        type: type,
        param: type.toUpperCase() === 'PDF' ? (cov_1vmcco6tdx.b[13][0]++, {
          fileName: $translate.instant('MENU.FUNDS') + '.pdf',
          theme: {
            header: {
              fontName: 'TimesRoman', fontSize: 8, bold: true
            },
            record: {
              fontName: 'TimesRoman', fontSize: 8
            },
            caption: {
              fontName: 'TimesRoman', fontSize: 9
            }
          },
          // pageSize: 'A4',
          header: {
            fromTop: 0,
            height: 50,
            contents: [{
              type: 'Text',
              value: $translate.instant('MENU.FUNDS'),
              position: { x: 0, y: 0 },
              style: { fontSize: 15, hAlign: 'Center', vAlign: 'Center', bold: true }
            }, {
              type: 'Text',
              value: $translate.instant('FUNDS.FINANCING') + ': ' + $scope.me.selectedAgreement.AGREEMENT,
              position: { x: 0, y: 20 },
              style: { fontSize: 12, vAlign: 'Center' }
            }]
          },
          footer: {
            fromBottom: 0,
            height: 20,
            contents: [{
              type: 'PageNumber',
              pageNumberType: 'Arabic',
              format: '{$current} / {$total}',
              position: { x: 810, y: 0 },
              style: { textBrushColor: '#353535', fontSize: 9, hAlign: 'Center' }
            }]
          },
          pageOrientation: 'Landscape'
        }) : (cov_1vmcco6tdx.b[13][1]++, {
          header: {
            headerRows: 3,
            rows: [{ index: 1, cells: [{ index: 1, colSpan: 6, value: $translate.instant('MENU.FUNDS'), style: { fontSize: 25, hAlign: 'Center', bold: true } }] }, {
              index: 3,
              cells: [{ index: 1, colSpan: 6, value: $translate.instant('FUNDS.FINANCING') + ': ' + $scope.me.selectedAgreement.AGREEMENT, style: { fontSize: 15, bold: true } }]
            }]
          },
          fileName: $translate.instant('MENU.FUNDS') + '.xlsx'
        })
      });
    },
    getDevise: function getDevise() {
      var _this2 = this;

      cov_1vmcco6tdx.s[49]++;

      this.autoCompleteGroupOptions = null;
      cov_1vmcco6tdx.s[50]++;
      $CRUDService.getAll('Projet', { get: 'devises' }, function (_data) {
        cov_1vmcco6tdx.f[5]++;
        cov_1vmcco6tdx.s[51]++;

        _this2.autoCompleteGroupOptions = {
          minimumChars: 0,
          containerCssClass: 'white-bg',
          selectedCssClass: 'selected-item-auto-complete',
          noMatchTemplateEnabled: false,
          activateOnFocus: true,
          data: function data(searchText) {
            cov_1vmcco6tdx.s[52]++;
            // const data = $filter('filter')($scope.listActors, {CATEGORIE_ACTEUR: searchText});
            return _data;
          }
        };
      });
    },
    getAll: function getAll() {
      var _this3 = this;

      var forced = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1vmcco6tdx.b[14][0]++, false);

      var params = (cov_1vmcco6tdx.s[53]++, { get: 'all_by_agreement', id: ((cov_1vmcco6tdx.b[15][0]++, this.selectedAgreement) || (cov_1vmcco6tdx.b[15][1]++, { id: 0 })).id });

      cov_1vmcco6tdx.s[54]++;
      if ((cov_1vmcco6tdx.b[17][0]++, this.oldParams === angular.toJson(params)) && (cov_1vmcco6tdx.b[17][1]++, !forced)) {
        cov_1vmcco6tdx.b[16][0]++;
        cov_1vmcco6tdx.s[55]++;

        return;
      } else {
        cov_1vmcco6tdx.b[16][1]++;
      }
      cov_1vmcco6tdx.s[56]++;
      this.oldParams = angular.toJson(params);
      cov_1vmcco6tdx.s[57]++;
      this.AMOUNT = 0;
      cov_1vmcco6tdx.s[58]++;
      $scope.me.isloading = true;
      cov_1vmcco6tdx.s[59]++;
      $CRUDService.getAll(PATH, params, function (data) {
        cov_1vmcco6tdx.f[6]++;
        cov_1vmcco6tdx.s[60]++;

        _this3.fundsDisbursement = data.map(function (fund) {
          cov_1vmcco6tdx.f[7]++;
          cov_1vmcco6tdx.s[61]++;

          fund.DATE_FUNDS = Date.newDate(fund.DATE_FUNDS);
          cov_1vmcco6tdx.s[62]++;
          fund.AMOUNT = parseFloat(fund.AMOUNT);
          cov_1vmcco6tdx.s[63]++;
          fund.RATE = parseFloat(fund.RATE);
          cov_1vmcco6tdx.s[64]++;
          fund.AMOUNT_RATE = fund.AMOUNT * fund.RATE;
          cov_1vmcco6tdx.s[65]++;
          return fund;
        });

        cov_1vmcco6tdx.s[66]++;
        $scope.me.isloading = false;

        var periods = (cov_1vmcco6tdx.s[67]++, {});

        cov_1vmcco6tdx.s[68]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var fund = _step3.value;
            cov_1vmcco6tdx.s[69]++;

            periods[fund.DATE_FUNDS.getFullYear()] = true;
            cov_1vmcco6tdx.s[70]++;
            _this3.AMOUNT += fund.AMOUNT * fund.RATE;
          }

          // if(this.selectedYear==0){
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        cov_1vmcco6tdx.s[71]++;
        $scope.me.periods = Object.keys(periods);
        cov_1vmcco6tdx.s[72]++;
        $scope.me.periods.sort();
        cov_1vmcco6tdx.s[73]++;
        _this3.currentGraphData = data;
        // }

        cov_1vmcco6tdx.s[74]++;
        _this3.filterData();
        // this.initializeTable(this.fundsDisbursement);

        cov_1vmcco6tdx.s[75]++;
        _this3.graphData = [];
        var total_somme = (cov_1vmcco6tdx.s[76]++, 0);

        // detail

        cov_1vmcco6tdx.s[77]++;
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          var _loop2 = function _loop2() {
            var period = _step4.value;

            var filterData = (cov_1vmcco6tdx.s[78]++, _this3.currentGraphData.filter(function (d) {
              cov_1vmcco6tdx.f[8]++;
              cov_1vmcco6tdx.s[79]++;

              return d.DATE_FUNDS.getFullYear() == period;
            }));

            var somme_amount = (cov_1vmcco6tdx.s[80]++, 0);
            cov_1vmcco6tdx.s[81]++;
            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
              for (var _iterator5 = filterData[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                var _fund = _step5.value;
                cov_1vmcco6tdx.s[82]++;

                somme_amount += _fund.AMOUNT * _fund.RATE;
              }
            } catch (err) {
              _didIteratorError5 = true;
              _iteratorError5 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion5 && _iterator5.return) {
                  _iterator5.return();
                }
              } finally {
                if (_didIteratorError5) {
                  throw _iteratorError5;
                }
              }
            }

            cov_1vmcco6tdx.s[83]++;
            total_somme += somme_amount;
            cov_1vmcco6tdx.s[84]++;
            _this3.graphData.push({
              name: period,
              value: somme_amount,
              rate: (cov_1vmcco6tdx.b[19][0]++, $scope.me.selectedAgreement.AMOUNT == 0) || (cov_1vmcco6tdx.b[19][1]++, filterData.length === 0) ? (cov_1vmcco6tdx.b[18][0]++, null) : (cov_1vmcco6tdx.b[18][1]++, total_somme / $scope.me.selectedAgreement.AMOUNT)
            });
          };

          for (var _iterator4 = $scope.me.periods[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            _loop2();
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        cov_1vmcco6tdx.s[85]++;
        _this3.drawGraph();
      });
    },
    printData: function printData() {
      var params = (cov_1vmcco6tdx.s[86]++, { get: 'print', id: ((cov_1vmcco6tdx.b[20][0]++, this.selectedAgreement) || (cov_1vmcco6tdx.b[20][1]++, { id: 0 })).id });
      cov_1vmcco6tdx.s[87]++;
      $rootScope.printReport({
        link: PATH,
        params: params,
        title: $translate.instant('MENU_SHORT.FUNDS')
      });
    },
    filterData: function filterData() {
      var _this4 = this;

      cov_1vmcco6tdx.s[88]++;

      if (this.selectedYear == 0) {
        cov_1vmcco6tdx.b[21][0]++;
        cov_1vmcco6tdx.s[89]++;

        return this.initializeTable(this.fundsDisbursement);
      } else {
        cov_1vmcco6tdx.b[21][1]++;
      }

      cov_1vmcco6tdx.s[90]++;
      return this.initializeTable(this.fundsDisbursement.filter(function (fund) {
        cov_1vmcco6tdx.f[9]++;
        cov_1vmcco6tdx.s[91]++;

        return fund.DATE_FUNDS.getFullYear() == _this4.selectedYear;
      }));
    },
    newItem: function newItem() {
      cov_1vmcco6tdx.s[92]++;

      this.editItem({
        id: 0,
        ID_AGREEMENT: this.selectedAgreement.id,
        REFERENCE: '',
        LABEL: '',
        DATE_FUNDS: Date.newDate(),
        AMOUNT: 0,
        ID_DEVISE: '',
        RATE: 0
      });
    },
    editItem: function editItem(item) {
      cov_1vmcco6tdx.s[93]++;

      this.editedFund = angular.copy(item);
      cov_1vmcco6tdx.s[94]++;
      $uibModal.open({
        templateUrl: 'app/views/funds_disbursement/edit.html',
        controller: FundsDisbursementEditCtrl,
        scope: $scope,
        size: 'xs',
        windowClass: 'animated fadeInRight  left-modal',
        backdrop: 'static'
        // keyboard: false,
      });
    },
    deleteItem: function deleteItem(item) {
      cov_1vmcco6tdx.s[95]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',
        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_1vmcco6tdx.f[10]++;
        cov_1vmcco6tdx.s[96]++;

        if (isconfirm) {
          cov_1vmcco6tdx.b[22][0]++;
          cov_1vmcco6tdx.s[97]++;

          return;
        } else {
          cov_1vmcco6tdx.b[22][1]++;
        }
        // $scope.me.isloading = true;
        cov_1vmcco6tdx.s[98]++;
        $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')({ id: item.id }) }, function (data) {
          cov_1vmcco6tdx.f[11]++;
          cov_1vmcco6tdx.s[99]++;

          if (data < 0) {
            cov_1vmcco6tdx.b[23][0]++;
            cov_1vmcco6tdx.s[100]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
              type: 'warning'
            });
            cov_1vmcco6tdx.s[101]++;
            $scope.me.isloading = false;
          } else {
            cov_1vmcco6tdx.b[23][1]++;
            cov_1vmcco6tdx.s[102]++;

            $scope.me.getAll(true);
          }
        });
      });
    },
    openDevise: function openDevise() {
      var _this5 = this;

      var panel = (cov_1vmcco6tdx.s[103]++, $rootScope.openDevise());
      cov_1vmcco6tdx.s[104]++;
      panel.result.then(function (result) {
        cov_1vmcco6tdx.f[12]++;
        cov_1vmcco6tdx.s[105]++;

        if (!result) {
          cov_1vmcco6tdx.b[24][0]++;
          cov_1vmcco6tdx.s[106]++;

          return;
        } else {
          cov_1vmcco6tdx.b[24][1]++;
        }
        cov_1vmcco6tdx.s[107]++;
        $CRUDService.getAll('Devise', { get: 'all' }, function (data) {
          cov_1vmcco6tdx.f[13]++;
          cov_1vmcco6tdx.s[108]++;

          $scope.me.devises = data;
          var id = (cov_1vmcco6tdx.s[109]++, ((cov_1vmcco6tdx.b[25][0]++, _this5.editedFund.ID_DEVISE) || (cov_1vmcco6tdx.b[25][1]++, { id: 0 })).id);
          cov_1vmcco6tdx.s[110]++;
          _this5.editedFund.ID_DEVISE = (cov_1vmcco6tdx.b[26][0]++, $scope.me.devises.find(function (devise) {
            cov_1vmcco6tdx.f[14]++;
            cov_1vmcco6tdx.s[111]++;

            return devise.id === id;
          })) || (cov_1vmcco6tdx.b[26][1]++, $scope.me.devises[0]) || (cov_1vmcco6tdx.b[26][2]++, null);
        });
      });
    },
    selectAgreement: function selectAgreement(item) {
      cov_1vmcco6tdx.s[112]++;

      $scope.me.selectedYear = 0;
      cov_1vmcco6tdx.s[113]++;
      this.selectedAgreement = item;
      cov_1vmcco6tdx.s[114]++;
      this.getAll();
    },
    initializeTable: function initializeTable(data) {
      var params = (cov_1vmcco6tdx.s[115]++, {
        dataSource: data,
        editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
        aggregates: [{
          columns: [{
            type: 'Sum',
            field: 'AMOUNT_RATE',
            format: 'N'
          }]
        }],
        columns: [{ field: 'REFERENCE', width: 100, headerText: $translate.instant('FUNDS.REFERENCE').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip', textAlign: 'Left', headerTextAlign: 'Left' }, { field: 'LABEL', headerText: $translate.instant('FUNDS.LABEL').toUpperCase(), type: 'string', allowFiltering: false, clipMode: 'EllipsisWithTooltip', textAlign: 'Left', headerTextAlign: 'Left' }, { field: 'DATE_FUNDS', width: 120, headerText: $translate.instant('FUNDS.DATE_FUNDS').toUpperCase(), format: { skeleton: 'medium', type: 'date' }, clipMode: 'EllipsisWithTooltip', textAlign: 'Left', headerTextAlign: 'Left' }, { field: 'AMOUNT', headerText: $translate.instant('FUNDS.AMOUNT').toUpperCase(), format: 'N', type: 'number', width: 120, clipMode: 'EllipsisWithTooltip', textAlign: 'Right', headerTextAlign: 'Left' }, { field: 'ID_DEVISE', width: 120, headerText: $translate.instant('FUNDS.DEVISE').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip', textAlign: 'Left', headerTextAlign: 'Left' }, { field: 'RATE', headerText: $translate.instant('FUNDS.RATE').toUpperCase() + ' / ' + $scope.me.selectedAgreement.CURRENCY, format: 'N', width: 120, type: 'number', clipMode: 'EllipsisWithTooltip', textAlign: 'Left', headerTextAlign: 'Left' }, { field: 'AMOUNT_RATE', headerText: $translate.instant('FUNDS.AMOUNT').toUpperCase() + ' ' + $scope.me.selectedAgreement.CURRENCY, format: 'N', type: 'number', width: 120, clipMode: 'EllipsisWithTooltip', textAlign: 'Right', headerTextAlign: 'Left' }, { headerText: '', width: 120,
          commands: [].concat($rootScope.currentRight.u ? (cov_1vmcco6tdx.b[27][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_1vmcco6tdx.b[27][1]++, [])).concat($rootScope.currentRight.d ? (cov_1vmcco6tdx.b[28][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_1vmcco6tdx.b[28][1]++, []))

        }],
        actionBegin: function actionBegin(action) {
          cov_1vmcco6tdx.s[116]++;

          if (action.requestType === 'beginEdit') {
            cov_1vmcco6tdx.b[29][0]++;
            cov_1vmcco6tdx.s[117]++;

            action.cancel = true;
            cov_1vmcco6tdx.s[118]++;
            $scope.me.editItem(action.rowData);
            cov_1vmcco6tdx.s[119]++;
            return;
          } else {
            cov_1vmcco6tdx.b[29][1]++;
          }

          cov_1vmcco6tdx.s[120]++;
          if (action.requestType === 'delete') {
            cov_1vmcco6tdx.b[30][0]++;
            cov_1vmcco6tdx.s[121]++;

            action.cancel = true;
            cov_1vmcco6tdx.s[122]++;
            $scope.me.deleteItem(action.data[0]);
          } else {
            cov_1vmcco6tdx.b[30][1]++;
          }
        }
      });
      /* if ($scope.me.grid) {
        $scope.me.grid.columns = params.columns;
        $scope.me.grid.dataSource = params.dataSource;
      } else { */
      cov_1vmcco6tdx.s[123]++;
      $scope.me.grid = params;
      // }
    },
    drawGraph: function drawGraph() {
      cov_1vmcco6tdx.s[124]++;

      this.chartDesign = {
        export_: 0,
        locale: $LOGINService.getLang(),
        // Initializing Primary X and Y Axis
        primaryXAxis: {
          // title: 'Years',
          interval: 1,
          labelIntersectAction: 'Rotate45',
          valueType: 'Category',
          majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
          majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
          lineStyle: { width: 0 }
        },
        primaryYAxis: {
          valueType: 'Double',
          lineStyle: { width: 0 },
          majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
          minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
          labelFormat: 'P'
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        useGroupingSeparator: true,
        // Initializing Chart Series
        series: [{ // FILL
          type: 'Area',
          opacity: 0.5,
          dataSource: this.graphData,
          // dataLabel: {visible: true, position: 'Outside'},
          fill: '#208AC9',
          xName: 'name', yName: 'rate', // name: serie.TITLE,
          width: 3,
          marker: {
            visible: true,
            width: 10,
            height: 10
          }
        }],
        textRender: function textRender(args, a, b) {},

        width: '100%',
        height: '400px',
        // Initializing Chart title
        title_: $translate.instant('FUNDS.BAR_CHART').toUpperCase(),
        // crosshair: {enable: true, lineType: 'Vertical'},
        // Enable Legend
        legendSettings: {
          visible: false
        },
        // selectionMode: 'Series',
        /* pointClick(args) {
          $scope.process.deroule(args.pointIndex);
        }, */
        tooltip: { enable: true, shared: false }
      };

      cov_1vmcco6tdx.s[125]++;
      this.chartDesign.hasData = this.graphData.find(function (value) {
        cov_1vmcco6tdx.f[15]++;
        cov_1vmcco6tdx.s[126]++;

        return value.rate != null;
      });

      cov_1vmcco6tdx.s[127]++;
      this.pieChartDesign = {
        // Initializing Series
        export_: 0,
        series: [{
          type: 'Pie', dataSource: this.graphData.filter(function (value) {
            cov_1vmcco6tdx.f[16]++;
            cov_1vmcco6tdx.s[128]++;

            return value.value !== 0;
          }), xName: 'name', yName: 'value',
          dataLabel: { visible: true, position: 'Outside' // , innerRadius: '30%'
          } }], textRender: function textRender(args) {
          cov_1vmcco6tdx.s[129]++;

          if (!args.extraData) {
            cov_1vmcco6tdx.b[31][0]++;
            cov_1vmcco6tdx.s[130]++;

            args.extraData = $filter('number')(args.text);
          } else {
            cov_1vmcco6tdx.b[31][1]++;
          }
          cov_1vmcco6tdx.s[131]++;
          args.text = args.extraData + ' ' + $scope.me.selectedAgreement.CURRENCY;

          cov_1vmcco6tdx.s[132]++;
          args.border.width = 1;
          cov_1vmcco6tdx.s[133]++;
          args.border.color = args.point.color;
          cov_1vmcco6tdx.s[134]++;
          args.color = args.point.color;
        },

        legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
        title_: $translate.instant('FUNDS.PIE_CHART').toUpperCase(),
        // Initializing Tooltip
        tooltip: { enable: true, format: '${point.x} <br> ${point.percentage} %' },
        height: '400px',
        width: '100%'
      };
    },
    exportAsAnalyse: function exportAsAnalyse() {
      var graph = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1vmcco6tdx.b[32][0]++, false);

      var params = (cov_1vmcco6tdx.s[135]++, {
        from: 'funding',
        defaultTitle: $translate.instant(graph ? (cov_1vmcco6tdx.b[33][0]++, 'FUNDS.PIE_CHART') : (cov_1vmcco6tdx.b[33][1]++, 'FUNDS.BAR_CHART')),
        activity: -1,
        idAgreement: $scope.me.selectedAgreement.id,
        TYPE: graph ? (cov_1vmcco6tdx.b[34][0]++, 2) : (cov_1vmcco6tdx.b[34][1]++, 3)
      });
      cov_1vmcco6tdx.s[136]++;
      $rootScope.exportAsAnalyse(params);
    }
  };

  cov_1vmcco6tdx.s[137]++;
  $deltaPeriode.addController($scope, function () {
    /* $scope.choise.year = $stateParams.params.year || $scope.listData_Periode_bksb[0];
    $scope.choise.displayIndex = $scope.choise.year.ANNEE_PERIOD; */

    cov_1vmcco6tdx.f[17]++;
  });

  cov_1vmcco6tdx.s[138]++;
  $CRUDService.getAll('Agreements', { get: 'all' }, function (data) {
    cov_1vmcco6tdx.f[18]++;
    cov_1vmcco6tdx.s[139]++;

    if (((cov_1vmcco6tdx.b[36][0]++, data) || (cov_1vmcco6tdx.b[36][1]++, [])).length > 0) {
      cov_1vmcco6tdx.b[35][0]++;
      cov_1vmcco6tdx.s[140]++;

      $scope.me.selectedAgreement = data[0];
      cov_1vmcco6tdx.s[141]++;
      $scope.me.getAll(true);
    } else {
      cov_1vmcco6tdx.b[35][1]++;
    }
    cov_1vmcco6tdx.s[142]++;
    $scope.me.agreements = data;
  });

  cov_1vmcco6tdx.s[143]++;
  $scope.me.getDevise();

  cov_1vmcco6tdx.s[144]++;
  $CRUDService.getAll('Devise', { get: 'all' }, function (data) {
    cov_1vmcco6tdx.f[19]++;
    cov_1vmcco6tdx.s[145]++;

    $scope.me.devises = data;
  });

  // uploadfile
  var uploaderFile = (cov_1vmcco6tdx.s[146]++, $scope.uploaderFile = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadfinacial&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH,
    alias: 'data',
    removeAfterUpload: true
  }));

  cov_1vmcco6tdx.s[147]++;
  uploaderFile.onAfterAddingFile = function (fileItem) {
    cov_1vmcco6tdx.f[20]++;
    cov_1vmcco6tdx.s[148]++;

    $scope.loadFinancialData = true;
    cov_1vmcco6tdx.s[149]++;
    fileItem.upload();
  };

  cov_1vmcco6tdx.s[150]++;
  uploaderFile.onSuccessItem = function (fileItem, response) {
    cov_1vmcco6tdx.f[21]++;
    cov_1vmcco6tdx.s[151]++;

    $scope.loadFinancialData = false;
    cov_1vmcco6tdx.s[152]++;
    $scope.importedColumns = {
      id: response.id,
      columns: [{
        id: -1,
        name: $translate.instant('COMMON.NONE')
      }]
    };

    cov_1vmcco6tdx.s[153]++;
    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = response.keys[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var key = _step6.value;
        cov_1vmcco6tdx.s[154]++;

        $scope.importedColumns.columns.push(key);
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    cov_1vmcco6tdx.s[155]++;
    $uibModal.open({
      templateUrl: 'app/views/funds_disbursement/mapping.html',
      controller: FundsDisbursementMappingCtrl,
      scope: $scope,
      backdrop: 'static',
      size: 'xs',
      // keyboard: false,
      windowClass: 'animated fadeInRight left-modal'
    });
    cov_1vmcco6tdx.s[156]++;
    $scope.loadFinancialData = false;
  };

  cov_1vmcco6tdx.s[157]++;
  uploaderFile.onErrorItem = function (fileItem) {
    cov_1vmcco6tdx.f[22]++;
    cov_1vmcco6tdx.s[158]++;

    $scope.loading = false;
  };
});

function FundsDisbursementMappingCtrl($uibModalInstance, $scope, $CRUDService, $deltadate) {
  cov_1vmcco6tdx.f[23]++;

  var PATH = (cov_1vmcco6tdx.s[159]++, 'Funds_disbursement');

  cov_1vmcco6tdx.s[160]++;
  $scope.resultMap = {
    REFERENCE: $scope.importedColumns.columns[0],
    LABEL: $scope.importedColumns.columns[0],
    DATE_FUNDS: $scope.importedColumns.columns[0],
    AMOUNT: $scope.importedColumns.columns[0],
    ID_DEVISE: $scope.importedColumns.columns[0],
    RATE: $scope.importedColumns.columns[0],
    selectedFunding: $scope.me.selectedAgreement
  };

  cov_1vmcco6tdx.s[161]++;
  $scope.importData = function () {
    cov_1vmcco6tdx.f[24]++;
    cov_1vmcco6tdx.s[162]++;

    $scope.error_code = {};
    var hasError = (cov_1vmcco6tdx.s[163]++, false);
    cov_1vmcco6tdx.s[164]++;
    for (var key in $scope.resultMap) {
      cov_1vmcco6tdx.s[165]++;

      if (key === 'selectedFunding') {
        cov_1vmcco6tdx.b[37][0]++;
        cov_1vmcco6tdx.s[166]++;

        continue;
      } else {
        cov_1vmcco6tdx.b[37][1]++;
      }
      cov_1vmcco6tdx.s[167]++;
      if ($scope.resultMap[key].index >= 0) {
        cov_1vmcco6tdx.b[38][0]++;
        cov_1vmcco6tdx.s[168]++;

        continue;
      } else {
        cov_1vmcco6tdx.b[38][1]++;
      }
      cov_1vmcco6tdx.s[169]++;
      $scope.error_code[key] = true;
      cov_1vmcco6tdx.s[170]++;
      hasError = true;
    }

    cov_1vmcco6tdx.s[171]++;
    if (hasError) {
      cov_1vmcco6tdx.b[39][0]++;
      cov_1vmcco6tdx.s[172]++;

      return;
    } else {
      cov_1vmcco6tdx.b[39][1]++;
    }

    var value = (cov_1vmcco6tdx.s[173]++, angular.copy($scope.resultMap));
    cov_1vmcco6tdx.s[174]++;
    value.selectedFunding = $scope.resultMap.selectedFunding.id;

    cov_1vmcco6tdx.s[175]++;
    $CRUDService.save(PATH, { action: 'import_data', value: value, id: $scope.importedColumns.id }, function () {
      cov_1vmcco6tdx.f[25]++;
      cov_1vmcco6tdx.s[176]++;

      $scope.me.getAll(true);
      cov_1vmcco6tdx.s[177]++;
      $scope.me.getDevise();
      cov_1vmcco6tdx.s[178]++;
      $scope.closeModal();
    });
  };

  cov_1vmcco6tdx.s[179]++;
  $scope.closeModal = function () {
    cov_1vmcco6tdx.f[26]++;
    cov_1vmcco6tdx.s[180]++;

    $uibModalInstance.close('cancel');
  };
}

function FundsDisbursementEditCtrl($uibModalInstance, $scope, $CRUDService, $deltadate) {
  cov_1vmcco6tdx.f[27]++;

  var PATH = (cov_1vmcco6tdx.s[181]++, 'Funds_disbursement');
  cov_1vmcco6tdx.s[182]++;
  $scope.validateItem = function () {
    cov_1vmcco6tdx.f[28]++;
    cov_1vmcco6tdx.s[183]++;

    $scope.error_code = 0;
    var editedItem = (cov_1vmcco6tdx.s[184]++, angular.copy($scope.me.editedFund));
    cov_1vmcco6tdx.s[185]++;
    editedItem.ID_DEVISE = editedItem.ID_DEVISE.trim();
    cov_1vmcco6tdx.s[186]++;
    editedItem.REFERENCE = editedItem.REFERENCE.trim();
    cov_1vmcco6tdx.s[187]++;
    if ($scope.me.fundsDisbursement.find(function (fund) {
      cov_1vmcco6tdx.f[29]++;
      cov_1vmcco6tdx.s[188]++;

      return (cov_1vmcco6tdx.b[41][0]++, editedItem.REFERENCE === fund.REFERENCE) && (cov_1vmcco6tdx.b[41][1]++, editedItem.id != fund.id);
    })) {
      cov_1vmcco6tdx.b[40][0]++;
      cov_1vmcco6tdx.s[189]++;

      $scope.error_code = 2;
    } else {
      cov_1vmcco6tdx.b[40][1]++;
    }
    cov_1vmcco6tdx.s[190]++;
    if ($scope.error_code !== 0) {
      cov_1vmcco6tdx.b[42][0]++;
      cov_1vmcco6tdx.s[191]++;

      return;
    } else {
      cov_1vmcco6tdx.b[42][1]++;
    }
    // DATE_FUNDS
    cov_1vmcco6tdx.s[192]++;
    editedItem.DATE_FUNDS = $deltadate.format(editedItem.DATE_FUNDS, 'YYYY-MM-dd');
    cov_1vmcco6tdx.s[193]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: angular.toJson(editedItem) }, function (data) {
      cov_1vmcco6tdx.f[30]++;
      cov_1vmcco6tdx.s[194]++;

      $scope.me.getAll(true);
      cov_1vmcco6tdx.s[195]++;
      $scope.me.getDevise();
      cov_1vmcco6tdx.s[196]++;
      $scope.closeModal();
    });
  };

  cov_1vmcco6tdx.s[197]++;
  $scope.closeModal = function () {
    cov_1vmcco6tdx.f[31]++;
    cov_1vmcco6tdx.s[198]++;

    $uibModalInstance.close('cancel');
  };
}
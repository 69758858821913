'use strict';

var cov_230hxrfxsy = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator_portfolio/indicator_portfolio.js',
      hash = 'f959f79de05eae64faaae29c733038108f061d06',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator_portfolio/indicator_portfolio.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 45,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 37
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 5,
          column: 3
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '5': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 29
        }
      },
      '6': {
        start: {
          line: 11,
          column: 23
        },
        end: {
          line: 11,
          column: 60
        }
      },
      '7': {
        start: {
          line: 12,
          column: 15
        },
        end: {
          line: 12,
          column: 34
        }
      },
      '8': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 42,
          column: 4
        }
      },
      '9': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 37,
          column: 6
        }
      },
      '10': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 112
        }
      },
      '11': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 41,
          column: 5
        }
      },
      '12': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 158
        }
      },
      '13': {
        start: {
          line: 44,
          column: 2
        },
        end: {
          line: 44,
          column: 22
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 59
          },
          end: {
            line: 1,
            column: 60
          }
        },
        loc: {
          start: {
            line: 1,
            column: 201
          },
          end: {
            line: 45,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 22
          },
          end: {
            line: 14,
            column: 23
          }
        },
        loc: {
          start: {
            line: 14,
            column: 34
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 14
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }, {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        }, {
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        }],
        line: 7
      },
      '2': {
        loc: {
          start: {
            line: 11,
            column: 39
          },
          end: {
            line: 11,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 11,
            column: 39
          },
          end: {
            line: 11,
            column: 54
          }
        }, {
          start: {
            line: 11,
            column: 58
          },
          end: {
            line: 11,
            column: 59
          }
        }],
        line: 11
      },
      '3': {
        loc: {
          start: {
            line: 39,
            column: 4
          },
          end: {
            line: 41,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 4
          },
          end: {
            line: 41,
            column: 5
          }
        }, {
          start: {
            line: 39,
            column: 4
          },
          end: {
            line: 41,
            column: 5
          }
        }],
        line: 39
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_230hxrfxsy.s[0]++;
angular.module('app').controller('indicatorPortfolioCtrl', function ($scope, $stateParams, $CRUDService, $deltaUnite, $rootScope, $deltahttp, $translate, $log, $deltadate, $filter, FileUploader, $uibModal) {
  cov_230hxrfxsy.f[0]++;
  cov_230hxrfxsy.s[1]++;

  $rootScope.processPageRight('3_5');
  cov_230hxrfxsy.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_230hxrfxsy.b[0][0]++;
    cov_230hxrfxsy.s[3]++;

    return;
  } else {
    cov_230hxrfxsy.b[0][1]++;
  }

  cov_230hxrfxsy.s[4]++;
  if (!$stateParams.params) {
    cov_230hxrfxsy.b[1][0]++;
    cov_230hxrfxsy.s[5]++;

    $stateParams.params = {};
  } else {
    cov_230hxrfxsy.b[1][1]++;
  }

  var KEY_SAVE_TAB = (cov_230hxrfxsy.s[6]++, 'a_ov_tab__' + ((cov_230hxrfxsy.b[2][0]++, $stateParams.id) || (cov_230hxrfxsy.b[2][1]++, 0)));
  var PATH = (cov_230hxrfxsy.s[7]++, 'IndicateursGlobal');
  // Recuperer l'indicateur
  cov_230hxrfxsy.s[8]++;
  $scope.initialize = function () {
    cov_230hxrfxsy.f[1]++;
    cov_230hxrfxsy.s[9]++;


    $scope.selectedItem = {
      T_A_C: 0,
      id: $stateParams.indicator.id,
      AGREGATION_INDIC: $stateParams.indicator.AGREGATION_INDIC_G,
      CODE_ESPACE: $stateParams.indicator.CODE_ESPACE,
      CODE_INDIC: $stateParams.indicator.CODE_INDIC_G,
      DEB_EVAL_INDIC: Date.newDate($stateParams.indicator.DEB_EVAL_INDIC_G),
      DROITINDIC: $stateParams.indicator.DROITINDIC_G,
      FIN_EVAL_INDIC: Date.newDate($stateParams.indicator.FIN_EVAL_INDIC_G),
      FORMAT_UNITE: $stateParams.indicator.FORMAT_UNITE_G,
      ICON: $stateParams.indicator.ICON_G,
      ID_UNITE: $stateParams.indicator.ID_UNITE,
      LIBELLE_C_INDIC_ENG: $stateParams.indicator.LIBELLE_C_INDIC_ENG_G,
      LIBELLE_C_INDIC: $stateParams.indicator.LIBELLE_C_INDIC_G,
      LIBELLE_UNITES: $stateParams.indicator.LIBELLE_UNITES_G,
      PERIOD_INDIC: $stateParams.indicator.PERIOD_INDIC_G,
      TYPE_UNITES: $stateParams.indicator.TYPE_UNITES_G,
      Type_indicateur: $stateParams.indicator.Type_indicateur_G,
      categories: [],
      imageUrl: $deltahttp.getIndicatorGlobalRespositoy($stateParams.indicator.id)

    };
    cov_230hxrfxsy.s[10]++;
    $scope.listMenu = [{ active: true, id: '1', display: true, url: 'edit', name: 'Description', isplan: false }];
    cov_230hxrfxsy.s[11]++;
    if ($stateParams.indicator.id > 0) {
      cov_230hxrfxsy.b[3][0]++;
      cov_230hxrfxsy.s[12]++;

      $scope.listMenu.push({ active: true, id: '2', display: true, url: 'dashboard/main', name: $translate.instant('INDICATOR.DASHBOARD.TAB'), isplan: false });
    } else {
      cov_230hxrfxsy.b[3][1]++;
    }
  };

  cov_230hxrfxsy.s[13]++;
  $scope.initialize();
});
'use strict';

var cov_14xubh8kpe = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/document/preview/documentPreviewCtrl.js',
      hash = 'cbd0954b24b63d06965284611d36ecee01465db3',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/document/preview/documentPreviewCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 18,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 19
        },
        end: {
          line: 2,
          column: 30
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 11,
          column: 5
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 10,
          column: 5
        }
      },
      '4': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 25
        }
      },
      '5': {
        start: {
          line: 7,
          column: 6
        },
        end: {
          line: 7,
          column: 104
        }
      },
      '6': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 9,
          column: 13
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 14,
          column: 4
        }
      },
      '8': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 13,
          column: 78
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 17,
          column: 4
        }
      },
      '10': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 19
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 119
          },
          end: {
            line: 18,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 3,
            column: 70
          },
          end: {
            line: 3,
            column: 71
          }
        },
        loc: {
          start: {
            line: 3,
            column: 78
          },
          end: {
            line: 11,
            column: 3
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 18
          },
          end: {
            line: 12,
            column: 19
          }
        },
        loc: {
          start: {
            line: 12,
            column: 30
          },
          end: {
            line: 14,
            column: 3
          }
        },
        line: 12
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 15,
            column: 17
          },
          end: {
            line: 15,
            column: 18
          }
        },
        loc: {
          start: {
            line: 15,
            column: 29
          },
          end: {
            line: 17,
            column: 3
          }
        },
        line: 15
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 4
          },
          end: {
            line: 10,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 4
          },
          end: {
            line: 10,
            column: 5
          }
        }, {
          start: {
            line: 4,
            column: 4
          },
          end: {
            line: 10,
            column: 5
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 7,
            column: 27
          },
          end: {
            line: 7,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 7,
            column: 27
          },
          end: {
            line: 7,
            column: 44
          }
        }, {
          start: {
            line: 7,
            column: 48
          },
          end: {
            line: 7,
            column: 50
          }
        }],
        line: 7
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_14xubh8kpe.s[0]++;
angular.module('app').controller('documentPreviewCtrl', function ($scope, $state, $stateParams, $log, $CRUDService, $window) {
  cov_14xubh8kpe.f[0]++;

  var PATH_DOC = (cov_14xubh8kpe.s[1]++, 'Documents');
  cov_14xubh8kpe.s[2]++;
  $CRUDService.getAll(PATH_DOC, { get: 'single', id: $stateParams.id }, function (data) {
    cov_14xubh8kpe.f[1]++;
    cov_14xubh8kpe.s[3]++;

    if (data) {
      cov_14xubh8kpe.b[0][0]++;
      cov_14xubh8kpe.s[4]++;

      $scope.item = data;

      cov_14xubh8kpe.s[5]++;
      $scope.item.CORPS = ((cov_14xubh8kpe.b[1][0]++, $scope.item.CORPS) || (cov_14xubh8kpe.b[1][1]++, '')).replace('<i ', '<span ').replace('</i>', '</span>');

      cov_14xubh8kpe.s[6]++;
      return;
    } else {
      cov_14xubh8kpe.b[0][1]++;
    }
  });
  cov_14xubh8kpe.s[7]++;
  $scope.goback = function () {
    cov_14xubh8kpe.f[2]++;
    cov_14xubh8kpe.s[8]++;

    $state.go('document.document', { params: { idcl: $scope.item.IDCLASSEURS } });
  };
  cov_14xubh8kpe.s[9]++;
  $scope.print = function () {
    cov_14xubh8kpe.f[3]++;
    cov_14xubh8kpe.s[10]++;

    window.print();
  };
});
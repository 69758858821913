'use strict';

var cov_1wy53092m4 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/comments/CommentCtrl.js',
      hash = '217c8219ce96bf226fb7160d873962119bd83017',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/comments/CommentCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 385,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 24
        }
      },
      '2': {
        start: {
          line: 3,
          column: 31
        },
        end: {
          line: 3,
          column: 51
        }
      },
      '3': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 4
        }
      },
      '4': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 34
        }
      },
      '5': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 10,
          column: 4
        }
      },
      '6': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 36
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 49
        }
      },
      '8': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 29,
          column: 4
        }
      },
      '9': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 82,
          column: 4
        }
      },
      '10': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '11': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 20
        }
      },
      '12': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '13': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 53
        }
      },
      '14': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 37,
          column: 16
        }
      },
      '15': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 24
        }
      },
      '16': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '17': {
        start: {
          line: 40,
          column: 18
        },
        end: {
          line: 42,
          column: 8
        }
      },
      '18': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 23
        }
      },
      '19': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 52
        }
      },
      '20': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 35
        }
      },
      '21': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 27
        }
      },
      '22': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 43
        }
      },
      '23': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 78,
          column: 7
        }
      },
      '24': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 57,
          column: 13
        }
      },
      '25': {
        start: {
          line: 50,
          column: 25
        },
        end: {
          line: 50,
          column: 75
        }
      },
      '26': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 60
        }
      },
      '27': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 26
        }
      },
      '28': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 67,
          column: 10
        }
      },
      '29': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 62,
          column: 11
        }
      },
      '30': {
        start: {
          line: 60,
          column: 12
        },
        end: {
          line: 60,
          column: 38
        }
      },
      '31': {
        start: {
          line: 61,
          column: 12
        },
        end: {
          line: 61,
          column: 19
        }
      },
      '32': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 35
        }
      },
      '33': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 66,
          column: 11
        }
      },
      '34': {
        start: {
          line: 65,
          column: 12
        },
        end: {
          line: 65,
          column: 32
        }
      },
      '35': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 62
        }
      },
      '36': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 69,
          column: 37
        }
      },
      '37': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 77,
          column: 9
        }
      },
      '38': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 32
        }
      },
      '39': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 35
        }
      },
      '40': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 73,
          column: 30
        }
      },
      '41': {
        start: {
          line: 75,
          column: 10
        },
        end: {
          line: 75,
          column: 33
        }
      },
      '42': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 76,
          column: 36
        }
      },
      '43': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 27
        }
      },
      '44': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 126,
          column: 4
        }
      },
      '45': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 87,
          column: 5
        }
      },
      '46': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 13
        }
      },
      '47': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 88,
          column: 26
        }
      },
      '48': {
        start: {
          line: 89,
          column: 4
        },
        end: {
          line: 125,
          column: 7
        }
      },
      '49': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 29
        }
      },
      '50': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 43
        }
      },
      '51': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 97,
          column: 7
        }
      },
      '52': {
        start: {
          line: 95,
          column: 8
        },
        end: {
          line: 95,
          column: 33
        }
      },
      '53': {
        start: {
          line: 96,
          column: 8
        },
        end: {
          line: 96,
          column: 35
        }
      },
      '54': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 115,
          column: 7
        }
      },
      '55': {
        start: {
          line: 99,
          column: 8
        },
        end: {
          line: 108,
          column: 12
        }
      },
      '56': {
        start: {
          line: 101,
          column: 25
        },
        end: {
          line: 101,
          column: 75
        }
      },
      '57': {
        start: {
          line: 102,
          column: 10
        },
        end: {
          line: 102,
          column: 60
        }
      },
      '58': {
        start: {
          line: 103,
          column: 10
        },
        end: {
          line: 103,
          column: 26
        }
      },
      '59': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 62
        }
      },
      '60': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 40
        }
      },
      '61': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 114,
          column: 9
        }
      },
      '62': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 112,
          column: 36
        }
      },
      '63': {
        start: {
          line: 113,
          column: 10
        },
        end: {
          line: 113,
          column: 34
        }
      },
      '64': {
        start: {
          line: 116,
          column: 6
        },
        end: {
          line: 121,
          column: 9
        }
      },
      '65': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 24
        }
      },
      '66': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 27
        }
      },
      '67': {
        start: {
          line: 128,
          column: 2
        },
        end: {
          line: 134,
          column: 5
        }
      },
      '68': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 35
        }
      },
      '69': {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 130,
          column: 34
        }
      },
      '70': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 133,
          column: 7
        }
      },
      '71': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 38
        }
      },
      '72': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 143,
          column: 5
        }
      },
      '73': {
        start: {
          line: 137,
          column: 4
        },
        end: {
          line: 137,
          column: 40
        }
      },
      '74': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 141,
          column: 6
        }
      },
      '75': {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 142,
          column: 38
        }
      },
      '76': {
        start: {
          line: 144,
          column: 2
        },
        end: {
          line: 146,
          column: 5
        }
      },
      '77': {
        start: {
          line: 145,
          column: 4
        },
        end: {
          line: 145,
          column: 34
        }
      },
      '78': {
        start: {
          line: 147,
          column: 2
        },
        end: {
          line: 294,
          column: 4
        }
      },
      '79': {
        start: {
          line: 158,
          column: 6
        },
        end: {
          line: 158,
          column: 74
        }
      },
      '80': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 163,
          column: 7
        }
      },
      '81': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 162,
          column: 24
        }
      },
      '82': {
        start: {
          line: 164,
          column: 6
        },
        end: {
          line: 164,
          column: 20
        }
      },
      '83': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 165,
          column: 26
        }
      },
      '84': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 190,
          column: 9
        }
      },
      '85': {
        start: {
          line: 167,
          column: 20
        },
        end: {
          line: 169,
          column: 10
        }
      },
      '86': {
        start: {
          line: 168,
          column: 10
        },
        end: {
          line: 168,
          column: 25
        }
      },
      '87': {
        start: {
          line: 170,
          column: 8
        },
        end: {
          line: 170,
          column: 54
        }
      },
      '88': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 171,
          column: 37
        }
      },
      '89': {
        start: {
          line: 172,
          column: 8
        },
        end: {
          line: 172,
          column: 29
        }
      },
      '90': {
        start: {
          line: 173,
          column: 8
        },
        end: {
          line: 173,
          column: 37
        }
      },
      '91': {
        start: {
          line: 174,
          column: 8
        },
        end: {
          line: 187,
          column: 9
        }
      },
      '92': {
        start: {
          line: 175,
          column: 10
        },
        end: {
          line: 183,
          column: 14
        }
      },
      '93': {
        start: {
          line: 176,
          column: 27
        },
        end: {
          line: 176,
          column: 77
        }
      },
      '94': {
        start: {
          line: 177,
          column: 12
        },
        end: {
          line: 177,
          column: 62
        }
      },
      '95': {
        start: {
          line: 178,
          column: 12
        },
        end: {
          line: 178,
          column: 28
        }
      },
      '96': {
        start: {
          line: 184,
          column: 10
        },
        end: {
          line: 184,
          column: 64
        }
      },
      '97': {
        start: {
          line: 185,
          column: 10
        },
        end: {
          line: 185,
          column: 32
        }
      },
      '98': {
        start: {
          line: 186,
          column: 10
        },
        end: {
          line: 186,
          column: 30
        }
      },
      '99': {
        start: {
          line: 189,
          column: 8
        },
        end: {
          line: 189,
          column: 31
        }
      },
      '100': {
        start: {
          line: 193,
          column: 6
        },
        end: {
          line: 193,
          column: 37
        }
      },
      '101': {
        start: {
          line: 194,
          column: 19
        },
        end: {
          line: 194,
          column: 21
        }
      },
      '102': {
        start: {
          line: 195,
          column: 6
        },
        end: {
          line: 203,
          column: 7
        }
      },
      '103': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 39
        }
      },
      '104': {
        start: {
          line: 204,
          column: 26
        },
        end: {
          line: 204,
          column: 28
        }
      },
      '105': {
        start: {
          line: 210,
          column: 6
        },
        end: {
          line: 216,
          column: 9
        }
      },
      '106': {
        start: {
          line: 217,
          column: 6
        },
        end: {
          line: 217,
          column: 44
        }
      },
      '107': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 224,
          column: 7
        }
      },
      '108': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 223,
          column: 9
        }
      },
      '109': {
        start: {
          line: 222,
          column: 10
        },
        end: {
          line: 222,
          column: 22
        }
      },
      '110': {
        start: {
          line: 225,
          column: 6
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '111': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 228,
          column: 9
        }
      },
      '112': {
        start: {
          line: 227,
          column: 10
        },
        end: {
          line: 227,
          column: 22
        }
      },
      '113': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 230,
          column: 19
        }
      },
      '114': {
        start: {
          line: 233,
          column: 23
        },
        end: {
          line: 233,
          column: 41
        }
      },
      '115': {
        start: {
          line: 234,
          column: 6
        },
        end: {
          line: 234,
          column: 26
        }
      },
      '116': {
        start: {
          line: 235,
          column: 6
        },
        end: {
          line: 235,
          column: 32
        }
      },
      '117': {
        start: {
          line: 236,
          column: 6
        },
        end: {
          line: 238,
          column: 7
        }
      },
      '118': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 237,
          column: 43
        }
      },
      '119': {
        start: {
          line: 239,
          column: 6
        },
        end: {
          line: 241,
          column: 7
        }
      },
      '120': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 240,
          column: 50
        }
      },
      '121': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 242,
          column: 19
        }
      },
      '122': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 243,
          column: 23
        }
      },
      '123': {
        start: {
          line: 244,
          column: 6
        },
        end: {
          line: 244,
          column: 31
        }
      },
      '124': {
        start: {
          line: 249,
          column: 27
        },
        end: {
          line: 249,
          column: 54
        }
      },
      '125': {
        start: {
          line: 250,
          column: 6
        },
        end: {
          line: 250,
          column: 32
        }
      },
      '126': {
        start: {
          line: 251,
          column: 19
        },
        end: {
          line: 251,
          column: 21
        }
      },
      '127': {
        start: {
          line: 252,
          column: 26
        },
        end: {
          line: 252,
          column: 29
        }
      },
      '128': {
        start: {
          line: 253,
          column: 6
        },
        end: {
          line: 257,
          column: 7
        }
      },
      '129': {
        start: {
          line: 254,
          column: 8
        },
        end: {
          line: 256,
          column: 9
        }
      },
      '130': {
        start: {
          line: 255,
          column: 10
        },
        end: {
          line: 255,
          column: 25
        }
      },
      '131': {
        start: {
          line: 259,
          column: 6
        },
        end: {
          line: 263,
          column: 7
        }
      },
      '132': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 262,
          column: 9
        }
      },
      '133': {
        start: {
          line: 261,
          column: 10
        },
        end: {
          line: 261,
          column: 32
        }
      },
      '134': {
        start: {
          line: 264,
          column: 6
        },
        end: {
          line: 264,
          column: 26
        }
      },
      '135': {
        start: {
          line: 265,
          column: 6
        },
        end: {
          line: 265,
          column: 47
        }
      },
      '136': {
        start: {
          line: 267,
          column: 6
        },
        end: {
          line: 267,
          column: 24
        }
      },
      '137': {
        start: {
          line: 269,
          column: 6
        },
        end: {
          line: 269,
          column: 62
        }
      },
      '138': {
        start: {
          line: 271,
          column: 6
        },
        end: {
          line: 271,
          column: 27
        }
      },
      '139': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 272,
          column: 31
        }
      },
      '140': {
        start: {
          line: 274,
          column: 6
        },
        end: {
          line: 274,
          column: 40
        }
      },
      '141': {
        start: {
          line: 278,
          column: 6
        },
        end: {
          line: 278,
          column: 31
        }
      },
      '142': {
        start: {
          line: 285,
          column: 6
        },
        end: {
          line: 292,
          column: 9
        }
      },
      '143': {
        start: {
          line: 296,
          column: 2
        },
        end: {
          line: 303,
          column: 4
        }
      },
      '144': {
        start: {
          line: 297,
          column: 4
        },
        end: {
          line: 299,
          column: 5
        }
      },
      '145': {
        start: {
          line: 298,
          column: 6
        },
        end: {
          line: 298,
          column: 67
        }
      },
      '146': {
        start: {
          line: 300,
          column: 4
        },
        end: {
          line: 302,
          column: 5
        }
      },
      '147': {
        start: {
          line: 301,
          column: 6
        },
        end: {
          line: 301,
          column: 53
        }
      },
      '148': {
        start: {
          line: 306,
          column: 2
        },
        end: {
          line: 327,
          column: 5
        }
      },
      '149': {
        start: {
          line: 307,
          column: 19
        },
        end: {
          line: 310,
          column: 5
        }
      },
      '150': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 316,
          column: 7
        }
      },
      '151': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 315,
          column: 9
        }
      },
      '152': {
        start: {
          line: 313,
          column: 8
        },
        end: {
          line: 313,
          column: 59
        }
      },
      '153': {
        start: {
          line: 314,
          column: 8
        },
        end: {
          line: 314,
          column: 20
        }
      },
      '154': {
        start: {
          line: 318,
          column: 4
        },
        end: {
          line: 326,
          column: 7
        }
      },
      '155': {
        start: {
          line: 319,
          column: 6
        },
        end: {
          line: 322,
          column: 9
        }
      },
      '156': {
        start: {
          line: 320,
          column: 8
        },
        end: {
          line: 320,
          column: 69
        }
      },
      '157': {
        start: {
          line: 321,
          column: 8
        },
        end: {
          line: 321,
          column: 20
        }
      },
      '158': {
        start: {
          line: 323,
          column: 6
        },
        end: {
          line: 325,
          column: 7
        }
      },
      '159': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 324,
          column: 33
        }
      },
      '160': {
        start: {
          line: 329,
          column: 2
        },
        end: {
          line: 329,
          column: 20
        }
      },
      '161': {
        start: {
          line: 331,
          column: 2
        },
        end: {
          line: 368,
          column: 4
        }
      },
      '162': {
        start: {
          line: 338,
          column: 6
        },
        end: {
          line: 341,
          column: 8
        }
      },
      '163': {
        start: {
          line: 342,
          column: 6
        },
        end: {
          line: 344,
          column: 7
        }
      },
      '164': {
        start: {
          line: 343,
          column: 8
        },
        end: {
          line: 343,
          column: 45
        }
      },
      '165': {
        start: {
          line: 345,
          column: 6
        },
        end: {
          line: 345,
          column: 40
        }
      },
      '166': {
        start: {
          line: 348,
          column: 6
        },
        end: {
          line: 351,
          column: 8
        }
      },
      '167': {
        start: {
          line: 352,
          column: 6
        },
        end: {
          line: 352,
          column: 36
        }
      },
      '168': {
        start: {
          line: 353,
          column: 6
        },
        end: {
          line: 353,
          column: 40
        }
      },
      '169': {
        start: {
          line: 356,
          column: 6
        },
        end: {
          line: 359,
          column: 8
        }
      },
      '170': {
        start: {
          line: 360,
          column: 20
        },
        end: {
          line: 360,
          column: 66
        }
      },
      '171': {
        start: {
          line: 361,
          column: 6
        },
        end: {
          line: 365,
          column: 7
        }
      },
      '172': {
        start: {
          line: 362,
          column: 8
        },
        end: {
          line: 362,
          column: 47
        }
      },
      '173': {
        start: {
          line: 364,
          column: 8
        },
        end: {
          line: 364,
          column: 52
        }
      },
      '174': {
        start: {
          line: 366,
          column: 6
        },
        end: {
          line: 366,
          column: 40
        }
      },
      '175': {
        start: {
          line: 370,
          column: 2
        },
        end: {
          line: 370,
          column: 36
        }
      },
      '176': {
        start: {
          line: 372,
          column: 2
        },
        end: {
          line: 383,
          column: 5
        }
      },
      '177': {
        start: {
          line: 373,
          column: 4
        },
        end: {
          line: 382,
          column: 7
        }
      },
      '178': {
        start: {
          line: 374,
          column: 19
        },
        end: {
          line: 374,
          column: 37
        }
      },
      '179': {
        start: {
          line: 375,
          column: 6
        },
        end: {
          line: 378,
          column: 7
        }
      },
      '180': {
        start: {
          line: 376,
          column: 8
        },
        end: {
          line: 376,
          column: 43
        }
      },
      '181': {
        start: {
          line: 377,
          column: 8
        },
        end: {
          line: 377,
          column: 33
        }
      },
      '182': {
        start: {
          line: 379,
          column: 6
        },
        end: {
          line: 381,
          column: 7
        }
      },
      '183': {
        start: {
          line: 380,
          column: 8
        },
        end: {
          line: 380,
          column: 48
        }
      },
      '184': {
        start: {
          line: 384,
          column: 2
        },
        end: {
          line: 384,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 48
          },
          end: {
            line: 1,
            column: 49
          }
        },
        loc: {
          start: {
            line: 1,
            column: 193
          },
          end: {
            line: 385,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 4,
            column: 21
          },
          end: {
            line: 4,
            column: 22
          }
        },
        loc: {
          start: {
            line: 4,
            column: 33
          },
          end: {
            line: 6,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 8,
            column: 23
          },
          end: {
            line: 8,
            column: 24
          }
        },
        loc: {
          start: {
            line: 8,
            column: 41
          },
          end: {
            line: 10,
            column: 3
          }
        },
        line: 8
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 30,
            column: 20
          },
          end: {
            line: 30,
            column: 21
          }
        },
        loc: {
          start: {
            line: 30,
            column: 36
          },
          end: {
            line: 82,
            column: 3
          }
        },
        line: 30
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 39,
            column: 193
          },
          end: {
            line: 39,
            column: 194
          }
        },
        loc: {
          start: {
            line: 39,
            column: 201
          },
          end: {
            line: 79,
            column: 5
          }
        },
        line: 39
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 40,
            column: 27
          },
          end: {
            line: 40,
            column: 28
          }
        },
        loc: {
          start: {
            line: 40,
            column: 35
          },
          end: {
            line: 42,
            column: 7
          }
        },
        line: 40
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 48,
            column: 29
          },
          end: {
            line: 48,
            column: 30
          }
        },
        loc: {
          start: {
            line: 48,
            column: 47
          },
          end: {
            line: 57,
            column: 12
          }
        },
        line: 48
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 58,
            column: 29
          },
          end: {
            line: 58,
            column: 30
          }
        },
        loc: {
          start: {
            line: 58,
            column: 41
          },
          end: {
            line: 67,
            column: 9
          }
        },
        line: 58
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 79,
            column: 7
          },
          end: {
            line: 79,
            column: 8
          }
        },
        loc: {
          start: {
            line: 79,
            column: 13
          },
          end: {
            line: 81,
            column: 5
          }
        },
        line: 79
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 84,
            column: 20
          },
          end: {
            line: 84,
            column: 21
          }
        },
        loc: {
          start: {
            line: 84,
            column: 60
          },
          end: {
            line: 126,
            column: 3
          }
        },
        line: 84
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 91,
            column: 7
          },
          end: {
            line: 91,
            column: 8
          }
        },
        loc: {
          start: {
            line: 91,
            column: 15
          },
          end: {
            line: 123,
            column: 5
          }
        },
        line: 91
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 99,
            column: 29
          },
          end: {
            line: 99,
            column: 30
          }
        },
        loc: {
          start: {
            line: 99,
            column: 47
          },
          end: {
            line: 108,
            column: 11
          }
        },
        line: 99
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 123,
            column: 7
          },
          end: {
            line: 123,
            column: 8
          }
        },
        loc: {
          start: {
            line: 123,
            column: 13
          },
          end: {
            line: 125,
            column: 5
          }
        },
        line: 123
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 128,
            column: 25
          },
          end: {
            line: 128,
            column: 26
          }
        },
        loc: {
          start: {
            line: 128,
            column: 31
          },
          end: {
            line: 134,
            column: 3
          }
        },
        line: 128
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 131,
            column: 50
          },
          end: {
            line: 131,
            column: 51
          }
        },
        loc: {
          start: {
            line: 131,
            column: 58
          },
          end: {
            line: 133,
            column: 5
          }
        },
        line: 131
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 136,
            column: 24
          },
          end: {
            line: 136,
            column: 25
          }
        },
        loc: {
          start: {
            line: 136,
            column: 30
          },
          end: {
            line: 143,
            column: 3
          }
        },
        line: 136
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 144,
            column: 25
          },
          end: {
            line: 144,
            column: 26
          }
        },
        loc: {
          start: {
            line: 144,
            column: 31
          },
          end: {
            line: 146,
            column: 3
          }
        },
        line: 144
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 158,
            column: 62
          },
          end: {
            line: 158,
            column: 63
          }
        },
        loc: {
          start: {
            line: 158,
            column: 70
          },
          end: {
            line: 158,
            column: 72
          }
        },
        line: 158
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 166,
            column: 80
          },
          end: {
            line: 166,
            column: 81
          }
        },
        loc: {
          start: {
            line: 166,
            column: 88
          },
          end: {
            line: 188,
            column: 7
          }
        },
        line: 166
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 167,
            column: 29
          },
          end: {
            line: 167,
            column: 30
          }
        },
        loc: {
          start: {
            line: 167,
            column: 37
          },
          end: {
            line: 169,
            column: 9
          }
        },
        line: 167
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 175,
            column: 31
          },
          end: {
            line: 175,
            column: 32
          }
        },
        loc: {
          start: {
            line: 175,
            column: 49
          },
          end: {
            line: 183,
            column: 13
          }
        },
        line: 175
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 188,
            column: 9
          },
          end: {
            line: 188,
            column: 10
          }
        },
        loc: {
          start: {
            line: 188,
            column: 15
          },
          end: {
            line: 190,
            column: 7
          }
        },
        line: 188
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 296,
            column: 25
          },
          end: {
            line: 296,
            column: 26
          }
        },
        loc: {
          start: {
            line: 296,
            column: 37
          },
          end: {
            line: 303,
            column: 3
          }
        },
        line: 296
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 306,
            column: 21
          },
          end: {
            line: 306,
            column: 22
          }
        },
        loc: {
          start: {
            line: 306,
            column: 27
          },
          end: {
            line: 327,
            column: 3
          }
        },
        line: 306
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 311,
            column: 40
          },
          end: {
            line: 311,
            column: 41
          }
        },
        loc: {
          start: {
            line: 311,
            column: 48
          },
          end: {
            line: 316,
            column: 5
          }
        },
        line: 311
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 312,
            column: 38
          },
          end: {
            line: 312,
            column: 39
          }
        },
        loc: {
          start: {
            line: 312,
            column: 46
          },
          end: {
            line: 315,
            column: 7
          }
        },
        line: 312
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 318,
            column: 64
          },
          end: {
            line: 318,
            column: 65
          }
        },
        loc: {
          start: {
            line: 318,
            column: 72
          },
          end: {
            line: 326,
            column: 5
          }
        },
        line: 318
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 319,
            column: 41
          },
          end: {
            line: 319,
            column: 42
          }
        },
        loc: {
          start: {
            line: 319,
            column: 49
          },
          end: {
            line: 322,
            column: 7
          }
        },
        line: 319
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 372,
            column: 28
          },
          end: {
            line: 372,
            column: 29
          }
        },
        loc: {
          start: {
            line: 372,
            column: 34
          },
          end: {
            line: 383,
            column: 3
          }
        },
        line: 372
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 373,
            column: 89
          },
          end: {
            line: 373,
            column: 90
          }
        },
        loc: {
          start: {
            line: 373,
            column: 97
          },
          end: {
            line: 382,
            column: 5
          }
        },
        line: 373
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }, {
          start: {
            line: 31,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }],
        line: 31
      },
      '1': {
        loc: {
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        }, {
          start: {
            line: 34,
            column: 4
          },
          end: {
            line: 36,
            column: 5
          }
        }],
        line: 34
      },
      '2': {
        loc: {
          start: {
            line: 39,
            column: 86
          },
          end: {
            line: 39,
            column: 190
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 39,
            column: 133
          },
          end: {
            line: 39,
            column: 178
          }
        }, {
          start: {
            line: 39,
            column: 181
          },
          end: {
            line: 39,
            column: 190
          }
        }],
        line: 39
      },
      '3': {
        loc: {
          start: {
            line: 59,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        }, {
          start: {
            line: 59,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        }],
        line: 59
      },
      '4': {
        loc: {
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        }, {
          start: {
            line: 64,
            column: 10
          },
          end: {
            line: 66,
            column: 11
          }
        }],
        line: 64
      },
      '5': {
        loc: {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }, {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }],
        line: 70
      },
      '6': {
        loc: {
          start: {
            line: 84,
            column: 42
          },
          end: {
            line: 84,
            column: 58
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 84,
            column: 56
          },
          end: {
            line: 84,
            column: 58
          }
        }],
        line: 84
      },
      '7': {
        loc: {
          start: {
            line: 85,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        }, {
          start: {
            line: 85,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        }],
        line: 85
      },
      '8': {
        loc: {
          start: {
            line: 89,
            column: 123
          },
          end: {
            line: 89,
            column: 133
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 89,
            column: 123
          },
          end: {
            line: 89,
            column: 127
          }
        }, {
          start: {
            line: 89,
            column: 131
          },
          end: {
            line: 89,
            column: 133
          }
        }],
        line: 89
      },
      '9': {
        loc: {
          start: {
            line: 90,
            column: 6
          },
          end: {
            line: 90,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 90,
            column: 6
          },
          end: {
            line: 90,
            column: 28
          }
        }, {
          start: {
            line: 90,
            column: 32
          },
          end: {
            line: 90,
            column: 50
          }
        }],
        line: 90
      },
      '10': {
        loc: {
          start: {
            line: 94,
            column: 6
          },
          end: {
            line: 97,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 6
          },
          end: {
            line: 97,
            column: 7
          }
        }, {
          start: {
            line: 94,
            column: 6
          },
          end: {
            line: 97,
            column: 7
          }
        }],
        line: 94
      },
      '11': {
        loc: {
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 114,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 114,
            column: 9
          }
        }, {
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 114,
            column: 9
          }
        }],
        line: 111
      },
      '12': {
        loc: {
          start: {
            line: 111,
            column: 12
          },
          end: {
            line: 111,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 111,
            column: 12
          },
          end: {
            line: 111,
            column: 48
          }
        }, {
          start: {
            line: 111,
            column: 52
          },
          end: {
            line: 111,
            column: 83
          }
        }],
        line: 111
      },
      '13': {
        loc: {
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }, {
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }],
        line: 161
      },
      '14': {
        loc: {
          start: {
            line: 195,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 195,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }, {
          start: {
            line: 195,
            column: 6
          },
          end: {
            line: 203,
            column: 7
          }
        }],
        line: 195
      },
      '15': {
        loc: {
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 223,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 223,
            column: 9
          }
        }, {
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 223,
            column: 9
          }
        }],
        line: 221
      },
      '16': {
        loc: {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        }, {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 228,
            column: 9
          }
        }],
        line: 226
      },
      '17': {
        loc: {
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 256,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 256,
            column: 9
          }
        }, {
          start: {
            line: 254,
            column: 8
          },
          end: {
            line: 256,
            column: 9
          }
        }],
        line: 254
      },
      '18': {
        loc: {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        }, {
          start: {
            line: 260,
            column: 8
          },
          end: {
            line: 262,
            column: 9
          }
        }],
        line: 260
      },
      '19': {
        loc: {
          start: {
            line: 323,
            column: 6
          },
          end: {
            line: 325,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 323,
            column: 6
          },
          end: {
            line: 325,
            column: 7
          }
        }, {
          start: {
            line: 323,
            column: 6
          },
          end: {
            line: 325,
            column: 7
          }
        }],
        line: 323
      },
      '20': {
        loc: {
          start: {
            line: 361,
            column: 6
          },
          end: {
            line: 365,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 361,
            column: 6
          },
          end: {
            line: 365,
            column: 7
          }
        }, {
          start: {
            line: 361,
            column: 6
          },
          end: {
            line: 365,
            column: 7
          }
        }],
        line: 361
      },
      '21': {
        loc: {
          start: {
            line: 375,
            column: 6
          },
          end: {
            line: 378,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 375,
            column: 6
          },
          end: {
            line: 378,
            column: 7
          }
        }, {
          start: {
            line: 375,
            column: 6
          },
          end: {
            line: 378,
            column: 7
          }
        }],
        line: 375
      },
      '22': {
        loc: {
          start: {
            line: 379,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 379,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }, {
          start: {
            line: 379,
            column: 6
          },
          end: {
            line: 381,
            column: 7
          }
        }],
        line: 379
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1wy53092m4.s[0]++;
angular.module('app').controller('CommentCtrl', function ($scope, $sce, SweetAlert, $deltadate, $translate, $CRUDService, $deltahttp, $rootScope, $compile, $timeout, $filter, $uibModal, toast, $log) {
  cov_1wy53092m4.f[0]++;

  var PATH = (cov_1wy53092m4.s[1]++, 'Comment');
  var KEY_COMMENT_POSITION = (cov_1wy53092m4.s[2]++, 'COMMENT_X_POSITION');
  cov_1wy53092m4.s[3]++;
  $scope.getUserId = function () {
    cov_1wy53092m4.f[1]++;
    cov_1wy53092m4.s[4]++;

    return $CRUDService.getUser();
  };

  cov_1wy53092m4.s[5]++;
  $scope.trustAsHtml = function (string) {
    cov_1wy53092m4.f[2]++;
    cov_1wy53092m4.s[6]++;

    return $sce.trustAsHtml(string);
  };

  cov_1wy53092m4.s[7]++;
  $scope.lastUpdate = Date.newDate().getTime();
  cov_1wy53092m4.s[8]++;
  $scope.options_summernote_comment = {
    height: 500,
    focus: false,
    airMode: false,
    toolbar: [['edit', ['undo', 'redo']], ['headline', ['style']], ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']], ['fontface', ['fontname']], ['textsize', ['fontsize']], ['fontclr', ['color']], ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']], ['height', ['height']], ['table', ['table']], ['insert', ['hr']]]
  };
  cov_1wy53092m4.s[9]++;
  $scope.getChild = function (item) {
    cov_1wy53092m4.f[3]++;
    cov_1wy53092m4.s[10]++;

    if (angular.isUndefined(item.page)) {
      cov_1wy53092m4.b[0][0]++;
      cov_1wy53092m4.s[11]++;

      item.page = 0;
    } else {
      cov_1wy53092m4.b[0][1]++;
    }
    cov_1wy53092m4.s[12]++;
    if (item.id == 0) {
      cov_1wy53092m4.b[1][0]++;
      cov_1wy53092m4.s[13]++;

      $scope.lastUpdate = Date.newDate().getTime();
    } else {
      cov_1wy53092m4.b[1][1]++;
    }
    cov_1wy53092m4.s[14]++;
    item.page++;
    cov_1wy53092m4.s[15]++;
    item.loading = true;
    cov_1wy53092m4.s[16]++;
    $CRUDService.getAll(PATH, { get: 'all', page: item.page, id: item.id, communities: $scope.filter.selectedCommunities.length > 0 ? (cov_1wy53092m4.b[2][0]++, $scope.filter.selectedCommunities.join(' , ')) : (cov_1wy53092m4.b[2][1]++, undefined) }, function (data) {
      cov_1wy53092m4.f[4]++;

      var ids = (cov_1wy53092m4.s[17]++, data.map(function (item) {
        cov_1wy53092m4.f[5]++;
        cov_1wy53092m4.s[18]++;

        return item.id;
      }));
      cov_1wy53092m4.s[19]++;
      $scope.data.openComments(angular.toJson(ids));
      cov_1wy53092m4.s[20]++;
      $rootScope.getNotification();
      cov_1wy53092m4.s[21]++;
      item.loading = false;
      cov_1wy53092m4.s[22]++;
      item.displaymore = data.length >= 10;
      cov_1wy53092m4.s[23]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function _loop() {
          var el = _step.value;
          cov_1wy53092m4.s[24]++;

          el.getFormatedDate = function (format) {
            cov_1wy53092m4.f[6]++;

            // return $filter('date')(this.CREATEDDATE * 1000, 'medium');
            var tmp_date = (cov_1wy53092m4.s[25]++, $filter('date')(this.CREATEDDATE * 1000, 'medium'));
            cov_1wy53092m4.s[26]++;
            tmp_date = tmp_date.slice(0, tmp_date.length - 3);
            cov_1wy53092m4.s[27]++;
            return tmp_date;
            /* const date = this.CREATEDDATE.split(' ');
             date[0] = date[0].split('-');
             date[1] = date[1].split(':');
             return $filter('date')(new Date(date[0][0], parseInt(date[0][1], 10) - 1, date[0][2], date[1][0], date[1][1]), format.replace('YYYY', 'yyyy').replace('DD', 'dd'));
            */
          };
          cov_1wy53092m4.s[28]++;
          el._displayComment = function () {
            cov_1wy53092m4.f[7]++;
            cov_1wy53092m4.s[29]++;

            if (el.commentDisplay == true) {
              cov_1wy53092m4.b[3][0]++;
              cov_1wy53092m4.s[30]++;

              el.commentDisplay = false;
              cov_1wy53092m4.s[31]++;
              return;
            } else {
              cov_1wy53092m4.b[3][1]++;
            }
            cov_1wy53092m4.s[32]++;
            el.commentDisplay = true;
            cov_1wy53092m4.s[33]++;
            if (el.COMMENT_CHILDS.length == 0) {
              cov_1wy53092m4.b[4][0]++;
              cov_1wy53092m4.s[34]++;

              $scope.getChild(el);
            } else {
              cov_1wy53092m4.b[4][1]++;
            }
          };
          cov_1wy53092m4.s[35]++;
          el.linkUser = $deltahttp.getUserRepository(el.IDUSER);
          cov_1wy53092m4.s[36]++;
          item.COMMENT_CHILDS.push(el);
          cov_1wy53092m4.s[37]++;
          if (item.id == 0) {
            cov_1wy53092m4.b[5][0]++;
            cov_1wy53092m4.s[38]++;

            el.showComment = true;
            cov_1wy53092m4.s[39]++;
            el.commentDisplay = true;
            cov_1wy53092m4.s[40]++;
            $scope.getChild(el);
          } else {
            cov_1wy53092m4.b[5][1]++;
            cov_1wy53092m4.s[41]++;

            el.showComment = false;
            cov_1wy53092m4.s[42]++;
            el.commentDisplay = false;
          }
        };

        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }, function () {
      cov_1wy53092m4.f[8]++;
      cov_1wy53092m4.s[43]++;

      item.loading = false;
    });
  };

  cov_1wy53092m4.s[44]++;
  $scope.addChild = function (item, user) {
    var _this = this;

    var communities = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_1wy53092m4.b[6][0]++, '');
    cov_1wy53092m4.f[9]++;
    cov_1wy53092m4.s[45]++;

    if (!item.newItem) {
      cov_1wy53092m4.b[7][0]++;
      cov_1wy53092m4.s[46]++;

      return;
    } else {
      cov_1wy53092m4.b[7][1]++;
    }
    cov_1wy53092m4.s[47]++;
    item.isloading = true;
    cov_1wy53092m4.s[48]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', idp: item.id, comment: item.newItem, communities: communities, users: $filter('json')((cov_1wy53092m4.b[8][0]++, user) || (cov_1wy53092m4.b[8][1]++, [])), lastd: ((cov_1wy53092m4.b[9][0]++, item.COMMENT_CHILDS[0]) || (cov_1wy53092m4.b[9][1]++, { CREATEDDATE: '0' })).CREATEDDATE
    }, function (data) {
      cov_1wy53092m4.f[10]++;
      cov_1wy53092m4.s[49]++;

      item.isloading = false;
      cov_1wy53092m4.s[50]++;
      item.displaymore = data.length >= 10;
      cov_1wy53092m4.s[51]++;
      if (item.COMMENT_IDPARENT != 0) {
        cov_1wy53092m4.b[10][0]++;
        cov_1wy53092m4.s[52]++;

        item.showComment = false;
        cov_1wy53092m4.s[53]++;
        item.commentDisplay = true;
      } else {
        cov_1wy53092m4.b[10][1]++;
      }
      cov_1wy53092m4.s[54]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _el = _step2.value;
          cov_1wy53092m4.s[55]++;

          _el.getFormatedDate = function (format) {
            cov_1wy53092m4.f[11]++;

            // return $filter('date')(this.CREATEDDATE * 1000, 'medium');
            var tmp_date = (cov_1wy53092m4.s[56]++, $filter('date')(this.CREATEDDATE * 1000, 'medium'));
            cov_1wy53092m4.s[57]++;
            tmp_date = tmp_date.slice(0, tmp_date.length - 3);
            cov_1wy53092m4.s[58]++;
            return tmp_date;
            /* const date = this.CREATEDDATE.split(' ');
            date[0] = date[0].split('-');
            date[1] = date[1].split(':');
            return $filter('date')(new Date(date[0][0], parseInt(date[0][1], 10) - 1, date[0][2], date[1][0], date[1][1]), format.replace('YYYY', 'yyyy').replace('DD', 'dd'));
            */
          };
          cov_1wy53092m4.s[59]++;
          _el.linkUser = $deltahttp.getUserRepository(_el.IDUSER);
          cov_1wy53092m4.s[60]++;
          item.COMMENT_CHILDS.unshift(_el);
          cov_1wy53092m4.s[61]++;
          if ((cov_1wy53092m4.b[12][0]++, item.COMMENT_CHILDS.length % 10 == 1) && (cov_1wy53092m4.b[12][1]++, item.COMMENT_CHILDS.length != 1)) {
            cov_1wy53092m4.b[11][0]++;
            cov_1wy53092m4.s[62]++;

            item.COMMENT_CHILDS.pop();
            cov_1wy53092m4.s[63]++;
            item.displaymore = true;
          } else {
            cov_1wy53092m4.b[11][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_1wy53092m4.s[64]++;
      toast.create({
        timeout: 5 * 1000,
        message: $translate.instant('COMMENT.SUCCESS_ADD'),
        className: 'alert-success',
        dismissible: true
      });
      cov_1wy53092m4.s[65]++;
      item.newItem = '';
    }, function () {
      cov_1wy53092m4.f[12]++;
      cov_1wy53092m4.s[66]++;

      _this.loading = false;
    });
  };

  cov_1wy53092m4.s[67]++;
  $scope.displayUsers = function (id) {
    cov_1wy53092m4.f[13]++;
    cov_1wy53092m4.s[68]++;

    $scope.data.loadingUser = true;
    cov_1wy53092m4.s[69]++;
    $scope.data.usersComment = [];
    cov_1wy53092m4.s[70]++;
    $CRUDService.getAll(PATH, { get: 'users', id: id }, function (data) {
      cov_1wy53092m4.f[14]++;
      cov_1wy53092m4.s[71]++;

      $scope.data.usersComment = data;
    });
  };

  cov_1wy53092m4.s[72]++;
  $scope.refreshData = function () {
    cov_1wy53092m4.f[15]++;
    cov_1wy53092m4.s[73]++;

    $scope.displayRefreshButton = false;
    cov_1wy53092m4.s[74]++;
    $scope.data.data = {
      id: 0,
      COMMENT_CHILDS: []
    };
    cov_1wy53092m4.s[75]++;
    $scope.getChild($scope.data.data);
  };
  cov_1wy53092m4.s[76]++;
  $scope.closePanelfn = function () {
    cov_1wy53092m4.f[16]++;
    cov_1wy53092m4.s[77]++;

    $scope.data.closePanel = true;
  };
  cov_1wy53092m4.s[78]++;
  $scope.data = {
    closePanel: false,
    searchUser: '',
    page: 0,
    add_: false,
    type: false,
    data: {
      id: 0,
      COMMENT_CHILDS: []
    },
    openComments: function openComments(ids) {
      cov_1wy53092m4.s[79]++;

      $CRUDService.save(PATH, { action: 'open_comments', ids: ids }, function (data) {
        cov_1wy53092m4.f[17]++;
      });
    },
    getAll: function getAll(parent) {
      var _this2 = this;

      cov_1wy53092m4.s[80]++;

      if (angular.isUndefined(parent.page)) {
        cov_1wy53092m4.b[13][0]++;
        cov_1wy53092m4.s[81]++;

        parent.page = 0;
      } else {
        cov_1wy53092m4.b[13][1]++;
      }
      cov_1wy53092m4.s[82]++;
      parent.page++;
      cov_1wy53092m4.s[83]++;
      this.loading = true;
      cov_1wy53092m4.s[84]++;
      $CRUDService.getAll(PATH, { get: 'all', page: parent.page, id: parent.id }, function (data) {
        cov_1wy53092m4.f[18]++;

        var ids = (cov_1wy53092m4.s[85]++, data.map(function (item) {
          cov_1wy53092m4.f[19]++;
          cov_1wy53092m4.s[86]++;

          return item.id;
        }));
        cov_1wy53092m4.s[87]++;
        $scope.data.openComments(angular.toJson(ids));
        cov_1wy53092m4.s[88]++;
        $rootScope.getNotification();
        cov_1wy53092m4.s[89]++;
        _this2.loading = false;
        cov_1wy53092m4.s[90]++;
        parent.COMMENT_CHILDS = data;
        cov_1wy53092m4.s[91]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = parent.COMMENT_CHILDS[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _el2 = _step3.value;
            cov_1wy53092m4.s[92]++;

            _el2.getFormatedDate = function (format) {
              cov_1wy53092m4.f[20]++;

              var tmp_date = (cov_1wy53092m4.s[93]++, $filter('date')(this.CREATEDDATE * 1000, 'medium'));
              cov_1wy53092m4.s[94]++;
              tmp_date = tmp_date.slice(0, tmp_date.length - 3);
              cov_1wy53092m4.s[95]++;
              return tmp_date;
              /* const date = this.CREATEDDATE.split(' ');
              date[0] = date[0].split('-');
              date[1] = date[1].split(':');
              return $filter('date')(new Date(date[0][0], parseInt(date[0][1], 10) - 1, date[0][2], date[1][0], date[1][1]), format.replace('YYYY', 'yyyy').replace('DD', 'dd'));
              */
            };
            cov_1wy53092m4.s[96]++;
            _el2.linkUser = $deltahttp.getUserRepository(_el2.IDUSER);
            cov_1wy53092m4.s[97]++;
            _el2.showComment = true;
            cov_1wy53092m4.s[98]++;
            $scope.getChild(_el2);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }, function () {
        cov_1wy53092m4.f[21]++;
        cov_1wy53092m4.s[99]++;

        parent.loading = false;
      });
    },
    add: function add(parent) {
      cov_1wy53092m4.s[100]++;

      $scope.data.checkedAll = false;
      var user = (cov_1wy53092m4.s[101]++, []);
      cov_1wy53092m4.s[102]++;
      if (!$rootScope.addCommentDirectly) {
        /* for (const el_ of $scope.data.listUser) {
           user.push({
             IDUSER: el_.id
           });
         } */

        cov_1wy53092m4.b[14][0]++;
      } else {
        cov_1wy53092m4.b[14][1]++;
        cov_1wy53092m4.s[103]++;

        $scope.data.checkedAll = false;
      }
      var communities = (cov_1wy53092m4.s[104]++, []);
      /* for (const el_ of $scope.data.communities) {
        if (!$rootScope.addCommentDirectly || $rootScope.addCommentDirectly == el_.id) {
          communities.push({id: el_.id});
        }
      } */
      cov_1wy53092m4.s[105]++;
      this.edit({
        COMMENT: '<p></p>',
        COMMENT_IDPARENT: parent.id,
        id: 0,
        USERS: user,
        COMMUNITIES: communities
      });
      cov_1wy53092m4.s[106]++;
      $rootScope.addCommentDirectly = false;
    },
    canValidate: function canValidate() {
      cov_1wy53092m4.s[107]++;

      for (var key in this.itemEdit.USERS) {
        cov_1wy53092m4.s[108]++;

        if (this.itemEdit.USERS[key]) {
          cov_1wy53092m4.b[15][0]++;
          cov_1wy53092m4.s[109]++;

          return true;
        } else {
          cov_1wy53092m4.b[15][1]++;
        }
      }
      cov_1wy53092m4.s[110]++;
      for (var _key in this.itemEdit.COMMUNITIES) {
        cov_1wy53092m4.s[111]++;

        if (this.itemEdit.COMMUNITIES[_key]) {
          cov_1wy53092m4.b[16][0]++;
          cov_1wy53092m4.s[112]++;

          return true;
        } else {
          cov_1wy53092m4.b[16][1]++;
        }
      }
      cov_1wy53092m4.s[113]++;
      return false;
    },
    edit: function edit(item) {
      var itemEdit = (cov_1wy53092m4.s[114]++, angular.copy(item));
      cov_1wy53092m4.s[115]++;
      itemEdit.USERS = {};
      cov_1wy53092m4.s[116]++;
      itemEdit.COMMUNITIES = {};
      cov_1wy53092m4.s[117]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = item.USERS[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var user = _step4.value;
          cov_1wy53092m4.s[118]++;

          itemEdit.USERS[user.IDUSER] = true;
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_1wy53092m4.s[119]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = item.COMMUNITIES[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var community = _step5.value;
          cov_1wy53092m4.s[120]++;

          itemEdit.COMMUNITIES[community.id] = true;
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1wy53092m4.s[121]++;
      this.tab = 1;
      cov_1wy53092m4.s[122]++;
      this.add_ = true;
      cov_1wy53092m4.s[123]++;
      this.itemEdit = itemEdit;

      // this.openEdit();
    },
    saveData: function saveData() {
      var validateItem = (cov_1wy53092m4.s[124]++, angular.copy(this.itemEdit));
      cov_1wy53092m4.s[125]++;
      $scope.error_code = false;
      var user = (cov_1wy53092m4.s[126]++, []);
      var communities = (cov_1wy53092m4.s[127]++, [0]);
      cov_1wy53092m4.s[128]++;
      for (var key in validateItem.USERS) {
        cov_1wy53092m4.s[129]++;

        if (validateItem.USERS[key]) {
          cov_1wy53092m4.b[17][0]++;
          cov_1wy53092m4.s[130]++;

          user.push(key);
        } else {
          cov_1wy53092m4.b[17][1]++;
        }
      }

      cov_1wy53092m4.s[131]++;
      for (var _key2 in validateItem.COMMUNITIES) {
        cov_1wy53092m4.s[132]++;

        if (validateItem.COMMUNITIES[_key2]) {
          cov_1wy53092m4.b[18][0]++;
          cov_1wy53092m4.s[133]++;

          communities.push(_key2);
        } else {
          cov_1wy53092m4.b[18][1]++;
        }
      }
      cov_1wy53092m4.s[134]++;
      communities.push(0);
      cov_1wy53092m4.s[135]++;
      this.data.newItem = validateItem.COMMENT;

      cov_1wy53092m4.s[136]++;
      $scope.initData();

      cov_1wy53092m4.s[137]++;
      $scope.addChild(this.data, user, communities.join(';'));

      cov_1wy53092m4.s[138]++;
      $scope.refreshData();
      cov_1wy53092m4.s[139]++;
      $scope.checkNewMessage();

      cov_1wy53092m4.s[140]++;
      $scope.getChild($scope.data.data);

      // $scope.openCommentView();
      // $scope.openCommentView();
      cov_1wy53092m4.s[141]++;
      $scope.data.add_ = false;

      /* $CRUDService.save(PATH, {action: 'MiseAJour',idp: validateItem.COMMENT_IDPARENT, comment: validateItem.COMMENT, users: $filter('json')(user)}, (data) => {
        $scope.data.add_ = false;
      }); */
    },
    openEdit: function openEdit() {
      cov_1wy53092m4.s[142]++;

      $uibModal.open({
        templateUrl: 'app/views/comments/edit.html',
        controller: CommentCtrl_edit,
        scope: $scope,
        size: 'sm_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal'
      });
    }
  };

  cov_1wy53092m4.s[143]++;
  $scope.selectAllUser = function () {
    cov_1wy53092m4.f[22]++;
    cov_1wy53092m4.s[144]++;
    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = $scope.data.listUser[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var user = _step6.value;
        cov_1wy53092m4.s[145]++;

        $scope.data.itemEdit.USERS[user.id] = $scope.data.checkedAll;
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    cov_1wy53092m4.s[146]++;
    var _iteratorNormalCompletion7 = true;
    var _didIteratorError7 = false;
    var _iteratorError7 = undefined;

    try {
      for (var _iterator7 = $scope.data.communities[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
        var _el3 = _step7.value;
        cov_1wy53092m4.s[147]++;

        $scope.data.itemEdit.COMMUNITIES[_el3.id] = true;
      }
    } catch (err) {
      _didIteratorError7 = true;
      _iteratorError7 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion7 && _iterator7.return) {
          _iterator7.return();
        }
      } finally {
        if (_didIteratorError7) {
          throw _iteratorError7;
        }
      }
    }
  };
  // $scope.data.getAll($scope.data.data);

  cov_1wy53092m4.s[148]++;
  $scope.initData = function () {
    cov_1wy53092m4.f[23]++;

    var Params = (cov_1wy53092m4.s[149]++, {
      get: 'all',
      projet: '[' + $rootScope.currentProject.project.id + ']'
    });
    cov_1wy53092m4.s[150]++;
    $CRUDService.getAll('User', Params, function (data) {
      cov_1wy53092m4.f[24]++;
      cov_1wy53092m4.s[151]++;

      $scope.data.listUser = data.map(function (user) {
        cov_1wy53092m4.f[25]++;
        cov_1wy53092m4.s[152]++;

        user.imageUrl = $deltahttp.getUserPicture(user.id);
        cov_1wy53092m4.s[153]++;
        return user;
      });
    });

    cov_1wy53092m4.s[154]++;
    $CRUDService.getAll('communities', { get: 'all', simple: 1 }, function (data) {
      cov_1wy53092m4.f[26]++;
      cov_1wy53092m4.s[155]++;

      $scope.data.communities = data.map(function (item) {
        cov_1wy53092m4.f[27]++;
        cov_1wy53092m4.s[156]++;

        item.urlImage = $deltahttp.getCommunitiesRepository(item.id);
        cov_1wy53092m4.s[157]++;
        return item;
      });
      cov_1wy53092m4.s[158]++;
      if ($rootScope.addCommentDirectly) {
        cov_1wy53092m4.b[19][0]++;
        cov_1wy53092m4.s[159]++;

        $scope.data.add({ id: 0 });
      } else {
        cov_1wy53092m4.b[19][1]++;
      }
    });
  };

  cov_1wy53092m4.s[160]++;
  $scope.initData();

  cov_1wy53092m4.s[161]++;
  $scope.filter = {
    baseData: {
      id: 0,
      COMMENT_CHILDS: []
    },
    selectedCommunities: [],
    selectAllCommunities: function selectAllCommunities() {
      cov_1wy53092m4.s[162]++;

      $scope.data.data = {
        id: 0,
        COMMENT_CHILDS: []
      };
      cov_1wy53092m4.s[163]++;
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = $scope.data.communities[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var _el4 = _step8.value;
          cov_1wy53092m4.s[164]++;

          this.selectedCommunities.push(_el4.id);
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }

      cov_1wy53092m4.s[165]++;
      $scope.getChild($scope.data.data);
    },
    unselectAllCommunities: function unselectAllCommunities() {
      cov_1wy53092m4.s[166]++;

      $scope.data.data = {
        id: 0,
        COMMENT_CHILDS: []
      };
      cov_1wy53092m4.s[167]++;
      this.selectedCommunities = [];
      cov_1wy53092m4.s[168]++;
      $scope.getChild($scope.data.data);
    },
    addCommunity: function addCommunity(community) {
      cov_1wy53092m4.s[169]++;

      $scope.data.data = {
        id: 0,
        COMMENT_CHILDS: []
      };
      var index = (cov_1wy53092m4.s[170]++, this.selectedCommunities.indexOf(community.id));
      cov_1wy53092m4.s[171]++;
      if (index >= 0) {
        cov_1wy53092m4.b[20][0]++;
        cov_1wy53092m4.s[172]++;

        this.selectedCommunities.splice(index);
      } else {
        cov_1wy53092m4.b[20][1]++;
        cov_1wy53092m4.s[173]++;

        this.selectedCommunities.push(community.id);
      }
      cov_1wy53092m4.s[174]++;
      $scope.getChild($scope.data.data);
    }
  };

  cov_1wy53092m4.s[175]++;
  $scope.getChild($scope.data.data);

  cov_1wy53092m4.s[176]++;
  $scope.checkNewMessage = function () {
    cov_1wy53092m4.f[28]++;
    cov_1wy53092m4.s[177]++;

    $CRUDService.getAll(PATH, { get: 'check_new_message', lastUpdate: $scope.lastUpdate }, function (data) {
      cov_1wy53092m4.f[29]++;

      var item = (cov_1wy53092m4.s[178]++, parseInt(data, 10));
      cov_1wy53092m4.s[179]++;
      if (item > 0) {
        cov_1wy53092m4.b[21][0]++;
        cov_1wy53092m4.s[180]++;

        $scope.displayRefreshButton = true;
        cov_1wy53092m4.s[181]++;
        $scope.newMessage = item;
      } else {
        cov_1wy53092m4.b[21][1]++;
      }
      cov_1wy53092m4.s[182]++;
      if (!$scope.closePanel) {
        cov_1wy53092m4.b[22][0]++;
        cov_1wy53092m4.s[183]++;

        $timeout($scope.checkNewMessage, 60000);
      } else {
        cov_1wy53092m4.b[22][1]++;
      }
    });
  };
  cov_1wy53092m4.s[184]++;
  $scope.checkNewMessage();
});
'use strict';

var cov_1mukwrcu3r = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-map.js',
      hash = 'a73221c8d2a2da9beababe4eaa4f13b533619d97',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-map.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 23
        },
        end: {
          line: 440,
          column: 1
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 25
        }
      },
      '2': {
        start: {
          line: 4,
          column: 16
        },
        end: {
          line: 4,
          column: 20
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 131,
          column: 4
        }
      },
      '4': {
        start: {
          line: 39,
          column: 30
        },
        end: {
          line: 39,
          column: 57
        }
      },
      '5': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 40,
          column: 69
        }
      },
      '6': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 42,
          column: 75
        }
      },
      '7': {
        start: {
          line: 46,
          column: 21
        },
        end: {
          line: 46,
          column: 50
        }
      },
      '8': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 54,
          column: 11
        }
      },
      '9': {
        start: {
          line: 51,
          column: 12
        },
        end: {
          line: 51,
          column: 53
        }
      },
      '10': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 62,
          column: 11
        }
      },
      '11': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 59,
          column: 55
        }
      },
      '12': {
        start: {
          line: 68,
          column: 8
        },
        end: {
          line: 68,
          column: 50
        }
      },
      '13': {
        start: {
          line: 74,
          column: 21
        },
        end: {
          line: 74,
          column: 87
        }
      },
      '14': {
        start: {
          line: 75,
          column: 21
        },
        end: {
          line: 75,
          column: 25
        }
      },
      '15': {
        start: {
          line: 76,
          column: 23
        },
        end: {
          line: 76,
          column: 27
        }
      },
      '16': {
        start: {
          line: 77,
          column: 17
        },
        end: {
          line: 77,
          column: 19
        }
      },
      '17': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 95
        }
      },
      '18': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 60
        }
      },
      '19': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 129,
          column: 7
        }
      },
      '20': {
        start: {
          line: 81,
          column: 36
        },
        end: {
          line: 110,
          column: 9
        }
      },
      '21': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 72
        }
      },
      '22': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 100
        }
      },
      '23': {
        start: {
          line: 113,
          column: 8
        },
        end: {
          line: 115,
          column: 9
        }
      },
      '24': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 64
        }
      },
      '25': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 48
        }
      },
      '26': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 117,
          column: 60
        }
      },
      '27': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 120,
          column: 9
        }
      },
      '28': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 119,
          column: 89
        }
      },
      '29': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 124,
          column: 11
        }
      },
      '30': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 128,
          column: 9
        }
      },
      '31': {
        start: {
          line: 126,
          column: 10
        },
        end: {
          line: 126,
          column: 52
        }
      },
      '32': {
        start: {
          line: 127,
          column: 10
        },
        end: {
          line: 127,
          column: 69
        }
      },
      '33': {
        start: {
          line: 133,
          column: 2
        },
        end: {
          line: 133,
          column: 28
        }
      },
      '34': {
        start: {
          line: 135,
          column: 21
        },
        end: {
          line: 135,
          column: 73
        }
      },
      '35': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '36': {
        start: {
          line: 138,
          column: 17
        },
        end: {
          line: 138,
          column: 104
        }
      },
      '37': {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 161,
          column: 5
        }
      },
      '38': {
        start: {
          line: 144,
          column: 8
        },
        end: {
          line: 144,
          column: 105
        }
      },
      '39': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 14
        }
      },
      '40': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 108
        }
      },
      '41': {
        start: {
          line: 148,
          column: 8
        },
        end: {
          line: 148,
          column: 14
        }
      },
      '42': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 108
        }
      },
      '43': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 14
        }
      },
      '44': {
        start: {
          line: 153,
          column: 8
        },
        end: {
          line: 153,
          column: 108
        }
      },
      '45': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 14
        }
      },
      '46': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 156,
          column: 108
        }
      },
      '47': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 157,
          column: 14
        }
      },
      '48': {
        start: {
          line: 159,
          column: 8
        },
        end: {
          line: 159,
          column: 105
        }
      },
      '49': {
        start: {
          line: 160,
          column: 8
        },
        end: {
          line: 160,
          column: 14
        }
      },
      '50': {
        start: {
          line: 162,
          column: 4
        },
        end: {
          line: 162,
          column: 122
        }
      },
      '51': {
        start: {
          line: 189,
          column: 2
        },
        end: {
          line: 207,
          column: 4
        }
      },
      '52': {
        start: {
          line: 190,
          column: 22
        },
        end: {
          line: 190,
          column: 68
        }
      },
      '53': {
        start: {
          line: 191,
          column: 16
        },
        end: {
          line: 191,
          column: 57
        }
      },
      '54': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 204,
          column: 5
        }
      },
      '55': {
        start: {
          line: 193,
          column: 6
        },
        end: {
          line: 195,
          column: 7
        }
      },
      '56': {
        start: {
          line: 194,
          column: 8
        },
        end: {
          line: 194,
          column: 96
        }
      },
      '57': {
        start: {
          line: 196,
          column: 6
        },
        end: {
          line: 198,
          column: 7
        }
      },
      '58': {
        start: {
          line: 197,
          column: 8
        },
        end: {
          line: 197,
          column: 84
        }
      },
      '59': {
        start: {
          line: 199,
          column: 6
        },
        end: {
          line: 201,
          column: 7
        }
      },
      '60': {
        start: {
          line: 200,
          column: 8
        },
        end: {
          line: 200,
          column: 161
        }
      },
      '61': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 203,
          column: 78
        }
      },
      '62': {
        start: {
          line: 206,
          column: 4
        },
        end: {
          line: 206,
          column: 130
        }
      },
      '63': {
        start: {
          line: 208,
          column: 2
        },
        end: {
          line: 367,
          column: 4
        }
      },
      '64': {
        start: {
          line: 209,
          column: 16
        },
        end: {
          line: 209,
          column: 17
        }
      },
      '65': {
        start: {
          line: 210,
          column: 19
        },
        end: {
          line: 210,
          column: 25
        }
      },
      '66': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 360,
          column: 5
        }
      },
      '67': {
        start: {
          line: 212,
          column: 14
        },
        end: {
          line: 212,
          column: 18
        }
      },
      '68': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 217,
          column: 21
        }
      },
      '69': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 216,
          column: 9
        }
      },
      '70': {
        start: {
          line: 215,
          column: 10
        },
        end: {
          line: 215,
          column: 51
        }
      },
      '71': {
        start: {
          line: 219,
          column: 6
        },
        end: {
          line: 223,
          column: 7
        }
      },
      '72': {
        start: {
          line: 220,
          column: 8
        },
        end: {
          line: 220,
          column: 27
        }
      },
      '73': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 221,
          column: 35
        }
      },
      '74': {
        start: {
          line: 222,
          column: 8
        },
        end: {
          line: 222,
          column: 51
        }
      },
      '75': {
        start: {
          line: 224,
          column: 6
        },
        end: {
          line: 224,
          column: 22
        }
      },
      '76': {
        start: {
          line: 227,
          column: 6
        },
        end: {
          line: 359,
          column: 7
        }
      },
      '77': {
        start: {
          line: 228,
          column: 24
        },
        end: {
          line: 236,
          column: 10
        }
      },
      '78': {
        start: {
          line: 231,
          column: 12
        },
        end: {
          line: 234,
          column: 15
        }
      },
      '79': {
        start: {
          line: 237,
          column: 8
        },
        end: {
          line: 239,
          column: 9
        }
      },
      '80': {
        start: {
          line: 238,
          column: 10
        },
        end: {
          line: 238,
          column: 27
        }
      },
      '81': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 304,
          column: 9
        }
      },
      '82': {
        start: {
          line: 241,
          column: 10
        },
        end: {
          line: 303,
          column: 11
        }
      },
      '83': {
        start: {
          line: 242,
          column: 12
        },
        end: {
          line: 269,
          column: 13
        }
      },
      '84': {
        start: {
          line: 243,
          column: 28
        },
        end: {
          line: 243,
          column: 121
        }
      },
      '85': {
        start: {
          line: 244,
          column: 14
        },
        end: {
          line: 246,
          column: 15
        }
      },
      '86': {
        start: {
          line: 245,
          column: 16
        },
        end: {
          line: 245,
          column: 25
        }
      },
      '87': {
        start: {
          line: 247,
          column: 14
        },
        end: {
          line: 249,
          column: 15
        }
      },
      '88': {
        start: {
          line: 248,
          column: 16
        },
        end: {
          line: 248,
          column: 25
        }
      },
      '89': {
        start: {
          line: 250,
          column: 14
        },
        end: {
          line: 250,
          column: 36
        }
      },
      '90': {
        start: {
          line: 251,
          column: 14
        },
        end: {
          line: 251,
          column: 36
        }
      },
      '91': {
        start: {
          line: 252,
          column: 14
        },
        end: {
          line: 252,
          column: 22
        }
      },
      '92': {
        start: {
          line: 253,
          column: 14
        },
        end: {
          line: 268,
          column: 17
        }
      },
      '93': {
        start: {
          line: 258,
          column: 30
        },
        end: {
          line: 260,
          column: 306
        }
      },
      '94': {
        start: {
          line: 261,
          column: 16
        },
        end: {
          line: 267,
          column: 19
        }
      },
      '95': {
        start: {
          line: 262,
          column: 18
        },
        end: {
          line: 266,
          column: 19
        }
      },
      '96': {
        start: {
          line: 263,
          column: 20
        },
        end: {
          line: 263,
          column: 75
        }
      },
      '97': {
        start: {
          line: 265,
          column: 20
        },
        end: {
          line: 265,
          column: 52
        }
      },
      '98': {
        start: {
          line: 271,
          column: 12
        },
        end: {
          line: 300,
          column: 13
        }
      },
      '99': {
        start: {
          line: 272,
          column: 26
        },
        end: {
          line: 272,
          column: 119
        }
      },
      '100': {
        start: {
          line: 273,
          column: 14
        },
        end: {
          line: 275,
          column: 15
        }
      },
      '101': {
        start: {
          line: 274,
          column: 16
        },
        end: {
          line: 274,
          column: 25
        }
      },
      '102': {
        start: {
          line: 276,
          column: 14
        },
        end: {
          line: 284,
          column: 17
        }
      },
      '103': {
        start: {
          line: 277,
          column: 16
        },
        end: {
          line: 279,
          column: 17
        }
      },
      '104': {
        start: {
          line: 278,
          column: 18
        },
        end: {
          line: 278,
          column: 31
        }
      },
      '105': {
        start: {
          line: 280,
          column: 16
        },
        end: {
          line: 282,
          column: 17
        }
      },
      '106': {
        start: {
          line: 281,
          column: 18
        },
        end: {
          line: 281,
          column: 31
        }
      },
      '107': {
        start: {
          line: 283,
          column: 16
        },
        end: {
          line: 283,
          column: 28
        }
      },
      '108': {
        start: {
          line: 285,
          column: 14
        },
        end: {
          line: 299,
          column: 17
        }
      },
      '109': {
        start: {
          line: 289,
          column: 30
        },
        end: {
          line: 291,
          column: 306
        }
      },
      '110': {
        start: {
          line: 292,
          column: 16
        },
        end: {
          line: 298,
          column: 19
        }
      },
      '111': {
        start: {
          line: 293,
          column: 18
        },
        end: {
          line: 297,
          column: 19
        }
      },
      '112': {
        start: {
          line: 294,
          column: 20
        },
        end: {
          line: 294,
          column: 75
        }
      },
      '113': {
        start: {
          line: 296,
          column: 20
        },
        end: {
          line: 296,
          column: 52
        }
      },
      '114': {
        start: {
          line: 306,
          column: 20
        },
        end: {
          line: 310,
          column: 10
        }
      },
      '115': {
        start: {
          line: 307,
          column: 10
        },
        end: {
          line: 307,
          column: 45
        }
      },
      '116': {
        start: {
          line: 309,
          column: 10
        },
        end: {
          line: 309,
          column: 48
        }
      },
      '117': {
        start: {
          line: 311,
          column: 8
        },
        end: {
          line: 358,
          column: 11
        }
      },
      '118': {
        start: {
          line: 312,
          column: 26
        },
        end: {
          line: 312,
          column: 32
        }
      },
      '119': {
        start: {
          line: 313,
          column: 26
        },
        end: {
          line: 321,
          column: 21
        }
      },
      '120': {
        start: {
          line: 316,
          column: 14
        },
        end: {
          line: 319,
          column: 17
        }
      },
      '121': {
        start: {
          line: 322,
          column: 10
        },
        end: {
          line: 352,
          column: 11
        }
      },
      '122': {
        start: {
          line: 323,
          column: 12
        },
        end: {
          line: 351,
          column: 13
        }
      },
      '123': {
        start: {
          line: 324,
          column: 28
        },
        end: {
          line: 324,
          column: 52
        }
      },
      '124': {
        start: {
          line: 325,
          column: 14
        },
        end: {
          line: 327,
          column: 15
        }
      },
      '125': {
        start: {
          line: 326,
          column: 16
        },
        end: {
          line: 326,
          column: 25
        }
      },
      '126': {
        start: {
          line: 328,
          column: 14
        },
        end: {
          line: 330,
          column: 15
        }
      },
      '127': {
        start: {
          line: 329,
          column: 16
        },
        end: {
          line: 329,
          column: 25
        }
      },
      '128': {
        start: {
          line: 331,
          column: 14
        },
        end: {
          line: 331,
          column: 48
        }
      },
      '129': {
        start: {
          line: 332,
          column: 14
        },
        end: {
          line: 332,
          column: 48
        }
      },
      '130': {
        start: {
          line: 333,
          column: 14
        },
        end: {
          line: 333,
          column: 22
        }
      },
      '131': {
        start: {
          line: 334,
          column: 14
        },
        end: {
          line: 349,
          column: 17
        }
      },
      '132': {
        start: {
          line: 339,
          column: 30
        },
        end: {
          line: 341,
          column: 306
        }
      },
      '133': {
        start: {
          line: 342,
          column: 16
        },
        end: {
          line: 348,
          column: 19
        }
      },
      '134': {
        start: {
          line: 343,
          column: 18
        },
        end: {
          line: 347,
          column: 19
        }
      },
      '135': {
        start: {
          line: 344,
          column: 20
        },
        end: {
          line: 344,
          column: 75
        }
      },
      '136': {
        start: {
          line: 346,
          column: 20
        },
        end: {
          line: 346,
          column: 52
        }
      },
      '137': {
        start: {
          line: 353,
          column: 10
        },
        end: {
          line: 356,
          column: 11
        }
      },
      '138': {
        start: {
          line: 354,
          column: 12
        },
        end: {
          line: 354,
          column: 31
        }
      },
      '139': {
        start: {
          line: 355,
          column: 12
        },
        end: {
          line: 355,
          column: 31
        }
      },
      '140': {
        start: {
          line: 357,
          column: 10
        },
        end: {
          line: 357,
          column: 39
        }
      },
      '141': {
        start: {
          line: 362,
          column: 4
        },
        end: {
          line: 365,
          column: 5
        }
      },
      '142': {
        start: {
          line: 363,
          column: 6
        },
        end: {
          line: 363,
          column: 25
        }
      },
      '143': {
        start: {
          line: 364,
          column: 6
        },
        end: {
          line: 364,
          column: 25
        }
      },
      '144': {
        start: {
          line: 366,
          column: 4
        },
        end: {
          line: 366,
          column: 33
        }
      },
      '145': {
        start: {
          line: 369,
          column: 2
        },
        end: {
          line: 373,
          column: 4
        }
      },
      '146': {
        start: {
          line: 370,
          column: 4
        },
        end: {
          line: 372,
          column: 7
        }
      },
      '147': {
        start: {
          line: 371,
          column: 6
        },
        end: {
          line: 371,
          column: 34
        }
      },
      '148': {
        start: {
          line: 375,
          column: 2
        },
        end: {
          line: 388,
          column: 4
        }
      },
      '149': {
        start: {
          line: 376,
          column: 4
        },
        end: {
          line: 376,
          column: 31
        }
      },
      '150': {
        start: {
          line: 377,
          column: 4
        },
        end: {
          line: 377,
          column: 27
        }
      },
      '151': {
        start: {
          line: 378,
          column: 19
        },
        end: {
          line: 378,
          column: 173
        }
      },
      '152': {
        start: {
          line: 379,
          column: 4
        },
        end: {
          line: 385,
          column: 7
        }
      },
      '153': {
        start: {
          line: 380,
          column: 6
        },
        end: {
          line: 380,
          column: 62
        }
      },
      '154': {
        start: {
          line: 381,
          column: 6
        },
        end: {
          line: 383,
          column: 7
        }
      },
      '155': {
        start: {
          line: 382,
          column: 8
        },
        end: {
          line: 382,
          column: 32
        }
      },
      '156': {
        start: {
          line: 384,
          column: 6
        },
        end: {
          line: 384,
          column: 35
        }
      },
      '157': {
        start: {
          line: 387,
          column: 4
        },
        end: {
          line: 387,
          column: 24
        }
      },
      '158': {
        start: {
          line: 390,
          column: 2
        },
        end: {
          line: 405,
          column: 5
        }
      },
      '159': {
        start: {
          line: 391,
          column: 26
        },
        end: {
          line: 391,
          column: 62
        }
      },
      '160': {
        start: {
          line: 393,
          column: 4
        },
        end: {
          line: 403,
          column: 7
        }
      },
      '161': {
        start: {
          line: 394,
          column: 18
        },
        end: {
          line: 394,
          column: 61
        }
      },
      '162': {
        start: {
          line: 395,
          column: 6
        },
        end: {
          line: 401,
          column: 7
        }
      },
      '163': {
        start: {
          line: 396,
          column: 8
        },
        end: {
          line: 400,
          column: 34
        }
      },
      '164': {
        start: {
          line: 402,
          column: 6
        },
        end: {
          line: 402,
          column: 17
        }
      },
      '165': {
        start: {
          line: 404,
          column: 4
        },
        end: {
          line: 404,
          column: 38
        }
      },
      '166': {
        start: {
          line: 407,
          column: 2
        },
        end: {
          line: 407,
          column: 25
        }
      },
      '167': {
        start: {
          line: 408,
          column: 2
        },
        end: {
          line: 408,
          column: 21
        }
      },
      '168': {
        start: {
          line: 417,
          column: 2
        },
        end: {
          line: 439,
          column: 5
        }
      },
      '169': {
        start: {
          line: 418,
          column: 4
        },
        end: {
          line: 418,
          column: 34
        }
      },
      '170': {
        start: {
          line: 419,
          column: 4
        },
        end: {
          line: 419,
          column: 47
        }
      },
      '171': {
        start: {
          line: 420,
          column: 4
        },
        end: {
          line: 422,
          column: 5
        }
      },
      '172': {
        start: {
          line: 421,
          column: 6
        },
        end: {
          line: 421,
          column: 33
        }
      },
      '173': {
        start: {
          line: 423,
          column: 22
        },
        end: {
          line: 423,
          column: 54
        }
      },
      '174': {
        start: {
          line: 424,
          column: 4
        },
        end: {
          line: 429,
          column: 46
        }
      },
      '175': {
        start: {
          line: 430,
          column: 4
        },
        end: {
          line: 435,
          column: 5
        }
      },
      '176': {
        start: {
          line: 431,
          column: 6
        },
        end: {
          line: 433,
          column: 9
        }
      },
      '177': {
        start: {
          line: 434,
          column: 6
        },
        end: {
          line: 434,
          column: 44
        }
      },
      '178': {
        start: {
          line: 436,
          column: 4
        },
        end: {
          line: 436,
          column: 43
        }
      },
      '179': {
        start: {
          line: 437,
          column: 4
        },
        end: {
          line: 437,
          column: 36
        }
      },
      '180': {
        start: {
          line: 438,
          column: 4
        },
        end: {
          line: 438,
          column: 45
        }
      },
      '181': {
        start: {
          line: 441,
          column: 0
        },
        end: {
          line: 453,
          column: 3
        }
      },
      '182': {
        start: {
          line: 442,
          column: 2
        },
        end: {
          line: 452,
          column: 4
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 23
          },
          end: {
            line: 1,
            column: 24
          }
        },
        loc: {
          start: {
            line: 1,
            column: 127
          },
          end: {
            line: 440,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 137,
            column: 21
          },
          end: {
            line: 137,
            column: 22
          }
        },
        loc: {
          start: {
            line: 137,
            column: 38
          },
          end: {
            line: 163,
            column: 3
          }
        },
        line: 137
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 189,
            column: 22
          },
          end: {
            line: 189,
            column: 23
          }
        },
        loc: {
          start: {
            line: 189,
            column: 54
          },
          end: {
            line: 207,
            column: 3
          }
        },
        line: 189
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 208,
            column: 22
          },
          end: {
            line: 208,
            column: 23
          }
        },
        loc: {
          start: {
            line: 208,
            column: 38
          },
          end: {
            line: 367,
            column: 3
          }
        },
        line: 208
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 257,
            column: 46
          },
          end: {
            line: 257,
            column: 47
          }
        },
        loc: {
          start: {
            line: 257,
            column: 59
          },
          end: {
            line: 268,
            column: 15
          }
        },
        line: 257
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 261,
            column: 122
          },
          end: {
            line: 261,
            column: 123
          }
        },
        loc: {
          start: {
            line: 261,
            column: 133
          },
          end: {
            line: 267,
            column: 17
          }
        },
        line: 261
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 276,
            column: 35
          },
          end: {
            line: 276,
            column: 36
          }
        },
        loc: {
          start: {
            line: 276,
            column: 42
          },
          end: {
            line: 284,
            column: 15
          }
        },
        line: 276
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 288,
            column: 37
          },
          end: {
            line: 288,
            column: 38
          }
        },
        loc: {
          start: {
            line: 288,
            column: 50
          },
          end: {
            line: 299,
            column: 15
          }
        },
        line: 288
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 292,
            column: 122
          },
          end: {
            line: 292,
            column: 123
          }
        },
        loc: {
          start: {
            line: 292,
            column: 133
          },
          end: {
            line: 298,
            column: 17
          }
        },
        line: 292
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 306,
            column: 29
          },
          end: {
            line: 306,
            column: 30
          }
        },
        loc: {
          start: {
            line: 306,
            column: 37
          },
          end: {
            line: 308,
            column: 9
          }
        },
        line: 306
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 308,
            column: 18
          },
          end: {
            line: 308,
            column: 19
          }
        },
        loc: {
          start: {
            line: 308,
            column: 26
          },
          end: {
            line: 310,
            column: 9
          }
        },
        line: 308
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 311,
            column: 85
          },
          end: {
            line: 311,
            column: 86
          }
        },
        loc: {
          start: {
            line: 311,
            column: 94
          },
          end: {
            line: 358,
            column: 9
          }
        },
        line: 311
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 338,
            column: 46
          },
          end: {
            line: 338,
            column: 47
          }
        },
        loc: {
          start: {
            line: 338,
            column: 59
          },
          end: {
            line: 349,
            column: 15
          }
        },
        line: 338
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 342,
            column: 122
          },
          end: {
            line: 342,
            column: 123
          }
        },
        loc: {
          start: {
            line: 342,
            column: 133
          },
          end: {
            line: 348,
            column: 17
          }
        },
        line: 342
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 369,
            column: 27
          },
          end: {
            line: 369,
            column: 28
          }
        },
        loc: {
          start: {
            line: 369,
            column: 41
          },
          end: {
            line: 373,
            column: 3
          }
        },
        line: 369
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 370,
            column: 111
          },
          end: {
            line: 370,
            column: 112
          }
        },
        loc: {
          start: {
            line: 370,
            column: 119
          },
          end: {
            line: 372,
            column: 5
          }
        },
        line: 370
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 375,
            column: 18
          },
          end: {
            line: 375,
            column: 19
          }
        },
        loc: {
          start: {
            line: 375,
            column: 32
          },
          end: {
            line: 388,
            column: 3
          }
        },
        line: 375
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 379,
            column: 38
          },
          end: {
            line: 379,
            column: 39
          }
        },
        loc: {
          start: {
            line: 379,
            column: 46
          },
          end: {
            line: 385,
            column: 5
          }
        },
        line: 379
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 390,
            column: 23
          },
          end: {
            line: 390,
            column: 24
          }
        },
        loc: {
          start: {
            line: 390,
            column: 29
          },
          end: {
            line: 405,
            column: 3
          }
        },
        line: 390
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 393,
            column: 27
          },
          end: {
            line: 393,
            column: 28
          }
        },
        loc: {
          start: {
            line: 393,
            column: 34
          },
          end: {
            line: 403,
            column: 5
          }
        },
        line: 393
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 417,
            column: 78
          },
          end: {
            line: 417,
            column: 79
          }
        },
        loc: {
          start: {
            line: 417,
            column: 86
          },
          end: {
            line: 439,
            column: 3
          }
        },
        line: 417
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 441,
            column: 48
          },
          end: {
            line: 441,
            column: 49
          }
        },
        loc: {
          start: {
            line: 441,
            column: 54
          },
          end: {
            line: 453,
            column: 1
          }
        },
        line: 441
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 40,
            column: 26
          },
          end: {
            line: 40,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 40,
            column: 42
          },
          end: {
            line: 40,
            column: 55
          }
        }, {
          start: {
            line: 40,
            column: 58
          },
          end: {
            line: 40,
            column: 68
          }
        }],
        line: 40
      },
      '1': {
        loc: {
          start: {
            line: 74,
            column: 22
          },
          end: {
            line: 74,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 74,
            column: 22
          },
          end: {
            line: 74,
            column: 56
          }
        }, {
          start: {
            line: 74,
            column: 60
          },
          end: {
            line: 74,
            column: 72
          }
        }],
        line: 74
      },
      '2': {
        loc: {
          start: {
            line: 80,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 80,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }, {
          start: {
            line: 80,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }],
        line: 80
      },
      '3': {
        loc: {
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }, {
          start: {
            line: 113,
            column: 8
          },
          end: {
            line: 115,
            column: 9
          }
        }],
        line: 113
      },
      '4': {
        loc: {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        }, {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 120,
            column: 9
          }
        }],
        line: 118
      },
      '5': {
        loc: {
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 128,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 128,
            column: 9
          }
        }, {
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 128,
            column: 9
          }
        }],
        line: 125
      },
      '6': {
        loc: {
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 161,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 145,
            column: 14
          }
        }, {
          start: {
            line: 146,
            column: 6
          },
          end: {
            line: 148,
            column: 14
          }
        }, {
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 151,
            column: 14
          }
        }, {
          start: {
            line: 152,
            column: 6
          },
          end: {
            line: 154,
            column: 14
          }
        }, {
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 157,
            column: 14
          }
        }, {
          start: {
            line: 158,
            column: 6
          },
          end: {
            line: 160,
            column: 14
          }
        }],
        line: 142
      },
      '7': {
        loc: {
          start: {
            line: 189,
            column: 36
          },
          end: {
            line: 189,
            column: 49
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 189,
            column: 48
          },
          end: {
            line: 189,
            column: 49
          }
        }],
        line: 189
      },
      '8': {
        loc: {
          start: {
            line: 191,
            column: 29
          },
          end: {
            line: 191,
            column: 56
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 191,
            column: 29
          },
          end: {
            line: 191,
            column: 41
          }
        }, {
          start: {
            line: 191,
            column: 45
          },
          end: {
            line: 191,
            column: 56
          }
        }],
        line: 191
      },
      '9': {
        loc: {
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 195,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 195,
            column: 7
          }
        }, {
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 195,
            column: 7
          }
        }],
        line: 193
      },
      '10': {
        loc: {
          start: {
            line: 194,
            column: 26
          },
          end: {
            line: 194,
            column: 95
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 194,
            column: 44
          },
          end: {
            line: 194,
            column: 90
          }
        }, {
          start: {
            line: 194,
            column: 93
          },
          end: {
            line: 194,
            column: 95
          }
        }],
        line: 194
      },
      '11': {
        loc: {
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }, {
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }],
        line: 196
      },
      '12': {
        loc: {
          start: {
            line: 197,
            column: 26
          },
          end: {
            line: 197,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 197,
            column: 44
          },
          end: {
            line: 197,
            column: 78
          }
        }, {
          start: {
            line: 197,
            column: 81
          },
          end: {
            line: 197,
            column: 83
          }
        }],
        line: 197
      },
      '13': {
        loc: {
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 201,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 201,
            column: 7
          }
        }, {
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 201,
            column: 7
          }
        }],
        line: 199
      },
      '14': {
        loc: {
          start: {
            line: 203,
            column: 55
          },
          end: {
            line: 203,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 203,
            column: 55
          },
          end: {
            line: 203,
            column: 70
          }
        }, {
          start: {
            line: 203,
            column: 74
          },
          end: {
            line: 203,
            column: 76
          }
        }],
        line: 203
      },
      '15': {
        loc: {
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 216,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 216,
            column: 9
          }
        }, {
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 216,
            column: 9
          }
        }],
        line: 214
      },
      '16': {
        loc: {
          start: {
            line: 219,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 219,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }, {
          start: {
            line: 219,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }],
        line: 219
      },
      '17': {
        loc: {
          start: {
            line: 227,
            column: 6
          },
          end: {
            line: 359,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 227,
            column: 6
          },
          end: {
            line: 359,
            column: 7
          }
        }, {
          start: {
            line: 227,
            column: 6
          },
          end: {
            line: 359,
            column: 7
          }
        }],
        line: 227
      },
      '18': {
        loc: {
          start: {
            line: 237,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 237,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        }, {
          start: {
            line: 237,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        }],
        line: 237
      },
      '19': {
        loc: {
          start: {
            line: 242,
            column: 12
          },
          end: {
            line: 269,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 242,
            column: 12
          },
          end: {
            line: 269,
            column: 13
          }
        }, {
          start: {
            line: 242,
            column: 12
          },
          end: {
            line: 269,
            column: 13
          }
        }],
        line: 242
      },
      '20': {
        loc: {
          start: {
            line: 243,
            column: 28
          },
          end: {
            line: 243,
            column: 121
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 243,
            column: 66
          },
          end: {
            line: 243,
            column: 101
          }
        }, {
          start: {
            line: 243,
            column: 104
          },
          end: {
            line: 243,
            column: 121
          }
        }],
        line: 243
      },
      '21': {
        loc: {
          start: {
            line: 244,
            column: 14
          },
          end: {
            line: 246,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 244,
            column: 14
          },
          end: {
            line: 246,
            column: 15
          }
        }, {
          start: {
            line: 244,
            column: 14
          },
          end: {
            line: 246,
            column: 15
          }
        }],
        line: 244
      },
      '22': {
        loc: {
          start: {
            line: 247,
            column: 14
          },
          end: {
            line: 249,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 247,
            column: 14
          },
          end: {
            line: 249,
            column: 15
          }
        }, {
          start: {
            line: 247,
            column: 14
          },
          end: {
            line: 249,
            column: 15
          }
        }],
        line: 247
      },
      '23': {
        loc: {
          start: {
            line: 262,
            column: 18
          },
          end: {
            line: 266,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 18
          },
          end: {
            line: 266,
            column: 19
          }
        }, {
          start: {
            line: 262,
            column: 18
          },
          end: {
            line: 266,
            column: 19
          }
        }],
        line: 262
      },
      '24': {
        loc: {
          start: {
            line: 271,
            column: 12
          },
          end: {
            line: 300,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 12
          },
          end: {
            line: 300,
            column: 13
          }
        }, {
          start: {
            line: 271,
            column: 12
          },
          end: {
            line: 300,
            column: 13
          }
        }],
        line: 271
      },
      '25': {
        loc: {
          start: {
            line: 272,
            column: 26
          },
          end: {
            line: 272,
            column: 119
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 272,
            column: 64
          },
          end: {
            line: 272,
            column: 99
          }
        }, {
          start: {
            line: 272,
            column: 102
          },
          end: {
            line: 272,
            column: 119
          }
        }],
        line: 272
      },
      '26': {
        loc: {
          start: {
            line: 273,
            column: 14
          },
          end: {
            line: 275,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 273,
            column: 14
          },
          end: {
            line: 275,
            column: 15
          }
        }, {
          start: {
            line: 273,
            column: 14
          },
          end: {
            line: 275,
            column: 15
          }
        }],
        line: 273
      },
      '27': {
        loc: {
          start: {
            line: 277,
            column: 16
          },
          end: {
            line: 279,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 277,
            column: 16
          },
          end: {
            line: 279,
            column: 17
          }
        }, {
          start: {
            line: 277,
            column: 16
          },
          end: {
            line: 279,
            column: 17
          }
        }],
        line: 277
      },
      '28': {
        loc: {
          start: {
            line: 280,
            column: 16
          },
          end: {
            line: 282,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 16
          },
          end: {
            line: 282,
            column: 17
          }
        }, {
          start: {
            line: 280,
            column: 16
          },
          end: {
            line: 282,
            column: 17
          }
        }],
        line: 280
      },
      '29': {
        loc: {
          start: {
            line: 286,
            column: 16
          },
          end: {
            line: 287,
            column: 97
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 286,
            column: 32
          },
          end: {
            line: 286,
            column: 110
          }
        }, {
          start: {
            line: 287,
            column: 18
          },
          end: {
            line: 287,
            column: 97
          }
        }],
        line: 286
      },
      '30': {
        loc: {
          start: {
            line: 293,
            column: 18
          },
          end: {
            line: 297,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 293,
            column: 18
          },
          end: {
            line: 297,
            column: 19
          }
        }, {
          start: {
            line: 293,
            column: 18
          },
          end: {
            line: 297,
            column: 19
          }
        }],
        line: 293
      },
      '31': {
        loc: {
          start: {
            line: 309,
            column: 17
          },
          end: {
            line: 309,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 309,
            column: 17
          },
          end: {
            line: 309,
            column: 21
          }
        }, {
          start: {
            line: 309,
            column: 25
          },
          end: {
            line: 309,
            column: 47
          }
        }],
        line: 309
      },
      '32': {
        loc: {
          start: {
            line: 325,
            column: 14
          },
          end: {
            line: 327,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 325,
            column: 14
          },
          end: {
            line: 327,
            column: 15
          }
        }, {
          start: {
            line: 325,
            column: 14
          },
          end: {
            line: 327,
            column: 15
          }
        }],
        line: 325
      },
      '33': {
        loc: {
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 330,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 330,
            column: 15
          }
        }, {
          start: {
            line: 328,
            column: 14
          },
          end: {
            line: 330,
            column: 15
          }
        }],
        line: 328
      },
      '34': {
        loc: {
          start: {
            line: 343,
            column: 18
          },
          end: {
            line: 347,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 343,
            column: 18
          },
          end: {
            line: 347,
            column: 19
          }
        }, {
          start: {
            line: 343,
            column: 18
          },
          end: {
            line: 347,
            column: 19
          }
        }],
        line: 343
      },
      '35': {
        loc: {
          start: {
            line: 353,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 353,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        }, {
          start: {
            line: 353,
            column: 10
          },
          end: {
            line: 356,
            column: 11
          }
        }],
        line: 353
      },
      '36': {
        loc: {
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        }, {
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        }],
        line: 362
      },
      '37': {
        loc: {
          start: {
            line: 381,
            column: 6
          },
          end: {
            line: 383,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 381,
            column: 6
          },
          end: {
            line: 383,
            column: 7
          }
        }, {
          start: {
            line: 381,
            column: 6
          },
          end: {
            line: 383,
            column: 7
          }
        }],
        line: 381
      },
      '38': {
        loc: {
          start: {
            line: 398,
            column: 35
          },
          end: {
            line: 398,
            column: 120
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 398,
            column: 54
          },
          end: {
            line: 398,
            column: 115
          }
        }, {
          start: {
            line: 398,
            column: 118
          },
          end: {
            line: 398,
            column: 120
          }
        }],
        line: 398
      },
      '39': {
        loc: {
          start: {
            line: 420,
            column: 4
          },
          end: {
            line: 422,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 420,
            column: 4
          },
          end: {
            line: 422,
            column: 5
          }
        }, {
          start: {
            line: 420,
            column: 4
          },
          end: {
            line: 422,
            column: 5
          }
        }],
        line: 420
      },
      '40': {
        loc: {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 435,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 435,
            column: 5
          }
        }, {
          start: {
            line: 430,
            column: 4
          },
          end: {
            line: 435,
            column: 5
          }
        }],
        line: 430
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0, 0, 0, 0, 0],
      '7': [0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1mukwrcu3r.s[0]++;
var fnDashboardMap = function fnDashboardMap($rootScope, $scope, $deltadate, $translate, $LOGINService, $filter, $CRUDService, $deltahttp) {
  cov_1mukwrcu3r.f[0]++;

  var PATH = (cov_1mukwrcu3r.s[1]++, 'Formular');
  // Get Data
  var p_bksb_ = (cov_1mukwrcu3r.s[2]++, null);
  cov_1mukwrcu3r.s[3]++;
  $scope.syncfusionPivot = {
    params: {
      locale: $LOGINService.getLang(),
      dataSourceSettings: {
        enableSorting: true,
        columns: [],
        valueSortSettings: { headerDelimiter: ' - ' },
        values: [],
        allowLabelFilter: true,
        allowValueFilter: true,
        rows: [],
        formatSettings: [],
        expandAll: false,
        filters: [],
        alwaysShowValueHeader: true
      },
      width: $('#content_' + $scope.idcomponent).width() - 10 + 'px',
      allowExcelExport: true,
      allowConditionalFormatting: true,
      allowNumberFormatting: true,
      allowPdfExport: true,
      showToolbar: true,
      displayOption: { view: 'Both' },
      allowCalculatedField: true,
      showFieldList: true,
      toolbar: ['Grid', 'Chart'],
      allowDrillThrough: true,
      height: $('#content_' + $scope.idcomponent).height() - 30 + 'px',
      showTooltip: true,
      showValuesButton: true,
      // gridSettings: {columnWidth: 140},
      chartSettings: {
        chartSeries: { type: 'Column' },
        load: function load(args) {
          var selectedTheme = (cov_1mukwrcu3r.s[4]++, location.hash.split('/')[1]);
          cov_1mukwrcu3r.s[5]++;
          selectedTheme = selectedTheme ? (cov_1mukwrcu3r.b[0][0]++, selectedTheme) : (cov_1mukwrcu3r.b[0][1]++, 'Material');
          cov_1mukwrcu3r.s[6]++;
          args.chart.theme = selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1);
        }
      },
      toolbarRender: function toolbarRender(toolbars) {
        var size = (cov_1mukwrcu3r.s[7]++, toolbars.customToolbar.length);

        cov_1mukwrcu3r.s[8]++;
        toolbars.customToolbar.splice(size - 1, 0, {
          prefixIcon: 'e-pivotview-pdf-export e-icons', tooltipText: 'PDF',
          click: function click(args) {
            cov_1mukwrcu3r.s[9]++;

            $scope.syncfusionPivot.exportData('pdf');
          },

          align: 'Right'
        });

        cov_1mukwrcu3r.s[10]++;
        toolbars.customToolbar.splice(size - 1, 0, {
          prefixIcon: 'e-pivotview-excel-export  e-icons', tooltipText: 'Excel',
          click: function click(args) {
            cov_1mukwrcu3r.s[11]++;

            $scope.syncfusionPivot.exportData('excel');
          },

          align: 'Right'
        });
      },
      beforeExport: function beforeExport(params) {},
      pdfHeaderQueryCellInfo: function pdfHeaderQueryCellInfo(args) {
        cov_1mukwrcu3r.s[12]++;

        args.cell.row.pdfGrid.repeatHeader = true;
      }
    },

    viewType: 'Table',
    exportData: function exportData(type) {
      var title_ = (cov_1mukwrcu3r.s[13]++, ((cov_1mukwrcu3r.b[1][0]++, $scope.selectedRequest.NAMEREQUEST) || (cov_1mukwrcu3r.b[1][1]++, 'new report')).toUpperCase());
      var A4Size = (cov_1mukwrcu3r.s[14]++, 1450);
      var pageSize = (cov_1mukwrcu3r.s[15]++, 'A3');
      var date = (cov_1mukwrcu3r.s[16]++, '');
      cov_1mukwrcu3r.s[17]++;
      date += new Date().getMonth().toString() + '/' + new Date().getDate().toString();
      cov_1mukwrcu3r.s[18]++;
      date += '/' + new Date().getFullYear().toString();
      cov_1mukwrcu3r.s[19]++;
      if (type.toUpperCase() == 'PDF') {
        cov_1mukwrcu3r.b[2][0]++;

        var pdfExportProperties = (cov_1mukwrcu3r.s[20]++, {
          pageSize: 'A4',
          header: {
            fromTop: 0,
            height: 50,
            contents: [{
              type: 'Text',
              value: title_,
              position: { x: 0, y: 0 },
              style: { fontSize: 11, hAlign: 'Center', vAlign: 'Center' }
            }]
          },
          footer: {
            fromBottom: 0,
            height: 20,
            contents: [{
              type: 'PageNumber',
              pageNumberType: 'Arabic',
              format: '{$current} / {$total}',
              position: { x: 200, y: 0 },
              style: { textBrushColor: '#353535', fontSize: 9, hAlign: 'Center' }
            }]
          },
          pageOrientation: 'Landscape',
          fileName: title_ + '.pdf'
        });
        cov_1mukwrcu3r.s[21]++;
        pdfExportProperties.footer.contents[0].position.x = A4Size - 50;
        cov_1mukwrcu3r.s[22]++;
        pdfExportProperties.header.contents[0].position.x = (700 - 700 * title_.length / 104) / 2;
        cov_1mukwrcu3r.s[23]++;
        if (pdfExportProperties.header.contents[0].position.x < 0) {
          cov_1mukwrcu3r.b[3][0]++;
          cov_1mukwrcu3r.s[24]++;

          pdfExportProperties.header.contents[0].position.x = 0;
        } else {
          cov_1mukwrcu3r.b[3][1]++;
        }
        cov_1mukwrcu3r.s[25]++;
        pdfExportProperties.pageSize = pageSize;
        cov_1mukwrcu3r.s[26]++;
        $scope.pivotTableObj.pdfExport(pdfExportProperties);
        cov_1mukwrcu3r.s[27]++;
        if ($scope.pivotTableObj.chartExport) {
          cov_1mukwrcu3r.b[4][0]++;
          cov_1mukwrcu3r.s[28]++;

          $scope.pivotTableObj.chartExport('PDF', title_ + 'Chart', pdfExportProperties);
        } else {
          cov_1mukwrcu3r.b[4][1]++;
        }
      } else {
        cov_1mukwrcu3r.b[2][1]++;
        cov_1mukwrcu3r.s[29]++;

        $scope.pivotTableObj.excelExport({
          fileName: $scope.selectedRequest.NAMEREQUEST + '.xlsx'
        });
        cov_1mukwrcu3r.s[30]++;
        if ($scope.pivotTableObj.chartExport) {
          cov_1mukwrcu3r.b[5][0]++;
          cov_1mukwrcu3r.s[31]++;

          $scope.pivotTableObj.chart.title = title_;
          cov_1mukwrcu3r.s[32]++;
          $scope.pivotTableObj.chartExport('JPEG', title_ + 'Chart');
        } else {
          cov_1mukwrcu3r.b[5][1]++;
        }
      }
    }
  };

  cov_1mukwrcu3r.s[33]++;
  $scope.requestFields = [];

  var pathMarker = (cov_1mukwrcu3r.s[34]++, $deltahttp.getAnalysePinRepository($scope.idRequest));

  cov_1mukwrcu3r.s[35]++;
  $scope.getMapUrl = function (value) {
    cov_1mukwrcu3r.f[1]++;

    var mapstr = (cov_1mukwrcu3r.s[36]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
    // L.tileLayer(mapstr).addTo(this.layerGroup);
    // let mapstr = '';
    // const value = ($scope.displayCity ? 1 : 0) + ($scope.displaySatelite ? 2 : 0);
    cov_1mukwrcu3r.s[37]++;
    switch (value) {
      case 0:
        cov_1mukwrcu3r.b[6][0]++;
        cov_1mukwrcu3r.s[38]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
        cov_1mukwrcu3r.s[39]++;
        break;
      case 1:
        cov_1mukwrcu3r.b[6][1]++;
        cov_1mukwrcu3r.s[40]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
        cov_1mukwrcu3r.s[41]++;
        break;
      case 2:
        cov_1mukwrcu3r.b[6][2]++;
        cov_1mukwrcu3r.s[42]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
        cov_1mukwrcu3r.s[43]++;
        break;
      case 3:
        cov_1mukwrcu3r.b[6][3]++;
        cov_1mukwrcu3r.s[44]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
        cov_1mukwrcu3r.s[45]++;
        break;
      case 4:
        cov_1mukwrcu3r.b[6][4]++;
        cov_1mukwrcu3r.s[46]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
        cov_1mukwrcu3r.s[47]++;
        break;
      default:
        cov_1mukwrcu3r.b[6][5]++;
        cov_1mukwrcu3r.s[48]++;

        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
        cov_1mukwrcu3r.s[49]++;
        break;
    }
    cov_1mukwrcu3r.s[50]++;
    return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
  };
  /*
  $scope.formatData = function (item, layer) {
    const maxHeight = $('#map_' + $scope.idcomponent).height() - 100;
     let label = angular.copy(layer.format || $scope.mask);
    for (const item_s of $scope.requestFields) {
      if (item_s.t == 3) {
        item[item_s.id] = item[item_s.id] ? $filter('date')(item[item_s.id], 'mediumDate') : '';
      }
      if (item_s.t == 4) {
        item[item_s.id] = item[item_s.id] ? $filter('number')(item[item_s.id]) : '';
      }
       if (item_s.type == 8) {
        item[item_s.id] = `<img style="max-height: 50px; max-width: 100px; width: auto; height: auto" src="${$deltahttp.getDataPath('Formular')}?${$CRUDService.getComplement(2, true)}&get=picture&record=${item.id}&field=${item_s.id}&change=${item[item_s.id] || ''}" />`;//     item[item_s.id] ? $filter('number')(item[item_s.id]) : '';
      }
       label = label.replaceAll('${' + item_s.id + '}', item[item_s.id] || '');
     }
    // return label;
    return `<div class="theme-scrollbar-slim map_modal_info" style="max-height: ${maxHeight}px; overflow-y: auto">${label}</div>`;
  };
   */
  cov_1mukwrcu3r.s[51]++;
  $scope.formatData = function (item, layer) {
    var id_record = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_1mukwrcu3r.b[7][0]++, 0);
    cov_1mukwrcu3r.f[2]++;

    var maxHeight = (cov_1mukwrcu3r.s[52]++, $('#map_' + $scope.idcomponent).height() - 100);
    var label = (cov_1mukwrcu3r.s[53]++, angular.copy((cov_1mukwrcu3r.b[8][0]++, layer.format) || (cov_1mukwrcu3r.b[8][1]++, $scope.mask)));
    cov_1mukwrcu3r.s[54]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.requestFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var item_s = _step.value;
        cov_1mukwrcu3r.s[55]++;

        if (item_s.t == 3) {
          cov_1mukwrcu3r.b[9][0]++;
          cov_1mukwrcu3r.s[56]++;

          item[item_s.id] = item[item_s.id] ? (cov_1mukwrcu3r.b[10][0]++, $filter('date')(item[item_s.id], 'mediumDate')) : (cov_1mukwrcu3r.b[10][1]++, '');
        } else {
          cov_1mukwrcu3r.b[9][1]++;
        }
        cov_1mukwrcu3r.s[57]++;
        if (item_s.t == 4) {
          cov_1mukwrcu3r.b[11][0]++;
          cov_1mukwrcu3r.s[58]++;

          item[item_s.id] = item[item_s.id] ? (cov_1mukwrcu3r.b[12][0]++, $filter('number')(item[item_s.id])) : (cov_1mukwrcu3r.b[12][1]++, '');
        } else {
          cov_1mukwrcu3r.b[11][1]++;
        }
        cov_1mukwrcu3r.s[59]++;
        if (item_s.t == 8) {
          cov_1mukwrcu3r.b[13][0]++;
          cov_1mukwrcu3r.s[60]++;

          item[item_s.id] = '<img style="max-height: 150px; max-width: 150px; width: auto; height: auto" src="' + $deltahttp.getFormRecord(item.id, item_s.id) + '" />'; //     item[item_s.id] ? $filter('number')(item[item_s.id]) : '';
        } else {
          cov_1mukwrcu3r.b[13][1]++;
        }

        cov_1mukwrcu3r.s[61]++;
        label = label.replaceAll('${' + item_s.id + '}', (cov_1mukwrcu3r.b[14][0]++, item[item_s.id]) || (cov_1mukwrcu3r.b[14][1]++, ''));
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1mukwrcu3r.s[62]++;
    return '<div class="theme-scrollbar-slim map_modal_info" style="max-height: ' + maxHeight + 'px; overflow-y: auto">' + label + '</div>';
  };
  cov_1mukwrcu3r.s[63]++;
  $scope.drawLayers = function (data) {
    cov_1mukwrcu3r.f[3]++;

    var count = (cov_1mukwrcu3r.s[64]++, 0);
    var center = (cov_1mukwrcu3r.s[65]++, [0, 0]);
    cov_1mukwrcu3r.s[66]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      var _loop = function _loop() {
        var layer = _step2.value;

        var l = (cov_1mukwrcu3r.s[67]++, null);
        cov_1mukwrcu3r.s[68]++;
        try {
          cov_1mukwrcu3r.s[69]++;

          if (angular.isNumber(layer.id)) {
            cov_1mukwrcu3r.b[15][0]++;
            cov_1mukwrcu3r.s[70]++;

            l = $scope.layerGroup.getLayer(layer.id);
          } else {
            cov_1mukwrcu3r.b[15][1]++;
          }
        } catch (ex) {}

        cov_1mukwrcu3r.s[71]++;
        if (l == null) {
          cov_1mukwrcu3r.b[16][0]++;
          cov_1mukwrcu3r.s[72]++;

          l = L.layerGroup();
          cov_1mukwrcu3r.s[73]++;
          l.addTo($scope.layerGroup);
          cov_1mukwrcu3r.s[74]++;
          layer.id = $scope.layerGroup.getLayerId(l);
        } else {
          cov_1mukwrcu3r.b[16][1]++;
        }
        cov_1mukwrcu3r.s[75]++;
        l.clearLayers();
        // layer.id = l.getLayerId();

        cov_1mukwrcu3r.s[76]++;
        if (layer.type != 2) {
          cov_1mukwrcu3r.b[17][0]++;

          var markers = (cov_1mukwrcu3r.s[77]++, L.markerClusterGroup({
            maxClusterRadius: 40,
            iconCreateFunction: function iconCreateFunction(cluster) {
              cov_1mukwrcu3r.s[78]++;

              return L.divIcon({
                className: 'delta-div-icon',
                html: '<div class="info" style="color: ' + $rootScope.dynamiqueTextColor(layer.color) + '; background-color: rgb(' + layer.color + ')">' + cluster.getChildCount() + '</div>'
              });
            }
          }));
          cov_1mukwrcu3r.s[79]++;
          if (layer.type == 11) {
            cov_1mukwrcu3r.b[18][0]++;
            cov_1mukwrcu3r.s[80]++;

            markers.addTo(l);
          } else {
            cov_1mukwrcu3r.b[18][1]++;
          }
          cov_1mukwrcu3r.s[81]++;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            var _loop2 = function _loop2() {
              var item = _step3.value;
              cov_1mukwrcu3r.s[82]++;

              try {
                cov_1mukwrcu3r.s[83]++;

                if (layer.type == 11) {
                  cov_1mukwrcu3r.b[19][0]++;

                  var latlg = (cov_1mukwrcu3r.s[84]++, angular.isString(item[layer.field]) ? (cov_1mukwrcu3r.b[20][0]++, angular.fromJson(item[layer.field])) : (cov_1mukwrcu3r.b[20][1]++, item[layer.field]));
                  cov_1mukwrcu3r.s[85]++;
                  if (!angular.isArray(latlg)) {
                    cov_1mukwrcu3r.b[21][0]++;
                    cov_1mukwrcu3r.s[86]++;

                    return 'continue';
                  } else {
                    cov_1mukwrcu3r.b[21][1]++;
                  }
                  cov_1mukwrcu3r.s[87]++;
                  if (latlg.length < 2) {
                    cov_1mukwrcu3r.b[22][0]++;
                    cov_1mukwrcu3r.s[88]++;

                    return 'continue';
                  } else {
                    cov_1mukwrcu3r.b[22][1]++;
                  }
                  cov_1mukwrcu3r.s[89]++;
                  center[0] += latlg[0];
                  cov_1mukwrcu3r.s[90]++;
                  center[1] += latlg[1];
                  cov_1mukwrcu3r.s[91]++;
                  count++;
                  cov_1mukwrcu3r.s[92]++;
                  L.marker(latlg, { icon: L.icon({
                      iconUrl: layer.url,
                      iconSize: [24, 41], // size of the icon
                      shadowSize: [32, 32] // size of the shadow
                    }) }).addTo(markers).on('click', function (a, b, c) {
                    cov_1mukwrcu3r.f[4]++;

                    var popup = (cov_1mukwrcu3r.s[93]++, L.popup().setLatLng(a.latlng).setContent('<div class="spinner-example" ng-if="isloading"> <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div>').openOn($scope.mymap));
                    cov_1mukwrcu3r.s[94]++;
                    $CRUDService.getAll(PATH, { get: 'single_record', withData: 1, id: $scope.idForm, p_bksb_: p_bksb_, id_r: item.id }, function (records) {
                      cov_1mukwrcu3r.f[5]++;
                      cov_1mukwrcu3r.s[95]++;

                      if (records.length > 0) {
                        cov_1mukwrcu3r.b[23][0]++;
                        cov_1mukwrcu3r.s[96]++;

                        popup.setContent($scope.formatData(records[0], layer));
                      } else {
                        cov_1mukwrcu3r.b[23][1]++;
                        cov_1mukwrcu3r.s[97]++;

                        popup.setContent('<div></div>');
                      }
                    });
                  });
                } else {
                  cov_1mukwrcu3r.b[19][1]++;
                }

                cov_1mukwrcu3r.s[98]++;
                if (layer.type == 13) {
                  cov_1mukwrcu3r.b[24][0]++;

                  var _latlg = (cov_1mukwrcu3r.s[99]++, angular.isString(item[layer.field]) ? (cov_1mukwrcu3r.b[25][0]++, angular.fromJson(item[layer.field])) : (cov_1mukwrcu3r.b[25][1]++, item[layer.field]));
                  cov_1mukwrcu3r.s[100]++;
                  if (!angular.isArray(_latlg)) {
                    cov_1mukwrcu3r.b[26][0]++;
                    cov_1mukwrcu3r.s[101]++;

                    return 'continue';
                  } else {
                    cov_1mukwrcu3r.b[26][1]++;
                  }
                  cov_1mukwrcu3r.s[102]++;
                  _latlg = _latlg.filter(function (lat) {
                    cov_1mukwrcu3r.f[6]++;
                    cov_1mukwrcu3r.s[103]++;

                    if (!angular.isArray(lat)) {
                      cov_1mukwrcu3r.b[27][0]++;
                      cov_1mukwrcu3r.s[104]++;

                      return false;
                    } else {
                      cov_1mukwrcu3r.b[27][1]++;
                    }
                    cov_1mukwrcu3r.s[105]++;
                    if (lat.length < 2) {
                      cov_1mukwrcu3r.b[28][0]++;
                      cov_1mukwrcu3r.s[106]++;

                      return false;
                    } else {
                      cov_1mukwrcu3r.b[28][1]++;
                    }
                    cov_1mukwrcu3r.s[107]++;
                    return true;
                  });
                  cov_1mukwrcu3r.s[108]++;
                  (layer.polygon ? (cov_1mukwrcu3r.b[29][0]++, L.polygon(_latlg, { color: ('rgb(' + layer.color + ')').replaceAll(',', ' , ') })) : (cov_1mukwrcu3r.b[29][1]++, L.polyline(_latlg, { color: ('rgb(' + layer.color + ')').replaceAll(',', ' , ') }))).addTo(l).on('click', function (a, b, c) {
                    cov_1mukwrcu3r.f[7]++;

                    var popup = (cov_1mukwrcu3r.s[109]++, L.popup().setLatLng(a.latlng).setContent('<div class="spinner-example" ng-if="isloading"> <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div>').openOn($scope.mymap));
                    cov_1mukwrcu3r.s[110]++;
                    $CRUDService.getAll(PATH, { get: 'single_record', withData: 1, id: $scope.idForm, p_bksb_: p_bksb_, id_r: item.id }, function (records) {
                      cov_1mukwrcu3r.f[8]++;
                      cov_1mukwrcu3r.s[111]++;

                      if (records.length > 0) {
                        cov_1mukwrcu3r.b[30][0]++;
                        cov_1mukwrcu3r.s[112]++;

                        popup.setContent($scope.formatData(records[0], layer));
                      } else {
                        cov_1mukwrcu3r.b[30][1]++;
                        cov_1mukwrcu3r.s[113]++;

                        popup.setContent('<div></div>');
                      }
                    });
                  });
                } else {
                  cov_1mukwrcu3r.b[24][1]++;
                }
              } catch (ex) {}
            };

            for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var _ret2 = _loop2();

              if (_ret2 === 'continue') continue;
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        } else {
          cov_1mukwrcu3r.b[17][1]++;

          var ids = (cov_1mukwrcu3r.s[114]++, data.map(function (item) {
            cov_1mukwrcu3r.f[9]++;
            cov_1mukwrcu3r.s[115]++;

            return parseInt(item[layer.field]);
          }).filter(function (item) {
            cov_1mukwrcu3r.f[10]++;
            cov_1mukwrcu3r.s[116]++;

            return (cov_1mukwrcu3r.b[31][0]++, item) && (cov_1mukwrcu3r.b[31][1]++, angular.isNumber(item));
          }));
          cov_1mukwrcu3r.s[117]++;
          $CRUDService.getAll('Localites', { get: 'uid_arc', ids: angular.toJson(ids) }, function (data_) {
            cov_1mukwrcu3r.f[11]++;

            var center_ = (cov_1mukwrcu3r.s[118]++, [0, 0]);
            var markers = (cov_1mukwrcu3r.s[119]++, L.markerClusterGroup({
              maxClusterRadius: 40,
              iconCreateFunction: function iconCreateFunction(cluster) {
                cov_1mukwrcu3r.s[120]++;

                return L.divIcon({
                  className: 'delta-div-icon',
                  html: '<div class="info" style="color: ' + $rootScope.dynamiqueTextColor(layer.color) + '; background-color: rgb(' + layer.color + ')">' + cluster.getChildCount() + '</div>'
                });
              }
            }).addTo(l));
            cov_1mukwrcu3r.s[121]++;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              var _loop3 = function _loop3() {
                var item = _step4.value;
                cov_1mukwrcu3r.s[122]++;

                try {
                  var latlg = (cov_1mukwrcu3r.s[123]++, data_[item[layer.field]]);
                  cov_1mukwrcu3r.s[124]++;
                  if (!angular.isArray(latlg)) {
                    cov_1mukwrcu3r.b[32][0]++;
                    cov_1mukwrcu3r.s[125]++;

                    return 'continue';
                  } else {
                    cov_1mukwrcu3r.b[32][1]++;
                  }
                  cov_1mukwrcu3r.s[126]++;
                  if (latlg.length < 2) {
                    cov_1mukwrcu3r.b[33][0]++;
                    cov_1mukwrcu3r.s[127]++;

                    return 'continue';
                  } else {
                    cov_1mukwrcu3r.b[33][1]++;
                  }
                  cov_1mukwrcu3r.s[128]++;
                  center[0] += parseFloat(latlg[0]);
                  cov_1mukwrcu3r.s[129]++;
                  center[1] += parseFloat(latlg[1]);
                  cov_1mukwrcu3r.s[130]++;
                  count++;
                  cov_1mukwrcu3r.s[131]++;
                  L.marker(latlg, { icon: L.icon({
                      iconUrl: layer.url,
                      iconSize: [24, 41], // size of the icon
                      shadowSize: [32, 32] // size of the shadow
                    }) }).addTo(markers).on('click', function (a, b, c) {
                    cov_1mukwrcu3r.f[12]++;

                    var popup = (cov_1mukwrcu3r.s[132]++, L.popup().setLatLng(a.latlng).setContent('<div class="spinner-example" ng-if="isloading"> <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div>').openOn($scope.mymap));
                    cov_1mukwrcu3r.s[133]++;
                    $CRUDService.getAll(PATH, { get: 'single_record', withData: 1, id: $scope.idForm, p_bksb_: p_bksb_, id_r: item.id }, function (records) {
                      cov_1mukwrcu3r.f[13]++;
                      cov_1mukwrcu3r.s[134]++;

                      if (records.length > 0) {
                        cov_1mukwrcu3r.b[34][0]++;
                        cov_1mukwrcu3r.s[135]++;

                        popup.setContent($scope.formatData(records[0], layer));
                      } else {
                        cov_1mukwrcu3r.b[34][1]++;
                        cov_1mukwrcu3r.s[136]++;

                        popup.setContent('<div></div>');
                      }
                    });
                  });
                } catch (ex) {}
              };

              for (var _iterator4 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var _ret3 = _loop3();

                if (_ret3 === 'continue') continue;
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }

            cov_1mukwrcu3r.s[137]++;
            if (count > 1) {
              cov_1mukwrcu3r.b[35][0]++;
              cov_1mukwrcu3r.s[138]++;

              center[0] /= count;
              cov_1mukwrcu3r.s[139]++;
              center[1] /= count;
            } else {
              cov_1mukwrcu3r.b[35][1]++;
            }
            cov_1mukwrcu3r.s[140]++;
            $scope.mymap.setView(center);
          });
        }
      };

      for (var _iterator2 = $scope.selectedRequest.MAP[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        _loop();
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_1mukwrcu3r.s[141]++;
    if (count > 1) {
      cov_1mukwrcu3r.b[36][0]++;
      cov_1mukwrcu3r.s[142]++;

      center[0] /= count;
      cov_1mukwrcu3r.s[143]++;
      center[1] /= count;
    } else {
      cov_1mukwrcu3r.b[36][1]++;
    }
    cov_1mukwrcu3r.s[144]++;
    $scope.mymap.setView(center);
  };

  cov_1mukwrcu3r.s[145]++;
  $scope.getFilterFields = function (id) {
    cov_1mukwrcu3r.f[14]++;
    cov_1mukwrcu3r.s[146]++;

    $CRUDService.getAll(PATH, { get: 'all_filter_fields', id: $scope.selectedRequest.ID, p_bksb_: p_bksb_, isMap: true }, function (data) {
      cov_1mukwrcu3r.f[15]++;
      cov_1mukwrcu3r.s[147]++;

      $scope.requestFields = data;
    });
  };

  cov_1mukwrcu3r.s[148]++;
  $scope.getAll = function (id) {
    cov_1mukwrcu3r.f[16]++;
    cov_1mukwrcu3r.s[149]++;

    $scope.getFilterFields(id);
    cov_1mukwrcu3r.s[150]++;
    $scope.listRecord = [];
    var params = (cov_1mukwrcu3r.s[151]++, { export: 1, withData: 1, withMap: true, page: 0, id: id, p_bksb_: p_bksb_, id_request: $scope.idRequest, paging: 1, get: 'all_r_simple_map', sort: $filter('json')([]) });
    cov_1mukwrcu3r.s[152]++;
    $CRUDService.getAll(PATH, params, function (data) {
      cov_1mukwrcu3r.f[17]++;
      cov_1mukwrcu3r.s[153]++;

      $scope.listRecord = $scope.listRecord.concat(data.data);
      cov_1mukwrcu3r.s[154]++;
      if ($scope.mask == null) {
        cov_1mukwrcu3r.b[37][0]++;
        cov_1mukwrcu3r.s[155]++;

        $scope.mask = data.mask;
      } else {
        cov_1mukwrcu3r.b[37][1]++;
      }
      cov_1mukwrcu3r.s[156]++;
      $scope.drawLayers(data.data);
    });

    cov_1mukwrcu3r.s[157]++;
    $scope.drawLegend();
  };

  cov_1mukwrcu3r.s[158]++;
  $scope.drawLegend = function () {
    cov_1mukwrcu3r.f[18]++;

    var legendControl = (cov_1mukwrcu3r.s[159]++, L.control({ position: 'bottomright' }));

    cov_1mukwrcu3r.s[160]++;
    legendControl.onAdd = function (map) {
      cov_1mukwrcu3r.f[19]++;

      var div = (cov_1mukwrcu3r.s[161]++, L.DomUtil.create('div', 'info legend p-xs'));
      cov_1mukwrcu3r.s[162]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.selectedRequest.MAP[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _layer = _step5.value;
          cov_1mukwrcu3r.s[163]++;

          div.innerHTML += '<div class="display-flex p-xs" style="align-items: center; gap: 10px;">\n                                  <div style="background: rgb(' + _layer.color + '); width: 10px; height: 10px; border-radius: 50%"></div>' + (_layer.type != 13 ? (cov_1mukwrcu3r.b[38][0]++, '<img style="width: 16px; height: 100%" src="' + _layer.url + '"/>') : (cov_1mukwrcu3r.b[38][1]++, '')) + ('<small>' + _layer.name + '</small>\n                          </div>');
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1mukwrcu3r.s[164]++;
      return div;
    };
    cov_1mukwrcu3r.s[165]++;
    legendControl.addTo($scope.mymap);
  };

  cov_1mukwrcu3r.s[166]++;
  $scope.listRecord = [];
  cov_1mukwrcu3r.s[167]++;
  $scope.mask = null;

  /* for (const item of $scope.selectedTemplate.STRUCTURE) {
    $scope.mask += '<p><b>' + item.dl + ': </b> ${' + item.id + '} </p>';
    if (item.type == 11) {
      $scope.selectedQuery.MAP[0].field = item.id;
      // break;
    }
  } */
  cov_1mukwrcu3r.s[168]++;
  $CRUDService.getAll(PATH, { get: 'one_request_by_id', id: $scope.idRequest }, function (data) {
    cov_1mukwrcu3r.f[20]++;
    cov_1mukwrcu3r.s[169]++;

    $scope.selectedRequest = data;
    cov_1mukwrcu3r.s[170]++;
    p_bksb_ = $scope.selectedRequest.ID_PROJET;
    cov_1mukwrcu3r.s[171]++;
    if (data.OPTIONS != '[]') {
      cov_1mukwrcu3r.b[39][0]++;
      cov_1mukwrcu3r.s[172]++;

      $scope.mask = data.OPTIONS;
    } else {
      cov_1mukwrcu3r.b[39][1]++;
    }
    var grayscale = (cov_1mukwrcu3r.s[173]++, L.tileLayer($scope.getMapUrl(4)));
    cov_1mukwrcu3r.s[174]++;
    $scope.mymap = L.map('map_' + $scope.idcomponent, { fullscreenControl: {
        title: {
          false: $translate.instant('COMMON.FULLSCREEN'),
          true: $translate.instant('COMMON.EXIT_FULLSCREEN')
        }
      }, layers: [] }).setView([16.82, 11.3], 5);
    cov_1mukwrcu3r.s[175]++;
    if (!$scope.layerGroup) {
      cov_1mukwrcu3r.b[40][0]++;
      cov_1mukwrcu3r.s[176]++;

      $scope.layerGroup = L.layerGroup([], {
        attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
      });
      cov_1mukwrcu3r.s[177]++;
      $scope.layerGroup.addTo($scope.mymap);
    } else {
      cov_1mukwrcu3r.b[40][1]++;
    }
    cov_1mukwrcu3r.s[178]++;
    $rootScope.getTileLayers($scope.mymap);
    cov_1mukwrcu3r.s[179]++;
    $scope.layerGroup.clearLayers();
    cov_1mukwrcu3r.s[180]++;
    $scope.getAll($scope.selectedRequest.ID);
  });
};
cov_1mukwrcu3r.s[181]++;
angular.module('app').directive('dashboardMap', function () {
  cov_1mukwrcu3r.f[21]++;
  cov_1mukwrcu3r.s[182]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      idForm: '=',
      idRequest: '='
    },

    templateUrl: 'app/views/common/dashboard-map.html',
    controller: fnDashboardMap
  };
});
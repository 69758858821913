'use strict';

var cov_1n0ltttluz = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-table-activity-item.js',
      hash = '626fed8ec1f8b13ad1ea9c33eece26bf28bd24f2',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-table-activity-item.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 31
        },
        end: {
          line: 878,
          column: 1
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 63
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 40
        }
      },
      '3': {
        start: {
          line: 5,
          column: 16
        },
        end: {
          line: 5,
          column: 31
        }
      },
      '4': {
        start: {
          line: 6,
          column: 16
        },
        end: {
          line: 6,
          column: 31
        }
      },
      '5': {
        start: {
          line: 7,
          column: 18
        },
        end: {
          line: 7,
          column: 27
        }
      },
      '6': {
        start: {
          line: 8,
          column: 15
        },
        end: {
          line: 8,
          column: 30
        }
      },
      '7': {
        start: {
          line: 9,
          column: 18
        },
        end: {
          line: 9,
          column: 32
        }
      },
      '8': {
        start: {
          line: 10,
          column: 25
        },
        end: {
          line: 10,
          column: 45
        }
      },
      '9': {
        start: {
          line: 17,
          column: 33
        },
        end: {
          line: 77,
          column: 3
        }
      },
      '10': {
        start: {
          line: 18,
          column: 24
        },
        end: {
          line: 18,
          column: 50
        }
      },
      '11': {
        start: {
          line: 19,
          column: 12
        },
        end: {
          line: 23,
          column: 5
        }
      },
      '12': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '13': {
        start: {
          line: 26,
          column: 19
        },
        end: {
          line: 26,
          column: 235
        }
      },
      '14': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '15': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 35,
          column: 10
        }
      },
      '16': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 27
        }
      },
      '17': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 56,
          column: 5
        }
      },
      '18': {
        start: {
          line: 41,
          column: 19
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '19': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 54,
          column: 7
        }
      },
      '20': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 53,
          column: 13
        }
      },
      '21': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 27
        }
      },
      '22': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '23': {
        start: {
          line: 59,
          column: 19
        },
        end: {
          line: 59,
          column: 234
        }
      },
      '24': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 68,
          column: 7
        }
      },
      '25': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 67,
          column: 13
        }
      },
      '26': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 27
        }
      },
      '27': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 191
        }
      },
      '28': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 74,
          column: 191
        }
      },
      '29': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 13
        }
      },
      '30': {
        start: {
          line: 78,
          column: 29
        },
        end: {
          line: 148,
          column: 3
        }
      },
      '31': {
        start: {
          line: 79,
          column: 17
        },
        end: {
          line: 79,
          column: 19
        }
      },
      '32': {
        start: {
          line: 80,
          column: 21
        },
        end: {
          line: 80,
          column: 22
        }
      },
      '33': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 145,
          column: 5
        }
      },
      '34': {
        start: {
          line: 82,
          column: 19
        },
        end: {
          line: 82,
          column: 61
        }
      },
      '35': {
        start: {
          line: 83,
          column: 23
        },
        end: {
          line: 83,
          column: 27
        }
      },
      '36': {
        start: {
          line: 84,
          column: 23
        },
        end: {
          line: 84,
          column: 27
        }
      },
      '37': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '38': {
        start: {
          line: 87,
          column: 20
        },
        end: {
          line: 87,
          column: 39
        }
      },
      '39': {
        start: {
          line: 88,
          column: 25
        },
        end: {
          line: 88,
          column: 39
        }
      },
      '40': {
        start: {
          line: 89,
          column: 21
        },
        end: {
          line: 91,
          column: 16
        }
      },
      '41': {
        start: {
          line: 93,
          column: 23
        },
        end: {
          line: 95,
          column: 16
        }
      },
      '42': {
        start: {
          line: 97,
          column: 21
        },
        end: {
          line: 97,
          column: 89
        }
      },
      '43': {
        start: {
          line: 99,
          column: 21
        },
        end: {
          line: 99,
          column: 88
        }
      },
      '44': {
        start: {
          line: 101,
          column: 29
        },
        end: {
          line: 101,
          column: 83
        }
      },
      '45': {
        start: {
          line: 102,
          column: 30
        },
        end: {
          line: 102,
          column: 88
        }
      },
      '46': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 108,
          column: 11
        }
      },
      '47': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 35
        }
      },
      '48': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 39
        }
      },
      '49': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 35
        }
      },
      '50': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 35
        }
      },
      '51': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 121,
          column: 9
        }
      },
      '52': {
        start: {
          line: 115,
          column: 10
        },
        end: {
          line: 120,
          column: 11
        }
      },
      '53': {
        start: {
          line: 116,
          column: 12
        },
        end: {
          line: 116,
          column: 43
        }
      },
      '54': {
        start: {
          line: 117,
          column: 12
        },
        end: {
          line: 117,
          column: 64
        }
      },
      '55': {
        start: {
          line: 119,
          column: 12
        },
        end: {
          line: 119,
          column: 50
        }
      },
      '56': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 125,
          column: 9
        }
      },
      '57': {
        start: {
          line: 124,
          column: 10
        },
        end: {
          line: 124,
          column: 48
        }
      },
      '58': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 128,
          column: 31
        }
      },
      '59': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 129,
          column: 31
        }
      },
      '60': {
        start: {
          line: 131,
          column: 19
        },
        end: {
          line: 133,
          column: 7
        }
      },
      '61': {
        start: {
          line: 135,
          column: 21
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '62': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 138,
          column: 25
        }
      },
      '63': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 29
        }
      },
      '64': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 119
        }
      },
      '65': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 123
        }
      },
      '66': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 144,
          column: 22
        }
      },
      '67': {
        start: {
          line: 147,
          column: 4
        },
        end: {
          line: 147,
          column: 16
        }
      },
      '68': {
        start: {
          line: 149,
          column: 25
        },
        end: {
          line: 201,
          column: 3
        }
      },
      '69': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 200,
          column: 7
        }
      },
      '70': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 31
        }
      },
      '71': {
        start: {
          line: 155,
          column: 20
        },
        end: {
          line: 155,
          column: 118
        }
      },
      '72': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 158,
          column: 7
        }
      },
      '73': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 157,
          column: 45
        }
      },
      '74': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 199,
          column: 9
        }
      },
      '75': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 198,
          column: 9
        }
      },
      '76': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 56
        }
      },
      '77': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 62
        }
      },
      '78': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 180,
          column: 13
        }
      },
      '79': {
        start: {
          line: 166,
          column: 26
        },
        end: {
          line: 166,
          column: 59
        }
      },
      '80': {
        start: {
          line: 167,
          column: 25
        },
        end: {
          line: 167,
          column: 34
        }
      },
      '81': {
        start: {
          line: 168,
          column: 26
        },
        end: {
          line: 168,
          column: 53
        }
      },
      '82': {
        start: {
          line: 169,
          column: 27
        },
        end: {
          line: 169,
          column: 55
        }
      },
      '83': {
        start: {
          line: 170,
          column: 25
        },
        end: {
          line: 170,
          column: 64
        }
      },
      '84': {
        start: {
          line: 171,
          column: 24
        },
        end: {
          line: 171,
          column: 65
        }
      },
      '85': {
        start: {
          line: 172,
          column: 12
        },
        end: {
          line: 172,
          column: 137
        }
      },
      '86': {
        start: {
          line: 174,
          column: 12
        },
        end: {
          line: 174,
          column: 70
        }
      },
      '87': {
        start: {
          line: 175,
          column: 12
        },
        end: {
          line: 175,
          column: 70
        }
      },
      '88': {
        start: {
          line: 176,
          column: 12
        },
        end: {
          line: 176,
          column: 144
        }
      },
      '89': {
        start: {
          line: 177,
          column: 12
        },
        end: {
          line: 177,
          column: 145
        }
      },
      '90': {
        start: {
          line: 179,
          column: 12
        },
        end: {
          line: 179,
          column: 37
        }
      },
      '91': {
        start: {
          line: 181,
          column: 10
        },
        end: {
          line: 181,
          column: 41
        }
      },
      '92': {
        start: {
          line: 183,
          column: 25
        },
        end: {
          line: 183,
          column: 27
        }
      },
      '93': {
        start: {
          line: 184,
          column: 10
        },
        end: {
          line: 190,
          column: 11
        }
      },
      '94': {
        start: {
          line: 185,
          column: 12
        },
        end: {
          line: 189,
          column: 15
        }
      },
      '95': {
        start: {
          line: 192,
          column: 10
        },
        end: {
          line: 192,
          column: 64
        }
      },
      '96': {
        start: {
          line: 193,
          column: 10
        },
        end: {
          line: 193,
          column: 64
        }
      },
      '97': {
        start: {
          line: 194,
          column: 10
        },
        end: {
          line: 194,
          column: 45
        }
      },
      '98': {
        start: {
          line: 195,
          column: 10
        },
        end: {
          line: 195,
          column: 46
        }
      },
      '99': {
        start: {
          line: 197,
          column: 10
        },
        end: {
          line: 197,
          column: 42
        }
      },
      '100': {
        start: {
          line: 205,
          column: 33
        },
        end: {
          line: 262,
          column: 3
        }
      },
      '101': {
        start: {
          line: 206,
          column: 26
        },
        end: {
          line: 206,
          column: 106
        }
      },
      '102': {
        start: {
          line: 207,
          column: 24
        },
        end: {
          line: 207,
          column: 50
        }
      },
      '103': {
        start: {
          line: 208,
          column: 12
        },
        end: {
          line: 212,
          column: 5
        }
      },
      '104': {
        start: {
          line: 214,
          column: 17
        },
        end: {
          line: 214,
          column: 242
        }
      },
      '105': {
        start: {
          line: 215,
          column: 4
        },
        end: {
          line: 224,
          column: 5
        }
      },
      '106': {
        start: {
          line: 216,
          column: 6
        },
        end: {
          line: 223,
          column: 8
        }
      },
      '107': {
        start: {
          line: 225,
          column: 4
        },
        end: {
          line: 225,
          column: 25
        }
      },
      '108': {
        start: {
          line: 227,
          column: 4
        },
        end: {
          line: 243,
          column: 5
        }
      },
      '109': {
        start: {
          line: 228,
          column: 19
        },
        end: {
          line: 230,
          column: 7
        }
      },
      '110': {
        start: {
          line: 232,
          column: 6
        },
        end: {
          line: 241,
          column: 7
        }
      },
      '111': {
        start: {
          line: 233,
          column: 8
        },
        end: {
          line: 240,
          column: 13
        }
      },
      '112': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 242,
          column: 27
        }
      },
      '113': {
        start: {
          line: 245,
          column: 4
        },
        end: {
          line: 245,
          column: 238
        }
      },
      '114': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 254,
          column: 5
        }
      },
      '115': {
        start: {
          line: 247,
          column: 6
        },
        end: {
          line: 253,
          column: 11
        }
      },
      '116': {
        start: {
          line: 255,
          column: 4
        },
        end: {
          line: 255,
          column: 25
        }
      },
      '117': {
        start: {
          line: 257,
          column: 4
        },
        end: {
          line: 257,
          column: 191
        }
      },
      '118': {
        start: {
          line: 259,
          column: 4
        },
        end: {
          line: 259,
          column: 191
        }
      },
      '119': {
        start: {
          line: 261,
          column: 4
        },
        end: {
          line: 261,
          column: 13
        }
      },
      '120': {
        start: {
          line: 263,
          column: 29
        },
        end: {
          line: 336,
          column: 3
        }
      },
      '121': {
        start: {
          line: 264,
          column: 17
        },
        end: {
          line: 264,
          column: 19
        }
      },
      '122': {
        start: {
          line: 265,
          column: 21
        },
        end: {
          line: 265,
          column: 22
        }
      },
      '123': {
        start: {
          line: 266,
          column: 4
        },
        end: {
          line: 333,
          column: 5
        }
      },
      '124': {
        start: {
          line: 267,
          column: 19
        },
        end: {
          line: 267,
          column: 61
        }
      },
      '125': {
        start: {
          line: 268,
          column: 23
        },
        end: {
          line: 268,
          column: 27
        }
      },
      '126': {
        start: {
          line: 269,
          column: 23
        },
        end: {
          line: 269,
          column: 27
        }
      },
      '127': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 314,
          column: 7
        }
      },
      '128': {
        start: {
          line: 271,
          column: 20
        },
        end: {
          line: 271,
          column: 32
        }
      },
      '129': {
        start: {
          line: 272,
          column: 25
        },
        end: {
          line: 272,
          column: 39
        }
      },
      '130': {
        start: {
          line: 273,
          column: 8
        },
        end: {
          line: 275,
          column: 9
        }
      },
      '131': {
        start: {
          line: 274,
          column: 10
        },
        end: {
          line: 274,
          column: 19
        }
      },
      '132': {
        start: {
          line: 277,
          column: 21
        },
        end: {
          line: 279,
          column: 16
        }
      },
      '133': {
        start: {
          line: 281,
          column: 23
        },
        end: {
          line: 283,
          column: 16
        }
      },
      '134': {
        start: {
          line: 285,
          column: 21
        },
        end: {
          line: 285,
          column: 88
        }
      },
      '135': {
        start: {
          line: 287,
          column: 21
        },
        end: {
          line: 287,
          column: 87
        }
      },
      '136': {
        start: {
          line: 289,
          column: 29
        },
        end: {
          line: 289,
          column: 133
        }
      },
      '137': {
        start: {
          line: 290,
          column: 30
        },
        end: {
          line: 290,
          column: 140
        }
      },
      '138': {
        start: {
          line: 292,
          column: 8
        },
        end: {
          line: 296,
          column: 11
        }
      },
      '139': {
        start: {
          line: 297,
          column: 8
        },
        end: {
          line: 297,
          column: 35
        }
      },
      '140': {
        start: {
          line: 298,
          column: 8
        },
        end: {
          line: 298,
          column: 39
        }
      },
      '141': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 299,
          column: 35
        }
      },
      '142': {
        start: {
          line: 300,
          column: 8
        },
        end: {
          line: 300,
          column: 35
        }
      },
      '143': {
        start: {
          line: 302,
          column: 8
        },
        end: {
          line: 309,
          column: 9
        }
      },
      '144': {
        start: {
          line: 303,
          column: 10
        },
        end: {
          line: 308,
          column: 11
        }
      },
      '145': {
        start: {
          line: 304,
          column: 12
        },
        end: {
          line: 304,
          column: 43
        }
      },
      '146': {
        start: {
          line: 305,
          column: 12
        },
        end: {
          line: 305,
          column: 64
        }
      },
      '147': {
        start: {
          line: 307,
          column: 12
        },
        end: {
          line: 307,
          column: 42
        }
      },
      '148': {
        start: {
          line: 311,
          column: 8
        },
        end: {
          line: 313,
          column: 9
        }
      },
      '149': {
        start: {
          line: 312,
          column: 10
        },
        end: {
          line: 312,
          column: 48
        }
      },
      '150': {
        start: {
          line: 316,
          column: 6
        },
        end: {
          line: 316,
          column: 32
        }
      },
      '151': {
        start: {
          line: 317,
          column: 6
        },
        end: {
          line: 317,
          column: 32
        }
      },
      '152': {
        start: {
          line: 319,
          column: 19
        },
        end: {
          line: 321,
          column: 14
        }
      },
      '153': {
        start: {
          line: 323,
          column: 21
        },
        end: {
          line: 325,
          column: 14
        }
      },
      '154': {
        start: {
          line: 326,
          column: 6
        },
        end: {
          line: 326,
          column: 26
        }
      },
      '155': {
        start: {
          line: 327,
          column: 6
        },
        end: {
          line: 327,
          column: 30
        }
      },
      '156': {
        start: {
          line: 329,
          column: 6
        },
        end: {
          line: 329,
          column: 119
        }
      },
      '157': {
        start: {
          line: 330,
          column: 6
        },
        end: {
          line: 330,
          column: 123
        }
      },
      '158': {
        start: {
          line: 332,
          column: 6
        },
        end: {
          line: 332,
          column: 22
        }
      },
      '159': {
        start: {
          line: 335,
          column: 4
        },
        end: {
          line: 335,
          column: 16
        }
      },
      '160': {
        start: {
          line: 337,
          column: 25
        },
        end: {
          line: 397,
          column: 3
        }
      },
      '161': {
        start: {
          line: 338,
          column: 4
        },
        end: {
          line: 396,
          column: 7
        }
      },
      '162': {
        start: {
          line: 342,
          column: 6
        },
        end: {
          line: 342,
          column: 31
        }
      },
      '163': {
        start: {
          line: 343,
          column: 6
        },
        end: {
          line: 349,
          column: 9
        }
      },
      '164': {
        start: {
          line: 344,
          column: 8
        },
        end: {
          line: 346,
          column: 9
        }
      },
      '165': {
        start: {
          line: 345,
          column: 10
        },
        end: {
          line: 345,
          column: 64
        }
      },
      '166': {
        start: {
          line: 348,
          column: 8
        },
        end: {
          line: 348,
          column: 48
        }
      },
      '167': {
        start: {
          line: 351,
          column: 20
        },
        end: {
          line: 351,
          column: 121
        }
      },
      '168': {
        start: {
          line: 352,
          column: 6
        },
        end: {
          line: 354,
          column: 7
        }
      },
      '169': {
        start: {
          line: 353,
          column: 8
        },
        end: {
          line: 353,
          column: 45
        }
      },
      '170': {
        start: {
          line: 356,
          column: 6
        },
        end: {
          line: 395,
          column: 9
        }
      },
      '171': {
        start: {
          line: 360,
          column: 8
        },
        end: {
          line: 394,
          column: 9
        }
      },
      '172': {
        start: {
          line: 361,
          column: 10
        },
        end: {
          line: 361,
          column: 56
        }
      },
      '173': {
        start: {
          line: 362,
          column: 10
        },
        end: {
          line: 362,
          column: 62
        }
      },
      '174': {
        start: {
          line: 363,
          column: 10
        },
        end: {
          line: 376,
          column: 13
        }
      },
      '175': {
        start: {
          line: 364,
          column: 26
        },
        end: {
          line: 364,
          column: 59
        }
      },
      '176': {
        start: {
          line: 365,
          column: 25
        },
        end: {
          line: 365,
          column: 34
        }
      },
      '177': {
        start: {
          line: 366,
          column: 26
        },
        end: {
          line: 366,
          column: 53
        }
      },
      '178': {
        start: {
          line: 367,
          column: 27
        },
        end: {
          line: 367,
          column: 55
        }
      },
      '179': {
        start: {
          line: 368,
          column: 25
        },
        end: {
          line: 368,
          column: 64
        }
      },
      '180': {
        start: {
          line: 369,
          column: 24
        },
        end: {
          line: 369,
          column: 65
        }
      },
      '181': {
        start: {
          line: 370,
          column: 12
        },
        end: {
          line: 370,
          column: 137
        }
      },
      '182': {
        start: {
          line: 371,
          column: 12
        },
        end: {
          line: 371,
          column: 70
        }
      },
      '183': {
        start: {
          line: 372,
          column: 12
        },
        end: {
          line: 372,
          column: 70
        }
      },
      '184': {
        start: {
          line: 373,
          column: 12
        },
        end: {
          line: 373,
          column: 144
        }
      },
      '185': {
        start: {
          line: 374,
          column: 12
        },
        end: {
          line: 374,
          column: 145
        }
      },
      '186': {
        start: {
          line: 375,
          column: 12
        },
        end: {
          line: 375,
          column: 37
        }
      },
      '187': {
        start: {
          line: 377,
          column: 10
        },
        end: {
          line: 377,
          column: 41
        }
      },
      '188': {
        start: {
          line: 379,
          column: 25
        },
        end: {
          line: 379,
          column: 27
        }
      },
      '189': {
        start: {
          line: 380,
          column: 10
        },
        end: {
          line: 386,
          column: 11
        }
      },
      '190': {
        start: {
          line: 381,
          column: 12
        },
        end: {
          line: 385,
          column: 15
        }
      },
      '191': {
        start: {
          line: 388,
          column: 10
        },
        end: {
          line: 388,
          column: 64
        }
      },
      '192': {
        start: {
          line: 389,
          column: 10
        },
        end: {
          line: 389,
          column: 64
        }
      },
      '193': {
        start: {
          line: 390,
          column: 10
        },
        end: {
          line: 390,
          column: 45
        }
      },
      '194': {
        start: {
          line: 391,
          column: 10
        },
        end: {
          line: 391,
          column: 46
        }
      },
      '195': {
        start: {
          line: 393,
          column: 10
        },
        end: {
          line: 393,
          column: 42
        }
      },
      '196': {
        start: {
          line: 400,
          column: 33
        },
        end: {
          line: 434,
          column: 3
        }
      },
      '197': {
        start: {
          line: 401,
          column: 24
        },
        end: {
          line: 401,
          column: 50
        }
      },
      '198': {
        start: {
          line: 402,
          column: 14
        },
        end: {
          line: 413,
          column: 5
        }
      },
      '199': {
        start: {
          line: 415,
          column: 4
        },
        end: {
          line: 426,
          column: 5
        }
      },
      '200': {
        start: {
          line: 416,
          column: 6
        },
        end: {
          line: 419,
          column: 8
        }
      },
      '201': {
        start: {
          line: 420,
          column: 6
        },
        end: {
          line: 422,
          column: 8
        }
      },
      '202': {
        start: {
          line: 423,
          column: 6
        },
        end: {
          line: 425,
          column: 8
        }
      },
      '203': {
        start: {
          line: 428,
          column: 4
        },
        end: {
          line: 432,
          column: 5
        }
      },
      '204': {
        start: {
          line: 429,
          column: 6
        },
        end: {
          line: 431,
          column: 9
        }
      },
      '205': {
        start: {
          line: 433,
          column: 4
        },
        end: {
          line: 433,
          column: 13
        }
      },
      '206': {
        start: {
          line: 435,
          column: 29
        },
        end: {
          line: 523,
          column: 3
        }
      },
      '207': {
        start: {
          line: 436,
          column: 17
        },
        end: {
          line: 436,
          column: 19
        }
      },
      '208': {
        start: {
          line: 437,
          column: 21
        },
        end: {
          line: 437,
          column: 22
        }
      },
      '209': {
        start: {
          line: 439,
          column: 4
        },
        end: {
          line: 521,
          column: 5
        }
      },
      '210': {
        start: {
          line: 440,
          column: 19
        },
        end: {
          line: 440,
          column: 111
        }
      },
      '211': {
        start: {
          line: 441,
          column: 23
        },
        end: {
          line: 441,
          column: 27
        }
      },
      '212': {
        start: {
          line: 442,
          column: 23
        },
        end: {
          line: 442,
          column: 27
        }
      },
      '213': {
        start: {
          line: 444,
          column: 6
        },
        end: {
          line: 518,
          column: 7
        }
      },
      '214': {
        start: {
          line: 445,
          column: 20
        },
        end: {
          line: 445,
          column: 32
        }
      },
      '215': {
        start: {
          line: 446,
          column: 8
        },
        end: {
          line: 448,
          column: 9
        }
      },
      '216': {
        start: {
          line: 447,
          column: 10
        },
        end: {
          line: 447,
          column: 19
        }
      },
      '217': {
        start: {
          line: 450,
          column: 8
        },
        end: {
          line: 452,
          column: 9
        }
      },
      '218': {
        start: {
          line: 451,
          column: 10
        },
        end: {
          line: 451,
          column: 16
        }
      },
      '219': {
        start: {
          line: 454,
          column: 25
        },
        end: {
          line: 454,
          column: 39
        }
      },
      '220': {
        start: {
          line: 455,
          column: 21
        },
        end: {
          line: 457,
          column: 16
        }
      },
      '221': {
        start: {
          line: 459,
          column: 23
        },
        end: {
          line: 461,
          column: 16
        }
      },
      '222': {
        start: {
          line: 463,
          column: 8
        },
        end: {
          line: 463,
          column: 102
        }
      },
      '223': {
        start: {
          line: 464,
          column: 8
        },
        end: {
          line: 464,
          column: 102
        }
      },
      '224': {
        start: {
          line: 465,
          column: 8
        },
        end: {
          line: 465,
          column: 43
        }
      },
      '225': {
        start: {
          line: 466,
          column: 8
        },
        end: {
          line: 466,
          column: 43
        }
      },
      '226': {
        start: {
          line: 468,
          column: 8
        },
        end: {
          line: 470,
          column: 9
        }
      },
      '227': {
        start: {
          line: 469,
          column: 10
        },
        end: {
          line: 469,
          column: 42
        }
      },
      '228': {
        start: {
          line: 472,
          column: 8
        },
        end: {
          line: 474,
          column: 9
        }
      },
      '229': {
        start: {
          line: 473,
          column: 10
        },
        end: {
          line: 473,
          column: 42
        }
      },
      '230': {
        start: {
          line: 476,
          column: 8
        },
        end: {
          line: 478,
          column: 9
        }
      },
      '231': {
        start: {
          line: 477,
          column: 10
        },
        end: {
          line: 477,
          column: 42
        }
      },
      '232': {
        start: {
          line: 480,
          column: 8
        },
        end: {
          line: 482,
          column: 9
        }
      },
      '233': {
        start: {
          line: 481,
          column: 10
        },
        end: {
          line: 481,
          column: 42
        }
      },
      '234': {
        start: {
          line: 484,
          column: 21
        },
        end: {
          line: 484,
          column: 88
        }
      },
      '235': {
        start: {
          line: 486,
          column: 21
        },
        end: {
          line: 486,
          column: 87
        }
      },
      '236': {
        start: {
          line: 488,
          column: 29
        },
        end: {
          line: 488,
          column: 133
        }
      },
      '237': {
        start: {
          line: 489,
          column: 30
        },
        end: {
          line: 489,
          column: 140
        }
      },
      '238': {
        start: {
          line: 491,
          column: 8
        },
        end: {
          line: 495,
          column: 11
        }
      },
      '239': {
        start: {
          line: 496,
          column: 8
        },
        end: {
          line: 496,
          column: 25
        }
      },
      '240': {
        start: {
          line: 497,
          column: 8
        },
        end: {
          line: 497,
          column: 29
        }
      },
      '241': {
        start: {
          line: 498,
          column: 8
        },
        end: {
          line: 498,
          column: 25
        }
      },
      '242': {
        start: {
          line: 499,
          column: 8
        },
        end: {
          line: 499,
          column: 25
        }
      },
      '243': {
        start: {
          line: 501,
          column: 8
        },
        end: {
          line: 508,
          column: 9
        }
      },
      '244': {
        start: {
          line: 502,
          column: 10
        },
        end: {
          line: 507,
          column: 11
        }
      },
      '245': {
        start: {
          line: 503,
          column: 12
        },
        end: {
          line: 503,
          column: 43
        }
      },
      '246': {
        start: {
          line: 504,
          column: 12
        },
        end: {
          line: 504,
          column: 64
        }
      },
      '247': {
        start: {
          line: 506,
          column: 12
        },
        end: {
          line: 506,
          column: 50
        }
      },
      '248': {
        start: {
          line: 510,
          column: 8
        },
        end: {
          line: 512,
          column: 9
        }
      },
      '249': {
        start: {
          line: 511,
          column: 10
        },
        end: {
          line: 511,
          column: 48
        }
      },
      '250': {
        start: {
          line: 514,
          column: 8
        },
        end: {
          line: 514,
          column: 109
        }
      },
      '251': {
        start: {
          line: 515,
          column: 8
        },
        end: {
          line: 515,
          column: 113
        }
      },
      '252': {
        start: {
          line: 516,
          column: 8
        },
        end: {
          line: 516,
          column: 68
        }
      },
      '253': {
        start: {
          line: 517,
          column: 8
        },
        end: {
          line: 517,
          column: 14
        }
      },
      '254': {
        start: {
          line: 520,
          column: 6
        },
        end: {
          line: 520,
          column: 22
        }
      },
      '255': {
        start: {
          line: 522,
          column: 4
        },
        end: {
          line: 522,
          column: 16
        }
      },
      '256': {
        start: {
          line: 524,
          column: 25
        },
        end: {
          line: 564,
          column: 3
        }
      },
      '257': {
        start: {
          line: 525,
          column: 4
        },
        end: {
          line: 563,
          column: 7
        }
      },
      '258': {
        start: {
          line: 529,
          column: 6
        },
        end: {
          line: 529,
          column: 31
        }
      },
      '259': {
        start: {
          line: 530,
          column: 6
        },
        end: {
          line: 532,
          column: 9
        }
      },
      '260': {
        start: {
          line: 531,
          column: 8
        },
        end: {
          line: 531,
          column: 48
        }
      },
      '261': {
        start: {
          line: 533,
          column: 20
        },
        end: {
          line: 533,
          column: 108
        }
      },
      '262': {
        start: {
          line: 534,
          column: 6
        },
        end: {
          line: 536,
          column: 7
        }
      },
      '263': {
        start: {
          line: 535,
          column: 8
        },
        end: {
          line: 535,
          column: 45
        }
      },
      '264': {
        start: {
          line: 537,
          column: 6
        },
        end: {
          line: 562,
          column: 9
        }
      },
      '265': {
        start: {
          line: 541,
          column: 8
        },
        end: {
          line: 561,
          column: 9
        }
      },
      '266': {
        start: {
          line: 542,
          column: 10
        },
        end: {
          line: 542,
          column: 56
        }
      },
      '267': {
        start: {
          line: 543,
          column: 10
        },
        end: {
          line: 543,
          column: 62
        }
      },
      '268': {
        start: {
          line: 544,
          column: 10
        },
        end: {
          line: 544,
          column: 41
        }
      },
      '269': {
        start: {
          line: 545,
          column: 10
        },
        end: {
          line: 545,
          column: 49
        }
      },
      '270': {
        start: {
          line: 547,
          column: 25
        },
        end: {
          line: 547,
          column: 27
        }
      },
      '271': {
        start: {
          line: 548,
          column: 10
        },
        end: {
          line: 554,
          column: 11
        }
      },
      '272': {
        start: {
          line: 549,
          column: 12
        },
        end: {
          line: 553,
          column: 15
        }
      },
      '273': {
        start: {
          line: 555,
          column: 10
        },
        end: {
          line: 555,
          column: 64
        }
      },
      '274': {
        start: {
          line: 556,
          column: 10
        },
        end: {
          line: 556,
          column: 64
        }
      },
      '275': {
        start: {
          line: 557,
          column: 10
        },
        end: {
          line: 557,
          column: 45
        }
      },
      '276': {
        start: {
          line: 558,
          column: 10
        },
        end: {
          line: 558,
          column: 46
        }
      },
      '277': {
        start: {
          line: 560,
          column: 10
        },
        end: {
          line: 560,
          column: 42
        }
      },
      '278': {
        start: {
          line: 566,
          column: 2
        },
        end: {
          line: 632,
          column: 4
        }
      },
      '279': {
        start: {
          line: 630,
          column: 6
        },
        end: {
          line: 630,
          column: 32
        }
      },
      '280': {
        start: {
          line: 634,
          column: 2
        },
        end: {
          line: 774,
          column: 3
        }
      },
      '281': {
        start: {
          line: 635,
          column: 4
        },
        end: {
          line: 773,
          column: 5
        }
      },
      '282': {
        start: {
          line: 636,
          column: 6
        },
        end: {
          line: 709,
          column: 9
        }
      },
      '283': {
        start: {
          line: 640,
          column: 22
        },
        end: {
          line: 640,
          column: 24
        }
      },
      '284': {
        start: {
          line: 642,
          column: 8
        },
        end: {
          line: 648,
          column: 9
        }
      },
      '285': {
        start: {
          line: 643,
          column: 10
        },
        end: {
          line: 647,
          column: 13
        }
      },
      '286': {
        start: {
          line: 650,
          column: 8
        },
        end: {
          line: 706,
          column: 10
        }
      },
      '287': {
        start: {
          line: 679,
          column: 22
        },
        end: {
          line: 679,
          column: 42
        }
      },
      '288': {
        start: {
          line: 680,
          column: 12
        },
        end: {
          line: 680,
          column: 36
        }
      },
      '289': {
        start: {
          line: 708,
          column: 8
        },
        end: {
          line: 708,
          column: 40
        }
      },
      '290': {
        start: {
          line: 711,
          column: 6
        },
        end: {
          line: 772,
          column: 9
        }
      },
      '291': {
        start: {
          line: 715,
          column: 22
        },
        end: {
          line: 715,
          column: 24
        }
      },
      '292': {
        start: {
          line: 717,
          column: 8
        },
        end: {
          line: 743,
          column: 9
        }
      },
      '293': {
        start: {
          line: 718,
          column: 22
        },
        end: {
          line: 718,
          column: 24
        }
      },
      '294': {
        start: {
          line: 719,
          column: 10
        },
        end: {
          line: 734,
          column: 11
        }
      },
      '295': {
        start: {
          line: 721,
          column: 14
        },
        end: {
          line: 721,
          column: 63
        }
      },
      '296': {
        start: {
          line: 722,
          column: 14
        },
        end: {
          line: 722,
          column: 20
        }
      },
      '297': {
        start: {
          line: 724,
          column: 14
        },
        end: {
          line: 724,
          column: 74
        }
      },
      '298': {
        start: {
          line: 725,
          column: 14
        },
        end: {
          line: 725,
          column: 20
        }
      },
      '299': {
        start: {
          line: 727,
          column: 14
        },
        end: {
          line: 727,
          column: 80
        }
      },
      '300': {
        start: {
          line: 728,
          column: 14
        },
        end: {
          line: 728,
          column: 20
        }
      },
      '301': {
        start: {
          line: 730,
          column: 14
        },
        end: {
          line: 730,
          column: 73
        }
      },
      '302': {
        start: {
          line: 731,
          column: 14
        },
        end: {
          line: 731,
          column: 20
        }
      },
      '303': {
        start: {
          line: 733,
          column: 14
        },
        end: {
          line: 733,
          column: 25
        }
      },
      '304': {
        start: {
          line: 736,
          column: 22
        },
        end: {
          line: 736,
          column: 23
        }
      },
      '305': {
        start: {
          line: 738,
          column: 10
        },
        end: {
          line: 740,
          column: 11
        }
      },
      '306': {
        start: {
          line: 739,
          column: 12
        },
        end: {
          line: 739,
          column: 50
        }
      },
      '307': {
        start: {
          line: 742,
          column: 10
        },
        end: {
          line: 742,
          column: 43
        }
      },
      '308': {
        start: {
          line: 745,
          column: 8
        },
        end: {
          line: 769,
          column: 10
        }
      },
      '309': {
        start: {
          line: 754,
          column: 12
        },
        end: {
          line: 756,
          column: 13
        }
      },
      '310': {
        start: {
          line: 755,
          column: 14
        },
        end: {
          line: 755,
          column: 60
        }
      },
      '311': {
        start: {
          line: 757,
          column: 12
        },
        end: {
          line: 757,
          column: 37
        }
      },
      '312': {
        start: {
          line: 759,
          column: 12
        },
        end: {
          line: 759,
          column: 34
        }
      },
      '313': {
        start: {
          line: 760,
          column: 12
        },
        end: {
          line: 760,
          column: 49
        }
      },
      '314': {
        start: {
          line: 761,
          column: 12
        },
        end: {
          line: 761,
          column: 42
        }
      },
      '315': {
        start: {
          line: 771,
          column: 8
        },
        end: {
          line: 771,
          column: 40
        }
      },
      '316': {
        start: {
          line: 775,
          column: 2
        },
        end: {
          line: 877,
          column: 3
        }
      },
      '317': {
        start: {
          line: 776,
          column: 4
        },
        end: {
          line: 874,
          column: 7
        }
      },
      '318': {
        start: {
          line: 780,
          column: 6
        },
        end: {
          line: 795,
          column: 7
        }
      },
      '319': {
        start: {
          line: 781,
          column: 26
        },
        end: {
          line: 781,
          column: 27
        }
      },
      '320': {
        start: {
          line: 782,
          column: 29
        },
        end: {
          line: 782,
          column: 76
        }
      },
      '321': {
        start: {
          line: 783,
          column: 8
        },
        end: {
          line: 787,
          column: 9
        }
      },
      '322': {
        start: {
          line: 784,
          column: 10
        },
        end: {
          line: 784,
          column: 72
        }
      },
      '323': {
        start: {
          line: 785,
          column: 10
        },
        end: {
          line: 785,
          column: 70
        }
      },
      '324': {
        start: {
          line: 786,
          column: 10
        },
        end: {
          line: 786,
          column: 107
        }
      },
      '325': {
        start: {
          line: 789,
          column: 8
        },
        end: {
          line: 794,
          column: 9
        }
      },
      '326': {
        start: {
          line: 790,
          column: 10
        },
        end: {
          line: 792,
          column: 11
        }
      },
      '327': {
        start: {
          line: 791,
          column: 12
        },
        end: {
          line: 791,
          column: 18
        }
      },
      '328': {
        start: {
          line: 793,
          column: 10
        },
        end: {
          line: 793,
          column: 35
        }
      },
      '329': {
        start: {
          line: 797,
          column: 6
        },
        end: {
          line: 869,
          column: 8
        }
      },
      '330': {
        start: {
          line: 853,
          column: 14
        },
        end: {
          line: 853,
          column: 38
        }
      },
      '331': {
        start: {
          line: 858,
          column: 10
        },
        end: {
          line: 860,
          column: 11
        }
      },
      '332': {
        start: {
          line: 859,
          column: 12
        },
        end: {
          line: 859,
          column: 58
        }
      },
      '333': {
        start: {
          line: 861,
          column: 10
        },
        end: {
          line: 861,
          column: 64
        }
      },
      '334': {
        start: {
          line: 871,
          column: 6
        },
        end: {
          line: 871,
          column: 38
        }
      },
      '335': {
        start: {
          line: 876,
          column: 19
        },
        end: {
          line: 876,
          column: 126
        }
      },
      '336': {
        start: {
          line: 880,
          column: 0
        },
        end: {
          line: 893,
          column: 3
        }
      },
      '337': {
        start: {
          line: 881,
          column: 2
        },
        end: {
          line: 892,
          column: 4
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 31
          },
          end: {
            line: 2,
            column: 32
          }
        },
        loc: {
          start: {
            line: 2,
            column: 143
          },
          end: {
            line: 878,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 17,
            column: 33
          },
          end: {
            line: 17,
            column: 34
          }
        },
        loc: {
          start: {
            line: 17,
            column: 39
          },
          end: {
            line: 77,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 78,
            column: 29
          },
          end: {
            line: 78,
            column: 30
          }
        },
        loc: {
          start: {
            line: 78,
            column: 38
          },
          end: {
            line: 148,
            column: 3
          }
        },
        line: 78
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 149,
            column: 25
          },
          end: {
            line: 149,
            column: 26
          }
        },
        loc: {
          start: {
            line: 149,
            column: 31
          },
          end: {
            line: 201,
            column: 3
          }
        },
        line: 149
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 150,
            column: 90
          },
          end: {
            line: 150,
            column: 91
          }
        },
        loc: {
          start: {
            line: 150,
            column: 98
          },
          end: {
            line: 200,
            column: 5
          }
        },
        line: 150
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 159,
            column: 40
          },
          end: {
            line: 159,
            column: 41
          }
        },
        loc: {
          start: {
            line: 159,
            column: 51
          },
          end: {
            line: 199,
            column: 7
          }
        },
        line: 159
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 165,
            column: 37
          },
          end: {
            line: 165,
            column: 38
          }
        },
        loc: {
          start: {
            line: 165,
            column: 45
          },
          end: {
            line: 180,
            column: 11
          }
        },
        line: 165
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 205,
            column: 33
          },
          end: {
            line: 205,
            column: 34
          }
        },
        loc: {
          start: {
            line: 205,
            column: 39
          },
          end: {
            line: 262,
            column: 3
          }
        },
        line: 205
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 263,
            column: 29
          },
          end: {
            line: 263,
            column: 30
          }
        },
        loc: {
          start: {
            line: 263,
            column: 38
          },
          end: {
            line: 336,
            column: 3
          }
        },
        line: 263
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 337,
            column: 25
          },
          end: {
            line: 337,
            column: 26
          }
        },
        loc: {
          start: {
            line: 337,
            column: 31
          },
          end: {
            line: 397,
            column: 3
          }
        },
        line: 337
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 338,
            column: 90
          },
          end: {
            line: 338,
            column: 91
          }
        },
        loc: {
          start: {
            line: 338,
            column: 98
          },
          end: {
            line: 396,
            column: 5
          }
        },
        line: 338
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 343,
            column: 41
          },
          end: {
            line: 343,
            column: 42
          }
        },
        loc: {
          start: {
            line: 343,
            column: 51
          },
          end: {
            line: 349,
            column: 7
          }
        },
        line: 343
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 356,
            column: 40
          },
          end: {
            line: 356,
            column: 41
          }
        },
        loc: {
          start: {
            line: 356,
            column: 51
          },
          end: {
            line: 395,
            column: 7
          }
        },
        line: 356
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 363,
            column: 37
          },
          end: {
            line: 363,
            column: 38
          }
        },
        loc: {
          start: {
            line: 363,
            column: 45
          },
          end: {
            line: 376,
            column: 11
          }
        },
        line: 363
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 400,
            column: 33
          },
          end: {
            line: 400,
            column: 34
          }
        },
        loc: {
          start: {
            line: 400,
            column: 39
          },
          end: {
            line: 434,
            column: 3
          }
        },
        line: 400
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 435,
            column: 29
          },
          end: {
            line: 435,
            column: 30
          }
        },
        loc: {
          start: {
            line: 435,
            column: 38
          },
          end: {
            line: 523,
            column: 3
          }
        },
        line: 435
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 524,
            column: 25
          },
          end: {
            line: 524,
            column: 26
          }
        },
        loc: {
          start: {
            line: 524,
            column: 31
          },
          end: {
            line: 564,
            column: 3
          }
        },
        line: 524
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 525,
            column: 90
          },
          end: {
            line: 525,
            column: 91
          }
        },
        loc: {
          start: {
            line: 525,
            column: 98
          },
          end: {
            line: 563,
            column: 5
          }
        },
        line: 525
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 530,
            column: 41
          },
          end: {
            line: 530,
            column: 42
          }
        },
        loc: {
          start: {
            line: 530,
            column: 51
          },
          end: {
            line: 532,
            column: 7
          }
        },
        line: 530
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 537,
            column: 40
          },
          end: {
            line: 537,
            column: 41
          }
        },
        loc: {
          start: {
            line: 537,
            column: 51
          },
          end: {
            line: 562,
            column: 7
          }
        },
        line: 537
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 545,
            column: 37
          },
          end: {
            line: 545,
            column: 38
          }
        },
        loc: {
          start: {
            line: 545,
            column: 45
          },
          end: {
            line: 545,
            column: 47
          }
        },
        line: 545
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 639,
            column: 9
          },
          end: {
            line: 639,
            column: 10
          }
        },
        loc: {
          start: {
            line: 639,
            column: 17
          },
          end: {
            line: 709,
            column: 7
          }
        },
        line: 639
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 714,
            column: 9
          },
          end: {
            line: 714,
            column: 10
          }
        },
        loc: {
          start: {
            line: 714,
            column: 17
          },
          end: {
            line: 772,
            column: 7
          }
        },
        line: 714
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 779,
            column: 7
          },
          end: {
            line: 779,
            column: 8
          }
        },
        loc: {
          start: {
            line: 779,
            column: 15
          },
          end: {
            line: 874,
            column: 5
          }
        },
        line: 779
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 852,
            column: 54
          },
          end: {
            line: 852,
            column: 55
          }
        },
        loc: {
          start: {
            line: 852,
            column: 63
          },
          end: {
            line: 854,
            column: 13
          }
        },
        line: 852
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 880,
            column: 62
          },
          end: {
            line: 880,
            column: 63
          }
        },
        loc: {
          start: {
            line: 880,
            column: 68
          },
          end: {
            line: 893,
            column: 1
          }
        },
        line: 880
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 10
          },
          end: {
            line: 21,
            column: 82
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 21,
            column: 37
          },
          end: {
            line: 21,
            column: 55
          }
        }, {
          start: {
            line: 21,
            column: 58
          },
          end: {
            line: 21,
            column: 82
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 25,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }, {
          start: {
            line: 25,
            column: 4
          },
          end: {
            line: 38,
            column: 5
          }
        }],
        line: 25
      },
      '2': {
        loc: {
          start: {
            line: 25,
            column: 8
          },
          end: {
            line: 25,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 25,
            column: 8
          },
          end: {
            line: 25,
            column: 32
          }
        }, {
          start: {
            line: 25,
            column: 36
          },
          end: {
            line: 25,
            column: 40
          }
        }],
        line: 25
      },
      '3': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 27
      },
      '4': {
        loc: {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 54,
            column: 7
          }
        }],
        line: 45
      },
      '5': {
        loc: {
          start: {
            line: 58,
            column: 4
          },
          end: {
            line: 70,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 4
          },
          end: {
            line: 70,
            column: 5
          }
        }, {
          start: {
            line: 58,
            column: 4
          },
          end: {
            line: 70,
            column: 5
          }
        }],
        line: 58
      },
      '6': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 58,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 58,
            column: 32
          }
        }, {
          start: {
            line: 58,
            column: 36
          },
          end: {
            line: 58,
            column: 40
          }
        }],
        line: 58
      },
      '7': {
        loc: {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        }, {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 68,
            column: 7
          }
        }],
        line: 60
      },
      '8': {
        loc: {
          start: {
            line: 89,
            column: 21
          },
          end: {
            line: 91,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 89,
            column: 32
          },
          end: {
            line: 91,
            column: 9
          }
        }, {
          start: {
            line: 91,
            column: 12
          },
          end: {
            line: 91,
            column: 16
          }
        }],
        line: 89
      },
      '9': {
        loc: {
          start: {
            line: 90,
            column: 10
          },
          end: {
            line: 90,
            column: 92
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 90,
            column: 41
          },
          end: {
            line: 90,
            column: 67
          }
        }, {
          start: {
            line: 90,
            column: 70
          },
          end: {
            line: 90,
            column: 92
          }
        }],
        line: 90
      },
      '10': {
        loc: {
          start: {
            line: 93,
            column: 23
          },
          end: {
            line: 95,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 93,
            column: 34
          },
          end: {
            line: 95,
            column: 9
          }
        }, {
          start: {
            line: 95,
            column: 12
          },
          end: {
            line: 95,
            column: 16
          }
        }],
        line: 93
      },
      '11': {
        loc: {
          start: {
            line: 94,
            column: 10
          },
          end: {
            line: 94,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 94,
            column: 35
          },
          end: {
            line: 94,
            column: 62
          }
        }, {
          start: {
            line: 94,
            column: 65
          },
          end: {
            line: 94,
            column: 88
          }
        }],
        line: 94
      },
      '12': {
        loc: {
          start: {
            line: 97,
            column: 21
          },
          end: {
            line: 97,
            column: 89
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 97,
            column: 32
          },
          end: {
            line: 97,
            column: 82
          }
        }, {
          start: {
            line: 97,
            column: 85
          },
          end: {
            line: 97,
            column: 89
          }
        }],
        line: 97
      },
      '13': {
        loc: {
          start: {
            line: 99,
            column: 21
          },
          end: {
            line: 99,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 99,
            column: 32
          },
          end: {
            line: 99,
            column: 81
          }
        }, {
          start: {
            line: 99,
            column: 84
          },
          end: {
            line: 99,
            column: 88
          }
        }],
        line: 99
      },
      '14': {
        loc: {
          start: {
            line: 101,
            column: 29
          },
          end: {
            line: 101,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 101,
            column: 63
          },
          end: {
            line: 101,
            column: 67
          }
        }, {
          start: {
            line: 101,
            column: 71
          },
          end: {
            line: 101,
            column: 82
          }
        }],
        line: 101
      },
      '15': {
        loc: {
          start: {
            line: 101,
            column: 30
          },
          end: {
            line: 101,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 101,
            column: 30
          },
          end: {
            line: 101,
            column: 42
          }
        }, {
          start: {
            line: 101,
            column: 46
          },
          end: {
            line: 101,
            column: 59
          }
        }],
        line: 101
      },
      '16': {
        loc: {
          start: {
            line: 102,
            column: 30
          },
          end: {
            line: 102,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 102,
            column: 66
          },
          end: {
            line: 102,
            column: 70
          }
        }, {
          start: {
            line: 102,
            column: 74
          },
          end: {
            line: 102,
            column: 87
          }
        }],
        line: 102
      },
      '17': {
        loc: {
          start: {
            line: 102,
            column: 31
          },
          end: {
            line: 102,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 102,
            column: 31
          },
          end: {
            line: 102,
            column: 45
          }
        }, {
          start: {
            line: 102,
            column: 49
          },
          end: {
            line: 102,
            column: 62
          }
        }],
        line: 102
      },
      '18': {
        loc: {
          start: {
            line: 114,
            column: 8
          },
          end: {
            line: 121,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 8
          },
          end: {
            line: 121,
            column: 9
          }
        }, {
          start: {
            line: 114,
            column: 8
          },
          end: {
            line: 121,
            column: 9
          }
        }],
        line: 114
      },
      '19': {
        loc: {
          start: {
            line: 115,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 115,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        }, {
          start: {
            line: 115,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        }],
        line: 115
      },
      '20': {
        loc: {
          start: {
            line: 116,
            column: 26
          },
          end: {
            line: 116,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 116,
            column: 26
          },
          end: {
            line: 116,
            column: 36
          }
        }, {
          start: {
            line: 116,
            column: 40
          },
          end: {
            line: 116,
            column: 41
          }
        }],
        line: 116
      },
      '21': {
        loc: {
          start: {
            line: 117,
            column: 25
          },
          end: {
            line: 117,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 117,
            column: 46
          },
          end: {
            line: 117,
            column: 50
          }
        }, {
          start: {
            line: 117,
            column: 53
          },
          end: {
            line: 117,
            column: 63
          }
        }],
        line: 117
      },
      '22': {
        loc: {
          start: {
            line: 119,
            column: 26
          },
          end: {
            line: 119,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 119,
            column: 26
          },
          end: {
            line: 119,
            column: 36
          }
        }, {
          start: {
            line: 119,
            column: 40
          },
          end: {
            line: 119,
            column: 41
          }
        }],
        line: 119
      },
      '23': {
        loc: {
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 125,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 125,
            column: 9
          }
        }, {
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 125,
            column: 9
          }
        }],
        line: 123
      },
      '24': {
        loc: {
          start: {
            line: 124,
            column: 24
          },
          end: {
            line: 124,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 124,
            column: 24
          },
          end: {
            line: 124,
            column: 34
          }
        }, {
          start: {
            line: 124,
            column: 38
          },
          end: {
            line: 124,
            column: 39
          }
        }],
        line: 124
      },
      '25': {
        loc: {
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 132,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 132,
            column: 38
          },
          end: {
            line: 132,
            column: 65
          }
        }, {
          start: {
            line: 132,
            column: 68
          },
          end: {
            line: 132,
            column: 91
          }
        }],
        line: 132
      },
      '26': {
        loc: {
          start: {
            line: 136,
            column: 8
          },
          end: {
            line: 136,
            column: 92
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 136,
            column: 37
          },
          end: {
            line: 136,
            column: 65
          }
        }, {
          start: {
            line: 136,
            column: 68
          },
          end: {
            line: 136,
            column: 92
          }
        }],
        line: 136
      },
      '27': {
        loc: {
          start: {
            line: 141,
            column: 20
          },
          end: {
            line: 141,
            column: 118
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 141,
            column: 58
          },
          end: {
            line: 141,
            column: 62
          }
        }, {
          start: {
            line: 141,
            column: 65
          },
          end: {
            line: 141,
            column: 118
          }
        }],
        line: 141
      },
      '28': {
        loc: {
          start: {
            line: 141,
            column: 20
          },
          end: {
            line: 141,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 141,
            column: 20
          },
          end: {
            line: 141,
            column: 39
          }
        }, {
          start: {
            line: 141,
            column: 43
          },
          end: {
            line: 141,
            column: 55
          }
        }],
        line: 141
      },
      '29': {
        loc: {
          start: {
            line: 142,
            column: 20
          },
          end: {
            line: 142,
            column: 122
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 142,
            column: 60
          },
          end: {
            line: 142,
            column: 64
          }
        }, {
          start: {
            line: 142,
            column: 67
          },
          end: {
            line: 142,
            column: 122
          }
        }],
        line: 142
      },
      '30': {
        loc: {
          start: {
            line: 142,
            column: 20
          },
          end: {
            line: 142,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 142,
            column: 20
          },
          end: {
            line: 142,
            column: 39
          }
        }, {
          start: {
            line: 142,
            column: 43
          },
          end: {
            line: 142,
            column: 57
          }
        }],
        line: 142
      },
      '31': {
        loc: {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 158,
            column: 7
          }
        }],
        line: 156
      },
      '32': {
        loc: {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        }, {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 198,
            column: 9
          }
        }],
        line: 162
      },
      '33': {
        loc: {
          start: {
            line: 210,
            column: 10
          },
          end: {
            line: 210,
            column: 82
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 210,
            column: 37
          },
          end: {
            line: 210,
            column: 55
          }
        }, {
          start: {
            line: 210,
            column: 58
          },
          end: {
            line: 210,
            column: 82
          }
        }],
        line: 210
      },
      '34': {
        loc: {
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 224,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 224,
            column: 5
          }
        }, {
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 224,
            column: 5
          }
        }],
        line: 215
      },
      '35': {
        loc: {
          start: {
            line: 232,
            column: 6
          },
          end: {
            line: 241,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 232,
            column: 6
          },
          end: {
            line: 241,
            column: 7
          }
        }, {
          start: {
            line: 232,
            column: 6
          },
          end: {
            line: 241,
            column: 7
          }
        }],
        line: 232
      },
      '36': {
        loc: {
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 254,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 254,
            column: 5
          }
        }, {
          start: {
            line: 246,
            column: 4
          },
          end: {
            line: 254,
            column: 5
          }
        }],
        line: 246
      },
      '37': {
        loc: {
          start: {
            line: 270,
            column: 78
          },
          end: {
            line: 270,
            column: 146
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 270,
            column: 113
          },
          end: {
            line: 270,
            column: 141
          }
        }, {
          start: {
            line: 270,
            column: 144
          },
          end: {
            line: 270,
            column: 146
          }
        }],
        line: 270
      },
      '38': {
        loc: {
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        }, {
          start: {
            line: 273,
            column: 8
          },
          end: {
            line: 275,
            column: 9
          }
        }],
        line: 273
      },
      '39': {
        loc: {
          start: {
            line: 277,
            column: 21
          },
          end: {
            line: 279,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 277,
            column: 32
          },
          end: {
            line: 279,
            column: 9
          }
        }, {
          start: {
            line: 279,
            column: 12
          },
          end: {
            line: 279,
            column: 16
          }
        }],
        line: 277
      },
      '40': {
        loc: {
          start: {
            line: 278,
            column: 10
          },
          end: {
            line: 278,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 278,
            column: 38
          },
          end: {
            line: 278,
            column: 66
          }
        }, {
          start: {
            line: 278,
            column: 69
          },
          end: {
            line: 278,
            column: 93
          }
        }],
        line: 278
      },
      '41': {
        loc: {
          start: {
            line: 281,
            column: 23
          },
          end: {
            line: 283,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 281,
            column: 34
          },
          end: {
            line: 283,
            column: 9
          }
        }, {
          start: {
            line: 283,
            column: 12
          },
          end: {
            line: 283,
            column: 16
          }
        }],
        line: 281
      },
      '42': {
        loc: {
          start: {
            line: 282,
            column: 10
          },
          end: {
            line: 282,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 282,
            column: 38
          },
          end: {
            line: 282,
            column: 61
          }
        }, {
          start: {
            line: 282,
            column: 64
          },
          end: {
            line: 282,
            column: 83
          }
        }],
        line: 282
      },
      '43': {
        loc: {
          start: {
            line: 285,
            column: 21
          },
          end: {
            line: 285,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 285,
            column: 32
          },
          end: {
            line: 285,
            column: 81
          }
        }, {
          start: {
            line: 285,
            column: 84
          },
          end: {
            line: 285,
            column: 88
          }
        }],
        line: 285
      },
      '44': {
        loc: {
          start: {
            line: 287,
            column: 21
          },
          end: {
            line: 287,
            column: 87
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 287,
            column: 32
          },
          end: {
            line: 287,
            column: 80
          }
        }, {
          start: {
            line: 287,
            column: 83
          },
          end: {
            line: 287,
            column: 87
          }
        }],
        line: 287
      },
      '45': {
        loc: {
          start: {
            line: 289,
            column: 29
          },
          end: {
            line: 289,
            column: 133
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 289,
            column: 76
          },
          end: {
            line: 289,
            column: 80
          }
        }, {
          start: {
            line: 289,
            column: 83
          },
          end: {
            line: 289,
            column: 133
          }
        }],
        line: 289
      },
      '46': {
        loc: {
          start: {
            line: 289,
            column: 30
          },
          end: {
            line: 289,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 30
          },
          end: {
            line: 289,
            column: 42
          }
        }, {
          start: {
            line: 289,
            column: 46
          },
          end: {
            line: 289,
            column: 55
          }
        }, {
          start: {
            line: 289,
            column: 59
          },
          end: {
            line: 289,
            column: 72
          }
        }],
        line: 289
      },
      '47': {
        loc: {
          start: {
            line: 290,
            column: 30
          },
          end: {
            line: 290,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 290,
            column: 81
          },
          end: {
            line: 290,
            column: 85
          }
        }, {
          start: {
            line: 290,
            column: 88
          },
          end: {
            line: 290,
            column: 140
          }
        }],
        line: 290
      },
      '48': {
        loc: {
          start: {
            line: 290,
            column: 31
          },
          end: {
            line: 290,
            column: 77
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 290,
            column: 31
          },
          end: {
            line: 290,
            column: 45
          }
        }, {
          start: {
            line: 290,
            column: 49
          },
          end: {
            line: 290,
            column: 60
          }
        }, {
          start: {
            line: 290,
            column: 64
          },
          end: {
            line: 290,
            column: 77
          }
        }],
        line: 290
      },
      '49': {
        loc: {
          start: {
            line: 302,
            column: 8
          },
          end: {
            line: 309,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 302,
            column: 8
          },
          end: {
            line: 309,
            column: 9
          }
        }, {
          start: {
            line: 302,
            column: 8
          },
          end: {
            line: 309,
            column: 9
          }
        }],
        line: 302
      },
      '50': {
        loc: {
          start: {
            line: 303,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 303,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }, {
          start: {
            line: 303,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }],
        line: 303
      },
      '51': {
        loc: {
          start: {
            line: 304,
            column: 26
          },
          end: {
            line: 304,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 304,
            column: 26
          },
          end: {
            line: 304,
            column: 36
          }
        }, {
          start: {
            line: 304,
            column: 40
          },
          end: {
            line: 304,
            column: 41
          }
        }],
        line: 304
      },
      '52': {
        loc: {
          start: {
            line: 305,
            column: 25
          },
          end: {
            line: 305,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 305,
            column: 46
          },
          end: {
            line: 305,
            column: 50
          }
        }, {
          start: {
            line: 305,
            column: 53
          },
          end: {
            line: 305,
            column: 63
          }
        }],
        line: 305
      },
      '53': {
        loc: {
          start: {
            line: 311,
            column: 8
          },
          end: {
            line: 313,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 311,
            column: 8
          },
          end: {
            line: 313,
            column: 9
          }
        }, {
          start: {
            line: 311,
            column: 8
          },
          end: {
            line: 313,
            column: 9
          }
        }],
        line: 311
      },
      '54': {
        loc: {
          start: {
            line: 312,
            column: 24
          },
          end: {
            line: 312,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 312,
            column: 24
          },
          end: {
            line: 312,
            column: 34
          }
        }, {
          start: {
            line: 312,
            column: 38
          },
          end: {
            line: 312,
            column: 39
          }
        }],
        line: 312
      },
      '55': {
        loc: {
          start: {
            line: 319,
            column: 19
          },
          end: {
            line: 321,
            column: 14
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 319,
            column: 35
          },
          end: {
            line: 321,
            column: 7
          }
        }, {
          start: {
            line: 321,
            column: 10
          },
          end: {
            line: 321,
            column: 14
          }
        }],
        line: 319
      },
      '56': {
        loc: {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 320,
            column: 105
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 320,
            column: 44
          },
          end: {
            line: 320,
            column: 75
          }
        }, {
          start: {
            line: 320,
            column: 78
          },
          end: {
            line: 320,
            column: 105
          }
        }],
        line: 320
      },
      '57': {
        loc: {
          start: {
            line: 323,
            column: 21
          },
          end: {
            line: 325,
            column: 14
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 323,
            column: 37
          },
          end: {
            line: 325,
            column: 7
          }
        }, {
          start: {
            line: 325,
            column: 10
          },
          end: {
            line: 325,
            column: 14
          }
        }],
        line: 323
      },
      '58': {
        loc: {
          start: {
            line: 324,
            column: 8
          },
          end: {
            line: 324,
            column: 107
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 324,
            column: 44
          },
          end: {
            line: 324,
            column: 76
          }
        }, {
          start: {
            line: 324,
            column: 79
          },
          end: {
            line: 324,
            column: 107
          }
        }],
        line: 324
      },
      '59': {
        loc: {
          start: {
            line: 329,
            column: 20
          },
          end: {
            line: 329,
            column: 118
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 329,
            column: 58
          },
          end: {
            line: 329,
            column: 62
          }
        }, {
          start: {
            line: 329,
            column: 65
          },
          end: {
            line: 329,
            column: 118
          }
        }],
        line: 329
      },
      '60': {
        loc: {
          start: {
            line: 329,
            column: 20
          },
          end: {
            line: 329,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 329,
            column: 20
          },
          end: {
            line: 329,
            column: 39
          }
        }, {
          start: {
            line: 329,
            column: 43
          },
          end: {
            line: 329,
            column: 55
          }
        }],
        line: 329
      },
      '61': {
        loc: {
          start: {
            line: 330,
            column: 20
          },
          end: {
            line: 330,
            column: 122
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 330,
            column: 60
          },
          end: {
            line: 330,
            column: 64
          }
        }, {
          start: {
            line: 330,
            column: 67
          },
          end: {
            line: 330,
            column: 122
          }
        }],
        line: 330
      },
      '62': {
        loc: {
          start: {
            line: 330,
            column: 20
          },
          end: {
            line: 330,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 330,
            column: 20
          },
          end: {
            line: 330,
            column: 39
          }
        }, {
          start: {
            line: 330,
            column: 43
          },
          end: {
            line: 330,
            column: 57
          }
        }],
        line: 330
      },
      '63': {
        loc: {
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 346,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 346,
            column: 9
          }
        }, {
          start: {
            line: 344,
            column: 8
          },
          end: {
            line: 346,
            column: 9
          }
        }],
        line: 344
      },
      '64': {
        loc: {
          start: {
            line: 351,
            column: 94
          },
          end: {
            line: 351,
            column: 116
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 351,
            column: 94
          },
          end: {
            line: 351,
            column: 105
          }
        }, {
          start: {
            line: 351,
            column: 109
          },
          end: {
            line: 351,
            column: 116
          }
        }],
        line: 351
      },
      '65': {
        loc: {
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 354,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 354,
            column: 7
          }
        }, {
          start: {
            line: 352,
            column: 6
          },
          end: {
            line: 354,
            column: 7
          }
        }],
        line: 352
      },
      '66': {
        loc: {
          start: {
            line: 360,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 360,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        }, {
          start: {
            line: 360,
            column: 8
          },
          end: {
            line: 394,
            column: 9
          }
        }],
        line: 360
      },
      '67': {
        loc: {
          start: {
            line: 404,
            column: 10
          },
          end: {
            line: 404,
            column: 82
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 404,
            column: 37
          },
          end: {
            line: 404,
            column: 55
          }
        }, {
          start: {
            line: 404,
            column: 58
          },
          end: {
            line: 404,
            column: 82
          }
        }],
        line: 404
      },
      '68': {
        loc: {
          start: {
            line: 415,
            column: 4
          },
          end: {
            line: 426,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 415,
            column: 4
          },
          end: {
            line: 426,
            column: 5
          }
        }, {
          start: {
            line: 415,
            column: 4
          },
          end: {
            line: 426,
            column: 5
          }
        }],
        line: 415
      },
      '69': {
        loc: {
          start: {
            line: 428,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 428,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        }, {
          start: {
            line: 428,
            column: 4
          },
          end: {
            line: 432,
            column: 5
          }
        }],
        line: 428
      },
      '70': {
        loc: {
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        }, {
          start: {
            line: 446,
            column: 8
          },
          end: {
            line: 448,
            column: 9
          }
        }],
        line: 446
      },
      '71': {
        loc: {
          start: {
            line: 450,
            column: 8
          },
          end: {
            line: 452,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 450,
            column: 8
          },
          end: {
            line: 452,
            column: 9
          }
        }, {
          start: {
            line: 450,
            column: 8
          },
          end: {
            line: 452,
            column: 9
          }
        }],
        line: 450
      },
      '72': {
        loc: {
          start: {
            line: 455,
            column: 21
          },
          end: {
            line: 457,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 455,
            column: 32
          },
          end: {
            line: 457,
            column: 9
          }
        }, {
          start: {
            line: 457,
            column: 12
          },
          end: {
            line: 457,
            column: 16
          }
        }],
        line: 455
      },
      '73': {
        loc: {
          start: {
            line: 456,
            column: 10
          },
          end: {
            line: 456,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 456,
            column: 38
          },
          end: {
            line: 456,
            column: 66
          }
        }, {
          start: {
            line: 456,
            column: 69
          },
          end: {
            line: 456,
            column: 93
          }
        }],
        line: 456
      },
      '74': {
        loc: {
          start: {
            line: 459,
            column: 23
          },
          end: {
            line: 461,
            column: 16
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 459,
            column: 34
          },
          end: {
            line: 461,
            column: 9
          }
        }, {
          start: {
            line: 461,
            column: 12
          },
          end: {
            line: 461,
            column: 16
          }
        }],
        line: 459
      },
      '75': {
        loc: {
          start: {
            line: 460,
            column: 10
          },
          end: {
            line: 460,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 460,
            column: 38
          },
          end: {
            line: 460,
            column: 61
          }
        }, {
          start: {
            line: 460,
            column: 64
          },
          end: {
            line: 460,
            column: 83
          }
        }],
        line: 460
      },
      '76': {
        loc: {
          start: {
            line: 463,
            column: 18
          },
          end: {
            line: 463,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 463,
            column: 46
          },
          end: {
            line: 463,
            column: 74
          }
        }, {
          start: {
            line: 463,
            column: 77
          },
          end: {
            line: 463,
            column: 101
          }
        }],
        line: 463
      },
      '77': {
        loc: {
          start: {
            line: 464,
            column: 18
          },
          end: {
            line: 464,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 464,
            column: 46
          },
          end: {
            line: 464,
            column: 74
          }
        }, {
          start: {
            line: 464,
            column: 77
          },
          end: {
            line: 464,
            column: 101
          }
        }],
        line: 464
      },
      '78': {
        loc: {
          start: {
            line: 468,
            column: 8
          },
          end: {
            line: 470,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 468,
            column: 8
          },
          end: {
            line: 470,
            column: 9
          }
        }, {
          start: {
            line: 468,
            column: 8
          },
          end: {
            line: 470,
            column: 9
          }
        }],
        line: 468
      },
      '79': {
        loc: {
          start: {
            line: 472,
            column: 8
          },
          end: {
            line: 474,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 472,
            column: 8
          },
          end: {
            line: 474,
            column: 9
          }
        }, {
          start: {
            line: 472,
            column: 8
          },
          end: {
            line: 474,
            column: 9
          }
        }],
        line: 472
      },
      '80': {
        loc: {
          start: {
            line: 476,
            column: 8
          },
          end: {
            line: 478,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 476,
            column: 8
          },
          end: {
            line: 478,
            column: 9
          }
        }, {
          start: {
            line: 476,
            column: 8
          },
          end: {
            line: 478,
            column: 9
          }
        }],
        line: 476
      },
      '81': {
        loc: {
          start: {
            line: 480,
            column: 8
          },
          end: {
            line: 482,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 480,
            column: 8
          },
          end: {
            line: 482,
            column: 9
          }
        }, {
          start: {
            line: 480,
            column: 8
          },
          end: {
            line: 482,
            column: 9
          }
        }],
        line: 480
      },
      '82': {
        loc: {
          start: {
            line: 484,
            column: 21
          },
          end: {
            line: 484,
            column: 88
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 484,
            column: 32
          },
          end: {
            line: 484,
            column: 81
          }
        }, {
          start: {
            line: 484,
            column: 84
          },
          end: {
            line: 484,
            column: 88
          }
        }],
        line: 484
      },
      '83': {
        loc: {
          start: {
            line: 486,
            column: 21
          },
          end: {
            line: 486,
            column: 87
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 486,
            column: 32
          },
          end: {
            line: 486,
            column: 80
          }
        }, {
          start: {
            line: 486,
            column: 83
          },
          end: {
            line: 486,
            column: 87
          }
        }],
        line: 486
      },
      '84': {
        loc: {
          start: {
            line: 488,
            column: 29
          },
          end: {
            line: 488,
            column: 133
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 488,
            column: 76
          },
          end: {
            line: 488,
            column: 80
          }
        }, {
          start: {
            line: 488,
            column: 83
          },
          end: {
            line: 488,
            column: 133
          }
        }],
        line: 488
      },
      '85': {
        loc: {
          start: {
            line: 488,
            column: 30
          },
          end: {
            line: 488,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 488,
            column: 30
          },
          end: {
            line: 488,
            column: 42
          }
        }, {
          start: {
            line: 488,
            column: 46
          },
          end: {
            line: 488,
            column: 55
          }
        }, {
          start: {
            line: 488,
            column: 59
          },
          end: {
            line: 488,
            column: 72
          }
        }],
        line: 488
      },
      '86': {
        loc: {
          start: {
            line: 489,
            column: 30
          },
          end: {
            line: 489,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 489,
            column: 81
          },
          end: {
            line: 489,
            column: 85
          }
        }, {
          start: {
            line: 489,
            column: 88
          },
          end: {
            line: 489,
            column: 140
          }
        }],
        line: 489
      },
      '87': {
        loc: {
          start: {
            line: 489,
            column: 31
          },
          end: {
            line: 489,
            column: 77
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 489,
            column: 31
          },
          end: {
            line: 489,
            column: 45
          }
        }, {
          start: {
            line: 489,
            column: 49
          },
          end: {
            line: 489,
            column: 60
          }
        }, {
          start: {
            line: 489,
            column: 64
          },
          end: {
            line: 489,
            column: 77
          }
        }],
        line: 489
      },
      '88': {
        loc: {
          start: {
            line: 501,
            column: 8
          },
          end: {
            line: 508,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 501,
            column: 8
          },
          end: {
            line: 508,
            column: 9
          }
        }, {
          start: {
            line: 501,
            column: 8
          },
          end: {
            line: 508,
            column: 9
          }
        }],
        line: 501
      },
      '89': {
        loc: {
          start: {
            line: 502,
            column: 10
          },
          end: {
            line: 507,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 502,
            column: 10
          },
          end: {
            line: 507,
            column: 11
          }
        }, {
          start: {
            line: 502,
            column: 10
          },
          end: {
            line: 507,
            column: 11
          }
        }],
        line: 502
      },
      '90': {
        loc: {
          start: {
            line: 503,
            column: 26
          },
          end: {
            line: 503,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 503,
            column: 26
          },
          end: {
            line: 503,
            column: 36
          }
        }, {
          start: {
            line: 503,
            column: 40
          },
          end: {
            line: 503,
            column: 41
          }
        }],
        line: 503
      },
      '91': {
        loc: {
          start: {
            line: 504,
            column: 25
          },
          end: {
            line: 504,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 504,
            column: 46
          },
          end: {
            line: 504,
            column: 50
          }
        }, {
          start: {
            line: 504,
            column: 53
          },
          end: {
            line: 504,
            column: 63
          }
        }],
        line: 504
      },
      '92': {
        loc: {
          start: {
            line: 506,
            column: 26
          },
          end: {
            line: 506,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 506,
            column: 26
          },
          end: {
            line: 506,
            column: 36
          }
        }, {
          start: {
            line: 506,
            column: 40
          },
          end: {
            line: 506,
            column: 41
          }
        }],
        line: 506
      },
      '93': {
        loc: {
          start: {
            line: 510,
            column: 8
          },
          end: {
            line: 512,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 510,
            column: 8
          },
          end: {
            line: 512,
            column: 9
          }
        }, {
          start: {
            line: 510,
            column: 8
          },
          end: {
            line: 512,
            column: 9
          }
        }],
        line: 510
      },
      '94': {
        loc: {
          start: {
            line: 511,
            column: 24
          },
          end: {
            line: 511,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 511,
            column: 24
          },
          end: {
            line: 511,
            column: 34
          }
        }, {
          start: {
            line: 511,
            column: 38
          },
          end: {
            line: 511,
            column: 39
          }
        }],
        line: 511
      },
      '95': {
        loc: {
          start: {
            line: 514,
            column: 22
          },
          end: {
            line: 514,
            column: 108
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 514,
            column: 54
          },
          end: {
            line: 514,
            column: 58
          }
        }, {
          start: {
            line: 514,
            column: 61
          },
          end: {
            line: 514,
            column: 108
          }
        }],
        line: 514
      },
      '96': {
        loc: {
          start: {
            line: 514,
            column: 22
          },
          end: {
            line: 514,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 514,
            column: 22
          },
          end: {
            line: 514,
            column: 35
          }
        }, {
          start: {
            line: 514,
            column: 39
          },
          end: {
            line: 514,
            column: 51
          }
        }],
        line: 514
      },
      '97': {
        loc: {
          start: {
            line: 515,
            column: 22
          },
          end: {
            line: 515,
            column: 112
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 515,
            column: 56
          },
          end: {
            line: 515,
            column: 60
          }
        }, {
          start: {
            line: 515,
            column: 63
          },
          end: {
            line: 515,
            column: 112
          }
        }],
        line: 515
      },
      '98': {
        loc: {
          start: {
            line: 515,
            column: 22
          },
          end: {
            line: 515,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 515,
            column: 22
          },
          end: {
            line: 515,
            column: 35
          }
        }, {
          start: {
            line: 515,
            column: 39
          },
          end: {
            line: 515,
            column: 53
          }
        }],
        line: 515
      },
      '99': {
        loc: {
          start: {
            line: 516,
            column: 24
          },
          end: {
            line: 516,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 516,
            column: 24
          },
          end: {
            line: 516,
            column: 61
          }
        }, {
          start: {
            line: 516,
            column: 65
          },
          end: {
            line: 516,
            column: 67
          }
        }],
        line: 516
      },
      '100': {
        loc: {
          start: {
            line: 516,
            column: 25
          },
          end: {
            line: 516,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 516,
            column: 25
          },
          end: {
            line: 516,
            column: 33
          }
        }, {
          start: {
            line: 516,
            column: 37
          },
          end: {
            line: 516,
            column: 51
          }
        }],
        line: 516
      },
      '101': {
        loc: {
          start: {
            line: 534,
            column: 6
          },
          end: {
            line: 536,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 534,
            column: 6
          },
          end: {
            line: 536,
            column: 7
          }
        }, {
          start: {
            line: 534,
            column: 6
          },
          end: {
            line: 536,
            column: 7
          }
        }],
        line: 534
      },
      '102': {
        loc: {
          start: {
            line: 541,
            column: 8
          },
          end: {
            line: 561,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 541,
            column: 8
          },
          end: {
            line: 561,
            column: 9
          }
        }, {
          start: {
            line: 541,
            column: 8
          },
          end: {
            line: 561,
            column: 9
          }
        }],
        line: 541
      },
      '103': {
        loc: {
          start: {
            line: 600,
            column: 14
          },
          end: {
            line: 600,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 600,
            column: 36
          },
          end: {
            line: 600,
            column: 42
          }
        }, {
          start: {
            line: 600,
            column: 45
          },
          end: {
            line: 600,
            column: 51
          }
        }],
        line: 600
      },
      '104': {
        loc: {
          start: {
            line: 634,
            column: 2
          },
          end: {
            line: 774,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 634,
            column: 2
          },
          end: {
            line: 774,
            column: 3
          }
        }, {
          start: {
            line: 634,
            column: 2
          },
          end: {
            line: 774,
            column: 3
          }
        }],
        line: 634
      },
      '105': {
        loc: {
          start: {
            line: 635,
            column: 4
          },
          end: {
            line: 773,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 635,
            column: 4
          },
          end: {
            line: 773,
            column: 5
          }
        }, {
          start: {
            line: 635,
            column: 4
          },
          end: {
            line: 773,
            column: 5
          }
        }],
        line: 635
      },
      '106': {
        loc: {
          start: {
            line: 719,
            column: 10
          },
          end: {
            line: 734,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 720,
            column: 12
          },
          end: {
            line: 722,
            column: 20
          }
        }, {
          start: {
            line: 723,
            column: 12
          },
          end: {
            line: 725,
            column: 20
          }
        }, {
          start: {
            line: 726,
            column: 12
          },
          end: {
            line: 728,
            column: 20
          }
        }, {
          start: {
            line: 729,
            column: 12
          },
          end: {
            line: 731,
            column: 20
          }
        }, {
          start: {
            line: 732,
            column: 12
          },
          end: {
            line: 733,
            column: 25
          }
        }],
        line: 719
      },
      '107': {
        loc: {
          start: {
            line: 738,
            column: 10
          },
          end: {
            line: 740,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 738,
            column: 10
          },
          end: {
            line: 740,
            column: 11
          }
        }, {
          start: {
            line: 738,
            column: 10
          },
          end: {
            line: 740,
            column: 11
          }
        }],
        line: 738
      },
      '108': {
        loc: {
          start: {
            line: 754,
            column: 12
          },
          end: {
            line: 756,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 754,
            column: 12
          },
          end: {
            line: 756,
            column: 13
          }
        }, {
          start: {
            line: 754,
            column: 12
          },
          end: {
            line: 756,
            column: 13
          }
        }],
        line: 754
      },
      '109': {
        loc: {
          start: {
            line: 775,
            column: 2
          },
          end: {
            line: 877,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 775,
            column: 2
          },
          end: {
            line: 877,
            column: 3
          }
        }, {
          start: {
            line: 775,
            column: 2
          },
          end: {
            line: 877,
            column: 3
          }
        }],
        line: 775
      },
      '110': {
        loc: {
          start: {
            line: 780,
            column: 6
          },
          end: {
            line: 795,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 780,
            column: 6
          },
          end: {
            line: 795,
            column: 7
          }
        }, {
          start: {
            line: 780,
            column: 6
          },
          end: {
            line: 795,
            column: 7
          }
        }],
        line: 780
      },
      '111': {
        loc: {
          start: {
            line: 786,
            column: 22
          },
          end: {
            line: 786,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 786,
            column: 37
          },
          end: {
            line: 786,
            column: 99
          }
        }, {
          start: {
            line: 786,
            column: 102
          },
          end: {
            line: 786,
            column: 106
          }
        }],
        line: 786
      },
      '112': {
        loc: {
          start: {
            line: 790,
            column: 10
          },
          end: {
            line: 792,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 790,
            column: 10
          },
          end: {
            line: 792,
            column: 11
          }
        }, {
          start: {
            line: 790,
            column: 10
          },
          end: {
            line: 792,
            column: 11
          }
        }],
        line: 790
      },
      '113': {
        loc: {
          start: {
            line: 797,
            column: 32
          },
          end: {
            line: 869,
            column: 7
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 797,
            column: 59
          },
          end: {
            line: 847,
            column: 7
          }
        }, {
          start: {
            line: 847,
            column: 10
          },
          end: {
            line: 869,
            column: 7
          }
        }],
        line: 797
      },
      '114': {
        loc: {
          start: {
            line: 858,
            column: 10
          },
          end: {
            line: 860,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 858,
            column: 10
          },
          end: {
            line: 860,
            column: 11
          }
        }, {
          start: {
            line: 858,
            column: 10
          },
          end: {
            line: 860,
            column: 11
          }
        }],
        line: 858
      },
      '115': {
        loc: {
          start: {
            line: 876,
            column: 19
          },
          end: {
            line: 876,
            column: 126
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 876,
            column: 46
          },
          end: {
            line: 876,
            column: 61
          }
        }, {
          start: {
            line: 876,
            column: 65
          },
          end: {
            line: 876,
            column: 125
          }
        }],
        line: 876
      },
      '116': {
        loc: {
          start: {
            line: 876,
            column: 65
          },
          end: {
            line: 876,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 876,
            column: 92
          },
          end: {
            line: 876,
            column: 107
          }
        }, {
          start: {
            line: 876,
            column: 110
          },
          end: {
            line: 876,
            column: 125
          }
        }],
        line: 876
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0,
      '259': 0,
      '260': 0,
      '261': 0,
      '262': 0,
      '263': 0,
      '264': 0,
      '265': 0,
      '266': 0,
      '267': 0,
      '268': 0,
      '269': 0,
      '270': 0,
      '271': 0,
      '272': 0,
      '273': 0,
      '274': 0,
      '275': 0,
      '276': 0,
      '277': 0,
      '278': 0,
      '279': 0,
      '280': 0,
      '281': 0,
      '282': 0,
      '283': 0,
      '284': 0,
      '285': 0,
      '286': 0,
      '287': 0,
      '288': 0,
      '289': 0,
      '290': 0,
      '291': 0,
      '292': 0,
      '293': 0,
      '294': 0,
      '295': 0,
      '296': 0,
      '297': 0,
      '298': 0,
      '299': 0,
      '300': 0,
      '301': 0,
      '302': 0,
      '303': 0,
      '304': 0,
      '305': 0,
      '306': 0,
      '307': 0,
      '308': 0,
      '309': 0,
      '310': 0,
      '311': 0,
      '312': 0,
      '313': 0,
      '314': 0,
      '315': 0,
      '316': 0,
      '317': 0,
      '318': 0,
      '319': 0,
      '320': 0,
      '321': 0,
      '322': 0,
      '323': 0,
      '324': 0,
      '325': 0,
      '326': 0,
      '327': 0,
      '328': 0,
      '329': 0,
      '330': 0,
      '331': 0,
      '332': 0,
      '333': 0,
      '334': 0,
      '335': 0,
      '336': 0,
      '337': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0, 0],
      '47': [0, 0],
      '48': [0, 0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0],
      '85': [0, 0, 0],
      '86': [0, 0],
      '87': [0, 0, 0],
      '88': [0, 0],
      '89': [0, 0],
      '90': [0, 0],
      '91': [0, 0],
      '92': [0, 0],
      '93': [0, 0],
      '94': [0, 0],
      '95': [0, 0],
      '96': [0, 0],
      '97': [0, 0],
      '98': [0, 0],
      '99': [0, 0],
      '100': [0, 0],
      '101': [0, 0],
      '102': [0, 0],
      '103': [0, 0],
      '104': [0, 0],
      '105': [0, 0],
      '106': [0, 0, 0, 0, 0],
      '107': [0, 0],
      '108': [0, 0],
      '109': [0, 0],
      '110': [0, 0],
      '111': [0, 0],
      '112': [0, 0],
      '113': [0, 0],
      '114': [0, 0],
      '115': [0, 0],
      '116': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1n0ltttluz.s[0]++;

var fn_table_activity_item = function fn_table_activity_item($scope, $rootScope, $LOGINService, $translate, $filter, $CRUDService, $deltaNumber, $deltadate, $log) {
  cov_1n0ltttluz.f[0]++;
  cov_1n0ltttluz.s[1]++;

  $scope.ISINCLUDEDRAPPORT = $rootScope.getISINCLUDEDRAPPORT();
  cov_1n0ltttluz.s[2]++;
  $scope.ID_COMPONENT = $scope.idWidget;
  var PATHG = (cov_1n0ltttluz.s[3]++, 'Planification');
  var PATHP = (cov_1n0ltttluz.s[4]++, 'Relever_B_Niv');
  var PATHPER = (cov_1n0ltttluz.s[5]++, 'Periode');
  var PATH = (cov_1n0ltttluz.s[6]++, 'DernierNiveau');
  var PATH_CL = (cov_1n0ltttluz.s[7]++, 'CadreLogique');
  var PATH_AGREEMENT = (cov_1n0ltttluz.s[8]++, 'Funds_disbursement');

  //$log.log("fn_table_activity_item");
  //$log.log($scope);
  //$log.log("analyse");
  //$log.log($scope.analyse);
  /* Global */
  cov_1n0ltttluz.s[9]++;
  var generateColumnsGlobal = function generateColumnsGlobal() {
    cov_1n0ltttluz.f[1]++;

    var doubleItems = (cov_1n0ltttluz.s[10]++, $scope.analyse.displaycost);
    var c = (cov_1n0ltttluz.s[11]++, [{ field: 'label', width: 300, headerText: $translate.instant($scope.project.portfolio ? (cov_1n0ltttluz.b[0][0]++, 'ACTIVITY.PROJECT') : (cov_1n0ltttluz.b[0][1]++, 'ACTIVITY.PLAN.LOCATION')), type: 'string', clipMode: 'EllipsisWithTooltip' }]);

    cov_1n0ltttluz.s[12]++;
    if ((cov_1n0ltttluz.b[2][0]++, $scope.analyse.TYPESUIVI) || (cov_1n0ltttluz.b[2][1]++, true)) {
      cov_1n0ltttluz.b[1][0]++;

      var global = (cov_1n0ltttluz.s[13]++, [{ field: 'plan_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED') + ' Global', format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]);
      cov_1n0ltttluz.s[14]++;
      if (doubleItems) {
        cov_1n0ltttluz.b[3][0]++;
        cov_1n0ltttluz.s[15]++;

        global = {
          headerText: 'Global',
          headerTextAlign: 'center',
          columns: [{ field: 'plan_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED'), format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'budget_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.COST_PLANNED'), format: 'N', width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]
        };
      } else {
        cov_1n0ltttluz.b[3][1]++;
      }
      cov_1n0ltttluz.s[16]++;
      c = c.concat(global);
    } else {
      cov_1n0ltttluz.b[1][1]++;
    }

    cov_1n0ltttluz.s[17]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.project.PERIODS[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var el = _step.value;

        var period = (cov_1n0ltttluz.s[18]++, [{ field: 'real_' + el.ANNEE_PERIOD, headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + ' ' + el.ANNEE_PERIOD, format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]);

        cov_1n0ltttluz.s[19]++;
        if (doubleItems) {
          cov_1n0ltttluz.b[4][0]++;
          cov_1n0ltttluz.s[20]++;

          period = {
            headerTextAlign: 'center',
            headerText: el.ANNEE_PERIOD,
            width: 300,
            columns: [{ field: 'real_' + el.ANNEE_PERIOD, headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL'), format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'cost_' + el.ANNEE_PERIOD, headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.COST_ACTUAL'), format: 'N', width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }] };
        } else {
          cov_1n0ltttluz.b[4][1]++;
        }
        cov_1n0ltttluz.s[21]++;
        c = c.concat(period);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1n0ltttluz.s[22]++;
    if ((cov_1n0ltttluz.b[6][0]++, $scope.analyse.TYPESUIVI) || (cov_1n0ltttluz.b[6][1]++, true)) {
      cov_1n0ltttluz.b[5][0]++;

      var _global = (cov_1n0ltttluz.s[23]++, [{ field: 'real_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + ' Global', format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]);
      cov_1n0ltttluz.s[24]++;
      if (doubleItems) {
        cov_1n0ltttluz.b[7][0]++;
        cov_1n0ltttluz.s[25]++;

        _global = {
          headerText: 'Global',
          width: 300,
          columns: [{ field: 'real_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL'), format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'cost_0', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.COST_ACTUAL'), format: 'N', width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }] };
      } else {
        cov_1n0ltttluz.b[7][1]++;
      }
      cov_1n0ltttluz.s[26]++;
      c = c.concat(_global);
    } else {
      cov_1n0ltttluz.b[5][1]++;
    }

    cov_1n0ltttluz.s[27]++;
    c.push({ field: 'rate_t', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET'), format: 'P2', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });

    cov_1n0ltttluz.s[28]++;
    c.push({ field: 'rate_b', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET'), format: 'P2', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });

    cov_1n0ltttluz.s[29]++;
    return c;
  };
  cov_1n0ltttluz.s[30]++;
  var processGlobalData = function processGlobalData(items) {
    cov_1n0ltttluz.f[2]++;

    var data = (cov_1n0ltttluz.s[31]++, []);
    var tmp_global = (cov_1n0ltttluz.s[32]++, 0);
    cov_1n0ltttluz.s[33]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var item = _step2.value;

        var line = (cov_1n0ltttluz.s[34]++, { label: item.C + ': ' + item.D, graph: [] });
        var globalReal = (cov_1n0ltttluz.s[35]++, null);
        var globalCost = (cov_1n0ltttluz.s[36]++, null);

        cov_1n0ltttluz.s[37]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = $scope.project.PERIODS[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var period = _step3.value;

            var key = (cov_1n0ltttluz.s[38]++, period.ANNEE_PERIOD);
            var dataLine = (cov_1n0ltttluz.s[39]++, item.data[key]);
            var _plan = (cov_1n0ltttluz.s[40]++, dataLine ? (cov_1n0ltttluz.b[8][0]++, $deltaNumber.convertToNumber(dataLine.DATE_DEBUT_P_AN_REV ? (cov_1n0ltttluz.b[9][0]++, dataLine.QTE_PREVUE_AN_REV) : (cov_1n0ltttluz.b[9][1]++, dataLine.QTE_PREVUE_AN))) : (cov_1n0ltttluz.b[8][1]++, null));

            var _budget = (cov_1n0ltttluz.s[41]++, dataLine ? (cov_1n0ltttluz.b[10][0]++, $deltaNumber.convertToNumber(dataLine.DATE_DEBUT_PR ? (cov_1n0ltttluz.b[11][0]++, dataLine.BUDGET_PLAN_AN_REV) : (cov_1n0ltttluz.b[11][1]++, dataLine.BUDGET_PLAN_AN))) : (cov_1n0ltttluz.b[10][1]++, null));

            var real = (cov_1n0ltttluz.s[42]++, dataLine ? (cov_1n0ltttluz.b[12][0]++, $deltaNumber.convertToNumber(dataLine.SQTE_REELLE)) : (cov_1n0ltttluz.b[12][1]++, null));

            var cost = (cov_1n0ltttluz.s[43]++, dataLine ? (cov_1n0ltttluz.b[13][0]++, $deltaNumber.convertToNumber(dataLine.SCOUT_REEL)) : (cov_1n0ltttluz.b[13][1]++, null));

            var physicalRate = (cov_1n0ltttluz.s[44]++, (cov_1n0ltttluz.b[15][0]++, _plan == null) || (cov_1n0ltttluz.b[15][1]++, real === null) ? (cov_1n0ltttluz.b[14][0]++, null) : (cov_1n0ltttluz.b[14][1]++, real / _plan));
            var financialRate = (cov_1n0ltttluz.s[45]++, (cov_1n0ltttluz.b[17][0]++, _budget == null) || (cov_1n0ltttluz.b[17][1]++, cost === null) ? (cov_1n0ltttluz.b[16][0]++, null) : (cov_1n0ltttluz.b[16][1]++, cost / _budget));

            cov_1n0ltttluz.s[46]++;
            line.graph.push({
              label: key.toString(),
              physical: physicalRate,
              financial: financialRate
            });
            cov_1n0ltttluz.s[47]++;
            line['plan_' + key] = _plan;
            cov_1n0ltttluz.s[48]++;
            line['budget_' + key] = _budget;
            cov_1n0ltttluz.s[49]++;
            line['real_' + key] = real;
            cov_1n0ltttluz.s[50]++;
            line['cost_' + key] = cost;

            cov_1n0ltttluz.s[51]++;
            if (real !== null) {
              cov_1n0ltttluz.b[18][0]++;
              cov_1n0ltttluz.s[52]++;

              if ($scope.analyse.activity_processus) {
                cov_1n0ltttluz.b[19][0]++;
                cov_1n0ltttluz.s[53]++;

                tmp_global = (cov_1n0ltttluz.b[20][0]++, globalReal) || (cov_1n0ltttluz.b[20][1]++, 0);
                cov_1n0ltttluz.s[54]++;
                globalReal = tmp_global < real ? (cov_1n0ltttluz.b[21][0]++, real) : (cov_1n0ltttluz.b[21][1]++, tmp_global);
              } else {
                cov_1n0ltttluz.b[19][1]++;
                cov_1n0ltttluz.s[55]++;

                globalReal = ((cov_1n0ltttluz.b[22][0]++, globalReal) || (cov_1n0ltttluz.b[22][1]++, 0)) + real;
              }
            } else {
              cov_1n0ltttluz.b[18][1]++;
            }

            cov_1n0ltttluz.s[56]++;
            if (cost !== null) {
              cov_1n0ltttluz.b[23][0]++;
              cov_1n0ltttluz.s[57]++;

              globalCost = ((cov_1n0ltttluz.b[24][0]++, globalCost) || (cov_1n0ltttluz.b[24][1]++, 0)) + cost;
            } else {
              cov_1n0ltttluz.b[23][1]++;
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        cov_1n0ltttluz.s[58]++;
        line.real_0 = globalReal;
        cov_1n0ltttluz.s[59]++;
        line.cost_0 = globalCost;

        var plan = (cov_1n0ltttluz.s[60]++, $deltaNumber.convertToNumber(item.data[0].QTE_PREVUE_REV ? (cov_1n0ltttluz.b[25][0]++, item.data[0].QTE_PREVUE_REV) : (cov_1n0ltttluz.b[25][1]++, item.data[0].QTE_PREVUE)));

        var budget = (cov_1n0ltttluz.s[61]++, $deltaNumber.convertToNumber(item.data[0].DATE_DEBUT_PR ? (cov_1n0ltttluz.b[26][0]++, item.data[0].BUDGET_PLAN_REV) : (cov_1n0ltttluz.b[26][1]++, item.data[0].BUDGET_PLAN)));
        cov_1n0ltttluz.s[62]++;
        line.plan_0 = plan;
        cov_1n0ltttluz.s[63]++;
        line.budget_0 = budget;

        cov_1n0ltttluz.s[64]++;
        line.rate_t = (cov_1n0ltttluz.b[28][0]++, globalReal === null) || (cov_1n0ltttluz.b[28][1]++, plan == null) ? (cov_1n0ltttluz.b[27][0]++, null) : (cov_1n0ltttluz.b[27][1]++, $deltaNumber.formatNumber(globalReal / plan, null, 4));
        cov_1n0ltttluz.s[65]++;
        line.rate_b = (cov_1n0ltttluz.b[30][0]++, globalCost === null) || (cov_1n0ltttluz.b[30][1]++, budget == null) ? (cov_1n0ltttluz.b[29][0]++, null) : (cov_1n0ltttluz.b[29][1]++, $deltaNumber.formatNumber(globalCost / budget, null, 4));

        cov_1n0ltttluz.s[66]++;
        data.push(line);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_1n0ltttluz.s[67]++;
    return data;
  };
  cov_1n0ltttluz.s[68]++;
  var processGlobal = function processGlobal() {
    cov_1n0ltttluz.f[3]++;
    cov_1n0ltttluz.s[69]++;

    $CRUDService.getAll(PATHP, { get: 'concern_projects_by_id', id: $scope.analyse.idp }, function (data) {
      cov_1n0ltttluz.f[4]++;
      cov_1n0ltttluz.s[70]++;

      //$log.log('processGlobal concern_projects_by_id');
      //$log.log(data);

      $scope.project = data[0];
      var param = (cov_1n0ltttluz.s[71]++, { get: 'dashboard_global', activ: $scope.analyse.activity, valid: 0, idp: $scope.project.ID_PROJET });
      cov_1n0ltttluz.s[72]++;
      if ($scope.project.portfolio) {
        cov_1n0ltttluz.b[31][0]++;
        cov_1n0ltttluz.s[73]++;

        param.get = 'dashboard_global_cross';
      } else {
        cov_1n0ltttluz.b[31][1]++;
      }
      cov_1n0ltttluz.s[74]++;
      $CRUDService.getAll(PATHP, param, function (allData) {
        cov_1n0ltttluz.f[5]++;
        cov_1n0ltttluz.s[75]++;

        //$log.log('processGlobal dashboard_global');
        //$log.log(allData);
        if ($scope.analyse.TYPE == 1) {
          cov_1n0ltttluz.b[32][0]++;
          cov_1n0ltttluz.s[76]++;

          $scope.grid.columns = generateColumnsGlobal();
          cov_1n0ltttluz.s[77]++;
          $scope.grid.dataSource = processGlobalData(allData);
          cov_1n0ltttluz.s[78]++;
          $scope.grid.recordClick = function (args) {
            cov_1n0ltttluz.f[6]++;

            var event = (cov_1n0ltttluz.s[79]++, args.cell.getBoundingClientRect());
            var body = (cov_1n0ltttluz.s[80]++, $('body'));
            var width = (cov_1n0ltttluz.s[81]++, Math.min(600, body.width()));
            var height = (cov_1n0ltttluz.s[82]++, Math.min(500, body.height()));
            var left = (cov_1n0ltttluz.s[83]++, Math.min(event.x, body.width() - width));
            var top = (cov_1n0ltttluz.s[84]++, Math.min(event.y, body.height() - height));
            cov_1n0ltttluz.s[85]++;
            $scope.grid.panelPosition = { top: top - 100 + 'px', left: left - 100 + 'px', width: width + 'px', height: height + 'px' };

            cov_1n0ltttluz.s[86]++;
            $scope.grid.graphParams.series[0].dataSource = args.graph;
            cov_1n0ltttluz.s[87]++;
            $scope.grid.graphParams.series[1].dataSource = args.graph;
            cov_1n0ltttluz.s[88]++;
            $scope.grid.graphParams.title = $translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_FIRST').toUpperCase() + ' (' + args.label + ')';
            cov_1n0ltttluz.s[89]++;
            $scope.grid.graphParams.title_ = $translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_FIRST').toUpperCase() + ' (' + args.label + ')';

            cov_1n0ltttluz.s[90]++;
            $scope.grid.chart = true;
          };
          cov_1n0ltttluz.s[91]++;
          $scope.grid.displayGrid = true;
        } else {
          cov_1n0ltttluz.b[32][1]++;

          var graphs = (cov_1n0ltttluz.s[92]++, []);
          cov_1n0ltttluz.s[93]++;
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = processGlobalData(allData)[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var item = _step4.value;
              cov_1n0ltttluz.s[94]++;

              graphs.push({
                label: item.label,
                physical: item.rate_t,
                financial: item.rate_b
              });
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }

          cov_1n0ltttluz.s[95]++;
          $scope.grid.graphParams.series[0].dataSource = graphs;
          cov_1n0ltttluz.s[96]++;
          $scope.grid.graphParams.series[1].dataSource = graphs;
          cov_1n0ltttluz.s[97]++;
          $scope.grid.graphParams.title = '';
          cov_1n0ltttluz.s[98]++;
          $scope.grid.graphParams.title_ = '';

          cov_1n0ltttluz.s[99]++;
          $scope.grid.displayChart = true;
        }
      });
    });
  };

  /* Annual */

  cov_1n0ltttluz.s[100]++;
  var generateColumnsAnnual = function generateColumnsAnnual() {
    cov_1n0ltttluz.f[7]++;

    var listSubPeriod = (cov_1n0ltttluz.s[101]++, $deltadate.getYearPeriod($scope.year.ANNEE_PERIOD, $scope.year.DECOUPAGE_PERIOD));
    var doubleItems = (cov_1n0ltttluz.s[102]++, $scope.analyse.displaycost);
    var c = (cov_1n0ltttluz.s[103]++, [{ field: 'label', width: 300, headerText: $translate.instant($scope.project.portfolio ? (cov_1n0ltttluz.b[33][0]++, 'ACTIVITY.PROJECT') : (cov_1n0ltttluz.b[33][1]++, 'ACTIVITY.PLAN.LOCATION')), type: 'string', clipMode: 'EllipsisWithTooltip' }]);

    var global = (cov_1n0ltttluz.s[104]++, [{ field: 'plan_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED') + ' ' + $scope.year.ANNEE_PERIOD, width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]);
    cov_1n0ltttluz.s[105]++;
    if (doubleItems) {
      cov_1n0ltttluz.b[34][0]++;
      cov_1n0ltttluz.s[106]++;

      global = {
        headerText: $scope.year.ANNEE_PERIOD,
        headerTextAlign: 'center',
        columns: [{ field: 'plan_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED'), width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'budget_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.COST_PLANNED'), width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]
      };
    } else {
      cov_1n0ltttluz.b[34][1]++;
    }
    cov_1n0ltttluz.s[107]++;
    c = c.concat(global);

    cov_1n0ltttluz.s[108]++;
    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;

    try {
      for (var _iterator5 = listSubPeriod[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var el = _step5.value;

        var period = (cov_1n0ltttluz.s[109]++, [{ field: 'real_' + el.index, headerTextAlign: 'center', headerText: el.label, width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }]);

        cov_1n0ltttluz.s[110]++;
        if (doubleItems) {
          cov_1n0ltttluz.b[35][0]++;
          cov_1n0ltttluz.s[111]++;

          period = {
            headerTextAlign: 'center',
            headerText: el.label,
            width: 300,
            columns: [{ field: 'real_' + el.index, headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL'), width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'cost_' + el.index, headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.COST_ACTUAL'), width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }] };
        } else {
          cov_1n0ltttluz.b[35][1]++;
        }
        cov_1n0ltttluz.s[112]++;
        c = c.concat(period);
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }

    cov_1n0ltttluz.s[113]++;
    global = [{ field: 'real_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + ' ' + $scope.year.ANNEE_PERIOD, width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }];
    cov_1n0ltttluz.s[114]++;
    if (doubleItems) {
      cov_1n0ltttluz.b[36][0]++;
      cov_1n0ltttluz.s[115]++;

      global = {
        headerText: $scope.year.ANNEE_PERIOD,
        width: 300,
        columns: [{ field: 'real_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL'), width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'cost_13', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.COST_ACTUAL'), width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }] };
    } else {
      cov_1n0ltttluz.b[36][1]++;
    }
    cov_1n0ltttluz.s[116]++;
    c = c.concat(global);

    cov_1n0ltttluz.s[117]++;
    c.push({ field: 'rate_t', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET'), format: 'P2', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });

    cov_1n0ltttluz.s[118]++;
    c.push({ field: 'rate_b', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET'), format: 'P2', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });

    cov_1n0ltttluz.s[119]++;
    return c;
  };
  cov_1n0ltttluz.s[120]++;
  var processAnnualData = function processAnnualData(items) {
    cov_1n0ltttluz.f[8]++;

    var data = (cov_1n0ltttluz.s[121]++, []);
    var tmp_global = (cov_1n0ltttluz.s[122]++, 0);
    cov_1n0ltttluz.s[123]++;
    var _iteratorNormalCompletion6 = true;
    var _didIteratorError6 = false;
    var _iteratorError6 = undefined;

    try {
      for (var _iterator6 = items[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
        var item = _step6.value;

        var line = (cov_1n0ltttluz.s[124]++, { label: item.C + ': ' + item.D, graph: [] });
        var globalReal = (cov_1n0ltttluz.s[125]++, null);
        var globalCost = (cov_1n0ltttluz.s[126]++, null);
        cov_1n0ltttluz.s[127]++;
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = $deltadate.getYearPeriod($scope.year.ANNEE_PERIOD, $scope.year.DECOUPAGE_PERIOD > 0 ? (cov_1n0ltttluz.b[37][0]++, $scope.year.DECOUPAGE_PERIOD) : (cov_1n0ltttluz.b[37][1]++, 12))[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var period = _step7.value;

            var key = (cov_1n0ltttluz.s[128]++, period.index);
            var dataLine = (cov_1n0ltttluz.s[129]++, item.data[key]);
            cov_1n0ltttluz.s[130]++;
            if (!dataLine) {
              cov_1n0ltttluz.b[38][0]++;
              cov_1n0ltttluz.s[131]++;

              continue;
            } else {
              cov_1n0ltttluz.b[38][1]++;
            }

            var _plan2 = (cov_1n0ltttluz.s[132]++, dataLine ? (cov_1n0ltttluz.b[39][0]++, $deltaNumber.convertToNumber(dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[40][0]++, dataLine.QTE_PREVUE_RELV_REV) : (cov_1n0ltttluz.b[40][1]++, dataLine.QTE_PREVUE_RELV))) : (cov_1n0ltttluz.b[39][1]++, null));

            var _budget2 = (cov_1n0ltttluz.s[133]++, dataLine ? (cov_1n0ltttluz.b[41][0]++, $deltaNumber.convertToNumber(dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[42][0]++, dataLine.BUDGET_RBN_REV) : (cov_1n0ltttluz.b[42][1]++, dataLine.BUDGET_RBN))) : (cov_1n0ltttluz.b[41][1]++, null));

            var real = (cov_1n0ltttluz.s[134]++, dataLine ? (cov_1n0ltttluz.b[43][0]++, $deltaNumber.convertToNumber(dataLine.QTE_REELLE)) : (cov_1n0ltttluz.b[43][1]++, null));

            var cost = (cov_1n0ltttluz.s[135]++, dataLine ? (cov_1n0ltttluz.b[44][0]++, $deltaNumber.convertToNumber(dataLine.COUT_REEL)) : (cov_1n0ltttluz.b[44][1]++, null));

            var physicalRate = (cov_1n0ltttluz.s[136]++, (cov_1n0ltttluz.b[46][0]++, _plan2 == null) || (cov_1n0ltttluz.b[46][1]++, _plan2 == 0) || (cov_1n0ltttluz.b[46][2]++, real === null) ? (cov_1n0ltttluz.b[45][0]++, null) : (cov_1n0ltttluz.b[45][1]++, $deltaNumber.convertToNumber(real / _plan2, null, 4)));
            var financialRate = (cov_1n0ltttluz.s[137]++, (cov_1n0ltttluz.b[48][0]++, _budget2 == null) || (cov_1n0ltttluz.b[48][1]++, _budget2 == 0) || (cov_1n0ltttluz.b[48][2]++, cost === null) ? (cov_1n0ltttluz.b[47][0]++, null) : (cov_1n0ltttluz.b[47][1]++, $deltaNumber.convertToNumber(cost / _budget2, null, 4)));

            cov_1n0ltttluz.s[138]++;
            line.graph.push({
              label: period.label.toString(),
              physical: physicalRate,
              financial: financialRate
            });
            cov_1n0ltttluz.s[139]++;
            line['plan_' + key] = _plan2;
            cov_1n0ltttluz.s[140]++;
            line['budget_' + key] = _budget2;
            cov_1n0ltttluz.s[141]++;
            line['real_' + key] = real;
            cov_1n0ltttluz.s[142]++;
            line['cost_' + key] = cost;

            cov_1n0ltttluz.s[143]++;
            if (real !== null) {
              cov_1n0ltttluz.b[49][0]++;
              cov_1n0ltttluz.s[144]++;

              if ($scope.analyse.activity_processus) {
                cov_1n0ltttluz.b[50][0]++;
                cov_1n0ltttluz.s[145]++;

                tmp_global = (cov_1n0ltttluz.b[51][0]++, globalReal) || (cov_1n0ltttluz.b[51][1]++, 0);
                cov_1n0ltttluz.s[146]++;
                globalReal = tmp_global < real ? (cov_1n0ltttluz.b[52][0]++, real) : (cov_1n0ltttluz.b[52][1]++, tmp_global);
              } else {
                cov_1n0ltttluz.b[50][1]++;
                cov_1n0ltttluz.s[147]++;

                globalReal = tmp_global + real;
              }
            } else {
              cov_1n0ltttluz.b[49][1]++;
            }

            cov_1n0ltttluz.s[148]++;
            if (cost !== null) {
              cov_1n0ltttluz.b[53][0]++;
              cov_1n0ltttluz.s[149]++;

              globalCost = ((cov_1n0ltttluz.b[54][0]++, globalCost) || (cov_1n0ltttluz.b[54][1]++, 0)) + cost;
            } else {
              cov_1n0ltttluz.b[53][1]++;
            }
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        cov_1n0ltttluz.s[150]++;
        line.real_13 = globalReal;
        cov_1n0ltttluz.s[151]++;
        line.cost_13 = globalCost;

        var plan = (cov_1n0ltttluz.s[152]++, item.data[13] ? (cov_1n0ltttluz.b[55][0]++, $deltaNumber.convertToNumber(item.data[13].DATE_DEBUT_P_AN_REV ? (cov_1n0ltttluz.b[56][0]++, item.data[13].QTE_PREVUE_AN_REV) : (cov_1n0ltttluz.b[56][1]++, item.data[13].QTE_PREVUE_AN))) : (cov_1n0ltttluz.b[55][1]++, null));

        var budget = (cov_1n0ltttluz.s[153]++, item.data[13] ? (cov_1n0ltttluz.b[57][0]++, $deltaNumber.convertToNumber(item.data[13].DATE_DEBUT_P_AN_REV ? (cov_1n0ltttluz.b[58][0]++, item.data[13].BUDGET_PLAN_AN_REV) : (cov_1n0ltttluz.b[58][1]++, item.data[13].BUDGET_PLAN_AN))) : (cov_1n0ltttluz.b[57][1]++, null));
        cov_1n0ltttluz.s[154]++;
        line.plan_13 = plan;
        cov_1n0ltttluz.s[155]++;
        line.budget_13 = budget;

        cov_1n0ltttluz.s[156]++;
        line.rate_t = (cov_1n0ltttluz.b[60][0]++, globalReal === null) || (cov_1n0ltttluz.b[60][1]++, plan == null) ? (cov_1n0ltttluz.b[59][0]++, null) : (cov_1n0ltttluz.b[59][1]++, $deltaNumber.formatNumber(globalReal / plan, null, 4));
        cov_1n0ltttluz.s[157]++;
        line.rate_b = (cov_1n0ltttluz.b[62][0]++, globalCost === null) || (cov_1n0ltttluz.b[62][1]++, budget == null) ? (cov_1n0ltttluz.b[61][0]++, null) : (cov_1n0ltttluz.b[61][1]++, $deltaNumber.formatNumber(globalCost / budget, null, 4));

        cov_1n0ltttluz.s[158]++;
        data.push(line);
      }
    } catch (err) {
      _didIteratorError6 = true;
      _iteratorError6 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion6 && _iterator6.return) {
          _iterator6.return();
        }
      } finally {
        if (_didIteratorError6) {
          throw _iteratorError6;
        }
      }
    }

    cov_1n0ltttluz.s[159]++;
    return data;
  };
  cov_1n0ltttluz.s[160]++;
  var processAnnual = function processAnnual() {
    cov_1n0ltttluz.f[9]++;
    cov_1n0ltttluz.s[161]++;

    $CRUDService.getAll(PATHP, { get: 'concern_projects_by_id', id: $scope.analyse.idp }, function (data) {
      cov_1n0ltttluz.f[10]++;
      cov_1n0ltttluz.s[162]++;

      //$log.log('processAnnual concern_projects_by_id');
      //$log.log(data);

      $scope.project = data[0];
      cov_1n0ltttluz.s[163]++;
      $scope.year = data[0].PERIODS.find(function (period) {
        cov_1n0ltttluz.f[11]++;
        cov_1n0ltttluz.s[164]++;

        if ($scope.analyse.yearFull) {
          cov_1n0ltttluz.b[63][0]++;
          cov_1n0ltttluz.s[165]++;

          return period.ANNEE_PERIOD == $scope.analyse.yearFull;
        } else {
          cov_1n0ltttluz.b[63][1]++;
        }

        cov_1n0ltttluz.s[166]++;
        return period.id == $scope.analyse.year;
      });

      var param = (cov_1n0ltttluz.s[167]++, { get: 'dashboard_annual', activ: $scope.analyse.activity, valid: 0, idp: ((cov_1n0ltttluz.b[64][0]++, $scope.year) || (cov_1n0ltttluz.b[64][1]++, { id: 0 })).id });
      cov_1n0ltttluz.s[168]++;
      if ($scope.project.portfolio) {
        cov_1n0ltttluz.b[65][0]++;
        cov_1n0ltttluz.s[169]++;

        param.get = 'dashboard_annual_cross';
      } else {
        cov_1n0ltttluz.b[65][1]++;
      }

      cov_1n0ltttluz.s[170]++;
      $CRUDService.getAll(PATHP, param, function (allData) {
        cov_1n0ltttluz.f[12]++;
        cov_1n0ltttluz.s[171]++;

        //$log.log('processAnnual dashboard_annual');
        //$log.log(allData);

        if ($scope.analyse.TYPE == 1) {
          cov_1n0ltttluz.b[66][0]++;
          cov_1n0ltttluz.s[172]++;

          $scope.grid.columns = generateColumnsAnnual();
          cov_1n0ltttluz.s[173]++;
          $scope.grid.dataSource = processAnnualData(allData);
          cov_1n0ltttluz.s[174]++;
          $scope.grid.recordClick = function (args) {
            cov_1n0ltttluz.f[13]++;

            var event = (cov_1n0ltttluz.s[175]++, args.cell.getBoundingClientRect());
            var body = (cov_1n0ltttluz.s[176]++, $('body'));
            var width = (cov_1n0ltttluz.s[177]++, Math.min(600, body.width()));
            var height = (cov_1n0ltttluz.s[178]++, Math.min(500, body.height()));
            var left = (cov_1n0ltttluz.s[179]++, Math.min(event.x, body.width() - width));
            var top = (cov_1n0ltttluz.s[180]++, Math.min(event.y, body.height() - height));
            cov_1n0ltttluz.s[181]++;
            $scope.grid.panelPosition = { top: top - 100 + 'px', left: left - 100 + 'px', width: width + 'px', height: height + 'px' };
            cov_1n0ltttluz.s[182]++;
            $scope.grid.graphParams.series[0].dataSource = args.graph;
            cov_1n0ltttluz.s[183]++;
            $scope.grid.graphParams.series[1].dataSource = args.graph;
            cov_1n0ltttluz.s[184]++;
            $scope.grid.graphParams.title = $translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_FIRST').toUpperCase() + ' (' + args.label + ')';
            cov_1n0ltttluz.s[185]++;
            $scope.grid.graphParams.title_ = $translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_FIRST').toUpperCase() + ' (' + args.label + ')';
            cov_1n0ltttluz.s[186]++;
            $scope.grid.chart = true;
          };
          cov_1n0ltttluz.s[187]++;
          $scope.grid.displayGrid = true;
        } else {
          cov_1n0ltttluz.b[66][1]++;

          var graphs = (cov_1n0ltttluz.s[188]++, []);
          cov_1n0ltttluz.s[189]++;
          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = processAnnualData(allData)[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var item = _step8.value;
              cov_1n0ltttluz.s[190]++;

              graphs.push({
                label: item.label,
                physical: item.rate_t,
                financial: item.rate_b
              });
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }

          cov_1n0ltttluz.s[191]++;
          $scope.grid.graphParams.series[0].dataSource = graphs;
          cov_1n0ltttluz.s[192]++;
          $scope.grid.graphParams.series[1].dataSource = graphs;
          cov_1n0ltttluz.s[193]++;
          $scope.grid.graphParams.title = '';
          cov_1n0ltttluz.s[194]++;
          $scope.grid.graphParams.title_ = '';

          cov_1n0ltttluz.s[195]++;
          $scope.grid.displayChart = true;
        }
      });
    });
  };

  /* Periodique */
  cov_1n0ltttluz.s[196]++;
  var generateColumnsPeriod = function generateColumnsPeriod() {
    cov_1n0ltttluz.f[14]++;

    var doubleItems = (cov_1n0ltttluz.s[197]++, $scope.analyse.displaycost);
    var c = (cov_1n0ltttluz.s[198]++, [{ field: 'label', width: 300, headerText: $translate.instant($scope.project.portfolio ? (cov_1n0ltttluz.b[67][0]++, 'ACTIVITY.PROJECT') : (cov_1n0ltttluz.b[67][1]++, 'ACTIVITY.PLAN.LOCATION')), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'plan', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED'), format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'real', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL'), format: 'N', width: 100, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'rate_t', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET'), format: 'P', type: 'number', width: 100, textAlign: 'Right', clipMode: 'EllipsisWithTooltip' }, { field: 'db', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.BEGIN_PLANNED'), width: 150, format: { type: 'date', skeleton: 'medium' }, textAlign: 'Center', clipMode: 'EllipsisWithTooltip' }, { field: 'fb', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.END_PLANNED'), width: 150, format: { type: 'date', skeleton: 'medium' }, textAlign: 'Center', clipMode: 'EllipsisWithTooltip' }, { field: 'dr', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.BEGIN_ACTUAL'), width: 150, format: { type: 'date', skeleton: 'medium' }, textAlign: 'Center', clipMode: 'EllipsisWithTooltip' }, { field: 'fr', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.END_ACTUAL'), width: 150, format: { type: 'date', skeleton: 'medium' }, textAlign: 'Center', clipMode: 'EllipsisWithTooltip' }]);

    cov_1n0ltttluz.s[199]++;
    if (doubleItems) {
      cov_1n0ltttluz.b[68][0]++;
      cov_1n0ltttluz.s[200]++;

      c.push({ field: 'budget', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.PLAN.COST_PLANNED'), format: 'N', width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });
      cov_1n0ltttluz.s[201]++;
      c.push({ field: 'cost', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.REAL.COST_ACTUAL'), format: 'N', width: 150, type: 'number', textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });
      cov_1n0ltttluz.s[202]++;
      c.push({ field: 'rate_b', headerTextAlign: 'center', headerText: $translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET'), format: 'P', type: 'number', width: 100, textAlign: 'Right', clipMode: 'EllipsisWithTooltip' });
    } else {
      cov_1n0ltttluz.b[68][1]++;
    }

    cov_1n0ltttluz.s[203]++;
    if ($scope.comment) {
      cov_1n0ltttluz.b[69][0]++;
      cov_1n0ltttluz.s[204]++;

      c.push({
        field: 'comments', headerText: $translate.instant('ACTIVITY.COMMENT'), width: 200, textAlign: 'left', clipMode: 'EllipsisWithTooltip'
      });
    } else {
      cov_1n0ltttluz.b[69][1]++;
    }
    cov_1n0ltttluz.s[205]++;
    return c;
  };
  cov_1n0ltttluz.s[206]++;
  var processPeriodData = function processPeriodData(items) {
    cov_1n0ltttluz.f[15]++;

    var data = (cov_1n0ltttluz.s[207]++, []);
    var tmp_global = (cov_1n0ltttluz.s[208]++, 0);

    cov_1n0ltttluz.s[209]++;
    var _iteratorNormalCompletion9 = true;
    var _didIteratorError9 = false;
    var _iteratorError9 = undefined;

    try {
      for (var _iterator9 = items[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
        var item = _step9.value;

        var line = (cov_1n0ltttluz.s[210]++, { label: item.C + ': ' + item.D, graph: [], plan: null, budget: null, real: null, cost: null });
        var globalReal = (cov_1n0ltttluz.s[211]++, null);
        var globalCost = (cov_1n0ltttluz.s[212]++, null);

        cov_1n0ltttluz.s[213]++;
        var _iteratorNormalCompletion10 = true;
        var _didIteratorError10 = false;
        var _iteratorError10 = undefined;

        try {
          for (var _iterator10 = $deltadate.getYearPeriod($scope.year.ANNEE_PERIOD, $scope.year.DECOUPAGE_PERIOD)[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
            var period = _step10.value;

            var key = (cov_1n0ltttluz.s[214]++, period.index);
            cov_1n0ltttluz.s[215]++;
            if (key != $scope.analyse.period) {
              cov_1n0ltttluz.b[70][0]++;
              cov_1n0ltttluz.s[216]++;

              continue;
            } else {
              cov_1n0ltttluz.b[70][1]++;
            }

            cov_1n0ltttluz.s[217]++;
            if (!item.data[key]) {
              cov_1n0ltttluz.b[71][0]++;
              cov_1n0ltttluz.s[218]++;

              break;
            } else {
              cov_1n0ltttluz.b[71][1]++;
            }

            var dataLine = (cov_1n0ltttluz.s[219]++, item.data[key]);
            var plan = (cov_1n0ltttluz.s[220]++, dataLine ? (cov_1n0ltttluz.b[72][0]++, $deltaNumber.convertToNumber(dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[73][0]++, dataLine.QTE_PREVUE_RELV_REV) : (cov_1n0ltttluz.b[73][1]++, dataLine.QTE_PREVUE_RELV))) : (cov_1n0ltttluz.b[72][1]++, null));

            var budget = (cov_1n0ltttluz.s[221]++, dataLine ? (cov_1n0ltttluz.b[74][0]++, $deltaNumber.convertToNumber(dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[75][0]++, dataLine.BUDGET_RBN_REV) : (cov_1n0ltttluz.b[75][1]++, dataLine.BUDGET_RBN))) : (cov_1n0ltttluz.b[74][1]++, null));

            cov_1n0ltttluz.s[222]++;
            line.db = dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[76][0]++, dataLine.DATE_DEB_P_RELV_REV) : (cov_1n0ltttluz.b[76][1]++, dataLine.DATE_DEB_P_RELV);
            cov_1n0ltttluz.s[223]++;
            line.fb = dataLine.DATE_RELV_REVISE ? (cov_1n0ltttluz.b[77][0]++, dataLine.DATE_Fin_P_RELV_REV) : (cov_1n0ltttluz.b[77][1]++, dataLine.Date_Fin_P_RELV);
            cov_1n0ltttluz.s[224]++;
            line.dr = dataLine.Date_DEB_R_RELV;
            cov_1n0ltttluz.s[225]++;
            line.fr = dataLine.Date_Fin_R_RELV;

            cov_1n0ltttluz.s[226]++;
            if (line.db) {
              cov_1n0ltttluz.b[78][0]++;
              cov_1n0ltttluz.s[227]++;

              line.db = Date.newDate(line.db);
            } else {
              cov_1n0ltttluz.b[78][1]++;
            }

            cov_1n0ltttluz.s[228]++;
            if (line.fb) {
              cov_1n0ltttluz.b[79][0]++;
              cov_1n0ltttluz.s[229]++;

              line.fb = Date.newDate(line.fb);
            } else {
              cov_1n0ltttluz.b[79][1]++;
            }

            cov_1n0ltttluz.s[230]++;
            if (line.dr) {
              cov_1n0ltttluz.b[80][0]++;
              cov_1n0ltttluz.s[231]++;

              line.dr = Date.newDate(line.dr);
            } else {
              cov_1n0ltttluz.b[80][1]++;
            }

            cov_1n0ltttluz.s[232]++;
            if (line.fr) {
              cov_1n0ltttluz.b[81][0]++;
              cov_1n0ltttluz.s[233]++;

              line.fr = Date.newDate(line.fr);
            } else {
              cov_1n0ltttluz.b[81][1]++;
            }

            var real = (cov_1n0ltttluz.s[234]++, dataLine ? (cov_1n0ltttluz.b[82][0]++, $deltaNumber.convertToNumber(dataLine.QTE_REELLE)) : (cov_1n0ltttluz.b[82][1]++, null));

            var cost = (cov_1n0ltttluz.s[235]++, dataLine ? (cov_1n0ltttluz.b[83][0]++, $deltaNumber.convertToNumber(dataLine.COUT_REEL)) : (cov_1n0ltttluz.b[83][1]++, null));

            var physicalRate = (cov_1n0ltttluz.s[236]++, (cov_1n0ltttluz.b[85][0]++, plan == null) || (cov_1n0ltttluz.b[85][1]++, plan == 0) || (cov_1n0ltttluz.b[85][2]++, real === null) ? (cov_1n0ltttluz.b[84][0]++, null) : (cov_1n0ltttluz.b[84][1]++, $deltaNumber.convertToNumber(real / plan, null, 4)));
            var financialRate = (cov_1n0ltttluz.s[237]++, (cov_1n0ltttluz.b[87][0]++, budget == null) || (cov_1n0ltttluz.b[87][1]++, budget == 0) || (cov_1n0ltttluz.b[87][2]++, cost === null) ? (cov_1n0ltttluz.b[86][0]++, null) : (cov_1n0ltttluz.b[86][1]++, $deltaNumber.convertToNumber(cost / budget, null, 4)));

            cov_1n0ltttluz.s[238]++;
            line.graph.push({
              label: period.label.toString(),
              physical: physicalRate,
              financial: financialRate
            });
            cov_1n0ltttluz.s[239]++;
            line.plan = plan;
            cov_1n0ltttluz.s[240]++;
            line.budget = budget;
            cov_1n0ltttluz.s[241]++;
            line.real = real;
            cov_1n0ltttluz.s[242]++;
            line.cost = cost;

            cov_1n0ltttluz.s[243]++;
            if (real !== null) {
              cov_1n0ltttluz.b[88][0]++;
              cov_1n0ltttluz.s[244]++;

              if ($scope.analyse.activity_processus) {
                cov_1n0ltttluz.b[89][0]++;
                cov_1n0ltttluz.s[245]++;

                tmp_global = (cov_1n0ltttluz.b[90][0]++, globalReal) || (cov_1n0ltttluz.b[90][1]++, 0);
                cov_1n0ltttluz.s[246]++;
                globalReal = tmp_global < real ? (cov_1n0ltttluz.b[91][0]++, real) : (cov_1n0ltttluz.b[91][1]++, tmp_global);
              } else {
                cov_1n0ltttluz.b[89][1]++;
                cov_1n0ltttluz.s[247]++;

                globalReal = ((cov_1n0ltttluz.b[92][0]++, globalReal) || (cov_1n0ltttluz.b[92][1]++, 0)) + real;
              }
            } else {
              cov_1n0ltttluz.b[88][1]++;
            }

            cov_1n0ltttluz.s[248]++;
            if (cost !== null) {
              cov_1n0ltttluz.b[93][0]++;
              cov_1n0ltttluz.s[249]++;

              globalCost = ((cov_1n0ltttluz.b[94][0]++, globalCost) || (cov_1n0ltttluz.b[94][1]++, 0)) + cost;
            } else {
              cov_1n0ltttluz.b[93][1]++;
            }

            cov_1n0ltttluz.s[250]++;
            line.rate_t = (cov_1n0ltttluz.b[96][0]++, real === null) || (cov_1n0ltttluz.b[96][1]++, plan == null) ? (cov_1n0ltttluz.b[95][0]++, null) : (cov_1n0ltttluz.b[95][1]++, $deltaNumber.formatNumber(real / plan, null, 4));
            cov_1n0ltttluz.s[251]++;
            line.rate_b = (cov_1n0ltttluz.b[98][0]++, cost === null) || (cov_1n0ltttluz.b[98][1]++, budget == null) ? (cov_1n0ltttluz.b[97][0]++, null) : (cov_1n0ltttluz.b[97][1]++, $deltaNumber.formatNumber(cost / budget, null, 4));
            cov_1n0ltttluz.s[252]++;
            line.comments = (cov_1n0ltttluz.b[99][0]++, ((cov_1n0ltttluz.b[100][0]++, dataLine) || (cov_1n0ltttluz.b[100][1]++, { COMMENTS: '' })).COMMENTS) || (cov_1n0ltttluz.b[99][1]++, '');
            cov_1n0ltttluz.s[253]++;
            break;
          }
        } catch (err) {
          _didIteratorError10 = true;
          _iteratorError10 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion10 && _iterator10.return) {
              _iterator10.return();
            }
          } finally {
            if (_didIteratorError10) {
              throw _iteratorError10;
            }
          }
        }

        cov_1n0ltttluz.s[254]++;
        data.push(line);
      }
    } catch (err) {
      _didIteratorError9 = true;
      _iteratorError9 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion9 && _iterator9.return) {
          _iterator9.return();
        }
      } finally {
        if (_didIteratorError9) {
          throw _iteratorError9;
        }
      }
    }

    cov_1n0ltttluz.s[255]++;
    return data;
  };
  cov_1n0ltttluz.s[256]++;
  var processPeriod = function processPeriod() {
    cov_1n0ltttluz.f[16]++;
    cov_1n0ltttluz.s[257]++;

    $CRUDService.getAll(PATHP, { get: 'concern_projects_by_id', id: $scope.analyse.idp }, function (data) {
      cov_1n0ltttluz.f[17]++;
      cov_1n0ltttluz.s[258]++;

      //$log.log('processPeriod concern_projects_by_id');
      //$log.log(data);

      $scope.project = data[0];
      cov_1n0ltttluz.s[259]++;
      $scope.year = data[0].PERIODS.find(function (period) {
        cov_1n0ltttluz.f[18]++;
        cov_1n0ltttluz.s[260]++;

        return period.id == $scope.analyse.year;
      });
      var param = (cov_1n0ltttluz.s[261]++, { get: 'dashboard_annual', activ: $scope.analyse.activity, valid: 0, idp: $scope.year.id });
      cov_1n0ltttluz.s[262]++;
      if ($scope.project.portfolio) {
        cov_1n0ltttluz.b[101][0]++;
        cov_1n0ltttluz.s[263]++;

        param.get = 'dashboard_annual_cross';
      } else {
        cov_1n0ltttluz.b[101][1]++;
      }
      cov_1n0ltttluz.s[264]++;
      $CRUDService.getAll(PATHP, param, function (allData) {
        cov_1n0ltttluz.f[19]++;
        cov_1n0ltttluz.s[265]++;

        //$log.log('processPeriod dashboard_annual');
        //$log.log(allData);

        if ($scope.analyse.TYPE == 1) {
          cov_1n0ltttluz.b[102][0]++;
          cov_1n0ltttluz.s[266]++;

          $scope.grid.columns = generateColumnsPeriod();
          cov_1n0ltttluz.s[267]++;
          $scope.grid.dataSource = processPeriodData(allData);
          cov_1n0ltttluz.s[268]++;
          $scope.grid.displayGrid = true;
          cov_1n0ltttluz.s[269]++;
          $scope.grid.recordClick = function (args) {
            cov_1n0ltttluz.f[20]++;
          };
        } else {
          cov_1n0ltttluz.b[102][1]++;

          var graphs = (cov_1n0ltttluz.s[270]++, []);
          cov_1n0ltttluz.s[271]++;
          var _iteratorNormalCompletion11 = true;
          var _didIteratorError11 = false;
          var _iteratorError11 = undefined;

          try {
            for (var _iterator11 = processPeriodData(allData)[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
              var item = _step11.value;
              cov_1n0ltttluz.s[272]++;

              graphs.push({
                label: item.label,
                physical: item.rate_t,
                financial: item.rate_b
              });
            }
          } catch (err) {
            _didIteratorError11 = true;
            _iteratorError11 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion11 && _iterator11.return) {
                _iterator11.return();
              }
            } finally {
              if (_didIteratorError11) {
                throw _iteratorError11;
              }
            }
          }

          cov_1n0ltttluz.s[273]++;
          $scope.grid.graphParams.series[0].dataSource = graphs;
          cov_1n0ltttluz.s[274]++;
          $scope.grid.graphParams.series[1].dataSource = graphs;
          cov_1n0ltttluz.s[275]++;
          $scope.grid.graphParams.title = '';
          cov_1n0ltttluz.s[276]++;
          $scope.grid.graphParams.title_ = '';

          cov_1n0ltttluz.s[277]++;
          $scope.grid.displayChart = true;
        }
      });
    });
  };

  cov_1n0ltttluz.s[278]++;
  $scope.grid = {
    graphParams: {
      locale: $LOGINService.getLang(),
      // Initializing Primary X and Y Axis
      primaryXAxis: {
        // title: 'Years',
        interval: 1,
        labelIntersectAction: 'Rotate45',
        valueType: 'Category',
        majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
        majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
        lineStyle: { width: 0 }
      },
      primaryYAxis: {
        valueType: 'Double',
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
        minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
        labelFormat: 'P'
      },
      chartArea: {
        border: {
          width: 0
        }
      },
      useGroupingSeparator: true,
      // Initializing Chart Series
      series: [{ // FILL
        type: 'Column',
        dataSource: [],
        xName: 'label', yName: 'physical', name: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'),
        width: 3,
        fill: 'rgba(99, 181, 247, 0.7)'
      }, { // FILL
        type: $scope.analyse.FILL ? (cov_1n0ltttluz.b[103][0]++, 'Area') : (cov_1n0ltttluz.b[103][1]++, 'Line'),
        opacity: 0.5,
        dataSource: [],
        // dataLabel: {visible: true, position: 'Outside'},
        fill: 'rgba(210, 61, 88, 0.7)',
        xName: 'label', yName: 'financial', name: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'),
        width: 3,
        marker: {
          visible: true,
          width: 10,
          height: 10
        }
      }],
      textRender: function textRender(args, a, b) {},

      width: '100%',
      height: '90%',
      // Initializing Chart title
      title_: $translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_FIRST').toUpperCase(),
      legendSettings: {
        visible: true
      },
      tooltip: { enable: true, shared: true }
    },
    panelPosition: { top: 0, left: 0, width: 600, height: 500 },

    dataSource: [],
    columns: [],

    closeChart: function closeChart() {
      cov_1n0ltttluz.s[279]++;

      $scope.grid.chart = false;
    }
  };

  cov_1n0ltttluz.s[280]++;
  if ($scope.analyse.from === 'ptba') {
    cov_1n0ltttluz.b[104][0]++;
    cov_1n0ltttluz.s[281]++;

    if ($scope.analyse.TYPE == 3) {
      cov_1n0ltttluz.b[105][0]++;
      cov_1n0ltttluz.s[282]++;

      $CRUDService.getAll(PATH_CL, {
        get: 'getPaaba_',
        p_bksb_: $scope.analyse.idp
      }, function (data) {
        cov_1n0ltttluz.f[21]++;

        var data_ = (cov_1n0ltttluz.s[283]++, []);

        cov_1n0ltttluz.s[284]++;
        for (var key in data) {
          cov_1n0ltttluz.s[285]++;

          data_.push({
            x: data[key].name,
            pt: data[key].PT,
            pc: data[key].Pc
          });
        }

        cov_1n0ltttluz.s[286]++;
        $scope.grid.graphParams = {
          export_: 0,
          locale: $LOGINService.getLang(),
          // Initializing Primary X and Y Axis
          primaryXAxis: {
            // title: 'Years',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
            majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            maximumLabelWidth: 100
          },
          primaryYAxis: {
            valueType: 'Double',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
            minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
            labelFormat: 'P'
          },
          chartArea: {
            border: {
              width: 0
            }
          },
          useGroupingSeparator: true,
          axisLabelRender: function axisLabelRender(args, a, b) {
            var _ = (cov_1n0ltttluz.s[287]++, args.text.split(':'));
            cov_1n0ltttluz.s[288]++;
            args.text = _[0].trim();
          },

          // Initializing Chart Series
          series: [{ // FILL
            type: 'Column',
            dataSource: data_,
            xName: 'x', yName: 'pt', name: $translate.instant('PLAN_BUDGET_ACTIVITY.ACTUAL_RATE'),
            width: 3
          }, { // FILL
            fill: 'rgba(0,0,0,0.7)',
            type: 'Column',
            dataSource: data_,
            xName: 'x', yName: 'pc', name: $translate.instant('PLAN_BUDGET_ACTIVITY.BUDGET_RATE'),
            width: 3
          }],

          width: '100%',
          height: '100%',
          // Initializing Chart title
          title_: $translate.instant('PLAN_BUDGET_ACTIVITY.ACTIVITY_PROGRESS'),
          // crosshair: {enable: true, lineType: 'Vertical'},
          // Enable Legend
          legendSettings: {
            visible: true
          },
          tooltip: { enable: true, shared: false }
        };

        cov_1n0ltttluz.s[289]++;
        $scope.grid.displayChart = true;
      });
    } else {
      cov_1n0ltttluz.b[105][1]++;
      cov_1n0ltttluz.s[290]++;

      $CRUDService.getAll(PATH, {
        get: 'activity_state',
        p_bksb_: $scope.analyse.idp
      }, function (data) {
        cov_1n0ltttluz.f[22]++;

        var data_ = (cov_1n0ltttluz.s[291]++, []);

        cov_1n0ltttluz.s[292]++;
        for (var key in data) {
          var label = (cov_1n0ltttluz.s[293]++, '');
          cov_1n0ltttluz.s[294]++;
          switch (key) {
            case 'planned':
              cov_1n0ltttluz.b[106][0]++;
              cov_1n0ltttluz.s[295]++;

              label = $translate.instant('ACTIVITY.ONLY_PLAN');
              cov_1n0ltttluz.s[296]++;
              break;
            case 'completed':
              cov_1n0ltttluz.b[106][1]++;
              cov_1n0ltttluz.s[297]++;

              label = $translate.instant('ACTIVITY.COMPLETED_ACTIVITIES');
              cov_1n0ltttluz.s[298]++;
              break;
            case 'not_carried_out':
              cov_1n0ltttluz.b[106][2]++;
              cov_1n0ltttluz.s[299]++;

              label = $translate.instant('ACTIVITY.NOT_CARRIED_OUT_ACTIVITIES');
              cov_1n0ltttluz.s[300]++;
              break;
            case 'on_going':
              cov_1n0ltttluz.b[106][3]++;
              cov_1n0ltttluz.s[301]++;

              label = $translate.instant('ACTIVITY.ON_GOING_ACTIVITIES');
              cov_1n0ltttluz.s[302]++;
              break;
            default:
              cov_1n0ltttluz.b[106][4]++;
              cov_1n0ltttluz.s[303]++;

              label = '';
          }

          var count = (cov_1n0ltttluz.s[304]++, 0);

          cov_1n0ltttluz.s[305]++;
          if (!angular.isArray(data[key])) {
            cov_1n0ltttluz.b[107][0]++;
            cov_1n0ltttluz.s[306]++;

            count = Object.keys(data[key]).length;
          } else {
            cov_1n0ltttluz.b[107][1]++;
          }

          cov_1n0ltttluz.s[307]++;
          data_.push({ x: label, y: count });
        }

        cov_1n0ltttluz.s[308]++;
        $scope.grid.graphParams = {
          export_: 0,
          // Initializing Series
          series: [{
            type: 'Pie', dataSource: data_, xName: 'x', yName: 'y',
            dataLabel: { visible: true, position: 'Outside' }
          }], textRender: function textRender(args, a, b) {
            cov_1n0ltttluz.s[309]++;

            if (!args.extraData) {
              cov_1n0ltttluz.b[108][0]++;
              cov_1n0ltttluz.s[310]++;

              args.extraData = $filter('number')(args.text);
            } else {
              cov_1n0ltttluz.b[108][1]++;
            }
            cov_1n0ltttluz.s[311]++;
            args.text = args.point.y;

            cov_1n0ltttluz.s[312]++;
            args.border.width = 1;
            cov_1n0ltttluz.s[313]++;
            args.border.color = args.point.color;
            cov_1n0ltttluz.s[314]++;
            args.color = args.point.color;
          },

          legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
          title_: $scope.analyse.TITLE,
          // Initializing Tooltip
          tooltip: { enable: true, format: '${point.x} <br> ${point.percentage} %' },
          height: '100%',
          width: '100%'
        };

        cov_1n0ltttluz.s[315]++;
        $scope.grid.displayChart = true;
      });
    }
  } else {
    cov_1n0ltttluz.b[104][1]++;
  }
  cov_1n0ltttluz.s[316]++;
  if ($scope.analyse.from === 'funding') {
    cov_1n0ltttluz.b[109][0]++;
    cov_1n0ltttluz.s[317]++;

    $CRUDService.getAll(PATH_AGREEMENT, {
      get: 'all_by_agreement_yearly',
      id: $scope.analyse.idAgreement
    }, function (data) {
      cov_1n0ltttluz.f[23]++;
      cov_1n0ltttluz.s[318]++;

      if ($scope.analyse.TYPE == 3) {
        cov_1n0ltttluz.b[110][0]++;

        var totalAmount = (cov_1n0ltttluz.s[319]++, 0);
        var globalAmount = (cov_1n0ltttluz.s[320]++, $deltaNumber.convertToNumber(data.info.T, null));
        cov_1n0ltttluz.s[321]++;
        var _iteratorNormalCompletion12 = true;
        var _didIteratorError12 = false;
        var _iteratorError12 = undefined;

        try {
          for (var _iterator12 = data.data[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
            var item = _step12.value;
            cov_1n0ltttluz.s[322]++;

            item.amount = $deltaNumber.convertToNumber(item.amount, null);
            cov_1n0ltttluz.s[323]++;
            totalAmount += $deltaNumber.convertToNumber(item.amount, 0);
            cov_1n0ltttluz.s[324]++;
            item.rate = globalAmount ? (cov_1n0ltttluz.b[111][0]++, $deltaNumber.formatNumber(totalAmount / globalAmount, null, 2)) : (cov_1n0ltttluz.b[111][1]++, null);
          }
        } catch (err) {
          _didIteratorError12 = true;
          _iteratorError12 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion12 && _iterator12.return) {
              _iterator12.return();
            }
          } finally {
            if (_didIteratorError12) {
              throw _iteratorError12;
            }
          }
        }

        cov_1n0ltttluz.s[325]++;
        for (var i = data.data.length - 1; i >= 0; i--) {
          cov_1n0ltttluz.s[326]++;

          if (data.data[i].amount !== null) {
            cov_1n0ltttluz.b[112][0]++;
            cov_1n0ltttluz.s[327]++;

            break;
          } else {
            cov_1n0ltttluz.b[112][1]++;
          }
          cov_1n0ltttluz.s[328]++;
          data.data[i].rate = null;
        }
      } else {
        cov_1n0ltttluz.b[110][1]++;
      }

      cov_1n0ltttluz.s[329]++;
      $scope.grid.graphParams = $scope.analyse.TYPE == 3 ? (cov_1n0ltttluz.b[113][0]++, {
        export_: 0,
        locale: $LOGINService.getLang(),
        // Initializing Primary X and Y Axis
        primaryXAxis: {
          // title: 'Years',
          interval: 1,
          labelIntersectAction: 'Rotate45',
          valueType: 'Category',
          majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
          majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
          lineStyle: { width: 0 }
        },
        primaryYAxis: {
          valueType: 'Double',
          lineStyle: { width: 0 },
          majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
          minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
          labelFormat: 'P'
        },
        chartArea: {
          border: {
            width: 0
          }
        },
        useGroupingSeparator: true,
        // Initializing Chart Series
        series: [{ // FILL
          type: 'Line',
          opacity: 0.5,
          dataSource: data.data,
          fill: 'rgba(0, 15, 62, 0.7)',
          xName: 'year', yName: 'rate', // name: serie.TITLE,
          width: 3,
          marker: {
            visible: true,
            width: 10,
            height: 10
          }
        }],
        textRender: function textRender(args, a, b) {},

        width: '100%',
        height: '90%',
        // Initializing Chart title
        title_: $translate.instant('FUNDS.BAR_CHART').toUpperCase(),
        // Enable Legend
        legendSettings: {
          visible: false
        },
        tooltip: { enable: true, shared: false }
      }) : (cov_1n0ltttluz.b[113][1]++, {
        // Initializing Series
        export_: 0,
        series: [{
          type: 'Pie', dataSource: data.data.filter(function (value) {
            cov_1n0ltttluz.f[24]++;
            cov_1n0ltttluz.s[330]++;

            return value.amount > 0;
          }), xName: 'year', yName: 'amount',
          dataLabel: { visible: true, position: 'Outside' // , innerRadius: '30%'
          } }], textRender: function textRender(args) {
          cov_1n0ltttluz.s[331]++;

          if (!args.extraData) {
            cov_1n0ltttluz.b[114][0]++;
            cov_1n0ltttluz.s[332]++;

            args.extraData = $filter('number')(args.text);
          } else {
            cov_1n0ltttluz.b[114][1]++;
          }
          cov_1n0ltttluz.s[333]++;
          args.text = args.extraData + ' ' + data.info.CURRENCY;
        },

        legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
        title_: $translate.instant('FUNDS.PIE_CHART').toUpperCase(),
        // Initializing Tooltip
        tooltip: { enable: true, format: '${point.x} <br> ${point.percentage} %' },
        height: '90%',
        width: '100%'
      });

      cov_1n0ltttluz.s[334]++;
      $scope.grid.displayChart = true;

      /* {get: 'all_by_agreement', id: (this.selectedAgreement || {id: 0}).id} */
    });
  } else {
    cov_1n0ltttluz.b[109][1]++;

    var result = (cov_1n0ltttluz.s[335]++, $scope.analyse.step == 3 ? (cov_1n0ltttluz.b[115][0]++, processGlobal()) : (cov_1n0ltttluz.b[115][1]++, $scope.analyse.step == 2 ? (cov_1n0ltttluz.b[116][0]++, processAnnual()) : (cov_1n0ltttluz.b[116][1]++, processPeriod())));
  }
};

cov_1n0ltttluz.s[336]++;
angular.module('app').directive('dashboardTableActivityItem', function () {
  cov_1n0ltttluz.f[25]++;
  cov_1n0ltttluz.s[337]++;

  return {
    restrict: 'E',
    scope: {
      data: '=',
      idWidget: '=',
      analyse: '=',
      comment: '=',
      titleDashboard: '@'
    },
    templateUrl: 'app/views/common/dashboard-table-activity-item.html',
    controller: fn_table_activity_item
  };
});
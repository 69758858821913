'use strict';

var cov_21ttixyfs1 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/communities/communities.js',
      hash = '851853e58e445ee1c595c2550c50e76e64631008',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/communities/communities.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 228,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 37
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 5,
          column: 3
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 11
        }
      },
      '4': {
        start: {
          line: 6,
          column: 18
        },
        end: {
          line: 6,
          column: 37
        }
      },
      '5': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 28
        }
      },
      '6': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 45
        }
      },
      '7': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 30,
          column: 5
        }
      },
      '8': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 32,
          column: 30
        }
      },
      '9': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 34,
          column: 31
        }
      },
      '10': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '11': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 37,
          column: 49
        }
      },
      '12': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '13': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '14': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 69,
          column: 5
        }
      },
      '15': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '16': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 13
        }
      },
      '17': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 54,
          column: 26
        }
      },
      '18': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 36
        }
      },
      '19': {
        start: {
          line: 56,
          column: 17
        },
        end: {
          line: 56,
          column: 36
        }
      },
      '20': {
        start: {
          line: 57,
          column: 18
        },
        end: {
          line: 57,
          column: 20
        }
      },
      '21': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 26
        }
      },
      '22': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 63,
          column: 5
        }
      },
      '23': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 62,
          column: 7
        }
      },
      '24': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 29
        }
      },
      '25': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 25
        }
      },
      '26': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 68,
          column: 7
        }
      },
      '27': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 38
        }
      },
      '28': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 29
        }
      },
      '29': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 73,
          column: 5
        }
      },
      '30': {
        start: {
          line: 72,
          column: 4
        },
        end: {
          line: 72,
          column: 36
        }
      },
      '31': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 117,
          column: 5
        }
      },
      '32': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 22
        }
      },
      '33': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 81,
          column: 5
        }
      },
      '34': {
        start: {
          line: 79,
          column: 6
        },
        end: {
          line: 79,
          column: 39
        }
      },
      '35': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 13
        }
      },
      '36': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 88,
          column: 5
        }
      },
      '37': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 84,
          column: 137
        }
      },
      '38': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 86,
          column: 37
        }
      },
      '39': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 13
        }
      },
      '40': {
        start: {
          line: 90,
          column: 20
        },
        end: {
          line: 90,
          column: 23
        }
      },
      '41': {
        start: {
          line: 92,
          column: 4
        },
        end: {
          line: 97,
          column: 5
        }
      },
      '42': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      '43': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 94,
          column: 17
        }
      },
      '44': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 27
        }
      },
      '45': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 20
        }
      },
      '46': {
        start: {
          line: 100,
          column: 22
        },
        end: {
          line: 100,
          column: 60
        }
      },
      '47': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 101,
          column: 42
        }
      },
      '48': {
        start: {
          line: 102,
          column: 4
        },
        end: {
          line: 102,
          column: 26
        }
      },
      '49': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 116,
          column: 7
        }
      },
      '50': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 107,
          column: 7
        }
      },
      '51': {
        start: {
          line: 105,
          column: 8
        },
        end: {
          line: 105,
          column: 31
        }
      },
      '52': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 15
        }
      },
      '53': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 113,
          column: 9
        }
      },
      '54': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 114,
          column: 32
        }
      },
      '55': {
        start: {
          line: 115,
          column: 6
        },
        end: {
          line: 115,
          column: 22
        }
      },
      '56': {
        start: {
          line: 119,
          column: 2
        },
        end: {
          line: 162,
          column: 5
        }
      },
      '57': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 161,
          column: 7
        }
      },
      '58': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 132,
          column: 7
        }
      },
      '59': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 15
        }
      },
      '60': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 28
        }
      },
      '61': {
        start: {
          line: 134,
          column: 27
        },
        end: {
          line: 136,
          column: 7
        }
      },
      '62': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 160,
          column: 9
        }
      },
      '63': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 159,
          column: 9
        }
      },
      '64': {
        start: {
          line: 144,
          column: 10
        },
        end: {
          line: 148,
          column: 13
        }
      },
      '65': {
        start: {
          line: 149,
          column: 10
        },
        end: {
          line: 149,
          column: 33
        }
      },
      '66': {
        start: {
          line: 151,
          column: 10
        },
        end: {
          line: 156,
          column: 13
        }
      },
      '67': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 157,
          column: 36
        }
      },
      '68': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 158,
          column: 26
        }
      },
      '69': {
        start: {
          line: 166,
          column: 2
        },
        end: {
          line: 166,
          column: 37
        }
      },
      '70': {
        start: {
          line: 168,
          column: 2
        },
        end: {
          line: 182,
          column: 4
        }
      },
      '71': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 180,
          column: 46
        }
      },
      '72': {
        start: {
          line: 184,
          column: 2
        },
        end: {
          line: 189,
          column: 5
        }
      },
      '73': {
        start: {
          line: 185,
          column: 4
        },
        end: {
          line: 188,
          column: 7
        }
      },
      '74': {
        start: {
          line: 186,
          column: 6
        },
        end: {
          line: 186,
          column: 57
        }
      },
      '75': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 18
        }
      },
      '76': {
        start: {
          line: 191,
          column: 2
        },
        end: {
          line: 200,
          column: 5
        }
      },
      '77': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 192,
          column: 26
        }
      },
      '78': {
        start: {
          line: 193,
          column: 4
        },
        end: {
          line: 199,
          column: 7
        }
      },
      '79': {
        start: {
          line: 194,
          column: 6
        },
        end: {
          line: 194,
          column: 29
        }
      },
      '80': {
        start: {
          line: 195,
          column: 6
        },
        end: {
          line: 198,
          column: 9
        }
      },
      '81': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 196,
          column: 69
        }
      },
      '82': {
        start: {
          line: 197,
          column: 8
        },
        end: {
          line: 197,
          column: 20
        }
      },
      '83': {
        start: {
          line: 203,
          column: 19
        },
        end: {
          line: 208,
          column: 4
        }
      },
      '84': {
        start: {
          line: 209,
          column: 2
        },
        end: {
          line: 212,
          column: 4
        }
      },
      '85': {
        start: {
          line: 210,
          column: 4
        },
        end: {
          line: 210,
          column: 32
        }
      },
      '86': {
        start: {
          line: 211,
          column: 4
        },
        end: {
          line: 211,
          column: 22
        }
      },
      '87': {
        start: {
          line: 214,
          column: 2
        },
        end: {
          line: 219,
          column: 4
        }
      },
      '88': {
        start: {
          line: 215,
          column: 4
        },
        end: {
          line: 215,
          column: 87
        }
      },
      '89': {
        start: {
          line: 216,
          column: 4
        },
        end: {
          line: 216,
          column: 88
        }
      },
      '90': {
        start: {
          line: 217,
          column: 4
        },
        end: {
          line: 217,
          column: 47
        }
      },
      '91': {
        start: {
          line: 218,
          column: 4
        },
        end: {
          line: 218,
          column: 33
        }
      },
      '92': {
        start: {
          line: 220,
          column: 2
        },
        end: {
          line: 222,
          column: 4
        }
      },
      '93': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 33
        }
      },
      '94': {
        start: {
          line: 223,
          column: 2
        },
        end: {
          line: 225,
          column: 4
        }
      },
      '95': {
        start: {
          line: 224,
          column: 4
        },
        end: {
          line: 224,
          column: 37
        }
      },
      '96': {
        start: {
          line: 227,
          column: 2
        },
        end: {
          line: 227,
          column: 18
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 52
          },
          end: {
            line: 1,
            column: 53
          }
        },
        loc: {
          start: {
            line: 1,
            column: 161
          },
          end: {
            line: 228,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 36,
            column: 23
          },
          end: {
            line: 36,
            column: 24
          }
        },
        loc: {
          start: {
            line: 36,
            column: 29
          },
          end: {
            line: 38,
            column: 3
          }
        },
        line: 36
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 40,
            column: 27
          },
          end: {
            line: 40,
            column: 28
          }
        },
        loc: {
          start: {
            line: 40,
            column: 33
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 40
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 50,
            column: 28
          },
          end: {
            line: 50,
            column: 29
          }
        },
        loc: {
          start: {
            line: 50,
            column: 54
          },
          end: {
            line: 69,
            column: 3
          }
        },
        line: 50
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 65,
            column: 49
          },
          end: {
            line: 65,
            column: 50
          }
        },
        loc: {
          start: {
            line: 65,
            column: 55
          },
          end: {
            line: 68,
            column: 5
          }
        },
        line: 65
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 71,
            column: 29
          },
          end: {
            line: 71,
            column: 30
          }
        },
        loc: {
          start: {
            line: 71,
            column: 35
          },
          end: {
            line: 73,
            column: 3
          }
        },
        line: 71
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 75,
            column: 30
          },
          end: {
            line: 75,
            column: 31
          }
        },
        loc: {
          start: {
            line: 75,
            column: 36
          },
          end: {
            line: 117,
            column: 3
          }
        },
        line: 75
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 83,
            column: 36
          },
          end: {
            line: 83,
            column: 37
          }
        },
        loc: {
          start: {
            line: 83,
            column: 49
          },
          end: {
            line: 85,
            column: 5
          }
        },
        line: 83
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 103,
            column: 80
          },
          end: {
            line: 103,
            column: 81
          }
        },
        loc: {
          start: {
            line: 103,
            column: 88
          },
          end: {
            line: 116,
            column: 5
          }
        },
        line: 103
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 119,
            column: 28
          },
          end: {
            line: 119,
            column: 29
          }
        },
        loc: {
          start: {
            line: 119,
            column: 36
          },
          end: {
            line: 162,
            column: 3
          }
        },
        line: 119
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 129,
            column: 7
          },
          end: {
            line: 129,
            column: 8
          }
        },
        loc: {
          start: {
            line: 129,
            column: 20
          },
          end: {
            line: 161,
            column: 5
          }
        },
        line: 129
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 142,
            column: 87
          },
          end: {
            line: 142,
            column: 88
          }
        },
        loc: {
          start: {
            line: 142,
            column: 95
          },
          end: {
            line: 160,
            column: 7
          }
        },
        line: 142
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 184,
            column: 55
          },
          end: {
            line: 184,
            column: 56
          }
        },
        loc: {
          start: {
            line: 184,
            column: 63
          },
          end: {
            line: 189,
            column: 3
          }
        },
        line: 184
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 185,
            column: 28
          },
          end: {
            line: 185,
            column: 29
          }
        },
        loc: {
          start: {
            line: 185,
            column: 36
          },
          end: {
            line: 188,
            column: 5
          }
        },
        line: 185
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 191,
            column: 19
          },
          end: {
            line: 191,
            column: 20
          }
        },
        loc: {
          start: {
            line: 191,
            column: 25
          },
          end: {
            line: 200,
            column: 3
          }
        },
        line: 191
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 193,
            column: 44
          },
          end: {
            line: 193,
            column: 45
          }
        },
        loc: {
          start: {
            line: 193,
            column: 52
          },
          end: {
            line: 199,
            column: 5
          }
        },
        line: 193
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 195,
            column: 40
          },
          end: {
            line: 195,
            column: 41
          }
        },
        loc: {
          start: {
            line: 195,
            column: 48
          },
          end: {
            line: 198,
            column: 7
          }
        },
        line: 195
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 209,
            column: 31
          },
          end: {
            line: 209,
            column: 32
          }
        },
        loc: {
          start: {
            line: 209,
            column: 51
          },
          end: {
            line: 212,
            column: 3
          }
        },
        line: 209
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 214,
            column: 27
          },
          end: {
            line: 214,
            column: 28
          }
        },
        loc: {
          start: {
            line: 214,
            column: 70
          },
          end: {
            line: 219,
            column: 3
          }
        },
        line: 214
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 220,
            column: 25
          },
          end: {
            line: 220,
            column: 26
          }
        },
        loc: {
          start: {
            line: 220,
            column: 68
          },
          end: {
            line: 222,
            column: 3
          }
        },
        line: 220
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 223,
            column: 28
          },
          end: {
            line: 223,
            column: 29
          }
        },
        loc: {
          start: {
            line: 223,
            column: 54
          },
          end: {
            line: 225,
            column: 3
          }
        },
        line: 223
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }, {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 50,
            column: 36
          },
          end: {
            line: 50,
            column: 49
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 50,
            column: 44
          },
          end: {
            line: 50,
            column: 49
          }
        }],
        line: 50
      },
      '2': {
        loc: {
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }, {
          start: {
            line: 51,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }],
        line: 51
      },
      '3': {
        loc: {
          start: {
            line: 59,
            column: 26
          },
          end: {
            line: 59,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 26
          },
          end: {
            line: 59,
            column: 38
          }
        }, {
          start: {
            line: 59,
            column: 42
          },
          end: {
            line: 59,
            column: 44
          }
        }],
        line: 59
      },
      '4': {
        loc: {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }, {
          start: {
            line: 60,
            column: 6
          },
          end: {
            line: 62,
            column: 7
          }
        }],
        line: 60
      },
      '5': {
        loc: {
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }, {
          start: {
            line: 78,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }],
        line: 78
      },
      '6': {
        loc: {
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 78,
            column: 85
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 78,
            column: 44
          }
        }, {
          start: {
            line: 78,
            column: 48
          },
          end: {
            line: 78,
            column: 85
          }
        }],
        line: 78
      },
      '7': {
        loc: {
          start: {
            line: 83,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 83,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        }, {
          start: {
            line: 83,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        }],
        line: 83
      },
      '8': {
        loc: {
          start: {
            line: 84,
            column: 13
          },
          end: {
            line: 84,
            column: 136
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 84,
            column: 13
          },
          end: {
            line: 84,
            column: 89
          }
        }, {
          start: {
            line: 84,
            column: 93
          },
          end: {
            line: 84,
            column: 136
          }
        }],
        line: 84
      },
      '9': {
        loc: {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }, {
          start: {
            line: 93,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }],
        line: 93
      },
      '10': {
        loc: {
          start: {
            line: 93,
            column: 10
          },
          end: {
            line: 93,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 93,
            column: 10
          },
          end: {
            line: 93,
            column: 21
          }
        }, {
          start: {
            line: 93,
            column: 25
          },
          end: {
            line: 93,
            column: 66
          }
        }],
        line: 93
      },
      '11': {
        loc: {
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }, {
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }],
        line: 104
      },
      '12': {
        loc: {
          start: {
            line: 110,
            column: 36
          },
          end: {
            line: 110,
            column: 111
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 110,
            column: 55
          },
          end: {
            line: 110,
            column: 83
          }
        }, {
          start: {
            line: 110,
            column: 86
          },
          end: {
            line: 110,
            column: 111
          }
        }],
        line: 110
      },
      '13': {
        loc: {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }, {
          start: {
            line: 130,
            column: 6
          },
          end: {
            line: 132,
            column: 7
          }
        }],
        line: 130
      },
      '14': {
        loc: {
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 159,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 159,
            column: 9
          }
        }, {
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 159,
            column: 9
          }
        }],
        line: 143
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    b: {
      '0': [0, 0],
      '1': [0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_21ttixyfs1.s[0]++;
angular.module('app').controller('communitiesCtrl', function ($scope, $stateParams, SweetAlert, $rootScope, toast, $translate, $deltahttp, $CRUDService, FileUploader) {
  cov_21ttixyfs1.f[0]++;
  cov_21ttixyfs1.s[1]++;

  $rootScope.processPageRight('5_5');
  cov_21ttixyfs1.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_21ttixyfs1.b[0][0]++;
    cov_21ttixyfs1.s[3]++;

    return;
  } else {
    cov_21ttixyfs1.b[0][1]++;
  }
  var KEYVIEW = (cov_21ttixyfs1.s[4]++, 'DOCUMENT_KEY_VIEW');
  var PATH = (cov_21ttixyfs1.s[5]++, 'communities');

  cov_21ttixyfs1.s[6]++;
  $scope.refreshimg = new Date().getTime();
  cov_21ttixyfs1.s[7]++;
  ej.base.L10n.load({
    en: {
      grid: {
        item: 'User',
        items: 'users'
      },
      pager: {
        totalItemsInfo: '({0} users)'
      }
    },
    fr: {
      grid: {
        item: 'utilisateur',
        items: 'utilisateurs'
      },
      pager: {
        totalItemsInfo: '({0} utilisateurs)'
      }
    }

  });

  cov_21ttixyfs1.s[8]++;
  $scope.communitiesList = [];

  cov_21ttixyfs1.s[9]++;
  $scope.displayAsList = false;

  cov_21ttixyfs1.s[10]++;
  $scope.changeView = function () {
    cov_21ttixyfs1.f[1]++;
    cov_21ttixyfs1.s[11]++;

    $scope.displayAsList = !$scope.displayAsList;
  };

  cov_21ttixyfs1.s[12]++;
  $scope.addCommunities = function () {
    cov_21ttixyfs1.f[2]++;
    cov_21ttixyfs1.s[13]++;

    $scope.editCommunities({
      NAME: '',
      DESCRIPTION: '',
      MEMBERS: '',
      id: 0,
      urlImage: $deltahttp.getCommunitiesRepository(0)
    });
  };

  cov_21ttixyfs1.s[14]++;
  $scope.editCommunities = function (item_) {
    var right = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_21ttixyfs1.b[1][0]++, false);
    cov_21ttixyfs1.f[3]++;
    cov_21ttixyfs1.s[15]++;

    if (right) {
      cov_21ttixyfs1.b[2][0]++;
      cov_21ttixyfs1.s[16]++;

      return;
    } else {
      cov_21ttixyfs1.b[2][1]++;
    }
    cov_21ttixyfs1.s[17]++;
    $scope.loading = true;
    cov_21ttixyfs1.s[18]++;
    $scope.selectedCommunity = null;
    var item = (cov_21ttixyfs1.s[19]++, angular.copy(item_));
    var users = (cov_21ttixyfs1.s[20]++, {});
    cov_21ttixyfs1.s[21]++;
    $scope.tempImg = null;
    cov_21ttixyfs1.s[22]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = ((cov_21ttixyfs1.b[3][0]++, item.MEMBERS) || (cov_21ttixyfs1.b[3][1]++, '')).split(';')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var userid = _step.value;
        cov_21ttixyfs1.s[23]++;

        if (userid > 0) {
          cov_21ttixyfs1.b[4][0]++;
          cov_21ttixyfs1.s[24]++;

          users[userid] = true;
        } else {
          cov_21ttixyfs1.b[4][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_21ttixyfs1.s[25]++;
    item.MEMBERS = users;
    cov_21ttixyfs1.s[26]++;
    $CRUDService.save(PATH, { action: 'suppimg' }, function () {
      cov_21ttixyfs1.f[4]++;
      cov_21ttixyfs1.s[27]++;

      $scope.selectedCommunity = item;
      cov_21ttixyfs1.s[28]++;
      $scope.loading = false;
    });
  };

  cov_21ttixyfs1.s[29]++;
  $scope.closeCommunities = function () {
    cov_21ttixyfs1.f[5]++;
    cov_21ttixyfs1.s[30]++;

    $scope.selectedCommunity = null;
  };

  cov_21ttixyfs1.s[31]++;
  $scope.validateCommunity = function () {
    cov_21ttixyfs1.f[6]++;
    cov_21ttixyfs1.s[32]++;

    $scope.error = {};

    cov_21ttixyfs1.s[33]++;
    if ((cov_21ttixyfs1.b[6][0]++, $scope.selectedCommunity.NAME === '') || (cov_21ttixyfs1.b[6][1]++, $scope.selectedCommunity.NAME == null)) {
      cov_21ttixyfs1.b[5][0]++;
      cov_21ttixyfs1.s[34]++;

      $scope.error.requiredName = true;
      cov_21ttixyfs1.s[35]++;
      return;
    } else {
      cov_21ttixyfs1.b[5][1]++;
    }

    cov_21ttixyfs1.s[36]++;
    if ($scope.communitiesList.find(function (community) {
      cov_21ttixyfs1.f[7]++;
      cov_21ttixyfs1.s[37]++;

      return (cov_21ttixyfs1.b[8][0]++, community.NAME.toUpperCase() === $scope.selectedCommunity.NAME.toUpperCase()) && (cov_21ttixyfs1.b[8][1]++, $scope.selectedCommunity.id != community.id);
    })) {
      cov_21ttixyfs1.b[7][0]++;
      cov_21ttixyfs1.s[38]++;

      $scope.error.doubleName = true;
      cov_21ttixyfs1.s[39]++;
      return;
    } else {
      cov_21ttixyfs1.b[7][1]++;
    }

    var members = (cov_21ttixyfs1.s[40]++, [0]);

    cov_21ttixyfs1.s[41]++;
    for (var userid in $scope.selectedCommunity.MEMBERS) {
      cov_21ttixyfs1.s[42]++;

      if ((cov_21ttixyfs1.b[10][0]++, userid <= 0) || (cov_21ttixyfs1.b[10][1]++, !$scope.selectedCommunity.MEMBERS[userid])) {
        cov_21ttixyfs1.b[9][0]++;
        cov_21ttixyfs1.s[43]++;

        continue;
      } else {
        cov_21ttixyfs1.b[9][1]++;
      }
      cov_21ttixyfs1.s[44]++;
      members.push(userid);
    }
    cov_21ttixyfs1.s[45]++;
    members.push(0);

    var community = (cov_21ttixyfs1.s[46]++, angular.copy($scope.selectedCommunity));
    cov_21ttixyfs1.s[47]++;
    community.MEMBERS = members.join(';');
    cov_21ttixyfs1.s[48]++;
    $scope.loading = true;
    cov_21ttixyfs1.s[49]++;
    $CRUDService.save(PATH, { action: 'save', value: angular.toJson(community) }, function (data) {
      cov_21ttixyfs1.f[8]++;
      cov_21ttixyfs1.s[50]++;

      if (data < 0) {
        cov_21ttixyfs1.b[11][0]++;
        cov_21ttixyfs1.s[51]++;

        $scope.loading = false;
        cov_21ttixyfs1.s[52]++;
        return;
      } else {
        cov_21ttixyfs1.b[11][1]++;
      }
      cov_21ttixyfs1.s[53]++;
      toast.create({
        timeout: 5 * 1000,
        message: $translate.instant(community.id > 0 ? (cov_21ttixyfs1.b[12][0]++, 'COMMUNITIES.SUCCESS_UPDATE') : (cov_21ttixyfs1.b[12][1]++, 'COMMUNITIES.SUCCESS_ADD')),
        className: 'alert-success',
        dismissible: true
      });
      cov_21ttixyfs1.s[54]++;
      $scope.closeCommunities();
      cov_21ttixyfs1.s[55]++;
      $scope.getAll();
    });
  };

  cov_21ttixyfs1.s[56]++;
  $scope.deleteCommunity = function (item) {
    cov_21ttixyfs1.f[9]++;
    cov_21ttixyfs1.s[57]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_21ttixyfs1.f[10]++;
      cov_21ttixyfs1.s[58]++;

      if (isconfirm) {
        cov_21ttixyfs1.b[13][0]++;
        cov_21ttixyfs1.s[59]++;

        return;
      } else {
        cov_21ttixyfs1.b[13][1]++;
      }
      cov_21ttixyfs1.s[60]++;
      $scope.loading = true;
      var validateItem = (cov_21ttixyfs1.s[61]++, {
        id: item.id
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_21ttixyfs1.s[62]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: angular.toJson(validateItem) }, function (data) {
        cov_21ttixyfs1.f[11]++;
        cov_21ttixyfs1.s[63]++;

        if (data < 0) {
          cov_21ttixyfs1.b[14][0]++;
          cov_21ttixyfs1.s[64]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_21ttixyfs1.s[65]++;
          $scope.loading = false;
        } else {
          cov_21ttixyfs1.b[14][1]++;
          cov_21ttixyfs1.s[66]++;

          toast.create({
            timeout: 5 * 1000,
            message: $translate.instant('COMMUNITIES.SUCCESS_DELETE'),
            className: 'alert-success',
            dismissible: true
          });
          cov_21ttixyfs1.s[67]++;
          $scope.closeCommunities();
          cov_21ttixyfs1.s[68]++;
          $scope.getAll();
        }
      });
    });
  };
  // $scope.filterType = { type: 'filterbar', showFilterBarStatus: true };
  // $scope.filterType = {type: 'e-filterbar'};
  // $scope.filterType = {type: 'Excel'};
  cov_21ttixyfs1.s[69]++;
  $scope.filterType = { type: 'Menu' };
  // $scope.filterType = 'filterbar';
  cov_21ttixyfs1.s[70]++;
  $scope.userTableParams = {
    allowPaging: false,
    allowFiltering: true,
    toolbar: ['Search'],
    searchSettings: { fields: ['name'], operator: 'contains', key: $translate.instant('COMMON.SEARCH'), ignoreCase: true },
    columns: [{ field: 'id', headerText: '', isPrimaryKey: true, visible: false }, { headerText: '', template: '<div><img src="${imageUrl}" class="img img-sm img-circle" /></div>', width: 60 }, { field: 'name', headerText: $translate.instant('DOCUMENT.USERS') }, { type: 'checkbox', width: 60 }],
    selectItemChange: function selectItemChange(data) {
      cov_21ttixyfs1.s[71]++;

      $scope.selectedCommunity.MEMBERS = data;
    }
  };

  cov_21ttixyfs1.s[72]++;
  $CRUDService.getAll('User', { get: 'all', simple: 1 }, function (data) {
    cov_21ttixyfs1.f[12]++;
    cov_21ttixyfs1.s[73]++;

    $scope.users = data.map(function (user) {
      cov_21ttixyfs1.f[13]++;
      cov_21ttixyfs1.s[74]++;

      user.imageUrl = $deltahttp.getUserPicture(user.id);
      cov_21ttixyfs1.s[75]++;
      return user;
    });
  });

  cov_21ttixyfs1.s[76]++;
  $scope.getAll = function () {
    cov_21ttixyfs1.f[14]++;
    cov_21ttixyfs1.s[77]++;

    $scope.loading = true;
    cov_21ttixyfs1.s[78]++;
    $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
      cov_21ttixyfs1.f[15]++;
      cov_21ttixyfs1.s[79]++;

      $scope.loading = false;
      cov_21ttixyfs1.s[80]++;
      $scope.communitiesList = data.map(function (item) {
        cov_21ttixyfs1.f[16]++;
        cov_21ttixyfs1.s[81]++;

        item.urlImage = $deltahttp.getCommunitiesRepository(item.id);
        cov_21ttixyfs1.s[82]++;
        return item;
      });
    });
  };

  // uploadfile
  var uploader = (cov_21ttixyfs1.s[83]++, $scope.uploader = new FileUploader({
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadimgFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH,
    headers: $CRUDService.getHeader(),
    alias: 'fileImage',
    removeAfterUpload: true
  }));
  cov_21ttixyfs1.s[84]++;
  uploader.onAfterAddingFile = function (fileItem) {
    cov_21ttixyfs1.f[17]++;
    cov_21ttixyfs1.s[85]++;

    $scope.loadingUpload = true;
    cov_21ttixyfs1.s[86]++;
    fileItem.upload();
  };

  cov_21ttixyfs1.s[87]++;
  uploader.onSuccessItem = function (item, response, status, headers) {
    cov_21ttixyfs1.f[18]++;
    cov_21ttixyfs1.s[88]++;

    $scope.selectedCommunity.tempImg = $deltahttp.getCommunitiesRepository(response.f);
    cov_21ttixyfs1.s[89]++;
    $scope.selectedCommunity.urlImage = $deltahttp.getCommunitiesRepository(response.f);
    cov_21ttixyfs1.s[90]++;
    $scope.refreshimg = new Date().getTime();
    cov_21ttixyfs1.s[91]++;
    $scope.loadingUpload = false;
  };
  cov_21ttixyfs1.s[92]++;
  uploader.onErrorItem = function (item, response, status, headers) {
    cov_21ttixyfs1.f[19]++;
    cov_21ttixyfs1.s[93]++;

    $scope.loadingUpload = false;
  };
  cov_21ttixyfs1.s[94]++;
  uploader.onProgressItem = function (item, progress) {
    cov_21ttixyfs1.f[20]++;
    cov_21ttixyfs1.s[95]++;

    $scope.uploadProgress = progress;
  };

  cov_21ttixyfs1.s[96]++;
  $scope.getAll();
});
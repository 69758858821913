"use strict";

var cov_2e1mcjpip3 = function () {
  var path = "/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/assets/js/plugins/leaflet.wms.js",
      hash = "fed68a7c95039503caabb933f9aedacd671e939c",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/assets/js/plugins/leaflet.wms.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();
'use strict';

var cov_2bkpv7mhxp = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logframe/logFrameCtrl.js',
      hash = '06100338ee2a0e3c54a9db9411f1998f38749b9d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/logframe/logFrameCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 75,
          column: 3
        }
      },
      '1': {
        start: {
          line: 4,
          column: 23
        },
        end: {
          line: 4,
          column: 31
        }
      },
      '2': {
        start: {
          line: 5,
          column: 28
        },
        end: {
          line: 5,
          column: 75
        }
      },
      '3': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 18
        }
      },
      '4': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 23
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '6': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 32
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '8': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 33
        }
      },
      '9': {
        start: {
          line: 22,
          column: 11
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '10': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 33
        }
      },
      '11': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 33
        }
      },
      '12': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 26
        }
      },
      '13': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '14': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 30,
          column: 9
        }
      },
      '15': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 29,
          column: 30
        }
      },
      '16': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '17': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 43
        }
      },
      '18': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 37,
          column: 9
        }
      },
      '19': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 17
        }
      },
      '20': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 43
        }
      },
      '21': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 42,
          column: 9
        }
      },
      '22': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 17
        }
      },
      '23': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 61
        }
      },
      '24': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 34
        }
      },
      '25': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 49,
          column: 42
        }
      },
      '26': {
        start: {
          line: 51,
          column: 2
        },
        end: {
          line: 51,
          column: 51
        }
      },
      '27': {
        start: {
          line: 53,
          column: 2
        },
        end: {
          line: 62,
          column: 4
        }
      },
      '28': {
        start: {
          line: 64,
          column: 2
        },
        end: {
          line: 64,
          column: 25
        }
      },
      '29': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '30': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 69,
          column: 7
        }
      },
      '31': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 52
        }
      },
      '32': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 15
        }
      },
      '33': {
        start: {
          line: 72,
          column: 2
        },
        end: {
          line: 74,
          column: 5
        }
      },
      '34': {
        start: {
          line: 73,
          column: 4
        },
        end: {
          line: 73,
          column: 79
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 49
          },
          end: {
            line: 2,
            column: 50
          }
        },
        loc: {
          start: {
            line: 3,
            column: 144
          },
          end: {
            line: 75,
            column: 1
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 9,
            column: 39
          },
          end: {
            line: 9,
            column: 40
          }
        },
        loc: {
          start: {
            line: 9,
            column: 45
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 65,
            column: 56
          },
          end: {
            line: 65,
            column: 57
          }
        },
        loc: {
          start: {
            line: 65,
            column: 64
          },
          end: {
            line: 70,
            column: 3
          }
        },
        line: 65
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 66,
            column: 33
          },
          end: {
            line: 66,
            column: 34
          }
        },
        loc: {
          start: {
            line: 66,
            column: 38
          },
          end: {
            line: 69,
            column: 5
          }
        },
        line: 66
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 72,
            column: 62
          },
          end: {
            line: 72,
            column: 63
          }
        },
        loc: {
          start: {
            line: 72,
            column: 70
          },
          end: {
            line: 74,
            column: 3
          }
        },
        line: 72
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }, {
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 11
          },
          end: {
            line: 44,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 11
          },
          end: {
            line: 44,
            column: 5
          }
        }, {
          start: {
            line: 22,
            column: 11
          },
          end: {
            line: 44,
            column: 5
          }
        }],
        line: 22
      },
      '2': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }],
        line: 27
      },
      '3': {
        loc: {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }, {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 30,
            column: 9
          }
        }],
        line: 28
      },
      '4': {
        loc: {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }, {
          start: {
            line: 33,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }],
        line: 33
      },
      '5': {
        loc: {
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }, {
          start: {
            line: 35,
            column: 8
          },
          end: {
            line: 37,
            column: 9
          }
        }],
        line: 35
      },
      '6': {
        loc: {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        }, {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        }],
        line: 40
      },
      '7': {
        loc: {
          start: {
            line: 73,
            column: 38
          },
          end: {
            line: 73,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 39
          },
          end: {
            line: 73,
            column: 56
          }
        }, {
          start: {
            line: 73,
            column: 62
          },
          end: {
            line: 73,
            column: 77
          }
        }],
        line: 73
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2bkpv7mhxp.s[0]++;

angular.module('app').controller('logFrameCtrl', function ($scope, $deltaLogLevel, $stateParams, $state, $rootScope, $filter, $deltaPlanLogique, $translate, $deltaTypeActivity, $deltahttp, FileUploader, $CRUDService) {
  cov_2bkpv7mhxp.f[0]++;

  var PATH_PROJECT = (cov_2bkpv7mhxp.s[1]++, 'Projet');
  var LOG_FRAME_LAST_ID = (cov_2bkpv7mhxp.s[2]++, 'log_frame_last_item_' + $deltahttp.getProjet());
  cov_2bkpv7mhxp.s[3]++;
  $scope.myID = 0;
  cov_2bkpv7mhxp.s[4]++;
  $scope.activity = -1;

  cov_2bkpv7mhxp.s[5]++;
  $deltaLogLevel.addController($scope, function () {
    cov_2bkpv7mhxp.f[1]++;
    cov_2bkpv7mhxp.s[6]++;

    /* if ($scope.listData_LogLevels_bksb.length == 0) {
      if($stateParams.id !== 'framework_1' && $stateParams.id !== 'framework_2'){
        $state.go('logframe.information', {id: 'framework_1'});
        return;
      }
    } */

    // $scope.activity = 0;

    $scope.resultframework = -1;
    cov_2bkpv7mhxp.s[7]++;
    if ($stateParams.id == 'framework_1') {
      cov_2bkpv7mhxp.b[0][0]++;
      cov_2bkpv7mhxp.s[8]++;

      $scope.resultframework = 1;
    } else {
        cov_2bkpv7mhxp.b[0][1]++;
        cov_2bkpv7mhxp.s[9]++;
        if ($stateParams.id == 'framework_2') {
          cov_2bkpv7mhxp.b[1][0]++;
          cov_2bkpv7mhxp.s[10]++;

          $scope.resultframework = 2;
        } else {
          cov_2bkpv7mhxp.b[1][1]++;
          cov_2bkpv7mhxp.s[11]++;

          $scope.resultframework = 0;
          cov_2bkpv7mhxp.s[12]++;
          $scope.activity = 0;
          cov_2bkpv7mhxp.s[13]++;
          if ($scope.listData_LogLevels_bksb.length > 0) {
            cov_2bkpv7mhxp.b[2][0]++;
            cov_2bkpv7mhxp.s[14]++;

            if ($scope.listData_LogLevels_bksb[$scope.listData_LogLevels_bksb.length - 1].id == $stateParams.id) {
              cov_2bkpv7mhxp.b[3][0]++;
              cov_2bkpv7mhxp.s[15]++;

              $scope.activity = 1;
            } else {
              cov_2bkpv7mhxp.b[3][1]++;
            }
          } else {
            cov_2bkpv7mhxp.b[2][1]++;
          }

          cov_2bkpv7mhxp.s[16]++;
          if ($scope.activity == 1) {
            cov_2bkpv7mhxp.b[4][0]++;
            cov_2bkpv7mhxp.s[17]++;

            $rootScope.processPageRight('2_2');
            cov_2bkpv7mhxp.s[18]++;
            if ($rootScope.global_access_page_denied) {
              cov_2bkpv7mhxp.b[5][0]++;
              cov_2bkpv7mhxp.s[19]++;

              return;
            } else {
              cov_2bkpv7mhxp.b[5][1]++;
            }
          } else {
            cov_2bkpv7mhxp.b[4][1]++;
            cov_2bkpv7mhxp.s[20]++;

            $rootScope.processPageRight('3_2');
            cov_2bkpv7mhxp.s[21]++;
            if ($rootScope.global_access_page_denied) {
              cov_2bkpv7mhxp.b[6][0]++;
              cov_2bkpv7mhxp.s[22]++;

              return;
            } else {
              cov_2bkpv7mhxp.b[6][1]++;
            }
          }
        }
      }cov_2bkpv7mhxp.s[23]++;
    localStorage.setItem(LOG_FRAME_LAST_ID, $stateParams.id);
    cov_2bkpv7mhxp.s[24]++;
    $scope.myID = $stateParams.id;
  });
  cov_2bkpv7mhxp.s[25]++;
  $deltaPlanLogique.addController($scope);

  cov_2bkpv7mhxp.s[26]++;
  $scope.portfolio = $deltahttp.getProjet() == 0;

  cov_2bkpv7mhxp.s[27]++;
  $scope.choise = {
    projectSelected: {
      LIBELLE_PROJET: $translate.instant('COMMON.ALL'),
      id: 0
    },
    agencySelected: {
      NAME_AG: $translate.instant('COMMON.ALL'),
      id: 0
    }
  };

  cov_2bkpv7mhxp.s[28]++;
  $scope.listProjet = [];
  cov_2bkpv7mhxp.s[29]++;
  $CRUDService.getAll(PATH_PROJECT, { get: 'all_short' }, function (data) {
    cov_2bkpv7mhxp.f[2]++;
    cov_2bkpv7mhxp.s[30]++;

    $scope.listProjet = data.map(function (d) {
      cov_2bkpv7mhxp.f[3]++;
      cov_2bkpv7mhxp.s[31]++;

      d.img = $deltahttp.getProjectRepository(d.id);
      cov_2bkpv7mhxp.s[32]++;
      return d;
    });
  });

  cov_2bkpv7mhxp.s[33]++;
  $CRUDService.getAll('Projet', { get: 'cross_cutting_state' }, function (data) {
    cov_2bkpv7mhxp.f[4]++;
    cov_2bkpv7mhxp.s[34]++;

    $scope.canDisplayCrossIndicator = (cov_2bkpv7mhxp.b[7][0]++, data.display == 1) && (cov_2bkpv7mhxp.b[7][1]++, data.value == 1);
  });
});
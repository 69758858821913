'use strict';

var cov_1r4olsdr52 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities/activity_content/activityContentCtrl.js',
      hash = 'bbf2c33ebd26f033319dda80ee8f58878113db0f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities/activity_content/activityContentCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 130,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 4,
          column: 3
        }
      },
      '2': {
        start: {
          line: 3,
          column: 4
        },
        end: {
          line: 3,
          column: 29
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 36
        }
      },
      '4': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 36
        }
      },
      '5': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 39
        }
      },
      '6': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 43
        }
      },
      '7': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 42
        }
      },
      '8': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 42
        }
      },
      '9': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 50
        }
      },
      '10': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 37
        }
      },
      '11': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 98
        }
      },
      '12': {
        start: {
          line: 14,
          column: 24
        },
        end: {
          line: 46,
          column: 3
        }
      },
      '13': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '14': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 16,
          column: 30
        }
      },
      '15': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 17,
          column: 29
        }
      },
      '16': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 31
        }
      },
      '17': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 13
        }
      },
      '18': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '19': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '20': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 32
        }
      },
      '21': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 31
        }
      },
      '22': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 32
        }
      },
      '23': {
        start: {
          line: 28,
          column: 13
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '24': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 32
        }
      },
      '25': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 31
        }
      },
      '26': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 32
        }
      },
      '27': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 31
        }
      },
      '28': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 30
        }
      },
      '29': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 32
        }
      },
      '30': {
        start: {
          line: 37,
          column: 11
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '31': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 38,
          column: 30
        }
      },
      '32': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 29
        }
      },
      '33': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 31
        }
      },
      '34': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 29
        }
      },
      '35': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 28
        }
      },
      '36': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 30
        }
      },
      '37': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 49,
          column: 25
        }
      },
      '38': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 50,
          column: 26
        }
      },
      '39': {
        start: {
          line: 51,
          column: 2
        },
        end: {
          line: 51,
          column: 27
        }
      },
      '40': {
        start: {
          line: 55,
          column: 2
        },
        end: {
          line: 58,
          column: 4
        }
      },
      '41': {
        start: {
          line: 59,
          column: 2
        },
        end: {
          line: 64,
          column: 3
        }
      },
      '42': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 63,
          column: 5
        }
      },
      '43': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 61,
          column: 45
        }
      },
      '44': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 80
        }
      },
      '45': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 73,
          column: 3
        }
      },
      '46': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 72,
          column: 6
        }
      },
      '47': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 85,
          column: 5
        }
      },
      '48': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 78,
          column: 5
        }
      },
      '49': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 118
        }
      },
      '50': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 81,
          column: 5
        }
      },
      '51': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 126
        }
      },
      '52': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 102
        }
      },
      '53': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 84,
          column: 19
        }
      },
      '54': {
        start: {
          line: 89,
          column: 15
        },
        end: {
          line: 89,
          column: 30
        }
      },
      '55': {
        start: {
          line: 91,
          column: 2
        },
        end: {
          line: 93,
          column: 4
        }
      },
      '56': {
        start: {
          line: 95,
          column: 2
        },
        end: {
          line: 103,
          column: 4
        }
      },
      '57': {
        start: {
          line: 96,
          column: 19
        },
        end: {
          line: 96,
          column: 51
        }
      },
      '58': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 102,
          column: 5
        }
      },
      '59': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 99,
          column: 52
        }
      },
      '60': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 31
        }
      },
      '61': {
        start: {
          line: 105,
          column: 2
        },
        end: {
          line: 107,
          column: 5
        }
      },
      '62': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 124
        }
      },
      '63': {
        start: {
          line: 109,
          column: 2
        },
        end: {
          line: 111,
          column: 4
        }
      },
      '64': {
        start: {
          line: 110,
          column: 4
        },
        end: {
          line: 110,
          column: 37
        }
      },
      '65': {
        start: {
          line: 113,
          column: 23
        },
        end: {
          line: 118,
          column: 4
        }
      },
      '66': {
        start: {
          line: 119,
          column: 2
        },
        end: {
          line: 123,
          column: 4
        }
      },
      '67': {
        start: {
          line: 120,
          column: 4
        },
        end: {
          line: 120,
          column: 39
        }
      },
      '68': {
        start: {
          line: 121,
          column: 4
        },
        end: {
          line: 121,
          column: 81
        }
      },
      '69': {
        start: {
          line: 122,
          column: 4
        },
        end: {
          line: 122,
          column: 22
        }
      },
      '70': {
        start: {
          line: 124,
          column: 2
        },
        end: {
          line: 128,
          column: 4
        }
      },
      '71': {
        start: {
          line: 125,
          column: 4
        },
        end: {
          line: 125,
          column: 58
        }
      },
      '72': {
        start: {
          line: 126,
          column: 4
        },
        end: {
          line: 126,
          column: 40
        }
      },
      '73': {
        start: {
          line: 127,
          column: 4
        },
        end: {
          line: 127,
          column: 100
        }
      },
      '74': {
        start: {
          line: 129,
          column: 2
        },
        end: {
          line: 129,
          column: 24
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 328
          },
          end: {
            line: 130,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 24
          },
          end: {
            line: 14,
            column: 25
          }
        },
        loc: {
          start: {
            line: 14,
            column: 30
          },
          end: {
            line: 46,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 75,
            column: 39
          },
          end: {
            line: 75,
            column: 40
          }
        },
        loc: {
          start: {
            line: 75,
            column: 45
          },
          end: {
            line: 85,
            column: 3
          }
        },
        line: 75
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 91,
            column: 24
          },
          end: {
            line: 91,
            column: 25
          }
        },
        loc: {
          start: {
            line: 91,
            column: 36
          },
          end: {
            line: 93,
            column: 3
          }
        },
        line: 91
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 95,
            column: 29
          },
          end: {
            line: 95,
            column: 30
          }
        },
        loc: {
          start: {
            line: 95,
            column: 41
          },
          end: {
            line: 103,
            column: 3
          }
        },
        line: 95
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 105,
            column: 30
          },
          end: {
            line: 105,
            column: 31
          }
        },
        loc: {
          start: {
            line: 105,
            column: 38
          },
          end: {
            line: 107,
            column: 3
          }
        },
        line: 105
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 109,
            column: 21
          },
          end: {
            line: 109,
            column: 22
          }
        },
        loc: {
          start: {
            line: 109,
            column: 33
          },
          end: {
            line: 111,
            column: 3
          }
        },
        line: 109
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 119,
            column: 35
          },
          end: {
            line: 119,
            column: 36
          }
        },
        loc: {
          start: {
            line: 119,
            column: 70
          },
          end: {
            line: 123,
            column: 3
          }
        },
        line: 119
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 124,
            column: 31
          },
          end: {
            line: 124,
            column: 32
          }
        },
        loc: {
          start: {
            line: 124,
            column: 61
          },
          end: {
            line: 128,
            column: 3
          }
        },
        line: 124
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 4,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 4,
            column: 3
          }
        }, {
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 4,
            column: 3
          }
        }],
        line: 2
      },
      '1': {
        loc: {
          start: {
            line: 15,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }, {
          start: {
            line: 15,
            column: 4
          },
          end: {
            line: 20,
            column: 5
          }
        }],
        line: 15
      },
      '2': {
        loc: {
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 15,
            column: 69
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 15,
            column: 33
          }
        }, {
          start: {
            line: 15,
            column: 37
          },
          end: {
            line: 15,
            column: 69
          }
        }],
        line: 15
      },
      '3': {
        loc: {
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        }, {
          start: {
            line: 23,
            column: 4
          },
          end: {
            line: 45,
            column: 5
          }
        }],
        line: 23
      },
      '4': {
        loc: {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 24,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 24
      },
      '5': {
        loc: {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 13
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 28
      },
      '6': {
        loc: {
          start: {
            line: 37,
            column: 11
          },
          end: {
            line: 45,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 11
          },
          end: {
            line: 45,
            column: 5
          }
        }, {
          start: {
            line: 37,
            column: 11
          },
          end: {
            line: 45,
            column: 5
          }
        }],
        line: 37
      },
      '7': {
        loc: {
          start: {
            line: 59,
            column: 2
          },
          end: {
            line: 64,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 2
          },
          end: {
            line: 64,
            column: 3
          }
        }, {
          start: {
            line: 59,
            column: 2
          },
          end: {
            line: 64,
            column: 3
          }
        }],
        line: 59
      },
      '8': {
        loc: {
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 59,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 6
          },
          end: {
            line: 59,
            column: 35
          }
        }, {
          start: {
            line: 59,
            column: 39
          },
          end: {
            line: 59,
            column: 78
          }
        }],
        line: 59
      },
      '9': {
        loc: {
          start: {
            line: 60,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        }, {
          start: {
            line: 60,
            column: 4
          },
          end: {
            line: 63,
            column: 5
          }
        }],
        line: 60
      },
      '10': {
        loc: {
          start: {
            line: 67,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        }, {
          start: {
            line: 67,
            column: 2
          },
          end: {
            line: 73,
            column: 3
          }
        }],
        line: 67
      },
      '11': {
        loc: {
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 67,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 67,
            column: 6
          },
          end: {
            line: 67,
            column: 29
          }
        }, {
          start: {
            line: 67,
            column: 33
          },
          end: {
            line: 67,
            column: 65
          }
        }],
        line: 67
      },
      '12': {
        loc: {
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        }, {
          start: {
            line: 76,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        }],
        line: 76
      },
      '13': {
        loc: {
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }, {
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 81,
            column: 5
          }
        }],
        line: 79
      },
      '14': {
        loc: {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        }, {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 102,
            column: 5
          }
        }],
        line: 98
      },
      '15': {
        loc: {
          start: {
            line: 98,
            column: 7
          },
          end: {
            line: 98,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 98,
            column: 7
          },
          end: {
            line: 98,
            column: 21
          }
        }, {
          start: {
            line: 98,
            column: 25
          },
          end: {
            line: 98,
            column: 46
          }
        }],
        line: 98
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1r4olsdr52.s[0]++;
angular.module('app').controller('activityContentCtrl', function ($scope, $window, $translate, FileUploader, SweetAlert, $uibModal, $deltahttp, $stateParams, $state, $deltaPlanLogique, $deltaGeoLevel, $deltaUnite, $deltaActor, $deltaLogLevel, $deltaFinancSource, $CRUDService, $deltaPlanMarches, $rootScope, $deltadate, $filter, $log) {
  cov_1r4olsdr52.f[0]++;
  cov_1r4olsdr52.s[1]++;

  if (!$stateParams.params) {
    cov_1r4olsdr52.b[0][0]++;
    cov_1r4olsdr52.s[2]++;

    $stateParams.params = {};
  } else {
    cov_1r4olsdr52.b[0][1]++;
  }
  cov_1r4olsdr52.s[3]++;
  $deltaUnite.addController($scope);
  cov_1r4olsdr52.s[4]++;
  $deltaActor.addController($scope);
  cov_1r4olsdr52.s[5]++;
  $deltaGeoLevel.addController($scope);
  cov_1r4olsdr52.s[6]++;
  $deltaFinancSource.addController($scope);
  cov_1r4olsdr52.s[7]++;
  $deltaPlanMarches.addController($scope);
  cov_1r4olsdr52.s[8]++;
  $deltaPlanLogique.addController($scope);
  cov_1r4olsdr52.s[9]++;
  $scope.selectedActivity = $stateParams.activity;
  cov_1r4olsdr52.s[10]++;
  $scope.canEditPlanActivity = false;
  cov_1r4olsdr52.s[11]++;
  $scope.selectedActivity.imageUrl = $deltahttp.getActivityRespositoy($scope.selectedActivity.id);
  cov_1r4olsdr52.s[12]++;
  var processRight = function processRight() {
    cov_1r4olsdr52.f[1]++;
    cov_1r4olsdr52.s[13]++;

    if (!((cov_1r4olsdr52.b[2][0]++, $scope.selectedActivity) && (cov_1r4olsdr52.b[2][1]++, $scope.selectedActivity.ID_UNITE))) {
      cov_1r4olsdr52.b[1][0]++;
      cov_1r4olsdr52.s[14]++;

      $scope.canTrack = false;
      cov_1r4olsdr52.s[15]++;
      $scope.canPlan = false;
      cov_1r4olsdr52.s[16]++;
      $scope.canReport = false;
      cov_1r4olsdr52.s[17]++;
      return;
    } else {
      cov_1r4olsdr52.b[1][1]++;
    }

    // projet courant  portfeuille
    cov_1r4olsdr52.s[18]++;
    if ($rootScope.getCurrentProject().project.PORTFOLIO == 1) {
      cov_1r4olsdr52.b[3][0]++;
      cov_1r4olsdr52.s[19]++;

      if ($scope.selectedActivity.CROSS_ACTIVITY_PORTFOLIO == 1) {
        cov_1r4olsdr52.b[4][0]++;
        cov_1r4olsdr52.s[20]++;
        // Activité Transversale
        $scope.canTrack = false;
        cov_1r4olsdr52.s[21]++;
        $scope.canPlan = false;
        cov_1r4olsdr52.s[22]++;
        $scope.canReport = true;
      } else {
          cov_1r4olsdr52.b[4][1]++;
          cov_1r4olsdr52.s[23]++;
          if ($rootScope.getCurrentProject().project.id != $scope.selectedActivity.ID_PROJET) {
            cov_1r4olsdr52.b[5][0]++;
            cov_1r4olsdr52.s[24]++;
            // Activité d'un autre projet
            $scope.canTrack = false;
            cov_1r4olsdr52.s[25]++;
            $scope.canPlan = false;
            cov_1r4olsdr52.s[26]++;
            $scope.canReport = true;
          } else {
            cov_1r4olsdr52.b[5][1]++;
            cov_1r4olsdr52.s[27]++;
            // Activité normal du projet
            $scope.canTrack = true;
            cov_1r4olsdr52.s[28]++;
            $scope.canPlan = true;
            cov_1r4olsdr52.s[29]++;
            $scope.canReport = true;
          }
        }
    } else {
        cov_1r4olsdr52.b[3][1]++;
        cov_1r4olsdr52.s[30]++;
        if ($rootScope.getCurrentProject().project.id != $scope.selectedActivity.ID_PROJET) {
          cov_1r4olsdr52.b[6][0]++;
          cov_1r4olsdr52.s[31]++;
          // Activité d'un autre projet
          $scope.canTrack = false;
          cov_1r4olsdr52.s[32]++;
          $scope.canPlan = false;
          cov_1r4olsdr52.s[33]++;
          $scope.canReport = false;
        } else {
          cov_1r4olsdr52.b[6][1]++;
          cov_1r4olsdr52.s[34]++;
          // Activité normal du projet
          $scope.canTrack = true;
          cov_1r4olsdr52.s[35]++;
          $scope.canPlan = true;
          cov_1r4olsdr52.s[36]++;
          $scope.canReport = true;
        }
      }
  };

  // Right
  cov_1r4olsdr52.s[37]++;
  $scope.canPlan = false;
  cov_1r4olsdr52.s[38]++;
  $scope.canTrack = false;
  cov_1r4olsdr52.s[39]++;
  $scope.canReport = false;

  //$log.log('activityContentCtrl');
  //$log.log($scope.selectedActivity);
  cov_1r4olsdr52.s[40]++;
  $scope.activity_processus = {
    check: false,
    steps: []
  };
  cov_1r4olsdr52.s[41]++;
  if ((cov_1r4olsdr52.b[8][0]++, $scope.selectedActivity.EXTRA) && (cov_1r4olsdr52.b[8][1]++, $scope.selectedActivity.EXTRA.TYPE_STEP)) {
    cov_1r4olsdr52.b[7][0]++;
    cov_1r4olsdr52.s[42]++;

    if ($scope.selectedActivity.EXTRA.TYPE_STEP.length > 0) {
      cov_1r4olsdr52.b[9][0]++;
      cov_1r4olsdr52.s[43]++;

      $scope.activity_processus.check = true;
      cov_1r4olsdr52.s[44]++;
      $scope.activity_processus.steps = $scope.selectedActivity.EXTRA.TYPE_STEP;
    } else {
      cov_1r4olsdr52.b[9][1]++;
    }
  } else {
    cov_1r4olsdr52.b[7][1]++;
  }
  //$log.log($scope.activity_processus);

  cov_1r4olsdr52.s[45]++;
  if ((cov_1r4olsdr52.b[11][0]++, $scope.selectedActivity) && (cov_1r4olsdr52.b[11][1]++, $scope.selectedActivity.ID_UNITE)) {
    cov_1r4olsdr52.b[10][0]++;
    cov_1r4olsdr52.s[46]++;

    $scope.unitSelected = {
      id: $scope.selectedActivity.ID_UNITE,
      LIBELLE_UNITES: $scope.selectedActivity.EXTRA.UNIT,
      CODE_UNITE: $scope.selectedActivity.EXTRA.CODE_U
    };
  } else {
    cov_1r4olsdr52.b[10][1]++;
  }

  cov_1r4olsdr52.s[47]++;
  $deltaLogLevel.addController($scope, function () {
    cov_1r4olsdr52.f[2]++;
    cov_1r4olsdr52.s[48]++;

    if ($scope.listData_LogLevels_bksb.length > 1) {
      cov_1r4olsdr52.b[12][0]++;
      cov_1r4olsdr52.s[49]++;

      $scope.labelSecondLevel = $scope.listData_LogLevels_bksb[$scope.listData_LogLevels_bksb.length - 2].LIBELLE_NCL;
    } else {
      cov_1r4olsdr52.b[12][1]++;
    }
    cov_1r4olsdr52.s[50]++;
    if ($scope.listData_LogLevels_bksb.length > 0) {
      cov_1r4olsdr52.b[13][0]++;
      cov_1r4olsdr52.s[51]++;

      $scope.canEditPlanActivity = !$scope.listData_LogLevels_bksb[$scope.listData_LogLevels_bksb.length - 1].ETAT_INDIC_TAUX;
    } else {
      cov_1r4olsdr52.b[13][1]++;
    }
    cov_1r4olsdr52.s[52]++;
    $scope.planItem = { NC: $scope.listData_LogLevels_bksb[$scope.listData_LogLevels_bksb.length - 1] };

    cov_1r4olsdr52.s[53]++;
    processRight();
  });

  // $rootScope.addHistori({indexTrad: 'MENU.P_A_' + $stateParams.id, id: $state.current.name, params: $stateParams});

  var PATH = (cov_1r4olsdr52.s[54]++, 'DernierNiveau');

  cov_1r4olsdr52.s[55]++;
  $scope.initOverview = function () {
    cov_1r4olsdr52.f[3]++;
  };

  cov_1r4olsdr52.s[56]++;
  $scope.goToPreviousFrame = function () {
    cov_1r4olsdr52.f[4]++;

    var params = (cov_1r4olsdr52.s[57]++, $rootScope.getReturnToActivity());
    // $log.log(params);
    cov_1r4olsdr52.s[58]++;
    if ((cov_1r4olsdr52.b[15][0]++, params == null) || (cov_1r4olsdr52.b[15][1]++, params.origin == null)) {
      cov_1r4olsdr52.b[14][0]++;
      cov_1r4olsdr52.s[59]++;

      $state.go('project_mgt.activity_plan_budget');
    } else {
      cov_1r4olsdr52.b[14][1]++;
      cov_1r4olsdr52.s[60]++;

      $state.go(params.origin);
    }
  };

  cov_1r4olsdr52.s[61]++;
  $scope.changeActivityTab = function (path) {
    cov_1r4olsdr52.f[5]++;
    cov_1r4olsdr52.s[62]++;

    localStorage.setItem('KEY_VIEW_TAB_ACTIVITIES' + $scope.selectedActivity.id + '_' + $scope.selectedActivity.ID_PROJET, path);
  };

  cov_1r4olsdr52.s[63]++;
  $scope.loadImage = function () {
    cov_1r4olsdr52.f[6]++;
    cov_1r4olsdr52.s[64]++;

    $('#loadimage').trigger('click');
  };
  // uploadfile
  var uploader_img = (cov_1r4olsdr52.s[65]++, $scope.uploader_img = new FileUploader({
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadimg&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH,
    headers: $CRUDService.getHeader(),
    alias: 'img',
    removeAfterUpload: true
  }));
  cov_1r4olsdr52.s[66]++;
  uploader_img.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_1r4olsdr52.f[7]++;
    cov_1r4olsdr52.s[67]++;

    fileItem.activ.showprogress = true;
    cov_1r4olsdr52.s[68]++;
    fileItem.url = uploader_img.url + '&activ=' + fileItem.activ.id + '&cross=0';
    cov_1r4olsdr52.s[69]++;
    fileItem.upload();
  };
  cov_1r4olsdr52.s[70]++;
  uploader_img.onSuccessItem = function (fileItem, response) {
    cov_1r4olsdr52.f[8]++;
    cov_1r4olsdr52.s[71]++;

    $scope.selectedActivity.ICON = new Date().getTime();
    cov_1r4olsdr52.s[72]++;
    $scope.selectedActivity.file = true;
    cov_1r4olsdr52.s[73]++;
    $scope.selectedActivity.imageUrl = $deltahttp.getActivityRespositoy($scope.selectedActivity.id);
  };
  cov_1r4olsdr52.s[74]++;
  $scope.initOverview();
});
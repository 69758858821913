'use strict';

var cov_2d9s73vrxy = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/formular/workflow/workflowCtrl.js',
      hash = '6f5d1388dc42c9988cb28c4ee4888c80f9057dac',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/formular/workflow/workflowCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 120,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 21
        }
      },
      '2': {
        start: {
          line: 3,
          column: 23
        },
        end: {
          line: 3,
          column: 33
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 26
        }
      },
      '4': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 27
        }
      },
      '5': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 23,
          column: 4
        }
      },
      '6': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 28
        }
      },
      '7': {
        start: {
          line: 10,
          column: 24
        },
        end: {
          line: 12,
          column: 20
        }
      },
      '8': {
        start: {
          line: 13,
          column: 4
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '9': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '10': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 15,
          column: 41
        }
      },
      '11': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 54
        }
      },
      '12': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 29
        }
      },
      '13': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 36
        }
      },
      '14': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 31
        }
      },
      '15': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 22
        }
      },
      '16': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 35,
          column: 4
        }
      },
      '17': {
        start: {
          line: 25,
          column: 15
        },
        end: {
          line: 25,
          column: 33
        }
      },
      '18': {
        start: {
          line: 26,
          column: 18
        },
        end: {
          line: 26,
          column: 54
        }
      },
      '19': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 34,
          column: 7
        }
      },
      '20': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 66,
          column: 4
        }
      },
      '21': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 65,
          column: 7
        }
      },
      '22': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '23': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 15
        }
      },
      '24': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 30
        }
      },
      '25': {
        start: {
          line: 53,
          column: 27
        },
        end: {
          line: 53,
          column: 45
        }
      },
      '26': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '27': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 62,
          column: 9
        }
      },
      '28': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 59,
          column: 13
        }
      },
      '29': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 35
        }
      },
      '30': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 17
        }
      },
      '31': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 24
        }
      },
      '32': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 92,
          column: 4
        }
      },
      '33': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 68,
          column: 45
        }
      },
      '34': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 31
        }
      },
      '35': {
        start: {
          line: 70,
          column: 21
        },
        end: {
          line: 70,
          column: 46
        }
      },
      '36': {
        start: {
          line: 71,
          column: 4
        },
        end: {
          line: 77,
          column: 5
        }
      },
      '37': {
        start: {
          line: 72,
          column: 20
        },
        end: {
          line: 72,
          column: 72
        }
      },
      '38': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 75,
          column: 7
        }
      },
      '39': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 74,
          column: 17
        }
      },
      '40': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 39
        }
      },
      '41': {
        start: {
          line: 78,
          column: 14
        },
        end: {
          line: 78,
          column: 88
        }
      },
      '42': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 83,
          column: 5
        }
      },
      '43': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 40
        }
      },
      '44': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 79
        }
      },
      '45': {
        start: {
          line: 85,
          column: 4
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '46': {
        start: {
          line: 93,
          column: 2
        },
        end: {
          line: 95,
          column: 4
        }
      },
      '47': {
        start: {
          line: 94,
          column: 4
        },
        end: {
          line: 94,
          column: 58
        }
      },
      '48': {
        start: {
          line: 96,
          column: 2
        },
        end: {
          line: 96,
          column: 24
        }
      },
      '49': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 118,
          column: 4
        }
      },
      '50': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 27
        }
      },
      '51': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 117,
          column: 7
        }
      },
      '52': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 116,
          column: 7
        }
      },
      '53': {
        start: {
          line: 101,
          column: 25
        },
        end: {
          line: 106,
          column: 9
        }
      },
      '54': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 112,
          column: 11
        }
      },
      '55': {
        start: {
          line: 108,
          column: 10
        },
        end: {
          line: 108,
          column: 30
        }
      },
      '56': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 34
        }
      },
      '57': {
        start: {
          line: 110,
          column: 10
        },
        end: {
          line: 110,
          column: 37
        }
      },
      '58': {
        start: {
          line: 111,
          column: 10
        },
        end: {
          line: 111,
          column: 33
        }
      },
      '59': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 31
        }
      },
      '60': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 31
        }
      },
      '61': {
        start: {
          line: 119,
          column: 2
        },
        end: {
          line: 119,
          column: 22
        }
      },
      '62': {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 123,
          column: 28
        }
      },
      '63': {
        start: {
          line: 124,
          column: 15
        },
        end: {
          line: 124,
          column: 25
        }
      },
      '64': {
        start: {
          line: 125,
          column: 2
        },
        end: {
          line: 132,
          column: 4
        }
      },
      '65': {
        start: {
          line: 126,
          column: 25
        },
        end: {
          line: 126,
          column: 58
        }
      },
      '66': {
        start: {
          line: 127,
          column: 4
        },
        end: {
          line: 127,
          column: 49
        }
      },
      '67': {
        start: {
          line: 128,
          column: 4
        },
        end: {
          line: 131,
          column: 7
        }
      },
      '68': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 129,
          column: 26
        }
      },
      '69': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 130,
          column: 22
        }
      },
      '70': {
        start: {
          line: 133,
          column: 2
        },
        end: {
          line: 135,
          column: 4
        }
      },
      '71': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 134,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 57
          },
          end: {
            line: 1,
            column: 58
          }
        },
        loc: {
          start: {
            line: 1,
            column: 153
          },
          end: {
            line: 120,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 22
          },
          end: {
            line: 8,
            column: 23
          }
        },
        loc: {
          start: {
            line: 8,
            column: 34
          },
          end: {
            line: 23,
            column: 3
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 43
          },
          end: {
            line: 13,
            column: 44
          }
        },
        loc: {
          start: {
            line: 13,
            column: 51
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 24,
            column: 19
          },
          end: {
            line: 24,
            column: 20
          }
        },
        loc: {
          start: {
            line: 24,
            column: 31
          },
          end: {
            line: 35,
            column: 3
          }
        },
        line: 24
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 36,
            column: 22
          },
          end: {
            line: 36,
            column: 23
          }
        },
        loc: {
          start: {
            line: 36,
            column: 38
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 36
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 48,
            column: 7
          },
          end: {
            line: 48,
            column: 8
          }
        },
        loc: {
          start: {
            line: 48,
            column: 20
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 48
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 54,
            column: 96
          },
          end: {
            line: 54,
            column: 97
          }
        },
        loc: {
          start: {
            line: 54,
            column: 104
          },
          end: {
            line: 64,
            column: 7
          }
        },
        line: 54
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 67,
            column: 20
          },
          end: {
            line: 67,
            column: 21
          }
        },
        loc: {
          start: {
            line: 67,
            column: 36
          },
          end: {
            line: 92,
            column: 3
          }
        },
        line: 67
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 93,
            column: 23
          },
          end: {
            line: 93,
            column: 24
          }
        },
        loc: {
          start: {
            line: 93,
            column: 35
          },
          end: {
            line: 95,
            column: 3
          }
        },
        line: 93
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 97,
            column: 18
          },
          end: {
            line: 97,
            column: 19
          }
        },
        loc: {
          start: {
            line: 97,
            column: 30
          },
          end: {
            line: 118,
            column: 3
          }
        },
        line: 97
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 99,
            column: 88
          },
          end: {
            line: 99,
            column: 89
          }
        },
        loc: {
          start: {
            line: 99,
            column: 96
          },
          end: {
            line: 117,
            column: 5
          }
        },
        line: 99
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 107,
            column: 97
          },
          end: {
            line: 107,
            column: 98
          }
        },
        loc: {
          start: {
            line: 107,
            column: 106
          },
          end: {
            line: 112,
            column: 9
          }
        },
        line: 107
      },
      '12': {
        name: 'formularWorkflowCtrl_edit',
        decl: {
          start: {
            line: 122,
            column: 9
          },
          end: {
            line: 122,
            column: 34
          }
        },
        loc: {
          start: {
            line: 122,
            column: 85
          },
          end: {
            line: 136,
            column: 1
          }
        },
        line: 122
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 125,
            column: 24
          },
          end: {
            line: 125,
            column: 25
          }
        },
        loc: {
          start: {
            line: 125,
            column: 36
          },
          end: {
            line: 132,
            column: 3
          }
        },
        line: 125
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 128,
            column: 94
          },
          end: {
            line: 128,
            column: 95
          }
        },
        loc: {
          start: {
            line: 128,
            column: 100
          },
          end: {
            line: 131,
            column: 5
          }
        },
        line: 128
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 133,
            column: 22
          },
          end: {
            line: 133,
            column: 23
          }
        },
        loc: {
          start: {
            line: 133,
            column: 34
          },
          end: {
            line: 135,
            column: 3
          }
        },
        line: 133
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 10,
            column: 24
          },
          end: {
            line: 12,
            column: 20
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 11,
            column: 8
          },
          end: {
            line: 11,
            column: 67
          }
        }, {
          start: {
            line: 12,
            column: 8
          },
          end: {
            line: 12,
            column: 20
          }
        }],
        line: 10
      },
      '1': {
        loc: {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }, {
          start: {
            line: 49,
            column: 6
          },
          end: {
            line: 51,
            column: 7
          }
        }],
        line: 49
      },
      '2': {
        loc: {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }, {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 62,
            column: 9
          }
        }],
        line: 55
      },
      '3': {
        loc: {
          start: {
            line: 73,
            column: 6
          },
          end: {
            line: 75,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 73,
            column: 6
          },
          end: {
            line: 75,
            column: 7
          }
        }, {
          start: {
            line: 73,
            column: 6
          },
          end: {
            line: 75,
            column: 7
          }
        }],
        line: 73
      },
      '4': {
        loc: {
          start: {
            line: 73,
            column: 10
          },
          end: {
            line: 73,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 10
          },
          end: {
            line: 73,
            column: 26
          }
        }, {
          start: {
            line: 73,
            column: 30
          },
          end: {
            line: 73,
            column: 52
          }
        }],
        line: 73
      },
      '5': {
        loc: {
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 83,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 83,
            column: 5
          }
        }, {
          start: {
            line: 79,
            column: 4
          },
          end: {
            line: 83,
            column: 5
          }
        }],
        line: 79
      },
      '6': {
        loc: {
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 94,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 94,
            column: 27
          }
        }, {
          start: {
            line: 94,
            column: 31
          },
          end: {
            line: 94,
            column: 46
          }
        }],
        line: 94
      },
      '7': {
        loc: {
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }, {
          start: {
            line: 100,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }],
        line: 100
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2d9s73vrxy.s[0]++;
angular.module('app').controller('formularWorkflowCtrl', function ($scope, $stateParams, $deltahttp, $translate, $CRUDService, SweetAlert, $filter, $uibModal) {
  cov_2d9s73vrxy.f[0]++;

  var PATH = (cov_2d9s73vrxy.s[1]++, 'User');
  var PATHWORKFLOW = (cov_2d9s73vrxy.s[2]++, 'Workflow');

  cov_2d9s73vrxy.s[3]++;
  $scope.tab_workflow = 5;
  cov_2d9s73vrxy.s[4]++;
  $scope.etat_workflow = 0;

  cov_2d9s73vrxy.s[5]++;
  $scope.getAllUser = function () {
    cov_2d9s73vrxy.f[1]++;
    cov_2d9s73vrxy.s[6]++;

    $scope.isloading = true;
    var userPayload = (cov_2d9s73vrxy.s[7]++, $scope.selectedProject.project.id ? (cov_2d9s73vrxy.b[0][0]++, { get: 'all', projet: [[$scope.selectedProject.project.id]] }) : (cov_2d9s73vrxy.b[0][1]++, { get: 'all' }));
    cov_2d9s73vrxy.s[8]++;
    $CRUDService.getAll(PATH, userPayload, function (data) {
      cov_2d9s73vrxy.f[2]++;
      cov_2d9s73vrxy.s[9]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_2d9s73vrxy.s[10]++;

          el.TYPEUSER = el.TYPEUSER == 1;
          cov_2d9s73vrxy.s[11]++;
          el.picture = $deltahttp.getUserPicture(el.id);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_2d9s73vrxy.s[12]++;
      $scope.listUser = data;
      cov_2d9s73vrxy.s[13]++;
      $scope.listUser.push({ id: 0 });
      cov_2d9s73vrxy.s[14]++;
      $scope.isloading = false;
      cov_2d9s73vrxy.s[15]++;
      $scope.getAll();
    });
  };
  cov_2d9s73vrxy.s[16]++;
  $scope.addStep = function () {
    cov_2d9s73vrxy.f[3]++;

    var id = (cov_2d9s73vrxy.s[17]++, $scope.workflow.id);
    var level = (cov_2d9s73vrxy.s[18]++, $scope.workflow.LIST_STEP.length + 1);
    cov_2d9s73vrxy.s[19]++;
    $scope.editStep({
      ID_WORKFLOW: id,
      NAME: '',
      COLOR: '0,0,0',
      id: 0,
      LEVEL: level,
      IDUSER: 0
    });
  };
  cov_2d9s73vrxy.s[20]++;
  $scope.deleteStep = function (item) {
    cov_2d9s73vrxy.f[4]++;
    cov_2d9s73vrxy.s[21]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_2d9s73vrxy.f[5]++;
      cov_2d9s73vrxy.s[22]++;

      if (isconfirm) {
        cov_2d9s73vrxy.b[1][0]++;
        cov_2d9s73vrxy.s[23]++;

        return;
      } else {
        cov_2d9s73vrxy.b[1][1]++;
      }
      cov_2d9s73vrxy.s[24]++;
      $scope.isloading = true;
      var validateItem = (cov_2d9s73vrxy.s[25]++, angular.copy(item));
      cov_2d9s73vrxy.s[26]++;
      $CRUDService.delet(PATHWORKFLOW, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_2d9s73vrxy.f[6]++;
        cov_2d9s73vrxy.s[27]++;

        if (data < 0) {
          cov_2d9s73vrxy.b[2][0]++;
          cov_2d9s73vrxy.s[28]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_2d9s73vrxy.s[29]++;
          $scope.isloading = false;
          cov_2d9s73vrxy.s[30]++;
          return;
        } else {
          cov_2d9s73vrxy.b[2][1]++;
        }
        cov_2d9s73vrxy.s[31]++;
        $scope.getAll();
      });
    });
  };
  cov_2d9s73vrxy.s[32]++;
  $scope.editStep = function (item) {
    cov_2d9s73vrxy.f[7]++;
    cov_2d9s73vrxy.s[33]++;

    $scope.selectedStep = angular.copy(item);
    cov_2d9s73vrxy.s[34]++;
    $scope.listFilterUser = [];
    var listUser = (cov_2d9s73vrxy.s[35]++, $scope.workflow.LIST_STEP);
    cov_2d9s73vrxy.s[36]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.listUser[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var user = _step2.value;

        var items = (cov_2d9s73vrxy.s[37]++, $filter('filter')(listUser, { IDUSER: user.id }, true));
        cov_2d9s73vrxy.s[38]++;
        if ((cov_2d9s73vrxy.b[4][0]++, items.length > 0) && (cov_2d9s73vrxy.b[4][1]++, items[0].id != item.id)) {
          cov_2d9s73vrxy.b[3][0]++;
          cov_2d9s73vrxy.s[39]++;

          continue;
        } else {
          cov_2d9s73vrxy.b[3][1]++;
        }
        cov_2d9s73vrxy.s[40]++;
        $scope.listFilterUser.push(user);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    var u = (cov_2d9s73vrxy.s[41]++, $filter('filter')($scope.listUser, { id: $scope.selectedStep.IDUSER }, true));
    cov_2d9s73vrxy.s[42]++;
    if (u.length > 0) {
      cov_2d9s73vrxy.b[5][0]++;
      cov_2d9s73vrxy.s[43]++;

      $scope.selectedStep.IDUSER = u[0];
    } else {
      cov_2d9s73vrxy.b[5][1]++;
      cov_2d9s73vrxy.s[44]++;

      $scope.selectedStep.IDUSER = $scope.listUser[$scope.listUser.length - 1];
    }

    cov_2d9s73vrxy.s[45]++;
    $uibModal.open({
      templateUrl: 'app/views/report_type/workflow/edit.html',
      controller: formularWorkflowCtrl_edit,
      scope: $scope,
      size: 'sm_',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_2d9s73vrxy.s[46]++;
  $scope.getStepList = function () {
    cov_2d9s73vrxy.f[8]++;
    cov_2d9s73vrxy.s[47]++;

    return ((cov_2d9s73vrxy.b[6][0]++, $scope.workflow) || (cov_2d9s73vrxy.b[6][1]++, { LIST_STEP: [] })).LIST_STEP;
  };
  cov_2d9s73vrxy.s[48]++;
  $scope.loading = true;
  cov_2d9s73vrxy.s[49]++;
  $scope.getAll = function () {
    cov_2d9s73vrxy.f[9]++;
    cov_2d9s73vrxy.s[50]++;

    $scope.loading = false;
    cov_2d9s73vrxy.s[51]++;
    $CRUDService.getAll(PATHWORKFLOW, { get: 'form', id: $scope.selectedItemWorflow.id }, function (data) {
      cov_2d9s73vrxy.f[10]++;
      cov_2d9s73vrxy.s[52]++;

      if (data == 'null') {
        cov_2d9s73vrxy.b[7][0]++;

        var workflow = (cov_2d9s73vrxy.s[53]++, {
          NAME: '',
          TYPE: '7',
          COLOR: '0, 0, 0',
          ID_REFERENCE: $scope.selectedItemWorflow.id
        });
        cov_2d9s73vrxy.s[54]++;
        $CRUDService.save(PATHWORKFLOW, { action: 'MiseAJour', valeur: angular.toJson(workflow) }, function (data_) {
          cov_2d9s73vrxy.f[11]++;
          cov_2d9s73vrxy.s[55]++;

          workflow.id = data_;
          cov_2d9s73vrxy.s[56]++;
          workflow.LIST_STEP = [];
          cov_2d9s73vrxy.s[57]++;
          $scope.workflow = workflow;
          cov_2d9s73vrxy.s[58]++;
          $scope.loading = false;
        });
      } else {
        cov_2d9s73vrxy.b[7][1]++;
        cov_2d9s73vrxy.s[59]++;

        $scope.workflow = data;
        cov_2d9s73vrxy.s[60]++;
        $scope.loading = false;
      }
    });
  };
  cov_2d9s73vrxy.s[61]++;
  $scope.getAllUser();
});

function formularWorkflowCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_2d9s73vrxy.f[12]++;
  cov_2d9s73vrxy.s[62]++;

  $scope.error_code = false;
  var PATH = (cov_2d9s73vrxy.s[63]++, 'Workflow');
  cov_2d9s73vrxy.s[64]++;
  $scope.setValidData = function () {
    cov_2d9s73vrxy.f[13]++;

    var validateItem = (cov_2d9s73vrxy.s[65]++, angular.copy($scope.selectedStep));
    cov_2d9s73vrxy.s[66]++;
    validateItem.IDUSER = validateItem.IDUSER.id;
    cov_2d9s73vrxy.s[67]++;
    $CRUDService.save(PATH, { action: 'MiseAJourStep', valeur: $filter('json')(validateItem) }, function () {
      cov_2d9s73vrxy.f[14]++;
      cov_2d9s73vrxy.s[68]++;

      $scope.closeModal();
      cov_2d9s73vrxy.s[69]++;
      $scope.getAll();
    });
  };
  cov_2d9s73vrxy.s[70]++;
  $scope.closeModal = function () {
    cov_2d9s73vrxy.f[15]++;
    cov_2d9s73vrxy.s[71]++;

    $uibModalInstance.dismiss('cancel');
  };
}
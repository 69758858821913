'use strict';

var cov_3ihp149tg = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/categories_budget/CategoriesBudgetCtrl.js',
      hash = 'db623d1929cf0bbdf340b054335d8bb5d28b7a40',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/categories_budget/CategoriesBudgetCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 1
        },
        end: {
          line: 140,
          column: 4
        }
      },
      '1': {
        start: {
          line: 3,
          column: 3
        },
        end: {
          line: 3,
          column: 39
        }
      },
      '2': {
        start: {
          line: 4,
          column: 3
        },
        end: {
          line: 6,
          column: 4
        }
      },
      '3': {
        start: {
          line: 5,
          column: 5
        },
        end: {
          line: 5,
          column: 12
        }
      },
      '4': {
        start: {
          line: 7,
          column: 3
        },
        end: {
          line: 7,
          column: 54
        }
      },
      '5': {
        start: {
          line: 8,
          column: 3
        },
        end: {
          line: 8,
          column: 22
        }
      },
      '6': {
        start: {
          line: 9,
          column: 3
        },
        end: {
          line: 9,
          column: 23
        }
      },
      '7': {
        start: {
          line: 10,
          column: 3
        },
        end: {
          line: 10,
          column: 28
        }
      },
      '8': {
        start: {
          line: 11,
          column: 3
        },
        end: {
          line: 11,
          column: 30
        }
      },
      '9': {
        start: {
          line: 12,
          column: 3
        },
        end: {
          line: 12,
          column: 36
        }
      },
      '10': {
        start: {
          line: 14,
          column: 3
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '11': {
        start: {
          line: 18,
          column: 3
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '12': {
        start: {
          line: 19,
          column: 5
        },
        end: {
          line: 23,
          column: 8
        }
      },
      '13': {
        start: {
          line: 26,
          column: 19
        },
        end: {
          line: 26,
          column: 49
        }
      },
      '14': {
        start: {
          line: 27,
          column: 3
        },
        end: {
          line: 73,
          column: 5
        }
      },
      '15': {
        start: {
          line: 28,
          column: 5
        },
        end: {
          line: 28,
          column: 29
        }
      },
      '16': {
        start: {
          line: 29,
          column: 5
        },
        end: {
          line: 72,
          column: 8
        }
      },
      '17': {
        start: {
          line: 30,
          column: 7
        },
        end: {
          line: 30,
          column: 36
        }
      },
      '18': {
        start: {
          line: 31,
          column: 7
        },
        end: {
          line: 69,
          column: 9
        }
      },
      '19': {
        start: {
          line: 33,
          column: 11
        },
        end: {
          line: 33,
          column: 59
        }
      },
      '20': {
        start: {
          line: 55,
          column: 11
        },
        end: {
          line: 58,
          column: 12
        }
      },
      '21': {
        start: {
          line: 56,
          column: 13
        },
        end: {
          line: 56,
          column: 34
        }
      },
      '22': {
        start: {
          line: 57,
          column: 13
        },
        end: {
          line: 57,
          column: 29
        }
      },
      '23': {
        start: {
          line: 59,
          column: 11
        },
        end: {
          line: 62,
          column: 12
        }
      },
      '24': {
        start: {
          line: 60,
          column: 13
        },
        end: {
          line: 60,
          column: 34
        }
      },
      '25': {
        start: {
          line: 61,
          column: 13
        },
        end: {
          line: 61,
          column: 44
        }
      },
      '26': {
        start: {
          line: 64,
          column: 11
        },
        end: {
          line: 67,
          column: 12
        }
      },
      '27': {
        start: {
          line: 65,
          column: 13
        },
        end: {
          line: 65,
          column: 34
        }
      },
      '28': {
        start: {
          line: 66,
          column: 13
        },
        end: {
          line: 66,
          column: 50
        }
      },
      '29': {
        start: {
          line: 70,
          column: 7
        },
        end: {
          line: 70,
          column: 32
        }
      },
      '30': {
        start: {
          line: 71,
          column: 7
        },
        end: {
          line: 71,
          column: 42
        }
      },
      '31': {
        start: {
          line: 74,
          column: 3
        },
        end: {
          line: 74,
          column: 19
        }
      },
      '32': {
        start: {
          line: 76,
          column: 3
        },
        end: {
          line: 81,
          column: 5
        }
      },
      '33': {
        start: {
          line: 77,
          column: 5
        },
        end: {
          line: 80,
          column: 8
        }
      },
      '34': {
        start: {
          line: 82,
          column: 3
        },
        end: {
          line: 93,
          column: 5
        }
      },
      '35': {
        start: {
          line: 83,
          column: 5
        },
        end: {
          line: 83,
          column: 31
        }
      },
      '36': {
        start: {
          line: 84,
          column: 5
        },
        end: {
          line: 84,
          column: 46
        }
      },
      '37': {
        start: {
          line: 85,
          column: 5
        },
        end: {
          line: 92,
          column: 8
        }
      },
      '38': {
        start: {
          line: 94,
          column: 3
        },
        end: {
          line: 98,
          column: 5
        }
      },
      '39': {
        start: {
          line: 95,
          column: 5
        },
        end: {
          line: 95,
          column: 24
        }
      },
      '40': {
        start: {
          line: 96,
          column: 5
        },
        end: {
          line: 96,
          column: 25
        }
      },
      '41': {
        start: {
          line: 97,
          column: 5
        },
        end: {
          line: 97,
          column: 30
        }
      },
      '42': {
        start: {
          line: 100,
          column: 3
        },
        end: {
          line: 139,
          column: 5
        }
      },
      '43': {
        start: {
          line: 101,
          column: 5
        },
        end: {
          line: 138,
          column: 8
        }
      },
      '44': {
        start: {
          line: 113,
          column: 7
        },
        end: {
          line: 115,
          column: 8
        }
      },
      '45': {
        start: {
          line: 114,
          column: 9
        },
        end: {
          line: 114,
          column: 16
        }
      },
      '46': {
        start: {
          line: 116,
          column: 7
        },
        end: {
          line: 116,
          column: 31
        }
      },
      '47': {
        start: {
          line: 117,
          column: 28
        },
        end: {
          line: 119,
          column: 8
        }
      },
      '48': {
        start: {
          line: 125,
          column: 7
        },
        end: {
          line: 137,
          column: 10
        }
      },
      '49': {
        start: {
          line: 126,
          column: 9
        },
        end: {
          line: 136,
          column: 10
        }
      },
      '50': {
        start: {
          line: 127,
          column: 11
        },
        end: {
          line: 131,
          column: 14
        }
      },
      '51': {
        start: {
          line: 132,
          column: 11
        },
        end: {
          line: 132,
          column: 36
        }
      },
      '52': {
        start: {
          line: 134,
          column: 11
        },
        end: {
          line: 134,
          column: 30
        }
      },
      '53': {
        start: {
          line: 135,
          column: 11
        },
        end: {
          line: 135,
          column: 27
        }
      },
      '54': {
        start: {
          line: 143,
          column: 3
        },
        end: {
          line: 143,
          column: 29
        }
      },
      '55': {
        start: {
          line: 144,
          column: 3
        },
        end: {
          line: 169,
          column: 5
        }
      },
      '56': {
        start: {
          line: 145,
          column: 26
        },
        end: {
          line: 145,
          column: 59
        }
      },
      '57': {
        start: {
          line: 146,
          column: 5
        },
        end: {
          line: 146,
          column: 27
        }
      },
      '58': {
        start: {
          line: 147,
          column: 5
        },
        end: {
          line: 147,
          column: 60
        }
      },
      '59': {
        start: {
          line: 148,
          column: 5
        },
        end: {
          line: 148,
          column: 72
        }
      },
      '60': {
        start: {
          line: 150,
          column: 20
        },
        end: {
          line: 150,
          column: 101
        }
      },
      '61': {
        start: {
          line: 151,
          column: 5
        },
        end: {
          line: 156,
          column: 6
        }
      },
      '62': {
        start: {
          line: 152,
          column: 7
        },
        end: {
          line: 155,
          column: 8
        }
      },
      '63': {
        start: {
          line: 153,
          column: 9
        },
        end: {
          line: 153,
          column: 31
        }
      },
      '64': {
        start: {
          line: 154,
          column: 9
        },
        end: {
          line: 154,
          column: 16
        }
      },
      '65': {
        start: {
          line: 165,
          column: 5
        },
        end: {
          line: 168,
          column: 8
        }
      },
      '66': {
        start: {
          line: 166,
          column: 7
        },
        end: {
          line: 166,
          column: 27
        }
      },
      '67': {
        start: {
          line: 167,
          column: 7
        },
        end: {
          line: 167,
          column: 23
        }
      },
      '68': {
        start: {
          line: 170,
          column: 3
        },
        end: {
          line: 172,
          column: 5
        }
      },
      '69': {
        start: {
          line: 171,
          column: 5
        },
        end: {
          line: 171,
          column: 41
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 58
          },
          end: {
            line: 2,
            column: 59
          }
        },
        loc: {
          start: {
            line: 2,
            column: 194
          },
          end: {
            line: 140,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 18,
            column: 23
          },
          end: {
            line: 18,
            column: 24
          }
        },
        loc: {
          start: {
            line: 18,
            column: 29
          },
          end: {
            line: 24,
            column: 4
          }
        },
        line: 18
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 27,
            column: 19
          },
          end: {
            line: 27,
            column: 20
          }
        },
        loc: {
          start: {
            line: 27,
            column: 31
          },
          end: {
            line: 73,
            column: 4
          }
        },
        line: 27
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 52
          },
          end: {
            line: 29,
            column: 53
          }
        },
        loc: {
          start: {
            line: 29,
            column: 60
          },
          end: {
            line: 72,
            column: 6
          }
        },
        line: 29
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 32,
            column: 31
          },
          end: {
            line: 32,
            column: 32
          }
        },
        loc: {
          start: {
            line: 32,
            column: 49
          },
          end: {
            line: 34,
            column: 10
          }
        },
        line: 32
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 76,
            column: 19
          },
          end: {
            line: 76,
            column: 20
          }
        },
        loc: {
          start: {
            line: 76,
            column: 31
          },
          end: {
            line: 81,
            column: 4
          }
        },
        line: 76
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 82,
            column: 20
          },
          end: {
            line: 82,
            column: 21
          }
        },
        loc: {
          start: {
            line: 82,
            column: 36
          },
          end: {
            line: 93,
            column: 4
          }
        },
        line: 82
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 94,
            column: 22
          },
          end: {
            line: 94,
            column: 23
          }
        },
        loc: {
          start: {
            line: 94,
            column: 34
          },
          end: {
            line: 98,
            column: 4
          }
        },
        line: 94
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 100,
            column: 26
          },
          end: {
            line: 100,
            column: 27
          }
        },
        loc: {
          start: {
            line: 100,
            column: 42
          },
          end: {
            line: 139,
            column: 4
          }
        },
        line: 100
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 112,
            column: 8
          },
          end: {
            line: 112,
            column: 9
          }
        },
        loc: {
          start: {
            line: 112,
            column: 21
          },
          end: {
            line: 138,
            column: 6
          }
        },
        line: 112
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 125,
            column: 96
          },
          end: {
            line: 125,
            column: 97
          }
        },
        loc: {
          start: {
            line: 125,
            column: 104
          },
          end: {
            line: 137,
            column: 8
          }
        },
        line: 125
      },
      '11': {
        name: 'CategoriesBudgetCtrl_edit',
        decl: {
          start: {
            line: 142,
            column: 10
          },
          end: {
            line: 142,
            column: 35
          }
        },
        loc: {
          start: {
            line: 142,
            column: 86
          },
          end: {
            line: 173,
            column: 2
          }
        },
        line: 142
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 144,
            column: 25
          },
          end: {
            line: 144,
            column: 26
          }
        },
        loc: {
          start: {
            line: 144,
            column: 37
          },
          end: {
            line: 169,
            column: 4
          }
        },
        line: 144
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 165,
            column: 98
          },
          end: {
            line: 165,
            column: 99
          }
        },
        loc: {
          start: {
            line: 165,
            column: 104
          },
          end: {
            line: 168,
            column: 6
          }
        },
        line: 165
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 170,
            column: 23
          },
          end: {
            line: 170,
            column: 24
          }
        },
        loc: {
          start: {
            line: 170,
            column: 35
          },
          end: {
            line: 172,
            column: 4
          }
        },
        line: 170
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        }, {
          start: {
            line: 4,
            column: 3
          },
          end: {
            line: 6,
            column: 4
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 33,
            column: 18
          },
          end: {
            line: 33,
            column: 58
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 33,
            column: 52
          },
          end: {
            line: 33,
            column: 53
          }
        }, {
          start: {
            line: 33,
            column: 56
          },
          end: {
            line: 33,
            column: 58
          }
        }],
        line: 33
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 19
          },
          end: {
            line: 44,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 44,
            column: 47
          },
          end: {
            line: 44,
            column: 54
          }
        }, {
          start: {
            line: 44,
            column: 57
          },
          end: {
            line: 44,
            column: 66
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 50,
            column: 33
          },
          end: {
            line: 50,
            column: 146
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 50,
            column: 61
          },
          end: {
            line: 50,
            column: 141
          }
        }, {
          start: {
            line: 50,
            column: 144
          },
          end: {
            line: 50,
            column: 146
          }
        }],
        line: 50
      },
      '4': {
        loc: {
          start: {
            line: 51,
            column: 23
          },
          end: {
            line: 51,
            column: 139
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 51,
            column: 51
          },
          end: {
            line: 51,
            column: 134
          }
        }, {
          start: {
            line: 51,
            column: 137
          },
          end: {
            line: 51,
            column: 139
          }
        }],
        line: 51
      },
      '5': {
        loc: {
          start: {
            line: 55,
            column: 11
          },
          end: {
            line: 58,
            column: 12
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 11
          },
          end: {
            line: 58,
            column: 12
          }
        }, {
          start: {
            line: 55,
            column: 11
          },
          end: {
            line: 58,
            column: 12
          }
        }],
        line: 55
      },
      '6': {
        loc: {
          start: {
            line: 59,
            column: 11
          },
          end: {
            line: 62,
            column: 12
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 11
          },
          end: {
            line: 62,
            column: 12
          }
        }, {
          start: {
            line: 59,
            column: 11
          },
          end: {
            line: 62,
            column: 12
          }
        }],
        line: 59
      },
      '7': {
        loc: {
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 67,
            column: 12
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 67,
            column: 12
          }
        }, {
          start: {
            line: 64,
            column: 11
          },
          end: {
            line: 67,
            column: 12
          }
        }],
        line: 64
      },
      '8': {
        loc: {
          start: {
            line: 113,
            column: 7
          },
          end: {
            line: 115,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 113,
            column: 7
          },
          end: {
            line: 115,
            column: 8
          }
        }, {
          start: {
            line: 113,
            column: 7
          },
          end: {
            line: 115,
            column: 8
          }
        }],
        line: 113
      },
      '9': {
        loc: {
          start: {
            line: 126,
            column: 9
          },
          end: {
            line: 136,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 9
          },
          end: {
            line: 136,
            column: 10
          }
        }, {
          start: {
            line: 126,
            column: 9
          },
          end: {
            line: 136,
            column: 10
          }
        }],
        line: 126
      },
      '10': {
        loc: {
          start: {
            line: 152,
            column: 7
          },
          end: {
            line: 155,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 152,
            column: 7
          },
          end: {
            line: 155,
            column: 8
          }
        }, {
          start: {
            line: 152,
            column: 7
          },
          end: {
            line: 155,
            column: 8
          }
        }],
        line: 152
      },
      '11': {
        loc: {
          start: {
            line: 152,
            column: 11
          },
          end: {
            line: 152,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 152,
            column: 11
          },
          end: {
            line: 152,
            column: 15
          }
        }, {
          start: {
            line: 152,
            column: 19
          },
          end: {
            line: 152,
            column: 45
          }
        }],
        line: 152
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_3ihp149tg.s[0]++;

angular.module('app').controller('CategoriesBudgetCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $deltaCategoriesBudget) {
  cov_3ihp149tg.f[0]++;
  cov_3ihp149tg.s[1]++;

  $rootScope.processPageRight('1_11');
  cov_3ihp149tg.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_3ihp149tg.b[0][0]++;
    cov_3ihp149tg.s[3]++;

    return;
  } else {
    cov_3ihp149tg.b[0][1]++;
  }
  cov_3ihp149tg.s[4]++;
  $scope.canAddCategorie = $rootScope.currentRight.u;
  cov_3ihp149tg.s[5]++;
  $scope.add = false;
  cov_3ihp149tg.s[6]++;
  $scope.edit = false;
  cov_3ihp149tg.s[7]++;
  $scope.btnAction = false;
  cov_3ihp149tg.s[8]++;
  $scope.listCategories = [];
  cov_3ihp149tg.s[9]++;
  $scope.PATH = 'CategoriesBudget';

  cov_3ihp149tg.s[10]++;
  $scope.tableParams = {};

  cov_3ihp149tg.s[11]++;
  $scope.printData = function () {
    cov_3ihp149tg.f[1]++;
    cov_3ihp149tg.s[12]++;

    $rootScope.printReport({
      link: $scope.PATH,
      params: { get: 'print' },
      title: $translate.instant('MENU.C_B')
    });
  };

  var project = (cov_3ihp149tg.s[13]++, $rootScope.getCurrentProject());
  cov_3ihp149tg.s[14]++;
  $scope.getAll = function () {
    cov_3ihp149tg.f[2]++;
    cov_3ihp149tg.s[15]++;

    $scope.isloading = true;
    cov_3ihp149tg.s[16]++;
    $CRUDService.getAll($scope.PATH, { get: 'all' }, function (data) {
      cov_3ihp149tg.f[3]++;
      cov_3ihp149tg.s[17]++;

      $scope.listCategories = data;
      cov_3ihp149tg.s[18]++;
      $scope.params = {
        dataSource: data.sort(function (cat_1, cat_2) {
          cov_3ihp149tg.f[4]++;
          cov_3ihp149tg.s[19]++;

          return cat_1.CODE_CAT > cat_2.CODE_CAT ? (cov_3ihp149tg.b[1][0]++, 1) : (cov_3ihp149tg.b[1][1]++, -1);
        }),
        cellSelected: function cellSelected(args) {
          /* if (!items) {
            return;
          }
          if (items.length == 0) {
            return;
          }
          $scope.setEdit(items[0]); */
        },

        toolbar: $rootScope.currentRight.u ? (cov_3ihp149tg.b[2][0]++, ['Add']) : (cov_3ihp149tg.b[2][1]++, undefined),
        editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
        columns: [{ field: 'CODE_CAT', width: 200, headerText: $translate.instant('CATEGORIE_BUDGET.CODE'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'LABEL_CAT', headerText: $translate.instant('CATEGORIE_BUDGET.LABEL'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { headerText: '', width: 120,
          commands: [].concat($rootScope.currentRight.u ? (cov_3ihp149tg.b[3][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_3ihp149tg.b[3][1]++, [])).concat($rootScope.currentRight.d ? (cov_3ihp149tg.b[4][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_3ihp149tg.b[4][1]++, []))
        }],
        actionBegin: function actionBegin(action) {
          cov_3ihp149tg.s[20]++;

          if (action.requestType === 'add') {
            cov_3ihp149tg.b[5][0]++;
            cov_3ihp149tg.s[21]++;

            action.cancel = true;
            cov_3ihp149tg.s[22]++;
            $scope.addnew();
          } else {
            cov_3ihp149tg.b[5][1]++;
          }
          cov_3ihp149tg.s[23]++;
          if (action.requestType === 'beginEdit') {
            cov_3ihp149tg.b[6][0]++;
            cov_3ihp149tg.s[24]++;

            action.cancel = true;
            cov_3ihp149tg.s[25]++;
            $scope.setEdit(action.rowData);
          } else {
            cov_3ihp149tg.b[6][1]++;
          }

          cov_3ihp149tg.s[26]++;
          if (action.requestType === 'delete') {
            cov_3ihp149tg.b[7][0]++;
            cov_3ihp149tg.s[27]++;

            action.cancel = true;
            cov_3ihp149tg.s[28]++;
            $scope.setDeleteData(action.data[0]);
          } else {
            cov_3ihp149tg.b[7][1]++;
          }
        }
      };
      cov_3ihp149tg.s[29]++;
      $scope.isloading = false;
      cov_3ihp149tg.s[30]++;
      $deltaCategoriesBudget.clearData();
    });
  };
  cov_3ihp149tg.s[31]++;
  $scope.getAll();

  cov_3ihp149tg.s[32]++;
  $scope.addnew = function () {
    cov_3ihp149tg.f[5]++;
    cov_3ihp149tg.s[33]++;

    $scope.setEdit({
      LABEL_CAT: '',
      id: 0
    });
  };
  cov_3ihp149tg.s[34]++;
  $scope.setEdit = function (item) {
    cov_3ihp149tg.f[6]++;
    cov_3ihp149tg.s[35]++;

    $scope.error_code = false;
    cov_3ihp149tg.s[36]++;
    $scope.validateItem = angular.copy(item);
    cov_3ihp149tg.s[37]++;
    $uibModal.open({
      templateUrl: 'app/views/categories_budget/edit.html',
      controller: CategoriesBudgetCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_3ihp149tg.s[38]++;
  $scope.setCancel = function () {
    cov_3ihp149tg.f[7]++;
    cov_3ihp149tg.s[39]++;

    $scope.add = false;
    cov_3ihp149tg.s[40]++;
    $scope.edit = false;
    cov_3ihp149tg.s[41]++;
    $scope.btnAction = false;
  };

  cov_3ihp149tg.s[42]++;
  $scope.setDeleteData = function (item) {
    cov_3ihp149tg.f[8]++;
    cov_3ihp149tg.s[43]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_3ihp149tg.f[9]++;
      cov_3ihp149tg.s[44]++;

      if (isconfirm) {
        cov_3ihp149tg.b[8][0]++;
        cov_3ihp149tg.s[45]++;

        return;
      } else {
        cov_3ihp149tg.b[8][1]++;
      }
      cov_3ihp149tg.s[46]++;
      $scope.isloading = true;
      var validateItem = (cov_3ihp149tg.s[47]++, {
        id: item.id
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_3ihp149tg.s[48]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_3ihp149tg.f[10]++;
        cov_3ihp149tg.s[49]++;

        if (data < 0) {
          cov_3ihp149tg.b[9][0]++;
          cov_3ihp149tg.s[50]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_3ihp149tg.s[51]++;
          $scope.isloading = false;
        } else {
          cov_3ihp149tg.b[9][1]++;
          cov_3ihp149tg.s[52]++;

          $scope.setCancel();
          cov_3ihp149tg.s[53]++;
          $scope.getAll();
        }
      });
    });
  };
});

function CategoriesBudgetCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_3ihp149tg.f[11]++;
  cov_3ihp149tg.s[54]++;

  $scope.error_code = false;
  cov_3ihp149tg.s[55]++;
  $scope.setValidData = function () {
    cov_3ihp149tg.f[12]++;

    var validateItem = (cov_3ihp149tg.s[56]++, angular.copy($scope.validateItem));
    cov_3ihp149tg.s[57]++;
    $scope.error_code = 0;
    cov_3ihp149tg.s[58]++;
    validateItem.LABEL_CAT = validateItem.LABEL_CAT.trim();
    cov_3ihp149tg.s[59]++;
    validateItem.CODE_CAT = validateItem.CODE_CAT.trim().toUpperCase();

    var items_ = (cov_3ihp149tg.s[60]++, $filter('filter')($scope.listCategories, { CODE_CAT: validateItem.CODE_CAT }, true));
    cov_3ihp149tg.s[61]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = items_[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var item = _step.value;
        cov_3ihp149tg.s[62]++;

        if ((cov_3ihp149tg.b[11][0]++, item) && (cov_3ihp149tg.b[11][1]++, item.id != validateItem.id)) {
          cov_3ihp149tg.b[10][0]++;
          cov_3ihp149tg.s[63]++;

          $scope.error_code = 1;
          cov_3ihp149tg.s[64]++;
          return;
        } else {
          cov_3ihp149tg.b[10][1]++;
        }
      }
      /*
           const items = $filter('filter')($scope.listCategories, {LABEL_CAT: validateItem.LABEL_CAT}, true);
           for (const item of items) {
             if (item && item.id != validateItem.id) {
               $scope.error_code = 2;
               return;
             }
           } */
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_3ihp149tg.s[65]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_3ihp149tg.f[13]++;
      cov_3ihp149tg.s[66]++;

      $scope.closeModal();
      cov_3ihp149tg.s[67]++;
      $scope.getAll();
    });
  };
  cov_3ihp149tg.s[68]++;
  $scope.closeModal = function () {
    cov_3ihp149tg.f[14]++;
    cov_3ihp149tg.s[69]++;

    $uibModalInstance.dismiss('cancel');
  };
}
'use strict';

var cov_bb04sl6b = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/category_indicateur/categoryIndicatorsCtrl.js',
      hash = 'b9f9fbf7b1dd5d9a64b766eb85277feb239d500c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/category_indicateur/categoryIndicatorsCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 193,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 35
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '3': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '4': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '5': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 7,
          column: 29
        }
      },
      '6': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 13,
          column: 4
        }
      },
      '7': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 21,
          column: 5
        }
      },
      '8': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '9': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 49,
          column: 4
        }
      },
      '10': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '11': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 29
        }
      },
      '12': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 30
        }
      },
      '13': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 43,
          column: 7
        }
      },
      '14': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 29
        }
      },
      '15': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 40
        }
      },
      '16': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '17': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 45,
          column: 29
        }
      },
      '18': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 46,
          column: 42
        }
      },
      '19': {
        start: {
          line: 51,
          column: 2
        },
        end: {
          line: 51,
          column: 29
        }
      },
      '20': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 52,
          column: 29
        }
      },
      '21': {
        start: {
          line: 53,
          column: 2
        },
        end: {
          line: 59,
          column: 4
        }
      },
      '22': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 54,
          column: 28
        }
      },
      '23': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 58,
          column: 7
        }
      },
      '24': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 35
        }
      },
      '25': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 57,
          column: 31
        }
      },
      '26': {
        start: {
          line: 61,
          column: 2
        },
        end: {
          line: 67,
          column: 4
        }
      },
      '27': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 28
        }
      },
      '28': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '29': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 41
        }
      },
      '30': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 31
        }
      },
      '31': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 71,
          column: 4
        }
      },
      '32': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 31
        }
      },
      '33': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 77,
          column: 4
        }
      },
      '34': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 76,
          column: 7
        }
      },
      '35': {
        start: {
          line: 79,
          column: 2
        },
        end: {
          line: 103,
          column: 4
        }
      },
      '36': {
        start: {
          line: 80,
          column: 25
        },
        end: {
          line: 80,
          column: 58
        }
      },
      '37': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 81,
          column: 106
        }
      },
      '38': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 26
        }
      },
      '39': {
        start: {
          line: 83,
          column: 16
        },
        end: {
          line: 83,
          column: 131
        }
      },
      '40': {
        start: {
          line: 84,
          column: 4
        },
        end: {
          line: 89,
          column: 5
        }
      },
      '41': {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '42': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 86,
          column: 30
        }
      },
      '43': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 15
        }
      },
      '44': {
        start: {
          line: 90,
          column: 4
        },
        end: {
          line: 90,
          column: 130
        }
      },
      '45': {
        start: {
          line: 91,
          column: 4
        },
        end: {
          line: 96,
          column: 5
        }
      },
      '46': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      '47': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 30
        }
      },
      '48': {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 94,
          column: 15
        }
      },
      '49': {
        start: {
          line: 97,
          column: 4
        },
        end: {
          line: 97,
          column: 101
        }
      },
      '50': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 102,
          column: 7
        }
      },
      '51': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 33
        }
      },
      '52': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 22
        }
      },
      '53': {
        start: {
          line: 105,
          column: 2
        },
        end: {
          line: 135,
          column: 4
        }
      },
      '54': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 134,
          column: 7
        }
      },
      '55': {
        start: {
          line: 118,
          column: 6
        },
        end: {
          line: 120,
          column: 7
        }
      },
      '56': {
        start: {
          line: 119,
          column: 8
        },
        end: {
          line: 119,
          column: 15
        }
      },
      '57': {
        start: {
          line: 121,
          column: 6
        },
        end: {
          line: 121,
          column: 30
        }
      },
      '58': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 133,
          column: 9
        }
      },
      '59': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 130,
          column: 9
        }
      },
      '60': {
        start: {
          line: 124,
          column: 10
        },
        end: {
          line: 127,
          column: 13
        }
      },
      '61': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 35
        }
      },
      '62': {
        start: {
          line: 129,
          column: 10
        },
        end: {
          line: 129,
          column: 17
        }
      },
      '63': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 33
        }
      },
      '64': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 24
        }
      },
      '65': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 151,
          column: 4
        }
      },
      '66': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 138,
          column: 28
        }
      },
      '67': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 150,
          column: 13
        }
      },
      '68': {
        start: {
          line: 140,
          column: 6
        },
        end: {
          line: 140,
          column: 31
        }
      },
      '69': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 47
        }
      },
      '70': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 172
        }
      },
      '71': {
        start: {
          line: 143,
          column: 6
        },
        end: {
          line: 148,
          column: 7
        }
      },
      '72': {
        start: {
          line: 144,
          column: 8
        },
        end: {
          line: 144,
          column: 32
        }
      },
      '73': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 53
        }
      },
      '74': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 41
        }
      },
      '75': {
        start: {
          line: 149,
          column: 6
        },
        end: {
          line: 149,
          column: 33
        }
      },
      '76': {
        start: {
          line: 153,
          column: 2
        },
        end: {
          line: 160,
          column: 4
        }
      },
      '77': {
        start: {
          line: 154,
          column: 4
        },
        end: {
          line: 159,
          column: 7
        }
      },
      '78': {
        start: {
          line: 162,
          column: 2
        },
        end: {
          line: 172,
          column: 4
        }
      },
      '79': {
        start: {
          line: 163,
          column: 4
        },
        end: {
          line: 163,
          column: 47
        }
      },
      '80': {
        start: {
          line: 164,
          column: 4
        },
        end: {
          line: 171,
          column: 7
        }
      },
      '81': {
        start: {
          line: 174,
          column: 2
        },
        end: {
          line: 180,
          column: 4
        }
      },
      '82': {
        start: {
          line: 175,
          column: 18
        },
        end: {
          line: 175,
          column: 59
        }
      },
      '83': {
        start: {
          line: 176,
          column: 4
        },
        end: {
          line: 179,
          column: 5
        }
      },
      '84': {
        start: {
          line: 177,
          column: 6
        },
        end: {
          line: 177,
          column: 51
        }
      },
      '85': {
        start: {
          line: 178,
          column: 6
        },
        end: {
          line: 178,
          column: 42
        }
      },
      '86': {
        start: {
          line: 182,
          column: 2
        },
        end: {
          line: 190,
          column: 4
        }
      },
      '87': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 188,
          column: 5
        }
      },
      '88': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 187,
          column: 7
        }
      },
      '89': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 30
        }
      },
      '90': {
        start: {
          line: 186,
          column: 8
        },
        end: {
          line: 186,
          column: 15
        }
      },
      '91': {
        start: {
          line: 189,
          column: 4
        },
        end: {
          line: 189,
          column: 43
        }
      },
      '92': {
        start: {
          line: 192,
          column: 2
        },
        end: {
          line: 192,
          column: 18
        }
      },
      '93': {
        start: {
          line: 197,
          column: 2
        },
        end: {
          line: 220,
          column: 4
        }
      },
      '94': {
        start: {
          line: 198,
          column: 25
        },
        end: {
          line: 198,
          column: 60
        }
      },
      '95': {
        start: {
          line: 199,
          column: 4
        },
        end: {
          line: 199,
          column: 64
        }
      },
      '96': {
        start: {
          line: 200,
          column: 4
        },
        end: {
          line: 200,
          column: 26
        }
      },
      '97': {
        start: {
          line: 201,
          column: 16
        },
        end: {
          line: 201,
          column: 95
        }
      },
      '98': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 207,
          column: 5
        }
      },
      '99': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 206,
          column: 7
        }
      },
      '100': {
        start: {
          line: 204,
          column: 8
        },
        end: {
          line: 204,
          column: 30
        }
      },
      '101': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 205,
          column: 15
        }
      },
      '102': {
        start: {
          line: 208,
          column: 4
        },
        end: {
          line: 208,
          column: 94
        }
      },
      '103': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 214,
          column: 5
        }
      },
      '104': {
        start: {
          line: 210,
          column: 6
        },
        end: {
          line: 213,
          column: 7
        }
      },
      '105': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 211,
          column: 30
        }
      },
      '106': {
        start: {
          line: 212,
          column: 8
        },
        end: {
          line: 212,
          column: 15
        }
      },
      '107': {
        start: {
          line: 215,
          column: 4
        },
        end: {
          line: 217,
          column: 5
        }
      },
      '108': {
        start: {
          line: 216,
          column: 6
        },
        end: {
          line: 216,
          column: 50
        }
      },
      '109': {
        start: {
          line: 218,
          column: 4
        },
        end: {
          line: 218,
          column: 40
        }
      },
      '110': {
        start: {
          line: 219,
          column: 4
        },
        end: {
          line: 219,
          column: 24
        }
      },
      '111': {
        start: {
          line: 221,
          column: 2
        },
        end: {
          line: 223,
          column: 4
        }
      },
      '112': {
        start: {
          line: 222,
          column: 4
        },
        end: {
          line: 222,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 59
          },
          end: {
            line: 1,
            column: 60
          }
        },
        loc: {
          start: {
            line: 1,
            column: 184
          },
          end: {
            line: 193,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 22
          },
          end: {
            line: 15,
            column: 23
          }
        },
        loc: {
          start: {
            line: 15,
            column: 28
          },
          end: {
            line: 21,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 53,
            column: 18
          },
          end: {
            line: 53,
            column: 19
          }
        },
        loc: {
          start: {
            line: 53,
            column: 30
          },
          end: {
            line: 59,
            column: 3
          }
        },
        line: 53
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 55,
            column: 56
          },
          end: {
            line: 55,
            column: 57
          }
        },
        loc: {
          start: {
            line: 55,
            column: 64
          },
          end: {
            line: 58,
            column: 5
          }
        },
        line: 55
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 61,
            column: 25
          },
          end: {
            line: 61,
            column: 26
          }
        },
        loc: {
          start: {
            line: 61,
            column: 39
          },
          end: {
            line: 67,
            column: 3
          }
        },
        line: 61
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 63,
            column: 55
          },
          end: {
            line: 63,
            column: 56
          }
        },
        loc: {
          start: {
            line: 63,
            column: 63
          },
          end: {
            line: 66,
            column: 5
          }
        },
        line: 63
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 69,
            column: 18
          },
          end: {
            line: 69,
            column: 19
          }
        },
        loc: {
          start: {
            line: 69,
            column: 30
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 69
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 73,
            column: 24
          },
          end: {
            line: 73,
            column: 25
          }
        },
        loc: {
          start: {
            line: 73,
            column: 36
          },
          end: {
            line: 77,
            column: 3
          }
        },
        line: 73
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 79,
            column: 24
          },
          end: {
            line: 79,
            column: 25
          }
        },
        loc: {
          start: {
            line: 79,
            column: 36
          },
          end: {
            line: 103,
            column: 3
          }
        },
        line: 79
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 99,
            column: 132
          },
          end: {
            line: 99,
            column: 133
          }
        },
        loc: {
          start: {
            line: 99,
            column: 140
          },
          end: {
            line: 102,
            column: 5
          }
        },
        line: 99
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 105,
            column: 22
          },
          end: {
            line: 105,
            column: 23
          }
        },
        loc: {
          start: {
            line: 105,
            column: 38
          },
          end: {
            line: 135,
            column: 3
          }
        },
        line: 105
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 117,
            column: 7
          },
          end: {
            line: 117,
            column: 8
          }
        },
        loc: {
          start: {
            line: 117,
            column: 20
          },
          end: {
            line: 134,
            column: 5
          }
        },
        line: 117
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 122,
            column: 79
          },
          end: {
            line: 122,
            column: 80
          }
        },
        loc: {
          start: {
            line: 122,
            column: 87
          },
          end: {
            line: 133,
            column: 7
          }
        },
        line: 122
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 137,
            column: 20
          },
          end: {
            line: 137,
            column: 21
          }
        },
        loc: {
          start: {
            line: 137,
            column: 36
          },
          end: {
            line: 151,
            column: 3
          }
        },
        line: 137
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 139,
            column: 15
          },
          end: {
            line: 139,
            column: 16
          }
        },
        loc: {
          start: {
            line: 139,
            column: 21
          },
          end: {
            line: 150,
            column: 5
          }
        },
        line: 139
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 153,
            column: 21
          },
          end: {
            line: 153,
            column: 22
          }
        },
        loc: {
          start: {
            line: 153,
            column: 33
          },
          end: {
            line: 160,
            column: 3
          }
        },
        line: 153
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 162,
            column: 22
          },
          end: {
            line: 162,
            column: 23
          }
        },
        loc: {
          start: {
            line: 162,
            column: 38
          },
          end: {
            line: 172,
            column: 3
          }
        },
        line: 162
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 174,
            column: 24
          },
          end: {
            line: 174,
            column: 25
          }
        },
        loc: {
          start: {
            line: 174,
            column: 40
          },
          end: {
            line: 180,
            column: 3
          }
        },
        line: 174
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 182,
            column: 26
          },
          end: {
            line: 182,
            column: 27
          }
        },
        loc: {
          start: {
            line: 182,
            column: 42
          },
          end: {
            line: 190,
            column: 3
          }
        },
        line: 182
      },
      '19': {
        name: 'CategoryIndicatorsCtrl_edit',
        decl: {
          start: {
            line: 195,
            column: 9
          },
          end: {
            line: 195,
            column: 36
          }
        },
        loc: {
          start: {
            line: 195,
            column: 73
          },
          end: {
            line: 224,
            column: 1
          }
        },
        line: 195
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 197,
            column: 26
          },
          end: {
            line: 197,
            column: 27
          }
        },
        loc: {
          start: {
            line: 197,
            column: 38
          },
          end: {
            line: 220,
            column: 3
          }
        },
        line: 197
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 221,
            column: 22
          },
          end: {
            line: 221,
            column: 23
          }
        },
        loc: {
          start: {
            line: 221,
            column: 34
          },
          end: {
            line: 223,
            column: 3
          }
        },
        line: 221
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 14
          },
          end: {
            line: 24,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 24,
            column: 42
          },
          end: {
            line: 24,
            column: 49
          }
        }, {
          start: {
            line: 24,
            column: 52
          },
          end: {
            line: 24,
            column: 61
          }
        }],
        line: 24
      },
      '2': {
        loc: {
          start: {
            line: 31,
            column: 28
          },
          end: {
            line: 31,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 31,
            column: 56
          },
          end: {
            line: 31,
            column: 136
          }
        }, {
          start: {
            line: 31,
            column: 139
          },
          end: {
            line: 31,
            column: 141
          }
        }],
        line: 31
      },
      '3': {
        loc: {
          start: {
            line: 32,
            column: 18
          },
          end: {
            line: 32,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 32,
            column: 46
          },
          end: {
            line: 32,
            column: 129
          }
        }, {
          start: {
            line: 32,
            column: 132
          },
          end: {
            line: 32,
            column: 134
          }
        }],
        line: 32
      },
      '4': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }],
        line: 36
      },
      '5': {
        loc: {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }, {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 43,
            column: 7
          }
        }],
        line: 40
      },
      '6': {
        loc: {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 47,
            column: 7
          }
        }],
        line: 44
      },
      '7': {
        loc: {
          start: {
            line: 81,
            column: 46
          },
          end: {
            line: 81,
            column: 90
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 81,
            column: 46
          },
          end: {
            line: 81,
            column: 84
          }
        }, {
          start: {
            line: 81,
            column: 88
          },
          end: {
            line: 81,
            column: 90
          }
        }],
        line: 81
      },
      '8': {
        loc: {
          start: {
            line: 85,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }, {
          start: {
            line: 85,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }],
        line: 85
      },
      '9': {
        loc: {
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 85,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 85,
            column: 14
          }
        }, {
          start: {
            line: 85,
            column: 18
          },
          end: {
            line: 85,
            column: 44
          }
        }],
        line: 85
      },
      '10': {
        loc: {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }, {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }],
        line: 92
      },
      '11': {
        loc: {
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 92,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 92,
            column: 14
          }
        }, {
          start: {
            line: 92,
            column: 18
          },
          end: {
            line: 92,
            column: 44
          }
        }],
        line: 92
      },
      '12': {
        loc: {
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        }, {
          start: {
            line: 118,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        }],
        line: 118
      },
      '13': {
        loc: {
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }, {
          start: {
            line: 123,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }],
        line: 123
      },
      '14': {
        loc: {
          start: {
            line: 142,
            column: 62
          },
          end: {
            line: 142,
            column: 167
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 142,
            column: 62
          },
          end: {
            line: 142,
            column: 161
          }
        }, {
          start: {
            line: 142,
            column: 165
          },
          end: {
            line: 142,
            column: 167
          }
        }],
        line: 142
      },
      '15': {
        loc: {
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 148,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 148,
            column: 7
          }
        }, {
          start: {
            line: 143,
            column: 6
          },
          end: {
            line: 148,
            column: 7
          }
        }],
        line: 143
      },
      '16': {
        loc: {
          start: {
            line: 176,
            column: 4
          },
          end: {
            line: 179,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 176,
            column: 4
          },
          end: {
            line: 179,
            column: 5
          }
        }, {
          start: {
            line: 176,
            column: 4
          },
          end: {
            line: 179,
            column: 5
          }
        }],
        line: 176
      },
      '17': {
        loc: {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        }, {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        }],
        line: 184
      },
      '18': {
        loc: {
          start: {
            line: 199,
            column: 25
          },
          end: {
            line: 199,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 199,
            column: 25
          },
          end: {
            line: 199,
            column: 42
          }
        }, {
          start: {
            line: 199,
            column: 46
          },
          end: {
            line: 199,
            column: 48
          }
        }],
        line: 199
      },
      '19': {
        loc: {
          start: {
            line: 203,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 203,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        }, {
          start: {
            line: 203,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        }],
        line: 203
      },
      '20': {
        loc: {
          start: {
            line: 203,
            column: 10
          },
          end: {
            line: 203,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 203,
            column: 10
          },
          end: {
            line: 203,
            column: 14
          }
        }, {
          start: {
            line: 203,
            column: 18
          },
          end: {
            line: 203,
            column: 44
          }
        }],
        line: 203
      },
      '21': {
        loc: {
          start: {
            line: 210,
            column: 6
          },
          end: {
            line: 213,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 210,
            column: 6
          },
          end: {
            line: 213,
            column: 7
          }
        }, {
          start: {
            line: 210,
            column: 6
          },
          end: {
            line: 213,
            column: 7
          }
        }],
        line: 210
      },
      '22': {
        loc: {
          start: {
            line: 210,
            column: 10
          },
          end: {
            line: 210,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 210,
            column: 10
          },
          end: {
            line: 210,
            column: 14
          }
        }, {
          start: {
            line: 210,
            column: 18
          },
          end: {
            line: 210,
            column: 44
          }
        }],
        line: 210
      },
      '23': {
        loc: {
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 217,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 217,
            column: 5
          }
        }, {
          start: {
            line: 215,
            column: 4
          },
          end: {
            line: 217,
            column: 5
          }
        }],
        line: 215
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_bb04sl6b.s[0]++;
angular.module('app').controller('CategoryIndicatorsCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $deltaUnite) {
  cov_bb04sl6b.f[0]++;

  var PATH = (cov_bb04sl6b.s[1]++, 'CategorieIndicator');
  cov_bb04sl6b.s[2]++;
  $rootScope.processPageRight('3_6');
  cov_bb04sl6b.s[3]++;
  if ($rootScope.global_access_page_denied) {
    cov_bb04sl6b.b[0][0]++;
    cov_bb04sl6b.s[4]++;

    return;
  } else {
    cov_bb04sl6b.b[0][1]++;
  }
  cov_bb04sl6b.s[5]++;
  $scope.selectedItem = null;
  cov_bb04sl6b.s[6]++;
  $scope.aggregateList = [{ name: $translate.instant('INDICATOR.SUM'), id: '1' }, { name: $translate.instant('INDICATOR.MOY'), id: '2' }, { name: $translate.instant('INDICATOR.MAXI'), id: '3' }, { name: $translate.instant('INDICATOR.MINI'), id: '4' }];

  cov_bb04sl6b.s[7]++;
  $scope.printData = function () {
    cov_bb04sl6b.f[1]++;
    cov_bb04sl6b.s[8]++;

    $rootScope.printReport({
      link: PATH,
      params: { get: 'print' },
      title: $translate.instant('MENU_SHORT.C_I')
    });
  };

  cov_bb04sl6b.s[9]++;
  $scope.params = {
    toolbar: $rootScope.currentRight.u ? (cov_bb04sl6b.b[1][0]++, ['Add']) : (cov_bb04sl6b.b[1][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ field: 'CODE_INDICATEUR_CATEGORIE', width: 150, headerText: $translate.instant('INDICATOR_CAT.CODE').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'LABEL_INDICATEUR_CATEGORIE', headerText: $translate.instant('INDICATOR_CAT.LABEL').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'items', headerText: $translate.instant('INDICATOR_CAT.OPTIONS').toUpperCase(), type: 'string', clipMode: 'EllipsisWithTooltip' }, { headerText: '', width: 120,
      commands: [].concat($rootScope.currentRight.u ? (cov_bb04sl6b.b[2][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_bb04sl6b.b[2][1]++, [])).concat($rootScope.currentRight.d ? (cov_bb04sl6b.b[3][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_bb04sl6b.b[3][1]++, []))
    }],
    actionBegin: function actionBegin(action) {
      cov_bb04sl6b.s[10]++;

      if (action.requestType === 'add') {
        cov_bb04sl6b.b[4][0]++;
        cov_bb04sl6b.s[11]++;

        action.cancel = true;
        cov_bb04sl6b.s[12]++;
        $scope.addCategorie();
      } else {
        cov_bb04sl6b.b[4][1]++;
      }
      cov_bb04sl6b.s[13]++;
      if (action.requestType === 'beginEdit') {
        cov_bb04sl6b.b[5][0]++;
        cov_bb04sl6b.s[14]++;

        action.cancel = true;
        cov_bb04sl6b.s[15]++;
        $scope.editItem(action.rowData);
      } else {
        cov_bb04sl6b.b[5][1]++;
      }
      cov_bb04sl6b.s[16]++;
      if (action.requestType === 'delete') {
        cov_bb04sl6b.b[6][0]++;
        cov_bb04sl6b.s[17]++;

        action.cancel = true;
        cov_bb04sl6b.s[18]++;
        $scope.deleteItem(action.data[0]);
      } else {
        cov_bb04sl6b.b[6][1]++;
      }
    }
  };

  cov_bb04sl6b.s[19]++;
  $scope.listCategories = [];
  cov_bb04sl6b.s[20]++;
  $scope.deletedOptions = [];
  cov_bb04sl6b.s[21]++;
  $scope.getAll = function () {
    cov_bb04sl6b.f[2]++;
    cov_bb04sl6b.s[22]++;

    $scope.isloading = true;
    cov_bb04sl6b.s[23]++;
    $CRUDService.getAll(PATH, { get: 'all', long: true }, function (data) {
      cov_bb04sl6b.f[3]++;
      cov_bb04sl6b.s[24]++;

      $scope.listCategories = data;
      cov_bb04sl6b.s[25]++;
      $scope.isloading = false;
    });
  };

  cov_bb04sl6b.s[26]++;
  $scope.getAllOptions = function (id) {
    cov_bb04sl6b.f[4]++;
    cov_bb04sl6b.s[27]++;

    $scope.isloading = true;
    cov_bb04sl6b.s[28]++;
    $CRUDService.getAll(PATH, { get: 'all_option', id: id }, function (data) {
      cov_bb04sl6b.f[5]++;
      cov_bb04sl6b.s[29]++;

      $scope.selectedItem.options = data;
      cov_bb04sl6b.s[30]++;
      $scope.isloading = false;
    });
  };

  cov_bb04sl6b.s[31]++;
  $scope.cancel = function () {
    cov_bb04sl6b.f[6]++;
    cov_bb04sl6b.s[32]++;

    $scope.selectedItem = null;
  };

  cov_bb04sl6b.s[33]++;
  $scope.addCategorie = function () {
    cov_bb04sl6b.f[7]++;
    cov_bb04sl6b.s[34]++;

    $scope.editItem({
      AGGREGATION_INDICATEUR_CATEGORIE: '1'
    });
  };

  cov_bb04sl6b.s[35]++;
  $scope.validateItem = function () {
    cov_bb04sl6b.f[8]++;

    var validateItem = (cov_bb04sl6b.s[36]++, angular.copy($scope.selectedItem));
    cov_bb04sl6b.s[37]++;
    validateItem.CODE_INDICATEUR_CATEGORIE = ((cov_bb04sl6b.b[7][0]++, validateItem.CODE_INDICATEUR_CATEGORIE) || (cov_bb04sl6b.b[7][1]++, '')).toUpperCase();
    cov_bb04sl6b.s[38]++;
    $scope.error_code = 0;
    var items = (cov_bb04sl6b.s[39]++, $filter('filter')($scope.listCategories, { CODE_INDICATEUR_CATEGORIE: validateItem.CODE_INDICATEUR_CATEGORIE }, true));
    cov_bb04sl6b.s[40]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var item = _step.value;
        cov_bb04sl6b.s[41]++;

        if ((cov_bb04sl6b.b[9][0]++, item) && (cov_bb04sl6b.b[9][1]++, item.id != validateItem.id)) {
          cov_bb04sl6b.b[8][0]++;
          cov_bb04sl6b.s[42]++;

          $scope.error_code = 3;
          cov_bb04sl6b.s[43]++;
          return;
        } else {
          cov_bb04sl6b.b[8][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_bb04sl6b.s[44]++;
    items = $filter('filter')($scope.listCategories, { LABEL_INDICATEUR_CATEGORIE: validateItem.LABEL_INDICATEUR_CATEGORIE }, true);
    cov_bb04sl6b.s[45]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var _item = _step2.value;
        cov_bb04sl6b.s[46]++;

        if ((cov_bb04sl6b.b[11][0]++, _item) && (cov_bb04sl6b.b[11][1]++, _item.id != validateItem.id)) {
          cov_bb04sl6b.b[10][0]++;
          cov_bb04sl6b.s[47]++;

          $scope.error_code = 4;
          cov_bb04sl6b.s[48]++;
          return;
        } else {
          cov_bb04sl6b.b[10][1]++;
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_bb04sl6b.s[49]++;
    validateItem.AGGREGATION_INDICATEUR_CATEGORIE = validateItem.AGGREGATION_INDICATEUR_CATEGORIE.id;
    // $scope.isloading = true;
    cov_bb04sl6b.s[50]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: angular.toJson(validateItem), di: angular.toJson($scope.deletedOptions) }, function (data) {
      cov_bb04sl6b.f[9]++;
      cov_bb04sl6b.s[51]++;

      $scope.selectedItem = null;
      cov_bb04sl6b.s[52]++;
      $scope.getAll();
    });
  };

  cov_bb04sl6b.s[53]++;
  $scope.deleteItem = function (item) {
    cov_bb04sl6b.f[10]++;
    cov_bb04sl6b.s[54]++;

    // supp
    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_bb04sl6b.f[11]++;
      cov_bb04sl6b.s[55]++;

      if (isconfirm) {
        cov_bb04sl6b.b[12][0]++;
        cov_bb04sl6b.s[56]++;

        return;
      } else {
        cov_bb04sl6b.b[12][1]++;
      }
      cov_bb04sl6b.s[57]++;
      $scope.isloading = true;
      cov_bb04sl6b.s[58]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: angular.toJson(item) }, function (data) {
        cov_bb04sl6b.f[12]++;
        cov_bb04sl6b.s[59]++;

        if (data < 0) {
          cov_bb04sl6b.b[13][0]++;
          cov_bb04sl6b.s[60]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_bb04sl6b.s[61]++;
          $scope.isloading = false;
          cov_bb04sl6b.s[62]++;
          return;
        } else {
          cov_bb04sl6b.b[13][1]++;
        }
        cov_bb04sl6b.s[63]++;
        $scope.isloading = false;
        cov_bb04sl6b.s[64]++;
        $scope.getAll();
      });
    });
  };

  cov_bb04sl6b.s[65]++;
  $scope.editItem = function (item) {
    cov_bb04sl6b.f[13]++;
    cov_bb04sl6b.s[66]++;

    $scope.isloading = true;
    cov_bb04sl6b.s[67]++;
    setTimeout(function () {
      cov_bb04sl6b.f[14]++;
      cov_bb04sl6b.s[68]++;

      $scope.isloading = false;
      cov_bb04sl6b.s[69]++;
      $scope.selectedItem = angular.copy(item);
      cov_bb04sl6b.s[70]++;
      $scope.selectedItem.AGGREGATION_INDICATEUR_CATEGORIE = ((cov_bb04sl6b.b[14][0]++, $filter('filter')($scope.aggregateList, { id: $scope.selectedItem.AGGREGATION_INDICATEUR_CATEGORIE })) || (cov_bb04sl6b.b[14][1]++, []))[0];
      cov_bb04sl6b.s[71]++;
      if ($scope.selectedItem.id) {
        cov_bb04sl6b.b[15][0]++;
        cov_bb04sl6b.s[72]++;

        $scope.isloading = true;
        cov_bb04sl6b.s[73]++;
        $scope.getAllOptions($scope.selectedItem.id);
      } else {
        cov_bb04sl6b.b[15][1]++;
        cov_bb04sl6b.s[74]++;

        $scope.selectedItem.options = [];
      }
      cov_bb04sl6b.s[75]++;
      $scope.deletedOptions = [];
    }, 1000);
  };

  cov_bb04sl6b.s[76]++;
  $scope.addOption = function () {
    cov_bb04sl6b.f[15]++;
    cov_bb04sl6b.s[77]++;

    $scope.editOption({
      CODE: Date.newDate().getTime().toString().substr(-10, 10),
      LABEL: '',
      id: 0,
      check: 1
    });
  };

  cov_bb04sl6b.s[78]++;
  $scope.editOption = function (item) {
    cov_bb04sl6b.f[16]++;
    cov_bb04sl6b.s[79]++;

    $scope.selectedOption = angular.copy(item);
    cov_bb04sl6b.s[80]++;
    $uibModal.open({
      templateUrl: 'app/views/category_indicateur/edit_option.html',
      controller: CategoryIndicatorsCtrl_edit,
      scope: $scope,
      backdrop: 'static',
      size: 'sm_',
      windowClass: 'animated fadeInRight  left-modal'
    });
  };

  cov_bb04sl6b.s[81]++;
  $scope.deleteOption = function (item) {
    cov_bb04sl6b.f[17]++;

    var index = (cov_bb04sl6b.s[82]++, $scope.selectedItem.options.indexOf(item));
    cov_bb04sl6b.s[83]++;
    if (index >= 0) {
      cov_bb04sl6b.b[16][0]++;
      cov_bb04sl6b.s[84]++;

      $scope.selectedItem.options.splice(index, 1);
      cov_bb04sl6b.s[85]++;
      $scope.deletedOptions.push(item.id);
    } else {
      cov_bb04sl6b.b[16][1]++;
    }
  };

  cov_bb04sl6b.s[86]++;
  $scope.processOptions = function (item) {
    cov_bb04sl6b.f[18]++;
    cov_bb04sl6b.s[87]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = $scope.selectedItem.options[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step3.value;
        cov_bb04sl6b.s[88]++;

        if (el.id == item.id) {
          cov_bb04sl6b.b[17][0]++;
          cov_bb04sl6b.s[89]++;

          el.LABEL = item.LABEL;
          cov_bb04sl6b.s[90]++;
          return;
        } else {
          cov_bb04sl6b.b[17][1]++;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_bb04sl6b.s[91]++;
    $scope.selectedItem.options.push(item);
  };

  cov_bb04sl6b.s[92]++;
  $scope.getAll();
});

function CategoryIndicatorsCtrl_edit($scope, $uibModalInstance, $filter) {
  cov_bb04sl6b.f[19]++;
  cov_bb04sl6b.s[93]++;

  // $scope.selectedOption = {};
  $scope.validateOption = function () {
    cov_bb04sl6b.f[20]++;

    var validateItem = (cov_bb04sl6b.s[94]++, angular.copy($scope.selectedOption));
    cov_bb04sl6b.s[95]++;
    validateItem.CODE = ((cov_bb04sl6b.b[18][0]++, validateItem.CODE) || (cov_bb04sl6b.b[18][1]++, '')).toUpperCase();
    cov_bb04sl6b.s[96]++;
    $scope.error_code = 0;
    var items = (cov_bb04sl6b.s[97]++, $filter('filter')($scope.selectedItem.options, { CODE: validateItem.CODE }, true));
    cov_bb04sl6b.s[98]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = items[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var item = _step4.value;
        cov_bb04sl6b.s[99]++;

        if ((cov_bb04sl6b.b[20][0]++, item) && (cov_bb04sl6b.b[20][1]++, item.id != validateItem.id)) {
          cov_bb04sl6b.b[19][0]++;
          cov_bb04sl6b.s[100]++;

          $scope.error_code = 1;
          cov_bb04sl6b.s[101]++;
          return;
        } else {
          cov_bb04sl6b.b[19][1]++;
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    cov_bb04sl6b.s[102]++;
    items = $filter('filter')($scope.selectedItem.options, { LABEL: validateItem.LABEL }, true);
    cov_bb04sl6b.s[103]++;
    var _iteratorNormalCompletion5 = true;
    var _didIteratorError5 = false;
    var _iteratorError5 = undefined;

    try {
      for (var _iterator5 = items[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
        var _item2 = _step5.value;
        cov_bb04sl6b.s[104]++;

        if ((cov_bb04sl6b.b[22][0]++, _item2) && (cov_bb04sl6b.b[22][1]++, _item2.id != validateItem.id)) {
          cov_bb04sl6b.b[21][0]++;
          cov_bb04sl6b.s[105]++;

          $scope.error_code = 2;
          cov_bb04sl6b.s[106]++;
          return;
        } else {
          cov_bb04sl6b.b[21][1]++;
        }
      }
    } catch (err) {
      _didIteratorError5 = true;
      _iteratorError5 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion5 && _iterator5.return) {
          _iterator5.return();
        }
      } finally {
        if (_didIteratorError5) {
          throw _iteratorError5;
        }
      }
    }

    cov_bb04sl6b.s[107]++;
    if (validateItem.id == 0) {
      cov_bb04sl6b.b[23][0]++;
      cov_bb04sl6b.s[108]++;

      validateItem.id = -Date.newDate().getTime();
    } else {
      cov_bb04sl6b.b[23][1]++;
    }
    cov_bb04sl6b.s[109]++;
    $scope.processOptions(validateItem);
    cov_bb04sl6b.s[110]++;
    $scope.closeModal();
  };
  cov_bb04sl6b.s[111]++;
  $scope.closeModal = function () {
    cov_bb04sl6b.f[21]++;
    cov_bb04sl6b.s[112]++;

    $uibModalInstance.dismiss('cancel');
  };
}
'use strict';

var cov_8yq64vfod = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/routes.js',
      hash = '483bf292a45ec182ff4ddcda18102b34da8cbec3',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 11,
          column: 0
        },
        end: {
          line: 1114,
          column: 5
        }
      },
      '1': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 15,
          column: 50
        }
      },
      '2': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '3': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 1097,
          column: 9
        }
      },
      '4': {
        start: {
          line: 113,
          column: 12
        },
        end: {
          line: 122,
          column: 15
        }
      },
      '5': {
        start: {
          line: 133,
          column: 12
        },
        end: {
          line: 142,
          column: 15
        }
      },
      '6': {
        start: {
          line: 399,
          column: 12
        },
        end: {
          line: 399,
          column: 40
        }
      },
      '7': {
        start: {
          line: 413,
          column: 12
        },
        end: {
          line: 413,
          column: 40
        }
      },
      '8': {
        start: {
          line: 426,
          column: 12
        },
        end: {
          line: 426,
          column: 40
        }
      },
      '9': {
        start: {
          line: 1101,
          column: 4
        },
        end: {
          line: 1101,
          column: 31
        }
      },
      '10': {
        start: {
          line: 1102,
          column: 4
        },
        end: {
          line: 1107,
          column: 6
        }
      },
      '11': {
        start: {
          line: 1108,
          column: 4
        },
        end: {
          line: 1110,
          column: 6
        }
      },
      '12': {
        start: {
          line: 1109,
          column: 6
        },
        end: {
          line: 1109,
          column: 39
        }
      },
      '13': {
        start: {
          line: 1111,
          column: 4
        },
        end: {
          line: 1113,
          column: 6
        }
      },
      '14': {
        start: {
          line: 1112,
          column: 6
        },
        end: {
          line: 1112,
          column: 37
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 13,
            column: 10
          },
          end: {
            line: 13,
            column: 11
          }
        },
        loc: {
          start: {
            line: 13,
            column: 90
          },
          end: {
            line: 1099,
            column: 3
          }
        },
        line: 13
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 1100,
            column: 7
          },
          end: {
            line: 1100,
            column: 8
          }
        },
        loc: {
          start: {
            line: 1100,
            column: 31
          },
          end: {
            line: 1114,
            column: 3
          }
        },
        line: 1100
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 1108,
            column: 35
          },
          end: {
            line: 1108,
            column: 36
          }
        },
        loc: {
          start: {
            line: 1108,
            column: 47
          },
          end: {
            line: 1110,
            column: 5
          }
        },
        line: 1108
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 1111,
            column: 33
          },
          end: {
            line: 1111,
            column: 34
          }
        },
        loc: {
          start: {
            line: 1111,
            column: 45
          },
          end: {
            line: 1113,
            column: 5
          }
        },
        line: 1111
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 119,
            column: 18
          },
          end: {
            line: 119,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 119,
            column: 51
          },
          end: {
            line: 119,
            column: 53
          }
        }, {
          start: {
            line: 119,
            column: 56
          },
          end: {
            line: 119,
            column: 140
          }
        }],
        line: 119
      },
      '1': {
        loc: {
          start: {
            line: 139,
            column: 18
          },
          end: {
            line: 139,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 139,
            column: 51
          },
          end: {
            line: 139,
            column: 53
          }
        }, {
          start: {
            line: 139,
            column: 56
          },
          end: {
            line: 139,
            column: 140
          }
        }],
        line: 139
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_8yq64vfod.s[0]++;

/**
 * delta - Responsive Admin Theme
 *
 * delta theme use AngularUI Router to manage routing and views
 * Each view are defined as state.agencies
 * Initial there are writdesign_formten state for all view in theme.
 *Type_Activityactivity
 */

angular.module('app').config(function ($stateProvider, $urlRouterProvider, $locationProvider, $ocLazyLoadProvider) {
  cov_8yq64vfod.f[0]++;
  cov_8yq64vfod.s[1]++;

  // $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/app/projects');
  cov_8yq64vfod.s[2]++;
  $ocLazyLoadProvider.config({
    // Set to true if you want to see what and when is dynamically loaded
    debug: false
  });
  cov_8yq64vfod.s[3]++;
  $stateProvider.state('login', {
    url: '/login',
    templateUrl: 'app/views/login/login.html',
    data: { index: 1 }
  })
  /* .state('login', {
    url: '/login',
    templateUrl: 'app/views/login_cglua/main.html',
    data: {index: 1}
  }) */
  .state('loading', {
    url: '/loading',
    templateUrl: 'app/views/loading/template.html'
  }).state('index', {
    abstract: true,
    url: '/app',
    templateUrl: 'app/views/menu/app.html'

  }).state('index.portfolio', {
    url: '/portfolio',
    templateUrl: 'app/views/portfolio/view.html'
  }).state('index.sub-portfolio', {
    url: '/portfolio/:id',
    templateUrl: 'app/views/portfolio/view.html',
    params: {
      id: null
    }
  }).state('index.settings-portfolio', {
    abstract: true,
    url: '/settings',
    templateUrl: 'app/views/menu/settings.html'
  }).state('index.settings-portfolio.geographic_map', {
    url: '/geographic_map',
    templateUrl: 'app/views/geographic_map/view.html',
    data: { indexTrad: 'MENU.P_G' }
  }).state('index.settings-portfolio.financing', {
    url: '/financing',
    templateUrl: 'app/views/SourceFin/SourceFinView.html',
    data: { indexTrad: 'MENU.S_F' }
  }).state('index.settings-portfolio.agencies', {
    url: '/agencies',
    templateUrl: 'app/views/agencies/view.html',
    data: { indexTrad: 'MENU.AGENCIES' }
  }).state('index.settings-portfolio.layers', {
    url: '/layers',
    templateUrl: 'app/views/layer/view.html'
  }).state('index.users', {
    abstract: true,
    url: '/user',
    templateUrl: 'app/views/menu/user.html'
  }).state('index.users.mangement', {
    url: '/management',
    templateUrl: 'app/views/user/view.html'
  }).state('index.users.custom_right', {
    abstract: true,
    url: '/custom_right',
    templateUrl: 'app/views/user/custom_right/view.html'
  }).state('index.users.custom_right.privileges', {
    url: '/privileges',
    templateUrl: 'app/views/user/privileges/view.html'
  }).state('index.projects', {
    url: '/projects',
    templateUrl: 'app/views/project/view.html',
    data: { indexTrad: 'MENU.DO' },
    params: {
      edit: null,
      editproject: null
    },
    resolve: {
      loadPlugin: function loadPlugin($ocLazyLoad, $rootScope) {
        cov_8yq64vfod.s[4]++;

        return $ocLazyLoad.load([{
          serie: true,
          files: ['https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.8/summernote-bs4.min.js'].concat($rootScope.language.id == 'en' ? (cov_8yq64vfod.b[0][0]++, []) : (cov_8yq64vfod.b[0][1]++, ['https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.8/lang/summernote-fr-FR.js']))
        }]);
      }
    }
  }).state('index.dashboards', {
    url: '/dashboards',
    templateUrl: 'app/views/home_page/view.html',
    data: { indexTrad: 'MENU.DASHBOARDS' },
    resolve: {
      loadPlugin: function loadPlugin($ocLazyLoad, $rootScope) {
        cov_8yq64vfod.s[5]++;

        return $ocLazyLoad.load([{
          serie: true,
          files: ['https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.8/summernote-bs4.min.js'].concat($rootScope.language.id == 'en' ? (cov_8yq64vfod.b[1][0]++, []) : (cov_8yq64vfod.b[1][1]++, ['https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.8/lang/summernote-fr-FR.js']))
        }]);
      }
    }
  }).state('index.program', {
    url: '/program',
    templateUrl: 'app/views/program/view.html'
    // data: {indexTrad: 'MENU.DO'},
  }).state('index.edit_project', {
    url: '/edit_project',
    templateUrl: 'app/views/project/edit/isole-edit-project/projectEdit.html'
    // data: {indexTrad: 'MENU.DO'},
  }).state('index.sectors', {
    url: '/sectors',
    templateUrl: 'app/views/sectors/view.html',
    data: { indexTrad: 'MENU.SECTORS' }
  }).state('settings', {
    abstract: true,
    url: '/settings',
    templateUrl: 'app/views/menu/settings.html'
  }).state('settings.agencies', {
    url: '/agencies',
    templateUrl: 'app/views/agencies/view.html',
    data: { indexTrad: 'MENU.AGENCIES' }
  }).state('settings.layers', {
    url: '/layers',
    templateUrl: 'app/views/layer/view.html'
  }).state('index.currencies', {
    url: '/currencies',
    templateUrl: 'app/views/currencies/view.html',
    data: { indexTrad: 'MENU.CURRENCIES' }
  }).state('index.activity_dashboard', {
    url: '/activity_dashboard',
    templateUrl: 'app/views/activity_dashboard/view.html',
    params: {
      view_item: 0
    }
  }).state('index.indicator_dashboard', {
    url: '/indicator_dashboard',
    templateUrl: 'app/views/indicator_dashboard/view.html',
    params: {
      view_item: 0
    }
  }).state('settings.Unit', {
    url: '/Units',
    templateUrl: 'app/views/Units/EditView.html',
    data: { indexTrad: 'MENU.UN' }
  }).state('settings.categories', {
    url: '/categories_budget',
    templateUrl: 'app/views/categories_budget/EditView.html',
    data: { indexTrad: 'MENU.C_B' }
  }).state('settings.Sfin', {
    url: '/SourceFinView',
    templateUrl: 'app/views/SourceFin/SourceFinView.html',
    data: { indexTrad: 'MENU.S_F' }
  }).state('settings.financing', {
    url: '/financing',
    templateUrl: 'app/views/financing/financing.html',
    data: { indexTrad: 'MENU.FINANCING' }
  }).state('settings.Actors', {
    url: '/Stakeholders',
    templateUrl: 'app/views/Actors/ActorsView.html',
    data: { indexTrad: 'MENU.AC' }
  }).state('index.mail', {
    url: '/mail',
    templateUrl: 'app/views/mail/view.html',
    data: { indexTrad: 'MESSAGE.EMAIL_BOX' }
  }).state('index.dashboard', {
    url: '/dashboard',
    icon: 'fa-dashboard',
    templateUrl: 'app/views/dashboard/view.html',
    data: { indexTrad: 'DASHBOARD.DASHBOARD' },
    params: {
      view: 0
    }
  }).state('index.profil', {
    url: '/profil',
    templateUrl: 'app/views/profil/view.html'
  }).state('index.alerts', {
    url: '/alerts',
    templateUrl: 'app/views/alert/view.html',
    data: { indexTrad: 'MENU.ALERT' }
  }).state('index.TBordActor', {
    url: '/TBordActor',
    templateUrl: 'app/views/Actors/TBordActors.html',
    data: { pageTitle: ' dashboard Actors view' }
  }).state('index.step_one', {
    url: '/step_one',
    templateUrl: 'app/views/wizard/step_one.html',
    data: { pageTitle: 'Actors' }
  }).state('index.step_two', {
    url: '/step_two',
    templateUrl: 'app/views/wizard/step_two.html',
    data: { pageTitle: 'Actors' }
  }).state('settings.periods', {
    url: '/periods',
    templateUrl: 'app/views/time_division/view.html',
    data: { indexTrad: 'MENU.D_P' }
  }).state('settings.activity_breakdown', {
    url: '/activity_breakdown',
    templateUrl: 'app/views/planning_division/view.html',
    data: { indexTrad: 'MENU.D_PL' }
  }).state('settings.geographic_map', {
    url: '/geographic_map',
    templateUrl: 'app/views/geographic_map/view.html',
    data: { indexTrad: 'MENU.P_G' }
  }).state('settings.logical_plan', {
    url: '/logical_plan',
    templateUrl: 'app/views/logical_plan/view.html',
    data: { indexTrad: 'MENU.P_L' }
  }).state('settings.TypeActivity', {
    url: '/Type_Activity',
    templateUrl: 'app/views/TypeActivity/TypeActivView.html',
    data: { indexTrad: 'MENU.T_A' }

  }).state('index.default_logical_plan', {
    url: '/default_log_frame',
    templateUrl: 'app/views/default_log_frame/view.html',
    data: { indexTrad: 'MENU.P_CUSTOM' }
  }).state('settings.default_logical_plan', {
    url: '/default_log_frame',
    templateUrl: 'app/views/default_log_frame/view_partial.html',
    data: { indexTrad: 'MENU.P_CUSTOM' }
  }).state('settings.TypeIndic', {
    url: '/TypeIndicView',
    templateUrl: 'app/views/TypeIndic/TypeIndicView.html',
    data: { indexTrad: 'MENU.T_I' }

  }).state('settings.CatIndic', {
    url: '/CategoryIndicator',
    templateUrl: 'app/views/category_indicateur/view.html',
    data: { indexTrad: 'MENU.T_I' }

  }).state('settings.flag', {
    url: '/flag',
    templateUrl: 'app/views/flag/view.html',
    data: { indexTrad: 'MENU.FL' }
  }).state('index.procurement', {
    url: '/procurement',
    templateUrl: 'app/views/procurement_plan/view.html',
    data: { indexTrad: 'MENU.P_M' }
  }).state('index.agenda', {
    url: '/agenda',
    templateUrl: 'app/views/agendas/view.html',
    data: { indexTrad: 'MENU.AGENDA' }
  }).state('index.feedback', {
    url: '/feedback',
    templateUrl: 'app/views/feedbacks/view.html',
    data: { indexTrad: 'MENU.FEEDBACK' }
  }).state('index.file', {
    url: '/file',
    templateUrl: 'app/views/comments/view.html',
    data: { indexTrad: 'MENU.FILE' }
  }).state('index.workflow', {
    url: '/workflow',
    templateUrl: 'app/views/workflow/view.html',
    data: { indexTrad: 'COMMON.WORKFLOW' }
  }).state('indicatorglobal', {
    abstract: true,
    url: '/indicatorglobal',
    templateUrl: 'app/views/common/content.html'
  }).state('indicatorglobal.indicator', {
    url: '/indicators',
    templateUrl: 'app/views/indicator_global/view.html',
    params: {
      p: null

    }
  }).state('indicatorglobal.editindicator', {
    url: '/edition',
    templateUrl: 'app/views/indicator_global/edit.html',
    params: {
      id: null,
      listUnit: []
    }
  }).state('indicatorglobal.periodicvalue', {
    url: '/:id/periodicvalue/',
    templateUrl: 'app/views/indicator_global/globalvalues/globalPlan.html',
    params: {
      isplan: false,
      id: null,
      unit: null
    }
  }).state('indicator', {
    abstract: true,
    url: '/indicator',
    templateUrl: 'app/views/menu/indicator.html'
  }).state('indicator.indicator', {
    url: '/indicators',
    templateUrl: 'app/views/indicator/view.html',
    data: { indexTrad: 'MENU.IN' },
    params: {
      idcl: null
    }
  }).state('indicator.editindicator', {
    url: '/edition',
    templateUrl: 'app/views/indicator/edit.html',
    params: {
      id: null,
      idcl: null
    },
    resolve: {
      loadPlugin: function loadPlugin($ocLazyLoad) {
        cov_8yq64vfod.s[6]++;

        return $ocLazyLoad.load([]);
      }
    }
  }).state('indicator.periodicvalue', {
    url: '/:id/periodicvalue/',
    templateUrl: 'app/views/indicator/periodicvalues/main.html',
    params: {
      isplan: false,
      id: null,
      idcl: null
    },
    resolve: {
      loadPlugin: function loadPlugin($ocLazyLoad) {
        cov_8yq64vfod.s[7]++;

        return $ocLazyLoad.load([]);
      }
    }
  }).state('indicator.globalvalue', {
    url: '/:id/globalvalues/',
    templateUrl: 'app/views/indicator/globalvalues/globalPlan.html',
    params: {
      id: null,
      idcl: null
    },
    resolve: {
      loadPlugin: function loadPlugin($ocLazyLoad) {
        cov_8yq64vfod.s[8]++;

        return $ocLazyLoad.load([]);
      }
    }
  }).state('indicator.dashboard', {
    url: '/:id/dashboard/',
    templateUrl: 'app/views/indicator/dashboard/main.html',
    params: {
      id: null,
      idcl: null,
      from: null
    }
  }).state('indicator.specialpage', {
    url: '/{indicatorId}/{namepage}',
    templateUrl: 'app/views/indicator/view.html'
  }).state('indicator.workflow', {
    url: '/workflow',
    templateUrl: 'app/views/indicator/workflow/view.html',
    params: {
      indicator: null
    }
  }).state('indicator.overview_portfolio', {
    url: '/overview_portfolio',
    templateUrl: 'app/views/indicator_portfolio/main.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' },
    // data: {indexTrad: 'MENU.IN'},
    params: {
      indicator: null,
      dashboard: false,
      origin: null
    }
  }).state('indicator.overview', {
    abstract: true,
    url: '/overview',
    templateUrl: 'app/views/indicator/content/view.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' },
    // data: {indexTrad: 'MENU.IN'},
    params: {
      indicator: null,
      dashboard: false,
      origin: null,
      reload: false,
      global: false,
      idcl: null,
      idClSelected: null
    }
  }).state('indicator.overview.settings', {
    url: '/settings/',
    templateUrl: 'app/views/indicator/overview/overview.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' }
  }).state('indicator.overview.baselines', {
    url: '/baselines/',
    templateUrl: 'app/views/indicator/globalvalues/globalPlanPart.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' }
  }).state('indicator.overview.targets', {
    url: '/targets/',
    templateUrl: 'app/views/indicator/periodicvalues/main.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' },
    params: {
      isPlan: true
    }
  }).state('indicator.overview.actuals', {
    url: '/actuals/',
    templateUrl: 'app/views/indicator/periodicvalues/main.html',
    data: { indexTrad: 'MENU.OVERVIEW_INDICATOR' },
    params: {
      isPlan: false
    }
  }).state('indicator.overview.dashboards', {
    url: '/dashboards/',
    templateUrl: 'app/views/indicator/dashboard/main.html'
  }).state('indicator.overview.dashboardGlobal', {
    url: '/dashboard_global/',
    templateUrl: 'app/views/indicator/dashboard/main_global.html'
  }).state('indicator.overview.chat', {
    url: '/chat/',
    templateUrl: 'app/views/indicator/dashboard/indicator_tchat.html',
    params: {
      ID_PARENT: null,
      TYPE: 5
    }
  }).state('indicator.resultframe', {
    url: '/resultframe',
    data: { indexTrad: 'MENU.R_F' },
    templateUrl: 'app/views/results_frame/view.html'
  }).state('indicator.spatial', {
    url: '/spatial_visualisation',
    data: { indexTrad: 'MENU.SAP_VIS' },
    templateUrl: 'app/views/spatial_visualisation/view.html'

  }).state('indicator.visualization', {
    url: '/visualization',
    data: { indexTrad: 'MENU.V_I' },
    templateUrl: 'app/views/indicator_visualization/view.html'

  }).state('activity', {
    abstract: true,
    url: '/activity',
    templateUrl: 'app/views/menu/activity.html'
  }).state('activity.workflow', {
    url: '/:id/workflow/',
    templateUrl: 'app/views/activities/workflow/view.html',
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.right', {
    url: '/:id/right/',
    templateUrl: 'app/views/activities/right/right.html',
    data: { indexTrad: 'MENU.P_CL' },
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.edit_cross', {
    url: '/edit_cross/',
    templateUrl: 'app/views/activities_transversal/edit/main.html',
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.planning', {
    abstract: true,
    url: '/planning',
    templateUrl: 'app/views/activities/content.html'
  }).state('activity.planning.overview', {
    abstract: true,
    url: '/:id/overview',
    templateUrl: 'app/views/activities/activity_content/view.html',
    params: {
      id: null,
      activity: null,
      origin: null,
      reload: null
    }
  }).state('activity.planning.overview.settings', {
    url: '/settings',
    templateUrl: 'app/views/activities/overview/overview.html',
    data: { indexTrad: 'MENU.OVERVIEW_ACTIVITY' }
  }).state('activity.planning.overview.plan', {
    url: '/plan',
    templateUrl: 'app/views/activities/planification/view_new.html'
  }).state('activity.planning.overview.tracking', {
    url: '/tracking',
    templateUrl: 'app/views/activities/realisation/view.html'
  }).state('activity.planning.overview.reports', {
    url: '/reports',
    templateUrl: 'app/views/activities/dashboard/view.html',
    params: {
      params: null
    }
  }).state('activity.planning.overview.chat', {
    url: '/chat',
    templateUrl: 'app/views/activities/activities_tchat.html',
    params: {
      ID_PARENT: null,
      TYPE: 4
    }
  }).state('activity.planning.overview_portfolio', {
    url: '/:id/overview_portfolio/',
    templateUrl: 'app/views/activities_portfolio/main.html',
    data: { indexTrad: 'MENU.OVERVIEW_ACTIVITY' },
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.planning.main', {
    url: '/',
    templateUrl: 'app/views/activities/view.html',
    data: { indexTrad: 'MENU.P_A' }
  }).state('activity.planning.dashboard', {
    url: '/:id/dashboard',
    templateUrl: 'app/views/activities/dashboard/view.html',
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.ptba', {
    url: '/ptba',
    templateUrl: 'app/views/ptba/view.html',
    data: { indexTrad: 'MENU.S_PTBA' }
  }).state('activity.ptbaannuel', {
    url: '/ptbaannuel',
    templateUrl: 'app/views/ptbaannuel/view.html',
    data: { indexTrad: 'MENU.S_PTBP' },
    params: {
      from: null,
      id: null,
      params: {},
      origin: ''
    }
  }).state('activity.ganttchart', {
    url: '/ganttChart',
    templateUrl: 'app/views/ganttChart/view.html',
    data: { indexTrad: 'MENU.C_GANTT' },
    params: {
      from: null
    }
  }).state('activity.contract_follow', {
    url: '/contract_follow',
    data: { indexTrad: 'MENU.S_M' },
    templateUrl: 'app/views/contract/contractView.html'

  }).state('activity.contract_ppm', {
    url: '/contract_ppm',
    data: { indexTrad: 'MENU.S_PPM' },
    templateUrl: 'app/views/contract_ppm/view.html'
  }).state('project_mgt.staffreports', {
    abstract: true,
    url: '/staffreports',
    templateUrl: 'app/views/report_type/menu.html'
  }).state('project_mgt.staffreports.planning', {
    url: '/planning',
    templateUrl: 'app/views/report_type/view.html',
    data: { indexTrad: 'MENU.T_RA' }
  }).state('project_mgt.staffreports.tracking', {
    url: '/tracking',
    templateUrl: 'app/views/report_calendar/view.html',
    data: { indexTrad: 'MENU.CA_R' },
    params: {
      params: {}
    }
  })

  // Base documentaire
  .state('document', {
    abstract: true,
    url: '/document',
    templateUrl: 'app/views/menu/document.html'
  })
  /* .state('document.reportType', {
     url: '/report_type',
     templateUrl: 'app/views/report_type/view.html',
     data: {indexTrad: 'MENU.T_RA'}
   })
   .state('document.reportCalendar', {
     url: '/report_calendar',
     templateUrl: 'app/views/report_calendar/view.html',
     data: {indexTrad: 'MENU.CA_R'},
     params: {
       params: {}
     }
   }) */
  .state('document.document', {
    url: '/document_file',
    templateUrl: 'app/views/document/view_new.html',
    data: { indexTrad: 'MENU.C_D' },
    params: {
      params: {}
    }
  }).state('document.edit', {
    url: '/:id/document_edit',
    templateUrl: 'app/views/document/edit/edit.html',
    data: { indexTrad: 'MENU.C_D' },
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('document.preview', {
    url: '/:id/preview',
    templateUrl: 'app/views/document/preview/documentPreview.html',
    data: { indexTrad: 'MENU.C_D' }
  }).state('document.communities', {
    url: '/communities',
    templateUrl: 'app/views/communities/view.html',
    data: { indexTrad: 'MENU.COMMUNITIES' }
  }).state('document.photolibrary', {
    url: '/photo_library',
    templateUrl: 'app/views/photolibrary/view.html',
    data: { indexTrad: 'MENU.PH' /* ,
                                 resolve: {
                                 loadPlugin($ocLazyLoad) {
                                 return $ocLazyLoad.load([{
                                 files: ['js/plugins/blueimp/jquery.blueimp-gallery.min.js']
                                 }]);
                                 }
                                 } */
    } }).state('document.photos', {
    url: '/photo_library',
    templateUrl: 'app/views/photolibrary/view.html',
    data: { indexTrad: 'MENU.PH' },
    params: {
      type: null
      /* ,
        resolve: {
          loadPlugin($ocLazyLoad) {
            return $ocLazyLoad.load([{
              files: ['js/plugins/blueimp/jquery.blueimp-gallery.min.js']
             }]);
          }
        }
      */
    } }).state('document.videos', {
    url: '/video_library',
    templateUrl: 'app/views/photolibrary/view.html',
    data: { indexTrad: 'MENU.PH' },
    params: {
      type: null
      /* ,
        resolve: {
          loadPlugin($ocLazyLoad) {
            return $ocLazyLoad.load([{
              files: ['js/plugins/blueimp/jquery.blueimp-gallery.min.js']
             }]);
          }
        }
      */
    } }).state('users', {
    abstract: true,
    url: '/user',
    templateUrl: 'app/views/menu/user.html'
  }).state('users.mangement', {
    url: '/management',
    templateUrl: 'app/views/user/view.html'
  }).state('users.deletedItem', {
    url: '/deleted_items',
    templateUrl: 'app/views/deleted_items/view.html'
  }).state('users.group', {
    url: '/group',
    templateUrl: 'app/views/user/usergroup/view.html'
  }).state('users.privileges', {
    url: '/privileges',
    templateUrl: 'app/views/user/privileges/view.html'
  }).state('users.custom_right', {
    abstract: true,
    url: '/custom_right',
    templateUrl: 'app/views/user/custom_right/view.html'
  }).state('users.custom_right.group', {
    url: '/group',
    templateUrl: 'app/views/user/usergroup/view.html'
  }).state('users.custom_right.privileges', {
    url: '/privileges',
    templateUrl: 'app/views/user/privileges/view.html'
  }).state('users.audits', {
    url: '/audits',
    templateUrl: 'app/views/audits/view.html'
  }).state('users.communities', {
    url: '/communities',
    templateUrl: 'app/views/communities/view.html',
    data: { indexTrad: 'MENU.COMMUNITIES' }
  }).state('formular', {
    abstract: true,
    url: '/template',
    templateUrl: 'app/views/menu/template.html'
  }).state('formular.formular', {
    url: '/design_form',
    templateUrl: 'app/views/formular/formular.html',
    data: { indexTrad: 'MENU.DESIGN_FORMS' }
  }).state('formDesign', {
    abstract: true,
    url: '/view/:id',
    templateUrl: 'app/views/formular/content/view.html',
    params: {
      id: null,
      form: null,
      reload: null
    }
  }).state('formDesign.edit', {
    url: '/edit',
    templateUrl: 'app/views/formular/edit/edit.html'
  }).state('formDesign.collect', {
    url: '/collect',
    templateUrl: 'app/views/formular/collect/view.html'
  }).state('formDesign.review', {
    url: '/review',
    templateUrl: 'app/views/formular/record/list.html'
  }).state('formDesign.analyze', {
    url: '/analyze',
    templateUrl: 'app/views/formular/request/requestPart.html'
  }).state('formDesign.analyze.table', {
    url: '/table',
    templateUrl: 'app/views/formular/table_analyse/view.html',
    params: {
      idRequest: null,
      type: 'pivot'
    }
  }).state('formDesign.analyze.map', {
    url: '/map',
    templateUrl: 'app/views/formular/map_analyse/view.html',
    params: {
      idRequest: null
    }
  }).state('formDesign.analyze.chart', {
    url: '/chart',
    templateUrl: 'app/views/formular/chart_analyse/view.html',
    params: {
      idRequest: null,
      type: null
    }
  })

  /**
   * Analytics
   * * */

  .state('analytics', {
    abstract: true,
    url: '/analytics',
    templateUrl: 'app/views/menu/analytics.html'
  }).state('analytics.custom_report', {
    url: '/custom_report',
    templateUrl: 'app/views/analyse/view.html',
    params: {
      id: null
    },
    data: { indexTrad: 'MENU.DESIGN_FORMS' }
  }).state('analytics.sig', {
    url: '/sig',
    templateUrl: 'app/views/dashboard_sig/view.html',
    params: {
      id: null
    },
    data: { indexTrad: 'MENU.ANALYTICS_GIS' }
  }).state('analytics.analyze_table', {
    url: '/analyze_table',
    templateUrl: 'app/views/formular/table_analyse/view.html',
    params: {
      id: null,
      idRequest: null,
      type: 'pivot'
    }
  }).state('analytics.analyze_map', {
    url: '/analyze_map',
    templateUrl: 'app/views/formular/map_analyse/view.html',
    params: {
      id: null,
      idRequest: null
    }
  }).state('analytics.analyze_chart', {
    url: '/analyze_chart',
    templateUrl: 'app/views/formular/chart_analyse/view.html',
    params: {
      id: null,
      idRequest: null,
      type: null
    }
  }).state('analytics.custom_dashboard', {
    url: '/custom_dashboard',
    templateUrl: 'app/views/analytics/record/list.html',
    data: { indexTrad: 'MENU.COLLECT_DATA' }
  }).state('analytics.gis', {
    url: '/gis',
    templateUrl: 'app/views/analytics/request/request.html',
    data: { indexTrad: 'MENU.ANALYZE_DATA' }
  }).state('analytics.dashboard', {
    url: '/customized_dashboards',
    templateUrl: 'app/views/customdashboard/view.html',
    data: { indexTrad: 'MENU.DASHBOARD' }
  }).state('analyze', {
    abstract: true,
    url: '/dashboard_editor',
    templateUrl: 'app/views/menu/dashboard_editor.html'
  }).state('analyze.analyse', {
    url: '/customized_reports',
    templateUrl: 'app/views/analyse/view.html',
    params: {
      id: null
    },
    data: { indexTrad: 'MENU.ANALYSE' }
  }).state('analyze.dashboard', {
    url: '/customized_dashboards',
    templateUrl: 'app/views/customdashboard/view.html',
    data: { indexTrad: 'MENU.DASHBOARD' }
  }).state('logframe', {
    abstract: true,
    url: '/logframe',
    templateUrl: 'app/views/menu/logframe.html'
  }).state('logframe.information', {
    url: '/:id',
    templateUrl: 'app/views/logframe/view.html',
    data: { indexTrad: 'MENU.P_CL' },
    params: {
      id: null
    }
  }).state('logframe.activityworkflow', {
    url: '/:id/workflow/',
    templateUrl: 'app/views/activities/workflow/view.html',
    data: { indexTrad: 'MENU.P_CL' },
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('logframe.activityright', {
    url: '/:id/right/',
    templateUrl: 'app/views/activities/right/right.html',
    data: { indexTrad: 'MENU.P_CL' },
    params: {
      id: null,
      params: {},
      origin: ''
    }
  }).state('project_mgt', {
    abstract: true,
    url: '/project_mgt',
    templateUrl: 'app/views/menu/project_mgt.html'
  }).state('project_mgt.activity_plan_budget', {
    url: '/activity_plan_budget',
    templateUrl: 'app/views/activity_plan_budget/view.html',
    data: { indexTrad: 'MENU_SHORT.ACTIVITY_PLAN_BUDGET' },
    params: {
      params: null
    }
  }).state('project_mgt.report', {
    url: '/report',
    templateUrl: 'app/views/activities_report/view.html',
    data: { indexTrad: 'MENU_SHORT.ACTIVITY_REPORT' },
    params: {
      id: null
    }
  }).state('project_mgt.funds_disbursement', {
    url: '/funds_disbursement',
    templateUrl: 'app/views/funds_disbursement/view.html',
    data: { indexTrad: 'MENU_SHORT.FUNDS' }
  }).state('project_mgt.missions', {
    url: '/missions',
    templateUrl: 'app/views/missions/view.html',
    data: { indexTrad: 'MENU_SHORT.MISSION_MANAGEMENT' }
  }).state('project_mgt.risks_management', {
    url: '/risk_management',
    templateUrl: 'app/views/risk/view.html',
    data: { indexTrad: 'MENU_SHORT.RISKS_MANAGEMENT' },
    params: {
      type: 1
    }
  }).state('project_mgt.problem_management', {
    url: '/problem_management',
    templateUrl: 'app/views/risk/view.html',
    data: { indexTrad: 'MENU_SHORT.PROBLEM_MANAGEMENT' },
    params: {
      type: 2
    }
  }).state('project_mgt.project_timeline', {
    url: '/project_timeline',
    templateUrl: 'app/views/ganttChart/view.html',
    data: { indexTrad: 'MENU.C_GANTT' },
    params: {
      from: null
    }
  });
}).run(function ($rootScope, $state) {
  cov_8yq64vfod.f[1]++;
  cov_8yq64vfod.s[9]++;

  $rootScope.$state = $state;
  cov_8yq64vfod.s[10]++;
  $rootScope.currentSpace = {
    nbprojets: 0,
    deleted: 0,
    nbusers: 0,
    id: null
  };
  cov_8yq64vfod.s[11]++;
  $rootScope.getCurrentProject = function () {
    cov_8yq64vfod.f[2]++;
    cov_8yq64vfod.s[12]++;

    return $rootScope.currentProject;
  };
  cov_8yq64vfod.s[13]++;
  $rootScope.getCurrentSpace = function () {
    cov_8yq64vfod.f[3]++;
    cov_8yq64vfod.s[14]++;

    return $rootScope.currentSpace;
  };
});
'use strict';

var cov_2bjerrj3dv = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/login_cglua/LoginCGLUACtrl.js',
      hash = '4d78dd72dbf1a3edce27196e86efbe6f8409d194',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/login_cglua/LoginCGLUACtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 65,
          column: 7
        }
      },
      '1': {
        start: {
          line: 4,
          column: 6
        },
        end: {
          line: 4,
          column: 26
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 29
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 6,
          column: 30
        }
      },
      '4': {
        start: {
          line: 8,
          column: 6
        },
        end: {
          line: 8,
          column: 23
        }
      },
      '5': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 12,
          column: 8
        }
      },
      '6': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 29,
          column: 8
        }
      },
      '7': {
        start: {
          line: 14,
          column: 20
        },
        end: {
          line: 14,
          column: 21
        }
      },
      '8': {
        start: {
          line: 15,
          column: 8
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '9': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '10': {
        start: {
          line: 17,
          column: 12
        },
        end: {
          line: 17,
          column: 22
        }
      },
      '11': {
        start: {
          line: 18,
          column: 12
        },
        end: {
          line: 18,
          column: 18
        }
      },
      '12': {
        start: {
          line: 21,
          column: 8
        },
        end: {
          line: 21,
          column: 50
        }
      },
      '13': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 46
        }
      },
      '14': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 47
        }
      },
      '15': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 46
        }
      },
      '16': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 46
        }
      },
      '17': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 57
        }
      },
      '18': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 53
        }
      },
      '19': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 65
        }
      },
      '20': {
        start: {
          line: 30,
          column: 17
        },
        end: {
          line: 30,
          column: 21
        }
      },
      '21': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 33,
          column: 7
        }
      },
      '22': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 56
        }
      },
      '23': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 62,
          column: 8
        }
      },
      '24': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 27
        }
      },
      '25': {
        start: {
          line: 36,
          column: 8
        },
        end: {
          line: 61,
          column: 11
        }
      },
      '26': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 60,
          column: 11
        }
      },
      '27': {
        start: {
          line: 38,
          column: 38
        },
        end: {
          line: 38,
          column: 45
        }
      },
      '28': {
        start: {
          line: 39,
          column: 12
        },
        end: {
          line: 39,
          column: 48
        }
      },
      '29': {
        start: {
          line: 40,
          column: 12
        },
        end: {
          line: 40,
          column: 40
        }
      },
      '30': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 49,
          column: 15
        }
      },
      '31': {
        start: {
          line: 50,
          column: 12
        },
        end: {
          line: 50,
          column: 43
        }
      },
      '32': {
        start: {
          line: 51,
          column: 12
        },
        end: {
          line: 51,
          column: 40
        }
      },
      '33': {
        start: {
          line: 52,
          column: 12
        },
        end: {
          line: 52,
          column: 54
        }
      },
      '34': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 57,
          column: 13
        }
      },
      '35': {
        start: {
          line: 55,
          column: 14
        },
        end: {
          line: 55,
          column: 32
        }
      },
      '36': {
        start: {
          line: 56,
          column: 14
        },
        end: {
          line: 56,
          column: 21
        }
      },
      '37': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 59,
          column: 30
        }
      },
      '38': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 34
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 34
          },
          end: {
            line: 3,
            column: 35
          }
        },
        loc: {
          start: {
            line: 3,
            column: 126
          },
          end: {
            line: 65,
            column: 5
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 13,
            column: 30
          },
          end: {
            line: 13,
            column: 31
          }
        },
        loc: {
          start: {
            line: 13,
            column: 46
          },
          end: {
            line: 29,
            column: 7
          }
        },
        line: 13
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 34,
            column: 21
          },
          end: {
            line: 34,
            column: 22
          }
        },
        loc: {
          start: {
            line: 34,
            column: 33
          },
          end: {
            line: 62,
            column: 7
          }
        },
        line: 34
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 36,
            column: 114
          },
          end: {
            line: 36,
            column: 115
          }
        },
        loc: {
          start: {
            line: 36,
            column: 122
          },
          end: {
            line: 61,
            column: 9
          }
        },
        line: 36
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 16,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 16,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }, {
          start: {
            line: 16,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }],
        line: 16
      },
      '1': {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 33,
            column: 7
          }
        }],
        line: 31
      },
      '2': {
        loc: {
          start: {
            line: 32,
            column: 15
          },
          end: {
            line: 32,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 32,
            column: 15
          },
          end: {
            line: 32,
            column: 47
          }
        }, {
          start: {
            line: 32,
            column: 51
          },
          end: {
            line: 32,
            column: 55
          }
        }],
        line: 32
      },
      '3': {
        loc: {
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 60,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 60,
            column: 11
          }
        }, {
          start: {
            line: 37,
            column: 10
          },
          end: {
            line: 60,
            column: 11
          }
        }],
        line: 37
      },
      '4': {
        loc: {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }, {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }],
        line: 54
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2bjerrj3dv.s[0]++;
angular.module('app').controller('LoginCGLUACtrl', function ($scope, $LOGINService, $rootScope, $translate, $location, tmhDynamicLocale, $deltadate) {
  cov_2bjerrj3dv.f[0]++;
  cov_2bjerrj3dv.s[1]++;

  $scope.email = null;
  cov_2bjerrj3dv.s[2]++;
  $scope.password = null;
  cov_2bjerrj3dv.s[3]++;
  $scope.espace = 'CGLUA';

  cov_2bjerrj3dv.s[4]++;
  $scope.flag = '';
  cov_2bjerrj3dv.s[5]++;
  $scope.langs = [{ lib: 'English', flag: 'assets/img/flags/16/Canada.png', display: 'assets/img/flags/24/Canada.png', id: 'en' }, { lib: 'Français', flag: 'assets/img/flags/16/France.png', display: 'assets/img/flags/24/France.png', id: 'fr' }];
  cov_2bjerrj3dv.s[6]++;
  $scope.changeLanguage = function (lang) {
    cov_2bjerrj3dv.f[1]++;

    var index = (cov_2bjerrj3dv.s[7]++, 0);
    cov_2bjerrj3dv.s[8]++;
    for (var i = $scope.langs.length - 1; i >= 0; i--) {
      cov_2bjerrj3dv.s[9]++;

      if ($scope.langs[i].id == lang) {
        cov_2bjerrj3dv.b[0][0]++;
        cov_2bjerrj3dv.s[10]++;

        index = i;
        cov_2bjerrj3dv.s[11]++;
        break;
      } else {
        cov_2bjerrj3dv.b[0][1]++;
      }
    }
    cov_2bjerrj3dv.s[12]++;
    $scope.flag = $scope.langs[index].display;
    cov_2bjerrj3dv.s[13]++;
    $scope.lang = $scope.langs[index].lib;
    cov_2bjerrj3dv.s[14]++;
    $translate.use($scope.langs[index].id);
    cov_2bjerrj3dv.s[15]++;
    $scope.language = $scope.langs[index];
    cov_2bjerrj3dv.s[16]++;
    $rootScope.language = $scope.language;
    cov_2bjerrj3dv.s[17]++;
    $deltadate.CURRENT_LANG = $scope.langs[index].id;
    cov_2bjerrj3dv.s[18]++;
    tmhDynamicLocale.set($scope.langs[index].id);
    cov_2bjerrj3dv.s[19]++;
    localStorage.setItem('LANG_APP', $scope.langs[index].id);
  };
  var lang = (cov_2bjerrj3dv.s[20]++, 'en');
  cov_2bjerrj3dv.s[21]++;
  if (window.localStorage) {
    cov_2bjerrj3dv.b[1][0]++;
    cov_2bjerrj3dv.s[22]++;

    lang = (cov_2bjerrj3dv.b[2][0]++, localStorage.getItem('LANG_APP')) || (cov_2bjerrj3dv.b[2][1]++, 'en');
  } else {
    cov_2bjerrj3dv.b[1][1]++;
  }
  cov_2bjerrj3dv.s[23]++;
  $scope.login = function () {
    cov_2bjerrj3dv.f[2]++;
    cov_2bjerrj3dv.s[24]++;

    $scope.logerr = -1;
    cov_2bjerrj3dv.s[25]++;
    $LOGINService.login({ t: 1, e: $scope.espace, m: $scope.email, p: $scope.password, l: $scope.language.id }, function (data) {
      cov_2bjerrj3dv.f[3]++;
      cov_2bjerrj3dv.s[26]++;

      if (data.i) {
        cov_2bjerrj3dv.b[3][0]++;

        var _lang = ( /* 'en' || */cov_2bjerrj3dv.s[27]++, data.la);
        cov_2bjerrj3dv.s[28]++;
        $LOGINService.setUE(data.i, data.e);
        cov_2bjerrj3dv.s[29]++;
        $LOGINService.setLang(_lang);
        cov_2bjerrj3dv.s[30]++;
        $scope.$parent.$parent.setUser({
          name: data.n,
          id: data.i,
          espace: data.e,
          login: data.l,
          token: data.t,
          langue: _lang,
          superAdmin: data.type == 1
        });
        cov_2bjerrj3dv.s[31]++;
        $LOGINService.setToken(data.t);
        cov_2bjerrj3dv.s[32]++;
        $scope.changeLanguage(_lang);
        cov_2bjerrj3dv.s[33]++;
        $location.path('/app/projects').replace();
      } else {
        cov_2bjerrj3dv.b[3][1]++;
        cov_2bjerrj3dv.s[34]++;

        if (data.c == 1) {
          cov_2bjerrj3dv.b[4][0]++;
          cov_2bjerrj3dv.s[35]++;

          $scope.logerr = 2;
          cov_2bjerrj3dv.s[36]++;
          return;
        } else {
          cov_2bjerrj3dv.b[4][1]++;
        }

        cov_2bjerrj3dv.s[37]++;
        $scope.logerr = 1;
      }
    });
  };

  cov_2bjerrj3dv.s[38]++;
  $scope.changeLanguage(lang);
});
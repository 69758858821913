'use strict';

var cov_29nd3d4e50 = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/content/content.js',
      hash = 'dda66172ce6c69c426f84604f2ecc3f6b4e621c1',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/indicator/content/content.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 194,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 21
        },
        end: {
          line: 2,
          column: 43
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 49
        }
      },
      '3': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 72
        }
      },
      '4': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 38
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 39
        }
      },
      '6': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 42
        }
      },
      '7': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 47
        }
      },
      '8': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 43
        }
      },
      '9': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 50
        }
      },
      '10': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 53
        }
      },
      '11': {
        start: {
          line: 16,
          column: 24
        },
        end: {
          line: 16,
          column: 57
        }
      },
      '12': {
        start: {
          line: 17,
          column: 19
        },
        end: {
          line: 22,
          column: 4
        }
      },
      '13': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 27,
          column: 4
        }
      },
      '14': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 24,
          column: 39
        }
      },
      '15': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 25,
          column: 64
        }
      },
      '16': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 26,
          column: 22
        }
      },
      '17': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 32,
          column: 4
        }
      },
      '18': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 29,
          column: 46
        }
      },
      '19': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 30,
          column: 44
        }
      },
      '20': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 36
        }
      },
      '21': {
        start: {
          line: 33,
          column: 2
        },
        end: {
          line: 35,
          column: 4
        }
      },
      '22': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 34,
          column: 40
        }
      },
      '23': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 39,
          column: 4
        }
      },
      '24': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 56
        }
      },
      '25': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '26': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '27': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 93
        }
      },
      '28': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 174,
          column: 5
        }
      },
      '29': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 48,
          column: 28
        }
      },
      '30': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 49,
          column: 41
        }
      },
      '31': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 38
        }
      },
      '32': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 49
        }
      },
      '33': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 96
        }
      },
      '34': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 53,
          column: 47
        }
      },
      '35': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 58,
          column: 7
        }
      },
      '36': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 57,
          column: 12
        }
      },
      '37': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 56
        }
      },
      '38': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 79,
          column: 6
        }
      },
      '39': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 63,
          column: 7
        }
      },
      '40': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 36
        }
      },
      '41': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '42': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 36
        }
      },
      '43': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '44': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 69,
          column: 40
        }
      },
      '45': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 73,
          column: 7
        }
      },
      '46': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 72,
          column: 40
        }
      },
      '47': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 77,
          column: 7
        }
      },
      '48': {
        start: {
          line: 76,
          column: 8
        },
        end: {
          line: 76,
          column: 40
        }
      },
      '49': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 34
        }
      },
      '50': {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 99,
          column: 6
        }
      },
      '51': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 84,
          column: 7
        }
      },
      '52': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 15
        }
      },
      '53': {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 85,
          column: 30
        }
      },
      '54': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 98,
          column: 9
        }
      },
      '55': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '56': {
        start: {
          line: 88,
          column: 10
        },
        end: {
          line: 88,
          column: 32
        }
      },
      '57': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 24
        }
      },
      '58': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 96,
          column: 9
        }
      },
      '59': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 93,
          column: 32
        }
      },
      '60': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 95,
          column: 24
        }
      },
      '61': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 34
        }
      },
      '62': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 101,
          column: 55
        }
      },
      '63': {
        start: {
          line: 103,
          column: 4
        },
        end: {
          line: 103,
          column: 47
        }
      },
      '64': {
        start: {
          line: 105,
          column: 4
        },
        end: {
          line: 105,
          column: 37
        }
      },
      '65': {
        start: {
          line: 106,
          column: 4
        },
        end: {
          line: 106,
          column: 28
        }
      },
      '66': {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 107,
          column: 27
        }
      },
      '67': {
        start: {
          line: 108,
          column: 4
        },
        end: {
          line: 108,
          column: 29
        }
      },
      '68': {
        start: {
          line: 111,
          column: 4
        },
        end: {
          line: 163,
          column: 5
        }
      },
      '69': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 129,
          column: 7
        }
      },
      '70': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 32
        }
      },
      '71': {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 31
        }
      },
      '72': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 117,
          column: 68
        }
      },
      '73': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 118,
          column: 42
        }
      },
      '74': {
        start: {
          line: 119,
          column: 13
        },
        end: {
          line: 129,
          column: 7
        }
      },
      '75': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 32
        }
      },
      '76': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 31
        }
      },
      '77': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 32
        }
      },
      '78': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 124,
          column: 42
        }
      },
      '79': {
        start: {
          line: 126,
          column: 8
        },
        end: {
          line: 126,
          column: 31
        }
      },
      '80': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 30
        }
      },
      '81': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 128,
          column: 32
        }
      },
      '82': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 136,
          column: 7
        }
      },
      '83': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 32
        }
      },
      '84': {
        start: {
          line: 133,
          column: 8
        },
        end: {
          line: 133,
          column: 31
        }
      },
      '85': {
        start: {
          line: 134,
          column: 8
        },
        end: {
          line: 134,
          column: 42
        }
      },
      '86': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 32
        }
      },
      '87': {
        start: {
          line: 137,
          column: 6
        },
        end: {
          line: 145,
          column: 7
        }
      },
      '88': {
        start: {
          line: 138,
          column: 8
        },
        end: {
          line: 144,
          column: 11
        }
      },
      '89': {
        start: {
          line: 147,
          column: 11
        },
        end: {
          line: 163,
          column: 5
        }
      },
      '90': {
        start: {
          line: 148,
          column: 6
        },
        end: {
          line: 157,
          column: 7
        }
      },
      '91': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 149,
          column: 32
        }
      },
      '92': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 31
        }
      },
      '93': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 33
        }
      },
      '94': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 152,
          column: 42
        }
      },
      '95': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 31
        }
      },
      '96': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 155,
          column: 30
        }
      },
      '97': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 156,
          column: 32
        }
      },
      '98': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 159,
          column: 29
        }
      },
      '99': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 28
        }
      },
      '100': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 161,
          column: 30
        }
      },
      '101': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 40
        }
      },
      '102': {
        start: {
          line: 165,
          column: 4
        },
        end: {
          line: 169,
          column: 5
        }
      },
      '103': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 30
        }
      },
      '104': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 29
        }
      },
      '105': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 168,
          column: 40
        }
      },
      '106': {
        start: {
          line: 171,
          column: 4
        },
        end: {
          line: 173,
          column: 7
        }
      },
      '107': {
        start: {
          line: 172,
          column: 6
        },
        end: {
          line: 172,
          column: 89
        }
      },
      '108': {
        start: {
          line: 176,
          column: 2
        },
        end: {
          line: 193,
          column: 3
        }
      },
      '109': {
        start: {
          line: 177,
          column: 4
        },
        end: {
          line: 177,
          column: 28
        }
      },
      '110': {
        start: {
          line: 178,
          column: 4
        },
        end: {
          line: 190,
          column: 7
        }
      },
      '111': {
        start: {
          line: 179,
          column: 6
        },
        end: {
          line: 179,
          column: 29
        }
      },
      '112': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 189,
          column: 7
        }
      },
      '113': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 188,
          column: 11
        }
      },
      '114': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 192,
          column: 22
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 65
          },
          end: {
            line: 1,
            column: 66
          }
        },
        loc: {
          start: {
            line: 1,
            column: 357
          },
          end: {
            line: 194,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 23,
            column: 31
          },
          end: {
            line: 23,
            column: 32
          }
        },
        loc: {
          start: {
            line: 23,
            column: 66
          },
          end: {
            line: 27,
            column: 3
          }
        },
        line: 23
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 28,
            column: 27
          },
          end: {
            line: 28,
            column: 28
          }
        },
        loc: {
          start: {
            line: 28,
            column: 74
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 28
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 33,
            column: 25
          },
          end: {
            line: 33,
            column: 26
          }
        },
        loc: {
          start: {
            line: 33,
            column: 72
          },
          end: {
            line: 35,
            column: 3
          }
        },
        line: 33
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 37,
            column: 26
          },
          end: {
            line: 37,
            column: 27
          }
        },
        loc: {
          start: {
            line: 37,
            column: 38
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 37
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 41,
            column: 32
          },
          end: {
            line: 41,
            column: 33
          }
        },
        loc: {
          start: {
            line: 41,
            column: 40
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 41
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 47,
            column: 21
          },
          end: {
            line: 47,
            column: 22
          }
        },
        loc: {
          start: {
            line: 47,
            column: 27
          },
          end: {
            line: 174,
            column: 3
          }
        },
        line: 47
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 54,
            column: 38
          },
          end: {
            line: 54,
            column: 39
          }
        },
        loc: {
          start: {
            line: 54,
            column: 44
          },
          end: {
            line: 58,
            column: 5
          }
        },
        line: 54
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 55,
            column: 67
          },
          end: {
            line: 55,
            column: 68
          }
        },
        loc: {
          start: {
            line: 55,
            column: 76
          },
          end: {
            line: 57,
            column: 7
          }
        },
        line: 55
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 60,
            column: 32
          },
          end: {
            line: 60,
            column: 33
          }
        },
        loc: {
          start: {
            line: 60,
            column: 48
          },
          end: {
            line: 79,
            column: 5
          }
        },
        line: 60
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 81,
            column: 28
          },
          end: {
            line: 81,
            column: 29
          }
        },
        loc: {
          start: {
            line: 81,
            column: 49
          },
          end: {
            line: 99,
            column: 5
          }
        },
        line: 81
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 86,
            column: 102
          },
          end: {
            line: 86,
            column: 103
          }
        },
        loc: {
          start: {
            line: 86,
            column: 110
          },
          end: {
            line: 98,
            column: 7
          }
        },
        line: 86
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 171,
            column: 89
          },
          end: {
            line: 171,
            column: 90
          }
        },
        loc: {
          start: {
            line: 171,
            column: 97
          },
          end: {
            line: 173,
            column: 5
          }
        },
        line: 171
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 178,
            column: 182
          },
          end: {
            line: 178,
            column: 183
          }
        },
        loc: {
          start: {
            line: 178,
            column: 190
          },
          end: {
            line: 190,
            column: 5
          }
        },
        line: 178
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 6,
            column: 15
          },
          end: {
            line: 6,
            column: 72
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 6,
            column: 37
          },
          end: {
            line: 6,
            column: 56
          }
        }, {
          start: {
            line: 6,
            column: 59
          },
          end: {
            line: 6,
            column: 72
          }
        }],
        line: 6
      },
      '1': {
        loc: {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }, {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }],
        line: 42
      },
      '2': {
        loc: {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }, {
          start: {
            line: 61,
            column: 6
          },
          end: {
            line: 63,
            column: 7
          }
        }],
        line: 61
      },
      '3': {
        loc: {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }, {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }],
        line: 64
      },
      '4': {
        loc: {
          start: {
            line: 68,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }, {
          start: {
            line: 68,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }],
        line: 68
      },
      '5': {
        loc: {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }, {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }],
        line: 71
      },
      '6': {
        loc: {
          start: {
            line: 75,
            column: 6
          },
          end: {
            line: 77,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 6
          },
          end: {
            line: 77,
            column: 7
          }
        }, {
          start: {
            line: 75,
            column: 6
          },
          end: {
            line: 77,
            column: 7
          }
        }],
        line: 75
      },
      '7': {
        loc: {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }, {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 84,
            column: 7
          }
        }],
        line: 82
      },
      '8': {
        loc: {
          start: {
            line: 111,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 111,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }, {
          start: {
            line: 111,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }],
        line: 111
      },
      '9': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 129,
            column: 7
          }
        }],
        line: 114
      },
      '10': {
        loc: {
          start: {
            line: 119,
            column: 13
          },
          end: {
            line: 129,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 119,
            column: 13
          },
          end: {
            line: 129,
            column: 7
          }
        }, {
          start: {
            line: 119,
            column: 13
          },
          end: {
            line: 129,
            column: 7
          }
        }],
        line: 119
      },
      '11': {
        loc: {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        }, {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 136,
            column: 7
          }
        }],
        line: 131
      },
      '12': {
        loc: {
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        }, {
          start: {
            line: 137,
            column: 6
          },
          end: {
            line: 145,
            column: 7
          }
        }],
        line: 137
      },
      '13': {
        loc: {
          start: {
            line: 137,
            column: 10
          },
          end: {
            line: 137,
            column: 98
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 137,
            column: 10
          },
          end: {
            line: 137,
            column: 58
          }
        }, {
          start: {
            line: 137,
            column: 63
          },
          end: {
            line: 137,
            column: 97
          }
        }],
        line: 137
      },
      '14': {
        loc: {
          start: {
            line: 147,
            column: 11
          },
          end: {
            line: 163,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 11
          },
          end: {
            line: 163,
            column: 5
          }
        }, {
          start: {
            line: 147,
            column: 11
          },
          end: {
            line: 163,
            column: 5
          }
        }],
        line: 147
      },
      '15': {
        loc: {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        }, {
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 157,
            column: 7
          }
        }],
        line: 148
      },
      '16': {
        loc: {
          start: {
            line: 165,
            column: 4
          },
          end: {
            line: 169,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 165,
            column: 4
          },
          end: {
            line: 169,
            column: 5
          }
        }, {
          start: {
            line: 165,
            column: 4
          },
          end: {
            line: 169,
            column: 5
          }
        }],
        line: 165
      },
      '17': {
        loc: {
          start: {
            line: 171,
            column: 59
          },
          end: {
            line: 171,
            column: 86
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 171,
            column: 59
          },
          end: {
            line: 171,
            column: 81
          }
        }, {
          start: {
            line: 171,
            column: 85
          },
          end: {
            line: 171,
            column: 86
          }
        }],
        line: 171
      },
      '18': {
        loc: {
          start: {
            line: 172,
            column: 34
          },
          end: {
            line: 172,
            column: 88
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 172,
            column: 34
          },
          end: {
            line: 172,
            column: 59
          }
        }, {
          start: {
            line: 172,
            column: 64
          },
          end: {
            line: 172,
            column: 87
          }
        }],
        line: 172
      },
      '19': {
        loc: {
          start: {
            line: 176,
            column: 2
          },
          end: {
            line: 193,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 176,
            column: 2
          },
          end: {
            line: 193,
            column: 3
          }
        }, {
          start: {
            line: 176,
            column: 2
          },
          end: {
            line: 193,
            column: 3
          }
        }],
        line: 176
      },
      '20': {
        loc: {
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 189,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 189,
            column: 7
          }
        }, {
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 189,
            column: 7
          }
        }],
        line: 181
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_29nd3d4e50.s[0]++;
angular.module('app').controller('indicatorOverviewContentCtrl', function ($scope, $translate, $stateParams, $state, $deltahttp, $CRUDService, $rootScope, SweetAlert, $filter, $deltaTypeIndicateur, $deltaActor, $deltaGeoLevel, $deltaUnite, $window, FileUploader, $deltaPeriode, $deltaLocation, $deltaPlanLogique, $deltaCategoriesBudget, $deltaFinancSource, $log) {
  cov_29nd3d4e50.f[0]++;

  var PATHGLOBAL = (cov_29nd3d4e50.s[1]++, 'DecoupageGlobalIndic');

  cov_29nd3d4e50.s[2]++;
  $scope.isGlobalIndicator = $stateParams.global;

  var PATH = (cov_29nd3d4e50.s[3]++, $stateParams.global ? (cov_29nd3d4e50.b[0][0]++, 'IndicateursGlobal') : (cov_29nd3d4e50.b[0][1]++, 'Indicateurs'));

  cov_29nd3d4e50.s[4]++;
  $deltaPeriode.addController($scope);
  cov_29nd3d4e50.s[5]++;
  $deltaLocation.addController($scope);
  cov_29nd3d4e50.s[6]++;
  $deltaPlanLogique.addController($scope);
  cov_29nd3d4e50.s[7]++;
  $deltaCategoriesBudget.addController($scope);
  cov_29nd3d4e50.s[8]++;
  $deltaFinancSource.addController($scope);
  cov_29nd3d4e50.s[9]++;
  $scope.addItem = $stateParams.indicator.id == 0;
  cov_29nd3d4e50.s[10]++;
  $scope.dashboardPanelOnly = $stateParams.dashboard;

  var requestOrigin = (cov_29nd3d4e50.s[11]++, angular.copy($stateParams.origin));
  var uploader = (cov_29nd3d4e50.s[12]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadimg&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATH,
    alias: 'img',
    removeAfterUpload: true
  }));
  cov_29nd3d4e50.s[13]++;
  uploader.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_29nd3d4e50.f[1]++;
    cov_29nd3d4e50.s[14]++;

    fileItem.indic.showprogress = true;
    cov_29nd3d4e50.s[15]++;
    fileItem.url = uploader.url + '&indic=' + fileItem.indic.id;
    cov_29nd3d4e50.s[16]++;
    fileItem.upload();
  };
  cov_29nd3d4e50.s[17]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_29nd3d4e50.f[2]++;
    cov_29nd3d4e50.s[18]++;

    $scope.selectedItem.ICON = Date.newDate();
    cov_29nd3d4e50.s[19]++;
    $scope.selectedItem.loading = undefined;
    cov_29nd3d4e50.s[20]++;
    $scope.selectedItem.file = true;
  };
  cov_29nd3d4e50.s[21]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_29nd3d4e50.f[3]++;
    cov_29nd3d4e50.s[22]++;

    fileItem.indic.showprogress = false;
  };

  cov_29nd3d4e50.s[23]++;
  $scope.closeIndicator = function () {
    cov_29nd3d4e50.f[4]++;
    cov_29nd3d4e50.s[24]++;

    $state.go(requestOrigin.from, requestOrigin.params);
  };

  cov_29nd3d4e50.s[25]++;
  $scope.changePageIndicator = function (page) {
    cov_29nd3d4e50.f[5]++;
    cov_29nd3d4e50.s[26]++;

    if ($stateParams.indicator.id > 0) {
      cov_29nd3d4e50.b[1][0]++;
      cov_29nd3d4e50.s[27]++;

      localStorage.setItem('INDICATOR_CONTENT_MAIN_PAGE_' + $stateParams.indicator.id, page);
    } else {
      cov_29nd3d4e50.b[1][1]++;
    }
  };

  cov_29nd3d4e50.s[28]++;
  $scope.initPage = function () {
    cov_29nd3d4e50.f[6]++;
    cov_29nd3d4e50.s[29]++;

    $scope.activeOnglet = 0;
    cov_29nd3d4e50.s[30]++;
    $deltaGeoLevel.addController($scope);
    cov_29nd3d4e50.s[31]++;
    $deltaActor.addController($scope);
    cov_29nd3d4e50.s[32]++;
    $scope.selectedItem = $stateParams.indicator;
    cov_29nd3d4e50.s[33]++;
    $scope.selectedItem.imageUrl = $deltahttp.getIndicatorRespositoy($stateParams.indicator.id);
    cov_29nd3d4e50.s[34]++;
    $deltaTypeIndicateur.addController($scope);
    cov_29nd3d4e50.s[35]++;
    $deltaUnite.addController($scope, function () {
      cov_29nd3d4e50.f[7]++;
      cov_29nd3d4e50.s[36]++;

      $scope.selectedItemUnit = $scope.listData_unites_bksb.filter(function (value) {
        cov_29nd3d4e50.f[8]++;
        cov_29nd3d4e50.s[37]++;

        return value.id == $scope.selectedItem.ID_UNITE;
      })[0];
    });

    cov_29nd3d4e50.s[38]++;
    $scope.getIndicateurLabel = function (item) {
      cov_29nd3d4e50.f[9]++;
      cov_29nd3d4e50.s[39]++;

      if (!item.LIBELLE_C_INDIC_ENG) {
        cov_29nd3d4e50.b[2][0]++;
        cov_29nd3d4e50.s[40]++;

        return item.LIBELLE_C_INDIC;
      } else {
        cov_29nd3d4e50.b[2][1]++;
      }
      cov_29nd3d4e50.s[41]++;
      if (item.LIBELLE_C_INDIC_ENG.trim() == '') {
        cov_29nd3d4e50.b[3][0]++;
        cov_29nd3d4e50.s[42]++;

        return item.LIBELLE_C_INDIC;
      } else {
        cov_29nd3d4e50.b[3][1]++;
      }

      cov_29nd3d4e50.s[43]++;
      if (!item.LIBELLE_C_INDIC) {
        cov_29nd3d4e50.b[4][0]++;
        cov_29nd3d4e50.s[44]++;

        return item.LIBELLE_C_INDIC_ENG;
      } else {
        cov_29nd3d4e50.b[4][1]++;
      }
      cov_29nd3d4e50.s[45]++;
      if (item.LIBELLE_C_INDIC.trim() == '') {
        cov_29nd3d4e50.b[5][0]++;
        cov_29nd3d4e50.s[46]++;

        return item.LIBELLE_C_INDIC_ENG;
      } else {
        cov_29nd3d4e50.b[5][1]++;
      }

      cov_29nd3d4e50.s[47]++;
      if ($rootScope.language.id == 'en') {
        cov_29nd3d4e50.b[6][0]++;
        cov_29nd3d4e50.s[48]++;

        return item.LIBELLE_C_INDIC_ENG;
      } else {
        cov_29nd3d4e50.b[6][1]++;
      }
      cov_29nd3d4e50.s[49]++;
      return item.LIBELLE_C_INDIC;
    };

    cov_29nd3d4e50.s[50]++;
    $scope.getGlobalValue = function (indicItem) {
      cov_29nd3d4e50.f[10]++;
      cov_29nd3d4e50.s[51]++;

      if (!indicItem) {
        cov_29nd3d4e50.b[7][0]++;
        cov_29nd3d4e50.s[52]++;

        return;
      } else {
        cov_29nd3d4e50.b[7][1]++;
      }
      cov_29nd3d4e50.s[53]++;
      $scope.glovalValue = {};
      cov_29nd3d4e50.s[54]++;
      $CRUDService.getAll(PATHGLOBAL, { get: 'all_global', idcl: indicItem.CODE_CL, id: indicItem.id }, function (data) {
        cov_29nd3d4e50.f[11]++;
        cov_29nd3d4e50.s[55]++;

        try {
          cov_29nd3d4e50.s[56]++;

          data.c = eval(data.c);
        } catch (ex) {
          cov_29nd3d4e50.s[57]++;

          data.c = null;
        }
        cov_29nd3d4e50.s[58]++;
        try {
          cov_29nd3d4e50.s[59]++;

          data.r = eval(data.r);
        } catch (ex) {
          cov_29nd3d4e50.s[60]++;

          data.r = null;
        }
        cov_29nd3d4e50.s[61]++;
        $scope.glovalValue = data;
      });
    };
    // Fonction getGlobalValue dupliquer dans le fils :indicatorGlobalValueCtrl
    cov_29nd3d4e50.s[62]++;
    $scope.getGlobalValueClone = $scope.getGlobalValue;

    cov_29nd3d4e50.s[63]++;
    $scope.getGlobalValue($scope.selectedItem);

    cov_29nd3d4e50.s[64]++;
    $scope.global_can_collect = true;
    cov_29nd3d4e50.s[65]++;
    $scope.canTrack = false;
    cov_29nd3d4e50.s[66]++;
    $scope.canPlan = false;
    cov_29nd3d4e50.s[67]++;
    $scope.canReport = false;
    // $log.log("content.js");
    // $log.log($scope.selectedItem);
    cov_29nd3d4e50.s[68]++;
    if ($rootScope.getCurrentProject().project.PORTFOLIO == 1) {
      cov_29nd3d4e50.b[8][0]++;
      cov_29nd3d4e50.s[69]++;
      // Portefeuille
      //  && ($scope.selectedItem.LINKED_INDICATOR==0)

      if ($rootScope.getCurrentProject().project.id != $scope.selectedItem.ID_PROJET) {
        cov_29nd3d4e50.b[9][0]++;
        cov_29nd3d4e50.s[70]++;
        // Indicateur d'un autre projet
        $scope.canTrack = false;
        cov_29nd3d4e50.s[71]++;
        $scope.canPlan = false;
        cov_29nd3d4e50.s[72]++;
        $scope.canReport = $scope.selectedItem.CROSS_INDICATOR != 1;
        cov_29nd3d4e50.s[73]++;
        $scope.global_can_collect = false;
      } else {
          cov_29nd3d4e50.b[9][1]++;
          cov_29nd3d4e50.s[74]++;
          if ($scope.selectedItem.CROSS_INDICATOR == 1) {
            cov_29nd3d4e50.b[10][0]++;
            cov_29nd3d4e50.s[75]++;
            // Indicateur Transversale
            //  && ($scope.selectedItem.LINKED_INDICATOR==0)
            $scope.canTrack = false;
            cov_29nd3d4e50.s[76]++;
            $scope.canPlan = false;
            cov_29nd3d4e50.s[77]++;
            $scope.canReport = true;
            cov_29nd3d4e50.s[78]++;
            $scope.global_can_collect = false;
          } else {
            cov_29nd3d4e50.b[10][1]++;
            cov_29nd3d4e50.s[79]++;
            // Indicateur normal du projet
            $scope.canTrack = true;
            cov_29nd3d4e50.s[80]++;
            $scope.canPlan = true;
            cov_29nd3d4e50.s[81]++;
            $scope.canReport = true;
          }
        }cov_29nd3d4e50.s[82]++;
      if ($scope.selectedItem.INDICATEUR_GLOBAL == 1) {
        cov_29nd3d4e50.b[11][0]++;
        cov_29nd3d4e50.s[83]++;

        $scope.canTrack = false;
        cov_29nd3d4e50.s[84]++;
        $scope.canPlan = false;
        cov_29nd3d4e50.s[85]++;
        $scope.global_can_collect = false;
        cov_29nd3d4e50.s[86]++;
        $scope.canReport = true;
      } else {
        cov_29nd3d4e50.b[11][1]++;
      }
      cov_29nd3d4e50.s[87]++;
      if ((cov_29nd3d4e50.b[13][0]++, angular.isDefined($scope.selectedItem.PORTFOLIO)) && (cov_29nd3d4e50.b[13][1]++, $scope.selectedItem.PORTFOLIO == 1)) {
        cov_29nd3d4e50.b[12][0]++;
        cov_29nd3d4e50.s[88]++;

        $state.go('indicator.overview.dashboards', {
          origin: $stateParams.origin,
          reload: false,
          indicator: $scope.selectedItem,
          idcl: $scope.selectedItem.CODE_CL,
          id: $scope.selectedItem.id
        });
      } else {
        cov_29nd3d4e50.b[12][1]++;
      }
    } else {
        cov_29nd3d4e50.b[8][1]++;
        cov_29nd3d4e50.s[89]++;
        if (!$scope.isGlobalIndicator) {
          cov_29nd3d4e50.b[14][0]++;
          cov_29nd3d4e50.s[90]++;

          if ($rootScope.getCurrentProject().project.id != $scope.selectedItem.ID_PROJET) {
            cov_29nd3d4e50.b[15][0]++;
            cov_29nd3d4e50.s[91]++;
            // Indicateur Transversal
            $scope.canTrack = false;
            cov_29nd3d4e50.s[92]++;
            $scope.canPlan = false;
            cov_29nd3d4e50.s[93]++;
            $scope.canReport = false;
            cov_29nd3d4e50.s[94]++;
            $scope.global_can_collect = false;
          } else {
            cov_29nd3d4e50.b[15][1]++;
            cov_29nd3d4e50.s[95]++;
            // Indicateur normal du projet
            $scope.canTrack = true;
            cov_29nd3d4e50.s[96]++;
            $scope.canPlan = true;
            cov_29nd3d4e50.s[97]++;
            $scope.canReport = true;
          }
        } else {
          cov_29nd3d4e50.b[14][1]++;
          cov_29nd3d4e50.s[98]++;

          $scope.canTrack = true;
          cov_29nd3d4e50.s[99]++;
          $scope.canPlan = true;
          cov_29nd3d4e50.s[100]++;
          $scope.canReport = true;
          cov_29nd3d4e50.s[101]++;
          $scope.global_can_collect = false;
        }
      }cov_29nd3d4e50.s[102]++;
    if ($scope.selectedItem.Type_indicateur == 2) {
      cov_29nd3d4e50.b[16][0]++;
      cov_29nd3d4e50.s[103]++;

      $scope.canTrack = false;
      cov_29nd3d4e50.s[104]++;
      $scope.canPlan = false;
      cov_29nd3d4e50.s[105]++;
      $scope.global_can_collect = false;
    } else {
      cov_29nd3d4e50.b[16][1]++;
    }

    cov_29nd3d4e50.s[106]++;
    $CRUDService.getAll(PATH, { get: 'can_global_plan', id: (cov_29nd3d4e50.b[17][0]++, $scope.selectedItem.id) || (cov_29nd3d4e50.b[17][1]++, 0) }, function (data) {
      cov_29nd3d4e50.f[12]++;
      cov_29nd3d4e50.s[107]++;

      $scope.global_can_collect = (cov_29nd3d4e50.b[18][0]++, $scope.global_can_collect) && (cov_29nd3d4e50.b[18][1]++, parseInt(data, 10) == 1);
    });
  };

  cov_29nd3d4e50.s[108]++;
  if ($stateParams.reload) {
    cov_29nd3d4e50.b[19][0]++;
    cov_29nd3d4e50.s[109]++;

    $scope.isloading = true;
    cov_29nd3d4e50.s[110]++;
    $CRUDService.getAll(PATH, { get: 'all', idcl: $stateParams.indicator.CODE_CL, idp: $stateParams.indicator.ID_PROJET, id: $stateParams.indicator.id, page: 1, nr: true, cat: true }, function (data) {
      cov_29nd3d4e50.f[13]++;
      cov_29nd3d4e50.s[111]++;

      $scope.loading = false;

      cov_29nd3d4e50.s[112]++;
      if (data.length > 0) {
        cov_29nd3d4e50.b[20][0]++;
        cov_29nd3d4e50.s[113]++;

        $state.go('indicator.overview.settings', {
          origin: $stateParams.origin,
          reload: false,
          indicator: data[0],
          idcl: data[0].CODE_CL,
          id: data[0].id
        });
      } else {
        cov_29nd3d4e50.b[20][1]++;
      }
    });
  } else {
    cov_29nd3d4e50.b[19][1]++;
    cov_29nd3d4e50.s[114]++;

    $scope.initPage();
  }
});
'use strict';

var cov_2oslm54p1m = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-table-pivot.js',
      hash = 'c7d75fd50cd9facc61d43d63f46e4678d452e93f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/directives/dashboard-table-pivot.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 30
        },
        end: {
          line: 427,
          column: 1
        }
      },
      '1': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 25
        }
      },
      '2': {
        start: {
          line: 4,
          column: 16
        },
        end: {
          line: 4,
          column: 20
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 133,
          column: 4
        }
      },
      '4': {
        start: {
          line: 41,
          column: 30
        },
        end: {
          line: 41,
          column: 57
        }
      },
      '5': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 69
        }
      },
      '6': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 44,
          column: 75
        }
      },
      '7': {
        start: {
          line: 48,
          column: 21
        },
        end: {
          line: 48,
          column: 50
        }
      },
      '8': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 56,
          column: 11
        }
      },
      '9': {
        start: {
          line: 53,
          column: 12
        },
        end: {
          line: 53,
          column: 53
        }
      },
      '10': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 64,
          column: 11
        }
      },
      '11': {
        start: {
          line: 61,
          column: 12
        },
        end: {
          line: 61,
          column: 55
        }
      },
      '12': {
        start: {
          line: 70,
          column: 8
        },
        end: {
          line: 70,
          column: 50
        }
      },
      '13': {
        start: {
          line: 76,
          column: 21
        },
        end: {
          line: 76,
          column: 87
        }
      },
      '14': {
        start: {
          line: 77,
          column: 21
        },
        end: {
          line: 77,
          column: 25
        }
      },
      '15': {
        start: {
          line: 78,
          column: 23
        },
        end: {
          line: 78,
          column: 27
        }
      },
      '16': {
        start: {
          line: 79,
          column: 17
        },
        end: {
          line: 79,
          column: 19
        }
      },
      '17': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 80,
          column: 95
        }
      },
      '18': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 81,
          column: 60
        }
      },
      '19': {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 131,
          column: 7
        }
      },
      '20': {
        start: {
          line: 83,
          column: 36
        },
        end: {
          line: 112,
          column: 9
        }
      },
      '21': {
        start: {
          line: 113,
          column: 8
        },
        end: {
          line: 113,
          column: 72
        }
      },
      '22': {
        start: {
          line: 114,
          column: 8
        },
        end: {
          line: 114,
          column: 100
        }
      },
      '23': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 117,
          column: 9
        }
      },
      '24': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 64
        }
      },
      '25': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 118,
          column: 48
        }
      },
      '26': {
        start: {
          line: 119,
          column: 8
        },
        end: {
          line: 119,
          column: 60
        }
      },
      '27': {
        start: {
          line: 120,
          column: 8
        },
        end: {
          line: 122,
          column: 9
        }
      },
      '28': {
        start: {
          line: 121,
          column: 10
        },
        end: {
          line: 121,
          column: 89
        }
      },
      '29': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 126,
          column: 11
        }
      },
      '30': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 130,
          column: 9
        }
      },
      '31': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 52
        }
      },
      '32': {
        start: {
          line: 129,
          column: 10
        },
        end: {
          line: 129,
          column: 69
        }
      },
      '33': {
        start: {
          line: 135,
          column: 2
        },
        end: {
          line: 135,
          column: 28
        }
      },
      '34': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 141,
          column: 4
        }
      },
      '35': {
        start: {
          line: 138,
          column: 4
        },
        end: {
          line: 140,
          column: 7
        }
      },
      '36': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 139,
          column: 34
        }
      },
      '37': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 144,
          column: 4
        }
      },
      '38': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 143,
          column: 41
        }
      },
      '39': {
        start: {
          line: 145,
          column: 2
        },
        end: {
          line: 289,
          column: 4
        }
      },
      '40': {
        start: {
          line: 146,
          column: 4
        },
        end: {
          line: 148,
          column: 5
        }
      },
      '41': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 147,
          column: 13
        }
      },
      '42': {
        start: {
          line: 149,
          column: 19
        },
        end: {
          line: 149,
          column: 150
        }
      },
      '43': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 288,
          column: 7
        }
      },
      '44': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 153,
          column: 7
        }
      },
      '45': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 152,
          column: 15
        }
      },
      '46': {
        start: {
          line: 154,
          column: 24
        },
        end: {
          line: 156,
          column: 8
        }
      },
      '47': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 155,
          column: 58
        }
      },
      '48': {
        start: {
          line: 157,
          column: 20
        },
        end: {
          line: 157,
          column: 63
        }
      },
      '49': {
        start: {
          line: 158,
          column: 18
        },
        end: {
          line: 158,
          column: 19
        }
      },
      '50': {
        start: {
          line: 159,
          column: 15
        },
        end: {
          line: 159,
          column: 18
        }
      },
      '51': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 167,
          column: 7
        }
      },
      '52': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 161,
          column: 18
        }
      },
      '53': {
        start: {
          line: 162,
          column: 22
        },
        end: {
          line: 162,
          column: 60
        }
      },
      '54': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 163,
          column: 22
        }
      },
      '55': {
        start: {
          line: 164,
          column: 8
        },
        end: {
          line: 166,
          column: 9
        }
      },
      '56': {
        start: {
          line: 165,
          column: 10
        },
        end: {
          line: 165,
          column: 51
        }
      },
      '57': {
        start: {
          line: 168,
          column: 19
        },
        end: {
          line: 222,
          column: 7
        }
      },
      '58': {
        start: {
          line: 169,
          column: 71
        },
        end: {
          line: 208,
          column: 9
        }
      },
      '59': {
        start: {
          line: 172,
          column: 26
        },
        end: {
          line: 172,
          column: 49
        }
      },
      '60': {
        start: {
          line: 173,
          column: 14
        },
        end: {
          line: 175,
          column: 15
        }
      },
      '61': {
        start: {
          line: 174,
          column: 16
        },
        end: {
          line: 174,
          column: 29
        }
      },
      '62': {
        start: {
          line: 176,
          column: 14
        },
        end: {
          line: 178,
          column: 15
        }
      },
      '63': {
        start: {
          line: 177,
          column: 16
        },
        end: {
          line: 177,
          column: 29
        }
      },
      '64': {
        start: {
          line: 179,
          column: 14
        },
        end: {
          line: 181,
          column: 15
        }
      },
      '65': {
        start: {
          line: 180,
          column: 16
        },
        end: {
          line: 180,
          column: 28
        }
      },
      '66': {
        start: {
          line: 182,
          column: 14
        },
        end: {
          line: 182,
          column: 27
        }
      },
      '67': {
        start: {
          line: 185,
          column: 14
        },
        end: {
          line: 185,
          column: 52
        }
      },
      '68': {
        start: {
          line: 186,
          column: 14
        },
        end: {
          line: 186,
          column: 101
        }
      },
      '69': {
        start: {
          line: 187,
          column: 14
        },
        end: {
          line: 187,
          column: 23
        }
      },
      '70': {
        start: {
          line: 224,
          column: 6
        },
        end: {
          line: 268,
          column: 7
        }
      },
      '71': {
        start: {
          line: 225,
          column: 8
        },
        end: {
          line: 267,
          column: 10
        }
      },
      '72': {
        start: {
          line: 240,
          column: 26
        },
        end: {
          line: 244,
          column: 13
        }
      },
      '73': {
        start: {
          line: 246,
          column: 12
        },
        end: {
          line: 250,
          column: 13
        }
      },
      '74': {
        start: {
          line: 247,
          column: 14
        },
        end: {
          line: 249,
          column: 16
        }
      },
      '75': {
        start: {
          line: 251,
          column: 12
        },
        end: {
          line: 251,
          column: 25
        }
      },
      '76': {
        start: {
          line: 263,
          column: 12
        },
        end: {
          line: 263,
          column: 42
        }
      },
      '77': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 287,
          column: 7
        }
      },
      '78': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 271,
          column: 83
        }
      },
      '79': {
        start: {
          line: 273,
          column: 8
        },
        end: {
          line: 283,
          column: 65
        }
      },
      '80': {
        start: {
          line: 284,
          column: 8
        },
        end: {
          line: 284,
          column: 95
        }
      },
      '81': {
        start: {
          line: 291,
          column: 2
        },
        end: {
          line: 417,
          column: 4
        }
      },
      '82': {
        start: {
          line: 292,
          column: 4
        },
        end: {
          line: 292,
          column: 31
        }
      },
      '83': {
        start: {
          line: 294,
          column: 4
        },
        end: {
          line: 294,
          column: 27
        }
      },
      '84': {
        start: {
          line: 295,
          column: 19
        },
        end: {
          line: 295,
          column: 144
        }
      },
      '85': {
        start: {
          line: 297,
          column: 4
        },
        end: {
          line: 416,
          column: 7
        }
      },
      '86': {
        start: {
          line: 298,
          column: 6
        },
        end: {
          line: 298,
          column: 34
        }
      },
      '87': {
        start: {
          line: 299,
          column: 6
        },
        end: {
          line: 299,
          column: 115
        }
      },
      '88': {
        start: {
          line: 301,
          column: 22
        },
        end: {
          line: 301,
          column: 108
        }
      },
      '89': {
        start: {
          line: 301,
          column: 89
        },
        end: {
          line: 301,
          column: 107
        }
      },
      '90': {
        start: {
          line: 302,
          column: 25
        },
        end: {
          line: 302,
          column: 114
        }
      },
      '91': {
        start: {
          line: 302,
          column: 95
        },
        end: {
          line: 302,
          column: 113
        }
      },
      '92': {
        start: {
          line: 303,
          column: 24
        },
        end: {
          line: 303,
          column: 112
        }
      },
      '93': {
        start: {
          line: 303,
          column: 93
        },
        end: {
          line: 303,
          column: 111
        }
      },
      '94': {
        start: {
          line: 304,
          column: 24
        },
        end: {
          line: 304,
          column: 113
        }
      },
      '95': {
        start: {
          line: 304,
          column: 94
        },
        end: {
          line: 304,
          column: 112
        }
      },
      '96': {
        start: {
          line: 305,
          column: 18
        },
        end: {
          line: 305,
          column: 91
        }
      },
      '97': {
        start: {
          line: 307,
          column: 21
        },
        end: {
          line: 312,
          column: 8
        }
      },
      '98': {
        start: {
          line: 308,
          column: 8
        },
        end: {
          line: 310,
          column: 9
        }
      },
      '99': {
        start: {
          line: 309,
          column: 10
        },
        end: {
          line: 309,
          column: 23
        }
      },
      '100': {
        start: {
          line: 311,
          column: 8
        },
        end: {
          line: 311,
          column: 20
        }
      },
      '101': {
        start: {
          line: 313,
          column: 6
        },
        end: {
          line: 313,
          column: 137
        }
      },
      '102': {
        start: {
          line: 316,
          column: 6
        },
        end: {
          line: 324,
          column: 9
        }
      },
      '103': {
        start: {
          line: 317,
          column: 23
        },
        end: {
          line: 319,
          column: 10
        }
      },
      '104': {
        start: {
          line: 318,
          column: 10
        },
        end: {
          line: 318,
          column: 45
        }
      },
      '105': {
        start: {
          line: 320,
          column: 8
        },
        end: {
          line: 322,
          column: 9
        }
      },
      '106': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 45
        }
      },
      '107': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 323,
          column: 22
        }
      },
      '108': {
        start: {
          line: 326,
          column: 6
        },
        end: {
          line: 334,
          column: 9
        }
      },
      '109': {
        start: {
          line: 327,
          column: 23
        },
        end: {
          line: 329,
          column: 10
        }
      },
      '110': {
        start: {
          line: 328,
          column: 10
        },
        end: {
          line: 328,
          column: 45
        }
      },
      '111': {
        start: {
          line: 330,
          column: 8
        },
        end: {
          line: 332,
          column: 9
        }
      },
      '112': {
        start: {
          line: 331,
          column: 10
        },
        end: {
          line: 331,
          column: 45
        }
      },
      '113': {
        start: {
          line: 333,
          column: 8
        },
        end: {
          line: 333,
          column: 22
        }
      },
      '114': {
        start: {
          line: 336,
          column: 6
        },
        end: {
          line: 344,
          column: 9
        }
      },
      '115': {
        start: {
          line: 337,
          column: 23
        },
        end: {
          line: 339,
          column: 10
        }
      },
      '116': {
        start: {
          line: 338,
          column: 10
        },
        end: {
          line: 338,
          column: 45
        }
      },
      '117': {
        start: {
          line: 340,
          column: 8
        },
        end: {
          line: 342,
          column: 9
        }
      },
      '118': {
        start: {
          line: 341,
          column: 10
        },
        end: {
          line: 341,
          column: 45
        }
      },
      '119': {
        start: {
          line: 343,
          column: 8
        },
        end: {
          line: 343,
          column: 22
        }
      },
      '120': {
        start: {
          line: 346,
          column: 6
        },
        end: {
          line: 365,
          column: 9
        }
      },
      '121': {
        start: {
          line: 347,
          column: 23
        },
        end: {
          line: 349,
          column: 10
        }
      },
      '122': {
        start: {
          line: 348,
          column: 10
        },
        end: {
          line: 348,
          column: 45
        }
      },
      '123': {
        start: {
          line: 350,
          column: 8
        },
        end: {
          line: 363,
          column: 9
        }
      },
      '124': {
        start: {
          line: 351,
          column: 10
        },
        end: {
          line: 351,
          column: 45
        }
      },
      '125': {
        start: {
          line: 352,
          column: 10
        },
        end: {
          line: 359,
          column: 11
        }
      },
      '126': {
        start: {
          line: 353,
          column: 25
        },
        end: {
          line: 353,
          column: 67
        }
      },
      '127': {
        start: {
          line: 354,
          column: 12
        },
        end: {
          line: 356,
          column: 13
        }
      },
      '128': {
        start: {
          line: 355,
          column: 14
        },
        end: {
          line: 355,
          column: 32
        }
      },
      '129': {
        start: {
          line: 357,
          column: 12
        },
        end: {
          line: 357,
          column: 33
        }
      },
      '130': {
        start: {
          line: 358,
          column: 12
        },
        end: {
          line: 358,
          column: 35
        }
      },
      '131': {
        start: {
          line: 360,
          column: 10
        },
        end: {
          line: 362,
          column: 11
        }
      },
      '132': {
        start: {
          line: 361,
          column: 12
        },
        end: {
          line: 361,
          column: 43
        }
      },
      '133': {
        start: {
          line: 364,
          column: 8
        },
        end: {
          line: 364,
          column: 22
        }
      },
      '134': {
        start: {
          line: 369,
          column: 6
        },
        end: {
          line: 377,
          column: 9
        }
      },
      '135': {
        start: {
          line: 370,
          column: 23
        },
        end: {
          line: 372,
          column: 10
        }
      },
      '136': {
        start: {
          line: 371,
          column: 10
        },
        end: {
          line: 371,
          column: 45
        }
      },
      '137': {
        start: {
          line: 373,
          column: 8
        },
        end: {
          line: 375,
          column: 9
        }
      },
      '138': {
        start: {
          line: 374,
          column: 10
        },
        end: {
          line: 374,
          column: 54
        }
      },
      '139': {
        start: {
          line: 376,
          column: 8
        },
        end: {
          line: 376,
          column: 21
        }
      },
      '140': {
        start: {
          line: 379,
          column: 6
        },
        end: {
          line: 405,
          column: 81
        }
      },
      '141': {
        start: {
          line: 380,
          column: 8
        },
        end: {
          line: 382,
          column: 9
        }
      },
      '142': {
        start: {
          line: 381,
          column: 10
        },
        end: {
          line: 381,
          column: 23
        }
      },
      '143': {
        start: {
          line: 383,
          column: 24
        },
        end: {
          line: 385,
          column: 10
        }
      },
      '144': {
        start: {
          line: 384,
          column: 10
        },
        end: {
          line: 384,
          column: 45
        }
      },
      '145': {
        start: {
          line: 386,
          column: 8
        },
        end: {
          line: 386,
          column: 37
        }
      },
      '146': {
        start: {
          line: 388,
          column: 22
        },
        end: {
          line: 388,
          column: 41
        }
      },
      '147': {
        start: {
          line: 389,
          column: 8
        },
        end: {
          line: 396,
          column: 9
        }
      },
      '148': {
        start: {
          line: 390,
          column: 23
        },
        end: {
          line: 390,
          column: 65
        }
      },
      '149': {
        start: {
          line: 391,
          column: 10
        },
        end: {
          line: 393,
          column: 11
        }
      },
      '150': {
        start: {
          line: 392,
          column: 12
        },
        end: {
          line: 392,
          column: 30
        }
      },
      '151': {
        start: {
          line: 394,
          column: 10
        },
        end: {
          line: 394,
          column: 30
        }
      },
      '152': {
        start: {
          line: 395,
          column: 10
        },
        end: {
          line: 395,
          column: 32
        }
      },
      '153': {
        start: {
          line: 397,
          column: 8
        },
        end: {
          line: 403,
          column: 9
        }
      },
      '154': {
        start: {
          line: 398,
          column: 10
        },
        end: {
          line: 398,
          column: 40
        }
      },
      '155': {
        start: {
          line: 401,
          column: 10
        },
        end: {
          line: 401,
          column: 45
        }
      },
      '156': {
        start: {
          line: 402,
          column: 10
        },
        end: {
          line: 402,
          column: 45
        }
      },
      '157': {
        start: {
          line: 404,
          column: 8
        },
        end: {
          line: 404,
          column: 21
        }
      },
      '158': {
        start: {
          line: 406,
          column: 6
        },
        end: {
          line: 410,
          column: 9
        }
      },
      '159': {
        start: {
          line: 412,
          column: 6
        },
        end: {
          line: 412,
          column: 104
        }
      },
      '160': {
        start: {
          line: 413,
          column: 6
        },
        end: {
          line: 413,
          column: 102
        }
      },
      '161': {
        start: {
          line: 414,
          column: 6
        },
        end: {
          line: 414,
          column: 87
        }
      },
      '162': {
        start: {
          line: 415,
          column: 6
        },
        end: {
          line: 415,
          column: 73
        }
      },
      '163': {
        start: {
          line: 418,
          column: 2
        },
        end: {
          line: 418,
          column: 25
        }
      },
      '164': {
        start: {
          line: 419,
          column: 2
        },
        end: {
          line: 426,
          column: 5
        }
      },
      '165': {
        start: {
          line: 420,
          column: 4
        },
        end: {
          line: 420,
          column: 34
        }
      },
      '166': {
        start: {
          line: 421,
          column: 4
        },
        end: {
          line: 421,
          column: 47
        }
      },
      '167': {
        start: {
          line: 422,
          column: 4
        },
        end: {
          line: 422,
          column: 120
        }
      },
      '168': {
        start: {
          line: 423,
          column: 4
        },
        end: {
          line: 423,
          column: 45
        }
      },
      '169': {
        start: {
          line: 424,
          column: 4
        },
        end: {
          line: 424,
          column: 48
        }
      },
      '170': {
        start: {
          line: 425,
          column: 4
        },
        end: {
          line: 425,
          column: 26
        }
      },
      '171': {
        start: {
          line: 428,
          column: 0
        },
        end: {
          line: 449,
          column: 3
        }
      },
      '172': {
        start: {
          line: 429,
          column: 2
        },
        end: {
          line: 448,
          column: 4
        }
      },
      '173': {
        start: {
          line: 438,
          column: 6
        },
        end: {
          line: 445,
          column: 8
        }
      },
      '174': {
        start: {
          line: 441,
          column: 10
        },
        end: {
          line: 443,
          column: 11
        }
      },
      '175': {
        start: {
          line: 442,
          column: 12
        },
        end: {
          line: 442,
          column: 42
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 30
          },
          end: {
            line: 1,
            column: 31
          }
        },
        loc: {
          start: {
            line: 1,
            column: 138
          },
          end: {
            line: 427,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 137,
            column: 27
          },
          end: {
            line: 137,
            column: 28
          }
        },
        loc: {
          start: {
            line: 137,
            column: 41
          },
          end: {
            line: 141,
            column: 3
          }
        },
        line: 137
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 138,
            column: 71
          },
          end: {
            line: 138,
            column: 72
          }
        },
        loc: {
          start: {
            line: 138,
            column: 79
          },
          end: {
            line: 140,
            column: 5
          }
        },
        line: 138
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 142,
            column: 23
          },
          end: {
            line: 142,
            column: 24
          }
        },
        loc: {
          start: {
            line: 142,
            column: 35
          },
          end: {
            line: 144,
            column: 3
          }
        },
        line: 142
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 145,
            column: 21
          },
          end: {
            line: 145,
            column: 22
          }
        },
        loc: {
          start: {
            line: 145,
            column: 35
          },
          end: {
            line: 289,
            column: 3
          }
        },
        line: 145
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 150,
            column: 38
          },
          end: {
            line: 150,
            column: 39
          }
        },
        loc: {
          start: {
            line: 150,
            column: 46
          },
          end: {
            line: 288,
            column: 5
          }
        },
        line: 150
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 154,
            column: 52
          },
          end: {
            line: 154,
            column: 53
          }
        },
        loc: {
          start: {
            line: 154,
            column: 61
          },
          end: {
            line: 156,
            column: 7
          }
        },
        line: 154
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 169,
            column: 61
          },
          end: {
            line: 169,
            column: 62
          }
        },
        loc: {
          start: {
            line: 169,
            column: 71
          },
          end: {
            line: 208,
            column: 9
          }
        },
        line: 169
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 171,
            column: 20
          },
          end: {
            line: 171,
            column: 21
          }
        },
        loc: {
          start: {
            line: 171,
            column: 25
          },
          end: {
            line: 183,
            column: 13
          }
        },
        line: 171
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 184,
            column: 17
          },
          end: {
            line: 184,
            column: 18
          }
        },
        loc: {
          start: {
            line: 184,
            column: 22
          },
          end: {
            line: 188,
            column: 13
          }
        },
        line: 184
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 239,
            column: 58
          },
          end: {
            line: 239,
            column: 59
          }
        },
        loc: {
          start: {
            line: 239,
            column: 67
          },
          end: {
            line: 252,
            column: 11
          }
        },
        line: 239
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 262,
            column: 60
          },
          end: {
            line: 262,
            column: 61
          }
        },
        loc: {
          start: {
            line: 262,
            column: 69
          },
          end: {
            line: 264,
            column: 11
          }
        },
        line: 262
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 291,
            column: 18
          },
          end: {
            line: 291,
            column: 19
          }
        },
        loc: {
          start: {
            line: 291,
            column: 32
          },
          end: {
            line: 417,
            column: 3
          }
        },
        line: 291
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 297,
            column: 38
          },
          end: {
            line: 297,
            column: 39
          }
        },
        loc: {
          start: {
            line: 297,
            column: 46
          },
          end: {
            line: 416,
            column: 5
          }
        },
        line: 297
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 301,
            column: 80
          },
          end: {
            line: 301,
            column: 81
          }
        },
        loc: {
          start: {
            line: 301,
            column: 89
          },
          end: {
            line: 301,
            column: 107
          }
        },
        line: 301
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 302,
            column: 86
          },
          end: {
            line: 302,
            column: 87
          }
        },
        loc: {
          start: {
            line: 302,
            column: 95
          },
          end: {
            line: 302,
            column: 113
          }
        },
        line: 302
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 303,
            column: 84
          },
          end: {
            line: 303,
            column: 85
          }
        },
        loc: {
          start: {
            line: 303,
            column: 93
          },
          end: {
            line: 303,
            column: 111
          }
        },
        line: 303
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 304,
            column: 85
          },
          end: {
            line: 304,
            column: 86
          }
        },
        loc: {
          start: {
            line: 304,
            column: 94
          },
          end: {
            line: 304,
            column: 112
          }
        },
        line: 304
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 307,
            column: 38
          },
          end: {
            line: 307,
            column: 39
          }
        },
        loc: {
          start: {
            line: 307,
            column: 47
          },
          end: {
            line: 312,
            column: 7
          }
        },
        line: 307
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 316,
            column: 128
          },
          end: {
            line: 316,
            column: 129
          }
        },
        loc: {
          start: {
            line: 316,
            column: 138
          },
          end: {
            line: 324,
            column: 7
          }
        },
        line: 316
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 317,
            column: 40
          },
          end: {
            line: 317,
            column: 41
          }
        },
        loc: {
          start: {
            line: 317,
            column: 49
          },
          end: {
            line: 319,
            column: 9
          }
        },
        line: 317
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 326,
            column: 134
          },
          end: {
            line: 326,
            column: 135
          }
        },
        loc: {
          start: {
            line: 326,
            column: 144
          },
          end: {
            line: 334,
            column: 7
          }
        },
        line: 326
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 327,
            column: 40
          },
          end: {
            line: 327,
            column: 41
          }
        },
        loc: {
          start: {
            line: 327,
            column: 49
          },
          end: {
            line: 329,
            column: 9
          }
        },
        line: 327
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 336,
            column: 132
          },
          end: {
            line: 336,
            column: 133
          }
        },
        loc: {
          start: {
            line: 336,
            column: 142
          },
          end: {
            line: 344,
            column: 7
          }
        },
        line: 336
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 337,
            column: 40
          },
          end: {
            line: 337,
            column: 41
          }
        },
        loc: {
          start: {
            line: 337,
            column: 49
          },
          end: {
            line: 339,
            column: 9
          }
        },
        line: 337
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 346,
            column: 134
          },
          end: {
            line: 346,
            column: 135
          }
        },
        loc: {
          start: {
            line: 346,
            column: 144
          },
          end: {
            line: 365,
            column: 7
          }
        },
        line: 346
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 347,
            column: 40
          },
          end: {
            line: 347,
            column: 41
          }
        },
        loc: {
          start: {
            line: 347,
            column: 49
          },
          end: {
            line: 349,
            column: 9
          }
        },
        line: 347
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 369,
            column: 151
          },
          end: {
            line: 369,
            column: 152
          }
        },
        loc: {
          start: {
            line: 369,
            column: 161
          },
          end: {
            line: 377,
            column: 7
          }
        },
        line: 369
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 370,
            column: 40
          },
          end: {
            line: 370,
            column: 41
          }
        },
        loc: {
          start: {
            line: 370,
            column: 49
          },
          end: {
            line: 372,
            column: 9
          }
        },
        line: 370
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 379,
            column: 90
          },
          end: {
            line: 379,
            column: 91
          }
        },
        loc: {
          start: {
            line: 379,
            column: 99
          },
          end: {
            line: 387,
            column: 7
          }
        },
        line: 379
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 383,
            column: 95
          },
          end: {
            line: 383,
            column: 96
          }
        },
        loc: {
          start: {
            line: 383,
            column: 105
          },
          end: {
            line: 385,
            column: 9
          }
        },
        line: 383
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 387,
            column: 14
          },
          end: {
            line: 387,
            column: 15
          }
        },
        loc: {
          start: {
            line: 387,
            column: 24
          },
          end: {
            line: 405,
            column: 7
          }
        },
        line: 387
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 419,
            column: 75
          },
          end: {
            line: 419,
            column: 76
          }
        },
        loc: {
          start: {
            line: 419,
            column: 83
          },
          end: {
            line: 426,
            column: 3
          }
        },
        line: 419
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 428,
            column: 55
          },
          end: {
            line: 428,
            column: 56
          }
        },
        loc: {
          start: {
            line: 428,
            column: 61
          },
          end: {
            line: 449,
            column: 1
          }
        },
        line: 428
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 440,
            column: 8
          },
          end: {
            line: 440,
            column: 9
          }
        },
        loc: {
          start: {
            line: 440,
            column: 14
          },
          end: {
            line: 444,
            column: 9
          }
        },
        line: 440
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 42,
            column: 26
          },
          end: {
            line: 42,
            column: 68
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 42,
            column: 42
          },
          end: {
            line: 42,
            column: 55
          }
        }, {
          start: {
            line: 42,
            column: 58
          },
          end: {
            line: 42,
            column: 68
          }
        }],
        line: 42
      },
      '1': {
        loc: {
          start: {
            line: 76,
            column: 22
          },
          end: {
            line: 76,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 76,
            column: 22
          },
          end: {
            line: 76,
            column: 56
          }
        }, {
          start: {
            line: 76,
            column: 60
          },
          end: {
            line: 76,
            column: 72
          }
        }],
        line: 76
      },
      '2': {
        loc: {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }, {
          start: {
            line: 82,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }],
        line: 82
      },
      '3': {
        loc: {
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        }, {
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        }],
        line: 115
      },
      '4': {
        loc: {
          start: {
            line: 120,
            column: 8
          },
          end: {
            line: 122,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 8
          },
          end: {
            line: 122,
            column: 9
          }
        }, {
          start: {
            line: 120,
            column: 8
          },
          end: {
            line: 122,
            column: 9
          }
        }],
        line: 120
      },
      '5': {
        loc: {
          start: {
            line: 127,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 127,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }, {
          start: {
            line: 127,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }],
        line: 127
      },
      '6': {
        loc: {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        }, {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        }],
        line: 146
      },
      '7': {
        loc: {
          start: {
            line: 151,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 151,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        }, {
          start: {
            line: 151,
            column: 6
          },
          end: {
            line: 153,
            column: 7
          }
        }],
        line: 151
      },
      '8': {
        loc: {
          start: {
            line: 157,
            column: 20
          },
          end: {
            line: 157,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 157,
            column: 43
          },
          end: {
            line: 157,
            column: 57
          }
        }, {
          start: {
            line: 157,
            column: 60
          },
          end: {
            line: 157,
            column: 63
          }
        }],
        line: 157
      },
      '9': {
        loc: {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 167,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 167,
            column: 7
          }
        }, {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 167,
            column: 7
          }
        }],
        line: 160
      },
      '10': {
        loc: {
          start: {
            line: 160,
            column: 10
          },
          end: {
            line: 160,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 160,
            column: 10
          },
          end: {
            line: 160,
            column: 25
          }
        }, {
          start: {
            line: 160,
            column: 29
          },
          end: {
            line: 160,
            column: 64
          }
        }],
        line: 160
      },
      '11': {
        loc: {
          start: {
            line: 165,
            column: 19
          },
          end: {
            line: 165,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 165,
            column: 19
          },
          end: {
            line: 165,
            column: 45
          }
        }, {
          start: {
            line: 165,
            column: 49
          },
          end: {
            line: 165,
            column: 50
          }
        }],
        line: 165
      },
      '12': {
        loc: {
          start: {
            line: 170,
            column: 23
          },
          end: {
            line: 170,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 170,
            column: 23
          },
          end: {
            line: 170,
            column: 27
          }
        }, {
          start: {
            line: 170,
            column: 31
          },
          end: {
            line: 170,
            column: 33
          }
        }],
        line: 170
      },
      '13': {
        loc: {
          start: {
            line: 173,
            column: 14
          },
          end: {
            line: 175,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 173,
            column: 14
          },
          end: {
            line: 175,
            column: 15
          }
        }, {
          start: {
            line: 173,
            column: 14
          },
          end: {
            line: 175,
            column: 15
          }
        }],
        line: 173
      },
      '14': {
        loc: {
          start: {
            line: 176,
            column: 14
          },
          end: {
            line: 178,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 176,
            column: 14
          },
          end: {
            line: 178,
            column: 15
          }
        }, {
          start: {
            line: 176,
            column: 14
          },
          end: {
            line: 178,
            column: 15
          }
        }],
        line: 176
      },
      '15': {
        loc: {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        }, {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        }],
        line: 179
      },
      '16': {
        loc: {
          start: {
            line: 189,
            column: 18
          },
          end: {
            line: 189,
            column: 83
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 189,
            column: 18
          },
          end: {
            line: 189,
            column: 49
          }
        }, {
          start: {
            line: 189,
            column: 53
          },
          end: {
            line: 189,
            column: 83
          }
        }],
        line: 189
      },
      '17': {
        loc: {
          start: {
            line: 224,
            column: 6
          },
          end: {
            line: 268,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 224,
            column: 6
          },
          end: {
            line: 268,
            column: 7
          }
        }, {
          start: {
            line: 224,
            column: 6
          },
          end: {
            line: 268,
            column: 7
          }
        }],
        line: 224
      },
      '18': {
        loc: {
          start: {
            line: 241,
            column: 20
          },
          end: {
            line: 241,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 241,
            column: 35
          },
          end: {
            line: 241,
            column: 41
          }
        }, {
          start: {
            line: 241,
            column: 44
          },
          end: {
            line: 241,
            column: 52
          }
        }],
        line: 241
      },
      '19': {
        loc: {
          start: {
            line: 242,
            column: 22
          },
          end: {
            line: 242,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 242,
            column: 22
          },
          end: {
            line: 242,
            column: 53
          }
        }, {
          start: {
            line: 242,
            column: 57
          },
          end: {
            line: 242,
            column: 87
          }
        }],
        line: 242
      },
      '20': {
        loc: {
          start: {
            line: 246,
            column: 12
          },
          end: {
            line: 250,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 246,
            column: 12
          },
          end: {
            line: 250,
            column: 13
          }
        }, {
          start: {
            line: 246,
            column: 12
          },
          end: {
            line: 250,
            column: 13
          }
        }],
        line: 246
      },
      '21': {
        loc: {
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 287,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 287,
            column: 7
          }
        }, {
          start: {
            line: 270,
            column: 6
          },
          end: {
            line: 287,
            column: 7
          }
        }],
        line: 270
      },
      '22': {
        loc: {
          start: {
            line: 299,
            column: 59
          },
          end: {
            line: 299,
            column: 99
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 299,
            column: 59
          },
          end: {
            line: 299,
            column: 93
          }
        }, {
          start: {
            line: 299,
            column: 97
          },
          end: {
            line: 299,
            column: 99
          }
        }],
        line: 299
      },
      '23': {
        loc: {
          start: {
            line: 301,
            column: 90
          },
          end: {
            line: 301,
            column: 101
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 301,
            column: 90
          },
          end: {
            line: 301,
            column: 95
          }
        }, {
          start: {
            line: 301,
            column: 99
          },
          end: {
            line: 301,
            column: 101
          }
        }],
        line: 301
      },
      '24': {
        loc: {
          start: {
            line: 302,
            column: 96
          },
          end: {
            line: 302,
            column: 107
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 302,
            column: 96
          },
          end: {
            line: 302,
            column: 101
          }
        }, {
          start: {
            line: 302,
            column: 105
          },
          end: {
            line: 302,
            column: 107
          }
        }],
        line: 302
      },
      '25': {
        loc: {
          start: {
            line: 303,
            column: 94
          },
          end: {
            line: 303,
            column: 105
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 303,
            column: 94
          },
          end: {
            line: 303,
            column: 99
          }
        }, {
          start: {
            line: 303,
            column: 103
          },
          end: {
            line: 303,
            column: 105
          }
        }],
        line: 303
      },
      '26': {
        loc: {
          start: {
            line: 304,
            column: 95
          },
          end: {
            line: 304,
            column: 106
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 304,
            column: 95
          },
          end: {
            line: 304,
            column: 100
          }
        }, {
          start: {
            line: 304,
            column: 104
          },
          end: {
            line: 304,
            column: 106
          }
        }],
        line: 304
      },
      '27': {
        loc: {
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 310,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 310,
            column: 9
          }
        }, {
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 310,
            column: 9
          }
        }],
        line: 308
      },
      '28': {
        loc: {
          start: {
            line: 316,
            column: 63
          },
          end: {
            line: 316,
            column: 122
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 316,
            column: 63
          },
          end: {
            line: 316,
            column: 116
          }
        }, {
          start: {
            line: 316,
            column: 120
          },
          end: {
            line: 316,
            column: 122
          }
        }],
        line: 316
      },
      '29': {
        loc: {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }, {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }],
        line: 320
      },
      '30': {
        loc: {
          start: {
            line: 326,
            column: 66
          },
          end: {
            line: 326,
            column: 128
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 326,
            column: 66
          },
          end: {
            line: 326,
            column: 122
          }
        }, {
          start: {
            line: 326,
            column: 126
          },
          end: {
            line: 326,
            column: 128
          }
        }],
        line: 326
      },
      '31': {
        loc: {
          start: {
            line: 330,
            column: 8
          },
          end: {
            line: 332,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 330,
            column: 8
          },
          end: {
            line: 332,
            column: 9
          }
        }, {
          start: {
            line: 330,
            column: 8
          },
          end: {
            line: 332,
            column: 9
          }
        }],
        line: 330
      },
      '32': {
        loc: {
          start: {
            line: 336,
            column: 65
          },
          end: {
            line: 336,
            column: 126
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 336,
            column: 65
          },
          end: {
            line: 336,
            column: 120
          }
        }, {
          start: {
            line: 336,
            column: 124
          },
          end: {
            line: 336,
            column: 126
          }
        }],
        line: 336
      },
      '33': {
        loc: {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        }, {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        }],
        line: 340
      },
      '34': {
        loc: {
          start: {
            line: 346,
            column: 66
          },
          end: {
            line: 346,
            column: 128
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 346,
            column: 66
          },
          end: {
            line: 346,
            column: 122
          }
        }, {
          start: {
            line: 346,
            column: 126
          },
          end: {
            line: 346,
            column: 128
          }
        }],
        line: 346
      },
      '35': {
        loc: {
          start: {
            line: 350,
            column: 8
          },
          end: {
            line: 363,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 350,
            column: 8
          },
          end: {
            line: 363,
            column: 9
          }
        }, {
          start: {
            line: 350,
            column: 8
          },
          end: {
            line: 363,
            column: 9
          }
        }],
        line: 350
      },
      '36': {
        loc: {
          start: {
            line: 352,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 352,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        }, {
          start: {
            line: 352,
            column: 10
          },
          end: {
            line: 359,
            column: 11
          }
        }],
        line: 352
      },
      '37': {
        loc: {
          start: {
            line: 354,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 354,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        }, {
          start: {
            line: 354,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        }],
        line: 354
      },
      '38': {
        loc: {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 362,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 362,
            column: 11
          }
        }, {
          start: {
            line: 360,
            column: 10
          },
          end: {
            line: 362,
            column: 11
          }
        }],
        line: 360
      },
      '39': {
        loc: {
          start: {
            line: 369,
            column: 73
          },
          end: {
            line: 369,
            column: 142
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 369,
            column: 73
          },
          end: {
            line: 369,
            column: 136
          }
        }, {
          start: {
            line: 369,
            column: 140
          },
          end: {
            line: 369,
            column: 142
          }
        }],
        line: 369
      },
      '40': {
        loc: {
          start: {
            line: 373,
            column: 8
          },
          end: {
            line: 375,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 373,
            column: 8
          },
          end: {
            line: 375,
            column: 9
          }
        }, {
          start: {
            line: 373,
            column: 8
          },
          end: {
            line: 375,
            column: 9
          }
        }],
        line: 373
      },
      '41': {
        loc: {
          start: {
            line: 374,
            column: 17
          },
          end: {
            line: 374,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 374,
            column: 17
          },
          end: {
            line: 374,
            column: 33
          }
        }, {
          start: {
            line: 374,
            column: 37
          },
          end: {
            line: 374,
            column: 53
          }
        }],
        line: 374
      },
      '42': {
        loc: {
          start: {
            line: 380,
            column: 8
          },
          end: {
            line: 382,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 380,
            column: 8
          },
          end: {
            line: 382,
            column: 9
          }
        }, {
          start: {
            line: 380,
            column: 8
          },
          end: {
            line: 382,
            column: 9
          }
        }],
        line: 380
      },
      '43': {
        loc: {
          start: {
            line: 380,
            column: 12
          },
          end: {
            line: 380,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 380,
            column: 12
          },
          end: {
            line: 380,
            column: 24
          }
        }, {
          start: {
            line: 380,
            column: 28
          },
          end: {
            line: 380,
            column: 40
          }
        }],
        line: 380
      },
      '44': {
        loc: {
          start: {
            line: 389,
            column: 8
          },
          end: {
            line: 396,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 389,
            column: 8
          },
          end: {
            line: 396,
            column: 9
          }
        }, {
          start: {
            line: 389,
            column: 8
          },
          end: {
            line: 396,
            column: 9
          }
        }],
        line: 389
      },
      '45': {
        loc: {
          start: {
            line: 391,
            column: 10
          },
          end: {
            line: 393,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 391,
            column: 10
          },
          end: {
            line: 393,
            column: 11
          }
        }, {
          start: {
            line: 391,
            column: 10
          },
          end: {
            line: 393,
            column: 11
          }
        }],
        line: 391
      },
      '46': {
        loc: {
          start: {
            line: 397,
            column: 8
          },
          end: {
            line: 403,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 397,
            column: 8
          },
          end: {
            line: 403,
            column: 9
          }
        }, {
          start: {
            line: 397,
            column: 8
          },
          end: {
            line: 403,
            column: 9
          }
        }],
        line: 397
      },
      '47': {
        loc: {
          start: {
            line: 422,
            column: 82
          },
          end: {
            line: 422,
            column: 117
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 422,
            column: 82
          },
          end: {
            line: 422,
            column: 111
          }
        }, {
          start: {
            line: 422,
            column: 115
          },
          end: {
            line: 422,
            column: 117
          }
        }],
        line: 422
      },
      '48': {
        loc: {
          start: {
            line: 441,
            column: 10
          },
          end: {
            line: 443,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 441,
            column: 10
          },
          end: {
            line: 443,
            column: 11
          }
        }, {
          start: {
            line: 441,
            column: 10
          },
          end: {
            line: 443,
            column: 11
          }
        }],
        line: 441
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2oslm54p1m.s[0]++;
var fnDashboardTablePivot = function fnDashboardTablePivot($scope, $deltadate, $translate, $deltaLocation, $LOGINService, $filter, $CRUDService, $deltahttp) {
  cov_2oslm54p1m.f[0]++;

  var PATH = (cov_2oslm54p1m.s[1]++, 'Formular');
  // Get Data
  var p_bksb_ = (cov_2oslm54p1m.s[2]++, null);
  cov_2oslm54p1m.s[3]++;
  $scope.syncfusionPivot = {
    params: {
      locale: $LOGINService.getLang(),
      dataSourceSettings: {
        enableSorting: true,
        columns: [],
        valueSortSettings: { headerDelimiter: ' - ' },
        values: [],
        allowLabelFilter: true,
        allowValueFilter: true,
        rows: [],
        formatSettings: [],
        expandAll: false,
        filters: [],
        alwaysShowValueHeader: true
      },
      width: $('#content_' + $scope.idcomponent).width() - 10 + 'px',
      allowExcelExport: true,
      allowConditionalFormatting: true,
      allowNumberFormatting: true,
      allowPdfExport: true,
      showToolbar: true,
      displayOption: { view: 'Both' },
      allowCalculatedField: true,
      showFieldList: true,
      toolbar: ['Grid', 'Chart'],
      allowDrillThrough: true,
      height: $('#content_' + $scope.idcomponent).height() - 70 + 'px',
      showTooltip: true,
      showValuesButton: true,
      // showGroupingBar: true,
      // gridSettings: {columnWidth: 140},
      chartSettings: {
        enableMultiAxis: true,
        chartSeries: { type: 'Column' },
        load: function load(args) {
          var selectedTheme = (cov_2oslm54p1m.s[4]++, location.hash.split('/')[1]);
          cov_2oslm54p1m.s[5]++;
          selectedTheme = selectedTheme ? (cov_2oslm54p1m.b[0][0]++, selectedTheme) : (cov_2oslm54p1m.b[0][1]++, 'Material');
          cov_2oslm54p1m.s[6]++;
          args.chart.theme = selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1);
        }
      },
      toolbarRender: function toolbarRender(toolbars) {
        var size = (cov_2oslm54p1m.s[7]++, toolbars.customToolbar.length);

        cov_2oslm54p1m.s[8]++;
        toolbars.customToolbar.splice(size - 1, 0, {
          prefixIcon: 'e-pivotview-pdf-export e-icons', tooltipText: 'PDF',
          click: function click(args) {
            cov_2oslm54p1m.s[9]++;

            $scope.syncfusionPivot.exportData('pdf');
          },

          align: 'Right'
        });

        cov_2oslm54p1m.s[10]++;
        toolbars.customToolbar.splice(size - 1, 0, {
          prefixIcon: 'e-pivotview-excel-export  e-icons', tooltipText: 'Excel',
          click: function click(args) {
            cov_2oslm54p1m.s[11]++;

            $scope.syncfusionPivot.exportData('excel');
          },

          align: 'Right'
        });
      },
      beforeExport: function beforeExport(params) {},
      pdfHeaderQueryCellInfo: function pdfHeaderQueryCellInfo(args) {
        cov_2oslm54p1m.s[12]++;

        args.cell.row.pdfGrid.repeatHeader = true;
      }
    },

    viewType: 'Table',
    exportData: function exportData(type) {
      var title_ = (cov_2oslm54p1m.s[13]++, ((cov_2oslm54p1m.b[1][0]++, $scope.selectedRequest.NAMEREQUEST) || (cov_2oslm54p1m.b[1][1]++, 'new report')).toUpperCase());
      var A4Size = (cov_2oslm54p1m.s[14]++, 1450);
      var pageSize = (cov_2oslm54p1m.s[15]++, 'A3');
      var date = (cov_2oslm54p1m.s[16]++, '');
      cov_2oslm54p1m.s[17]++;
      date += new Date().getMonth().toString() + '/' + new Date().getDate().toString();
      cov_2oslm54p1m.s[18]++;
      date += '/' + new Date().getFullYear().toString();
      cov_2oslm54p1m.s[19]++;
      if (type.toUpperCase() == 'PDF') {
        cov_2oslm54p1m.b[2][0]++;

        var pdfExportProperties = (cov_2oslm54p1m.s[20]++, {
          pageSize: 'A4',
          header: {
            fromTop: 0,
            height: 50,
            contents: [{
              type: 'Text',
              value: title_,
              position: { x: 0, y: 0 },
              style: { fontSize: 11, hAlign: 'Center', vAlign: 'Center' }
            }]
          },
          footer: {
            fromBottom: 0,
            height: 20,
            contents: [{
              type: 'PageNumber',
              pageNumberType: 'Arabic',
              format: '{$current} / {$total}',
              position: { x: 200, y: 0 },
              style: { textBrushColor: '#353535', fontSize: 9, hAlign: 'Center' }
            }]
          },
          pageOrientation: 'Landscape',
          fileName: title_ + '.pdf'
        });
        cov_2oslm54p1m.s[21]++;
        pdfExportProperties.footer.contents[0].position.x = A4Size - 50;
        cov_2oslm54p1m.s[22]++;
        pdfExportProperties.header.contents[0].position.x = (700 - 700 * title_.length / 104) / 2;
        cov_2oslm54p1m.s[23]++;
        if (pdfExportProperties.header.contents[0].position.x < 0) {
          cov_2oslm54p1m.b[3][0]++;
          cov_2oslm54p1m.s[24]++;

          pdfExportProperties.header.contents[0].position.x = 0;
        } else {
          cov_2oslm54p1m.b[3][1]++;
        }
        cov_2oslm54p1m.s[25]++;
        pdfExportProperties.pageSize = pageSize;
        cov_2oslm54p1m.s[26]++;
        $scope.pivotTableObj.pdfExport(pdfExportProperties);
        cov_2oslm54p1m.s[27]++;
        if ($scope.pivotTableObj.chartExport) {
          cov_2oslm54p1m.b[4][0]++;
          cov_2oslm54p1m.s[28]++;

          $scope.pivotTableObj.chartExport('PDF', title_ + 'Chart', pdfExportProperties);
        } else {
          cov_2oslm54p1m.b[4][1]++;
        }
      } else {
        cov_2oslm54p1m.b[2][1]++;
        cov_2oslm54p1m.s[29]++;

        $scope.pivotTableObj.excelExport({
          fileName: $scope.selectedRequest.NAMEREQUEST + '.xlsx'
        });
        cov_2oslm54p1m.s[30]++;
        if ($scope.pivotTableObj.chartExport) {
          cov_2oslm54p1m.b[5][0]++;
          cov_2oslm54p1m.s[31]++;

          $scope.pivotTableObj.chart.title = title_;
          cov_2oslm54p1m.s[32]++;
          $scope.pivotTableObj.chartExport('JPEG', title_ + 'Chart');
        } else {
          cov_2oslm54p1m.b[5][1]++;
        }
      }
    }
  };

  cov_2oslm54p1m.s[33]++;
  $scope.requestFields = [];

  cov_2oslm54p1m.s[34]++;
  $scope.getFilterFields = function (id) {
    cov_2oslm54p1m.f[1]++;
    cov_2oslm54p1m.s[35]++;

    $CRUDService.getAll(PATH, { get: 'all_filter_fields', id: id, p_bksb_: p_bksb_ }, function (data) {
      cov_2oslm54p1m.f[2]++;
      cov_2oslm54p1m.s[36]++;

      $scope.requestFields = data;
    });
  };
  cov_2oslm54p1m.s[37]++;
  $scope.toggleChart = function () {
    cov_2oslm54p1m.f[3]++;
    cov_2oslm54p1m.s[38]++;

    $scope.chartView = !$scope.chartView;
  };
  cov_2oslm54p1m.s[39]++;
  $scope.drawChart = function (id) {
    cov_2oslm54p1m.f[4]++;
    cov_2oslm54p1m.s[40]++;

    if ($scope.chart) {
      cov_2oslm54p1m.b[6][0]++;
      cov_2oslm54p1m.s[41]++;

      return;
    } else {
      cov_2oslm54p1m.b[6][1]++;
    }
    var params = (cov_2oslm54p1m.s[42]++, { withData: 1, export: 1, page: 0, id: id, id_request: $scope.idForm, paging: 1, p_bksb_: p_bksb_, get: 'all_r_graph', sort: $filter('json')([]) });
    cov_2oslm54p1m.s[43]++;
    $CRUDService.getAll(PATH, params, function (data) {
      cov_2oslm54p1m.f[5]++;
      cov_2oslm54p1m.s[44]++;

      if (!angular.isArray(data)) {
        cov_2oslm54p1m.b[7][0]++;
        cov_2oslm54p1m.s[45]++;

        return;
      } else {
        cov_2oslm54p1m.b[7][1]++;
      }
      var categorie = (cov_2oslm54p1m.s[46]++, $scope.requestFields.filter(function (value) {
        cov_2oslm54p1m.f[6]++;
        cov_2oslm54p1m.s[47]++;

        return value.id == $scope.selectedRequest.CHART.C;
      }));
      var idCat = (cov_2oslm54p1m.s[48]++, categorie.length > 0 ? (cov_2oslm54p1m.b[8][0]++, categorie[0].l) : (cov_2oslm54p1m.b[8][1]++, '_'));
      var Total = (cov_2oslm54p1m.s[49]++, 1);
      var id = (cov_2oslm54p1m.s[50]++, '_');
      cov_2oslm54p1m.s[51]++;
      if ((cov_2oslm54p1m.b[10][0]++, data.length > 0) && (cov_2oslm54p1m.b[10][1]++, $scope.selectedRequest.CHART.T != 1)) {
        cov_2oslm54p1m.b[9][0]++;
        cov_2oslm54p1m.s[52]++;

        Total = 0;
        var serie = (cov_2oslm54p1m.s[53]++, $scope.selectedRequest.CHART.SERIES[0]);
        cov_2oslm54p1m.s[54]++;
        id = serie.id;
        cov_2oslm54p1m.s[55]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;
            cov_2oslm54p1m.s[56]++;

            Total += (cov_2oslm54p1m.b[11][0]++, parseFloat(item[serie.id])) || (cov_2oslm54p1m.b[11][1]++, 0);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      } else {
        cov_2oslm54p1m.b[9][1]++;
      }
      var params = (cov_2oslm54p1m.s[57]++, {
        series: [$scope.selectedRequest.CHART.SERIES[0]].map(function (value) {
          cov_2oslm54p1m.f[7]++;
          cov_2oslm54p1m.s[58]++;
          return {
            dataSource: ((cov_2oslm54p1m.b[12][0]++, data) || (cov_2oslm54p1m.b[12][1]++, [])).filter(function (d) {
              cov_2oslm54p1m.f[8]++;

              var val = (cov_2oslm54p1m.s[59]++, parseFloat(d[value.id]));
              cov_2oslm54p1m.s[60]++;
              if (d[value.id] === null) {
                cov_2oslm54p1m.b[13][0]++;
                cov_2oslm54p1m.s[61]++;

                return false;
              } else {
                cov_2oslm54p1m.b[13][1]++;
              }
              cov_2oslm54p1m.s[62]++;
              if (Number.isNaN(val)) {
                cov_2oslm54p1m.b[14][0]++;
                cov_2oslm54p1m.s[63]++;

                return false;
              } else {
                cov_2oslm54p1m.b[14][1]++;
              }
              cov_2oslm54p1m.s[64]++;
              if (angular.isNumber(val)) {
                cov_2oslm54p1m.b[15][0]++;
                cov_2oslm54p1m.s[65]++;

                return true;
              } else {
                cov_2oslm54p1m.b[15][1]++;
              }
              cov_2oslm54p1m.s[66]++;
              return false;
            }).map(function (d) {
              cov_2oslm54p1m.f[9]++;
              cov_2oslm54p1m.s[67]++;

              d[value.id] = parseFloat(d[value.id]);
              cov_2oslm54p1m.s[68]++;
              d.radius = $filter('number')(parseInt(10000 * d[value.id] / Total, 10) / 100, 2) + '%';
              cov_2oslm54p1m.s[69]++;
              return d;
            }),
            xName: (cov_2oslm54p1m.b[16][0]++, $scope.selectedRequest.CHART.SB) || (cov_2oslm54p1m.b[16][1]++, $scope.selectedRequest.CHART.C),
            yName: value.id,
            // radius: 'radius',
            // innerRadius: '20%',
            dataLabel: {
              visible: true,
              position: 'Outside',
              name: 'radius',
              font: {
                fontWeight: '600'
              }
            },
            radius: '70%',
            startAngle: 0,
            endAngle: 0,
            innerRadius: '0%',
            explode: true,
            explodeOffset: '10%',
            explodeIndex: 0
          };
        }),
        width: $('#content_' + $scope.idcomponent).width() - 10 + 'px',
        height: $('#content_' + $scope.idcomponent).height() - 10 + 'px',
        center: { x: '50%', y: '50%' },
        enableSmartLabels: true,
        enableAnimation: true,
        legendSettings: {
          visible: true
        },
        // Initializing Tooltip
        // eslint-disable-next-line no-template-curly-in-string
        tooltip: { enable: true, /* header: 'Browser', */format: '${point.x}:<b> ${point.y}<b>'
          // Initializing Title
          // title: 'Mobile Browser Statistics',
        } });

      cov_2oslm54p1m.s[70]++;
      if ($scope.selectedRequest.CHART.T == 1) {
        cov_2oslm54p1m.b[17][0]++;
        cov_2oslm54p1m.s[71]++;

        params = {
          primaryXAxis: {
            title: idCat,
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            majorGridLines: { width: 0 }
          },
          primaryYAxis: {
            rangePadding: 'None',
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            valueType: 'Double'
          },
          series: $scope.selectedRequest.CHART.SERIES.map(function (value) {
            cov_2oslm54p1m.f[10]++;

            var serie = (cov_2oslm54p1m.s[72]++, {
              type: value.T == 1 ? (cov_2oslm54p1m.b[18][0]++, 'Line') : (cov_2oslm54p1m.b[18][1]++, 'Column'), dataSource: data,
              xName: (cov_2oslm54p1m.b[19][0]++, $scope.selectedRequest.CHART.SB) || (cov_2oslm54p1m.b[19][1]++, $scope.selectedRequest.CHART.C), yName: value.id, name: value.L,
              width: 2, opacity: 0.6
            });

            cov_2oslm54p1m.s[73]++;
            if (value.T == 1) {
              cov_2oslm54p1m.b[20][0]++;
              cov_2oslm54p1m.s[74]++;

              serie.marker = {
                visible: true, width: 5, height: 5
              };
            } else {
              cov_2oslm54p1m.b[20][1]++;
            }
            cov_2oslm54p1m.s[75]++;
            return serie;
          }),
          zoomSettings: {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
          },
          tooltip: {
            enable: true
          },
          palettes: $scope.selectedRequest.CHART.SERIES.map(function (value) {
            cov_2oslm54p1m.f[11]++;
            cov_2oslm54p1m.s[76]++;

            return 'rgb(' + value.C + ')';
          }),
          width: $('#content_' + $scope.idcomponent).width() - 10 + 'px',
          height: $('#content_' + $scope.idcomponent).height() - 10 + 'px'
        };
      } else {
        cov_2oslm54p1m.b[17][1]++;
      }

      cov_2oslm54p1m.s[77]++;
      if ($scope.selectedRequest.CHART.T == 1) {
        cov_2oslm54p1m.b[21][0]++;
        cov_2oslm54p1m.s[78]++;

        $scope.chart = new ej.charts.Chart(params, '#chart_' + $scope.idcomponent);
      } else {
        cov_2oslm54p1m.b[21][1]++;
        cov_2oslm54p1m.s[79]++;

        params.palette = ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712'];
        cov_2oslm54p1m.s[80]++;
        $scope.chart = new ej.charts.AccumulationChart(params, '#chart_' + $scope.idcomponent);
        /* $scope.chartDesignPie.refreshChart();
        $scope.chartDesignPie.title = $scope.selectedRequest.NAMEREQUEST; */
      }
    });
  };

  cov_2oslm54p1m.s[81]++;
  $scope.getAll = function (id) {
    cov_2oslm54p1m.f[12]++;
    cov_2oslm54p1m.s[82]++;

    $scope.getFilterFields(id);

    cov_2oslm54p1m.s[83]++;
    $scope.listRecord = [];
    var params = (cov_2oslm54p1m.s[84]++, { export: 1, page: 0, id: id, id_request: $scope.idForm, paging: 1, p_bksb_: p_bksb_, get: 'all_r_simple_table', sort: $filter('json')([]) });
    // const params = {get: 'all_r_simple_table', id: $scope.selectedTemplate.id, sort: $filter('json')($scope.sort || [])};
    cov_2oslm54p1m.s[85]++;
    $CRUDService.getAll(PATH, params, function (data) {
      cov_2oslm54p1m.f[13]++;
      cov_2oslm54p1m.s[86]++;

      ej.base.enableRipple(false);
      cov_2oslm54p1m.s[87]++;
      $scope.syncfusionPivot.params.chartSettings.title = ((cov_2oslm54p1m.b[22][0]++, $scope.selectedRequest.NAMEREQUEST) || (cov_2oslm54p1m.b[22][1]++, '')).toUpperCase();
      // Initialize Row
      var rowsIds = (cov_2oslm54p1m.s[88]++, $scope.syncfusionPivot.params.dataSourceSettings.rows.map(function (value) {
        cov_2oslm54p1m.f[14]++;
        cov_2oslm54p1m.s[89]++;
        return ((cov_2oslm54p1m.b[23][0]++, value) || (cov_2oslm54p1m.b[23][1]++, {})).name;
      }));
      var columnsIds = (cov_2oslm54p1m.s[90]++, $scope.syncfusionPivot.params.dataSourceSettings.columns.map(function (value) {
        cov_2oslm54p1m.f[15]++;
        cov_2oslm54p1m.s[91]++;
        return ((cov_2oslm54p1m.b[24][0]++, value) || (cov_2oslm54p1m.b[24][1]++, {})).name;
      }));
      var valuesIds = (cov_2oslm54p1m.s[92]++, $scope.syncfusionPivot.params.dataSourceSettings.values.map(function (value) {
        cov_2oslm54p1m.f[16]++;
        cov_2oslm54p1m.s[93]++;
        return ((cov_2oslm54p1m.b[25][0]++, value) || (cov_2oslm54p1m.b[25][1]++, {})).name;
      }));
      var filterIds = (cov_2oslm54p1m.s[94]++, $scope.syncfusionPivot.params.dataSourceSettings.filters.map(function (value) {
        cov_2oslm54p1m.f[17]++;
        cov_2oslm54p1m.s[95]++;
        return ((cov_2oslm54p1m.b[26][0]++, value) || (cov_2oslm54p1m.b[26][1]++, {})).name;
      }));
      var ids = (cov_2oslm54p1m.s[96]++, [].concat(rowsIds).concat(columnsIds).concat(valuesIds).concat(filterIds));

      var fields = (cov_2oslm54p1m.s[97]++, data.rows.filter(function (value) {
        cov_2oslm54p1m.f[18]++;
        cov_2oslm54p1m.s[98]++;

        if (ids.indexOf(value.name) >= 0) {
          cov_2oslm54p1m.b[27][0]++;
          cov_2oslm54p1m.s[99]++;

          return false;
        } else {
          cov_2oslm54p1m.b[27][1]++;
        }
        cov_2oslm54p1m.s[100]++;
        return true;
      }));
      cov_2oslm54p1m.s[101]++;
      $scope.syncfusionPivot.params.dataSourceSettings.filters = fields.concat($scope.syncfusionPivot.params.dataSourceSettings.filters);

      // Update Rows
      cov_2oslm54p1m.s[102]++;
      $scope.syncfusionPivot.params.dataSourceSettings.rows = ((cov_2oslm54p1m.b[28][0]++, $scope.syncfusionPivot.params.dataSourceSettings.rows) || (cov_2oslm54p1m.b[28][1]++, [])).map(function (header) {
        cov_2oslm54p1m.f[19]++;

        var result = (cov_2oslm54p1m.s[103]++, data.rows.filter(function (value) {
          cov_2oslm54p1m.f[20]++;
          cov_2oslm54p1m.s[104]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[105]++;
        if (result.length > 0) {
          cov_2oslm54p1m.b[29][0]++;
          cov_2oslm54p1m.s[106]++;

          header.caption = result[0].caption;
        } else {
          cov_2oslm54p1m.b[29][1]++;
        }
        cov_2oslm54p1m.s[107]++;
        return header;
      });
      // Update Column
      cov_2oslm54p1m.s[108]++;
      $scope.syncfusionPivot.params.dataSourceSettings.columns = ((cov_2oslm54p1m.b[30][0]++, $scope.syncfusionPivot.params.dataSourceSettings.columns) || (cov_2oslm54p1m.b[30][1]++, [])).map(function (header) {
        cov_2oslm54p1m.f[21]++;

        var result = (cov_2oslm54p1m.s[109]++, data.rows.filter(function (value) {
          cov_2oslm54p1m.f[22]++;
          cov_2oslm54p1m.s[110]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[111]++;
        if (result.length > 0) {
          cov_2oslm54p1m.b[31][0]++;
          cov_2oslm54p1m.s[112]++;

          header.caption = result[0].caption;
        } else {
          cov_2oslm54p1m.b[31][1]++;
        }
        cov_2oslm54p1m.s[113]++;
        return header;
      });
      // Update Values
      cov_2oslm54p1m.s[114]++;
      $scope.syncfusionPivot.params.dataSourceSettings.values = ((cov_2oslm54p1m.b[32][0]++, $scope.syncfusionPivot.params.dataSourceSettings.values) || (cov_2oslm54p1m.b[32][1]++, [])).map(function (header) {
        cov_2oslm54p1m.f[23]++;

        var result = (cov_2oslm54p1m.s[115]++, data.rows.filter(function (value) {
          cov_2oslm54p1m.f[24]++;
          cov_2oslm54p1m.s[116]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[117]++;
        if (result.length > 0) {
          cov_2oslm54p1m.b[33][0]++;
          cov_2oslm54p1m.s[118]++;

          header.caption = result[0].caption;
        } else {
          cov_2oslm54p1m.b[33][1]++;
        }
        cov_2oslm54p1m.s[119]++;
        return header;
      });
      // Update Filter
      cov_2oslm54p1m.s[120]++;
      $scope.syncfusionPivot.params.dataSourceSettings.filters = ((cov_2oslm54p1m.b[34][0]++, $scope.syncfusionPivot.params.dataSourceSettings.filters) || (cov_2oslm54p1m.b[34][1]++, [])).map(function (header) {
        cov_2oslm54p1m.f[25]++;

        var result = (cov_2oslm54p1m.s[121]++, data.rows.filter(function (value) {
          cov_2oslm54p1m.f[26]++;
          cov_2oslm54p1m.s[122]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[123]++;
        if (result.length > 0) {
          cov_2oslm54p1m.b[35][0]++;
          cov_2oslm54p1m.s[124]++;

          header.caption = result[0].caption;
          cov_2oslm54p1m.s[125]++;
          if (header.t == 3) {
            cov_2oslm54p1m.b[36][0]++;

            var format = (cov_2oslm54p1m.s[126]++, header.f.replace('Date', '').toLowerCase());
            cov_2oslm54p1m.s[127]++;
            if (['short', 'medium', 'long', 'full'].indexOf(format) < 0) {
              cov_2oslm54p1m.b[37][0]++;
              cov_2oslm54p1m.s[128]++;

              format = 'medium';
            } else {
              cov_2oslm54p1m.b[37][1]++;
            }
            cov_2oslm54p1m.s[129]++;
            header.type = 'date';
            cov_2oslm54p1m.s[130]++;
            header.format = format;
          } else {
            cov_2oslm54p1m.b[36][1]++;
          }
          cov_2oslm54p1m.s[131]++;
          if (header.t == 4) {
            cov_2oslm54p1m.b[38][0]++;
            cov_2oslm54p1m.s[132]++;

            header.format = 'N' + header.f;
          } else {
            cov_2oslm54p1m.b[38][1]++;
          }
        } else {
          cov_2oslm54p1m.b[35][1]++;
        }
        cov_2oslm54p1m.s[133]++;
        return header;
      });

      // Format
      // Remove Not exist or type in (3, 4)
      cov_2oslm54p1m.s[134]++;
      $scope.syncfusionPivot.params.dataSourceSettings.formatSettings = ((cov_2oslm54p1m.b[39][0]++, $scope.syncfusionPivot.params.dataSourceSettings.formatSettings) || (cov_2oslm54p1m.b[39][1]++, [])).filter(function (header) {
        cov_2oslm54p1m.f[27]++;

        var result = (cov_2oslm54p1m.s[135]++, data.rows.filter(function (value) {
          cov_2oslm54p1m.f[28]++;
          cov_2oslm54p1m.s[136]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[137]++;
        if (result.length > 0) {
          cov_2oslm54p1m.b[40][0]++;
          cov_2oslm54p1m.s[138]++;

          return (cov_2oslm54p1m.b[41][0]++, result[0].t == 3) || (cov_2oslm54p1m.b[41][1]++, result[0].t == 4);
        } else {
          cov_2oslm54p1m.b[40][1]++;
        }
        cov_2oslm54p1m.s[139]++;
        return false;
      });
      // Select new number and Date
      cov_2oslm54p1m.s[140]++;
      $scope.syncfusionPivot.params.dataSourceSettings.formatSettings = data.rows.filter(function (value) {
        cov_2oslm54p1m.f[29]++;
        cov_2oslm54p1m.s[141]++;

        if ((cov_2oslm54p1m.b[43][0]++, value.t != 3) && (cov_2oslm54p1m.b[43][1]++, value.t != 4)) {
          cov_2oslm54p1m.b[42][0]++;
          cov_2oslm54p1m.s[142]++;

          return false;
        } else {
          cov_2oslm54p1m.b[42][1]++;
        }
        var results = (cov_2oslm54p1m.s[143]++, $scope.syncfusionPivot.params.dataSourceSettings.formatSettings.filter(function (header) {
          cov_2oslm54p1m.f[30]++;
          cov_2oslm54p1m.s[144]++;

          return value.name == header.name;
        }));
        cov_2oslm54p1m.s[145]++;
        return results.length == 0;
      }).map(function (header) {
        cov_2oslm54p1m.f[31]++;

        var field = (cov_2oslm54p1m.s[146]++, { name: header.name });
        cov_2oslm54p1m.s[147]++;
        if (header.t == 3) {
          cov_2oslm54p1m.b[44][0]++;

          var format = (cov_2oslm54p1m.s[148]++, header.f.replace('Date', '').toLowerCase());
          cov_2oslm54p1m.s[149]++;
          if (['short', 'medium', 'long', 'full'].indexOf(format) < 0) {
            cov_2oslm54p1m.b[45][0]++;
            cov_2oslm54p1m.s[150]++;

            format = 'medium';
          } else {
            cov_2oslm54p1m.b[45][1]++;
          }
          cov_2oslm54p1m.s[151]++;
          field.type = 'date';
          cov_2oslm54p1m.s[152]++;
          field.format = format;
        } else {
          cov_2oslm54p1m.b[44][1]++;
        }
        cov_2oslm54p1m.s[153]++;
        if (header.t == 4) {
          cov_2oslm54p1m.b[46][0]++;
          cov_2oslm54p1m.s[154]++;

          field.format = 'N' + header.f;
          // field.textAlign = 'Right';
          // field.useGrouping= false;
          cov_2oslm54p1m.s[155]++;
          field.minimumSignificantDigits = 1;
          cov_2oslm54p1m.s[156]++;
          field.maximumSignificantDigits = 3;
        } else {
          cov_2oslm54p1m.b[46][1]++;
        }
        cov_2oslm54p1m.s[157]++;
        return field;
      }).concat($scope.syncfusionPivot.params.dataSourceSettings.formatSettings);
      cov_2oslm54p1m.s[158]++;
      $scope.syncfusionPivot.params.dataSourceSettings.dataSource = new ej.data.DataManager({
        url: $deltahttp.getReporting(data.link, new Date().getTime()),
        adaptor: new ej.data.WebApiAdaptor(),
        crossDomain: true
      });

      cov_2oslm54p1m.s[159]++;
      $scope.syncfusionPivot.params.height = $('#content_' + $scope.idcomponent).height() - 10 + 'px';
      cov_2oslm54p1m.s[160]++;
      $scope.syncfusionPivot.params.width = $('#content_' + $scope.idcomponent).width() - 10 + 'px';
      cov_2oslm54p1m.s[161]++;
      $scope.pivotTableObj = new ej.pivotview.PivotView($scope.syncfusionPivot.params);
      cov_2oslm54p1m.s[162]++;
      $scope.pivotTableObj.appendTo('#pivotTable_' + $scope.idcomponent);
    });
  };
  cov_2oslm54p1m.s[163]++;
  $scope.listRecord = [];
  cov_2oslm54p1m.s[164]++;
  $CRUDService.getAll(PATH, { get: 'one_request_by_id', id: $scope.idForm }, function (data) {
    cov_2oslm54p1m.f[32]++;
    cov_2oslm54p1m.s[165]++;

    $scope.selectedRequest = data;
    cov_2oslm54p1m.s[166]++;
    p_bksb_ = $scope.selectedRequest.ID_PROJET;
    cov_2oslm54p1m.s[167]++;
    $scope.syncfusionPivot.params = Object.assign($scope.syncfusionPivot.params, (cov_2oslm54p1m.b[47][0]++, $scope.selectedRequest.TABLE_) || (cov_2oslm54p1m.b[47][1]++, {}));
    cov_2oslm54p1m.s[168]++;
    $scope.getAll($scope.selectedRequest.ID);
    cov_2oslm54p1m.s[169]++;
    $scope.drawChart($scope.selectedRequest.ID);
    cov_2oslm54p1m.s[170]++;
    $scope.endInit = true;
  });
};
cov_2oslm54p1m.s[171]++;
angular.module('app').directive('dashboardTablePivot', function () {
  cov_2oslm54p1m.f[33]++;
  cov_2oslm54p1m.s[172]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      idForm: '=',
      idRequest: '='
    },
    templateUrl: 'app/views/common/dashboard-table-pivot.html',
    link: function link(scope, element) {
      cov_2oslm54p1m.s[173]++;

      scope.$on('$destroy', function () {
        cov_2oslm54p1m.f[34]++;
        cov_2oslm54p1m.s[174]++;

        if (scope.pivotTableObj) {
          cov_2oslm54p1m.b[48][0]++;
          cov_2oslm54p1m.s[175]++;

          scope.pivotTableObj.destroy();
        } else {
          cov_2oslm54p1m.b[48][1]++;
        }
      });
    },

    controller: fnDashboardTablePivot
  };
});
'use strict';

var cov_12ec53qceg = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities_portfolio/dashboard/dashboardCtrl.js',
      hash = 'ec99a3e273b70c6f1686a701db3af4856853d4a6',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities_portfolio/dashboard/dashboardCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 1111,
          column: 3
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 9,
          column: 3
        }
      },
      '2': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 29
        }
      },
      '3': {
        start: {
          line: 10,
          column: 16
        },
        end: {
          line: 10,
          column: 31
        }
      },
      '4': {
        start: {
          line: 11,
          column: 16
        },
        end: {
          line: 11,
          column: 31
        }
      },
      '5': {
        start: {
          line: 12,
          column: 18
        },
        end: {
          line: 12,
          column: 27
        }
      },
      '6': {
        start: {
          line: 13,
          column: 15
        },
        end: {
          line: 13,
          column: 30
        }
      },
      '7': {
        start: {
          line: 14,
          column: 17
        },
        end: {
          line: 14,
          column: 32
        }
      },
      '8': {
        start: {
          line: 15,
          column: 24
        },
        end: {
          line: 15,
          column: 35
        }
      },
      '9': {
        start: {
          line: 16,
          column: 26
        },
        end: {
          line: 16,
          column: 37
        }
      },
      '10': {
        start: {
          line: 17,
          column: 24
        },
        end: {
          line: 17,
          column: 35
        }
      },
      '11': {
        start: {
          line: 18,
          column: 24
        },
        end: {
          line: 18,
          column: 35
        }
      },
      '12': {
        start: {
          line: 19,
          column: 23
        },
        end: {
          line: 19,
          column: 36
        }
      },
      '13': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 20,
          column: 70
        }
      },
      '14': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 21,
          column: 27
        }
      },
      '15': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 22,
          column: 27
        }
      },
      '16': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 25,
          column: 4
        }
      },
      '17': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 24,
          column: 103
        }
      },
      '18': {
        start: {
          line: 26,
          column: 2
        },
        end: {
          line: 26,
          column: 41
        }
      },
      '19': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 35
        }
      },
      '20': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 30,
          column: 4
        }
      },
      '21': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 29,
          column: 108
        }
      },
      '22': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 35,
          column: 4
        }
      },
      '23': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 33,
          column: 33
        }
      },
      '24': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 34,
          column: 90
        }
      },
      '25': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 37,
          column: 26
        }
      },
      '26': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '27': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 39,
          column: 45
        }
      },
      '28': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '29': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 31
        }
      },
      '30': {
        start: {
          line: 45,
          column: 2
        },
        end: {
          line: 56,
          column: 4
        }
      },
      '31': {
        start: {
          line: 57,
          column: 2
        },
        end: {
          line: 57,
          column: 32
        }
      },
      '32': {
        start: {
          line: 58,
          column: 2
        },
        end: {
          line: 68,
          column: 4
        }
      },
      '33': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 28
        }
      },
      '34': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '35': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 30
        }
      },
      '36': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 60
        }
      },
      '37': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 74,
          column: 4
        }
      },
      '38': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 97,
          column: 4
        }
      },
      '39': {
        start: {
          line: 85,
          column: 18
        },
        end: {
          line: 85,
          column: 20
        }
      },
      '40': {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '41': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 32
        }
      },
      '42': {
        start: {
          line: 89,
          column: 16
        },
        end: {
          line: 89,
          column: 17
        }
      },
      '43': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '44': {
        start: {
          line: 91,
          column: 8
        },
        end: {
          line: 93,
          column: 9
        }
      },
      '45': {
        start: {
          line: 92,
          column: 10
        },
        end: {
          line: 92,
          column: 16
        }
      },
      '46': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 17
        }
      },
      '47': {
        start: {
          line: 98,
          column: 2
        },
        end: {
          line: 131,
          column: 4
        }
      },
      '48': {
        start: {
          line: 110,
          column: 4
        },
        end: {
          line: 120,
          column: 6
        }
      },
      '49': {
        start: {
          line: 132,
          column: 2
        },
        end: {
          line: 156,
          column: 4
        }
      },
      '50': {
        start: {
          line: 133,
          column: 4
        },
        end: {
          line: 133,
          column: 37
        }
      },
      '51': {
        start: {
          line: 134,
          column: 4
        },
        end: {
          line: 134,
          column: 41
        }
      },
      '52': {
        start: {
          line: 135,
          column: 4
        },
        end: {
          line: 138,
          column: 5
        }
      },
      '53': {
        start: {
          line: 136,
          column: 6
        },
        end: {
          line: 136,
          column: 71
        }
      },
      '54': {
        start: {
          line: 137,
          column: 6
        },
        end: {
          line: 137,
          column: 13
        }
      },
      '55': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 139,
          column: 99
        }
      },
      '56': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 148,
          column: 5
        }
      },
      '57': {
        start: {
          line: 141,
          column: 19
        },
        end: {
          line: 141,
          column: 54
        }
      },
      '58': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 144,
          column: 7
        }
      },
      '59': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 17
        }
      },
      '60': {
        start: {
          line: 145,
          column: 6
        },
        end: {
          line: 145,
          column: 45
        }
      },
      '61': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 93
        }
      },
      '62': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 147,
          column: 91
        }
      },
      '63': {
        start: {
          line: 149,
          column: 4
        },
        end: {
          line: 149,
          column: 216
        }
      },
      '64': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 151,
          column: 95
        }
      },
      '65': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 155,
          column: 5
        }
      },
      '66': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 37
        }
      },
      '67': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 48
        }
      },
      '68': {
        start: {
          line: 157,
          column: 2
        },
        end: {
          line: 235,
          column: 4
        }
      },
      '69': {
        start: {
          line: 158,
          column: 4
        },
        end: {
          line: 234,
          column: 5
        }
      },
      '70': {
        start: {
          line: 159,
          column: 6
        },
        end: {
          line: 159,
          column: 83
        }
      },
      '71': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 114
        }
      },
      '72': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 161,
          column: 64
        }
      },
      '73': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 36
        }
      },
      '74': {
        start: {
          line: 163,
          column: 6
        },
        end: {
          line: 163,
          column: 32
        }
      },
      '75': {
        start: {
          line: 164,
          column: 6
        },
        end: {
          line: 208,
          column: 7
        }
      },
      '76': {
        start: {
          line: 165,
          column: 8
        },
        end: {
          line: 165,
          column: 170
        }
      },
      '77': {
        start: {
          line: 167,
          column: 8
        },
        end: {
          line: 185,
          column: 9
        }
      },
      '78': {
        start: {
          line: 168,
          column: 10
        },
        end: {
          line: 168,
          column: 38
        }
      },
      '79': {
        start: {
          line: 169,
          column: 10
        },
        end: {
          line: 184,
          column: 11
        }
      },
      '80': {
        start: {
          line: 170,
          column: 12
        },
        end: {
          line: 175,
          column: 13
        }
      },
      '81': {
        start: {
          line: 171,
          column: 14
        },
        end: {
          line: 174,
          column: 15
        }
      },
      '82': {
        start: {
          line: 172,
          column: 16
        },
        end: {
          line: 172,
          column: 42
        }
      },
      '83': {
        start: {
          line: 173,
          column: 16
        },
        end: {
          line: 173,
          column: 22
        }
      },
      '84': {
        start: {
          line: 177,
          column: 23
        },
        end: {
          line: 177,
          column: 94
        }
      },
      '85': {
        start: {
          line: 178,
          column: 12
        },
        end: {
          line: 183,
          column: 13
        }
      },
      '86': {
        start: {
          line: 179,
          column: 14
        },
        end: {
          line: 182,
          column: 15
        }
      },
      '87': {
        start: {
          line: 180,
          column: 16
        },
        end: {
          line: 180,
          column: 42
        }
      },
      '88': {
        start: {
          line: 181,
          column: 16
        },
        end: {
          line: 181,
          column: 22
        }
      },
      '89': {
        start: {
          line: 187,
          column: 18
        },
        end: {
          line: 187,
          column: 19
        }
      },
      '90': {
        start: {
          line: 188,
          column: 8
        },
        end: {
          line: 190,
          column: 9
        }
      },
      '91': {
        start: {
          line: 189,
          column: 10
        },
        end: {
          line: 189,
          column: 43
        }
      },
      '92': {
        start: {
          line: 191,
          column: 8
        },
        end: {
          line: 191,
          column: 36
        }
      },
      '93': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 197,
          column: 9
        }
      },
      '94': {
        start: {
          line: 193,
          column: 10
        },
        end: {
          line: 196,
          column: 11
        }
      },
      '95': {
        start: {
          line: 194,
          column: 12
        },
        end: {
          line: 194,
          column: 38
        }
      },
      '96': {
        start: {
          line: 195,
          column: 12
        },
        end: {
          line: 195,
          column: 18
        }
      },
      '97': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 201,
          column: 9
        }
      },
      '98': {
        start: {
          line: 200,
          column: 10
        },
        end: {
          line: 200,
          column: 57
        }
      },
      '99': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 207,
          column: 9
        }
      },
      '100': {
        start: {
          line: 204,
          column: 29
        },
        end: {
          line: 204,
          column: 194
        }
      },
      '101': {
        start: {
          line: 205,
          column: 10
        },
        end: {
          line: 205,
          column: 174
        }
      },
      '102': {
        start: {
          line: 206,
          column: 10
        },
        end: {
          line: 206,
          column: 172
        }
      },
      '103': {
        start: {
          line: 209,
          column: 6
        },
        end: {
          line: 233,
          column: 7
        }
      },
      '104': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 32
        }
      },
      '105': {
        start: {
          line: 211,
          column: 8
        },
        end: {
          line: 215,
          column: 60
        }
      },
      '106': {
        start: {
          line: 217,
          column: 22
        },
        end: {
          line: 217,
          column: 154
        }
      },
      '107': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 222,
          column: 9
        }
      },
      '108': {
        start: {
          line: 219,
          column: 10
        },
        end: {
          line: 221,
          column: 11
        }
      },
      '109': {
        start: {
          line: 220,
          column: 12
        },
        end: {
          line: 220,
          column: 53
        }
      },
      '110': {
        start: {
          line: 224,
          column: 8
        },
        end: {
          line: 232,
          column: 11
        }
      },
      '111': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 225,
          column: 35
        }
      },
      '112': {
        start: {
          line: 226,
          column: 10
        },
        end: {
          line: 226,
          column: 32
        }
      },
      '113': {
        start: {
          line: 227,
          column: 10
        },
        end: {
          line: 227,
          column: 36
        }
      },
      '114': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 229,
          column: 34
        }
      },
      '115': {
        start: {
          line: 230,
          column: 10
        },
        end: {
          line: 230,
          column: 41
        }
      },
      '116': {
        start: {
          line: 231,
          column: 10
        },
        end: {
          line: 231,
          column: 43
        }
      },
      '117': {
        start: {
          line: 237,
          column: 2
        },
        end: {
          line: 250,
          column: 4
        }
      },
      '118': {
        start: {
          line: 238,
          column: 4
        },
        end: {
          line: 248,
          column: 7
        }
      },
      '119': {
        start: {
          line: 249,
          column: 4
        },
        end: {
          line: 249,
          column: 34
        }
      },
      '120': {
        start: {
          line: 252,
          column: 2
        },
        end: {
          line: 423,
          column: 4
        }
      },
      '121': {
        start: {
          line: 257,
          column: 21
        },
        end: {
          line: 257,
          column: 23
        }
      },
      '122': {
        start: {
          line: 258,
          column: 6
        },
        end: {
          line: 258,
          column: 63
        }
      },
      '123': {
        start: {
          line: 259,
          column: 6
        },
        end: {
          line: 262,
          column: 7
        }
      },
      '124': {
        start: {
          line: 260,
          column: 8
        },
        end: {
          line: 260,
          column: 96
        }
      },
      '125': {
        start: {
          line: 261,
          column: 8
        },
        end: {
          line: 261,
          column: 97
        }
      },
      '126': {
        start: {
          line: 263,
          column: 6
        },
        end: {
          line: 263,
          column: 95
        }
      },
      '127': {
        start: {
          line: 264,
          column: 6
        },
        end: {
          line: 269,
          column: 7
        }
      },
      '128': {
        start: {
          line: 265,
          column: 8
        },
        end: {
          line: 265,
          column: 75
        }
      },
      '129': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 266,
          column: 75
        }
      },
      '130': {
        start: {
          line: 267,
          column: 8
        },
        end: {
          line: 267,
          column: 75
        }
      },
      '131': {
        start: {
          line: 268,
          column: 8
        },
        end: {
          line: 268,
          column: 75
        }
      },
      '132': {
        start: {
          line: 271,
          column: 6
        },
        end: {
          line: 271,
          column: 90
        }
      },
      '133': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 272,
          column: 91
        }
      },
      '134': {
        start: {
          line: 273,
          column: 6
        },
        end: {
          line: 273,
          column: 95
        }
      },
      '135': {
        start: {
          line: 274,
          column: 6
        },
        end: {
          line: 274,
          column: 87
        }
      },
      '136': {
        start: {
          line: 276,
          column: 21
        },
        end: {
          line: 276,
          column: 118
        }
      },
      '137': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 277,
          column: 80
        }
      },
      '138': {
        start: {
          line: 279,
          column: 6
        },
        end: {
          line: 281,
          column: 7
        }
      },
      '139': {
        start: {
          line: 280,
          column: 8
        },
        end: {
          line: 280,
          column: 67
        }
      },
      '140': {
        start: {
          line: 283,
          column: 21
        },
        end: {
          line: 283,
          column: 62
        }
      },
      '141': {
        start: {
          line: 285,
          column: 20
        },
        end: {
          line: 285,
          column: 22
        }
      },
      '142': {
        start: {
          line: 286,
          column: 6
        },
        end: {
          line: 327,
          column: 7
        }
      },
      '143': {
        start: {
          line: 287,
          column: 8
        },
        end: {
          line: 289,
          column: 9
        }
      },
      '144': {
        start: {
          line: 288,
          column: 10
        },
        end: {
          line: 288,
          column: 19
        }
      },
      '145': {
        start: {
          line: 290,
          column: 22
        },
        end: {
          line: 290,
          column: 24
        }
      },
      '146': {
        start: {
          line: 291,
          column: 8
        },
        end: {
          line: 291,
          column: 26
        }
      },
      '147': {
        start: {
          line: 292,
          column: 8
        },
        end: {
          line: 299,
          column: 9
        }
      },
      '148': {
        start: {
          line: 293,
          column: 10
        },
        end: {
          line: 293,
          column: 72
        }
      },
      '149': {
        start: {
          line: 294,
          column: 10
        },
        end: {
          line: 298,
          column: 11
        }
      },
      '150': {
        start: {
          line: 295,
          column: 12
        },
        end: {
          line: 295,
          column: 79
        }
      },
      '151': {
        start: {
          line: 297,
          column: 12
        },
        end: {
          line: 297,
          column: 197
        }
      },
      '152': {
        start: {
          line: 300,
          column: 8
        },
        end: {
          line: 300,
          column: 89
        }
      },
      '153': {
        start: {
          line: 301,
          column: 8
        },
        end: {
          line: 314,
          column: 9
        }
      },
      '154': {
        start: {
          line: 302,
          column: 10
        },
        end: {
          line: 302,
          column: 92
        }
      },
      '155': {
        start: {
          line: 303,
          column: 10
        },
        end: {
          line: 303,
          column: 92
        }
      },
      '156': {
        start: {
          line: 304,
          column: 10
        },
        end: {
          line: 308,
          column: 11
        }
      },
      '157': {
        start: {
          line: 305,
          column: 12
        },
        end: {
          line: 305,
          column: 94
        }
      },
      '158': {
        start: {
          line: 307,
          column: 12
        },
        end: {
          line: 307,
          column: 212
        }
      },
      '159': {
        start: {
          line: 309,
          column: 10
        },
        end: {
          line: 313,
          column: 11
        }
      },
      '160': {
        start: {
          line: 310,
          column: 12
        },
        end: {
          line: 310,
          column: 94
        }
      },
      '161': {
        start: {
          line: 312,
          column: 12
        },
        end: {
          line: 312,
          column: 212
        }
      },
      '162': {
        start: {
          line: 316,
          column: 8
        },
        end: {
          line: 316,
          column: 69
        }
      },
      '163': {
        start: {
          line: 318,
          column: 8
        },
        end: {
          line: 322,
          column: 9
        }
      },
      '164': {
        start: {
          line: 319,
          column: 10
        },
        end: {
          line: 319,
          column: 72
        }
      },
      '165': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 185
        }
      },
      '166': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 323,
          column: 87
        }
      },
      '167': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 324,
          column: 68
        }
      },
      '168': {
        start: {
          line: 326,
          column: 8
        },
        end: {
          line: 326,
          column: 26
        }
      },
      '169': {
        start: {
          line: 329,
          column: 6
        },
        end: {
          line: 329,
          column: 55
        }
      },
      '170': {
        start: {
          line: 343,
          column: 14
        },
        end: {
          line: 343,
          column: 107
        }
      },
      '171': {
        start: {
          line: 386,
          column: 6
        },
        end: {
          line: 389,
          column: 8
        }
      },
      '172': {
        start: {
          line: 390,
          column: 6
        },
        end: {
          line: 390,
          column: 36
        }
      },
      '173': {
        start: {
          line: 393,
          column: 6
        },
        end: {
          line: 395,
          column: 7
        }
      },
      '174': {
        start: {
          line: 394,
          column: 8
        },
        end: {
          line: 394,
          column: 17
        }
      },
      '175': {
        start: {
          line: 396,
          column: 6
        },
        end: {
          line: 398,
          column: 7
        }
      },
      '176': {
        start: {
          line: 397,
          column: 8
        },
        end: {
          line: 397,
          column: 36
        }
      },
      '177': {
        start: {
          line: 399,
          column: 26
        },
        end: {
          line: 399,
          column: 40
        }
      },
      '178': {
        start: {
          line: 400,
          column: 23
        },
        end: {
          line: 400,
          column: 42
        }
      },
      '179': {
        start: {
          line: 401,
          column: 6
        },
        end: {
          line: 403,
          column: 7
        }
      },
      '180': {
        start: {
          line: 402,
          column: 8
        },
        end: {
          line: 402,
          column: 72
        }
      },
      '181': {
        start: {
          line: 404,
          column: 6
        },
        end: {
          line: 406,
          column: 7
        }
      },
      '182': {
        start: {
          line: 405,
          column: 8
        },
        end: {
          line: 405,
          column: 18
        }
      },
      '183': {
        start: {
          line: 407,
          column: 6
        },
        end: {
          line: 407,
          column: 46
        }
      },
      '184': {
        start: {
          line: 410,
          column: 6
        },
        end: {
          line: 412,
          column: 7
        }
      },
      '185': {
        start: {
          line: 411,
          column: 8
        },
        end: {
          line: 411,
          column: 17
        }
      },
      '186': {
        start: {
          line: 413,
          column: 24
        },
        end: {
          line: 413,
          column: 37
        }
      },
      '187': {
        start: {
          line: 414,
          column: 23
        },
        end: {
          line: 414,
          column: 37
        }
      },
      '188': {
        start: {
          line: 415,
          column: 6
        },
        end: {
          line: 417,
          column: 7
        }
      },
      '189': {
        start: {
          line: 416,
          column: 8
        },
        end: {
          line: 416,
          column: 67
        }
      },
      '190': {
        start: {
          line: 418,
          column: 6
        },
        end: {
          line: 420,
          column: 7
        }
      },
      '191': {
        start: {
          line: 419,
          column: 8
        },
        end: {
          line: 419,
          column: 18
        }
      },
      '192': {
        start: {
          line: 421,
          column: 6
        },
        end: {
          line: 421,
          column: 44
        }
      },
      '193': {
        start: {
          line: 424,
          column: 2
        },
        end: {
          line: 695,
          column: 4
        }
      },
      '194': {
        start: {
          line: 430,
          column: 6
        },
        end: {
          line: 437,
          column: 7
        }
      },
      '195': {
        start: {
          line: 431,
          column: 21
        },
        end: {
          line: 431,
          column: 30
        }
      },
      '196': {
        start: {
          line: 432,
          column: 22
        },
        end: {
          line: 432,
          column: 49
        }
      },
      '197': {
        start: {
          line: 433,
          column: 23
        },
        end: {
          line: 433,
          column: 51
        }
      },
      '198': {
        start: {
          line: 434,
          column: 21
        },
        end: {
          line: 434,
          column: 64
        }
      },
      '199': {
        start: {
          line: 435,
          column: 20
        },
        end: {
          line: 435,
          column: 65
        }
      },
      '200': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 436,
          column: 126
        }
      },
      '201': {
        start: {
          line: 438,
          column: 6
        },
        end: {
          line: 438,
          column: 31
        }
      },
      '202': {
        start: {
          line: 439,
          column: 6
        },
        end: {
          line: 439,
          column: 31
        }
      },
      '203': {
        start: {
          line: 440,
          column: 6
        },
        end: {
          line: 440,
          column: 35
        }
      },
      '204': {
        start: {
          line: 441,
          column: 6
        },
        end: {
          line: 443,
          column: 7
        }
      },
      '205': {
        start: {
          line: 442,
          column: 8
        },
        end: {
          line: 442,
          column: 15
        }
      },
      '206': {
        start: {
          line: 444,
          column: 6
        },
        end: {
          line: 478,
          column: 7
        }
      },
      '207': {
        start: {
          line: 445,
          column: 8
        },
        end: {
          line: 447,
          column: 9
        }
      },
      '208': {
        start: {
          line: 446,
          column: 10
        },
        end: {
          line: 446,
          column: 19
        }
      },
      '209': {
        start: {
          line: 448,
          column: 18
        },
        end: {
          line: 448,
          column: 38
        }
      },
      '210': {
        start: {
          line: 449,
          column: 8
        },
        end: {
          line: 451,
          column: 9
        }
      },
      '211': {
        start: {
          line: 450,
          column: 10
        },
        end: {
          line: 450,
          column: 19
        }
      },
      '212': {
        start: {
          line: 453,
          column: 8
        },
        end: {
          line: 453,
          column: 50
        }
      },
      '213': {
        start: {
          line: 455,
          column: 27
        },
        end: {
          line: 455,
          column: 31
        }
      },
      '214': {
        start: {
          line: 456,
          column: 28
        },
        end: {
          line: 456,
          column: 32
        }
      },
      '215': {
        start: {
          line: 458,
          column: 8
        },
        end: {
          line: 468,
          column: 9
        }
      },
      '216': {
        start: {
          line: 459,
          column: 10
        },
        end: {
          line: 459,
          column: 44
        }
      },
      '217': {
        start: {
          line: 461,
          column: 22
        },
        end: {
          line: 461,
          column: 39
        }
      },
      '218': {
        start: {
          line: 462,
          column: 10
        },
        end: {
          line: 464,
          column: 11
        }
      },
      '219': {
        start: {
          line: 463,
          column: 12
        },
        end: {
          line: 463,
          column: 42
        }
      },
      '220': {
        start: {
          line: 465,
          column: 10
        },
        end: {
          line: 467,
          column: 11
        }
      },
      '221': {
        start: {
          line: 466,
          column: 12
        },
        end: {
          line: 466,
          column: 54
        }
      },
      '222': {
        start: {
          line: 469,
          column: 21
        },
        end: {
          line: 469,
          column: 33
        }
      },
      '223': {
        start: {
          line: 470,
          column: 8
        },
        end: {
          line: 472,
          column: 9
        }
      },
      '224': {
        start: {
          line: 471,
          column: 10
        },
        end: {
          line: 471,
          column: 36
        }
      },
      '225': {
        start: {
          line: 473,
          column: 8
        },
        end: {
          line: 475,
          column: 9
        }
      },
      '226': {
        start: {
          line: 474,
          column: 10
        },
        end: {
          line: 474,
          column: 53
        }
      },
      '227': {
        start: {
          line: 476,
          column: 8
        },
        end: {
          line: 476,
          column: 91
        }
      },
      '228': {
        start: {
          line: 477,
          column: 8
        },
        end: {
          line: 477,
          column: 93
        }
      },
      '229': {
        start: {
          line: 481,
          column: 19
        },
        end: {
          line: 481,
          column: 36
        }
      },
      '230': {
        start: {
          line: 482,
          column: 6
        },
        end: {
          line: 482,
          column: 31
        }
      },
      '231': {
        start: {
          line: 483,
          column: 6
        },
        end: {
          line: 483,
          column: 35
        }
      },
      '232': {
        start: {
          line: 484,
          column: 6
        },
        end: {
          line: 488,
          column: 7
        }
      },
      '233': {
        start: {
          line: 485,
          column: 8
        },
        end: {
          line: 485,
          column: 39
        }
      },
      '234': {
        start: {
          line: 486,
          column: 8
        },
        end: {
          line: 486,
          column: 74
        }
      },
      '235': {
        start: {
          line: 487,
          column: 8
        },
        end: {
          line: 487,
          column: 76
        }
      },
      '236': {
        start: {
          line: 491,
          column: 21
        },
        end: {
          line: 491,
          column: 23
        }
      },
      '237': {
        start: {
          line: 492,
          column: 6
        },
        end: {
          line: 492,
          column: 40
        }
      },
      '238': {
        start: {
          line: 493,
          column: 6
        },
        end: {
          line: 495,
          column: 7
        }
      },
      '239': {
        start: {
          line: 494,
          column: 8
        },
        end: {
          line: 494,
          column: 72
        }
      },
      '240': {
        start: {
          line: 496,
          column: 6
        },
        end: {
          line: 496,
          column: 68
        }
      },
      '241': {
        start: {
          line: 497,
          column: 6
        },
        end: {
          line: 499,
          column: 7
        }
      },
      '242': {
        start: {
          line: 498,
          column: 8
        },
        end: {
          line: 498,
          column: 53
        }
      },
      '243': {
        start: {
          line: 500,
          column: 6
        },
        end: {
          line: 502,
          column: 7
        }
      },
      '244': {
        start: {
          line: 501,
          column: 8
        },
        end: {
          line: 501,
          column: 94
        }
      },
      '245': {
        start: {
          line: 504,
          column: 6
        },
        end: {
          line: 504,
          column: 90
        }
      },
      '246': {
        start: {
          line: 506,
          column: 6
        },
        end: {
          line: 506,
          column: 95
        }
      },
      '247': {
        start: {
          line: 508,
          column: 6
        },
        end: {
          line: 508,
          column: 95
        }
      },
      '248': {
        start: {
          line: 510,
          column: 20
        },
        end: {
          line: 510,
          column: 22
        }
      },
      '249': {
        start: {
          line: 511,
          column: 6
        },
        end: {
          line: 550,
          column: 7
        }
      },
      '250': {
        start: {
          line: 512,
          column: 8
        },
        end: {
          line: 514,
          column: 9
        }
      },
      '251': {
        start: {
          line: 513,
          column: 10
        },
        end: {
          line: 513,
          column: 19
        }
      },
      '252': {
        start: {
          line: 515,
          column: 22
        },
        end: {
          line: 515,
          column: 24
        }
      },
      '253': {
        start: {
          line: 516,
          column: 8
        },
        end: {
          line: 516,
          column: 26
        }
      },
      '254': {
        start: {
          line: 517,
          column: 8
        },
        end: {
          line: 523,
          column: 9
        }
      },
      '255': {
        start: {
          line: 518,
          column: 10
        },
        end: {
          line: 522,
          column: 11
        }
      },
      '256': {
        start: {
          line: 519,
          column: 12
        },
        end: {
          line: 519,
          column: 53
        }
      },
      '257': {
        start: {
          line: 521,
          column: 12
        },
        end: {
          line: 521,
          column: 121
        }
      },
      '258': {
        start: {
          line: 525,
          column: 8
        },
        end: {
          line: 529,
          column: 9
        }
      },
      '259': {
        start: {
          line: 526,
          column: 10
        },
        end: {
          line: 526,
          column: 52
        }
      },
      '260': {
        start: {
          line: 528,
          column: 10
        },
        end: {
          line: 528,
          column: 122
        }
      },
      '261': {
        start: {
          line: 531,
          column: 8
        },
        end: {
          line: 541,
          column: 9
        }
      },
      '262': {
        start: {
          line: 532,
          column: 10
        },
        end: {
          line: 534,
          column: 11
        }
      },
      '263': {
        start: {
          line: 533,
          column: 12
        },
        end: {
          line: 533,
          column: 64
        }
      },
      '264': {
        start: {
          line: 535,
          column: 10
        },
        end: {
          line: 537,
          column: 11
        }
      },
      '265': {
        start: {
          line: 536,
          column: 12
        },
        end: {
          line: 536,
          column: 80
        }
      },
      '266': {
        start: {
          line: 538,
          column: 10
        },
        end: {
          line: 540,
          column: 11
        }
      },
      '267': {
        start: {
          line: 539,
          column: 12
        },
        end: {
          line: 539,
          column: 63
        }
      },
      '268': {
        start: {
          line: 542,
          column: 8
        },
        end: {
          line: 544,
          column: 9
        }
      },
      '269': {
        start: {
          line: 543,
          column: 10
        },
        end: {
          line: 543,
          column: 55
        }
      },
      '270': {
        start: {
          line: 545,
          column: 8
        },
        end: {
          line: 545,
          column: 51
        }
      },
      '271': {
        start: {
          line: 546,
          column: 8
        },
        end: {
          line: 546,
          column: 58
        }
      },
      '272': {
        start: {
          line: 547,
          column: 8
        },
        end: {
          line: 547,
          column: 56
        }
      },
      '273': {
        start: {
          line: 549,
          column: 8
        },
        end: {
          line: 549,
          column: 26
        }
      },
      '274': {
        start: {
          line: 551,
          column: 21
        },
        end: {
          line: 551,
          column: 118
        }
      },
      '275': {
        start: {
          line: 553,
          column: 6
        },
        end: {
          line: 553,
          column: 80
        }
      },
      '276': {
        start: {
          line: 554,
          column: 21
        },
        end: {
          line: 554,
          column: 94
        }
      },
      '277': {
        start: {
          line: 556,
          column: 6
        },
        end: {
          line: 556,
          column: 55
        }
      },
      '278': {
        start: {
          line: 567,
          column: 14
        },
        end: {
          line: 567,
          column: 107
        }
      },
      '279': {
        start: {
          line: 609,
          column: 6
        },
        end: {
          line: 612,
          column: 8
        }
      },
      '280': {
        start: {
          line: 613,
          column: 6
        },
        end: {
          line: 613,
          column: 36
        }
      },
      '281': {
        start: {
          line: 616,
          column: 18
        },
        end: {
          line: 616,
          column: 19
        }
      },
      '282': {
        start: {
          line: 617,
          column: 6
        },
        end: {
          line: 622,
          column: 7
        }
      },
      '283': {
        start: {
          line: 618,
          column: 8
        },
        end: {
          line: 620,
          column: 9
        }
      },
      '284': {
        start: {
          line: 619,
          column: 10
        },
        end: {
          line: 619,
          column: 19
        }
      },
      '285': {
        start: {
          line: 621,
          column: 8
        },
        end: {
          line: 621,
          column: 71
        }
      },
      '286': {
        start: {
          line: 623,
          column: 6
        },
        end: {
          line: 623,
          column: 19
        }
      },
      '287': {
        start: {
          line: 626,
          column: 18
        },
        end: {
          line: 626,
          column: 19
        }
      },
      '288': {
        start: {
          line: 627,
          column: 6
        },
        end: {
          line: 632,
          column: 7
        }
      },
      '289': {
        start: {
          line: 628,
          column: 8
        },
        end: {
          line: 630,
          column: 9
        }
      },
      '290': {
        start: {
          line: 629,
          column: 10
        },
        end: {
          line: 629,
          column: 19
        }
      },
      '291': {
        start: {
          line: 631,
          column: 8
        },
        end: {
          line: 631,
          column: 70
        }
      },
      '292': {
        start: {
          line: 633,
          column: 6
        },
        end: {
          line: 633,
          column: 19
        }
      },
      '293': {
        start: {
          line: 636,
          column: 6
        },
        end: {
          line: 638,
          column: 7
        }
      },
      '294': {
        start: {
          line: 637,
          column: 8
        },
        end: {
          line: 637,
          column: 17
        }
      },
      '295': {
        start: {
          line: 639,
          column: 6
        },
        end: {
          line: 641,
          column: 7
        }
      },
      '296': {
        start: {
          line: 640,
          column: 8
        },
        end: {
          line: 640,
          column: 46
        }
      },
      '297': {
        start: {
          line: 642,
          column: 26
        },
        end: {
          line: 642,
          column: 57
        }
      },
      '298': {
        start: {
          line: 644,
          column: 23
        },
        end: {
          line: 644,
          column: 80
        }
      },
      '299': {
        start: {
          line: 645,
          column: 6
        },
        end: {
          line: 647,
          column: 7
        }
      },
      '300': {
        start: {
          line: 646,
          column: 8
        },
        end: {
          line: 646,
          column: 83
        }
      },
      '301': {
        start: {
          line: 649,
          column: 6
        },
        end: {
          line: 651,
          column: 7
        }
      },
      '302': {
        start: {
          line: 650,
          column: 8
        },
        end: {
          line: 650,
          column: 18
        }
      },
      '303': {
        start: {
          line: 652,
          column: 6
        },
        end: {
          line: 652,
          column: 46
        }
      },
      '304': {
        start: {
          line: 655,
          column: 6
        },
        end: {
          line: 657,
          column: 7
        }
      },
      '305': {
        start: {
          line: 656,
          column: 8
        },
        end: {
          line: 656,
          column: 17
        }
      },
      '306': {
        start: {
          line: 658,
          column: 24
        },
        end: {
          line: 658,
          column: 53
        }
      },
      '307': {
        start: {
          line: 660,
          column: 23
        },
        end: {
          line: 660,
          column: 81
        }
      },
      '308': {
        start: {
          line: 661,
          column: 6
        },
        end: {
          line: 663,
          column: 7
        }
      },
      '309': {
        start: {
          line: 662,
          column: 8
        },
        end: {
          line: 662,
          column: 84
        }
      },
      '310': {
        start: {
          line: 665,
          column: 6
        },
        end: {
          line: 667,
          column: 7
        }
      },
      '311': {
        start: {
          line: 666,
          column: 8
        },
        end: {
          line: 666,
          column: 18
        }
      },
      '312': {
        start: {
          line: 668,
          column: 6
        },
        end: {
          line: 668,
          column: 44
        }
      },
      '313': {
        start: {
          line: 672,
          column: 6
        },
        end: {
          line: 674,
          column: 7
        }
      },
      '314': {
        start: {
          line: 673,
          column: 8
        },
        end: {
          line: 673,
          column: 15
        }
      },
      '315': {
        start: {
          line: 675,
          column: 19
        },
        end: {
          line: 679,
          column: 7
        }
      },
      '316': {
        start: {
          line: 680,
          column: 6
        },
        end: {
          line: 692,
          column: 7
        }
      },
      '317': {
        start: {
          line: 681,
          column: 8
        },
        end: {
          line: 691,
          column: 9
        }
      },
      '318': {
        start: {
          line: 682,
          column: 10
        },
        end: {
          line: 684,
          column: 11
        }
      },
      '319': {
        start: {
          line: 683,
          column: 12
        },
        end: {
          line: 683,
          column: 31
        }
      },
      '320': {
        start: {
          line: 685,
          column: 10
        },
        end: {
          line: 690,
          column: 11
        }
      },
      '321': {
        start: {
          line: 686,
          column: 12
        },
        end: {
          line: 688,
          column: 13
        }
      },
      '322': {
        start: {
          line: 687,
          column: 14
        },
        end: {
          line: 687,
          column: 37
        }
      },
      '323': {
        start: {
          line: 689,
          column: 12
        },
        end: {
          line: 689,
          column: 92
        }
      },
      '324': {
        start: {
          line: 693,
          column: 6
        },
        end: {
          line: 693,
          column: 35
        }
      },
      '325': {
        start: {
          line: 696,
          column: 2
        },
        end: {
          line: 965,
          column: 4
        }
      },
      '326': {
        start: {
          line: 702,
          column: 6
        },
        end: {
          line: 709,
          column: 7
        }
      },
      '327': {
        start: {
          line: 703,
          column: 21
        },
        end: {
          line: 703,
          column: 30
        }
      },
      '328': {
        start: {
          line: 704,
          column: 22
        },
        end: {
          line: 704,
          column: 49
        }
      },
      '329': {
        start: {
          line: 705,
          column: 23
        },
        end: {
          line: 705,
          column: 51
        }
      },
      '330': {
        start: {
          line: 706,
          column: 21
        },
        end: {
          line: 706,
          column: 64
        }
      },
      '331': {
        start: {
          line: 707,
          column: 20
        },
        end: {
          line: 707,
          column: 65
        }
      },
      '332': {
        start: {
          line: 708,
          column: 8
        },
        end: {
          line: 708,
          column: 126
        }
      },
      '333': {
        start: {
          line: 710,
          column: 6
        },
        end: {
          line: 710,
          column: 31
        }
      },
      '334': {
        start: {
          line: 711,
          column: 6
        },
        end: {
          line: 711,
          column: 31
        }
      },
      '335': {
        start: {
          line: 712,
          column: 6
        },
        end: {
          line: 712,
          column: 35
        }
      },
      '336': {
        start: {
          line: 713,
          column: 6
        },
        end: {
          line: 715,
          column: 7
        }
      },
      '337': {
        start: {
          line: 714,
          column: 8
        },
        end: {
          line: 714,
          column: 15
        }
      },
      '338': {
        start: {
          line: 716,
          column: 6
        },
        end: {
          line: 749,
          column: 7
        }
      },
      '339': {
        start: {
          line: 717,
          column: 8
        },
        end: {
          line: 719,
          column: 9
        }
      },
      '340': {
        start: {
          line: 718,
          column: 10
        },
        end: {
          line: 718,
          column: 19
        }
      },
      '341': {
        start: {
          line: 720,
          column: 18
        },
        end: {
          line: 720,
          column: 38
        }
      },
      '342': {
        start: {
          line: 721,
          column: 8
        },
        end: {
          line: 723,
          column: 9
        }
      },
      '343': {
        start: {
          line: 722,
          column: 10
        },
        end: {
          line: 722,
          column: 19
        }
      },
      '344': {
        start: {
          line: 724,
          column: 8
        },
        end: {
          line: 724,
          column: 48
        }
      },
      '345': {
        start: {
          line: 726,
          column: 27
        },
        end: {
          line: 726,
          column: 31
        }
      },
      '346': {
        start: {
          line: 727,
          column: 28
        },
        end: {
          line: 727,
          column: 32
        }
      },
      '347': {
        start: {
          line: 729,
          column: 8
        },
        end: {
          line: 739,
          column: 9
        }
      },
      '348': {
        start: {
          line: 730,
          column: 10
        },
        end: {
          line: 730,
          column: 44
        }
      },
      '349': {
        start: {
          line: 732,
          column: 22
        },
        end: {
          line: 732,
          column: 37
        }
      },
      '350': {
        start: {
          line: 733,
          column: 10
        },
        end: {
          line: 735,
          column: 11
        }
      },
      '351': {
        start: {
          line: 734,
          column: 12
        },
        end: {
          line: 734,
          column: 40
        }
      },
      '352': {
        start: {
          line: 736,
          column: 10
        },
        end: {
          line: 738,
          column: 11
        }
      },
      '353': {
        start: {
          line: 737,
          column: 12
        },
        end: {
          line: 737,
          column: 55
        }
      },
      '354': {
        start: {
          line: 740,
          column: 21
        },
        end: {
          line: 740,
          column: 37
        }
      },
      '355': {
        start: {
          line: 741,
          column: 8
        },
        end: {
          line: 743,
          column: 9
        }
      },
      '356': {
        start: {
          line: 742,
          column: 10
        },
        end: {
          line: 742,
          column: 40
        }
      },
      '357': {
        start: {
          line: 744,
          column: 8
        },
        end: {
          line: 746,
          column: 9
        }
      },
      '358': {
        start: {
          line: 745,
          column: 10
        },
        end: {
          line: 745,
          column: 54
        }
      },
      '359': {
        start: {
          line: 747,
          column: 8
        },
        end: {
          line: 747,
          column: 103
        }
      },
      '360': {
        start: {
          line: 748,
          column: 8
        },
        end: {
          line: 748,
          column: 105
        }
      },
      '361': {
        start: {
          line: 752,
          column: 6
        },
        end: {
          line: 755,
          column: 8
        }
      },
      '362': {
        start: {
          line: 756,
          column: 6
        },
        end: {
          line: 756,
          column: 36
        }
      },
      '363': {
        start: {
          line: 759,
          column: 19
        },
        end: {
          line: 759,
          column: 36
        }
      },
      '364': {
        start: {
          line: 760,
          column: 6
        },
        end: {
          line: 760,
          column: 31
        }
      },
      '365': {
        start: {
          line: 761,
          column: 6
        },
        end: {
          line: 761,
          column: 35
        }
      },
      '366': {
        start: {
          line: 762,
          column: 6
        },
        end: {
          line: 766,
          column: 7
        }
      },
      '367': {
        start: {
          line: 763,
          column: 8
        },
        end: {
          line: 763,
          column: 39
        }
      },
      '368': {
        start: {
          line: 764,
          column: 8
        },
        end: {
          line: 764,
          column: 76
        }
      },
      '369': {
        start: {
          line: 765,
          column: 8
        },
        end: {
          line: 765,
          column: 74
        }
      },
      '370': {
        start: {
          line: 777,
          column: 14
        },
        end: {
          line: 777,
          column: 107
        }
      },
      '371': {
        start: {
          line: 819,
          column: 18
        },
        end: {
          line: 819,
          column: 19
        }
      },
      '372': {
        start: {
          line: 820,
          column: 6
        },
        end: {
          line: 828,
          column: 7
        }
      },
      '373': {
        start: {
          line: 821,
          column: 8
        },
        end: {
          line: 823,
          column: 9
        }
      },
      '374': {
        start: {
          line: 822,
          column: 10
        },
        end: {
          line: 822,
          column: 19
        }
      },
      '375': {
        start: {
          line: 824,
          column: 8
        },
        end: {
          line: 826,
          column: 9
        }
      },
      '376': {
        start: {
          line: 825,
          column: 10
        },
        end: {
          line: 825,
          column: 19
        }
      },
      '377': {
        start: {
          line: 827,
          column: 8
        },
        end: {
          line: 827,
          column: 79
        }
      },
      '378': {
        start: {
          line: 829,
          column: 6
        },
        end: {
          line: 829,
          column: 19
        }
      },
      '379': {
        start: {
          line: 832,
          column: 18
        },
        end: {
          line: 832,
          column: 19
        }
      },
      '380': {
        start: {
          line: 833,
          column: 6
        },
        end: {
          line: 841,
          column: 7
        }
      },
      '381': {
        start: {
          line: 834,
          column: 8
        },
        end: {
          line: 836,
          column: 9
        }
      },
      '382': {
        start: {
          line: 835,
          column: 10
        },
        end: {
          line: 835,
          column: 19
        }
      },
      '383': {
        start: {
          line: 837,
          column: 8
        },
        end: {
          line: 839,
          column: 9
        }
      },
      '384': {
        start: {
          line: 838,
          column: 10
        },
        end: {
          line: 838,
          column: 19
        }
      },
      '385': {
        start: {
          line: 840,
          column: 8
        },
        end: {
          line: 840,
          column: 75
        }
      },
      '386': {
        start: {
          line: 842,
          column: 6
        },
        end: {
          line: 842,
          column: 19
        }
      },
      '387': {
        start: {
          line: 845,
          column: 6
        },
        end: {
          line: 847,
          column: 7
        }
      },
      '388': {
        start: {
          line: 846,
          column: 8
        },
        end: {
          line: 846,
          column: 33
        }
      },
      '389': {
        start: {
          line: 848,
          column: 26
        },
        end: {
          line: 848,
          column: 57
        }
      },
      '390': {
        start: {
          line: 849,
          column: 23
        },
        end: {
          line: 849,
          column: 73
        }
      },
      '391': {
        start: {
          line: 850,
          column: 6
        },
        end: {
          line: 852,
          column: 7
        }
      },
      '392': {
        start: {
          line: 851,
          column: 8
        },
        end: {
          line: 851,
          column: 76
        }
      },
      '393': {
        start: {
          line: 853,
          column: 6
        },
        end: {
          line: 855,
          column: 7
        }
      },
      '394': {
        start: {
          line: 854,
          column: 8
        },
        end: {
          line: 854,
          column: 18
        }
      },
      '395': {
        start: {
          line: 856,
          column: 6
        },
        end: {
          line: 856,
          column: 46
        }
      },
      '396': {
        start: {
          line: 859,
          column: 24
        },
        end: {
          line: 859,
          column: 53
        }
      },
      '397': {
        start: {
          line: 860,
          column: 23
        },
        end: {
          line: 860,
          column: 74
        }
      },
      '398': {
        start: {
          line: 861,
          column: 6
        },
        end: {
          line: 863,
          column: 7
        }
      },
      '399': {
        start: {
          line: 862,
          column: 8
        },
        end: {
          line: 862,
          column: 77
        }
      },
      '400': {
        start: {
          line: 865,
          column: 6
        },
        end: {
          line: 867,
          column: 7
        }
      },
      '401': {
        start: {
          line: 866,
          column: 8
        },
        end: {
          line: 866,
          column: 18
        }
      },
      '402': {
        start: {
          line: 868,
          column: 6
        },
        end: {
          line: 868,
          column: 44
        }
      },
      '403': {
        start: {
          line: 872,
          column: 21
        },
        end: {
          line: 872,
          column: 23
        }
      },
      '404': {
        start: {
          line: 873,
          column: 6
        },
        end: {
          line: 873,
          column: 63
        }
      },
      '405': {
        start: {
          line: 874,
          column: 6
        },
        end: {
          line: 876,
          column: 7
        }
      },
      '406': {
        start: {
          line: 875,
          column: 8
        },
        end: {
          line: 875,
          column: 95
        }
      },
      '407': {
        start: {
          line: 877,
          column: 6
        },
        end: {
          line: 877,
          column: 91
        }
      },
      '408': {
        start: {
          line: 879,
          column: 6
        },
        end: {
          line: 881,
          column: 7
        }
      },
      '409': {
        start: {
          line: 880,
          column: 8
        },
        end: {
          line: 880,
          column: 53
        }
      },
      '410': {
        start: {
          line: 882,
          column: 6
        },
        end: {
          line: 884,
          column: 7
        }
      },
      '411': {
        start: {
          line: 883,
          column: 8
        },
        end: {
          line: 883,
          column: 94
        }
      },
      '412': {
        start: {
          line: 886,
          column: 6
        },
        end: {
          line: 886,
          column: 90
        }
      },
      '413': {
        start: {
          line: 888,
          column: 6
        },
        end: {
          line: 888,
          column: 95
        }
      },
      '414': {
        start: {
          line: 890,
          column: 6
        },
        end: {
          line: 890,
          column: 95
        }
      },
      '415': {
        start: {
          line: 892,
          column: 20
        },
        end: {
          line: 892,
          column: 22
        }
      },
      '416': {
        start: {
          line: 893,
          column: 6
        },
        end: {
          line: 929,
          column: 7
        }
      },
      '417': {
        start: {
          line: 894,
          column: 22
        },
        end: {
          line: 894,
          column: 24
        }
      },
      '418': {
        start: {
          line: 895,
          column: 8
        },
        end: {
          line: 895,
          column: 26
        }
      },
      '419': {
        start: {
          line: 896,
          column: 8
        },
        end: {
          line: 902,
          column: 9
        }
      },
      '420': {
        start: {
          line: 897,
          column: 10
        },
        end: {
          line: 901,
          column: 11
        }
      },
      '421': {
        start: {
          line: 898,
          column: 12
        },
        end: {
          line: 898,
          column: 49
        }
      },
      '422': {
        start: {
          line: 900,
          column: 12
        },
        end: {
          line: 900,
          column: 106
        }
      },
      '423': {
        start: {
          line: 904,
          column: 8
        },
        end: {
          line: 908,
          column: 9
        }
      },
      '424': {
        start: {
          line: 905,
          column: 10
        },
        end: {
          line: 905,
          column: 48
        }
      },
      '425': {
        start: {
          line: 907,
          column: 10
        },
        end: {
          line: 907,
          column: 106
        }
      },
      '426': {
        start: {
          line: 910,
          column: 8
        },
        end: {
          line: 920,
          column: 9
        }
      },
      '427': {
        start: {
          line: 911,
          column: 10
        },
        end: {
          line: 913,
          column: 11
        }
      },
      '428': {
        start: {
          line: 912,
          column: 12
        },
        end: {
          line: 912,
          column: 65
        }
      },
      '429': {
        start: {
          line: 914,
          column: 10
        },
        end: {
          line: 916,
          column: 11
        }
      },
      '430': {
        start: {
          line: 915,
          column: 12
        },
        end: {
          line: 915,
          column: 61
        }
      },
      '431': {
        start: {
          line: 917,
          column: 10
        },
        end: {
          line: 919,
          column: 11
        }
      },
      '432': {
        start: {
          line: 918,
          column: 12
        },
        end: {
          line: 918,
          column: 64
        }
      },
      '433': {
        start: {
          line: 921,
          column: 8
        },
        end: {
          line: 923,
          column: 9
        }
      },
      '434': {
        start: {
          line: 922,
          column: 10
        },
        end: {
          line: 922,
          column: 55
        }
      },
      '435': {
        start: {
          line: 924,
          column: 8
        },
        end: {
          line: 924,
          column: 51
        }
      },
      '436': {
        start: {
          line: 925,
          column: 8
        },
        end: {
          line: 925,
          column: 58
        }
      },
      '437': {
        start: {
          line: 926,
          column: 8
        },
        end: {
          line: 926,
          column: 56
        }
      },
      '438': {
        start: {
          line: 928,
          column: 8
        },
        end: {
          line: 928,
          column: 26
        }
      },
      '439': {
        start: {
          line: 931,
          column: 21
        },
        end: {
          line: 931,
          column: 118
        }
      },
      '440': {
        start: {
          line: 932,
          column: 6
        },
        end: {
          line: 932,
          column: 80
        }
      },
      '441': {
        start: {
          line: 933,
          column: 6
        },
        end: {
          line: 933,
          column: 94
        }
      },
      '442': {
        start: {
          line: 935,
          column: 21
        },
        end: {
          line: 935,
          column: 40
        }
      },
      '443': {
        start: {
          line: 937,
          column: 6
        },
        end: {
          line: 937,
          column: 143
        }
      },
      '444': {
        start: {
          line: 941,
          column: 6
        },
        end: {
          line: 943,
          column: 7
        }
      },
      '445': {
        start: {
          line: 942,
          column: 8
        },
        end: {
          line: 942,
          column: 15
        }
      },
      '446': {
        start: {
          line: 944,
          column: 19
        },
        end: {
          line: 948,
          column: 7
        }
      },
      '447': {
        start: {
          line: 949,
          column: 6
        },
        end: {
          line: 961,
          column: 7
        }
      },
      '448': {
        start: {
          line: 950,
          column: 8
        },
        end: {
          line: 960,
          column: 9
        }
      },
      '449': {
        start: {
          line: 951,
          column: 10
        },
        end: {
          line: 953,
          column: 11
        }
      },
      '450': {
        start: {
          line: 952,
          column: 12
        },
        end: {
          line: 952,
          column: 31
        }
      },
      '451': {
        start: {
          line: 954,
          column: 10
        },
        end: {
          line: 959,
          column: 11
        }
      },
      '452': {
        start: {
          line: 955,
          column: 12
        },
        end: {
          line: 957,
          column: 13
        }
      },
      '453': {
        start: {
          line: 956,
          column: 14
        },
        end: {
          line: 956,
          column: 37
        }
      },
      '454': {
        start: {
          line: 958,
          column: 12
        },
        end: {
          line: 958,
          column: 92
        }
      },
      '455': {
        start: {
          line: 962,
          column: 6
        },
        end: {
          line: 962,
          column: 35
        }
      },
      '456': {
        start: {
          line: 967,
          column: 2
        },
        end: {
          line: 970,
          column: 4
        }
      },
      '457': {
        start: {
          line: 1027,
          column: 2
        },
        end: {
          line: 1029,
          column: 4
        }
      },
      '458': {
        start: {
          line: 1028,
          column: 4
        },
        end: {
          line: 1028,
          column: 42
        }
      },
      '459': {
        start: {
          line: 1030,
          column: 2
        },
        end: {
          line: 1034,
          column: 4
        }
      },
      '460': {
        start: {
          line: 1031,
          column: 19
        },
        end: {
          line: 1031,
          column: 52
        }
      },
      '461': {
        start: {
          line: 1032,
          column: 4
        },
        end: {
          line: 1032,
          column: 56
        }
      },
      '462': {
        start: {
          line: 1033,
          column: 4
        },
        end: {
          line: 1033,
          column: 36
        }
      },
      '463': {
        start: {
          line: 1035,
          column: 2
        },
        end: {
          line: 1073,
          column: 4
        }
      },
      '464': {
        start: {
          line: 1036,
          column: 4
        },
        end: {
          line: 1036,
          column: 83
        }
      },
      '465': {
        start: {
          line: 1037,
          column: 4
        },
        end: {
          line: 1037,
          column: 78
        }
      },
      '466': {
        start: {
          line: 1039,
          column: 4
        },
        end: {
          line: 1072,
          column: 7
        }
      },
      '467': {
        start: {
          line: 1040,
          column: 6
        },
        end: {
          line: 1040,
          column: 67
        }
      },
      '468': {
        start: {
          line: 1041,
          column: 6
        },
        end: {
          line: 1041,
          column: 63
        }
      },
      '469': {
        start: {
          line: 1042,
          column: 6
        },
        end: {
          line: 1042,
          column: 37
        }
      },
      '470': {
        start: {
          line: 1043,
          column: 6
        },
        end: {
          line: 1043,
          column: 31
        }
      },
      '471': {
        start: {
          line: 1044,
          column: 6
        },
        end: {
          line: 1044,
          column: 30
        }
      },
      '472': {
        start: {
          line: 1045,
          column: 6
        },
        end: {
          line: 1045,
          column: 37
        }
      },
      '473': {
        start: {
          line: 1046,
          column: 6
        },
        end: {
          line: 1046,
          column: 82
        }
      },
      '474': {
        start: {
          line: 1047,
          column: 6
        },
        end: {
          line: 1060,
          column: 9
        }
      },
      '475': {
        start: {
          line: 1054,
          column: 10
        },
        end: {
          line: 1058,
          column: 12
        }
      },
      '476': {
        start: {
          line: 1061,
          column: 6
        },
        end: {
          line: 1061,
          column: 53
        }
      },
      '477': {
        start: {
          line: 1065,
          column: 6
        },
        end: {
          line: 1068,
          column: 9
        }
      },
      '478': {
        start: {
          line: 1066,
          column: 8
        },
        end: {
          line: 1066,
          column: 71
        }
      },
      '479': {
        start: {
          line: 1067,
          column: 8
        },
        end: {
          line: 1067,
          column: 84
        }
      },
      '480': {
        start: {
          line: 1071,
          column: 6
        },
        end: {
          line: 1071,
          column: 29
        }
      },
      '481': {
        start: {
          line: 1074,
          column: 2
        },
        end: {
          line: 1090,
          column: 4
        }
      },
      '482': {
        start: {
          line: 1075,
          column: 18
        },
        end: {
          line: 1075,
          column: 120
        }
      },
      '483': {
        start: {
          line: 1076,
          column: 4
        },
        end: {
          line: 1080,
          column: 5
        }
      },
      '484': {
        start: {
          line: 1077,
          column: 6
        },
        end: {
          line: 1079,
          column: 7
        }
      },
      '485': {
        start: {
          line: 1078,
          column: 8
        },
        end: {
          line: 1078,
          column: 49
        }
      },
      '486': {
        start: {
          line: 1081,
          column: 4
        },
        end: {
          line: 1089,
          column: 7
        }
      },
      '487': {
        start: {
          line: 1082,
          column: 6
        },
        end: {
          line: 1082,
          column: 31
        }
      },
      '488': {
        start: {
          line: 1083,
          column: 6
        },
        end: {
          line: 1083,
          column: 34
        }
      },
      '489': {
        start: {
          line: 1084,
          column: 6
        },
        end: {
          line: 1084,
          column: 41
        }
      },
      '490': {
        start: {
          line: 1088,
          column: 6
        },
        end: {
          line: 1088,
          column: 26
        }
      },
      '491': {
        start: {
          line: 1091,
          column: 2
        },
        end: {
          line: 1106,
          column: 4
        }
      },
      '492': {
        start: {
          line: 1092,
          column: 4
        },
        end: {
          line: 1094,
          column: 5
        }
      },
      '493': {
        start: {
          line: 1093,
          column: 6
        },
        end: {
          line: 1093,
          column: 31
        }
      },
      '494': {
        start: {
          line: 1095,
          column: 4
        },
        end: {
          line: 1097,
          column: 6
        }
      },
      '495': {
        start: {
          line: 1098,
          column: 4
        },
        end: {
          line: 1098,
          column: 40
        }
      },
      '496': {
        start: {
          line: 1099,
          column: 4
        },
        end: {
          line: 1102,
          column: 5
        }
      },
      '497': {
        start: {
          line: 1100,
          column: 6
        },
        end: {
          line: 1100,
          column: 191
        }
      },
      '498': {
        start: {
          line: 1101,
          column: 6
        },
        end: {
          line: 1101,
          column: 189
        }
      },
      '499': {
        start: {
          line: 1103,
          column: 4
        },
        end: {
          line: 1103,
          column: 58
        }
      },
      '500': {
        start: {
          line: 1104,
          column: 4
        },
        end: {
          line: 1104,
          column: 58
        }
      },
      '501': {
        start: {
          line: 1105,
          column: 4
        },
        end: {
          line: 1105,
          column: 28
        }
      },
      '502': {
        start: {
          line: 1107,
          column: 2
        },
        end: {
          line: 1107,
          column: 26
        }
      },
      '503': {
        start: {
          line: 1108,
          column: 2
        },
        end: {
          line: 1108,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 67
          },
          end: {
            line: 4,
            column: 68
          }
        },
        loc: {
          start: {
            line: 4,
            column: 237
          },
          end: {
            line: 1111,
            column: 1
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 23,
            column: 24
          },
          end: {
            line: 23,
            column: 25
          }
        },
        loc: {
          start: {
            line: 23,
            column: 36
          },
          end: {
            line: 25,
            column: 3
          }
        },
        line: 23
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 28,
            column: 29
          },
          end: {
            line: 28,
            column: 30
          }
        },
        loc: {
          start: {
            line: 28,
            column: 41
          },
          end: {
            line: 30,
            column: 3
          }
        },
        line: 28
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 32,
            column: 30
          },
          end: {
            line: 32,
            column: 31
          }
        },
        loc: {
          start: {
            line: 32,
            column: 47
          },
          end: {
            line: 35,
            column: 3
          }
        },
        line: 32
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 38,
            column: 50
          },
          end: {
            line: 38,
            column: 51
          }
        },
        loc: {
          start: {
            line: 38,
            column: 58
          },
          end: {
            line: 43,
            column: 3
          }
        },
        line: 38
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 58,
            column: 17
          },
          end: {
            line: 58,
            column: 18
          }
        },
        loc: {
          start: {
            line: 58,
            column: 45
          },
          end: {
            line: 68,
            column: 3
          }
        },
        line: 58
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 64,
            column: 17
          },
          end: {
            line: 64,
            column: 18
          }
        },
        loc: {
          start: {
            line: 64,
            column: 25
          },
          end: {
            line: 67,
            column: 5
          }
        },
        line: 64
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 98,
            column: 28
          },
          end: {
            line: 98,
            column: 29
          }
        },
        loc: {
          start: {
            line: 98,
            column: 40
          },
          end: {
            line: 131,
            column: 3
          }
        },
        line: 98
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 132,
            column: 24
          },
          end: {
            line: 132,
            column: 25
          }
        },
        loc: {
          start: {
            line: 132,
            column: 36
          },
          end: {
            line: 156,
            column: 3
          }
        },
        line: 132
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 157,
            column: 22
          },
          end: {
            line: 157,
            column: 23
          }
        },
        loc: {
          start: {
            line: 157,
            column: 34
          },
          end: {
            line: 235,
            column: 3
          }
        },
        line: 157
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 224,
            column: 42
          },
          end: {
            line: 224,
            column: 43
          }
        },
        loc: {
          start: {
            line: 224,
            column: 50
          },
          end: {
            line: 232,
            column: 9
          }
        },
        line: 224
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 237,
            column: 28
          },
          end: {
            line: 237,
            column: 29
          }
        },
        loc: {
          start: {
            line: 237,
            column: 44
          },
          end: {
            line: 250,
            column: 3
          }
        },
        line: 237
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 1027,
            column: 30
          },
          end: {
            line: 1027,
            column: 31
          }
        },
        loc: {
          start: {
            line: 1027,
            column: 42
          },
          end: {
            line: 1029,
            column: 3
          }
        },
        line: 1027
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 1030,
            column: 18
          },
          end: {
            line: 1030,
            column: 19
          }
        },
        loc: {
          start: {
            line: 1030,
            column: 30
          },
          end: {
            line: 1034,
            column: 3
          }
        },
        line: 1030
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 1035,
            column: 25
          },
          end: {
            line: 1035,
            column: 26
          }
        },
        loc: {
          start: {
            line: 1035,
            column: 41
          },
          end: {
            line: 1073,
            column: 3
          }
        },
        line: 1035
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 1039,
            column: 67
          },
          end: {
            line: 1039,
            column: 68
          }
        },
        loc: {
          start: {
            line: 1039,
            column: 75
          },
          end: {
            line: 1072,
            column: 5
          }
        },
        line: 1039
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 1053,
            column: 124
          },
          end: {
            line: 1053,
            column: 125
          }
        },
        loc: {
          start: {
            line: 1053,
            column: 133
          },
          end: {
            line: 1059,
            column: 9
          }
        },
        line: 1053
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 1065,
            column: 112
          },
          end: {
            line: 1065,
            column: 113
          }
        },
        loc: {
          start: {
            line: 1065,
            column: 128
          },
          end: {
            line: 1068,
            column: 7
          }
        },
        line: 1065
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 1074,
            column: 26
          },
          end: {
            line: 1074,
            column: 27
          }
        },
        loc: {
          start: {
            line: 1074,
            column: 38
          },
          end: {
            line: 1090,
            column: 3
          }
        },
        line: 1074
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 1081,
            column: 38
          },
          end: {
            line: 1081,
            column: 39
          }
        },
        loc: {
          start: {
            line: 1081,
            column: 46
          },
          end: {
            line: 1089,
            column: 5
          }
        },
        line: 1081
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 1091,
            column: 25
          },
          end: {
            line: 1091,
            column: 26
          }
        },
        loc: {
          start: {
            line: 1091,
            column: 37
          },
          end: {
            line: 1106,
            column: 3
          }
        },
        line: 1091
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        }, {
          start: {
            line: 7,
            column: 2
          },
          end: {
            line: 9,
            column: 3
          }
        }],
        line: 7
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 69
          },
          end: {
            line: 24,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 24,
            column: 92
          },
          end: {
            line: 24,
            column: 95
          }
        }, {
          start: {
            line: 24,
            column: 98
          },
          end: {
            line: 24,
            column: 101
          }
        }],
        line: 24
      },
      '2': {
        loc: {
          start: {
            line: 29,
            column: 69
          },
          end: {
            line: 29,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 29,
            column: 97
          },
          end: {
            line: 29,
            column: 100
          }
        }, {
          start: {
            line: 29,
            column: 103
          },
          end: {
            line: 29,
            column: 106
          }
        }],
        line: 29
      },
      '3': {
        loc: {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }, {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 42,
            column: 5
          }
        }],
        line: 40
      },
      '4': {
        loc: {
          start: {
            line: 79,
            column: 12
          },
          end: {
            line: 79,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 12
          },
          end: {
            line: 79,
            column: 36
          }
        }, {
          start: {
            line: 79,
            column: 40
          },
          end: {
            line: 79,
            column: 45
          }
        }],
        line: 79
      },
      '5': {
        loc: {
          start: {
            line: 83,
            column: 19
          },
          end: {
            line: 83,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 83,
            column: 19
          },
          end: {
            line: 83,
            column: 52
          }
        }, {
          start: {
            line: 83,
            column: 56
          },
          end: {
            line: 83,
            column: 61
          }
        }],
        line: 83
      },
      '6': {
        loc: {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }, {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 88,
            column: 7
          }
        }],
        line: 86
      },
      '7': {
        loc: {
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        }, {
          start: {
            line: 91,
            column: 8
          },
          end: {
            line: 93,
            column: 9
          }
        }],
        line: 91
      },
      '8': {
        loc: {
          start: {
            line: 91,
            column: 14
          },
          end: {
            line: 91,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 91,
            column: 14
          },
          end: {
            line: 91,
            column: 26
          }
        }, {
          start: {
            line: 91,
            column: 30
          },
          end: {
            line: 91,
            column: 40
          }
        }],
        line: 91
      },
      '9': {
        loc: {
          start: {
            line: 135,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        }, {
          start: {
            line: 135,
            column: 4
          },
          end: {
            line: 138,
            column: 5
          }
        }],
        line: 135
      },
      '10': {
        loc: {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }, {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }],
        line: 142
      },
      '11': {
        loc: {
          start: {
            line: 149,
            column: 180
          },
          end: {
            line: 149,
            column: 207
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 149,
            column: 180
          },
          end: {
            line: 149,
            column: 201
          }
        }, {
          start: {
            line: 149,
            column: 205
          },
          end: {
            line: 149,
            column: 207
          }
        }],
        line: 149
      },
      '12': {
        loc: {
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 155,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 155,
            column: 5
          }
        }, {
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 155,
            column: 5
          }
        }],
        line: 152
      },
      '13': {
        loc: {
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        }, {
          start: {
            line: 158,
            column: 4
          },
          end: {
            line: 234,
            column: 5
          }
        }],
        line: 158
      },
      '14': {
        loc: {
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 208,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 208,
            column: 7
          }
        }, {
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 208,
            column: 7
          }
        }],
        line: 164
      },
      '15': {
        loc: {
          start: {
            line: 165,
            column: 134
          },
          end: {
            line: 165,
            column: 161
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 165,
            column: 134
          },
          end: {
            line: 165,
            column: 155
          }
        }, {
          start: {
            line: 165,
            column: 159
          },
          end: {
            line: 165,
            column: 161
          }
        }],
        line: 165
      },
      '16': {
        loc: {
          start: {
            line: 167,
            column: 8
          },
          end: {
            line: 185,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 8
          },
          end: {
            line: 185,
            column: 9
          }
        }, {
          start: {
            line: 167,
            column: 8
          },
          end: {
            line: 185,
            column: 9
          }
        }],
        line: 167
      },
      '17': {
        loc: {
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 184,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 184,
            column: 11
          }
        }, {
          start: {
            line: 169,
            column: 10
          },
          end: {
            line: 184,
            column: 11
          }
        }],
        line: 169
      },
      '18': {
        loc: {
          start: {
            line: 171,
            column: 14
          },
          end: {
            line: 174,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 171,
            column: 14
          },
          end: {
            line: 174,
            column: 15
          }
        }, {
          start: {
            line: 171,
            column: 14
          },
          end: {
            line: 174,
            column: 15
          }
        }],
        line: 171
      },
      '19': {
        loc: {
          start: {
            line: 177,
            column: 23
          },
          end: {
            line: 177,
            column: 94
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 177,
            column: 23
          },
          end: {
            line: 177,
            column: 89
          }
        }, {
          start: {
            line: 177,
            column: 93
          },
          end: {
            line: 177,
            column: 94
          }
        }],
        line: 177
      },
      '20': {
        loc: {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 182,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 182,
            column: 15
          }
        }, {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 182,
            column: 15
          }
        }],
        line: 179
      },
      '21': {
        loc: {
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        }, {
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        }],
        line: 188
      },
      '22': {
        loc: {
          start: {
            line: 193,
            column: 10
          },
          end: {
            line: 196,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 193,
            column: 10
          },
          end: {
            line: 196,
            column: 11
          }
        }, {
          start: {
            line: 193,
            column: 10
          },
          end: {
            line: 196,
            column: 11
          }
        }],
        line: 193
      },
      '23': {
        loc: {
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 201,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 201,
            column: 9
          }
        }, {
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 201,
            column: 9
          }
        }],
        line: 199
      },
      '24': {
        loc: {
          start: {
            line: 204,
            column: 159
          },
          end: {
            line: 204,
            column: 186
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 204,
            column: 159
          },
          end: {
            line: 204,
            column: 180
          }
        }, {
          start: {
            line: 204,
            column: 184
          },
          end: {
            line: 204,
            column: 186
          }
        }],
        line: 204
      },
      '25': {
        loc: {
          start: {
            line: 209,
            column: 6
          },
          end: {
            line: 233,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 209,
            column: 6
          },
          end: {
            line: 233,
            column: 7
          }
        }, {
          start: {
            line: 209,
            column: 6
          },
          end: {
            line: 233,
            column: 7
          }
        }],
        line: 209
      },
      '26': {
        loc: {
          start: {
            line: 211,
            column: 26
          },
          end: {
            line: 215,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 211,
            column: 56
          },
          end: {
            line: 215,
            column: 9
          }
        }, {
          start: {
            line: 215,
            column: 12
          },
          end: {
            line: 215,
            column: 59
          }
        }],
        line: 211
      },
      '27': {
        loc: {
          start: {
            line: 217,
            column: 90
          },
          end: {
            line: 217,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 217,
            column: 120
          },
          end: {
            line: 217,
            column: 121
          }
        }, {
          start: {
            line: 217,
            column: 124
          },
          end: {
            line: 217,
            column: 125
          }
        }],
        line: 217
      },
      '28': {
        loc: {
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 222,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 222,
            column: 9
          }
        }, {
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 222,
            column: 9
          }
        }],
        line: 218
      },
      '29': {
        loc: {
          start: {
            line: 219,
            column: 10
          },
          end: {
            line: 221,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 219,
            column: 10
          },
          end: {
            line: 221,
            column: 11
          }
        }, {
          start: {
            line: 219,
            column: 10
          },
          end: {
            line: 221,
            column: 11
          }
        }],
        line: 219
      },
      '30': {
        loc: {
          start: {
            line: 238,
            column: 11
          },
          end: {
            line: 238,
            column: 65
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 238,
            column: 23
          },
          end: {
            line: 238,
            column: 31
          }
        }, {
          start: {
            line: 238,
            column: 35
          },
          end: {
            line: 238,
            column: 64
          }
        }],
        line: 238
      },
      '31': {
        loc: {
          start: {
            line: 238,
            column: 35
          },
          end: {
            line: 238,
            column: 64
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 238,
            column: 47
          },
          end: {
            line: 238,
            column: 53
          }
        }, {
          start: {
            line: 238,
            column: 56
          },
          end: {
            line: 238,
            column: 64
          }
        }],
        line: 238
      },
      '32': {
        loc: {
          start: {
            line: 241,
            column: 12
          },
          end: {
            line: 241,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 241,
            column: 111
          },
          end: {
            line: 241,
            column: 116
          }
        }, {
          start: {
            line: 241,
            column: 119
          },
          end: {
            line: 241,
            column: 125
          }
        }],
        line: 241
      },
      '33': {
        loc: {
          start: {
            line: 241,
            column: 45
          },
          end: {
            line: 241,
            column: 99
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 241,
            column: 57
          },
          end: {
            line: 241,
            column: 65
          }
        }, {
          start: {
            line: 241,
            column: 69
          },
          end: {
            line: 241,
            column: 98
          }
        }],
        line: 241
      },
      '34': {
        loc: {
          start: {
            line: 241,
            column: 69
          },
          end: {
            line: 241,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 241,
            column: 81
          },
          end: {
            line: 241,
            column: 87
          }
        }, {
          start: {
            line: 241,
            column: 90
          },
          end: {
            line: 241,
            column: 98
          }
        }],
        line: 241
      },
      '35': {
        loc: {
          start: {
            line: 242,
            column: 45
          },
          end: {
            line: 242,
            column: 99
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 242,
            column: 57
          },
          end: {
            line: 242,
            column: 65
          }
        }, {
          start: {
            line: 242,
            column: 69
          },
          end: {
            line: 242,
            column: 98
          }
        }],
        line: 242
      },
      '36': {
        loc: {
          start: {
            line: 242,
            column: 69
          },
          end: {
            line: 242,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 242,
            column: 81
          },
          end: {
            line: 242,
            column: 87
          }
        }, {
          start: {
            line: 242,
            column: 90
          },
          end: {
            line: 242,
            column: 98
          }
        }],
        line: 242
      },
      '37': {
        loc: {
          start: {
            line: 246,
            column: 12
          },
          end: {
            line: 246,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 246,
            column: 111
          },
          end: {
            line: 246,
            column: 116
          }
        }, {
          start: {
            line: 246,
            column: 119
          },
          end: {
            line: 246,
            column: 125
          }
        }],
        line: 246
      },
      '38': {
        loc: {
          start: {
            line: 246,
            column: 45
          },
          end: {
            line: 246,
            column: 99
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 246,
            column: 57
          },
          end: {
            line: 246,
            column: 65
          }
        }, {
          start: {
            line: 246,
            column: 69
          },
          end: {
            line: 246,
            column: 98
          }
        }],
        line: 246
      },
      '39': {
        loc: {
          start: {
            line: 246,
            column: 69
          },
          end: {
            line: 246,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 246,
            column: 81
          },
          end: {
            line: 246,
            column: 87
          }
        }, {
          start: {
            line: 246,
            column: 90
          },
          end: {
            line: 246,
            column: 98
          }
        }],
        line: 246
      },
      '40': {
        loc: {
          start: {
            line: 247,
            column: 45
          },
          end: {
            line: 247,
            column: 99
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 247,
            column: 57
          },
          end: {
            line: 247,
            column: 65
          }
        }, {
          start: {
            line: 247,
            column: 69
          },
          end: {
            line: 247,
            column: 98
          }
        }],
        line: 247
      },
      '41': {
        loc: {
          start: {
            line: 247,
            column: 69
          },
          end: {
            line: 247,
            column: 98
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 247,
            column: 81
          },
          end: {
            line: 247,
            column: 87
          }
        }, {
          start: {
            line: 247,
            column: 90
          },
          end: {
            line: 247,
            column: 98
          }
        }],
        line: 247
      },
      '42': {
        loc: {
          start: {
            line: 259,
            column: 6
          },
          end: {
            line: 262,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 259,
            column: 6
          },
          end: {
            line: 262,
            column: 7
          }
        }, {
          start: {
            line: 259,
            column: 6
          },
          end: {
            line: 262,
            column: 7
          }
        }],
        line: 259
      },
      '43': {
        loc: {
          start: {
            line: 264,
            column: 6
          },
          end: {
            line: 269,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 264,
            column: 6
          },
          end: {
            line: 269,
            column: 7
          }
        }, {
          start: {
            line: 264,
            column: 6
          },
          end: {
            line: 269,
            column: 7
          }
        }],
        line: 264
      },
      '44': {
        loc: {
          start: {
            line: 279,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 279,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        }, {
          start: {
            line: 279,
            column: 6
          },
          end: {
            line: 281,
            column: 7
          }
        }],
        line: 279
      },
      '45': {
        loc: {
          start: {
            line: 287,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 287,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        }, {
          start: {
            line: 287,
            column: 8
          },
          end: {
            line: 289,
            column: 9
          }
        }],
        line: 287
      },
      '46': {
        loc: {
          start: {
            line: 292,
            column: 8
          },
          end: {
            line: 299,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 292,
            column: 8
          },
          end: {
            line: 299,
            column: 9
          }
        }, {
          start: {
            line: 292,
            column: 8
          },
          end: {
            line: 299,
            column: 9
          }
        }],
        line: 292
      },
      '47': {
        loc: {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 298,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 298,
            column: 11
          }
        }, {
          start: {
            line: 294,
            column: 10
          },
          end: {
            line: 298,
            column: 11
          }
        }],
        line: 294
      },
      '48': {
        loc: {
          start: {
            line: 297,
            column: 24
          },
          end: {
            line: 297,
            column: 194
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 297,
            column: 83
          },
          end: {
            line: 297,
            column: 139
          }
        }, {
          start: {
            line: 297,
            column: 142
          },
          end: {
            line: 297,
            column: 194
          }
        }],
        line: 297
      },
      '49': {
        loc: {
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        }, {
          start: {
            line: 301,
            column: 8
          },
          end: {
            line: 314,
            column: 9
          }
        }],
        line: 301
      },
      '50': {
        loc: {
          start: {
            line: 304,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 304,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }, {
          start: {
            line: 304,
            column: 10
          },
          end: {
            line: 308,
            column: 11
          }
        }],
        line: 304
      },
      '51': {
        loc: {
          start: {
            line: 307,
            column: 39
          },
          end: {
            line: 307,
            column: 209
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 307,
            column: 98
          },
          end: {
            line: 307,
            column: 154
          }
        }, {
          start: {
            line: 307,
            column: 157
          },
          end: {
            line: 307,
            column: 209
          }
        }],
        line: 307
      },
      '52': {
        loc: {
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        }, {
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 313,
            column: 11
          }
        }],
        line: 309
      },
      '53': {
        loc: {
          start: {
            line: 312,
            column: 39
          },
          end: {
            line: 312,
            column: 209
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 312,
            column: 98
          },
          end: {
            line: 312,
            column: 154
          }
        }, {
          start: {
            line: 312,
            column: 157
          },
          end: {
            line: 312,
            column: 209
          }
        }],
        line: 312
      },
      '54': {
        loc: {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }, {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }],
        line: 318
      },
      '55': {
        loc: {
          start: {
            line: 321,
            column: 22
          },
          end: {
            line: 321,
            column: 182
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 321,
            column: 81
          },
          end: {
            line: 321,
            column: 132
          }
        }, {
          start: {
            line: 321,
            column: 135
          },
          end: {
            line: 321,
            column: 182
          }
        }],
        line: 321
      },
      '56': {
        loc: {
          start: {
            line: 376,
            column: 14
          },
          end: {
            line: 376,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 376,
            column: 64
          },
          end: {
            line: 376,
            column: 69
          }
        }, {
          start: {
            line: 376,
            column: 72
          },
          end: {
            line: 376,
            column: 78
          }
        }],
        line: 376
      },
      '57': {
        loc: {
          start: {
            line: 381,
            column: 14
          },
          end: {
            line: 381,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 381,
            column: 64
          },
          end: {
            line: 381,
            column: 69
          }
        }, {
          start: {
            line: 381,
            column: 72
          },
          end: {
            line: 381,
            column: 78
          }
        }],
        line: 381
      },
      '58': {
        loc: {
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        }, {
          start: {
            line: 393,
            column: 6
          },
          end: {
            line: 395,
            column: 7
          }
        }],
        line: 393
      },
      '59': {
        loc: {
          start: {
            line: 396,
            column: 6
          },
          end: {
            line: 398,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 396,
            column: 6
          },
          end: {
            line: 398,
            column: 7
          }
        }, {
          start: {
            line: 396,
            column: 6
          },
          end: {
            line: 398,
            column: 7
          }
        }],
        line: 396
      },
      '60': {
        loc: {
          start: {
            line: 401,
            column: 6
          },
          end: {
            line: 403,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 401,
            column: 6
          },
          end: {
            line: 403,
            column: 7
          }
        }, {
          start: {
            line: 401,
            column: 6
          },
          end: {
            line: 403,
            column: 7
          }
        }],
        line: 401
      },
      '61': {
        loc: {
          start: {
            line: 401,
            column: 10
          },
          end: {
            line: 401,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 401,
            column: 10
          },
          end: {
            line: 401,
            column: 30
          }
        }, {
          start: {
            line: 401,
            column: 34
          },
          end: {
            line: 401,
            column: 57
          }
        }],
        line: 401
      },
      '62': {
        loc: {
          start: {
            line: 404,
            column: 6
          },
          end: {
            line: 406,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 404,
            column: 6
          },
          end: {
            line: 406,
            column: 7
          }
        }, {
          start: {
            line: 404,
            column: 6
          },
          end: {
            line: 406,
            column: 7
          }
        }],
        line: 404
      },
      '63': {
        loc: {
          start: {
            line: 410,
            column: 6
          },
          end: {
            line: 412,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 410,
            column: 6
          },
          end: {
            line: 412,
            column: 7
          }
        }, {
          start: {
            line: 410,
            column: 6
          },
          end: {
            line: 412,
            column: 7
          }
        }],
        line: 410
      },
      '64': {
        loc: {
          start: {
            line: 415,
            column: 6
          },
          end: {
            line: 417,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 415,
            column: 6
          },
          end: {
            line: 417,
            column: 7
          }
        }, {
          start: {
            line: 415,
            column: 6
          },
          end: {
            line: 417,
            column: 7
          }
        }],
        line: 415
      },
      '65': {
        loc: {
          start: {
            line: 415,
            column: 10
          },
          end: {
            line: 415,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 415,
            column: 10
          },
          end: {
            line: 415,
            column: 30
          }
        }, {
          start: {
            line: 415,
            column: 34
          },
          end: {
            line: 415,
            column: 57
          }
        }],
        line: 415
      },
      '66': {
        loc: {
          start: {
            line: 418,
            column: 6
          },
          end: {
            line: 420,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 418,
            column: 6
          },
          end: {
            line: 420,
            column: 7
          }
        }, {
          start: {
            line: 418,
            column: 6
          },
          end: {
            line: 420,
            column: 7
          }
        }],
        line: 418
      },
      '67': {
        loc: {
          start: {
            line: 430,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 430,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        }, {
          start: {
            line: 430,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        }],
        line: 430
      },
      '68': {
        loc: {
          start: {
            line: 441,
            column: 6
          },
          end: {
            line: 443,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 441,
            column: 6
          },
          end: {
            line: 443,
            column: 7
          }
        }, {
          start: {
            line: 441,
            column: 6
          },
          end: {
            line: 443,
            column: 7
          }
        }],
        line: 441
      },
      '69': {
        loc: {
          start: {
            line: 445,
            column: 8
          },
          end: {
            line: 447,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 445,
            column: 8
          },
          end: {
            line: 447,
            column: 9
          }
        }, {
          start: {
            line: 445,
            column: 8
          },
          end: {
            line: 447,
            column: 9
          }
        }],
        line: 445
      },
      '70': {
        loc: {
          start: {
            line: 449,
            column: 8
          },
          end: {
            line: 451,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 449,
            column: 8
          },
          end: {
            line: 451,
            column: 9
          }
        }, {
          start: {
            line: 449,
            column: 8
          },
          end: {
            line: 451,
            column: 9
          }
        }],
        line: 449
      },
      '71': {
        loc: {
          start: {
            line: 458,
            column: 8
          },
          end: {
            line: 468,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 458,
            column: 8
          },
          end: {
            line: 468,
            column: 9
          }
        }, {
          start: {
            line: 458,
            column: 8
          },
          end: {
            line: 468,
            column: 9
          }
        }],
        line: 458
      },
      '72': {
        loc: {
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        }, {
          start: {
            line: 462,
            column: 10
          },
          end: {
            line: 464,
            column: 11
          }
        }],
        line: 462
      },
      '73': {
        loc: {
          start: {
            line: 462,
            column: 14
          },
          end: {
            line: 462,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 462,
            column: 14
          },
          end: {
            line: 462,
            column: 35
          }
        }, {
          start: {
            line: 462,
            column: 39
          },
          end: {
            line: 462,
            column: 59
          }
        }],
        line: 462
      },
      '74': {
        loc: {
          start: {
            line: 465,
            column: 10
          },
          end: {
            line: 467,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 465,
            column: 10
          },
          end: {
            line: 467,
            column: 11
          }
        }, {
          start: {
            line: 465,
            column: 10
          },
          end: {
            line: 467,
            column: 11
          }
        }],
        line: 465
      },
      '75': {
        loc: {
          start: {
            line: 465,
            column: 14
          },
          end: {
            line: 465,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 465,
            column: 14
          },
          end: {
            line: 465,
            column: 24
          }
        }, {
          start: {
            line: 465,
            column: 28
          },
          end: {
            line: 465,
            column: 49
          }
        }],
        line: 465
      },
      '76': {
        loc: {
          start: {
            line: 470,
            column: 8
          },
          end: {
            line: 472,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 470,
            column: 8
          },
          end: {
            line: 472,
            column: 9
          }
        }, {
          start: {
            line: 470,
            column: 8
          },
          end: {
            line: 472,
            column: 9
          }
        }],
        line: 470
      },
      '77': {
        loc: {
          start: {
            line: 470,
            column: 12
          },
          end: {
            line: 470,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 470,
            column: 12
          },
          end: {
            line: 470,
            column: 33
          }
        }, {
          start: {
            line: 470,
            column: 37
          },
          end: {
            line: 470,
            column: 57
          }
        }],
        line: 470
      },
      '78': {
        loc: {
          start: {
            line: 473,
            column: 8
          },
          end: {
            line: 475,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 473,
            column: 8
          },
          end: {
            line: 475,
            column: 9
          }
        }, {
          start: {
            line: 473,
            column: 8
          },
          end: {
            line: 475,
            column: 9
          }
        }],
        line: 473
      },
      '79': {
        loc: {
          start: {
            line: 473,
            column: 12
          },
          end: {
            line: 473,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 473,
            column: 12
          },
          end: {
            line: 473,
            column: 23
          }
        }, {
          start: {
            line: 473,
            column: 27
          },
          end: {
            line: 473,
            column: 47
          }
        }],
        line: 473
      },
      '80': {
        loc: {
          start: {
            line: 476,
            column: 34
          },
          end: {
            line: 476,
            column: 89
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 476,
            column: 58
          },
          end: {
            line: 476,
            column: 82
          }
        }, {
          start: {
            line: 476,
            column: 85
          },
          end: {
            line: 476,
            column: 89
          }
        }],
        line: 476
      },
      '81': {
        loc: {
          start: {
            line: 477,
            column: 34
          },
          end: {
            line: 477,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 477,
            column: 59
          },
          end: {
            line: 477,
            column: 84
          }
        }, {
          start: {
            line: 477,
            column: 87
          },
          end: {
            line: 477,
            column: 91
          }
        }],
        line: 477
      },
      '82': {
        loc: {
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        }, {
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        }],
        line: 493
      },
      '83': {
        loc: {
          start: {
            line: 500,
            column: 6
          },
          end: {
            line: 502,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 500,
            column: 6
          },
          end: {
            line: 502,
            column: 7
          }
        }, {
          start: {
            line: 500,
            column: 6
          },
          end: {
            line: 502,
            column: 7
          }
        }],
        line: 500
      },
      '84': {
        loc: {
          start: {
            line: 512,
            column: 8
          },
          end: {
            line: 514,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 512,
            column: 8
          },
          end: {
            line: 514,
            column: 9
          }
        }, {
          start: {
            line: 512,
            column: 8
          },
          end: {
            line: 514,
            column: 9
          }
        }],
        line: 512
      },
      '85': {
        loc: {
          start: {
            line: 517,
            column: 8
          },
          end: {
            line: 523,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 517,
            column: 8
          },
          end: {
            line: 523,
            column: 9
          }
        }, {
          start: {
            line: 517,
            column: 8
          },
          end: {
            line: 523,
            column: 9
          }
        }],
        line: 517
      },
      '86': {
        loc: {
          start: {
            line: 518,
            column: 10
          },
          end: {
            line: 522,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 518,
            column: 10
          },
          end: {
            line: 522,
            column: 11
          }
        }, {
          start: {
            line: 518,
            column: 10
          },
          end: {
            line: 522,
            column: 11
          }
        }],
        line: 518
      },
      '87': {
        loc: {
          start: {
            line: 521,
            column: 24
          },
          end: {
            line: 521,
            column: 118
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 521,
            column: 59
          },
          end: {
            line: 521,
            column: 89
          }
        }, {
          start: {
            line: 521,
            column: 92
          },
          end: {
            line: 521,
            column: 118
          }
        }],
        line: 521
      },
      '88': {
        loc: {
          start: {
            line: 525,
            column: 8
          },
          end: {
            line: 529,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 525,
            column: 8
          },
          end: {
            line: 529,
            column: 9
          }
        }, {
          start: {
            line: 525,
            column: 8
          },
          end: {
            line: 529,
            column: 9
          }
        }],
        line: 525
      },
      '89': {
        loc: {
          start: {
            line: 528,
            column: 22
          },
          end: {
            line: 528,
            column: 119
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 528,
            column: 57
          },
          end: {
            line: 528,
            column: 89
          }
        }, {
          start: {
            line: 528,
            column: 92
          },
          end: {
            line: 528,
            column: 119
          }
        }],
        line: 528
      },
      '90': {
        loc: {
          start: {
            line: 532,
            column: 10
          },
          end: {
            line: 534,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 532,
            column: 10
          },
          end: {
            line: 534,
            column: 11
          }
        }, {
          start: {
            line: 532,
            column: 10
          },
          end: {
            line: 534,
            column: 11
          }
        }],
        line: 532
      },
      '91': {
        loc: {
          start: {
            line: 532,
            column: 14
          },
          end: {
            line: 532,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 532,
            column: 14
          },
          end: {
            line: 532,
            column: 26
          }
        }, {
          start: {
            line: 532,
            column: 30
          },
          end: {
            line: 532,
            column: 39
          }
        }],
        line: 532
      },
      '92': {
        loc: {
          start: {
            line: 533,
            column: 25
          },
          end: {
            line: 533,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 533,
            column: 25
          },
          end: {
            line: 533,
            column: 43
          }
        }, {
          start: {
            line: 533,
            column: 47
          },
          end: {
            line: 533,
            column: 49
          }
        }],
        line: 533
      },
      '93': {
        loc: {
          start: {
            line: 535,
            column: 10
          },
          end: {
            line: 537,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 535,
            column: 10
          },
          end: {
            line: 537,
            column: 11
          }
        }, {
          start: {
            line: 535,
            column: 10
          },
          end: {
            line: 537,
            column: 11
          }
        }],
        line: 535
      },
      '94': {
        loc: {
          start: {
            line: 535,
            column: 14
          },
          end: {
            line: 535,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 535,
            column: 14
          },
          end: {
            line: 535,
            column: 26
          }
        }, {
          start: {
            line: 535,
            column: 30
          },
          end: {
            line: 535,
            column: 38
          }
        }],
        line: 535
      },
      '95': {
        loc: {
          start: {
            line: 538,
            column: 10
          },
          end: {
            line: 540,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 538,
            column: 10
          },
          end: {
            line: 540,
            column: 11
          }
        }, {
          start: {
            line: 538,
            column: 10
          },
          end: {
            line: 540,
            column: 11
          }
        }],
        line: 538
      },
      '96': {
        loc: {
          start: {
            line: 539,
            column: 25
          },
          end: {
            line: 539,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 539,
            column: 25
          },
          end: {
            line: 539,
            column: 43
          }
        }, {
          start: {
            line: 539,
            column: 47
          },
          end: {
            line: 539,
            column: 49
          }
        }],
        line: 539
      },
      '97': {
        loc: {
          start: {
            line: 542,
            column: 8
          },
          end: {
            line: 544,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 542,
            column: 8
          },
          end: {
            line: 544,
            column: 9
          }
        }, {
          start: {
            line: 542,
            column: 8
          },
          end: {
            line: 544,
            column: 9
          }
        }],
        line: 542
      },
      '98': {
        loc: {
          start: {
            line: 599,
            column: 14
          },
          end: {
            line: 599,
            column: 76
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 599,
            column: 62
          },
          end: {
            line: 599,
            column: 67
          }
        }, {
          start: {
            line: 599,
            column: 70
          },
          end: {
            line: 599,
            column: 76
          }
        }],
        line: 599
      },
      '99': {
        loc: {
          start: {
            line: 604,
            column: 14
          },
          end: {
            line: 604,
            column: 76
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 604,
            column: 62
          },
          end: {
            line: 604,
            column: 67
          }
        }, {
          start: {
            line: 604,
            column: 70
          },
          end: {
            line: 604,
            column: 76
          }
        }],
        line: 604
      },
      '100': {
        loc: {
          start: {
            line: 618,
            column: 8
          },
          end: {
            line: 620,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 618,
            column: 8
          },
          end: {
            line: 620,
            column: 9
          }
        }, {
          start: {
            line: 618,
            column: 8
          },
          end: {
            line: 620,
            column: 9
          }
        }],
        line: 618
      },
      '101': {
        loc: {
          start: {
            line: 628,
            column: 8
          },
          end: {
            line: 630,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 628,
            column: 8
          },
          end: {
            line: 630,
            column: 9
          }
        }, {
          start: {
            line: 628,
            column: 8
          },
          end: {
            line: 630,
            column: 9
          }
        }],
        line: 628
      },
      '102': {
        loc: {
          start: {
            line: 636,
            column: 6
          },
          end: {
            line: 638,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 636,
            column: 6
          },
          end: {
            line: 638,
            column: 7
          }
        }, {
          start: {
            line: 636,
            column: 6
          },
          end: {
            line: 638,
            column: 7
          }
        }],
        line: 636
      },
      '103': {
        loc: {
          start: {
            line: 639,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 639,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        }, {
          start: {
            line: 639,
            column: 6
          },
          end: {
            line: 641,
            column: 7
          }
        }],
        line: 639
      },
      '104': {
        loc: {
          start: {
            line: 645,
            column: 6
          },
          end: {
            line: 647,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 645,
            column: 6
          },
          end: {
            line: 647,
            column: 7
          }
        }, {
          start: {
            line: 645,
            column: 6
          },
          end: {
            line: 647,
            column: 7
          }
        }],
        line: 645
      },
      '105': {
        loc: {
          start: {
            line: 645,
            column: 10
          },
          end: {
            line: 645,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 645,
            column: 10
          },
          end: {
            line: 645,
            column: 30
          }
        }, {
          start: {
            line: 645,
            column: 34
          },
          end: {
            line: 645,
            column: 67
          }
        }],
        line: 645
      },
      '106': {
        loc: {
          start: {
            line: 649,
            column: 6
          },
          end: {
            line: 651,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 649,
            column: 6
          },
          end: {
            line: 651,
            column: 7
          }
        }, {
          start: {
            line: 649,
            column: 6
          },
          end: {
            line: 651,
            column: 7
          }
        }],
        line: 649
      },
      '107': {
        loc: {
          start: {
            line: 655,
            column: 6
          },
          end: {
            line: 657,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 655,
            column: 6
          },
          end: {
            line: 657,
            column: 7
          }
        }, {
          start: {
            line: 655,
            column: 6
          },
          end: {
            line: 657,
            column: 7
          }
        }],
        line: 655
      },
      '108': {
        loc: {
          start: {
            line: 661,
            column: 6
          },
          end: {
            line: 663,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 661,
            column: 6
          },
          end: {
            line: 663,
            column: 7
          }
        }, {
          start: {
            line: 661,
            column: 6
          },
          end: {
            line: 663,
            column: 7
          }
        }],
        line: 661
      },
      '109': {
        loc: {
          start: {
            line: 661,
            column: 10
          },
          end: {
            line: 661,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 661,
            column: 10
          },
          end: {
            line: 661,
            column: 30
          }
        }, {
          start: {
            line: 661,
            column: 34
          },
          end: {
            line: 661,
            column: 67
          }
        }],
        line: 661
      },
      '110': {
        loc: {
          start: {
            line: 665,
            column: 6
          },
          end: {
            line: 667,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 665,
            column: 6
          },
          end: {
            line: 667,
            column: 7
          }
        }, {
          start: {
            line: 665,
            column: 6
          },
          end: {
            line: 667,
            column: 7
          }
        }],
        line: 665
      },
      '111': {
        loc: {
          start: {
            line: 672,
            column: 6
          },
          end: {
            line: 674,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 672,
            column: 6
          },
          end: {
            line: 674,
            column: 7
          }
        }, {
          start: {
            line: 672,
            column: 6
          },
          end: {
            line: 674,
            column: 7
          }
        }],
        line: 672
      },
      '112': {
        loc: {
          start: {
            line: 682,
            column: 10
          },
          end: {
            line: 684,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 682,
            column: 10
          },
          end: {
            line: 684,
            column: 11
          }
        }, {
          start: {
            line: 682,
            column: 10
          },
          end: {
            line: 684,
            column: 11
          }
        }],
        line: 682
      },
      '113': {
        loc: {
          start: {
            line: 686,
            column: 12
          },
          end: {
            line: 688,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 686,
            column: 12
          },
          end: {
            line: 688,
            column: 13
          }
        }, {
          start: {
            line: 686,
            column: 12
          },
          end: {
            line: 688,
            column: 13
          }
        }],
        line: 686
      },
      '114': {
        loc: {
          start: {
            line: 689,
            column: 34
          },
          end: {
            line: 689,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 689,
            column: 56
          },
          end: {
            line: 689,
            column: 87
          }
        }, {
          start: {
            line: 689,
            column: 90
          },
          end: {
            line: 689,
            column: 91
          }
        }],
        line: 689
      },
      '115': {
        loc: {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        }, {
          start: {
            line: 702,
            column: 6
          },
          end: {
            line: 709,
            column: 7
          }
        }],
        line: 702
      },
      '116': {
        loc: {
          start: {
            line: 713,
            column: 6
          },
          end: {
            line: 715,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 713,
            column: 6
          },
          end: {
            line: 715,
            column: 7
          }
        }, {
          start: {
            line: 713,
            column: 6
          },
          end: {
            line: 715,
            column: 7
          }
        }],
        line: 713
      },
      '117': {
        loc: {
          start: {
            line: 717,
            column: 8
          },
          end: {
            line: 719,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 717,
            column: 8
          },
          end: {
            line: 719,
            column: 9
          }
        }, {
          start: {
            line: 717,
            column: 8
          },
          end: {
            line: 719,
            column: 9
          }
        }],
        line: 717
      },
      '118': {
        loc: {
          start: {
            line: 721,
            column: 8
          },
          end: {
            line: 723,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 721,
            column: 8
          },
          end: {
            line: 723,
            column: 9
          }
        }, {
          start: {
            line: 721,
            column: 8
          },
          end: {
            line: 723,
            column: 9
          }
        }],
        line: 721
      },
      '119': {
        loc: {
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 739,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 739,
            column: 9
          }
        }, {
          start: {
            line: 729,
            column: 8
          },
          end: {
            line: 739,
            column: 9
          }
        }],
        line: 729
      },
      '120': {
        loc: {
          start: {
            line: 733,
            column: 10
          },
          end: {
            line: 735,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 733,
            column: 10
          },
          end: {
            line: 735,
            column: 11
          }
        }, {
          start: {
            line: 733,
            column: 10
          },
          end: {
            line: 735,
            column: 11
          }
        }],
        line: 733
      },
      '121': {
        loc: {
          start: {
            line: 733,
            column: 14
          },
          end: {
            line: 733,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 733,
            column: 14
          },
          end: {
            line: 733,
            column: 35
          }
        }, {
          start: {
            line: 733,
            column: 39
          },
          end: {
            line: 733,
            column: 59
          }
        }],
        line: 733
      },
      '122': {
        loc: {
          start: {
            line: 736,
            column: 10
          },
          end: {
            line: 738,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 736,
            column: 10
          },
          end: {
            line: 738,
            column: 11
          }
        }, {
          start: {
            line: 736,
            column: 10
          },
          end: {
            line: 738,
            column: 11
          }
        }],
        line: 736
      },
      '123': {
        loc: {
          start: {
            line: 736,
            column: 14
          },
          end: {
            line: 736,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 736,
            column: 14
          },
          end: {
            line: 736,
            column: 24
          }
        }, {
          start: {
            line: 736,
            column: 28
          },
          end: {
            line: 736,
            column: 50
          }
        }],
        line: 736
      },
      '124': {
        loc: {
          start: {
            line: 741,
            column: 8
          },
          end: {
            line: 743,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 741,
            column: 8
          },
          end: {
            line: 743,
            column: 9
          }
        }, {
          start: {
            line: 741,
            column: 8
          },
          end: {
            line: 743,
            column: 9
          }
        }],
        line: 741
      },
      '125': {
        loc: {
          start: {
            line: 741,
            column: 12
          },
          end: {
            line: 741,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 741,
            column: 12
          },
          end: {
            line: 741,
            column: 33
          }
        }, {
          start: {
            line: 741,
            column: 37
          },
          end: {
            line: 741,
            column: 57
          }
        }],
        line: 741
      },
      '126': {
        loc: {
          start: {
            line: 744,
            column: 8
          },
          end: {
            line: 746,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 744,
            column: 8
          },
          end: {
            line: 746,
            column: 9
          }
        }, {
          start: {
            line: 744,
            column: 8
          },
          end: {
            line: 746,
            column: 9
          }
        }],
        line: 744
      },
      '127': {
        loc: {
          start: {
            line: 744,
            column: 12
          },
          end: {
            line: 744,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 744,
            column: 12
          },
          end: {
            line: 744,
            column: 23
          }
        }, {
          start: {
            line: 744,
            column: 27
          },
          end: {
            line: 744,
            column: 48
          }
        }],
        line: 744
      },
      '128': {
        loc: {
          start: {
            line: 747,
            column: 34
          },
          end: {
            line: 747,
            column: 101
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 747,
            column: 58
          },
          end: {
            line: 747,
            column: 94
          }
        }, {
          start: {
            line: 747,
            column: 97
          },
          end: {
            line: 747,
            column: 101
          }
        }],
        line: 747
      },
      '129': {
        loc: {
          start: {
            line: 748,
            column: 34
          },
          end: {
            line: 748,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 748,
            column: 59
          },
          end: {
            line: 748,
            column: 96
          }
        }, {
          start: {
            line: 748,
            column: 99
          },
          end: {
            line: 748,
            column: 103
          }
        }],
        line: 748
      },
      '130': {
        loc: {
          start: {
            line: 809,
            column: 14
          },
          end: {
            line: 809,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 809,
            column: 64
          },
          end: {
            line: 809,
            column: 69
          }
        }, {
          start: {
            line: 809,
            column: 72
          },
          end: {
            line: 809,
            column: 78
          }
        }],
        line: 809
      },
      '131': {
        loc: {
          start: {
            line: 814,
            column: 14
          },
          end: {
            line: 814,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 814,
            column: 64
          },
          end: {
            line: 814,
            column: 69
          }
        }, {
          start: {
            line: 814,
            column: 72
          },
          end: {
            line: 814,
            column: 78
          }
        }],
        line: 814
      },
      '132': {
        loc: {
          start: {
            line: 821,
            column: 8
          },
          end: {
            line: 823,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 821,
            column: 8
          },
          end: {
            line: 823,
            column: 9
          }
        }, {
          start: {
            line: 821,
            column: 8
          },
          end: {
            line: 823,
            column: 9
          }
        }],
        line: 821
      },
      '133': {
        loc: {
          start: {
            line: 824,
            column: 8
          },
          end: {
            line: 826,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 824,
            column: 8
          },
          end: {
            line: 826,
            column: 9
          }
        }, {
          start: {
            line: 824,
            column: 8
          },
          end: {
            line: 826,
            column: 9
          }
        }],
        line: 824
      },
      '134': {
        loc: {
          start: {
            line: 834,
            column: 8
          },
          end: {
            line: 836,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 834,
            column: 8
          },
          end: {
            line: 836,
            column: 9
          }
        }, {
          start: {
            line: 834,
            column: 8
          },
          end: {
            line: 836,
            column: 9
          }
        }],
        line: 834
      },
      '135': {
        loc: {
          start: {
            line: 837,
            column: 8
          },
          end: {
            line: 839,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 837,
            column: 8
          },
          end: {
            line: 839,
            column: 9
          }
        }, {
          start: {
            line: 837,
            column: 8
          },
          end: {
            line: 839,
            column: 9
          }
        }],
        line: 837
      },
      '136': {
        loc: {
          start: {
            line: 845,
            column: 6
          },
          end: {
            line: 847,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 845,
            column: 6
          },
          end: {
            line: 847,
            column: 7
          }
        }, {
          start: {
            line: 845,
            column: 6
          },
          end: {
            line: 847,
            column: 7
          }
        }],
        line: 845
      },
      '137': {
        loc: {
          start: {
            line: 850,
            column: 6
          },
          end: {
            line: 852,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 850,
            column: 6
          },
          end: {
            line: 852,
            column: 7
          }
        }, {
          start: {
            line: 850,
            column: 6
          },
          end: {
            line: 852,
            column: 7
          }
        }],
        line: 850
      },
      '138': {
        loc: {
          start: {
            line: 850,
            column: 10
          },
          end: {
            line: 850,
            column: 60
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 850,
            column: 10
          },
          end: {
            line: 850,
            column: 30
          }
        }, {
          start: {
            line: 850,
            column: 34
          },
          end: {
            line: 850,
            column: 60
          }
        }],
        line: 850
      },
      '139': {
        loc: {
          start: {
            line: 853,
            column: 6
          },
          end: {
            line: 855,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 853,
            column: 6
          },
          end: {
            line: 855,
            column: 7
          }
        }, {
          start: {
            line: 853,
            column: 6
          },
          end: {
            line: 855,
            column: 7
          }
        }],
        line: 853
      },
      '140': {
        loc: {
          start: {
            line: 861,
            column: 6
          },
          end: {
            line: 863,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 861,
            column: 6
          },
          end: {
            line: 863,
            column: 7
          }
        }, {
          start: {
            line: 861,
            column: 6
          },
          end: {
            line: 863,
            column: 7
          }
        }],
        line: 861
      },
      '141': {
        loc: {
          start: {
            line: 861,
            column: 10
          },
          end: {
            line: 861,
            column: 60
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 861,
            column: 10
          },
          end: {
            line: 861,
            column: 30
          }
        }, {
          start: {
            line: 861,
            column: 34
          },
          end: {
            line: 861,
            column: 60
          }
        }],
        line: 861
      },
      '142': {
        loc: {
          start: {
            line: 865,
            column: 6
          },
          end: {
            line: 867,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 865,
            column: 6
          },
          end: {
            line: 867,
            column: 7
          }
        }, {
          start: {
            line: 865,
            column: 6
          },
          end: {
            line: 867,
            column: 7
          }
        }],
        line: 865
      },
      '143': {
        loc: {
          start: {
            line: 874,
            column: 6
          },
          end: {
            line: 876,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 874,
            column: 6
          },
          end: {
            line: 876,
            column: 7
          }
        }, {
          start: {
            line: 874,
            column: 6
          },
          end: {
            line: 876,
            column: 7
          }
        }],
        line: 874
      },
      '144': {
        loc: {
          start: {
            line: 882,
            column: 6
          },
          end: {
            line: 884,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 882,
            column: 6
          },
          end: {
            line: 884,
            column: 7
          }
        }, {
          start: {
            line: 882,
            column: 6
          },
          end: {
            line: 884,
            column: 7
          }
        }],
        line: 882
      },
      '145': {
        loc: {
          start: {
            line: 896,
            column: 8
          },
          end: {
            line: 902,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 896,
            column: 8
          },
          end: {
            line: 902,
            column: 9
          }
        }, {
          start: {
            line: 896,
            column: 8
          },
          end: {
            line: 902,
            column: 9
          }
        }],
        line: 896
      },
      '146': {
        loc: {
          start: {
            line: 897,
            column: 10
          },
          end: {
            line: 901,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 897,
            column: 10
          },
          end: {
            line: 901,
            column: 11
          }
        }, {
          start: {
            line: 897,
            column: 10
          },
          end: {
            line: 901,
            column: 11
          }
        }],
        line: 897
      },
      '147': {
        loc: {
          start: {
            line: 900,
            column: 24
          },
          end: {
            line: 900,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 900,
            column: 52
          },
          end: {
            line: 900,
            column: 78
          }
        }, {
          start: {
            line: 900,
            column: 81
          },
          end: {
            line: 900,
            column: 103
          }
        }],
        line: 900
      },
      '148': {
        loc: {
          start: {
            line: 904,
            column: 8
          },
          end: {
            line: 908,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 904,
            column: 8
          },
          end: {
            line: 908,
            column: 9
          }
        }, {
          start: {
            line: 904,
            column: 8
          },
          end: {
            line: 908,
            column: 9
          }
        }],
        line: 904
      },
      '149': {
        loc: {
          start: {
            line: 907,
            column: 22
          },
          end: {
            line: 907,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 907,
            column: 50
          },
          end: {
            line: 907,
            column: 77
          }
        }, {
          start: {
            line: 907,
            column: 80
          },
          end: {
            line: 907,
            column: 103
          }
        }],
        line: 907
      },
      '150': {
        loc: {
          start: {
            line: 911,
            column: 10
          },
          end: {
            line: 913,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 911,
            column: 10
          },
          end: {
            line: 913,
            column: 11
          }
        }, {
          start: {
            line: 911,
            column: 10
          },
          end: {
            line: 913,
            column: 11
          }
        }],
        line: 911
      },
      '151': {
        loc: {
          start: {
            line: 911,
            column: 14
          },
          end: {
            line: 911,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 911,
            column: 14
          },
          end: {
            line: 911,
            column: 26
          }
        }, {
          start: {
            line: 911,
            column: 30
          },
          end: {
            line: 911,
            column: 39
          }
        }],
        line: 911
      },
      '152': {
        loc: {
          start: {
            line: 912,
            column: 25
          },
          end: {
            line: 912,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 912,
            column: 25
          },
          end: {
            line: 912,
            column: 43
          }
        }, {
          start: {
            line: 912,
            column: 47
          },
          end: {
            line: 912,
            column: 49
          }
        }],
        line: 912
      },
      '153': {
        loc: {
          start: {
            line: 914,
            column: 10
          },
          end: {
            line: 916,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 914,
            column: 10
          },
          end: {
            line: 916,
            column: 11
          }
        }, {
          start: {
            line: 914,
            column: 10
          },
          end: {
            line: 916,
            column: 11
          }
        }],
        line: 914
      },
      '154': {
        loc: {
          start: {
            line: 914,
            column: 14
          },
          end: {
            line: 914,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 914,
            column: 14
          },
          end: {
            line: 914,
            column: 26
          }
        }, {
          start: {
            line: 914,
            column: 30
          },
          end: {
            line: 914,
            column: 38
          }
        }],
        line: 914
      },
      '155': {
        loc: {
          start: {
            line: 915,
            column: 25
          },
          end: {
            line: 915,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 915,
            column: 25
          },
          end: {
            line: 915,
            column: 43
          }
        }, {
          start: {
            line: 915,
            column: 47
          },
          end: {
            line: 915,
            column: 49
          }
        }],
        line: 915
      },
      '156': {
        loc: {
          start: {
            line: 917,
            column: 10
          },
          end: {
            line: 919,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 917,
            column: 10
          },
          end: {
            line: 919,
            column: 11
          }
        }, {
          start: {
            line: 917,
            column: 10
          },
          end: {
            line: 919,
            column: 11
          }
        }],
        line: 917
      },
      '157': {
        loc: {
          start: {
            line: 918,
            column: 25
          },
          end: {
            line: 918,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 918,
            column: 25
          },
          end: {
            line: 918,
            column: 43
          }
        }, {
          start: {
            line: 918,
            column: 47
          },
          end: {
            line: 918,
            column: 49
          }
        }],
        line: 918
      },
      '158': {
        loc: {
          start: {
            line: 921,
            column: 8
          },
          end: {
            line: 923,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 921,
            column: 8
          },
          end: {
            line: 923,
            column: 9
          }
        }, {
          start: {
            line: 921,
            column: 8
          },
          end: {
            line: 923,
            column: 9
          }
        }],
        line: 921
      },
      '159': {
        loc: {
          start: {
            line: 941,
            column: 6
          },
          end: {
            line: 943,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 941,
            column: 6
          },
          end: {
            line: 943,
            column: 7
          }
        }, {
          start: {
            line: 941,
            column: 6
          },
          end: {
            line: 943,
            column: 7
          }
        }],
        line: 941
      },
      '160': {
        loc: {
          start: {
            line: 951,
            column: 10
          },
          end: {
            line: 953,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 951,
            column: 10
          },
          end: {
            line: 953,
            column: 11
          }
        }, {
          start: {
            line: 951,
            column: 10
          },
          end: {
            line: 953,
            column: 11
          }
        }],
        line: 951
      },
      '161': {
        loc: {
          start: {
            line: 955,
            column: 12
          },
          end: {
            line: 957,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 955,
            column: 12
          },
          end: {
            line: 957,
            column: 13
          }
        }, {
          start: {
            line: 955,
            column: 12
          },
          end: {
            line: 957,
            column: 13
          }
        }],
        line: 955
      },
      '162': {
        loc: {
          start: {
            line: 958,
            column: 34
          },
          end: {
            line: 958,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 958,
            column: 56
          },
          end: {
            line: 958,
            column: 87
          }
        }, {
          start: {
            line: 958,
            column: 90
          },
          end: {
            line: 958,
            column: 91
          }
        }],
        line: 958
      },
      '163': {
        loc: {
          start: {
            line: 1046,
            column: 32
          },
          end: {
            line: 1046,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1046,
            column: 32
          },
          end: {
            line: 1046,
            column: 73
          }
        }, {
          start: {
            line: 1046,
            column: 77
          },
          end: {
            line: 1046,
            column: 80
          }
        }],
        line: 1046
      },
      '164': {
        loc: {
          start: {
            line: 1053,
            column: 84
          },
          end: {
            line: 1053,
            column: 111
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1053,
            column: 84
          },
          end: {
            line: 1053,
            column: 105
          }
        }, {
          start: {
            line: 1053,
            column: 109
          },
          end: {
            line: 1053,
            column: 111
          }
        }],
        line: 1053
      },
      '165': {
        loc: {
          start: {
            line: 1075,
            column: 51
          },
          end: {
            line: 1075,
            column: 86
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 1075,
            column: 81
          },
          end: {
            line: 1075,
            column: 82
          }
        }, {
          start: {
            line: 1075,
            column: 85
          },
          end: {
            line: 1075,
            column: 86
          }
        }],
        line: 1075
      },
      '166': {
        loc: {
          start: {
            line: 1076,
            column: 4
          },
          end: {
            line: 1080,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1076,
            column: 4
          },
          end: {
            line: 1080,
            column: 5
          }
        }, {
          start: {
            line: 1076,
            column: 4
          },
          end: {
            line: 1080,
            column: 5
          }
        }],
        line: 1076
      },
      '167': {
        loc: {
          start: {
            line: 1077,
            column: 6
          },
          end: {
            line: 1079,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1077,
            column: 6
          },
          end: {
            line: 1079,
            column: 7
          }
        }, {
          start: {
            line: 1077,
            column: 6
          },
          end: {
            line: 1079,
            column: 7
          }
        }],
        line: 1077
      },
      '168': {
        loc: {
          start: {
            line: 1092,
            column: 4
          },
          end: {
            line: 1094,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 1092,
            column: 4
          },
          end: {
            line: 1094,
            column: 5
          }
        }, {
          start: {
            line: 1092,
            column: 4
          },
          end: {
            line: 1094,
            column: 5
          }
        }],
        line: 1092
      },
      '169': {
        loc: {
          start: {
            line: 1092,
            column: 8
          },
          end: {
            line: 1092,
            column: 73
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 1092,
            column: 8
          },
          end: {
            line: 1092,
            column: 44
          }
        }, {
          start: {
            line: 1092,
            column: 48
          },
          end: {
            line: 1092,
            column: 73
          }
        }],
        line: 1092
      },
      '170': {
        loc: {
          start: {
            line: 1096,
            column: 6
          },
          end: {
            line: 1096,
            column: 85
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 1096,
            column: 40
          },
          end: {
            line: 1096,
            column: 58
          }
        }, {
          start: {
            line: 1096,
            column: 61
          },
          end: {
            line: 1096,
            column: 85
          }
        }],
        line: 1096
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0,
      '259': 0,
      '260': 0,
      '261': 0,
      '262': 0,
      '263': 0,
      '264': 0,
      '265': 0,
      '266': 0,
      '267': 0,
      '268': 0,
      '269': 0,
      '270': 0,
      '271': 0,
      '272': 0,
      '273': 0,
      '274': 0,
      '275': 0,
      '276': 0,
      '277': 0,
      '278': 0,
      '279': 0,
      '280': 0,
      '281': 0,
      '282': 0,
      '283': 0,
      '284': 0,
      '285': 0,
      '286': 0,
      '287': 0,
      '288': 0,
      '289': 0,
      '290': 0,
      '291': 0,
      '292': 0,
      '293': 0,
      '294': 0,
      '295': 0,
      '296': 0,
      '297': 0,
      '298': 0,
      '299': 0,
      '300': 0,
      '301': 0,
      '302': 0,
      '303': 0,
      '304': 0,
      '305': 0,
      '306': 0,
      '307': 0,
      '308': 0,
      '309': 0,
      '310': 0,
      '311': 0,
      '312': 0,
      '313': 0,
      '314': 0,
      '315': 0,
      '316': 0,
      '317': 0,
      '318': 0,
      '319': 0,
      '320': 0,
      '321': 0,
      '322': 0,
      '323': 0,
      '324': 0,
      '325': 0,
      '326': 0,
      '327': 0,
      '328': 0,
      '329': 0,
      '330': 0,
      '331': 0,
      '332': 0,
      '333': 0,
      '334': 0,
      '335': 0,
      '336': 0,
      '337': 0,
      '338': 0,
      '339': 0,
      '340': 0,
      '341': 0,
      '342': 0,
      '343': 0,
      '344': 0,
      '345': 0,
      '346': 0,
      '347': 0,
      '348': 0,
      '349': 0,
      '350': 0,
      '351': 0,
      '352': 0,
      '353': 0,
      '354': 0,
      '355': 0,
      '356': 0,
      '357': 0,
      '358': 0,
      '359': 0,
      '360': 0,
      '361': 0,
      '362': 0,
      '363': 0,
      '364': 0,
      '365': 0,
      '366': 0,
      '367': 0,
      '368': 0,
      '369': 0,
      '370': 0,
      '371': 0,
      '372': 0,
      '373': 0,
      '374': 0,
      '375': 0,
      '376': 0,
      '377': 0,
      '378': 0,
      '379': 0,
      '380': 0,
      '381': 0,
      '382': 0,
      '383': 0,
      '384': 0,
      '385': 0,
      '386': 0,
      '387': 0,
      '388': 0,
      '389': 0,
      '390': 0,
      '391': 0,
      '392': 0,
      '393': 0,
      '394': 0,
      '395': 0,
      '396': 0,
      '397': 0,
      '398': 0,
      '399': 0,
      '400': 0,
      '401': 0,
      '402': 0,
      '403': 0,
      '404': 0,
      '405': 0,
      '406': 0,
      '407': 0,
      '408': 0,
      '409': 0,
      '410': 0,
      '411': 0,
      '412': 0,
      '413': 0,
      '414': 0,
      '415': 0,
      '416': 0,
      '417': 0,
      '418': 0,
      '419': 0,
      '420': 0,
      '421': 0,
      '422': 0,
      '423': 0,
      '424': 0,
      '425': 0,
      '426': 0,
      '427': 0,
      '428': 0,
      '429': 0,
      '430': 0,
      '431': 0,
      '432': 0,
      '433': 0,
      '434': 0,
      '435': 0,
      '436': 0,
      '437': 0,
      '438': 0,
      '439': 0,
      '440': 0,
      '441': 0,
      '442': 0,
      '443': 0,
      '444': 0,
      '445': 0,
      '446': 0,
      '447': 0,
      '448': 0,
      '449': 0,
      '450': 0,
      '451': 0,
      '452': 0,
      '453': 0,
      '454': 0,
      '455': 0,
      '456': 0,
      '457': 0,
      '458': 0,
      '459': 0,
      '460': 0,
      '461': 0,
      '462': 0,
      '463': 0,
      '464': 0,
      '465': 0,
      '466': 0,
      '467': 0,
      '468': 0,
      '469': 0,
      '470': 0,
      '471': 0,
      '472': 0,
      '473': 0,
      '474': 0,
      '475': 0,
      '476': 0,
      '477': 0,
      '478': 0,
      '479': 0,
      '480': 0,
      '481': 0,
      '482': 0,
      '483': 0,
      '484': 0,
      '485': 0,
      '486': 0,
      '487': 0,
      '488': 0,
      '489': 0,
      '490': 0,
      '491': 0,
      '492': 0,
      '493': 0,
      '494': 0,
      '495': 0,
      '496': 0,
      '497': 0,
      '498': 0,
      '499': 0,
      '500': 0,
      '501': 0,
      '502': 0,
      '503': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0],
      '85': [0, 0],
      '86': [0, 0],
      '87': [0, 0],
      '88': [0, 0],
      '89': [0, 0],
      '90': [0, 0],
      '91': [0, 0],
      '92': [0, 0],
      '93': [0, 0],
      '94': [0, 0],
      '95': [0, 0],
      '96': [0, 0],
      '97': [0, 0],
      '98': [0, 0],
      '99': [0, 0],
      '100': [0, 0],
      '101': [0, 0],
      '102': [0, 0],
      '103': [0, 0],
      '104': [0, 0],
      '105': [0, 0],
      '106': [0, 0],
      '107': [0, 0],
      '108': [0, 0],
      '109': [0, 0],
      '110': [0, 0],
      '111': [0, 0],
      '112': [0, 0],
      '113': [0, 0],
      '114': [0, 0],
      '115': [0, 0],
      '116': [0, 0],
      '117': [0, 0],
      '118': [0, 0],
      '119': [0, 0],
      '120': [0, 0],
      '121': [0, 0],
      '122': [0, 0],
      '123': [0, 0],
      '124': [0, 0],
      '125': [0, 0],
      '126': [0, 0],
      '127': [0, 0],
      '128': [0, 0],
      '129': [0, 0],
      '130': [0, 0],
      '131': [0, 0],
      '132': [0, 0],
      '133': [0, 0],
      '134': [0, 0],
      '135': [0, 0],
      '136': [0, 0],
      '137': [0, 0],
      '138': [0, 0],
      '139': [0, 0],
      '140': [0, 0],
      '141': [0, 0],
      '142': [0, 0],
      '143': [0, 0],
      '144': [0, 0],
      '145': [0, 0],
      '146': [0, 0],
      '147': [0, 0],
      '148': [0, 0],
      '149': [0, 0],
      '150': [0, 0],
      '151': [0, 0],
      '152': [0, 0],
      '153': [0, 0],
      '154': [0, 0],
      '155': [0, 0],
      '156': [0, 0],
      '157': [0, 0],
      '158': [0, 0],
      '159': [0, 0],
      '160': [0, 0],
      '161': [0, 0],
      '162': [0, 0],
      '163': [0, 0],
      '164': [0, 0],
      '165': [0, 0],
      '166': [0, 0],
      '167': [0, 0],
      '168': [0, 0],
      '169': [0, 0],
      '170': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_12ec53qceg.s[0]++;
/**
 * Activity  - controller
 */
angular.module('app').controller('ActivityPortfolioDashboardCtrl', function ($scope, $deltaGeoLevel, $rootScope, $stateParams, $state, $translate, $CRUDService, $deltahttp, $deltadate, $window, $deltaPeriode, $filter, $deltaNumber, $document) {
  cov_12ec53qceg.f[0]++;
  cov_12ec53qceg.s[1]++;

  // const PATHG="Planification";
  // const PATHA="PlanificationAnnuel";
  if (!$stateParams.params) {
    cov_12ec53qceg.b[0][0]++;
    cov_12ec53qceg.s[2]++;

    $stateParams.params = {};
  } else {
    cov_12ec53qceg.b[0][1]++;
  }
  var PATHG = (cov_12ec53qceg.s[3]++, 'Planification');
  var PATHP = (cov_12ec53qceg.s[4]++, 'Relever_B_Niv');
  var PATHPER = (cov_12ec53qceg.s[5]++, 'Periode');
  var PATH = (cov_12ec53qceg.s[6]++, 'DernierNiveau');
  var PATH__ = (cov_12ec53qceg.s[7]++, 'ActivityCross');
  var KEY_YEAR_BASE = (cov_12ec53qceg.s[8]++, 'a_ov_d_y_');
  var KEY_PERIOD_BASE = (cov_12ec53qceg.s[9]++, 'a_ov_d_p_');
  var KEY_COST_BASE = (cov_12ec53qceg.s[10]++, 'a_ov_d_c_');
  var KEY_TYPE_BASE = (cov_12ec53qceg.s[11]++, 'a_ov_d_t_');
  var KEY_TAB_BASE = (cov_12ec53qceg.s[12]++, 'a_ov_d_tab_');
  cov_12ec53qceg.s[13]++;
  $scope.firstTabLabel = $translate.instant('ACTIVITY.PLAN.LOCATION');
  cov_12ec53qceg.s[14]++;
  $scope.tab_dashboard = 1;
  cov_12ec53qceg.s[15]++;
  $scope.TYPESUIVI = false;
  cov_12ec53qceg.s[16]++;
  $scope.changeReview = function () {
    cov_12ec53qceg.f[1]++;
    cov_12ec53qceg.s[17]++;

    localStorage.setItem(KEY_TYPE_BASE + $scope.selectedActivity.id, $scope.choise.revise ? (cov_12ec53qceg.b[1][0]++, '1') : (cov_12ec53qceg.b[1][1]++, '0'));
  };
  cov_12ec53qceg.s[18]++;
  $scope.is_loading_report_panel = false;
  cov_12ec53qceg.s[19]++;
  $scope.canDoGlobalPlaning = true;
  cov_12ec53qceg.s[20]++;
  $scope.changeCostDisplay = function () {
    cov_12ec53qceg.f[2]++;
    cov_12ec53qceg.s[21]++;

    localStorage.setItem(KEY_COST_BASE + $scope.selectedActivity.id, $scope.global.displaycost ? (cov_12ec53qceg.b[2][0]++, '1') : (cov_12ec53qceg.b[2][1]++, '0'));
  };

  cov_12ec53qceg.s[22]++;
  $scope.changeDashboardTab = function (index) {
    cov_12ec53qceg.f[3]++;
    cov_12ec53qceg.s[23]++;

    $scope.tab_dashboard = index;
    cov_12ec53qceg.s[24]++;
    localStorage.setItem(KEY_TAB_BASE + $scope.selectedActivity.id, $scope.tab_dashboard);
  };

  cov_12ec53qceg.s[25]++;
  $scope.indexPlaning = 0;
  cov_12ec53qceg.s[26]++;
  $CRUDService.getAll(PATHG, { get: 'plan_state' }, function (data) {
    cov_12ec53qceg.f[4]++;
    cov_12ec53qceg.s[27]++;

    $scope.indexPlaning = parseInt(data, 10);
    cov_12ec53qceg.s[28]++;
    if ($scope.indexPlaning > 1) {
      cov_12ec53qceg.b[3][0]++;
      cov_12ec53qceg.s[29]++;

      $scope.tab_dashboard = 2;
    } else {
      cov_12ec53qceg.b[3][1]++;
    }
  });

  cov_12ec53qceg.s[30]++;
  $scope.labels = {
    period: {
      plan: {
        DB: $translate.instant('ACTIVITY.PLAN.BEGIN_PLANNED'),
        FB: $translate.instant('ACTIVITY.PLAN.END_PLANNED')
      },
      real: {
        DR: $translate.instant('ACTIVITY.REAL.BEGIN_ACTUAL'),
        FR: $translate.instant('ACTIVITY.REAL.END_ACTUAL')
      }
    }
  };
  cov_12ec53qceg.s[31]++;
  $scope.initialVariable = true;
  cov_12ec53qceg.s[32]++;
  $scope.print = function (typesuvi, printt) {
    cov_12ec53qceg.f[5]++;
    cov_12ec53qceg.s[33]++;

    $scope.indexLoading = 0;
    cov_12ec53qceg.s[34]++;
    $CRUDService.save(PATHP, { action: 'print_dashboard_global', data: $filter('json')([$scope.global.print(typesuvi), $scope.year.print(typesuvi), $scope.period.print(typesuvi)]), printt: printt }, function (data) {
      cov_12ec53qceg.f[6]++;
      cov_12ec53qceg.s[35]++;

      $scope.indexLoading = 1;
      cov_12ec53qceg.s[36]++;
      $window.open($deltahttp.getReporting(data), '_blank');
    });
  };
  cov_12ec53qceg.s[37]++;
  $scope.headerTab = ['', '', ''
  // $translate.instant('ACTIVITY.DASHBOARD.PROJECT') + ' ' + $deltadate.format($rootScope.currentProject.project.DATE_DEB_PROJET, 'yyyy') + '-' + $deltadate.format($rootScope.currentProject.project.DATE_FIN_PROJET, 'yyyy')
  ];
  cov_12ec53qceg.s[38]++;
  $scope.choise = {
    year: null,
    period: null,
    listheader: [],
    revise: (cov_12ec53qceg.b[4][0]++, $stateParams.params.type) || (cov_12ec53qceg.b[4][1]++, false),
    listheaderGlobal: [],
    labelPeriod: '',
    labelYear: '',
    validatedData: (cov_12ec53qceg.b[5][0]++, $stateParams.params.validatedData) || (cov_12ec53qceg.b[5][1]++, false),
    getHeaderNumber: function getHeaderNumber(type, financial) {
      var table = (cov_12ec53qceg.s[39]++, []);
      cov_12ec53qceg.s[40]++;
      if (type == 2) {
        cov_12ec53qceg.b[6][0]++;
        cov_12ec53qceg.s[41]++;

        table = this.listheader;
      } else {
        cov_12ec53qceg.b[6][1]++;
      }
      var nbr = (cov_12ec53qceg.s[42]++, 0);
      cov_12ec53qceg.s[43]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = table[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_12ec53qceg.s[44]++;

          if (!((cov_12ec53qceg.b[8][0]++, el.type == 2) && (cov_12ec53qceg.b[8][1]++, !financial))) {
            cov_12ec53qceg.b[7][0]++;
            cov_12ec53qceg.s[45]++;

            nbr++;
          } else {
            cov_12ec53qceg.b[7][1]++;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_12ec53qceg.s[46]++;
      return nbr;
    }
  };
  cov_12ec53qceg.s[47]++;
  $scope.transformSharing = function () {
    cov_12ec53qceg.f[7]++;
    cov_12ec53qceg.s[48]++;

    /*  .state('activity.planning.overview', {
          url: '/:id/overview/',
          templateUrl: 'app/views/activities/overview/overview.html',
          data: {indexTrad: 'MENU.OVERVIEW_ACTIVITY'},
          params: {
            id: null,
            params: {},
            origin: ''
          }
        }) */

    return {
      origin: 'activity.planning.overview',
      id: $scope.selectedActivity.id,
      params: {
        item: $scope.selectedActivity,
        type: $scope.choise.revise,
        validatedData: $scope.choise.validatedData,
        period: $scope.choise.period.index,
        year: $scope.choise.year
      }
    }; /*
       return {
       origin: 'activity.planning.dashboard',
       id: $scope.selectedActivity.id,
       params: {
        type: $scope.choise.revise,
        validatedData: $scope.choise.validatedData,
        period: $scope.choise.period.index,
        year: $scope.choise.year
       }
       }; */
  };
  cov_12ec53qceg.s[49]++;
  $scope.changePeriod = function () {
    cov_12ec53qceg.f[8]++;
    cov_12ec53qceg.s[50]++;

    $scope.period.graphe.labels = [];
    cov_12ec53qceg.s[51]++;
    $scope.period.graphe.data = [[], []];
    cov_12ec53qceg.s[52]++;
    if (!$scope.choise.period) {
      cov_12ec53qceg.b[9][0]++;
      cov_12ec53qceg.s[53]++;

      $scope.headerTab[0] = $translate.instant('ACTIVITY.PLAN.PERIOD');
      cov_12ec53qceg.s[54]++;
      return;
    } else {
      cov_12ec53qceg.b[9][1]++;
    }
    cov_12ec53qceg.s[55]++;
    localStorage.setItem(KEY_PERIOD_BASE + $scope.selectedActivity.id, $scope.choise.period.index);
    cov_12ec53qceg.s[56]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.annualData[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var el = _step2.value;

        var item = (cov_12ec53qceg.s[57]++, el.data[$scope.choise.period.index]);
        cov_12ec53qceg.s[58]++;
        if (!item) {
          cov_12ec53qceg.b[10][0]++;
          cov_12ec53qceg.s[59]++;

          continue;
        } else {
          cov_12ec53qceg.b[10][1]++;
        }
        cov_12ec53qceg.s[60]++;
        $scope.period.graphe.labels.push(el.D);
        cov_12ec53qceg.s[61]++;
        $scope.period.graphe.data[0].push(Math.round($scope.period.calculateAmountRate(item)));
        cov_12ec53qceg.s[62]++;
        $scope.period.graphe.data[1].push(Math.round($scope.period.calculateCostRate(item)));
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    cov_12ec53qceg.s[63]++;
    $scope.choise.labelPeriod = $deltadate.getLabelPerioddepth($scope.choise.period.index * (12 / $scope.choise.year.DECOUPAGE_PERIOD), 12, true, $scope.choise.year.ANNEE_PERIOD, ((cov_12ec53qceg.b[11][0]++, $scope.choise.project) || (cov_12ec53qceg.b[11][1]++, {})).START);

    cov_12ec53qceg.s[64]++;
    $scope.headerTab[0] = $scope.choise.period.label + ' - ' + $scope.choise.year.ANNEE_PERIOD;
    cov_12ec53qceg.s[65]++;
    if ($scope.choise.year.DECOUPAGE_PERIOD == 1) {
      cov_12ec53qceg.b[12][0]++;
      cov_12ec53qceg.s[66]++;

      $scope.choise.labelPeriod = '';
      cov_12ec53qceg.s[67]++;
      $scope.headerTab[0] = $scope.headerTab[1];
    } else {
      cov_12ec53qceg.b[12][1]++;
    }
  };
  cov_12ec53qceg.s[68]++;
  $scope.changeYear = function () {
    cov_12ec53qceg.f[9]++;
    cov_12ec53qceg.s[69]++;

    if (this.choise.year) {
      cov_12ec53qceg.b[13][0]++;
      cov_12ec53qceg.s[70]++;

      localStorage.setItem(KEY_YEAR_BASE + $stateParams.id, $scope.choise.year.id);
      cov_12ec53qceg.s[71]++;
      $scope.headerTab[1] = $translate.instant('ACTIVITY.DASHBOARD.YEAR') + ' ' + $scope.choise.year.ANNEE_PERIOD;
      cov_12ec53qceg.s[72]++;
      $scope.choise.labelYear = $scope.choise.year.ANNEE_PERIOD;
      cov_12ec53qceg.s[73]++;
      $scope.choise.listheader = [];
      cov_12ec53qceg.s[74]++;
      $scope.listSubPeriod = [];
      cov_12ec53qceg.s[75]++;
      if ($scope.choise.year.DECOUPAGE_PERIOD > 0) {
        cov_12ec53qceg.b[14][0]++;
        cov_12ec53qceg.s[76]++;

        $scope.listSubPeriod = $deltadate.getYearPeriod($scope.choise.year.ANNEE_PERIOD, $scope.choise.year.DECOUPAGE_PERIOD, false, ((cov_12ec53qceg.b[15][0]++, $scope.choise.project) || (cov_12ec53qceg.b[15][1]++, {})).START);

        cov_12ec53qceg.s[77]++;
        if ($scope.initialVariable) {
          cov_12ec53qceg.b[16][0]++;
          cov_12ec53qceg.s[78]++;

          $scope.choise.period = null;
          cov_12ec53qceg.s[79]++;
          if ($stateParams.params.period != null) {
            cov_12ec53qceg.b[17][0]++;
            cov_12ec53qceg.s[80]++;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = $scope.listSubPeriod[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var el = _step3.value;
                cov_12ec53qceg.s[81]++;

                if (el.index == $stateParams.params.period) {
                  cov_12ec53qceg.b[18][0]++;
                  cov_12ec53qceg.s[82]++;

                  $scope.choise.period = el;
                  cov_12ec53qceg.s[83]++;
                  break;
                } else {
                  cov_12ec53qceg.b[18][1]++;
                }
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }
          } else {
            cov_12ec53qceg.b[17][1]++;

            var id = (cov_12ec53qceg.s[84]++, (cov_12ec53qceg.b[19][0]++, localStorage.getItem(KEY_PERIOD_BASE + $scope.selectedActivity.id)) || (cov_12ec53qceg.b[19][1]++, 0));
            cov_12ec53qceg.s[85]++;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = $scope.listSubPeriod[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var _el = _step4.value;
                cov_12ec53qceg.s[86]++;

                if (_el.index == id) {
                  cov_12ec53qceg.b[20][0]++;
                  cov_12ec53qceg.s[87]++;

                  $scope.choise.period = _el;
                  cov_12ec53qceg.s[88]++;
                  break;
                } else {
                  cov_12ec53qceg.b[20][1]++;
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }
          }
        } else {
          cov_12ec53qceg.b[16][1]++;
        }

        var idp = (cov_12ec53qceg.s[89]++, 0);
        cov_12ec53qceg.s[90]++;
        if ($scope.choise.period != null) {
          cov_12ec53qceg.b[21][0]++;
          cov_12ec53qceg.s[91]++;

          idp = $scope.choise.period.index;
        } else {
          cov_12ec53qceg.b[21][1]++;
        }
        cov_12ec53qceg.s[92]++;
        $scope.choise.period = null;
        cov_12ec53qceg.s[93]++;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = $scope.listSubPeriod[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var _el2 = _step5.value;
            cov_12ec53qceg.s[94]++;

            if (_el2.index == idp) {
              cov_12ec53qceg.b[22][0]++;
              cov_12ec53qceg.s[95]++;

              $scope.choise.period = _el2;
              cov_12ec53qceg.s[96]++;
              break;
            } else {
              cov_12ec53qceg.b[22][1]++;
            }
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cov_12ec53qceg.s[97]++;
        if ($scope.choise.period == null) {
          cov_12ec53qceg.b[23][0]++;
          cov_12ec53qceg.s[98]++;

          $scope.choise.period = $scope.listSubPeriod[0];
        } else {
          cov_12ec53qceg.b[23][1]++;
        }

        cov_12ec53qceg.s[99]++;
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = $scope.listSubPeriod[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var _el3 = _step6.value;

            var labelShort = (cov_12ec53qceg.s[100]++, $deltadate.getLabelPerioddepth(_el3.index * (12 / $scope.choise.year.DECOUPAGE_PERIOD), 12, true, $scope.choise.year.ANNEE_PERIOD, ((cov_12ec53qceg.b[24][0]++, $scope.choise.project) || (cov_12ec53qceg.b[24][1]++, {})).START));
            cov_12ec53qceg.s[101]++;
            $scope.choise.listheader.push({ labelPeriod: labelShort, label: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + '<br/>' + labelShort, index: _el3.index, type: 1 });
            cov_12ec53qceg.s[102]++;
            $scope.choise.listheader.push({ labelPeriod: labelShort, label: $translate.instant('ACTIVITY.REAL.COST_ACTUAL') + '<br/>' + labelShort, index: _el3.index, type: 2 });
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }
      } else {
        cov_12ec53qceg.b[14][1]++;
      }
      cov_12ec53qceg.s[103]++;
      if ($scope.selectedActivity) {
        cov_12ec53qceg.b[25][0]++;
        cov_12ec53qceg.s[104]++;

        $scope.indexLoading = 0;
        cov_12ec53qceg.s[105]++;
        $scope.listFlag = $deltahttp.getProjet() == 0 ? (cov_12ec53qceg.b[26][0]++, [{ label: '', color: '70,136,71', min: 70, max: 10000000, id: 1 }, { label: '', color: '192,152,83', min: 40, max: 70, id: 2 }, { label: '', color: '185,74,72', min: 0, max: 40, id: 3 }]) : (cov_12ec53qceg.b[26][1]++, $rootScope.currentProject.project.getFlag(true));

        var param = (cov_12ec53qceg.s[106]++, { get: 'dashboard_annual', activ: $scope.selectedActivity.id, valid: $scope.choise.validatedData ? (cov_12ec53qceg.b[27][0]++, 1) : (cov_12ec53qceg.b[27][1]++, 0), idp: $scope.choise.year.id });
        cov_12ec53qceg.s[107]++;
        if ($scope.choise.project) {
          cov_12ec53qceg.b[28][0]++;
          cov_12ec53qceg.s[108]++;

          if ($scope.choise.project.portfolio) {
            cov_12ec53qceg.b[29][0]++;
            cov_12ec53qceg.s[109]++;

            param.get = 'dashboard_annual_portfolio';
          } else {
            cov_12ec53qceg.b[29][1]++;
          }
        } else {
          cov_12ec53qceg.b[28][1]++;
        }

        cov_12ec53qceg.s[110]++;
        $CRUDService.getAll(PATHP, param, function (data) {
          cov_12ec53qceg.f[10]++;
          cov_12ec53qceg.s[111]++;

          $scope.annualData = data;
          cov_12ec53qceg.s[112]++;
          $scope.changePeriod();
          cov_12ec53qceg.s[113]++;
          $scope.year.Drawgraphe2();
          // $scope.year.Drawgraphe1(data[0]);
          cov_12ec53qceg.s[114]++;
          $scope.indexLoading = 1;
          cov_12ec53qceg.s[115]++;
          $scope.initialVariable = false;
          cov_12ec53qceg.s[116]++;
          $scope.year.calculateTotalLine();
        });
      } else {
        cov_12ec53qceg.b[25][1]++;
      }
    } else {
      cov_12ec53qceg.b[13][1]++;
    }
  };

  cov_12ec53qceg.s[117]++;
  $scope.changeTypeGraphe = function (type) {
    cov_12ec53qceg.f[11]++;
    cov_12ec53qceg.s[118]++;

    $scope[type == 1 ? (cov_12ec53qceg.b[30][0]++, 'period') : (cov_12ec53qceg.b[30][1]++, type == 2 ? (cov_12ec53qceg.b[31][0]++, 'year') : (cov_12ec53qceg.b[31][1]++, 'global'))].graphe.data_override = [{
      label: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'),
      borderWidth: 1,
      type: $rootScope.personalData.activity[type == 1 ? (cov_12ec53qceg.b[33][0]++, 'period') : (cov_12ec53qceg.b[33][1]++, type == 2 ? (cov_12ec53qceg.b[34][0]++, 'year') : (cov_12ec53qceg.b[34][1]++, 'global'))].PT == 1 ? (cov_12ec53qceg.b[32][0]++, 'bar') : (cov_12ec53qceg.b[32][1]++, 'line'),
      fill: $rootScope.personalData.activity[type == 1 ? (cov_12ec53qceg.b[35][0]++, 'period') : (cov_12ec53qceg.b[35][1]++, type == 2 ? (cov_12ec53qceg.b[36][0]++, 'year') : (cov_12ec53qceg.b[36][1]++, 'global'))].F == 1
    }, {
      label: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'),
      borderWidth: 3,
      type: $rootScope.personalData.activity[type == 1 ? (cov_12ec53qceg.b[38][0]++, 'period') : (cov_12ec53qceg.b[38][1]++, type == 2 ? (cov_12ec53qceg.b[39][0]++, 'year') : (cov_12ec53qceg.b[39][1]++, 'global'))].RT == 1 ? (cov_12ec53qceg.b[37][0]++, 'bar') : (cov_12ec53qceg.b[37][1]++, 'line'),
      fill: $rootScope.personalData.activity[type == 1 ? (cov_12ec53qceg.b[40][0]++, 'period') : (cov_12ec53qceg.b[40][1]++, type == 2 ? (cov_12ec53qceg.b[41][0]++, 'year') : (cov_12ec53qceg.b[41][1]++, 'global'))].F == 1
    }];
    cov_12ec53qceg.s[119]++;
    $rootScope.savePersonalData();
  };

  cov_12ec53qceg.s[120]++;
  $scope.period = {
    displayTable: true,
    displaycost: true,

    print: function print(typesuvi) {
      var header = (cov_12ec53qceg.s[121]++, []);
      cov_12ec53qceg.s[122]++;
      header.push($scope.firstTabLabel.replace('<br/>', '\n'));
      cov_12ec53qceg.s[123]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[42][0]++;
        cov_12ec53qceg.s[124]++;

        header.push($translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL').replace('<br/>', '\n'));
        cov_12ec53qceg.s[125]++;
        header.push($translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED').replace('<br/>', '\n'));
      } else {
        cov_12ec53qceg.b[42][1]++;
      }
      cov_12ec53qceg.s[126]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET').replace('<br/>', '\n'));
      cov_12ec53qceg.s[127]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[43][0]++;
        cov_12ec53qceg.s[128]++;

        header.push($scope.labels.period.plan.DB.replace('<br/>', '\n'));
        cov_12ec53qceg.s[129]++;
        header.push($scope.labels.period.plan.FB.replace('<br/>', '\n'));
        cov_12ec53qceg.s[130]++;
        header.push($scope.labels.period.real.DR.replace('<br/>', '\n'));
        cov_12ec53qceg.s[131]++;
        header.push($scope.labels.period.real.FR.replace('<br/>', '\n'));
      } else {
        cov_12ec53qceg.b[43][1]++;
      }

      cov_12ec53qceg.s[132]++;
      header.push($translate.instant('ACTIVITY.REAL.COST_ACTUAL').replace('<br/>', '\n'));
      cov_12ec53qceg.s[133]++;
      header.push($translate.instant('ACTIVITY.PLAN.COST_PLANNED').replace('<br/>', '\n'));
      cov_12ec53qceg.s[134]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET').replace('<br/>', '\n'));
      cov_12ec53qceg.s[135]++;
      header.push($translate.instant('ACTIVITY.REAL.COMMENTS').replace('<br/>', '\n'));

      var titles = (cov_12ec53qceg.s[136]++, [($scope.selectedActivity.CODE_CLC + ' : ' + $scope.selectedActivity.LIBELLE_C_CL).toUpperCase()]);
      cov_12ec53qceg.s[137]++;
      titles.push($translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_SECOND'));

      cov_12ec53qceg.s[138]++;
      if (!$scope.choise.period) {
        cov_12ec53qceg.b[44][0]++;
        cov_12ec53qceg.s[139]++;

        return { data: { header: header, datas: [], titles: titles, wtitle: '__' } };
      } else {
        cov_12ec53qceg.b[44][1]++;
      }

      var wtitle = (cov_12ec53qceg.s[140]++, $filter('date')($scope.choise.period.end));

      var datas = (cov_12ec53qceg.s[141]++, []);
      cov_12ec53qceg.s[142]++;
      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = $scope.annualData[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var eld = _step7.value;
          cov_12ec53qceg.s[143]++;

          if (!eld.data[$scope.choise.period.index]) {
            cov_12ec53qceg.b[45][0]++;
            cov_12ec53qceg.s[144]++;

            continue;
          } else {
            cov_12ec53qceg.b[45][1]++;
          }
          var item_ = (cov_12ec53qceg.s[145]++, []);
          cov_12ec53qceg.s[146]++;
          item_.push(eld.D);
          cov_12ec53qceg.s[147]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[46][0]++;
            cov_12ec53qceg.s[148]++;

            item_.push(eld.data[$scope.choise.period.index].QTE_REELLE);
            cov_12ec53qceg.s[149]++;
            if (!$scope.choise.revise) {
              cov_12ec53qceg.b[47][0]++;
              cov_12ec53qceg.s[150]++;

              item_.push(eld.data[$scope.choise.period.index].QTE_PREVUE_RELV);
            } else {
              cov_12ec53qceg.b[47][1]++;
              cov_12ec53qceg.s[151]++;

              item_.push(eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV ? (cov_12ec53qceg.b[48][0]++, eld.data[$scope.choise.period.index].QTE_PREVUE_RELV_REV) : (cov_12ec53qceg.b[48][1]++, eld.data[$scope.choise.period.index].QTE_PREVUE_RELV));
            }
          } else {
            cov_12ec53qceg.b[46][1]++;
          }
          cov_12ec53qceg.s[152]++;
          item_.push(this.calculateAmountRate(eld.data[$scope.choise.period.index]) / 100);
          cov_12ec53qceg.s[153]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[49][0]++;
            cov_12ec53qceg.s[154]++;

            item_.push($filter('date')(eld.data[$scope.choise.period.index].Date_DEB_R_RELV));
            cov_12ec53qceg.s[155]++;
            item_.push($filter('date')(eld.data[$scope.choise.period.index].Date_Fin_R_RELV));
            cov_12ec53qceg.s[156]++;
            if (!$scope.choise.revise) {
              cov_12ec53qceg.b[50][0]++;
              cov_12ec53qceg.s[157]++;

              item_.push($filter('date')(eld.data[$scope.choise.period.index].DATE_DEB_P_RELV));
            } else {
              cov_12ec53qceg.b[50][1]++;
              cov_12ec53qceg.s[158]++;

              item_.push($filter('date')(eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV ? (cov_12ec53qceg.b[51][0]++, eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV) : (cov_12ec53qceg.b[51][1]++, eld.data[$scope.choise.period.index].DATE_DEB_P_RELV)));
            }
            cov_12ec53qceg.s[159]++;
            if (!$scope.choise.revise) {
              cov_12ec53qceg.b[52][0]++;
              cov_12ec53qceg.s[160]++;

              item_.push($filter('date')(eld.data[$scope.choise.period.index].Date_Fin_P_RELV));
            } else {
              cov_12ec53qceg.b[52][1]++;
              cov_12ec53qceg.s[161]++;

              item_.push($filter('date')(eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV ? (cov_12ec53qceg.b[53][0]++, eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV) : (cov_12ec53qceg.b[53][1]++, eld.data[$scope.choise.period.index].Date_Fin_P_RELV)));
            }
          } else {
            cov_12ec53qceg.b[49][1]++;
          }

          cov_12ec53qceg.s[162]++;
          item_.push(eld.data[$scope.choise.period.index].COUT_REEL);

          cov_12ec53qceg.s[163]++;
          if (!$scope.choise.revise) {
            cov_12ec53qceg.b[54][0]++;
            cov_12ec53qceg.s[164]++;

            item_.push(eld.data[$scope.choise.period.index].BUDGET_RBN);
          } else {
            cov_12ec53qceg.b[54][1]++;
            cov_12ec53qceg.s[165]++;

            item_.push(eld.data[$scope.choise.period.index].DATE_DEB_P_RELV_REV ? (cov_12ec53qceg.b[55][0]++, eld.data[$scope.choise.period.index].BUDGET_RBN_REV) : (cov_12ec53qceg.b[55][1]++, eld.data[$scope.choise.period.index].BUDGET_RBN));
          }
          cov_12ec53qceg.s[166]++;
          item_.push(this.calculateCostRate(eld.data[$scope.choise.period.index]) / 100);
          cov_12ec53qceg.s[167]++;
          item_.push(eld.data[$scope.choise.period.index].COMMENTS);

          cov_12ec53qceg.s[168]++;
          datas.push(item_);
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      cov_12ec53qceg.s[169]++;
      return { data: { header: header, datas: datas, titles: titles, wtitle: wtitle } };

      /* $CRUDService.save(PATHP,{action:'print_dashboard_global', data: $filter('json')({header, datas, titles })}, (data)=>{
        $window.open($deltahttp.getReporting() + data, '_blank');
       }); */
    },

    graphe: {
      data: [],
      labels: [],
      options: {
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              cov_12ec53qceg.s[170]++;

              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + '%';
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          display: Boolean($rootScope.getISINCLUDEDRAPPORT()),
          position: 'top'
        },
        maintainAspectRatio: false,

        elements: {
          line: {
            cubicInterpolationMode: 'monotone',
            spanGaps: false,
            tension: 0
          }
        }
      },
      series: [$translate.instant('ACTIVITY.DASHBOARD.RATE_PHY')],
      colors: ['rgba(' + $rootScope.personalData.activity.period.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.period.R + ',0.7)'],
      data_override: [{
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'),
        borderWidth: 1,
        type: $rootScope.personalData.activity.period.PT == 1 ? (cov_12ec53qceg.b[56][0]++, 'bar') : (cov_12ec53qceg.b[56][1]++, 'line'),
        fill: $rootScope.personalData.activity.period.F == 1
      }, {
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'),
        borderWidth: 3,
        type: $rootScope.personalData.activity.period.RT == 1 ? (cov_12ec53qceg.b[57][0]++, 'bar') : (cov_12ec53qceg.b[57][1]++, 'line'),
        fill: $rootScope.personalData.activity.period.F == 1
      }]
    },
    changeColor: function changeColor() {
      cov_12ec53qceg.s[171]++;

      this.graphe.colors = ['rgba(' + $rootScope.personalData.activity.period.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.period.R + ',0.7)'];
      cov_12ec53qceg.s[172]++;
      $rootScope.savePersonalData();
    },
    calculateAmountRate: function calculateAmountRate(val) {
      cov_12ec53qceg.s[173]++;

      if (!val) {
        cov_12ec53qceg.b[58][0]++;
        cov_12ec53qceg.s[174]++;

        return 0;
      } else {
        cov_12ec53qceg.b[58][1]++;
      }
      cov_12ec53qceg.s[175]++;
      if (angular.isDefined(val.RATE)) {
        cov_12ec53qceg.b[59][0]++;
        cov_12ec53qceg.s[176]++;

        return parseFloat(val.RATE);
      } else {
        cov_12ec53qceg.b[59][1]++;
      }
      var totalAmount = (cov_12ec53qceg.s[177]++, val.QTE_REELLE);
      var totalPrevu = (cov_12ec53qceg.s[178]++, val.QTE_PREVUE_RELV);
      cov_12ec53qceg.s[179]++;
      if ((cov_12ec53qceg.b[61][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[61][1]++, val.DATE_DEB_P_RELV_REV)) {
        cov_12ec53qceg.b[60][0]++;
        cov_12ec53qceg.s[180]++;

        totalPrevu = $deltaNumber.formatNumber(val.QTE_PREVUE_RELV_REV); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[60][1]++;
      }
      cov_12ec53qceg.s[181]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[62][0]++;
        cov_12ec53qceg.s[182]++;

        return '';
      } else {
        cov_12ec53qceg.b[62][1]++;
      }
      cov_12ec53qceg.s[183]++;
      return 100 * (totalAmount / totalPrevu);
    },
    calculateCostRate: function calculateCostRate(val) {
      cov_12ec53qceg.s[184]++;

      if (!val) {
        cov_12ec53qceg.b[63][0]++;
        cov_12ec53qceg.s[185]++;

        return 0;
      } else {
        cov_12ec53qceg.b[63][1]++;
      }
      var totalCost = (cov_12ec53qceg.s[186]++, val.COUT_REEL);
      var totalPrevu = (cov_12ec53qceg.s[187]++, val.BUDGET_RBN);
      cov_12ec53qceg.s[188]++;
      if ((cov_12ec53qceg.b[65][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[65][1]++, val.DATE_DEB_P_RELV_REV)) {
        cov_12ec53qceg.b[64][0]++;
        cov_12ec53qceg.s[189]++;

        totalPrevu = $deltaNumber.formatNumber(val.BUDGET_RBN_REV); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[64][1]++;
      }
      cov_12ec53qceg.s[190]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[66][0]++;
        cov_12ec53qceg.s[191]++;

        return '';
      } else {
        cov_12ec53qceg.b[66][1]++;
      }
      cov_12ec53qceg.s[192]++;
      return 100 * (totalCost / totalPrevu);
    }
  };
  cov_12ec53qceg.s[193]++;
  $scope.year = {
    displaycost: true,
    selectedItem: null,
    displayTable: true,
    panelPosition: { top: 0, left: 0, width: 600, height: 500 },
    Drawgraphe1: function Drawgraphe1(item, event) {
      cov_12ec53qceg.s[194]++;

      if (event) {
        cov_12ec53qceg.b[67][0]++;

        var body = (cov_12ec53qceg.s[195]++, $('body'));
        var width = (cov_12ec53qceg.s[196]++, Math.min(600, body.width()));
        var height = (cov_12ec53qceg.s[197]++, Math.min(500, body.height()));
        var left = (cov_12ec53qceg.s[198]++, Math.min(event.pageX, body.width() - width));
        var top = (cov_12ec53qceg.s[199]++, Math.min(event.pageY, body.height() - height));
        cov_12ec53qceg.s[200]++;
        this.panelPosition = { top: top - 100 + 'px', left: left - 100 + 'px', width: width + 'px', height: height + 'px' };
      } else {
        cov_12ec53qceg.b[67][1]++;
      }
      cov_12ec53qceg.s[201]++;
      this.selectedItem = item;
      cov_12ec53qceg.s[202]++;
      this.graphe.labels1 = [];
      cov_12ec53qceg.s[203]++;
      this.graphe.data1 = [[], []];
      cov_12ec53qceg.s[204]++;
      if (!item) {
        cov_12ec53qceg.b[68][0]++;
        cov_12ec53qceg.s[205]++;

        return;
      } else {
        cov_12ec53qceg.b[68][1]++;
      }
      cov_12ec53qceg.s[206]++;
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = $scope.choise.listheader[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var per = _step8.value;
          cov_12ec53qceg.s[207]++;

          if (per.type != 1) {
            cov_12ec53qceg.b[69][0]++;
            cov_12ec53qceg.s[208]++;

            continue;
          } else {
            cov_12ec53qceg.b[69][1]++;
          }
          var d = (cov_12ec53qceg.s[209]++, item.data[per.index]);
          cov_12ec53qceg.s[210]++;
          if (!d) {
            cov_12ec53qceg.b[70][0]++;
            cov_12ec53qceg.s[211]++;

            continue;
          } else {
            cov_12ec53qceg.b[70][1]++;
          }

          cov_12ec53qceg.s[212]++;
          this.graphe.labels1.push(per.labelPeriod);
          // Taux Physique
          var physiqueRate = (cov_12ec53qceg.s[213]++, null);
          var financialRate = (cov_12ec53qceg.s[214]++, null);

          cov_12ec53qceg.s[215]++;
          if (angular.isDefined(d.RATE)) {
            cov_12ec53qceg.b[71][0]++;
            cov_12ec53qceg.s[216]++;

            physiqueRate = parseFloat(d.RATE);
          } else {
            cov_12ec53qceg.b[71][1]++;

            var prevu = (cov_12ec53qceg.s[217]++, d.QTE_PREVUE_RELV);
            cov_12ec53qceg.s[218]++;
            if ((cov_12ec53qceg.b[73][0]++, d.DATE_DEB_P_RELV_REV) && (cov_12ec53qceg.b[73][1]++, $scope.choise.revise)) {
              cov_12ec53qceg.b[72][0]++;
              cov_12ec53qceg.s[219]++;

              prevu = d.QTE_PREVUE_RELV_REV;
            } else {
              cov_12ec53qceg.b[72][1]++;
            }
            cov_12ec53qceg.s[220]++;
            if ((cov_12ec53qceg.b[75][0]++, prevu != 0) && (cov_12ec53qceg.b[75][1]++, d.QTE_REELLE !== null)) {
              cov_12ec53qceg.b[74][0]++;
              cov_12ec53qceg.s[221]++;

              physiqueRate = 100 * d.QTE_REELLE / prevu;
            } else {
              cov_12ec53qceg.b[74][1]++;
            }
          }
          var budget = (cov_12ec53qceg.s[222]++, d.BUDGET_RBN);
          cov_12ec53qceg.s[223]++;
          if ((cov_12ec53qceg.b[77][0]++, d.DATE_DEB_P_RELV_REV) && (cov_12ec53qceg.b[77][1]++, $scope.choise.revise)) {
            cov_12ec53qceg.b[76][0]++;
            cov_12ec53qceg.s[224]++;

            budget = d.BUDGET_RBN_REV;
          } else {
            cov_12ec53qceg.b[76][1]++;
          }
          cov_12ec53qceg.s[225]++;
          if ((cov_12ec53qceg.b[79][0]++, budget != 0) && (cov_12ec53qceg.b[79][1]++, d.COUT_REEL !== null)) {
            cov_12ec53qceg.b[78][0]++;
            cov_12ec53qceg.s[226]++;

            financialRate = 100 * d.COUT_REEL / budget;
          } else {
            cov_12ec53qceg.b[78][1]++;
          }
          cov_12ec53qceg.s[227]++;
          this.graphe.data1[0].push(physiqueRate !== null ? (cov_12ec53qceg.b[80][0]++, Math.round(physiqueRate)) : (cov_12ec53qceg.b[80][1]++, null));
          cov_12ec53qceg.s[228]++;
          this.graphe.data1[1].push(financialRate !== null ? (cov_12ec53qceg.b[81][0]++, Math.round(financialRate)) : (cov_12ec53qceg.b[81][1]++, null));
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }
    },
    Drawgraphe2: function Drawgraphe2() {
      var data = (cov_12ec53qceg.s[229]++, $scope.annualData);
      cov_12ec53qceg.s[230]++;
      this.graphe.labels2 = [];
      cov_12ec53qceg.s[231]++;
      this.graphe.data2 = [[], []];
      cov_12ec53qceg.s[232]++;
      var _iteratorNormalCompletion9 = true;
      var _didIteratorError9 = false;
      var _iteratorError9 = undefined;

      try {
        for (var _iterator9 = data[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
          var el = _step9.value;
          cov_12ec53qceg.s[233]++;

          this.graphe.labels2.push(el.D);
          cov_12ec53qceg.s[234]++;
          this.graphe.data2[1].push(Math.round(this.calculateCostRate(el)));
          cov_12ec53qceg.s[235]++;
          this.graphe.data2[0].push(Math.round(this.calculateAmountRate(el)));
        }
      } catch (err) {
        _didIteratorError9 = true;
        _iteratorError9 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion9 && _iterator9.return) {
            _iterator9.return();
          }
        } finally {
          if (_didIteratorError9) {
            throw _iteratorError9;
          }
        }
      }
    },
    print: function print(typesuvi) {
      var header = (cov_12ec53qceg.s[236]++, []);
      cov_12ec53qceg.s[237]++;
      header.push($scope.firstTabLabel);
      cov_12ec53qceg.s[238]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[82][0]++;
        cov_12ec53qceg.s[239]++;

        header.push($translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED'));
      } else {
        cov_12ec53qceg.b[82][1]++;
      }
      cov_12ec53qceg.s[240]++;
      header.push($translate.instant('ACTIVITY.PLAN.COST_PLANNED'));
      cov_12ec53qceg.s[241]++;
      var _iteratorNormalCompletion10 = true;
      var _didIteratorError10 = false;
      var _iteratorError10 = undefined;

      try {
        for (var _iterator10 = $scope.choise.listheader[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
          var el = _step10.value;
          cov_12ec53qceg.s[242]++;

          header.push(el.label.replace('<br/>', '\n'));
        }
      } catch (err) {
        _didIteratorError10 = true;
        _iteratorError10 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion10 && _iterator10.return) {
            _iterator10.return();
          }
        } finally {
          if (_didIteratorError10) {
            throw _iteratorError10;
          }
        }
      }

      cov_12ec53qceg.s[243]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[83][0]++;
        cov_12ec53qceg.s[244]++;

        header.push($translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL').replace('<br/>', '\n'));
      } else {
        cov_12ec53qceg.b[83][1]++;
      }

      cov_12ec53qceg.s[245]++;
      header.push($translate.instant('ACTIVITY.REAL.COST_ACTUAL').replace('<br/>', '\n'));

      cov_12ec53qceg.s[246]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET').replace('<br/>', '\n'));

      cov_12ec53qceg.s[247]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET').replace('<br/>', '\n'));

      var datas = (cov_12ec53qceg.s[248]++, []);
      cov_12ec53qceg.s[249]++;
      var _iteratorNormalCompletion11 = true;
      var _didIteratorError11 = false;
      var _iteratorError11 = undefined;

      try {
        for (var _iterator11 = $scope.annualData[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
          var eld = _step11.value;
          cov_12ec53qceg.s[250]++;

          if (!eld.data[13]) {
            cov_12ec53qceg.b[84][0]++;
            cov_12ec53qceg.s[251]++;

            continue;
          } else {
            cov_12ec53qceg.b[84][1]++;
          }
          var item_ = (cov_12ec53qceg.s[252]++, []);
          cov_12ec53qceg.s[253]++;
          item_.push(eld.D);
          cov_12ec53qceg.s[254]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[85][0]++;
            cov_12ec53qceg.s[255]++;

            if (!$scope.choise.revise) {
              cov_12ec53qceg.b[86][0]++;
              cov_12ec53qceg.s[256]++;

              item_.push(eld.data[13].QTE_PREVUE_AN);
            } else {
              cov_12ec53qceg.b[86][1]++;
              cov_12ec53qceg.s[257]++;

              item_.push(eld.data[13].DATE_DEBUT_P_AN_REV ? (cov_12ec53qceg.b[87][0]++, eld.data[13].QTE_PREVUE_AN_REV) : (cov_12ec53qceg.b[87][1]++, eld.data[13].QTE_PREVUE_AN));
            }
          } else {
            cov_12ec53qceg.b[85][1]++;
          }

          cov_12ec53qceg.s[258]++;
          if (!$scope.choise.revise) {
            cov_12ec53qceg.b[88][0]++;
            cov_12ec53qceg.s[259]++;

            item_.push(eld.data[13].BUDGET_PLAN_AN);
          } else {
            cov_12ec53qceg.b[88][1]++;
            cov_12ec53qceg.s[260]++;

            item_.push(eld.data[13].DATE_DEBUT_P_AN_REV ? (cov_12ec53qceg.b[89][0]++, eld.data[13].DATE_DEBUT_P_AN_REV) : (cov_12ec53qceg.b[89][1]++, eld.data[13].BUDGET_PLAN_AN));
          }

          cov_12ec53qceg.s[261]++;
          var _iteratorNormalCompletion12 = true;
          var _didIteratorError12 = false;
          var _iteratorError12 = undefined;

          try {
            for (var _iterator12 = $scope.choise.listheader[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
              var _el4 = _step12.value;
              cov_12ec53qceg.s[262]++;

              if ((cov_12ec53qceg.b[91][0]++, _el4.type == 1) && (cov_12ec53qceg.b[91][1]++, !typesuvi)) {
                cov_12ec53qceg.b[90][0]++;
                cov_12ec53qceg.s[263]++;

                item_.push(((cov_12ec53qceg.b[92][0]++, eld.data[_el4.index]) || (cov_12ec53qceg.b[92][1]++, {})).QTE_REELLE);
              } else {
                cov_12ec53qceg.b[90][1]++;
              }
              cov_12ec53qceg.s[264]++;
              if ((cov_12ec53qceg.b[94][0]++, _el4.type == 1) && (cov_12ec53qceg.b[94][1]++, typesuvi)) {
                cov_12ec53qceg.b[93][0]++;
                cov_12ec53qceg.s[265]++;

                item_.push($scope.period.calculateAmountRate(eld.data[_el4.index]));
              } else {
                cov_12ec53qceg.b[93][1]++;
              }
              cov_12ec53qceg.s[266]++;
              if (_el4.type == 2) {
                cov_12ec53qceg.b[95][0]++;
                cov_12ec53qceg.s[267]++;

                item_.push(((cov_12ec53qceg.b[96][0]++, eld.data[_el4.index]) || (cov_12ec53qceg.b[96][1]++, {})).COUT_REEL);
              } else {
                cov_12ec53qceg.b[95][1]++;
              }
            }
          } catch (err) {
            _didIteratorError12 = true;
            _iteratorError12 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion12 && _iterator12.return) {
                _iterator12.return();
              }
            } finally {
              if (_didIteratorError12) {
                throw _iteratorError12;
              }
            }
          }

          cov_12ec53qceg.s[268]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[97][0]++;
            cov_12ec53qceg.s[269]++;

            item_.push(this.calculateTotalAmount(eld));
          } else {
            cov_12ec53qceg.b[97][1]++;
          }
          cov_12ec53qceg.s[270]++;
          item_.push(this.calculateTotalCost(eld));
          cov_12ec53qceg.s[271]++;
          item_.push(this.calculateAmountRate(eld) / 100);
          cov_12ec53qceg.s[272]++;
          item_.push(this.calculateCostRate(eld) / 100);

          cov_12ec53qceg.s[273]++;
          datas.push(item_);
        }
      } catch (err) {
        _didIteratorError11 = true;
        _iteratorError11 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion11 && _iterator11.return) {
            _iterator11.return();
          }
        } finally {
          if (_didIteratorError11) {
            throw _iteratorError11;
          }
        }
      }

      var titles = (cov_12ec53qceg.s[274]++, [($scope.selectedActivity.CODE_CLC + ' : ' + $scope.selectedActivity.LIBELLE_C_CL).toUpperCase()]);

      cov_12ec53qceg.s[275]++;
      titles.push($translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_SECOND'));
      var wtitle = (cov_12ec53qceg.s[276]++, $translate.instant('COMMON.YEAR') + ' ' + $scope.choise.year.ANNEE_PERIOD);

      cov_12ec53qceg.s[277]++;
      return { data: { header: header, datas: datas, titles: titles, wtitle: wtitle } };
    },

    graphe: {
      data1: [],
      labels1: [],
      data2: [],
      labels2: [],
      options: {
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              cov_12ec53qceg.s[278]++;

              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + '%';
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          display: Boolean($rootScope.getISINCLUDEDRAPPORT()),
          position: 'top'
        },
        elements: {
          line: {
            cubicInterpolationMode: 'monotone',
            spanGaps: false,
            tension: 0
          }
        },
        maintainAspectRatio: false
      },
      series: [$translate.instant('ACTIVITY.DASHBOARD.RATE_PHY')],
      colors: ['rgba(' + $rootScope.personalData.activity.year.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.year.R + ',0.7)'],
      data_override: [{
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'),
        borderWidth: 1,
        type: $rootScope.personalData.activity.year.PT == 1 ? (cov_12ec53qceg.b[98][0]++, 'bar') : (cov_12ec53qceg.b[98][1]++, 'line'),
        fill: $rootScope.personalData.activity.year.F == 1
      }, {
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'),
        borderWidth: 3,
        type: $rootScope.personalData.activity.year.RT == 1 ? (cov_12ec53qceg.b[99][0]++, 'bar') : (cov_12ec53qceg.b[99][1]++, 'line'),
        fill: $rootScope.personalData.activity.year.F == 1
      }]
    },
    changeColor: function changeColor() {
      cov_12ec53qceg.s[279]++;

      this.graphe.colors = ['rgba(' + $rootScope.personalData.activity.year.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.year.R + ',0.7)'];
      cov_12ec53qceg.s[280]++;
      $rootScope.savePersonalData();
    },
    calculateTotalAmount: function calculateTotalAmount(item) {
      var total = (cov_12ec53qceg.s[281]++, 0);
      cov_12ec53qceg.s[282]++;
      for (var i = 0; i < 13; i++) {
        cov_12ec53qceg.s[283]++;

        if (!item.data[i]) {
          cov_12ec53qceg.b[100][0]++;
          cov_12ec53qceg.s[284]++;

          continue;
        } else {
          cov_12ec53qceg.b[100][1]++;
        }
        cov_12ec53qceg.s[285]++;
        total += $deltaNumber.formatNumber(item.data[i].QTE_REELLE, 0); // jshint ignore:line
      }
      cov_12ec53qceg.s[286]++;
      return total;
    },
    calculateTotalCost: function calculateTotalCost(item) {
      var total = (cov_12ec53qceg.s[287]++, 0);
      cov_12ec53qceg.s[288]++;
      for (var i = 0; i < 13; i++) {
        cov_12ec53qceg.s[289]++;

        if (!item.data[i]) {
          cov_12ec53qceg.b[101][0]++;
          cov_12ec53qceg.s[290]++;

          continue;
        } else {
          cov_12ec53qceg.b[101][1]++;
        }
        cov_12ec53qceg.s[291]++;
        total += $deltaNumber.formatNumber(item.data[i].COUT_REEL, 0); // jshint ignore:line
      }
      cov_12ec53qceg.s[292]++;
      return total;
    },
    calculateAmountRate: function calculateAmountRate(item) {
      cov_12ec53qceg.s[293]++;

      if (!item.data[13]) {
        cov_12ec53qceg.b[102][0]++;
        cov_12ec53qceg.s[294]++;

        return 0;
      } else {
        cov_12ec53qceg.b[102][1]++;
      }
      cov_12ec53qceg.s[295]++;
      if (angular.isDefined(item.data[13].RATE)) {
        cov_12ec53qceg.b[103][0]++;
        cov_12ec53qceg.s[296]++;

        return parseFloat(item.data[13].RATE);
      } else {
        cov_12ec53qceg.b[103][1]++;
      }
      var totalAmount = (cov_12ec53qceg.s[297]++, this.calculateTotalAmount(item));

      var totalPrevu = (cov_12ec53qceg.s[298]++, $deltaNumber.formatNumber(item.data[13].QTE_PREVUE_AN, 0)); // jshint ignore:line
      cov_12ec53qceg.s[299]++;
      if ((cov_12ec53qceg.b[105][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[105][1]++, item.data[13].DATE_DEBUT_P_AN_REV)) {
        cov_12ec53qceg.b[104][0]++;
        cov_12ec53qceg.s[300]++;

        totalPrevu = $deltaNumber.formatNumber(item.data[13].QTE_PREVUE_AN_REV, 0); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[104][1]++;
      }

      cov_12ec53qceg.s[301]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[106][0]++;
        cov_12ec53qceg.s[302]++;

        return '';
      } else {
        cov_12ec53qceg.b[106][1]++;
      }
      cov_12ec53qceg.s[303]++;
      return 100 * (totalAmount / totalPrevu);
    },
    calculateCostRate: function calculateCostRate(item) {
      cov_12ec53qceg.s[304]++;

      if (!item.data[13]) {
        cov_12ec53qceg.b[107][0]++;
        cov_12ec53qceg.s[305]++;

        return 0;
      } else {
        cov_12ec53qceg.b[107][1]++;
      }
      var totalCost = (cov_12ec53qceg.s[306]++, this.calculateTotalCost(item));

      var totalPrevu = (cov_12ec53qceg.s[307]++, $deltaNumber.formatNumber(item.data[13].BUDGET_PLAN_AN, 0)); // jshint ignore:line
      cov_12ec53qceg.s[308]++;
      if ((cov_12ec53qceg.b[109][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[109][1]++, item.data[13].DATE_DEBUT_P_AN_REV)) {
        cov_12ec53qceg.b[108][0]++;
        cov_12ec53qceg.s[309]++;

        totalPrevu = $deltaNumber.formatNumber(item.data[13].BUDGET_PLAN_AN_REV, 0); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[108][1]++;
      }

      cov_12ec53qceg.s[310]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[110][0]++;
        cov_12ec53qceg.s[311]++;

        return '';
      } else {
        cov_12ec53qceg.b[110][1]++;
      }
      cov_12ec53qceg.s[312]++;
      return 100 * (totalCost / totalPrevu);
    },
    calculateTotalLine: function calculateTotalLine() {
      cov_12ec53qceg.s[313]++;

      if ($scope.annualData.length < 2) {
        cov_12ec53qceg.b[111][0]++;
        cov_12ec53qceg.s[314]++;

        return;
      } else {
        cov_12ec53qceg.b[111][1]++;
      }
      var item = (cov_12ec53qceg.s[315]++, {
        id: 0,
        D: 'Total',
        data: {}
      });
      cov_12ec53qceg.s[316]++;
      var _iteratorNormalCompletion13 = true;
      var _didIteratorError13 = false;
      var _iteratorError13 = undefined;

      try {
        for (var _iterator13 = $scope.annualData[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
          var item_ = _step13.value;
          cov_12ec53qceg.s[317]++;

          for (var k_ in item_.data) {
            cov_12ec53qceg.s[318]++;

            if (angular.isUndefined(item.data[k_])) {
              cov_12ec53qceg.b[112][0]++;
              cov_12ec53qceg.s[319]++;

              item.data[k_] = {};
            } else {
              cov_12ec53qceg.b[112][1]++;
            }
            cov_12ec53qceg.s[320]++;
            for (var key in item_.data[k_]) {
              cov_12ec53qceg.s[321]++;

              if (angular.isUndefined(item.data[k_][key])) {
                cov_12ec53qceg.b[113][0]++;
                cov_12ec53qceg.s[322]++;

                item.data[k_][key] = 0;
              } else {
                cov_12ec53qceg.b[113][1]++;
              }
              cov_12ec53qceg.s[323]++;
              item.data[k_][key] += item_.data[k_][key] ? (cov_12ec53qceg.b[114][0]++, parseFloat(item_.data[k_][key])) : (cov_12ec53qceg.b[114][1]++, 0);
            }
          }
        }
      } catch (err) {
        _didIteratorError13 = true;
        _iteratorError13 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion13 && _iterator13.return) {
            _iterator13.return();
          }
        } finally {
          if (_didIteratorError13) {
            throw _iteratorError13;
          }
        }
      }

      cov_12ec53qceg.s[324]++;
      $scope.annualData.push(item);
    }
  };
  cov_12ec53qceg.s[325]++;
  $scope.global = {
    displaycost: true,
    selectedItem: null,
    displayTable: true,
    panelPosition: { top: 0, left: 0, width: 600, height: 500 },
    Drawgraphe1: function Drawgraphe1(item, event) {
      cov_12ec53qceg.s[326]++;

      if (event) {
        cov_12ec53qceg.b[115][0]++;

        var body = (cov_12ec53qceg.s[327]++, $('body'));
        var width = (cov_12ec53qceg.s[328]++, Math.min(600, body.width()));
        var height = (cov_12ec53qceg.s[329]++, Math.min(500, body.height()));
        var left = (cov_12ec53qceg.s[330]++, Math.min(event.pageX, body.width() - width));
        var top = (cov_12ec53qceg.s[331]++, Math.min(event.pageY, body.height() - height));
        cov_12ec53qceg.s[332]++;
        this.panelPosition = { top: top - 100 + 'px', left: left - 100 + 'px', width: width + 'px', height: height + 'px' };
      } else {
        cov_12ec53qceg.b[115][1]++;
      }
      cov_12ec53qceg.s[333]++;
      this.selectedItem = item;
      cov_12ec53qceg.s[334]++;
      this.graphe.labels1 = [];
      cov_12ec53qceg.s[335]++;
      this.graphe.data1 = [[], []];
      cov_12ec53qceg.s[336]++;
      if (!item) {
        cov_12ec53qceg.b[116][0]++;
        cov_12ec53qceg.s[337]++;

        return;
      } else {
        cov_12ec53qceg.b[116][1]++;
      }
      cov_12ec53qceg.s[338]++;
      var _iteratorNormalCompletion14 = true;
      var _didIteratorError14 = false;
      var _iteratorError14 = undefined;

      try {
        for (var _iterator14 = $scope.choise.listheaderGlobal[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
          var per = _step14.value;
          cov_12ec53qceg.s[339]++;

          if (per.type != 1) {
            cov_12ec53qceg.b[117][0]++;
            cov_12ec53qceg.s[340]++;

            continue;
          } else {
            cov_12ec53qceg.b[117][1]++;
          }
          var d = (cov_12ec53qceg.s[341]++, item.data[per.index]);
          cov_12ec53qceg.s[342]++;
          if (!d) {
            cov_12ec53qceg.b[118][0]++;
            cov_12ec53qceg.s[343]++;

            continue;
          } else {
            cov_12ec53qceg.b[118][1]++;
          }
          cov_12ec53qceg.s[344]++;
          this.graphe.labels1.push(per.labelYear);
          // Taux Physique
          var physiqueRate = (cov_12ec53qceg.s[345]++, null);
          var financialRate = (cov_12ec53qceg.s[346]++, null);

          cov_12ec53qceg.s[347]++;
          if (angular.isDefined(d.RATE)) {
            cov_12ec53qceg.b[119][0]++;
            cov_12ec53qceg.s[348]++;

            physiqueRate = parseFloat(d.RATE);
          } else {
            cov_12ec53qceg.b[119][1]++;

            var prevu = (cov_12ec53qceg.s[349]++, d.QTE_PREVUE_AN);
            cov_12ec53qceg.s[350]++;
            if ((cov_12ec53qceg.b[121][0]++, d.DATE_DEBUT_P_AN_REV) && (cov_12ec53qceg.b[121][1]++, $scope.choise.revise)) {
              cov_12ec53qceg.b[120][0]++;
              cov_12ec53qceg.s[351]++;

              prevu = d.QTE_PREVUE_AN_REV;
            } else {
              cov_12ec53qceg.b[120][1]++;
            }
            cov_12ec53qceg.s[352]++;
            if ((cov_12ec53qceg.b[123][0]++, prevu != 0) && (cov_12ec53qceg.b[123][1]++, d.SQTE_REELLE !== null)) {
              cov_12ec53qceg.b[122][0]++;
              cov_12ec53qceg.s[353]++;

              physiqueRate = 100 * d.SQTE_REELLE / prevu;
            } else {
              cov_12ec53qceg.b[122][1]++;
            }
          }
          var budget = (cov_12ec53qceg.s[354]++, d.BUDGET_PLAN_AN);
          cov_12ec53qceg.s[355]++;
          if ((cov_12ec53qceg.b[125][0]++, d.DATE_DEBUT_P_AN_REV) && (cov_12ec53qceg.b[125][1]++, $scope.choise.revise)) {
            cov_12ec53qceg.b[124][0]++;
            cov_12ec53qceg.s[356]++;

            budget = d.BUDGET_PLAN_AN_REV;
          } else {
            cov_12ec53qceg.b[124][1]++;
          }
          cov_12ec53qceg.s[357]++;
          if ((cov_12ec53qceg.b[127][0]++, budget != 0) && (cov_12ec53qceg.b[127][1]++, d.SCOUT_REEL !== null)) {
            cov_12ec53qceg.b[126][0]++;
            cov_12ec53qceg.s[358]++;

            financialRate = 100 * d.SCOUT_REEL / budget;
          } else {
            cov_12ec53qceg.b[126][1]++;
          }
          cov_12ec53qceg.s[359]++;
          this.graphe.data1[0].push(physiqueRate !== null ? (cov_12ec53qceg.b[128][0]++, Math.round(Math.round(physiqueRate))) : (cov_12ec53qceg.b[128][1]++, null));
          cov_12ec53qceg.s[360]++;
          this.graphe.data1[1].push(financialRate !== null ? (cov_12ec53qceg.b[129][0]++, Math.round(Math.round(financialRate))) : (cov_12ec53qceg.b[129][1]++, null));
        }
      } catch (err) {
        _didIteratorError14 = true;
        _iteratorError14 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion14 && _iterator14.return) {
            _iterator14.return();
          }
        } finally {
          if (_didIteratorError14) {
            throw _iteratorError14;
          }
        }
      }
    },
    changeColor: function changeColor() {
      cov_12ec53qceg.s[361]++;

      this.graphe.colors = ['rgba(' + $rootScope.personalData.activity.global.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.global.R + ',0.7)'];
      cov_12ec53qceg.s[362]++;
      $rootScope.savePersonalData();
    },
    Drawgraphe2: function Drawgraphe2() {
      var data = (cov_12ec53qceg.s[363]++, $scope.globalData);
      cov_12ec53qceg.s[364]++;
      this.graphe.labels2 = [];
      cov_12ec53qceg.s[365]++;
      this.graphe.data2 = [[], []];
      cov_12ec53qceg.s[366]++;
      var _iteratorNormalCompletion15 = true;
      var _didIteratorError15 = false;
      var _iteratorError15 = undefined;

      try {
        for (var _iterator15 = data[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
          var el = _step15.value;
          cov_12ec53qceg.s[367]++;

          this.graphe.labels2.push(el.D);
          cov_12ec53qceg.s[368]++;
          this.graphe.data2[0].push(Math.round(this.calculateAmountRate(el)));
          cov_12ec53qceg.s[369]++;
          this.graphe.data2[1].push(Math.round(this.calculateCostRate(el)));
        }
      } catch (err) {
        _didIteratorError15 = true;
        _iteratorError15 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion15 && _iterator15.return) {
            _iterator15.return();
          }
        } finally {
          if (_didIteratorError15) {
            throw _iteratorError15;
          }
        }
      }
    },

    graphe: {
      data1: [],
      labels1: [],
      data2: [],
      labels2: [],
      options: {
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              cov_12ec53qceg.s[370]++;

              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + '%';
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          display: Boolean($rootScope.getISINCLUDEDRAPPORT()),
          position: 'top'
        },
        maintainAspectRatio: false,
        elements: {
          line: {
            cubicInterpolationMode: 'monotone',
            spanGaps: false,
            tension: 0
          }
        }
      },
      series: [$translate.instant('ACTIVITY.DASHBOARD.RATE_PHY')],
      colors: ['rgba(' + $rootScope.personalData.activity.global.P + ',0.7)', 'rgba(' + $rootScope.personalData.activity.global.R + ',0.7)'],
      data_override: [{
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_PHY'),
        borderWidth: 1,
        type: $rootScope.personalData.activity.global.PT == 1 ? (cov_12ec53qceg.b[130][0]++, 'bar') : (cov_12ec53qceg.b[130][1]++, 'line'),
        fill: $rootScope.personalData.activity.global.F == 1
      }, {
        label: $translate.instant('ACTIVITY.DASHBOARD.RATE_FINANC'),
        borderWidth: 3,
        type: $rootScope.personalData.activity.global.RT == 1 ? (cov_12ec53qceg.b[131][0]++, 'bar') : (cov_12ec53qceg.b[131][1]++, 'line'),
        fill: $rootScope.personalData.activity.global.F == 1
      }]
    },
    calculateTotalAmount: function calculateTotalAmount(item) {
      var total = (cov_12ec53qceg.s[371]++, 0);
      cov_12ec53qceg.s[372]++;
      var _iteratorNormalCompletion16 = true;
      var _didIteratorError16 = false;
      var _iteratorError16 = undefined;

      try {
        for (var _iterator16 = $scope.choise.listheaderGlobal[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
          var el = _step16.value;
          cov_12ec53qceg.s[373]++;

          if (el.type == 2) {
            cov_12ec53qceg.b[132][0]++;
            cov_12ec53qceg.s[374]++;

            continue;
          } else {
            cov_12ec53qceg.b[132][1]++;
          }
          cov_12ec53qceg.s[375]++;
          if (!item.data[el.index]) {
            cov_12ec53qceg.b[133][0]++;
            cov_12ec53qceg.s[376]++;

            continue;
          } else {
            cov_12ec53qceg.b[133][1]++;
          }
          cov_12ec53qceg.s[377]++;
          total += $deltaNumber.formatNumber(item.data[el.index].SQTE_REELLE, 0); // jshint ignore:line
        }
      } catch (err) {
        _didIteratorError16 = true;
        _iteratorError16 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion16 && _iterator16.return) {
            _iterator16.return();
          }
        } finally {
          if (_didIteratorError16) {
            throw _iteratorError16;
          }
        }
      }

      cov_12ec53qceg.s[378]++;
      return total;
    },
    calculateTotalCost: function calculateTotalCost(item) {
      var total = (cov_12ec53qceg.s[379]++, 0);
      cov_12ec53qceg.s[380]++;
      var _iteratorNormalCompletion17 = true;
      var _didIteratorError17 = false;
      var _iteratorError17 = undefined;

      try {
        for (var _iterator17 = $scope.choise.listheaderGlobal[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
          var el = _step17.value;
          cov_12ec53qceg.s[381]++;

          if (el.type == 1) {
            cov_12ec53qceg.b[134][0]++;
            cov_12ec53qceg.s[382]++;

            continue;
          } else {
            cov_12ec53qceg.b[134][1]++;
          }
          cov_12ec53qceg.s[383]++;
          if (!item.data[el.index]) {
            cov_12ec53qceg.b[135][0]++;
            cov_12ec53qceg.s[384]++;

            continue;
          } else {
            cov_12ec53qceg.b[135][1]++;
          }
          cov_12ec53qceg.s[385]++;
          total += $deltaNumber.formatNumber(item.data[el.index].SCOUT_REEL); // jshint ignore:line
        }
      } catch (err) {
        _didIteratorError17 = true;
        _iteratorError17 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion17 && _iterator17.return) {
            _iterator17.return();
          }
        } finally {
          if (_didIteratorError17) {
            throw _iteratorError17;
          }
        }
      }

      cov_12ec53qceg.s[386]++;
      return total;
    },
    calculateAmountRate: function calculateAmountRate(item) {
      cov_12ec53qceg.s[387]++;

      if (angular.isDefined(item.data[0].RATE)) {
        cov_12ec53qceg.b[136][0]++;
        cov_12ec53qceg.s[388]++;

        return item.data[0].RATE;
      } else {
        cov_12ec53qceg.b[136][1]++;
      }
      var totalAmount = (cov_12ec53qceg.s[389]++, this.calculateTotalAmount(item));
      var totalPrevu = (cov_12ec53qceg.s[390]++, $deltaNumber.formatNumber(item.data[0].QTE_PREVUE)); // jshint ignore:line
      cov_12ec53qceg.s[391]++;
      if ((cov_12ec53qceg.b[138][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[138][1]++, item.data[0].DATE_DEBUT_PR)) {
        cov_12ec53qceg.b[137][0]++;
        cov_12ec53qceg.s[392]++;

        totalPrevu = $deltaNumber.formatNumber(item.data[0].QTE_PREVUE_REV); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[137][1]++;
      }
      cov_12ec53qceg.s[393]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[139][0]++;
        cov_12ec53qceg.s[394]++;

        return '';
      } else {
        cov_12ec53qceg.b[139][1]++;
      }
      cov_12ec53qceg.s[395]++;
      return 100 * (totalAmount / totalPrevu);
    },
    calculateCostRate: function calculateCostRate(item) {
      var totalCost = (cov_12ec53qceg.s[396]++, this.calculateTotalCost(item));
      var totalPrevu = (cov_12ec53qceg.s[397]++, $deltaNumber.formatNumber(item.data[0].BUDGET_PLAN)); // jshint ignore:line
      cov_12ec53qceg.s[398]++;
      if ((cov_12ec53qceg.b[141][0]++, $scope.choise.revise) && (cov_12ec53qceg.b[141][1]++, item.data[0].DATE_DEBUT_PR)) {
        cov_12ec53qceg.b[140][0]++;
        cov_12ec53qceg.s[399]++;

        totalPrevu = $deltaNumber.formatNumber(item.data[0].BUDGET_PLAN_REV); // jshint ignore:line
      } else {
        cov_12ec53qceg.b[140][1]++;
      }

      cov_12ec53qceg.s[400]++;
      if (totalPrevu == 0) {
        cov_12ec53qceg.b[142][0]++;
        cov_12ec53qceg.s[401]++;

        return '';
      } else {
        cov_12ec53qceg.b[142][1]++;
      }
      cov_12ec53qceg.s[402]++;
      return 100 * (totalCost / totalPrevu);
    },
    print: function print(typesuvi) {
      var header = (cov_12ec53qceg.s[403]++, []);
      cov_12ec53qceg.s[404]++;
      header.push($scope.firstTabLabel.replace('<br/>', '\n'));
      cov_12ec53qceg.s[405]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[143][0]++;
        cov_12ec53qceg.s[406]++;

        header.push($translate.instant('ACTIVITY.PLAN.AMOUNT_PLANNED').replace('<br/>', '\n'));
      } else {
        cov_12ec53qceg.b[143][1]++;
      }
      cov_12ec53qceg.s[407]++;
      header.push($translate.instant('ACTIVITY.PLAN.COST_PLANNED').replace('<br/>', '\n'));

      cov_12ec53qceg.s[408]++;
      var _iteratorNormalCompletion18 = true;
      var _didIteratorError18 = false;
      var _iteratorError18 = undefined;

      try {
        for (var _iterator18 = $scope.choise.listheaderGlobal[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
          var el = _step18.value;
          cov_12ec53qceg.s[409]++;

          header.push(el.label.replace('<br/>', '\n'));
        }
      } catch (err) {
        _didIteratorError18 = true;
        _iteratorError18 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion18 && _iterator18.return) {
            _iterator18.return();
          }
        } finally {
          if (_didIteratorError18) {
            throw _iteratorError18;
          }
        }
      }

      cov_12ec53qceg.s[410]++;
      if (!typesuvi) {
        cov_12ec53qceg.b[144][0]++;
        cov_12ec53qceg.s[411]++;

        header.push($translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL').replace('<br/>', '\n'));
      } else {
        cov_12ec53qceg.b[144][1]++;
      }

      cov_12ec53qceg.s[412]++;
      header.push($translate.instant('ACTIVITY.REAL.COST_ACTUAL').replace('<br/>', '\n'));

      cov_12ec53qceg.s[413]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_TARGET').replace('<br/>', '\n'));

      cov_12ec53qceg.s[414]++;
      header.push($translate.instant('ACTIVITY.DASHBOARD.RATE_BUDGET').replace('<br/>', '\n'));

      var datas = (cov_12ec53qceg.s[415]++, []);
      cov_12ec53qceg.s[416]++;
      var _iteratorNormalCompletion19 = true;
      var _didIteratorError19 = false;
      var _iteratorError19 = undefined;

      try {
        for (var _iterator19 = $scope.globalData[Symbol.iterator](), _step19; !(_iteratorNormalCompletion19 = (_step19 = _iterator19.next()).done); _iteratorNormalCompletion19 = true) {
          var eld = _step19.value;

          var item_ = (cov_12ec53qceg.s[417]++, []);
          cov_12ec53qceg.s[418]++;
          item_.push(eld.D);
          cov_12ec53qceg.s[419]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[145][0]++;
            cov_12ec53qceg.s[420]++;

            if (!$scope.choise.revise) {
              cov_12ec53qceg.b[146][0]++;
              cov_12ec53qceg.s[421]++;

              item_.push(eld.data[0].QTE_PREVUE);
            } else {
              cov_12ec53qceg.b[146][1]++;
              cov_12ec53qceg.s[422]++;

              item_.push(eld.data[0].DATE_DEBUT_PR ? (cov_12ec53qceg.b[147][0]++, eld.data[0].QTE_PREVUE_REV) : (cov_12ec53qceg.b[147][1]++, eld.data[0].QTE_PREVUE));
            }
          } else {
            cov_12ec53qceg.b[145][1]++;
          }

          cov_12ec53qceg.s[423]++;
          if (!$scope.choise.revise) {
            cov_12ec53qceg.b[148][0]++;
            cov_12ec53qceg.s[424]++;

            item_.push(eld.data[0].BUDGET_PLAN);
          } else {
            cov_12ec53qceg.b[148][1]++;
            cov_12ec53qceg.s[425]++;

            item_.push(eld.data[0].DATE_DEBUT_PR ? (cov_12ec53qceg.b[149][0]++, eld.data[0].BUDGET_PLAN_REV) : (cov_12ec53qceg.b[149][1]++, eld.data[0].BUDGET_PLAN));
          }

          cov_12ec53qceg.s[426]++;
          var _iteratorNormalCompletion20 = true;
          var _didIteratorError20 = false;
          var _iteratorError20 = undefined;

          try {
            for (var _iterator20 = $scope.choise.listheaderGlobal[Symbol.iterator](), _step20; !(_iteratorNormalCompletion20 = (_step20 = _iterator20.next()).done); _iteratorNormalCompletion20 = true) {
              var _el5 = _step20.value;
              cov_12ec53qceg.s[427]++;

              if ((cov_12ec53qceg.b[151][0]++, _el5.type == 1) && (cov_12ec53qceg.b[151][1]++, !typesuvi)) {
                cov_12ec53qceg.b[150][0]++;
                cov_12ec53qceg.s[428]++;

                item_.push(((cov_12ec53qceg.b[152][0]++, eld.data[_el5.index]) || (cov_12ec53qceg.b[152][1]++, {})).SQTE_REELLE);
              } else {
                cov_12ec53qceg.b[150][1]++;
              }
              cov_12ec53qceg.s[429]++;
              if ((cov_12ec53qceg.b[154][0]++, _el5.type == 1) && (cov_12ec53qceg.b[154][1]++, typesuvi)) {
                cov_12ec53qceg.b[153][0]++;
                cov_12ec53qceg.s[430]++;

                item_.push((((cov_12ec53qceg.b[155][0]++, eld.data[_el5.index]) || (cov_12ec53qceg.b[155][1]++, {})).RATE, 0));
              } else {
                cov_12ec53qceg.b[153][1]++;
              }
              cov_12ec53qceg.s[431]++;
              if (_el5.type == 2) {
                cov_12ec53qceg.b[156][0]++;
                cov_12ec53qceg.s[432]++;

                item_.push(((cov_12ec53qceg.b[157][0]++, eld.data[_el5.index]) || (cov_12ec53qceg.b[157][1]++, {})).SCOUT_REEL);
              } else {
                cov_12ec53qceg.b[156][1]++;
              }
            }
          } catch (err) {
            _didIteratorError20 = true;
            _iteratorError20 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion20 && _iterator20.return) {
                _iterator20.return();
              }
            } finally {
              if (_didIteratorError20) {
                throw _iteratorError20;
              }
            }
          }

          cov_12ec53qceg.s[433]++;
          if (!typesuvi) {
            cov_12ec53qceg.b[158][0]++;
            cov_12ec53qceg.s[434]++;

            item_.push(this.calculateTotalAmount(eld));
          } else {
            cov_12ec53qceg.b[158][1]++;
          }
          cov_12ec53qceg.s[435]++;
          item_.push(this.calculateTotalCost(eld));
          cov_12ec53qceg.s[436]++;
          item_.push(this.calculateAmountRate(eld) / 100);
          cov_12ec53qceg.s[437]++;
          item_.push(this.calculateCostRate(eld) / 100);

          cov_12ec53qceg.s[438]++;
          datas.push(item_);
        }
      } catch (err) {
        _didIteratorError19 = true;
        _iteratorError19 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion19 && _iterator19.return) {
            _iterator19.return();
          }
        } finally {
          if (_didIteratorError19) {
            throw _iteratorError19;
          }
        }
      }

      var titles = (cov_12ec53qceg.s[439]++, [($scope.selectedActivity.CODE_CLC + ' : ' + $scope.selectedActivity.LIBELLE_C_CL).toUpperCase()]);
      cov_12ec53qceg.s[440]++;
      titles.push($translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_SECOND'));
      cov_12ec53qceg.s[441]++;
      titles.push($translate.instant('ACTIVITY.DASHBOARD.TITLE_GRAPHE_SECOND').toUpperCase());
      // const wtitle = $translate.instant('ACTIVITY.PLAN.GLOBAL') + ' (' + $scope.selectedActivity.DATE_DEMARRAGE.getFullYear() + ' - ' + $scope.selectedActivity.DATE_FIN_ACT.getFullYear() + ')';
      var wtitle = (cov_12ec53qceg.s[442]++, $scope.headerTab[2]);

      cov_12ec53qceg.s[443]++;
      return { data: { header: header, datas: datas, titles: titles, wtitle: wtitle, colors: [$scope.personalData.activity.global.P, $scope.personalData.activity.global.R] } };
    },
    calculateTotalLine: function calculateTotalLine() {
      cov_12ec53qceg.s[444]++;

      if ($scope.globalData.length < 2) {
        cov_12ec53qceg.b[159][0]++;
        cov_12ec53qceg.s[445]++;

        return;
      } else {
        cov_12ec53qceg.b[159][1]++;
      }
      var item = (cov_12ec53qceg.s[446]++, {
        id: 0,
        D: 'Total',
        data: {}
      });
      cov_12ec53qceg.s[447]++;
      var _iteratorNormalCompletion21 = true;
      var _didIteratorError21 = false;
      var _iteratorError21 = undefined;

      try {
        for (var _iterator21 = $scope.globalData[Symbol.iterator](), _step21; !(_iteratorNormalCompletion21 = (_step21 = _iterator21.next()).done); _iteratorNormalCompletion21 = true) {
          var item_ = _step21.value;
          cov_12ec53qceg.s[448]++;

          for (var k_ in item_.data) {
            cov_12ec53qceg.s[449]++;

            if (angular.isUndefined(item.data[k_])) {
              cov_12ec53qceg.b[160][0]++;
              cov_12ec53qceg.s[450]++;

              item.data[k_] = {};
            } else {
              cov_12ec53qceg.b[160][1]++;
            }
            cov_12ec53qceg.s[451]++;
            for (var key in item_.data[k_]) {
              cov_12ec53qceg.s[452]++;

              if (angular.isUndefined(item.data[k_][key])) {
                cov_12ec53qceg.b[161][0]++;
                cov_12ec53qceg.s[453]++;

                item.data[k_][key] = 0;
              } else {
                cov_12ec53qceg.b[161][1]++;
              }
              cov_12ec53qceg.s[454]++;
              item.data[k_][key] += item_.data[k_][key] ? (cov_12ec53qceg.b[162][0]++, parseFloat(item_.data[k_][key])) : (cov_12ec53qceg.b[162][1]++, 0);
            }
          }
        }
      } catch (err) {
        _didIteratorError21 = true;
        _iteratorError21 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion21 && _iterator21.return) {
            _iterator21.return();
          }
        } finally {
          if (_didIteratorError21) {
            throw _iteratorError21;
          }
        }
      }

      cov_12ec53qceg.s[455]++;
      $scope.globalData.push(item);
    }
  };

  cov_12ec53qceg.s[456]++;
  $scope.listGraph = [{ id: 0, title: $translate.instant('COMMON.LINECHART') }, { id: 1, title: $translate.instant('COMMON.BARCHART') }];

  /* if ($deltahttp.getProjet() == 0 || ($deltahttp.getProjet() != $stateParams.params.item.ID_PROJET)) {
    $CRUDService.getAll(PATHPER, {get: 'all', idp: $stateParams.params.item.ID_PROJET}, __ => {
      $scope.listData_Periode_bksb = __;
      let year = Date.newDate().getFullYear();
      if ($stateParams.params.year) {
        year = $stateParams.params.year.ANNEE_PERIOD;
      } else {
        const id = localStorage.getItem(KEY_YEAR_BASE + $stateParams.id) || 0;
        if (id > 0) {
          for (const el of $scope.listData_Periode_bksb) {
            if (el.id == id) {
              year = el.ANNEE_PERIOD;
            }
          }
        }
      }
       $scope.choise.year = $scope.listData_Periode_bksb[0];
      for (const el of $scope.listData_Periode_bksb) {
        if (el.ANNEE_PERIOD == year) {
          $scope.choise.year = el;
        }
        $scope.choise.listheaderGlobal.push({labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 1});
        $scope.choise.listheaderGlobal.push({labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.COST_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 2});
      }
      $scope.changeYear();
    });
  } else {
    $deltaPeriode.addController($scope, () => {
      let year = Date.newDate().getFullYear();
      if ($stateParams.params.year) {
        year = $stateParams.params.year.ANNEE_PERIOD;
      } else {
        const id = localStorage.getItem(KEY_YEAR_BASE + $stateParams.id) || 0;
        if (id > 0) {
          for (const el of $scope.listData_Periode_bksb) {
            if (el.id == id) {
              year = el.ANNEE_PERIOD;
            }
          }
        }
      }
       $scope.choise.year = $scope.listData_Periode_bksb[0];
      for (const el of $scope.listData_Periode_bksb) {
        if (el.ANNEE_PERIOD == year) {
          $scope.choise.year = el;
        }
        $scope.choise.listheaderGlobal.push({labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 1});
        $scope.choise.listheaderGlobal.push({labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.COST_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 2});
      }
      $scope.changeYear();
    });
  } */

  cov_12ec53qceg.s[457]++;
  $scope.changeValidateData = function () {
    cov_12ec53qceg.f[12]++;
    cov_12ec53qceg.s[458]++;

    $scope.initDashboard($stateParams.id);
  };
  cov_12ec53qceg.s[459]++;
  $scope.cancel = function () {
    cov_12ec53qceg.f[13]++;

    var origin = (cov_12ec53qceg.s[460]++, angular.copy($stateParams.origin));
    cov_12ec53qceg.s[461]++;
    $stateParams.origin = 'activity.planning.dashboard';
    cov_12ec53qceg.s[462]++;
    $state.go(origin, $stateParams);
  };
  cov_12ec53qceg.s[463]++;
  $scope.initDashboard = function (item) {
    cov_12ec53qceg.f[14]++;
    cov_12ec53qceg.s[464]++;

    $scope.global.displaycost = localStorage.getItem(KEY_COST_BASE + item) !== '0';
    cov_12ec53qceg.s[465]++;
    $scope.choise.revise = localStorage.getItem(KEY_TYPE_BASE + item) !== '0';

    cov_12ec53qceg.s[466]++;
    $CRUDService.getAll(PATH__, { get: 'one', id: $stateParams.id }, function (data) {
      cov_12ec53qceg.f[15]++;
      cov_12ec53qceg.s[467]++;

      data.DATE_DEMARRAGE = Date.newDate(data.DATE_BEGIN_ACTIVITY);
      cov_12ec53qceg.s[468]++;
      data.DATE_FIN_ACT = Date.newDate(data.DATE_END_ACTIVITY);
      cov_12ec53qceg.s[469]++;
      $scope.headerTab[2] = 'Global';
      cov_12ec53qceg.s[470]++;
      $scope.tab_dashboard = 2;
      cov_12ec53qceg.s[471]++;
      $scope.indexLoading = 0;
      cov_12ec53qceg.s[472]++;
      $scope.selectedActivity = data;
      cov_12ec53qceg.s[473]++;
      $scope.changeDashboardTab((cov_12ec53qceg.b[163][0]++, localStorage.getItem(KEY_TAB_BASE + item)) || (cov_12ec53qceg.b[163][1]++, '2'));
      cov_12ec53qceg.s[474]++;
      $scope.listProjects = [{
        portfolio: true,
        ID: $scope.selectedActivity.id,
        LABEL: $translate.instant('COMMON.ALL'),
        ID_PROJET: 0,
        START: 1,
        PERIODS: $deltadate.getYearBetween(data.DATE_DEMARRAGE, data.DATE_FIN_ACT, ((cov_12ec53qceg.b[164][0]++, $scope.choise.project) || (cov_12ec53qceg.b[164][1]++, {})).START).map(function (value) {
          cov_12ec53qceg.f[16]++;
          cov_12ec53qceg.s[475]++;

          return {
            ANNEE_PERIOD: value.name,
            ID_PROJET: 0,
            id: value.id
          };
        })
      }];
      cov_12ec53qceg.s[476]++;
      $scope.choise.project = $scope.listProjects[0];
      /* $scope.firstTabLabel = $translate.instant(
          $scope.choise.project.portfolio ? 'ACTIVITY.PROJECT' : 'ACTIVITY.PLAN.LOCATION'
      ); */
      cov_12ec53qceg.s[477]++;
      $CRUDService.getAll('Relever_B_Niv', { get: 'concern_projects_portfolio', id: $scope.selectedActivity.id }, function (data_project) {
        cov_12ec53qceg.f[17]++;
        cov_12ec53qceg.s[478]++;

        $scope.listProjects = data_project.concat($scope.listProjects);
        cov_12ec53qceg.s[479]++;
        $scope.choise.project = $scope.listProjects[$scope.listProjects.length - 1];
      });
      // $scope.initialiseData();

      cov_12ec53qceg.s[480]++;
      $scope.changeProject();
    });
  };
  cov_12ec53qceg.s[481]++;
  $scope.initialiseData = function () {
    cov_12ec53qceg.f[18]++;

    var param = (cov_12ec53qceg.s[482]++, { get: 'dashboard_global', valid: $scope.choise.validatedData ? (cov_12ec53qceg.b[165][0]++, 1) : (cov_12ec53qceg.b[165][1]++, 0), activ: this.selectedActivity.id });
    cov_12ec53qceg.s[483]++;
    if ($scope.choise.project) {
      cov_12ec53qceg.b[166][0]++;
      cov_12ec53qceg.s[484]++;

      if ($scope.choise.project.portfolio) {
        cov_12ec53qceg.b[167][0]++;
        cov_12ec53qceg.s[485]++;

        param.get = 'dashboard_global_portfolio';
      } else {
        cov_12ec53qceg.b[167][1]++;
      }
    } else {
      cov_12ec53qceg.b[166][1]++;
    }
    cov_12ec53qceg.s[486]++;
    $CRUDService.getAll(PATHP, param, function (data) {
      cov_12ec53qceg.f[19]++;
      cov_12ec53qceg.s[487]++;

      $scope.globalData = data;
      cov_12ec53qceg.s[488]++;
      $scope.global.Drawgraphe2();
      cov_12ec53qceg.s[489]++;
      $scope.global.calculateTotalLine();
      // Calculate Global

      // $scope.global.Drawgraphe1(data[0]);
      cov_12ec53qceg.s[490]++;
      $scope.changeYear();
    });
  };
  cov_12ec53qceg.s[491]++;
  $scope.changeProject = function () {
    cov_12ec53qceg.f[20]++;
    cov_12ec53qceg.s[492]++;

    if ((cov_12ec53qceg.b[169][0]++, $scope.choise.project.ID_PROJET == 0) && (cov_12ec53qceg.b[169][1]++, $scope.tab_dashboard == 1)) {
      cov_12ec53qceg.b[168][0]++;
      cov_12ec53qceg.s[493]++;

      $scope.tab_dashboard = 2;
    } else {
      cov_12ec53qceg.b[168][1]++;
    }
    cov_12ec53qceg.s[494]++;
    $scope.firstTabLabel = $translate.instant($scope.choise.project.portfolio ? (cov_12ec53qceg.b[170][0]++, 'ACTIVITY.PROJECT') : (cov_12ec53qceg.b[170][1]++, 'ACTIVITY.PLAN.LOCATION'));
    cov_12ec53qceg.s[495]++;
    $scope.choise.listheaderGlobal = [];
    cov_12ec53qceg.s[496]++;
    var _iteratorNormalCompletion22 = true;
    var _didIteratorError22 = false;
    var _iteratorError22 = undefined;

    try {
      for (var _iterator22 = $scope.choise.project.PERIODS[Symbol.iterator](), _step22; !(_iteratorNormalCompletion22 = (_step22 = _iterator22.next()).done); _iteratorNormalCompletion22 = true) {
        var el = _step22.value;
        cov_12ec53qceg.s[497]++;

        $scope.choise.listheaderGlobal.push({ labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.AMOUNT_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 1 });
        cov_12ec53qceg.s[498]++;
        $scope.choise.listheaderGlobal.push({ labelYear: el.ANNEE_PERIOD, label: $translate.instant('ACTIVITY.REAL.COST_ACTUAL') + '<br/>' + el.ANNEE_PERIOD, index: el.ANNEE_PERIOD, type: 2 });
      }
    } catch (err) {
      _didIteratorError22 = true;
      _iteratorError22 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion22 && _iterator22.return) {
          _iterator22.return();
        }
      } finally {
        if (_didIteratorError22) {
          throw _iteratorError22;
        }
      }
    }

    cov_12ec53qceg.s[499]++;
    $scope.choise.year = $scope.choise.project.PERIODS[0];
    cov_12ec53qceg.s[500]++;
    $scope.selectedActivity.id = $scope.choise.project.ID;
    cov_12ec53qceg.s[501]++;
    $scope.initialiseData();
  };
  cov_12ec53qceg.s[502]++;
  $scope.isloading = true;
  cov_12ec53qceg.s[503]++;
  $scope.initDashboard($stateParams.id);
  // $scope.init();
  // $scope.changeYear();
});
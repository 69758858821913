'use strict';

var cov_17hj6d0mxg = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/procurement_plan/procurement_planCtrl.js',
      hash = '965e7bd30c0399a8f9272685acc7dfc4fe4524cd',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/procurement_plan/procurement_planCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 155,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 42
        }
      },
      '2': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 28
        }
      },
      '3': {
        start: {
          line: 4,
          column: 20
        },
        end: {
          line: 4,
          column: 29
        }
      },
      '4': {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 69,
          column: 4
        }
      },
      '5': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 12,
          column: 25
        }
      },
      '6': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '7': {
        start: {
          line: 14,
          column: 8
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '8': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 32
        }
      },
      '9': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 41
        }
      },
      '10': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 53,
          column: 9
        }
      },
      '11': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 39,
          column: 9
        }
      },
      '12': {
        start: {
          line: 38,
          column: 10
        },
        end: {
          line: 38,
          column: 17
        }
      },
      '13': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 35
        }
      },
      '14': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 52,
          column: 11
        }
      },
      '15': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 38
        }
      },
      '16': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 49,
          column: 11
        }
      },
      '17': {
        start: {
          line: 44,
          column: 12
        },
        end: {
          line: 47,
          column: 15
        }
      },
      '18': {
        start: {
          line: 48,
          column: 12
        },
        end: {
          line: 48,
          column: 19
        }
      },
      '19': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 31
        }
      },
      '20': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 51,
          column: 67
        }
      },
      '21': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 63,
          column: 9
        }
      },
      '22': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 36
        }
      },
      '23': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 60,
          column: 9
        }
      },
      '24': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 17
        }
      },
      '25': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 61,
          column: 29
        }
      },
      '26': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 62,
          column: 65
        }
      },
      '27': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 25
        }
      },
      '28': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 27
        }
      },
      '29': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 154,
          column: 4
        }
      },
      '30': {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 86,
          column: 7
        }
      },
      '31': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 29
        }
      },
      '32': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 83,
          column: 11
        }
      },
      '33': {
        start: {
          line: 85,
          column: 8
        },
        end: {
          line: 85,
          column: 43
        }
      },
      '34': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '35': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 90,
          column: 15
        }
      },
      '36': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 29
        }
      },
      '37': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 33
        }
      },
      '38': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 97,
          column: 9
        }
      },
      '39': {
        start: {
          line: 95,
          column: 8
        },
        end: {
          line: 95,
          column: 32
        }
      },
      '40': {
        start: {
          line: 96,
          column: 8
        },
        end: {
          line: 96,
          column: 36
        }
      },
      '41': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 132,
          column: 9
        }
      },
      '42': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 117,
          column: 9
        }
      },
      '43': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 17
        }
      },
      '44': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 118,
          column: 44
        }
      },
      '45': {
        start: {
          line: 119,
          column: 8
        },
        end: {
          line: 131,
          column: 11
        }
      },
      '46': {
        start: {
          line: 120,
          column: 10
        },
        end: {
          line: 120,
          column: 47
        }
      },
      '47': {
        start: {
          line: 121,
          column: 10
        },
        end: {
          line: 127,
          column: 11
        }
      },
      '48': {
        start: {
          line: 122,
          column: 12
        },
        end: {
          line: 125,
          column: 15
        }
      },
      '49': {
        start: {
          line: 126,
          column: 12
        },
        end: {
          line: 126,
          column: 19
        }
      },
      '50': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 47
        }
      },
      '51': {
        start: {
          line: 129,
          column: 10
        },
        end: {
          line: 129,
          column: 38
        }
      },
      '52': {
        start: {
          line: 130,
          column: 10
        },
        end: {
          line: 130,
          column: 45
        }
      },
      '53': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 135,
          column: 27
        }
      },
      '54': {
        start: {
          line: 136,
          column: 6
        },
        end: {
          line: 136,
          column: 25
        }
      },
      '55': {
        start: {
          line: 139,
          column: 6
        },
        end: {
          line: 141,
          column: 7
        }
      },
      '56': {
        start: {
          line: 140,
          column: 8
        },
        end: {
          line: 140,
          column: 15
        }
      },
      '57': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 144,
          column: 7
        }
      },
      '58': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 15
        }
      },
      '59': {
        start: {
          line: 145,
          column: 23
        },
        end: {
          line: 145,
          column: 35
        }
      },
      '60': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 42
        }
      },
      '61': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 152,
          column: 9
        }
      },
      '62': {
        start: {
          line: 148,
          column: 8
        },
        end: {
          line: 148,
          column: 45
        }
      },
      '63': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 149,
          column: 21
        }
      },
      '64': {
        start: {
          line: 150,
          column: 8
        },
        end: {
          line: 150,
          column: 36
        }
      },
      '65': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 49
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 57
          },
          end: {
            line: 1,
            column: 58
          }
        },
        loc: {
          start: {
            line: 1,
            column: 166
          },
          end: {
            line: 155,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 36,
            column: 9
          },
          end: {
            line: 36,
            column: 10
          }
        },
        loc: {
          start: {
            line: 36,
            column: 22
          },
          end: {
            line: 53,
            column: 7
          }
        },
        line: 36
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 41,
            column: 89
          },
          end: {
            line: 41,
            column: 90
          }
        },
        loc: {
          start: {
            line: 41,
            column: 97
          },
          end: {
            line: 52,
            column: 9
          }
        },
        line: 41
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 56,
            column: 101
          },
          end: {
            line: 56,
            column: 102
          }
        },
        loc: {
          start: {
            line: 56,
            column: 109
          },
          end: {
            line: 63,
            column: 7
          }
        },
        line: 56
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 94,
            column: 74
          },
          end: {
            line: 94,
            column: 75
          }
        },
        loc: {
          start: {
            line: 94,
            column: 82
          },
          end: {
            line: 97,
            column: 7
          }
        },
        line: 94
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 114,
            column: 9
          },
          end: {
            line: 114,
            column: 10
          }
        },
        loc: {
          start: {
            line: 114,
            column: 22
          },
          end: {
            line: 132,
            column: 7
          }
        },
        line: 114
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 119,
            column: 84
          },
          end: {
            line: 119,
            column: 85
          }
        },
        loc: {
          start: {
            line: 119,
            column: 92
          },
          end: {
            line: 131,
            column: 9
          }
        },
        line: 119
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 147,
            column: 79
          },
          end: {
            line: 147,
            column: 80
          }
        },
        loc: {
          start: {
            line: 147,
            column: 85
          },
          end: {
            line: 152,
            column: 7
          }
        },
        line: 147
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }, {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 21,
            column: 7
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 39,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 39,
            column: 9
          }
        }, {
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 39,
            column: 9
          }
        }],
        line: 37
      },
      '2': {
        loc: {
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 49,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 49,
            column: 11
          }
        }, {
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 49,
            column: 11
          }
        }],
        line: 43
      },
      '3': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }, {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 60,
            column: 9
          }
        }],
        line: 58
      },
      '4': {
        loc: {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        }, {
          start: {
            line: 78,
            column: 6
          },
          end: {
            line: 86,
            column: 7
          }
        }],
        line: 78
      },
      '5': {
        loc: {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        }, {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        }],
        line: 89
      },
      '6': {
        loc: {
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        }, {
          start: {
            line: 115,
            column: 8
          },
          end: {
            line: 117,
            column: 9
          }
        }],
        line: 115
      },
      '7': {
        loc: {
          start: {
            line: 121,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }, {
          start: {
            line: 121,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }],
        line: 121
      },
      '8': {
        loc: {
          start: {
            line: 139,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 139,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }, {
          start: {
            line: 139,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }],
        line: 139
      },
      '9': {
        loc: {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }, {
          start: {
            line: 142,
            column: 6
          },
          end: {
            line: 144,
            column: 7
          }
        }],
        line: 142
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_17hj6d0mxg.s[0]++;
angular.module('app').controller('procurement_planCtrl', function ($scope, SweetAlert, $deltaPlanMarches, $rootScope, $translate, $CRUDService, $otherHttpService, $filter) {
  cov_17hj6d0mxg.f[0]++;
  cov_17hj6d0mxg.s[1]++;

  $deltaPlanMarches.addController($scope);
  var PATH = (cov_17hj6d0mxg.s[2]++, 'PlanMarches');
  var PATH_STEP = (cov_17hj6d0mxg.s[3]++, 'Marches');

  cov_17hj6d0mxg.s[4]++;
  $scope.step = {
    list: [],
    listEdit: [],
    loading: false,
    itemEdit: null,
    edit: function edit(item) {
      cov_17hj6d0mxg.s[5]++;

      this.listEdit = [];
      cov_17hj6d0mxg.s[6]++;
      if (!item) {
        cov_17hj6d0mxg.b[0][0]++;
        cov_17hj6d0mxg.s[7]++;

        this.listEdit.push({
          ETAPES_MARCHE: this.list.length + 1,
          IDPLANMARCHES: $scope.procurement.selectItem.id,
          LIBELLE_MARCHE: '',
          id: 0
        });
        cov_17hj6d0mxg.s[8]++;
        item = this.listEdit[0];
      } else {
        cov_17hj6d0mxg.b[0][1]++;
      }
      cov_17hj6d0mxg.s[9]++;
      this.itemEdit = angular.copy(item);
    },
    delet: function delet(item) {
      cov_17hj6d0mxg.s[10]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("PROCUREMENT.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_17hj6d0mxg.f[1]++;
        cov_17hj6d0mxg.s[11]++;

        if (isconfirm) {
          cov_17hj6d0mxg.b[1][0]++;
          cov_17hj6d0mxg.s[12]++;

          return;
        } else {
          cov_17hj6d0mxg.b[1][1]++;
        }
        cov_17hj6d0mxg.s[13]++;
        $scope.step.loading = true;
        cov_17hj6d0mxg.s[14]++;
        $CRUDService.delet(PATH_STEP, { action: 'delete', valeur: $filter('json')(item) }, function (data) {
          cov_17hj6d0mxg.f[2]++;
          cov_17hj6d0mxg.s[15]++;

          $scope.step.loading = false;
          cov_17hj6d0mxg.s[16]++;
          if (data < 1) {
            cov_17hj6d0mxg.b[2][0]++;
            cov_17hj6d0mxg.s[17]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_17hj6d0mxg.s[18]++;
            return;
          } else {
            cov_17hj6d0mxg.b[2][1]++;
          }
          cov_17hj6d0mxg.s[19]++;
          $scope.step.cancel();
          cov_17hj6d0mxg.s[20]++;
          $scope.procurement.select($scope.procurement.selectItem);
        });
      });
    },
    validate: function validate() {
      cov_17hj6d0mxg.s[21]++;

      $CRUDService.delet(PATH_STEP, { action: 'save', valeur: $filter('json')($scope.step.itemEdit) }, function (data) {
        cov_17hj6d0mxg.f[3]++;
        cov_17hj6d0mxg.s[22]++;

        $scope.step.loading = false;
        cov_17hj6d0mxg.s[23]++;
        if (data < 1) {
          cov_17hj6d0mxg.b[3][0]++;
          cov_17hj6d0mxg.s[24]++;

          return;
        } else {
          cov_17hj6d0mxg.b[3][1]++;
        }
        cov_17hj6d0mxg.s[25]++;
        $scope.step.cancel();
        cov_17hj6d0mxg.s[26]++;
        $scope.procurement.select($scope.procurement.selectItem);
      });
    },
    cancel: function cancel() {
      cov_17hj6d0mxg.s[27]++;

      this.listEdit = [];
      cov_17hj6d0mxg.s[28]++;
      this.itemEdit = null;
    }
  };

  cov_17hj6d0mxg.s[29]++;
  $scope.procurement = {
    isLoading: false,
    listEdit: [],
    editItem: null,
    panel: true,
    selectItem: null,
    edit: function edit(item) {
      cov_17hj6d0mxg.s[30]++;

      if (!item) {
        cov_17hj6d0mxg.b[4][0]++;
        cov_17hj6d0mxg.s[31]++;

        this.editItem = null;
        cov_17hj6d0mxg.s[32]++;
        this.listEdit = [{
          CODE_MARCHE: '', LIBELLE_MARCHES: '',
          STEP: null, SUIVRELOC_PLANMARCHE: '0', id: 0
        }];
      } else {
        cov_17hj6d0mxg.b[4][1]++;
        cov_17hj6d0mxg.s[33]++;

        this.editItem = angular.copy(item);
      }
    },
    select: function select(item) {
      cov_17hj6d0mxg.s[34]++;

      if (this.editItem) {
        cov_17hj6d0mxg.b[5][0]++;
        cov_17hj6d0mxg.s[35]++;

        return;
      } else {
        cov_17hj6d0mxg.b[5][1]++;
      }
      cov_17hj6d0mxg.s[36]++;
      this.selectItem = item;
      cov_17hj6d0mxg.s[37]++;
      $scope.step.loading = true;
      cov_17hj6d0mxg.s[38]++;
      $CRUDService.getAll(PATH_STEP, { get: 'allbyplan', idplan: item.id }, function (data) {
        cov_17hj6d0mxg.f[4]++;
        cov_17hj6d0mxg.s[39]++;

        $scope.step.list = data;
        cov_17hj6d0mxg.s[40]++;
        $scope.step.loading = false;
      });
    },
    showpanel: function showpanel() {},
    delet: function delet(item) {
      cov_17hj6d0mxg.s[41]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("PROCUREMENT.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_17hj6d0mxg.f[5]++;
        cov_17hj6d0mxg.s[42]++;

        if (isconfirm) {
          cov_17hj6d0mxg.b[6][0]++;
          cov_17hj6d0mxg.s[43]++;

          return;
        } else {
          cov_17hj6d0mxg.b[6][1]++;
        }
        cov_17hj6d0mxg.s[44]++;
        $scope.procurement.isLoading = true;
        cov_17hj6d0mxg.s[45]++;
        $CRUDService.delet(PATH, { action: 'delete', valeur: $filter('json')(item) }, function (data) {
          cov_17hj6d0mxg.f[6]++;
          cov_17hj6d0mxg.s[46]++;

          $scope.procurement.isLoading = false;
          cov_17hj6d0mxg.s[47]++;
          if (data < 1) {
            cov_17hj6d0mxg.b[7][0]++;
            cov_17hj6d0mxg.s[48]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_17hj6d0mxg.s[49]++;
            return;
          } else {
            cov_17hj6d0mxg.b[7][1]++;
          }
          cov_17hj6d0mxg.s[50]++;
          $scope.procurement.selectItem = null;
          cov_17hj6d0mxg.s[51]++;
          $scope.procurement.cancel();
          cov_17hj6d0mxg.s[52]++;
          $deltaPlanMarches.removeData(item);
        });
      });
    },
    cancel: function cancel() {
      cov_17hj6d0mxg.s[53]++;

      this.editItem = null;
      cov_17hj6d0mxg.s[54]++;
      this.listEdit = [];
    },
    validate: function validate(item) {
      cov_17hj6d0mxg.s[55]++;

      if (item.LIBELLE_MARCHES.trim() == '') {
        cov_17hj6d0mxg.b[8][0]++;
        cov_17hj6d0mxg.s[56]++;

        return;
      } else {
        cov_17hj6d0mxg.b[8][1]++;
      }
      cov_17hj6d0mxg.s[57]++;
      if (item.CODE_MARCHE.trim() == '') {
        cov_17hj6d0mxg.b[9][0]++;
        cov_17hj6d0mxg.s[58]++;

        return;
      } else {
        cov_17hj6d0mxg.b[9][1]++;
      }
      var newdata = (cov_17hj6d0mxg.s[59]++, item.id != 0);
      cov_17hj6d0mxg.s[60]++;
      $scope.procurement.isLoading = true;
      cov_17hj6d0mxg.s[61]++;
      $CRUDService.save(PATH, { action: 'save', valeur: $filter('json')(item) }, function (id) {
        cov_17hj6d0mxg.f[7]++;
        cov_17hj6d0mxg.s[62]++;

        $scope.procurement.isLoading = false;
        cov_17hj6d0mxg.s[63]++;
        item.id = id;
        cov_17hj6d0mxg.s[64]++;
        $scope.procurement.cancel();
        cov_17hj6d0mxg.s[65]++;
        $deltaPlanMarches.addData(item, newdata);
      });
    }
  };
});
"use strict";

var cov_2qd4tuuddr = function () {
  var path = "/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/analytics/analyticsCtrl.js",
      hash = "f5b65d6a7ee8f92fb78336744e8a7e058850586e",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/analytics/analyticsCtrl.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();
'use strict';

var cov_1kgks8ggwl = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities/right/rightCtrl.js',
      hash = 'fc88b538bc20e4f702c4c4d5149625d50ea66fdd',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/activities/right/rightCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 164,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 51
        }
      },
      '2': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 30
        }
      },
      '3': {
        start: {
          line: 4,
          column: 20
        },
        end: {
          line: 4,
          column: 31
        }
      },
      '4': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 6,
          column: 36
        }
      },
      '5': {
        start: {
          line: 7,
          column: 23
        },
        end: {
          line: 7,
          column: 31
        }
      },
      '6': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 36
        }
      },
      '7': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 12,
          column: 4
        }
      },
      '8': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 137
        }
      },
      '9': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 17,
          column: 4
        }
      },
      '10': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 21,
          column: 4
        }
      },
      '11': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 27,
          column: 4
        }
      },
      '12': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 26,
          column: 5
        }
      },
      '13': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 57
        }
      },
      '14': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 32,
          column: 4
        }
      },
      '15': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 31,
          column: 5
        }
      },
      '16': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 58
        }
      },
      '17': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 42,
          column: 4
        }
      },
      '18': {
        start: {
          line: 35,
          column: 4
        },
        end: {
          line: 37,
          column: 5
        }
      },
      '19': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 36,
          column: 18
        }
      },
      '20': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '21': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 18
        }
      },
      '22': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 41,
          column: 17
        }
      },
      '23': {
        start: {
          line: 43,
          column: 2
        },
        end: {
          line: 51,
          column: 4
        }
      },
      '24': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '25': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 18
        }
      },
      '26': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '27': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 18
        }
      },
      '28': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 17
        }
      },
      '29': {
        start: {
          line: 52,
          column: 2
        },
        end: {
          line: 60,
          column: 4
        }
      },
      '30': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '31': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 20
        }
      },
      '32': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 35
        }
      },
      '33': {
        start: {
          line: 61,
          column: 2
        },
        end: {
          line: 81,
          column: 4
        }
      },
      '34': {
        start: {
          line: 62,
          column: 14
        },
        end: {
          line: 62,
          column: 17
        }
      },
      '35': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 68,
          column: 5
        }
      },
      '36': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 66,
          column: 7
        }
      },
      '37': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 17
        }
      },
      '38': {
        start: {
          line: 67,
          column: 6
        },
        end: {
          line: 67,
          column: 24
        }
      },
      '39': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 17
        }
      },
      '40': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 75,
          column: 5
        }
      },
      '41': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 73,
          column: 7
        }
      },
      '42': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 72,
          column: 17
        }
      },
      '43': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 24
        }
      },
      '44': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 64
        }
      },
      '45': {
        start: {
          line: 77,
          column: 4
        },
        end: {
          line: 79,
          column: 7
        }
      },
      '46': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 80,
          column: 20
        }
      },
      '47': {
        start: {
          line: 82,
          column: 2
        },
        end: {
          line: 84,
          column: 4
        }
      },
      '48': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 83,
          column: 20
        }
      },
      '49': {
        start: {
          line: 85,
          column: 2
        },
        end: {
          line: 92,
          column: 4
        }
      },
      '50': {
        start: {
          line: 86,
          column: 4
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '51': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 90,
          column: 9
        }
      },
      '52': {
        start: {
          line: 88,
          column: 8
        },
        end: {
          line: 88,
          column: 59
        }
      },
      '53': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 20
        }
      },
      '54': {
        start: {
          line: 94,
          column: 2
        },
        end: {
          line: 154,
          column: 5
        }
      },
      '55': {
        start: {
          line: 95,
          column: 4
        },
        end: {
          line: 143,
          column: 5
        }
      },
      '56': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 98,
          column: 7
        }
      },
      '57': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 97,
          column: 17
        }
      },
      '58': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 99,
          column: 58
        }
      },
      '59': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 54
        }
      },
      '60': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 61
        }
      },
      '61': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 68
        }
      },
      '62': {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 103,
          column: 66
        }
      },
      '63': {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '64': {
        start: {
          line: 105,
          column: 8
        },
        end: {
          line: 105,
          column: 72
        }
      },
      '65': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 39
        }
      },
      '66': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 113,
          column: 7
        }
      },
      '67': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 70
        }
      },
      '68': {
        start: {
          line: 112,
          column: 8
        },
        end: {
          line: 112,
          column: 38
        }
      },
      '69': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 118,
          column: 7
        }
      },
      '70': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 66
        }
      },
      '71': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 117,
          column: 36
        }
      },
      '72': {
        start: {
          line: 119,
          column: 26
        },
        end: {
          line: 122,
          column: 7
        }
      },
      '73': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 141,
          column: 7
        }
      },
      '74': {
        start: {
          line: 124,
          column: 20
        },
        end: {
          line: 124,
          column: 44
        }
      },
      '75': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 140,
          column: 9
        }
      },
      '76': {
        start: {
          line: 126,
          column: 10
        },
        end: {
          line: 139,
          column: 11
        }
      },
      '77': {
        start: {
          line: 127,
          column: 23
        },
        end: {
          line: 127,
          column: 40
        }
      },
      '78': {
        start: {
          line: 128,
          column: 12
        },
        end: {
          line: 138,
          column: 13
        }
      },
      '79': {
        start: {
          line: 129,
          column: 14
        },
        end: {
          line: 131,
          column: 15
        }
      },
      '80': {
        start: {
          line: 130,
          column: 16
        },
        end: {
          line: 130,
          column: 25
        }
      },
      '81': {
        start: {
          line: 132,
          column: 14
        },
        end: {
          line: 134,
          column: 15
        }
      },
      '82': {
        start: {
          line: 133,
          column: 16
        },
        end: {
          line: 133,
          column: 44
        }
      },
      '83': {
        start: {
          line: 135,
          column: 14
        },
        end: {
          line: 137,
          column: 15
        }
      },
      '84': {
        start: {
          line: 136,
          column: 16
        },
        end: {
          line: 136,
          column: 44
        }
      },
      '85': {
        start: {
          line: 142,
          column: 6
        },
        end: {
          line: 142,
          column: 33
        }
      },
      '86': {
        start: {
          line: 144,
          column: 4
        },
        end: {
          line: 144,
          column: 28
        }
      },
      '87': {
        start: {
          line: 145,
          column: 4
        },
        end: {
          line: 145,
          column: 38
        }
      },
      '88': {
        start: {
          line: 147,
          column: 4
        },
        end: {
          line: 150,
          column: 6
        }
      },
      '89': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 152,
          column: 142
        }
      },
      '90': {
        start: {
          line: 153,
          column: 4
        },
        end: {
          line: 153,
          column: 21
        }
      },
      '91': {
        start: {
          line: 155,
          column: 2
        },
        end: {
          line: 163,
          column: 3
        }
      },
      '92': {
        start: {
          line: 156,
          column: 4
        },
        end: {
          line: 156,
          column: 52
        }
      },
      '93': {
        start: {
          line: 157,
          column: 4
        },
        end: {
          line: 161,
          column: 5
        }
      },
      '94': {
        start: {
          line: 158,
          column: 6
        },
        end: {
          line: 160,
          column: 107
        }
      },
      '95': {
        start: {
          line: 159,
          column: 16
        },
        end: {
          line: 160,
          column: 105
        }
      },
      '96': {
        start: {
          line: 162,
          column: 4
        },
        end: {
          line: 162,
          column: 24
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 56
          },
          end: {
            line: 1,
            column: 57
          }
        },
        loc: {
          start: {
            line: 1,
            column: 150
          },
          end: {
            line: 164,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 20
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 36
          },
          end: {
            line: 12,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 26
          },
          end: {
            line: 23,
            column: 27
          }
        },
        loc: {
          start: {
            line: 23,
            column: 38
          },
          end: {
            line: 27,
            column: 3
          }
        },
        line: 23
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 28,
            column: 26
          },
          end: {
            line: 28,
            column: 27
          }
        },
        loc: {
          start: {
            line: 28,
            column: 38
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 28
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 34,
            column: 27
          },
          end: {
            line: 34,
            column: 28
          }
        },
        loc: {
          start: {
            line: 34,
            column: 63
          },
          end: {
            line: 42,
            column: 3
          }
        },
        line: 34
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 43,
            column: 26
          },
          end: {
            line: 43,
            column: 27
          }
        },
        loc: {
          start: {
            line: 43,
            column: 62
          },
          end: {
            line: 51,
            column: 3
          }
        },
        line: 43
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 52,
            column: 16
          },
          end: {
            line: 52,
            column: 17
          }
        },
        loc: {
          start: {
            line: 52,
            column: 32
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 52
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 61,
            column: 16
          },
          end: {
            line: 61,
            column: 17
          }
        },
        loc: {
          start: {
            line: 61,
            column: 28
          },
          end: {
            line: 81,
            column: 3
          }
        },
        line: 61
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 77,
            column: 97
          },
          end: {
            line: 77,
            column: 98
          }
        },
        loc: {
          start: {
            line: 77,
            column: 105
          },
          end: {
            line: 79,
            column: 5
          }
        },
        line: 77
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 82,
            column: 17
          },
          end: {
            line: 82,
            column: 18
          }
        },
        loc: {
          start: {
            line: 82,
            column: 29
          },
          end: {
            line: 84,
            column: 3
          }
        },
        line: 82
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 85,
            column: 19
          },
          end: {
            line: 85,
            column: 20
          }
        },
        loc: {
          start: {
            line: 85,
            column: 31
          },
          end: {
            line: 92,
            column: 3
          }
        },
        line: 85
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 86,
            column: 51
          },
          end: {
            line: 86,
            column: 52
          }
        },
        loc: {
          start: {
            line: 86,
            column: 59
          },
          end: {
            line: 91,
            column: 5
          }
        },
        line: 86
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 87,
            column: 30
          },
          end: {
            line: 87,
            column: 31
          }
        },
        loc: {
          start: {
            line: 87,
            column: 38
          },
          end: {
            line: 90,
            column: 7
          }
        },
        line: 87
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 94,
            column: 71
          },
          end: {
            line: 94,
            column: 72
          }
        },
        loc: {
          start: {
            line: 94,
            column: 79
          },
          end: {
            line: 154,
            column: 3
          }
        },
        line: 94
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 155,
            column: 31
          },
          end: {
            line: 155,
            column: 32
          }
        },
        loc: {
          start: {
            line: 155,
            column: 43
          },
          end: {
            line: 163,
            column: 3
          }
        },
        line: 155
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 159,
            column: 9
          }
        },
        loc: {
          start: {
            line: 159,
            column: 16
          },
          end: {
            line: 160,
            column: 105
          }
        },
        line: 159
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }, {
          start: {
            line: 35,
            column: 4
          },
          end: {
            line: 37,
            column: 5
          }
        }],
        line: 35
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        }, {
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }, {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 47,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }, {
          start: {
            line: 47,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }],
        line: 47
      },
      '4': {
        loc: {
          start: {
            line: 53,
            column: 16
          },
          end: {
            line: 56,
            column: 5
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 53,
            column: 16
          },
          end: {
            line: 53,
            column: 30
          }
        }, {
          start: {
            line: 53,
            column: 34
          },
          end: {
            line: 56,
            column: 5
          }
        }],
        line: 53
      },
      '5': {
        loc: {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }, {
          start: {
            line: 64,
            column: 6
          },
          end: {
            line: 66,
            column: 7
          }
        }],
        line: 64
      },
      '6': {
        loc: {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }, {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 73,
            column: 7
          }
        }],
        line: 71
      },
      '7': {
        loc: {
          start: {
            line: 94,
            column: 48
          },
          end: {
            line: 94,
            column: 68
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 94,
            column: 48
          },
          end: {
            line: 94,
            column: 63
          }
        }, {
          start: {
            line: 94,
            column: 67
          },
          end: {
            line: 94,
            column: 68
          }
        }],
        line: 94
      },
      '8': {
        loc: {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        }, {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 98,
            column: 7
          }
        }],
        line: 96
      },
      '9': {
        loc: {
          start: {
            line: 102,
            column: 26
          },
          end: {
            line: 102,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 102,
            column: 26
          },
          end: {
            line: 102,
            column: 44
          }
        }, {
          start: {
            line: 102,
            column: 48
          },
          end: {
            line: 102,
            column: 66
          }
        }],
        line: 102
      },
      '10': {
        loc: {
          start: {
            line: 103,
            column: 24
          },
          end: {
            line: 103,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 103,
            column: 24
          },
          end: {
            line: 103,
            column: 42
          }
        }, {
          start: {
            line: 103,
            column: 46
          },
          end: {
            line: 103,
            column: 64
          }
        }],
        line: 103
      },
      '11': {
        loc: {
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 104,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 104
      },
      '12': {
        loc: {
          start: {
            line: 104,
            column: 10
          },
          end: {
            line: 104,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 104,
            column: 10
          },
          end: {
            line: 104,
            column: 33
          }
        }, {
          start: {
            line: 104,
            column: 37
          },
          end: {
            line: 104,
            column: 76
          }
        }],
        line: 104
      },
      '13': {
        loc: {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }, {
          start: {
            line: 109,
            column: 6
          },
          end: {
            line: 113,
            column: 7
          }
        }],
        line: 109
      },
      '14': {
        loc: {
          start: {
            line: 109,
            column: 10
          },
          end: {
            line: 109,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 109,
            column: 10
          },
          end: {
            line: 109,
            column: 32
          }
        }, {
          start: {
            line: 109,
            column: 36
          },
          end: {
            line: 109,
            column: 74
          }
        }],
        line: 109
      },
      '15': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 118,
            column: 7
          }
        }],
        line: 114
      },
      '16': {
        loc: {
          start: {
            line: 114,
            column: 10
          },
          end: {
            line: 114,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 114,
            column: 10
          },
          end: {
            line: 114,
            column: 30
          }
        }, {
          start: {
            line: 114,
            column: 34
          },
          end: {
            line: 114,
            column: 70
          }
        }],
        line: 114
      },
      '17': {
        loc: {
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }, {
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 141,
            column: 7
          }
        }],
        line: 123
      },
      '18': {
        loc: {
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        }, {
          start: {
            line: 125,
            column: 8
          },
          end: {
            line: 140,
            column: 9
          }
        }],
        line: 125
      },
      '19': {
        loc: {
          start: {
            line: 129,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 129,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        }, {
          start: {
            line: 129,
            column: 14
          },
          end: {
            line: 131,
            column: 15
          }
        }],
        line: 129
      },
      '20': {
        loc: {
          start: {
            line: 129,
            column: 18
          },
          end: {
            line: 129,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 129,
            column: 18
          },
          end: {
            line: 129,
            column: 31
          }
        }, {
          start: {
            line: 129,
            column: 35
          },
          end: {
            line: 129,
            column: 45
          }
        }],
        line: 129
      },
      '21': {
        loc: {
          start: {
            line: 132,
            column: 14
          },
          end: {
            line: 134,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 14
          },
          end: {
            line: 134,
            column: 15
          }
        }, {
          start: {
            line: 132,
            column: 14
          },
          end: {
            line: 134,
            column: 15
          }
        }],
        line: 132
      },
      '22': {
        loc: {
          start: {
            line: 135,
            column: 14
          },
          end: {
            line: 137,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 14
          },
          end: {
            line: 137,
            column: 15
          }
        }, {
          start: {
            line: 135,
            column: 14
          },
          end: {
            line: 137,
            column: 15
          }
        }],
        line: 135
      },
      '23': {
        loc: {
          start: {
            line: 147,
            column: 18
          },
          end: {
            line: 150,
            column: 5
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 147,
            column: 18
          },
          end: {
            line: 147,
            column: 51
          }
        }, {
          start: {
            line: 147,
            column: 55
          },
          end: {
            line: 150,
            column: 5
          }
        }],
        line: 147
      },
      '24': {
        loc: {
          start: {
            line: 152,
            column: 79
          },
          end: {
            line: 152,
            column: 120
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 152,
            column: 79
          },
          end: {
            line: 152,
            column: 102
          }
        }, {
          start: {
            line: 152,
            column: 106
          },
          end: {
            line: 152,
            column: 120
          }
        }],
        line: 152
      },
      '25': {
        loc: {
          start: {
            line: 157,
            column: 4
          },
          end: {
            line: 161,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 4
          },
          end: {
            line: 161,
            column: 5
          }
        }, {
          start: {
            line: 157,
            column: 4
          },
          end: {
            line: 161,
            column: 5
          }
        }],
        line: 157
      },
      '26': {
        loc: {
          start: {
            line: 157,
            column: 7
          },
          end: {
            line: 157,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 157,
            column: 7
          },
          end: {
            line: 157,
            column: 44
          }
        }, {
          start: {
            line: 157,
            column: 48
          },
          end: {
            line: 157,
            column: 91
          }
        }],
        line: 157
      },
      '27': {
        loc: {
          start: {
            line: 159,
            column: 16
          },
          end: {
            line: 160,
            column: 105
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 159,
            column: 16
          },
          end: {
            line: 159,
            column: 108
          }
        }, {
          start: {
            line: 160,
            column: 10
          },
          end: {
            line: 160,
            column: 105
          }
        }],
        line: 159
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1kgks8ggwl.s[0]++;
angular.module('app').controller('activitiesRightCtrl', function ($deltahttp, $scope, $stateParams, $CRUDService, $filter, $deltaNumber, $deltaUnite, $log) {
  cov_1kgks8ggwl.f[0]++;
  cov_1kgks8ggwl.s[1]++;

  $scope.portfolio = $deltahttp.getProjet() == 0;
  var PATH = (cov_1kgks8ggwl.s[2]++, 'DernierNiveau');
  var PATHACTIV = (cov_1kgks8ggwl.s[3]++, 'Activites');

  var PATHINDIC = (cov_1kgks8ggwl.s[4]++, 'DecoupageIndic');
  var PATH_PROJECT = (cov_1kgks8ggwl.s[5]++, 'Projet');
  cov_1kgks8ggwl.s[6]++;
  $deltaUnite.addController($scope);

  cov_1kgks8ggwl.s[7]++;
  $scope.getImage = function (item) {
    cov_1kgks8ggwl.f[1]++;
    cov_1kgks8ggwl.s[8]++;

    return $deltahttp.getDataPath('Connexion') + '?' + $CRUDService.getComplement(2, true) + '&get=pictures&type=actors&item=' + item.id;
  };

  cov_1kgks8ggwl.s[9]++;
  $scope.privileges = {
    collect: false,
    planning: false
  };

  cov_1kgks8ggwl.s[10]++;
  $scope.planningSearch = {
    searchUsersText: ''
  };

  cov_1kgks8ggwl.s[11]++;
  $scope.selectAllUserC = function () {
    cov_1kgks8ggwl.f[2]++;
    cov_1kgks8ggwl.s[12]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var user = _step.value;
        cov_1kgks8ggwl.s[13]++;

        $scope.data.c[user.id] = $scope.privileges.collect;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };
  cov_1kgks8ggwl.s[14]++;
  $scope.selectAllUserP = function () {
    cov_1kgks8ggwl.f[3]++;
    cov_1kgks8ggwl.s[15]++;
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.users[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var user = _step2.value;
        cov_1kgks8ggwl.s[16]++;

        $scope.data.p[user.id] = $scope.privileges.planning;
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }
  };

  cov_1kgks8ggwl.s[17]++;
  $scope.PlanningControl = function (item, idUser, superAdmin) {
    cov_1kgks8ggwl.f[4]++;
    cov_1kgks8ggwl.s[18]++;

    if (superAdmin) {
      cov_1kgks8ggwl.b[0][0]++;
      cov_1kgks8ggwl.s[19]++;

      return true;
    } else {
      cov_1kgks8ggwl.b[0][1]++;
    }
    cov_1kgks8ggwl.s[20]++;
    if (item.DROITPLAN.p[idUser] == true) {
      cov_1kgks8ggwl.b[1][0]++;
      cov_1kgks8ggwl.s[21]++;

      return true;
    } else {
      cov_1kgks8ggwl.b[1][1]++;
    }
    cov_1kgks8ggwl.s[22]++;
    return false;
  };
  cov_1kgks8ggwl.s[23]++;
  $scope.collectControl = function (item, idUser, superAdmin) {
    cov_1kgks8ggwl.f[5]++;
    cov_1kgks8ggwl.s[24]++;

    if (superAdmin) {
      cov_1kgks8ggwl.b[2][0]++;
      cov_1kgks8ggwl.s[25]++;

      return true;
    } else {
      cov_1kgks8ggwl.b[2][1]++;
    }
    cov_1kgks8ggwl.s[26]++;
    if (item.DROITPLAN.c[idUser] == true) {
      cov_1kgks8ggwl.b[3][0]++;
      cov_1kgks8ggwl.s[27]++;

      return true;
    } else {
      cov_1kgks8ggwl.b[3][1]++;
    }
    cov_1kgks8ggwl.s[28]++;
    return false;
  };
  cov_1kgks8ggwl.s[29]++;
  $scope.open = function (item) {
    cov_1kgks8ggwl.f[6]++;
    cov_1kgks8ggwl.s[30]++;

    this.data = (cov_1kgks8ggwl.b[4][0]++, item.DROITPLAN) || (cov_1kgks8ggwl.b[4][1]++, {
      p: {},
      c: {}
    });
    cov_1kgks8ggwl.s[31]++;
    $scope.view = 6;
    // $scope.getGlobalValue(item);
    cov_1kgks8ggwl.s[32]++;
    $scope.selectedActivity = item;
  };
  cov_1kgks8ggwl.s[33]++;
  $scope.save = function () {
    cov_1kgks8ggwl.f[7]++;

    var tab = (cov_1kgks8ggwl.s[34]++, ';');
    cov_1kgks8ggwl.s[35]++;
    for (var step in this.data.p) {
      cov_1kgks8ggwl.s[36]++;

      if (!this.data.p[step]) {
        cov_1kgks8ggwl.b[5][0]++;
        cov_1kgks8ggwl.s[37]++;

        continue;
      } else {
        cov_1kgks8ggwl.b[5][1]++;
      }
      cov_1kgks8ggwl.s[38]++;
      tab += step + ';';
    }
    cov_1kgks8ggwl.s[39]++;
    tab += '\t;';
    cov_1kgks8ggwl.s[40]++;
    for (var _step3 in this.data.c) {
      cov_1kgks8ggwl.s[41]++;

      if (!this.data.c[_step3]) {
        cov_1kgks8ggwl.b[6][0]++;
        cov_1kgks8ggwl.s[42]++;

        continue;
      } else {
        cov_1kgks8ggwl.b[6][1]++;
      }
      cov_1kgks8ggwl.s[43]++;
      tab += _step3 + ';';
    }
    cov_1kgks8ggwl.s[44]++;
    $scope.selectedActivity.DROITPLAN = angular.copy(this.data);
    cov_1kgks8ggwl.s[45]++;
    $CRUDService.save(PATHACTIV, { action: 'right', id: $scope.selectedActivity.id, valeur: tab }, function (data) {
      // $scope.isloading = false;

      cov_1kgks8ggwl.f[8]++;
    });
    cov_1kgks8ggwl.s[46]++;
    $scope.view = 1;
  };
  cov_1kgks8ggwl.s[47]++;
  $scope.close = function () {
    cov_1kgks8ggwl.f[9]++;
    cov_1kgks8ggwl.s[48]++;

    $scope.view = 1;
  };
  cov_1kgks8ggwl.s[49]++;
  $scope.getUser = function () {
    cov_1kgks8ggwl.f[10]++;
    cov_1kgks8ggwl.s[50]++;

    $CRUDService.getAll('User', { get: 'NonAdmin' }, function (data) {
      cov_1kgks8ggwl.f[11]++;
      cov_1kgks8ggwl.s[51]++;

      $scope.users = data.map(function (user) {
        cov_1kgks8ggwl.f[12]++;
        cov_1kgks8ggwl.s[52]++;

        user.imageUrl = $deltahttp.getUserPicture(user.id);
        cov_1kgks8ggwl.s[53]++;
        return user;
      });
    });
  };

  cov_1kgks8ggwl.s[54]++;
  $CRUDService.getAll(PATH, { get: 'single', id: (cov_1kgks8ggwl.b[7][0]++, $stateParams.id) || (cov_1kgks8ggwl.b[7][1]++, 0) }, function (data) {
    cov_1kgks8ggwl.f[13]++;
    cov_1kgks8ggwl.s[55]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion3 = (_step4 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var el = _step4.value;
        cov_1kgks8ggwl.s[56]++;

        if (el.ID_UNITE < 1) {
          cov_1kgks8ggwl.b[8][0]++;
          cov_1kgks8ggwl.s[57]++;

          continue;
        } else {
          cov_1kgks8ggwl.b[8][1]++;
        }
        cov_1kgks8ggwl.s[58]++;
        el.DATE_DEMARRAGE = Date.newDate(el.DATE_DEMARRAGE);
        cov_1kgks8ggwl.s[59]++;
        el.DATE_FIN_ACT = Date.newDate(el.DATE_FIN_ACT);
        cov_1kgks8ggwl.s[60]++;
        el.COUT_U_DN = $deltaNumber.formatNumber(el.COUT_U_DN);
        cov_1kgks8ggwl.s[61]++;
        el.BUDGET_FOLLOW = (cov_1kgks8ggwl.b[9][0]++, el.CALCULAUTO == 1) || (cov_1kgks8ggwl.b[9][1]++, el.CALCULAUTO == 3);
        cov_1kgks8ggwl.s[62]++;
        el.COST_FOLLOW = (cov_1kgks8ggwl.b[10][0]++, el.CALCULAUTO == 2) || (cov_1kgks8ggwl.b[10][1]++, el.CALCULAUTO == 3);
        cov_1kgks8ggwl.s[63]++;
        if ((cov_1kgks8ggwl.b[12][0]++, el.DATE_REVISION_GLOBAL) && (cov_1kgks8ggwl.b[12][1]++, el.DATE_REVISION_GLOBAL != '0000-00-00')) {
          cov_1kgks8ggwl.b[11][0]++;
          cov_1kgks8ggwl.s[64]++;

          el.DATE_REVISION_GLOBAL = Date.newDate(el.DATE_REVISION_GLOBAL);
        } else {
          cov_1kgks8ggwl.b[11][1]++;
          cov_1kgks8ggwl.s[65]++;

          el.DATE_REVISION_GLOBAL = null;
        }
        cov_1kgks8ggwl.s[66]++;
        if ((cov_1kgks8ggwl.b[14][0]++, el.DATE_REVISION_ANNEE) && (cov_1kgks8ggwl.b[14][1]++, el.DATE_REVISION_ANNEE != '0000-00-00')) {
          cov_1kgks8ggwl.b[13][0]++;
          cov_1kgks8ggwl.s[67]++;

          el.DATE_REVISION_ANNEE = Date.newDate(el.DATE_REVISION_ANNEE);
        } else {
          cov_1kgks8ggwl.b[13][1]++;
          cov_1kgks8ggwl.s[68]++;

          el.DATE_REVISION_ANNEE = null;
        }
        cov_1kgks8ggwl.s[69]++;
        if ((cov_1kgks8ggwl.b[16][0]++, el.DATE_REVISION_PER) && (cov_1kgks8ggwl.b[16][1]++, el.DATE_REVISION_PER != '0000-00-00')) {
          cov_1kgks8ggwl.b[15][0]++;
          cov_1kgks8ggwl.s[70]++;

          el.DATE_REVISION_PER = Date.newDate(el.DATE_REVISION_PER);
        } else {
          cov_1kgks8ggwl.b[15][1]++;
          cov_1kgks8ggwl.s[71]++;

          el.DATE_REVISION_PER = null;
        }
        var tamponDroit = (cov_1kgks8ggwl.s[72]++, {
          p: {},
          c: {}
        });
        cov_1kgks8ggwl.s[73]++;
        if (el.DROITPLAN) {
          cov_1kgks8ggwl.b[17][0]++;

          var tab = (cov_1kgks8ggwl.s[74]++, el.DROITPLAN.split('\t'));
          cov_1kgks8ggwl.s[75]++;
          if (tab.length == 2) {
            cov_1kgks8ggwl.b[18][0]++;
            cov_1kgks8ggwl.s[76]++;

            for (var j = 0; j < tab.length; j++) {
              var ch = (cov_1kgks8ggwl.s[77]++, tab[j].split(';'));
              cov_1kgks8ggwl.s[78]++;
              var _iteratorNormalCompletion4 = true;
              var _didIteratorError4 = false;
              var _iteratorError4 = undefined;

              try {
                for (var _iterator4 = ch[Symbol.iterator](), _step5; !(_iteratorNormalCompletion4 = (_step5 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                  var itema = _step5.value;
                  cov_1kgks8ggwl.s[79]++;

                  if ((cov_1kgks8ggwl.b[20][0]++, itema == '') || (cov_1kgks8ggwl.b[20][1]++, itema == 0)) {
                    cov_1kgks8ggwl.b[19][0]++;
                    cov_1kgks8ggwl.s[80]++;

                    continue;
                  } else {
                    cov_1kgks8ggwl.b[19][1]++;
                  }
                  cov_1kgks8ggwl.s[81]++;
                  if (j == 0) {
                    cov_1kgks8ggwl.b[21][0]++;
                    cov_1kgks8ggwl.s[82]++;

                    tamponDroit.p[itema] = true;
                  } else {
                    cov_1kgks8ggwl.b[21][1]++;
                  }
                  cov_1kgks8ggwl.s[83]++;
                  if (j == 1) {
                    cov_1kgks8ggwl.b[22][0]++;
                    cov_1kgks8ggwl.s[84]++;

                    tamponDroit.c[itema] = true;
                  } else {
                    cov_1kgks8ggwl.b[22][1]++;
                  }
                }
              } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion4 && _iterator4.return) {
                    _iterator4.return();
                  }
                } finally {
                  if (_didIteratorError4) {
                    throw _iteratorError4;
                  }
                }
              }
            }
          } else {
            cov_1kgks8ggwl.b[18][1]++;
          }
        } else {
          cov_1kgks8ggwl.b[17][1]++;
        }
        cov_1kgks8ggwl.s[85]++;
        el.DROITPLAN = tamponDroit;
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    cov_1kgks8ggwl.s[86]++;
    $scope.indexLoading = 0;
    cov_1kgks8ggwl.s[87]++;
    $scope.selectedActivity = data[0];

    cov_1kgks8ggwl.s[88]++;
    $scope.data = (cov_1kgks8ggwl.b[23][0]++, $scope.selectedActivity.DROITPLAN) || (cov_1kgks8ggwl.b[23][1]++, {
      p: {},
      c: {}
    });

    cov_1kgks8ggwl.s[89]++;
    $scope.unitSelected = $filter('filter')($scope.listData_unites_bksb, { id: ((cov_1kgks8ggwl.b[24][0]++, $scope.selectedActivity) || (cov_1kgks8ggwl.b[24][1]++, { ID_UNITE: -1 })).ID_UNITE }, true)[0];
    cov_1kgks8ggwl.s[90]++;
    $scope.getUser();
  });
  cov_1kgks8ggwl.s[91]++;
  $scope.listUsersWithFilter = function () {
    cov_1kgks8ggwl.f[14]++;
    cov_1kgks8ggwl.s[92]++;

    $log.log($scope.planningSearch.searchUsersText);
    cov_1kgks8ggwl.s[93]++;
    if ((cov_1kgks8ggwl.b[26][0]++, $scope.planningSearch.searchUsersText) && (cov_1kgks8ggwl.b[26][1]++, $scope.planningSearch.searchUsersText != '')) {
      cov_1kgks8ggwl.b[25][0]++;
      cov_1kgks8ggwl.s[94]++;

      return $scope.users.filter(function (user) {
        cov_1kgks8ggwl.f[15]++;
        cov_1kgks8ggwl.s[95]++;
        return (cov_1kgks8ggwl.b[27][0]++, user.NOMUSER.toLowerCase().indexOf($scope.planningSearch.searchUsersText.toLowerCase()) > -1) || (cov_1kgks8ggwl.b[27][1]++, user.PRENOMUSER.toLowerCase().indexOf($scope.planningSearch.searchUsersText.toLowerCase()) > -1);
      });
    } else {
      cov_1kgks8ggwl.b[25][1]++;
    }
    cov_1kgks8ggwl.s[96]++;
    return $scope.users;
  };
});
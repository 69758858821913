'use strict';

var cov_16on4em1mq = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/customdashboard/customDashboard.js',
      hash = 'c15593f76b237cf5a6929211de8a592be684fe9b',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/customdashboard/customDashboard.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '1': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 68,
          column: 4
        }
      },
      '2': {
        start: {
          line: 5,
          column: 17
        },
        end: {
          line: 5,
          column: 53
        }
      },
      '3': {
        start: {
          line: 6,
          column: 21
        },
        end: {
          line: 6,
          column: 23
        }
      },
      '4': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 57,
          column: 5
        }
      },
      '5': {
        start: {
          line: 8,
          column: 18
        },
        end: {
          line: 8,
          column: 72
        }
      },
      '6': {
        start: {
          line: 10,
          column: 6
        },
        end: {
          line: 32,
          column: 7
        }
      },
      '7': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 18,
          column: 9
        }
      },
      '8': {
        start: {
          line: 12,
          column: 10
        },
        end: {
          line: 14,
          column: 11
        }
      },
      '9': {
        start: {
          line: 13,
          column: 12
        },
        end: {
          line: 13,
          column: 30
        }
      },
      '10': {
        start: {
          line: 15,
          column: 10
        },
        end: {
          line: 17,
          column: 11
        }
      },
      '11': {
        start: {
          line: 16,
          column: 12
        },
        end: {
          line: 16,
          column: 30
        }
      },
      '12': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 31,
          column: 11
        }
      },
      '13': {
        start: {
          line: 58,
          column: 4
        },
        end: {
          line: 58,
          column: 31
        }
      },
      '14': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 31
        }
      },
      '15': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 60,
          column: 38
        }
      },
      '16': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 61,
          column: 44
        }
      },
      '17': {
        start: {
          line: 63,
          column: 16
        },
        end: {
          line: 63,
          column: 40
        }
      },
      '18': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 67,
          column: 7
        }
      },
      '19': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 26
        }
      },
      '20': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 29
        }
      },
      '21': {
        start: {
          line: 69,
          column: 2
        },
        end: {
          line: 71,
          column: 4
        }
      },
      '22': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 40
        }
      },
      '23': {
        start: {
          line: 73,
          column: 0
        },
        end: {
          line: 399,
          column: 3
        }
      },
      '24': {
        start: {
          line: 74,
          column: 2
        },
        end: {
          line: 74,
          column: 37
        }
      },
      '25': {
        start: {
          line: 75,
          column: 2
        },
        end: {
          line: 77,
          column: 3
        }
      },
      '26': {
        start: {
          line: 76,
          column: 4
        },
        end: {
          line: 76,
          column: 11
        }
      },
      '27': {
        start: {
          line: 78,
          column: 15
        },
        end: {
          line: 78,
          column: 26
        }
      },
      '28': {
        start: {
          line: 79,
          column: 20
        },
        end: {
          line: 79,
          column: 35
        }
      },
      '29': {
        start: {
          line: 80,
          column: 23
        },
        end: {
          line: 80,
          column: 32
        }
      },
      '30': {
        start: {
          line: 81,
          column: 2
        },
        end: {
          line: 81,
          column: 26
        }
      },
      '31': {
        start: {
          line: 82,
          column: 2
        },
        end: {
          line: 82,
          column: 39
        }
      },
      '32': {
        start: {
          line: 83,
          column: 2
        },
        end: {
          line: 83,
          column: 42
        }
      },
      '33': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 84,
          column: 38
        }
      },
      '34': {
        start: {
          line: 85,
          column: 2
        },
        end: {
          line: 85,
          column: 47
        }
      },
      '35': {
        start: {
          line: 86,
          column: 2
        },
        end: {
          line: 86,
          column: 43
        }
      },
      '36': {
        start: {
          line: 87,
          column: 2
        },
        end: {
          line: 97,
          column: 5
        }
      },
      '37': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 96,
          column: 5
        }
      },
      '38': {
        start: {
          line: 89,
          column: 20
        },
        end: {
          line: 89,
          column: 47
        }
      },
      '39': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 90,
          column: 30
        }
      },
      '40': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 91,
          column: 28
        }
      },
      '41': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 24
        }
      },
      '42': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 37
        }
      },
      '43': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 30
        }
      },
      '44': {
        start: {
          line: 95,
          column: 6
        },
        end: {
          line: 95,
          column: 37
        }
      },
      '45': {
        start: {
          line: 99,
          column: 2
        },
        end: {
          line: 111,
          column: 5
        }
      },
      '46': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 110,
          column: 5
        }
      },
      '47': {
        start: {
          line: 100,
          column: 46
        },
        end: {
          line: 108,
          column: 5
        }
      },
      '48': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 40
        }
      },
      '49': {
        start: {
          line: 113,
          column: 2
        },
        end: {
          line: 132,
          column: 4
        }
      },
      '50': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 122,
          column: 37
        }
      },
      '51': {
        start: {
          line: 129,
          column: 8
        },
        end: {
          line: 129,
          column: 37
        }
      },
      '52': {
        start: {
          line: 134,
          column: 2
        },
        end: {
          line: 137,
          column: 4
        }
      },
      '53': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 147,
          column: 4
        }
      },
      '54': {
        start: {
          line: 139,
          column: 18
        },
        end: {
          line: 139,
          column: 48
        }
      },
      '55': {
        start: {
          line: 140,
          column: 17
        },
        end: {
          line: 140,
          column: 26
        }
      },
      '56': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 141,
          column: 25
        }
      },
      '57': {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 142,
          column: 40
        }
      },
      '58': {
        start: {
          line: 145,
          column: 4
        },
        end: {
          line: 145,
          column: 72
        }
      },
      '59': {
        start: {
          line: 146,
          column: 4
        },
        end: {
          line: 146,
          column: 33
        }
      },
      '60': {
        start: {
          line: 148,
          column: 2
        },
        end: {
          line: 155,
          column: 4
        }
      },
      '61': {
        start: {
          line: 149,
          column: 18
        },
        end: {
          line: 149,
          column: 48
        }
      },
      '62': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 150,
          column: 31
        }
      },
      '63': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 151,
          column: 46
        }
      },
      '64': {
        start: {
          line: 153,
          column: 4
        },
        end: {
          line: 153,
          column: 72
        }
      },
      '65': {
        start: {
          line: 154,
          column: 4
        },
        end: {
          line: 154,
          column: 33
        }
      },
      '66': {
        start: {
          line: 157,
          column: 2
        },
        end: {
          line: 166,
          column: 4
        }
      },
      '67': {
        start: {
          line: 158,
          column: 4
        },
        end: {
          line: 165,
          column: 7
        }
      },
      '68': {
        start: {
          line: 168,
          column: 2
        },
        end: {
          line: 397,
          column: 4
        }
      },
      '69': {
        start: {
          line: 169,
          column: 4
        },
        end: {
          line: 175,
          column: 6
        }
      },
      '70': {
        start: {
          line: 170,
          column: 23
        },
        end: {
          line: 170,
          column: 64
        }
      },
      '71': {
        start: {
          line: 171,
          column: 25
        },
        end: {
          line: 171,
          column: 72
        }
      },
      '72': {
        start: {
          line: 172,
          column: 6
        },
        end: {
          line: 172,
          column: 54
        }
      },
      '73': {
        start: {
          line: 173,
          column: 6
        },
        end: {
          line: 173,
          column: 64
        }
      },
      '74': {
        start: {
          line: 174,
          column: 6
        },
        end: {
          line: 174,
          column: 33
        }
      },
      '75': {
        start: {
          line: 176,
          column: 4
        },
        end: {
          line: 395,
          column: 6
        }
      },
      '76': {
        start: {
          line: 181,
          column: 20
        },
        end: {
          line: 181,
          column: 25
        }
      },
      '77': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 184,
          column: 9
        }
      },
      '78': {
        start: {
          line: 183,
          column: 10
        },
        end: {
          line: 183,
          column: 49
        }
      },
      '79': {
        start: {
          line: 185,
          column: 8
        },
        end: {
          line: 185,
          column: 23
        }
      },
      '80': {
        start: {
          line: 186,
          column: 20
        },
        end: {
          line: 190,
          column: 9
        }
      },
      '81': {
        start: {
          line: 192,
          column: 8
        },
        end: {
          line: 192,
          column: 66
        }
      },
      '82': {
        start: {
          line: 193,
          column: 8
        },
        end: {
          line: 194,
          column: 11
        }
      },
      '83': {
        start: {
          line: 197,
          column: 21
        },
        end: {
          line: 197,
          column: 24
        }
      },
      '84': {
        start: {
          line: 198,
          column: 8
        },
        end: {
          line: 200,
          column: 9
        }
      },
      '85': {
        start: {
          line: 199,
          column: 10
        },
        end: {
          line: 199,
          column: 30
        }
      },
      '86': {
        start: {
          line: 201,
          column: 8
        },
        end: {
          line: 201,
          column: 44
        }
      },
      '87': {
        start: {
          line: 205,
          column: 21
        },
        end: {
          line: 205,
          column: 52
        }
      },
      '88': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 211,
          column: 9
        }
      },
      '89': {
        start: {
          line: 208,
          column: 10
        },
        end: {
          line: 208,
          column: 27
        }
      },
      '90': {
        start: {
          line: 209,
          column: 10
        },
        end: {
          line: 209,
          column: 30
        }
      },
      '91': {
        start: {
          line: 212,
          column: 8
        },
        end: {
          line: 217,
          column: 9
        }
      },
      '92': {
        start: {
          line: 213,
          column: 10
        },
        end: {
          line: 216,
          column: 11
        }
      },
      '93': {
        start: {
          line: 214,
          column: 12
        },
        end: {
          line: 214,
          column: 43
        }
      },
      '94': {
        start: {
          line: 215,
          column: 12
        },
        end: {
          line: 215,
          column: 18
        }
      },
      '95': {
        start: {
          line: 218,
          column: 20
        },
        end: {
          line: 218,
          column: 50
        }
      },
      '96': {
        start: {
          line: 219,
          column: 8
        },
        end: {
          line: 219,
          column: 35
        }
      },
      '97': {
        start: {
          line: 220,
          column: 8
        },
        end: {
          line: 220,
          column: 43
        }
      },
      '98': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 223,
          column: 11
        }
      },
      '99': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 226,
          column: 32
        }
      },
      '100': {
        start: {
          line: 227,
          column: 8
        },
        end: {
          line: 227,
          column: 25
        }
      },
      '101': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 237,
          column: 11
        }
      },
      '102': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 229,
          column: 29
        }
      },
      '103': {
        start: {
          line: 230,
          column: 10
        },
        end: {
          line: 235,
          column: 11
        }
      },
      '104': {
        start: {
          line: 231,
          column: 12
        },
        end: {
          line: 231,
          column: 58
        }
      },
      '105': {
        start: {
          line: 232,
          column: 12
        },
        end: {
          line: 232,
          column: 41
        }
      },
      '106': {
        start: {
          line: 233,
          column: 12
        },
        end: {
          line: 233,
          column: 47
        }
      },
      '107': {
        start: {
          line: 234,
          column: 12
        },
        end: {
          line: 234,
          column: 67
        }
      },
      '108': {
        start: {
          line: 236,
          column: 10
        },
        end: {
          line: 236,
          column: 35
        }
      },
      '109': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 238,
          column: 32
        }
      },
      '110': {
        start: {
          line: 239,
          column: 8
        },
        end: {
          line: 248,
          column: 11
        }
      },
      '111': {
        start: {
          line: 240,
          column: 10
        },
        end: {
          line: 240,
          column: 36
        }
      },
      '112': {
        start: {
          line: 241,
          column: 10
        },
        end: {
          line: 246,
          column: 11
        }
      },
      '113': {
        start: {
          line: 242,
          column: 12
        },
        end: {
          line: 242,
          column: 58
        }
      },
      '114': {
        start: {
          line: 243,
          column: 12
        },
        end: {
          line: 243,
          column: 41
        }
      },
      '115': {
        start: {
          line: 244,
          column: 12
        },
        end: {
          line: 244,
          column: 47
        }
      },
      '116': {
        start: {
          line: 245,
          column: 12
        },
        end: {
          line: 245,
          column: 67
        }
      },
      '117': {
        start: {
          line: 247,
          column: 10
        },
        end: {
          line: 247,
          column: 35
        }
      },
      '118': {
        start: {
          line: 249,
          column: 8
        },
        end: {
          line: 249,
          column: 30
        }
      },
      '119': {
        start: {
          line: 250,
          column: 8
        },
        end: {
          line: 255,
          column: 11
        }
      },
      '120': {
        start: {
          line: 251,
          column: 10
        },
        end: {
          line: 251,
          column: 32
        }
      },
      '121': {
        start: {
          line: 252,
          column: 10
        },
        end: {
          line: 254,
          column: 11
        }
      },
      '122': {
        start: {
          line: 253,
          column: 12
        },
        end: {
          line: 253,
          column: 121
        }
      },
      '123': {
        start: {
          line: 258,
          column: 8
        },
        end: {
          line: 258,
          column: 45
        }
      },
      '124': {
        start: {
          line: 259,
          column: 8
        },
        end: {
          line: 259,
          column: 15
        }
      },
      '125': {
        start: {
          line: 270,
          column: 8
        },
        end: {
          line: 270,
          column: 43
        }
      },
      '126': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 271,
          column: 37
        }
      },
      '127': {
        start: {
          line: 272,
          column: 8
        },
        end: {
          line: 284,
          column: 9
        }
      },
      '128': {
        start: {
          line: 273,
          column: 25
        },
        end: {
          line: 273,
          column: 81
        }
      },
      '129': {
        start: {
          line: 274,
          column: 10
        },
        end: {
          line: 283,
          column: 11
        }
      },
      '130': {
        start: {
          line: 275,
          column: 12
        },
        end: {
          line: 278,
          column: 13
        }
      },
      '131': {
        start: {
          line: 276,
          column: 14
        },
        end: {
          line: 276,
          column: 49
        }
      },
      '132': {
        start: {
          line: 277,
          column: 14
        },
        end: {
          line: 277,
          column: 20
        }
      },
      '133': {
        start: {
          line: 279,
          column: 12
        },
        end: {
          line: 282,
          column: 13
        }
      },
      '134': {
        start: {
          line: 280,
          column: 14
        },
        end: {
          line: 280,
          column: 49
        }
      },
      '135': {
        start: {
          line: 281,
          column: 14
        },
        end: {
          line: 281,
          column: 20
        }
      },
      '136': {
        start: {
          line: 285,
          column: 8
        },
        end: {
          line: 292,
          column: 11
        }
      },
      '137': {
        start: {
          line: 295,
          column: 22
        },
        end: {
          line: 295,
          column: 43
        }
      },
      '138': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 298,
          column: 9
        }
      },
      '139': {
        start: {
          line: 297,
          column: 10
        },
        end: {
          line: 297,
          column: 17
        }
      },
      '140': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 303,
          column: 11
        }
      },
      '141': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 306,
          column: 42
        }
      },
      '142': {
        start: {
          line: 307,
          column: 8
        },
        end: {
          line: 307,
          column: 35
        }
      },
      '143': {
        start: {
          line: 308,
          column: 8
        },
        end: {
          line: 308,
          column: 32
        }
      },
      '144': {
        start: {
          line: 311,
          column: 8
        },
        end: {
          line: 311,
          column: 36
        }
      },
      '145': {
        start: {
          line: 312,
          column: 8
        },
        end: {
          line: 312,
          column: 49
        }
      },
      '146': {
        start: {
          line: 313,
          column: 8
        },
        end: {
          line: 313,
          column: 47
        }
      },
      '147': {
        start: {
          line: 314,
          column: 8
        },
        end: {
          line: 314,
          column: 47
        }
      },
      '148': {
        start: {
          line: 315,
          column: 8
        },
        end: {
          line: 315,
          column: 33
        }
      },
      '149': {
        start: {
          line: 318,
          column: 8
        },
        end: {
          line: 322,
          column: 9
        }
      },
      '150': {
        start: {
          line: 319,
          column: 10
        },
        end: {
          line: 319,
          column: 60
        }
      },
      '151': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 73
        }
      },
      '152': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 323,
          column: 33
        }
      },
      '153': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 324,
          column: 36
        }
      },
      '154': {
        start: {
          line: 327,
          column: 8
        },
        end: {
          line: 327,
          column: 49
        }
      },
      '155': {
        start: {
          line: 330,
          column: 8
        },
        end: {
          line: 359,
          column: 11
        }
      },
      '156': {
        start: {
          line: 343,
          column: 10
        },
        end: {
          line: 345,
          column: 11
        }
      },
      '157': {
        start: {
          line: 344,
          column: 12
        },
        end: {
          line: 344,
          column: 19
        }
      },
      '158': {
        start: {
          line: 346,
          column: 10
        },
        end: {
          line: 346,
          column: 34
        }
      },
      '159': {
        start: {
          line: 348,
          column: 10
        },
        end: {
          line: 358,
          column: 13
        }
      },
      '160': {
        start: {
          line: 349,
          column: 12
        },
        end: {
          line: 356,
          column: 13
        }
      },
      '161': {
        start: {
          line: 350,
          column: 14
        },
        end: {
          line: 353,
          column: 17
        }
      },
      '162': {
        start: {
          line: 354,
          column: 14
        },
        end: {
          line: 354,
          column: 39
        }
      },
      '163': {
        start: {
          line: 355,
          column: 14
        },
        end: {
          line: 355,
          column: 21
        }
      },
      '164': {
        start: {
          line: 357,
          column: 12
        },
        end: {
          line: 357,
          column: 35
        }
      },
      '165': {
        start: {
          line: 362,
          column: 8
        },
        end: {
          line: 362,
          column: 50
        }
      },
      '166': {
        start: {
          line: 363,
          column: 8
        },
        end: {
          line: 363,
          column: 47
        }
      },
      '167': {
        start: {
          line: 364,
          column: 8
        },
        end: {
          line: 364,
          column: 44
        }
      },
      '168': {
        start: {
          line: 365,
          column: 8
        },
        end: {
          line: 391,
          column: 9
        }
      },
      '169': {
        start: {
          line: 366,
          column: 28
        },
        end: {
          line: 366,
          column: 84
        }
      },
      '170': {
        start: {
          line: 367,
          column: 10
        },
        end: {
          line: 376,
          column: 11
        }
      },
      '171': {
        start: {
          line: 368,
          column: 12
        },
        end: {
          line: 371,
          column: 13
        }
      },
      '172': {
        start: {
          line: 369,
          column: 14
        },
        end: {
          line: 369,
          column: 30
        }
      },
      '173': {
        start: {
          line: 370,
          column: 14
        },
        end: {
          line: 370,
          column: 20
        }
      },
      '174': {
        start: {
          line: 372,
          column: 12
        },
        end: {
          line: 375,
          column: 13
        }
      },
      '175': {
        start: {
          line: 373,
          column: 14
        },
        end: {
          line: 373,
          column: 30
        }
      },
      '176': {
        start: {
          line: 374,
          column: 14
        },
        end: {
          line: 374,
          column: 20
        }
      },
      '177': {
        start: {
          line: 392,
          column: 8
        },
        end: {
          line: 392,
          column: 58
        }
      },
      '178': {
        start: {
          line: 393,
          column: 8
        },
        end: {
          line: 393,
          column: 50
        }
      },
      '179': {
        start: {
          line: 396,
          column: 4
        },
        end: {
          line: 396,
          column: 25
        }
      },
      '180': {
        start: {
          line: 398,
          column: 2
        },
        end: {
          line: 398,
          column: 16
        }
      },
      '181': {
        start: {
          line: 402,
          column: 2
        },
        end: {
          line: 404,
          column: 4
        }
      },
      '182': {
        start: {
          line: 403,
          column: 4
        },
        end: {
          line: 403,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: 'customDashbordCtrl_edit',
        decl: {
          start: {
            line: 1,
            column: 9
          },
          end: {
            line: 1,
            column: 32
          }
        },
        loc: {
          start: {
            line: 1,
            column: 83
          },
          end: {
            line: 72,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 4,
            column: 24
          },
          end: {
            line: 4,
            column: 25
          }
        },
        loc: {
          start: {
            line: 4,
            column: 36
          },
          end: {
            line: 68,
            column: 3
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 64,
            column: 89
          },
          end: {
            line: 64,
            column: 90
          }
        },
        loc: {
          start: {
            line: 64,
            column: 95
          },
          end: {
            line: 67,
            column: 5
          }
        },
        line: 64
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 69,
            column: 22
          },
          end: {
            line: 69,
            column: 23
          }
        },
        loc: {
          start: {
            line: 69,
            column: 34
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 69
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 73,
            column: 55
          },
          end: {
            line: 73,
            column: 56
          }
        },
        loc: {
          start: {
            line: 73,
            column: 255
          },
          end: {
            line: 399,
            column: 1
          }
        },
        line: 73
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 87,
            column: 50
          },
          end: {
            line: 87,
            column: 51
          }
        },
        loc: {
          start: {
            line: 87,
            column: 58
          },
          end: {
            line: 97,
            column: 3
          }
        },
        line: 87
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 99,
            column: 67
          },
          end: {
            line: 99,
            column: 68
          }
        },
        loc: {
          start: {
            line: 99,
            column: 75
          },
          end: {
            line: 111,
            column: 3
          }
        },
        line: 99
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 100,
            column: 36
          },
          end: {
            line: 100,
            column: 37
          }
        },
        loc: {
          start: {
            line: 100,
            column: 46
          },
          end: {
            line: 108,
            column: 5
          }
        },
        line: 100
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 138,
            column: 18
          },
          end: {
            line: 138,
            column: 19
          }
        },
        loc: {
          start: {
            line: 138,
            column: 40
          },
          end: {
            line: 147,
            column: 3
          }
        },
        line: 138
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 148,
            column: 16
          },
          end: {
            line: 148,
            column: 17
          }
        },
        loc: {
          start: {
            line: 148,
            column: 38
          },
          end: {
            line: 155,
            column: 3
          }
        },
        line: 148
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 157,
            column: 22
          },
          end: {
            line: 157,
            column: 23
          }
        },
        loc: {
          start: {
            line: 157,
            column: 34
          },
          end: {
            line: 166,
            column: 3
          }
        },
        line: 157
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 168,
            column: 16
          },
          end: {
            line: 168,
            column: 17
          }
        },
        loc: {
          start: {
            line: 168,
            column: 28
          },
          end: {
            line: 397,
            column: 3
          }
        },
        line: 168
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 169,
            column: 26
          },
          end: {
            line: 169,
            column: 27
          }
        },
        loc: {
          start: {
            line: 169,
            column: 53
          },
          end: {
            line: 175,
            column: 5
          }
        },
        line: 169
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 193,
            column: 89
          },
          end: {
            line: 193,
            column: 90
          }
        },
        loc: {
          start: {
            line: 193,
            column: 97
          },
          end: {
            line: 194,
            column: 9
          }
        },
        line: 193
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 221,
            column: 93
          },
          end: {
            line: 221,
            column: 94
          }
        },
        loc: {
          start: {
            line: 221,
            column: 99
          },
          end: {
            line: 223,
            column: 9
          }
        },
        line: 221
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 228,
            column: 63
          },
          end: {
            line: 228,
            column: 64
          }
        },
        loc: {
          start: {
            line: 228,
            column: 71
          },
          end: {
            line: 237,
            column: 9
          }
        },
        line: 228
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 239,
            column: 66
          },
          end: {
            line: 239,
            column: 67
          }
        },
        loc: {
          start: {
            line: 239,
            column: 74
          },
          end: {
            line: 248,
            column: 9
          }
        },
        line: 239
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 250,
            column: 53
          },
          end: {
            line: 250,
            column: 54
          }
        },
        loc: {
          start: {
            line: 250,
            column: 61
          },
          end: {
            line: 255,
            column: 9
          }
        },
        line: 250
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 342,
            column: 11
          },
          end: {
            line: 342,
            column: 12
          }
        },
        loc: {
          start: {
            line: 342,
            column: 24
          },
          end: {
            line: 359,
            column: 9
          }
        },
        line: 342
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 348,
            column: 66
          },
          end: {
            line: 348,
            column: 67
          }
        },
        loc: {
          start: {
            line: 348,
            column: 74
          },
          end: {
            line: 358,
            column: 11
          }
        },
        line: 348
      },
      '20': {
        name: 'exportData_view',
        decl: {
          start: {
            line: 401,
            column: 9
          },
          end: {
            line: 401,
            column: 24
          }
        },
        loc: {
          start: {
            line: 401,
            column: 75
          },
          end: {
            line: 405,
            column: 1
          }
        },
        line: 401
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 402,
            column: 22
          },
          end: {
            line: 402,
            column: 23
          }
        },
        loc: {
          start: {
            line: 402,
            column: 34
          },
          end: {
            line: 404,
            column: 3
          }
        },
        line: 402
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }, {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 32,
            column: 7
          }
        }],
        line: 10
      },
      '1': {
        loc: {
          start: {
            line: 12,
            column: 10
          },
          end: {
            line: 14,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 12,
            column: 10
          },
          end: {
            line: 14,
            column: 11
          }
        }, {
          start: {
            line: 12,
            column: 10
          },
          end: {
            line: 14,
            column: 11
          }
        }],
        line: 12
      },
      '2': {
        loc: {
          start: {
            line: 12,
            column: 14
          },
          end: {
            line: 12,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 12,
            column: 14
          },
          end: {
            line: 12,
            column: 27
          }
        }, {
          start: {
            line: 12,
            column: 31
          },
          end: {
            line: 12,
            column: 38
          }
        }],
        line: 12
      },
      '3': {
        loc: {
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 17,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 17,
            column: 11
          }
        }, {
          start: {
            line: 15,
            column: 10
          },
          end: {
            line: 17,
            column: 11
          }
        }],
        line: 15
      },
      '4': {
        loc: {
          start: {
            line: 15,
            column: 14
          },
          end: {
            line: 15,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 15,
            column: 14
          },
          end: {
            line: 15,
            column: 28
          }
        }, {
          start: {
            line: 15,
            column: 32
          },
          end: {
            line: 15,
            column: 40
          }
        }],
        line: 15
      },
      '5': {
        loc: {
          start: {
            line: 60,
            column: 18
          },
          end: {
            line: 60,
            column: 37
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 60,
            column: 32
          },
          end: {
            line: 60,
            column: 33
          }
        }, {
          start: {
            line: 60,
            column: 36
          },
          end: {
            line: 60,
            column: 37
          }
        }],
        line: 60
      },
      '6': {
        loc: {
          start: {
            line: 61,
            column: 21
          },
          end: {
            line: 61,
            column: 43
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 61,
            column: 38
          },
          end: {
            line: 61,
            column: 39
          }
        }, {
          start: {
            line: 61,
            column: 42
          },
          end: {
            line: 61,
            column: 43
          }
        }],
        line: 61
      },
      '7': {
        loc: {
          start: {
            line: 75,
            column: 2
          },
          end: {
            line: 77,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 2
          },
          end: {
            line: 77,
            column: 3
          }
        }, {
          start: {
            line: 75,
            column: 2
          },
          end: {
            line: 77,
            column: 3
          }
        }],
        line: 75
      },
      '8': {
        loc: {
          start: {
            line: 107,
            column: 12
          },
          end: {
            line: 107,
            column: 72
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 107,
            column: 38
          },
          end: {
            line: 107,
            column: 39
          }
        }, {
          start: {
            line: 107,
            column: 43
          },
          end: {
            line: 107,
            column: 71
          }
        }],
        line: 107
      },
      '9': {
        loc: {
          start: {
            line: 107,
            column: 43
          },
          end: {
            line: 107,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 107,
            column: 66
          },
          end: {
            line: 107,
            column: 67
          }
        }, {
          start: {
            line: 107,
            column: 70
          },
          end: {
            line: 107,
            column: 71
          }
        }],
        line: 107
      },
      '10': {
        loc: {
          start: {
            line: 182,
            column: 8
          },
          end: {
            line: 184,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 182,
            column: 8
          },
          end: {
            line: 184,
            column: 9
          }
        }, {
          start: {
            line: 182,
            column: 8
          },
          end: {
            line: 184,
            column: 9
          }
        }],
        line: 182
      },
      '11': {
        loc: {
          start: {
            line: 188,
            column: 26
          },
          end: {
            line: 188,
            column: 39
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 188,
            column: 34
          },
          end: {
            line: 188,
            column: 35
          }
        }, {
          start: {
            line: 188,
            column: 38
          },
          end: {
            line: 188,
            column: 39
          }
        }],
        line: 188
      },
      '12': {
        loc: {
          start: {
            line: 189,
            column: 29
          },
          end: {
            line: 189,
            column: 42
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 189,
            column: 37
          },
          end: {
            line: 189,
            column: 38
          }
        }, {
          start: {
            line: 189,
            column: 41
          },
          end: {
            line: 189,
            column: 42
          }
        }],
        line: 189
      },
      '13': {
        loc: {
          start: {
            line: 213,
            column: 10
          },
          end: {
            line: 216,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 213,
            column: 10
          },
          end: {
            line: 216,
            column: 11
          }
        }, {
          start: {
            line: 213,
            column: 10
          },
          end: {
            line: 216,
            column: 11
          }
        }],
        line: 213
      },
      '14': {
        loc: {
          start: {
            line: 234,
            column: 29
          },
          end: {
            line: 234,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 234,
            column: 29
          },
          end: {
            line: 234,
            column: 42
          }
        }, {
          start: {
            line: 234,
            column: 46
          },
          end: {
            line: 234,
            column: 60
          }
        }, {
          start: {
            line: 234,
            column: 64
          },
          end: {
            line: 234,
            column: 65
          }
        }],
        line: 234
      },
      '15': {
        loc: {
          start: {
            line: 245,
            column: 29
          },
          end: {
            line: 245,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 245,
            column: 29
          },
          end: {
            line: 245,
            column: 42
          }
        }, {
          start: {
            line: 245,
            column: 46
          },
          end: {
            line: 245,
            column: 60
          }
        }, {
          start: {
            line: 245,
            column: 64
          },
          end: {
            line: 245,
            column: 65
          }
        }],
        line: 245
      },
      '16': {
        loc: {
          start: {
            line: 275,
            column: 12
          },
          end: {
            line: 278,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 275,
            column: 12
          },
          end: {
            line: 278,
            column: 13
          }
        }, {
          start: {
            line: 275,
            column: 12
          },
          end: {
            line: 278,
            column: 13
          }
        }],
        line: 275
      },
      '17': {
        loc: {
          start: {
            line: 275,
            column: 16
          },
          end: {
            line: 275,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 275,
            column: 16
          },
          end: {
            line: 275,
            column: 23
          }
        }, {
          start: {
            line: 275,
            column: 27
          },
          end: {
            line: 275,
            column: 40
          }
        }],
        line: 275
      },
      '18': {
        loc: {
          start: {
            line: 279,
            column: 12
          },
          end: {
            line: 282,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 279,
            column: 12
          },
          end: {
            line: 282,
            column: 13
          }
        }, {
          start: {
            line: 279,
            column: 12
          },
          end: {
            line: 282,
            column: 13
          }
        }],
        line: 279
      },
      '19': {
        loc: {
          start: {
            line: 279,
            column: 16
          },
          end: {
            line: 279,
            column: 42
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 279,
            column: 16
          },
          end: {
            line: 279,
            column: 24
          }
        }, {
          start: {
            line: 279,
            column: 28
          },
          end: {
            line: 279,
            column: 42
          }
        }],
        line: 279
      },
      '20': {
        loc: {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }, {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }],
        line: 296
      },
      '21': {
        loc: {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }, {
          start: {
            line: 318,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }],
        line: 318
      },
      '22': {
        loc: {
          start: {
            line: 343,
            column: 10
          },
          end: {
            line: 345,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 343,
            column: 10
          },
          end: {
            line: 345,
            column: 11
          }
        }, {
          start: {
            line: 343,
            column: 10
          },
          end: {
            line: 345,
            column: 11
          }
        }],
        line: 343
      },
      '23': {
        loc: {
          start: {
            line: 349,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 349,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        }, {
          start: {
            line: 349,
            column: 12
          },
          end: {
            line: 356,
            column: 13
          }
        }],
        line: 349
      },
      '24': {
        loc: {
          start: {
            line: 368,
            column: 12
          },
          end: {
            line: 371,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 368,
            column: 12
          },
          end: {
            line: 371,
            column: 13
          }
        }, {
          start: {
            line: 368,
            column: 12
          },
          end: {
            line: 371,
            column: 13
          }
        }],
        line: 368
      },
      '25': {
        loc: {
          start: {
            line: 368,
            column: 16
          },
          end: {
            line: 368,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 368,
            column: 16
          },
          end: {
            line: 368,
            column: 23
          }
        }, {
          start: {
            line: 368,
            column: 27
          },
          end: {
            line: 368,
            column: 43
          }
        }],
        line: 368
      },
      '26': {
        loc: {
          start: {
            line: 372,
            column: 12
          },
          end: {
            line: 375,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 372,
            column: 12
          },
          end: {
            line: 375,
            column: 13
          }
        }, {
          start: {
            line: 372,
            column: 12
          },
          end: {
            line: 375,
            column: 13
          }
        }],
        line: 372
      },
      '27': {
        loc: {
          start: {
            line: 372,
            column: 16
          },
          end: {
            line: 372,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 372,
            column: 16
          },
          end: {
            line: 372,
            column: 24
          }
        }, {
          start: {
            line: 372,
            column: 28
          },
          end: {
            line: 372,
            column: 45
          }
        }],
        line: 372
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0, 0],
      '15': [0, 0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function customDashbordCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_16on4em1mq.f[0]++;

  var PATH = (cov_16on4em1mq.s[0]++, 'Dashboard');

  cov_16on4em1mq.s[1]++;
  $scope.validateItem = function () {
    cov_16on4em1mq.f[1]++;

    var item = (cov_16on4em1mq.s[2]++, angular.copy($scope.common.editItem));
    var listView = (cov_16on4em1mq.s[3]++, []);
    cov_16on4em1mq.s[4]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = item.LIST_VIEW[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var view = _step.value;

        var ivs = (cov_16on4em1mq.s[5]++, $filter('filter')(item.STRUCTURE, { id: view.id }, true));

        cov_16on4em1mq.s[6]++;
        if (ivs.length > 0) {
          cov_16on4em1mq.b[0][0]++;
          cov_16on4em1mq.s[7]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = ivs[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var iv = _step2.value;
              cov_16on4em1mq.s[8]++;

              if ((cov_16on4em1mq.b[2][0]++, view.TYPE > 4) && (cov_16on4em1mq.b[2][1]++, iv.form)) {
                cov_16on4em1mq.b[1][0]++;
                cov_16on4em1mq.s[9]++;

                listView.push(iv);
              } else {
                cov_16on4em1mq.b[1][1]++;
              }
              cov_16on4em1mq.s[10]++;
              if ((cov_16on4em1mq.b[4][0]++, view.TYPE <= 4) && (cov_16on4em1mq.b[4][1]++, !iv.form)) {
                cov_16on4em1mq.b[3][0]++;
                cov_16on4em1mq.s[11]++;

                listView.push(iv);
              } else {
                cov_16on4em1mq.b[3][1]++;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        } else {
          cov_16on4em1mq.b[0][1]++;
          cov_16on4em1mq.s[12]++;

          listView.push({
            t: 100,
            l: 10,
            w: 50,
            h: 300,
            row: 1,
            col: 4,
            sizeX: 0,
            sizeY: 0,
            id: view.id,
            form: view.TYPE > 4
          });
        }

        /*
        for (const iv of ivs) {
          if (!((view.from && iv.TYPE > 4) || (!view.from && iv.TYPE <= 4))) {
            continue;
          }
          if (iv) {
            listView.push(iv);
          } else {
            listView.push({
              t: 100,
              l: 10,
              w: 50,
              h: 300,
              row: 1,
              col: 4,
              sizeX: 0,
              sizeY: 0,
              id: view.id,
              form: view.TYPE > 4
            });
          }
          break;
        } */
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_16on4em1mq.s[13]++;
    item.LIST_VIEW = undefined;
    cov_16on4em1mq.s[14]++;
    item.STRUCTURE = undefined;
    cov_16on4em1mq.s[15]++;
    item.PUBLIC = item.PUBLIC ? (cov_16on4em1mq.b[5][0]++, 1) : (cov_16on4em1mq.b[5][1]++, 0);
    cov_16on4em1mq.s[16]++;
    item.PORTFOLIO = item.PORTFOLIO ? (cov_16on4em1mq.b[6][0]++, 1) : (cov_16on4em1mq.b[6][1]++, 0);

    var str = (cov_16on4em1mq.s[17]++, angular.toJson(listView));
    cov_16on4em1mq.s[18]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: angular.toJson(item), c: str }, function () {
      cov_16on4em1mq.f[2]++;
      cov_16on4em1mq.s[19]++;

      $scope.closeModal();
      cov_16on4em1mq.s[20]++;
      $scope.common.getAll();
    });
  };
  cov_16on4em1mq.s[21]++;
  $scope.closeModal = function () {
    cov_16on4em1mq.f[3]++;
    cov_16on4em1mq.s[22]++;

    $uibModalInstance.dismiss('cancel');
  };
}
cov_16on4em1mq.s[23]++;
angular.module('app').controller('customDashbordCtrl', function ($scope, $deltaLocation, $rootScope, $deltaCategoriesBudget, $deltaFinancSource, $deltaPlanLogique, $deltahttp, $translate, $CRUDService, $deltaPeriode, $deltadate, $filter, $uibModal, SweetAlert) {
  cov_16on4em1mq.f[4]++;
  cov_16on4em1mq.s[24]++;

  $rootScope.processPageRight('4_2');
  cov_16on4em1mq.s[25]++;
  if ($rootScope.global_access_page_denied) {
    cov_16on4em1mq.b[7][0]++;
    cov_16on4em1mq.s[26]++;

    return;
  } else {
    cov_16on4em1mq.b[7][1]++;
  }
  var PATH = (cov_16on4em1mq.s[27]++, 'Dashboard');
  var PATH_USER = (cov_16on4em1mq.s[28]++, 'DashboardUser');
  var PATH_ANALYSE = (cov_16on4em1mq.s[29]++, 'Analyse');
  cov_16on4em1mq.s[30]++;
  $scope.analyseList = [];
  cov_16on4em1mq.s[31]++;
  $deltaLocation.addController($scope);
  cov_16on4em1mq.s[32]++;
  $deltaPlanLogique.addController($scope);
  cov_16on4em1mq.s[33]++;
  $deltaPeriode.addController($scope);
  cov_16on4em1mq.s[34]++;
  $deltaCategoriesBudget.addController($scope);
  cov_16on4em1mq.s[35]++;
  $deltaFinancSource.addController($scope);
  cov_16on4em1mq.s[36]++;
  $CRUDService.getAll(PATH_ANALYSE, { get: 'all' }, function (data) {
    cov_16on4em1mq.f[5]++;
    cov_16on4em1mq.s[37]++;
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {
      for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var eld = _step3.value;

        var param = (cov_16on4em1mq.s[38]++, angular.fromJson(eld.VALUE));
        cov_16on4em1mq.s[39]++;
        param.TITLE = eld.TITLE;
        cov_16on4em1mq.s[40]++;
        param.TYPE = eld.TYPE;
        cov_16on4em1mq.s[41]++;
        param.id = eld.id;
        cov_16on4em1mq.s[42]++;
        param.PUBLIC = eld.PUBLIC == 1;
        cov_16on4em1mq.s[43]++;
        param.COLOR = eld.COLOR;
        cov_16on4em1mq.s[44]++;
        $scope.analyseList.push(param);
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }
  });

  cov_16on4em1mq.s[45]++;
  $CRUDService.getAll('Formular', { get: 'all_request_by_project' }, function (data) {
    cov_16on4em1mq.f[6]++;
    cov_16on4em1mq.s[46]++;
    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = data.map(function (value) {
        cov_16on4em1mq.f[7]++;
        cov_16on4em1mq.s[47]++;
        return {
          AS_DBORDR: false,
          COLOR: '0,0,0',
          DESCRIPTION: null,
          PUBLIC: '1',
          id: value.id,
          TITLE: value.NAMEREQUEST,
          TYPE: value.COLOR == 'SIMPLE' ? (cov_16on4em1mq.b[8][0]++, 5) : (cov_16on4em1mq.b[8][1]++, value.COLOR == 'MAP' ? (cov_16on4em1mq.b[9][0]++, 7) : (cov_16on4em1mq.b[9][1]++, 6))
        };
      })[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var template = _step4.value;
        cov_16on4em1mq.s[48]++;

        $scope.analyseList.push(template);
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }
  });

  cov_16on4em1mq.s[49]++;
  $scope.gridsterOpts = {
    columns: 8,
    margins: [20, 20],
    outerMargin: false,
    pushing: true,
    floating: true,
    draggable: {
      enabled: true,
      stop: function stop(event, element, widget) {
        cov_16on4em1mq.s[50]++;

        $scope.common.validateItem();
      }
    },
    resizable: {
      enabled: true,
      handles: ['n', 'e', 's', 'w', 'se', 'sw'],
      stop: function stop(event, element, widget) {
        cov_16on4em1mq.s[51]++;

        $scope.common.validateItem();
      }
    }
  };

  cov_16on4em1mq.s[52]++;
  $scope.sortableOptions = {
    connectWith: '.connectPanels',
    handler: '.ibox-title'
  };
  cov_16on4em1mq.s[53]++;
  $scope.resize = function (args, item) {
    cov_16on4em1mq.f[8]++;

    var width = (cov_16on4em1mq.s[54]++, $('#display_item_dsh').width());
    var size = (cov_16on4em1mq.s[55]++, args.size);
    cov_16on4em1mq.s[56]++;
    item.h = size.height;
    cov_16on4em1mq.s[57]++;
    item.w = 100 * (size.width / width);

    // $scope.common.detectColission(item, $scope.common.selectedItem.STRUCTURE);
    cov_16on4em1mq.s[58]++;
    $scope.common.calculateHeight($scope.common.selectedItem.STRUCTURE);
    cov_16on4em1mq.s[59]++;
    $scope.common.validateItem();
  };
  cov_16on4em1mq.s[60]++;
  $scope.drag = function (args, item) {
    cov_16on4em1mq.f[9]++;

    var width = (cov_16on4em1mq.s[61]++, $('#display_item_dsh').width());
    cov_16on4em1mq.s[62]++;
    item.t = args.position.top;
    cov_16on4em1mq.s[63]++;
    item.l = 100 * args.position.left / width;
    // $scope.common.detectColission(item, $scope.common.selectedItem.STRUCTURE);
    cov_16on4em1mq.s[64]++;
    $scope.common.calculateHeight($scope.common.selectedItem.STRUCTURE);
    cov_16on4em1mq.s[65]++;
    $scope.common.validateItem();
  };

  cov_16on4em1mq.s[66]++;
  $scope.exportData = function () {
    cov_16on4em1mq.f[10]++;
    cov_16on4em1mq.s[67]++;

    $uibModal.open({
      templateUrl: 'app/views/export_data/exportData.html',
      controller: exportData_view,
      scope: $scope,
      size: 'xl_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_16on4em1mq.s[68]++;
  $scope.init = function () {
    cov_16on4em1mq.f[11]++;
    cov_16on4em1mq.s[69]++;

    this.onDropComplete = function (index, obj, evt) {
      cov_16on4em1mq.f[12]++;

      var otherObj = (cov_16on4em1mq.s[70]++, this.common.selectedItem.STRUCTURE[index]);
      var otherIndex = (cov_16on4em1mq.s[71]++, this.common.selectedItem.STRUCTURE.indexOf(obj));
      cov_16on4em1mq.s[72]++;
      this.common.selectedItem.STRUCTURE[index] = obj;
      cov_16on4em1mq.s[73]++;
      this.common.selectedItem.STRUCTURE[otherIndex] = otherObj;
      cov_16on4em1mq.s[74]++;
      this.common.validateItem();
    };
    cov_16on4em1mq.s[75]++;
    this.common = {
      searchText: '',
      category: true,
      widths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      setAsMain: function setAsMain(item) {
        var value = (cov_16on4em1mq.s[76]++, false);
        cov_16on4em1mq.s[77]++;
        if ($scope.data_main[item.id]) {
          cov_16on4em1mq.b[10][0]++;
          cov_16on4em1mq.s[78]++;

          value = $scope.data_main[item.id].main;
        } else {
          cov_16on4em1mq.b[10][1]++;
        }
        cov_16on4em1mq.s[79]++;
        value = !value;
        var val = (cov_16on4em1mq.s[80]++, {
          ID_DASHBOARD: item.id,
          MAIN_DASHBOARD: value ? (cov_16on4em1mq.b[11][0]++, 1) : (cov_16on4em1mq.b[11][1]++, 0),
          FAVORIS_DASHBOARD: value ? (cov_16on4em1mq.b[12][0]++, 1) : (cov_16on4em1mq.b[12][1]++, 0)
        });

        cov_16on4em1mq.s[81]++;
        $scope.data_main[item.id] = { main: value, favoris: value };
        cov_16on4em1mq.s[82]++;
        $CRUDService.save(PATH_USER, { action: 'MiseAJour', valeur: angular.toJson(val) }, function (data) {
          cov_16on4em1mq.f[13]++;
        });
      },
      calculateHeight: function calculateHeight(group) {
        var height = (cov_16on4em1mq.s[83]++, 100);
        cov_16on4em1mq.s[84]++;
        var _iteratorNormalCompletion5 = true;
        var _didIteratorError5 = false;
        var _iteratorError5 = undefined;

        try {
          for (var _iterator5 = group[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
            var el = _step5.value;
            cov_16on4em1mq.s[85]++;

            height += el.h + 20;
          }
        } catch (err) {
          _didIteratorError5 = true;
          _iteratorError5 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion5 && _iterator5.return) {
              _iterator5.return();
            }
          } finally {
            if (_didIteratorError5) {
              throw _iteratorError5;
            }
          }
        }

        cov_16on4em1mq.s[86]++;
        $scope.common.globalHeight = height;
      },
      validateItem: function validateItem() {
        var item = (cov_16on4em1mq.s[87]++, angular.copy(this.selectedItem));

        cov_16on4em1mq.s[88]++;
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = item.STRUCTURE[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var el = _step6.value;
            cov_16on4em1mq.s[89]++;

            el.id = el.id.id;
            cov_16on4em1mq.s[90]++;
            el.data = undefined;
            // el.t = el.t < 90 ? 90 : el.t;
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }

        cov_16on4em1mq.s[91]++;
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = $scope.data[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var elp = _step7.value;
            cov_16on4em1mq.s[92]++;

            if (elp.id == item.id) {
              cov_16on4em1mq.b[13][0]++;
              cov_16on4em1mq.s[93]++;

              elp.STRUCTURE = item.STRUCTURE;
              cov_16on4em1mq.s[94]++;
              break;
            } else {
              cov_16on4em1mq.b[13][1]++;
            }
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        var str = (cov_16on4em1mq.s[95]++, angular.toJson(item.STRUCTURE));
        cov_16on4em1mq.s[96]++;
        item.STRUCTURE = undefined;
        cov_16on4em1mq.s[97]++;
        this.updatePositonDashboard = true;
        cov_16on4em1mq.s[98]++;
        $CRUDService.save(PATH, { action: 'MiseAJour', valeur: angular.toJson(item), c: str }, function () {
          cov_16on4em1mq.f[14]++;
        });
      },
      getAll: function getAll() {
        cov_16on4em1mq.s[99]++;

        $scope.isloading = true;
        cov_16on4em1mq.s[100]++;
        $scope.data = [];
        cov_16on4em1mq.s[101]++;
        $CRUDService.getAll(PATH, { get: 'all_own', simple: 1 }, function (data) {
          cov_16on4em1mq.f[15]++;
          cov_16on4em1mq.s[102]++;

          $scope.data = data;
          cov_16on4em1mq.s[103]++;
          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = $scope.data[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var el = _step8.value;
              cov_16on4em1mq.s[104]++;

              el.STRUCTURE = angular.fromJson(el.STRUCTURE);
              cov_16on4em1mq.s[105]++;
              el.PUBLIC = el.PUBLIC == 1;
              cov_16on4em1mq.s[106]++;
              el.PORTFOLIO = el.PORTFOLIO == 1;
              cov_16on4em1mq.s[107]++;
              el.LASTUPDATE = (cov_16on4em1mq.b[14][0]++, el.LASTUPDATE) || (cov_16on4em1mq.b[14][1]++, el.LAST_UPDATE) || (cov_16on4em1mq.b[14][2]++, 0);
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }

          cov_16on4em1mq.s[108]++;
          $scope.isloading = false;
        });
        cov_16on4em1mq.s[109]++;
        $scope.data_public = [];
        cov_16on4em1mq.s[110]++;
        $CRUDService.getAll(PATH, { get: 'all_public', simple: 1 }, function (data) {
          cov_16on4em1mq.f[16]++;
          cov_16on4em1mq.s[111]++;

          $scope.data_public = data;
          cov_16on4em1mq.s[112]++;
          var _iteratorNormalCompletion9 = true;
          var _didIteratorError9 = false;
          var _iteratorError9 = undefined;

          try {
            for (var _iterator9 = $scope.data_public[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
              var el = _step9.value;
              cov_16on4em1mq.s[113]++;

              el.STRUCTURE = angular.fromJson(el.STRUCTURE);
              cov_16on4em1mq.s[114]++;
              el.PUBLIC = el.PUBLIC == 1;
              cov_16on4em1mq.s[115]++;
              el.PORTFOLIO = el.PORTFOLIO == 1;
              cov_16on4em1mq.s[116]++;
              el.LASTUPDATE = (cov_16on4em1mq.b[15][0]++, el.LASTUPDATE) || (cov_16on4em1mq.b[15][1]++, el.LAST_UPDATE) || (cov_16on4em1mq.b[15][2]++, 0);
            }
          } catch (err) {
            _didIteratorError9 = true;
            _iteratorError9 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion9 && _iterator9.return) {
                _iterator9.return();
              }
            } finally {
              if (_didIteratorError9) {
                throw _iteratorError9;
              }
            }
          }

          cov_16on4em1mq.s[117]++;
          $scope.isloading = false;
        });
        cov_16on4em1mq.s[118]++;
        $scope.data_main = {};
        cov_16on4em1mq.s[119]++;
        $CRUDService.getAll(PATH_USER, { get: 'all' }, function (data) {
          cov_16on4em1mq.f[17]++;
          cov_16on4em1mq.s[120]++;

          $scope.data_main = {};
          cov_16on4em1mq.s[121]++;
          var _iteratorNormalCompletion10 = true;
          var _didIteratorError10 = false;
          var _iteratorError10 = undefined;

          try {
            for (var _iterator10 = data[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
              var main = _step10.value;
              cov_16on4em1mq.s[122]++;

              $scope.data_main[main.ID_DASHBOARD] = { main: main.MAIN_DASHBOARD == 1, favoris: main.FAVORIS_DASHBOARD == 1 };
            }
          } catch (err) {
            _didIteratorError10 = true;
            _iteratorError10 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion10 && _iterator10.return) {
                _iterator10.return();
              }
            } finally {
              if (_didIteratorError10) {
                throw _iteratorError10;
              }
            }
          }
        });
      },
      add: function add(portfolio) {
        cov_16on4em1mq.s[123]++;

        $scope.common.selectedItem = { id: 0 };
        cov_16on4em1mq.s[124]++;
        return;
        /*
        this.edit({
          id: 0,
          TITLE: '',
          PUBLIC: true,
          PORTFOLIO: true,
          STRUCTURE: []
        }); */
      },
      edit: function edit(item) {
        cov_16on4em1mq.s[125]++;

        this.editItem = angular.copy(item);
        cov_16on4em1mq.s[126]++;
        this.editItem.LIST_VIEW = [];
        cov_16on4em1mq.s[127]++;
        var _iteratorNormalCompletion11 = true;
        var _didIteratorError11 = false;
        var _iteratorError11 = undefined;

        try {
          for (var _iterator11 = this.editItem.STRUCTURE[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
            var el = _step11.value;

            var views = (cov_16on4em1mq.s[128]++, $filter('filter')($scope.analyseList, { id: el.id }, true));
            cov_16on4em1mq.s[129]++;
            var _iteratorNormalCompletion12 = true;
            var _didIteratorError12 = false;
            var _iteratorError12 = undefined;

            try {
              for (var _iterator12 = views[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
                var view = _step12.value;
                cov_16on4em1mq.s[130]++;

                if ((cov_16on4em1mq.b[17][0]++, el.form) && (cov_16on4em1mq.b[17][1]++, view.TYPE > 4)) {
                  cov_16on4em1mq.b[16][0]++;
                  cov_16on4em1mq.s[131]++;

                  this.editItem.LIST_VIEW.push(view);
                  cov_16on4em1mq.s[132]++;
                  break;
                } else {
                  cov_16on4em1mq.b[16][1]++;
                }
                cov_16on4em1mq.s[133]++;
                if ((cov_16on4em1mq.b[19][0]++, !el.form) && (cov_16on4em1mq.b[19][1]++, view.TYPE <= 4)) {
                  cov_16on4em1mq.b[18][0]++;
                  cov_16on4em1mq.s[134]++;

                  this.editItem.LIST_VIEW.push(view);
                  cov_16on4em1mq.s[135]++;
                  break;
                } else {
                  cov_16on4em1mq.b[18][1]++;
                }
              }
            } catch (err) {
              _didIteratorError12 = true;
              _iteratorError12 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion12 && _iterator12.return) {
                  _iterator12.return();
                }
              } finally {
                if (_didIteratorError12) {
                  throw _iteratorError12;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError11 = true;
          _iteratorError11 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion11 && _iterator11.return) {
              _iterator11.return();
            }
          } finally {
            if (_didIteratorError11) {
              throw _iteratorError11;
            }
          }
        }

        cov_16on4em1mq.s[136]++;
        $uibModal.open({
          templateUrl: 'app/views/customdashboard/edit.html',
          controller: customDashbordCtrl_edit,
          scope: $scope,
          size: 'md',
          backdrop: 'static',
          windowClass: 'animated fadeInRight left-modal'
        });
      },
      add_view: function add_view() {
        var first = (cov_16on4em1mq.s[137]++, $scope.analyseList[0]);
        cov_16on4em1mq.s[138]++;
        if (!first) {
          cov_16on4em1mq.b[20][0]++;
          cov_16on4em1mq.s[139]++;

          return;
        } else {
          cov_16on4em1mq.b[20][1]++;
        }
        cov_16on4em1mq.s[140]++;
        this.edit_view({
          id: first,
          h: 250,
          w: 12
        });
      },
      edit_view: function edit_view(item, index) {
        cov_16on4em1mq.s[141]++;

        this.oldItem = angular.copy(item);
        cov_16on4em1mq.s[142]++;
        this.selectViewItem = item;
        cov_16on4em1mq.s[143]++;
        this.index_view = index;
      },
      cancelView: function cancelView() {
        cov_16on4em1mq.s[144]++;

        this.index_view = undefined;
        cov_16on4em1mq.s[145]++;
        this.selectViewItem.id = this.oldItem.id;
        cov_16on4em1mq.s[146]++;
        this.selectViewItem.h = this.oldItem.h;
        cov_16on4em1mq.s[147]++;
        this.selectViewItem.w = this.oldItem.w;
        cov_16on4em1mq.s[148]++;
        this.oldItem = undefined;
      },
      validatedView: function validatedView() {
        cov_16on4em1mq.s[149]++;

        if (angular.isUndefined(this.index_view)) {
          cov_16on4em1mq.b[21][0]++;
          cov_16on4em1mq.s[150]++;

          this.editItem.STRUCTURE.push(this.selectViewItem);
        } else {
          cov_16on4em1mq.b[21][1]++;
          cov_16on4em1mq.s[151]++;

          this.editItem.STRUCTURE[this.index_view] = this.selectViewItem;
        }
        cov_16on4em1mq.s[152]++;
        this.oldItem = undefined;
        cov_16on4em1mq.s[153]++;
        this.index_view = undefined;
      },
      delete_view: function delete_view(index) {
        cov_16on4em1mq.s[154]++;

        this.editItem.STRUCTURE.splice(index, 1);
      },
      deleteItem: function deleteItem(item) {
        cov_16on4em1mq.s[155]++;

        SweetAlert.swal({
          title: $translate.instant('COMMON.CONFIRM'),
          // text: $translate.instant('CUSTOM_DASHBOARD.CONFIRM_DELETE'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#c8c8c8',
          cancelButtonColor: '#DD6B55',

          confirmButtonText: $translate.instant('COMMON.NO'),
          cancelButtonText: $translate.instant('COMMON.YES'),
          closeOnConfirm: true,
          closeOnCancel: true
        }, function (isconfirm) {
          cov_16on4em1mq.f[18]++;
          cov_16on4em1mq.s[156]++;

          if (isconfirm) {
            cov_16on4em1mq.b[22][0]++;
            cov_16on4em1mq.s[157]++;

            return;
          } else {
            cov_16on4em1mq.b[22][1]++;
          }
          cov_16on4em1mq.s[158]++;
          $scope.isloading = true;

          cov_16on4em1mq.s[159]++;
          $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function (data) {
            cov_16on4em1mq.f[19]++;
            cov_16on4em1mq.s[160]++;

            if (data < 0) {
              cov_16on4em1mq.b[23][0]++;
              cov_16on4em1mq.s[161]++;

              SweetAlert.swal({
                title: $translate.instant('COMMON.ERROR_DELETE'),
                text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
              });
              cov_16on4em1mq.s[162]++;
              $scope.isloading = false;
              cov_16on4em1mq.s[163]++;
              return;
            } else {
              cov_16on4em1mq.b[23][1]++;
            }
            cov_16on4em1mq.s[164]++;
            $scope.common.getAll();
          });
        });
      },
      openDisplay: function openDisplay(item) {
        cov_16on4em1mq.s[165]++;

        this.idcomponent = new Date().getTime();
        cov_16on4em1mq.s[166]++;
        this.selectedItem = angular.copy(item);
        cov_16on4em1mq.s[167]++;
        this.updatePositonDashboard = false;
        cov_16on4em1mq.s[168]++;
        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = this.selectedItem.STRUCTURE[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var el = _step13.value;

            var elements = (cov_16on4em1mq.s[169]++, $filter('filter')($scope.analyseList, { id: el.id }, true));
            cov_16on4em1mq.s[170]++;
            var _iteratorNormalCompletion14 = true;
            var _didIteratorError14 = false;
            var _iteratorError14 = undefined;

            try {
              for (var _iterator14 = elements[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
                var element = _step14.value;
                cov_16on4em1mq.s[171]++;

                if ((cov_16on4em1mq.b[25][0]++, el.form) && (cov_16on4em1mq.b[25][1]++, element.TYPE > 4)) {
                  cov_16on4em1mq.b[24][0]++;
                  cov_16on4em1mq.s[172]++;

                  el.id = element;
                  cov_16on4em1mq.s[173]++;
                  break;
                } else {
                  cov_16on4em1mq.b[24][1]++;
                }
                cov_16on4em1mq.s[174]++;
                if ((cov_16on4em1mq.b[27][0]++, !el.form) && (cov_16on4em1mq.b[27][1]++, element.TYPE <= 4)) {
                  cov_16on4em1mq.b[26][0]++;
                  cov_16on4em1mq.s[175]++;

                  el.id = element;
                  cov_16on4em1mq.s[176]++;
                  break;
                } else {
                  cov_16on4em1mq.b[26][1]++;
                }
              }

              /* el.id = ($filter('filter')($scope.analyseList, {id: el.id}, true))[0];
                if(!el.col){
                 el.col = 4
               }
               if(!el.row){
                 el.row = 4
               }
               if(!el.sizeX){
                 el.sizeX = 4
               }
               if(!el.sizeY){
                 el.sizeY = 4
               } */
            } catch (err) {
              _didIteratorError14 = true;
              _iteratorError14 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion14 && _iterator14.return) {
                  _iterator14.return();
                }
              } finally {
                if (_didIteratorError14) {
                  throw _iteratorError14;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }

        cov_16on4em1mq.s[177]++;
        this.calculateHeight(this.selectedItem.STRUCTURE);
        cov_16on4em1mq.s[178]++;
        $rootScope.performFullScreen('analytics');
      }
    };
    cov_16on4em1mq.s[179]++;
    this.common.getAll();
  };
  cov_16on4em1mq.s[180]++;
  $scope.init();
});

function exportData_view($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_16on4em1mq.f[20]++;
  cov_16on4em1mq.s[181]++;

  $scope.closeModal = function () {
    cov_16on4em1mq.f[21]++;
    cov_16on4em1mq.s[182]++;

    $uibModalInstance.dismiss('cancel');
  };
}
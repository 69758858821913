'use strict';

var cov_i6pifnsl = function () {
  var path = '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/todo/todoCtrl.js',
      hash = '1be9c258681d22b4183346a610a5d5794c2e8a23',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/omcg/apps/delta-monitoring/client/src/app/views/todo/todoCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 8,
          column: 1
        }
      },
      '1': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 39
        }
      },
      '2': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 28
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 45
          },
          end: {
            line: 4,
            column: 46
          }
        },
        loc: {
          start: {
            line: 4,
            column: 142
          },
          end: {
            line: 7,
            column: 1
          }
        },
        line: 4
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_i6pifnsl.s[0]++;
/**
 * Created by BKSB on 17/05/2017.
 */
angular.module('app').controller('todoCtrl', function ($scope, $deltaLocation, $rootScope, $CRUDService, $deltaTypeIndicateur, $filter, $translate) {
  cov_i6pifnsl.f[0]++;
  cov_i6pifnsl.s[1]++;

  $deltaLocation.addController($scope);
  var PATH = (cov_i6pifnsl.s[2]++, 'Indicateurs');
});